import { useEffect, useMemo, useState } from 'react';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import AlertComponent from 'components/design-system/Alert/Alert';
import { FormControlLabel, FormGroup, Hidden } from '@mui/material';
import CheckboxComponent from 'components/design-system/Checkbox/Checkbox';
import SwitchComponent from 'components/design-system/Switch/Switch';
// Services
import serviceRsd from 'services/residuos';

import { DICTONARY } from 'const/Dictonary';

export const BusinessRSD = ({
  values,
  errors,
  touched,
  onChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  setFieldError
}) => {
  const [residuos, setResiduos] = useState([]);

  const [flag, setFlag] = useState(true);

  const [todosSeleccionados, setTodosSeleccionados] = useState(false);

  const [nombreResiduosSeleccionados, setNombreResiduosSeleccionados] =
    useState([]);

  const handleChangeSeleccionarTodo = event => {
    setFlag(!event.target.checked);

    setTodosSeleccionados(event.target.checked);
    let arraySeleccionados = [];
    setResiduos(
      residuos.map(residuo => {
        arraySeleccionados.push(residuo.codigoTipoResiduo.toString());

        return {
          codigoTipoResiduo: residuo.codigoTipoResiduo,
          nombreTipo: residuo.nombreTipo,
          checked: flag ? true : false
        };
      })
    );
    setFieldValue('codigosTipoResiduo', flag ? arraySeleccionados : []);
  };

  useEffect(() => {}, [values.codigosTipoResiduo]);

  useEffect(() => {
    serviceRsd
      .tipoResiduos({
        totalPagina: DICTONARY.PAGINACION.DEFAULT,
        nivel: DICTONARY.TIPO_RESIDUOS.NIVEL_1
      })
      .then(response => {
        const data = response.data.content;
        setResiduos(
          data.map(residuo => {
            return {
              codigoTipoResiduo: residuo.codigoTipoResiduo,
              nombreTipo: residuo.nombreTipo,
              checked: false
            };
          })
        );
      })
      .catch(err => {
        console.error('error servicio tipoResiduos: ', err);
      });
  }, [values]);

  useEffect(() => {}, [flag]);

  useEffect(() => {
    setFieldValue('nombreResiduosSeleccionados', nombreResiduosSeleccionados);
  }, [nombreResiduosSeleccionados, setFieldValue]);

  useEffect(() => {
    values.codigosTipoResiduo.length === 0 &&
      setFieldError(
        'codigosTipoResiduo',
        'Debe seleccionar al menos un tipo de usuario'
      );
  }, [setFieldError, values.codigosTipoResiduo]);
  return (
    <div>
      <div className="grid grid-cols-12">
        <div className="col-span-12">
          <Heading type="h3">Tipos de residuos</Heading>
          <Text className="mb-8">
            Selecciona los tipos de residuos del interés de tu organización.
          </Text>
        </div>

        <div className="col-span-12 md:col-span-9 mb-4">
          <AlertComponent type="warning">
            <Heading type="h5">Atención</Heading>
            <Text>
              Los siguientes tipos que selecciones se utilizarán como filtro en
              tus busquedas por lo que{' '}
              <b className="text-black">
                solamente podrás ver publicaciones de oferentes o demandantes
                asociados a los tipos aquí seleccionados.
              </b>
            </Text>
          </AlertComponent>
        </div>
      </div>
      <Text className="text-error-dark">
        {' '}
        {errors.codigosTipoResiduo ? errors.codigosTipoResiduo : ''}
      </Text>
      <div>
        <FormControlLabel
          className="my-3 mx-0"
          control={
            <SwitchComponent
              checked={todosSeleccionados}
              onChange={handleChangeSeleccionarTodo}
              name="seleccionartodo"
            />
          }
          label={
            <Text className="font-semibold">
              Seleccionar todos los residuos
            </Text>
          }
        />
        <div className="bg-background-primary p-5 rounded overflow-hidden w-full overflow-y-scroll max-h-[360px] scrollbar-uv">
          <FormGroup>
            {residuos &&
              residuos.map((item, key) => (
                <FormControlLabel
                  key={key}
                  className="w-full"
                  control={
                    <CheckboxComponent
                      name="codigosTipoResiduo"
                      touched={touched.codigosTipoResiduo}
                      value={item.codigoTipoResiduo}
                      checked={values.codigosTipoResiduo.includes(
                        item.codigoTipoResiduo.toString() || ''
                      )}
                      onChange={e => {
                        // Verificar si ya existe un objeto con el mismo código de residuo
                        const existeObjeto = nombreResiduosSeleccionados.some(
                          obj =>
                            obj.codigoTipoResiduo === item.codigoTipoResiduo
                        );
                        // Agregar objeto solo si no existe
                        if (!existeObjeto) {
                          setNombreResiduosSeleccionados([
                            ...nombreResiduosSeleccionados,
                            {
                              nombre: item.nombreTipo,
                              codigoTipoResiduo: item.codigoTipoResiduo
                            }
                          ]);
                        } else {
                          setNombreResiduosSeleccionados(
                            nombreResiduosSeleccionados.filter(
                              obj =>
                                obj.codigoTipoResiduo !== item.codigoTipoResiduo
                            )
                          );
                        }
                        onChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                  }
                  label={
                    <div className="ml-2">
                      <Hidden smDown>
                        <Heading type="h5" className="mb-1">
                          {item.nombreTipo}
                        </Heading>
                      </Hidden>
                      <Hidden smUp>
                        <Text className="text-black" size="S">
                          {item.nombreTipo}
                        </Text>
                      </Hidden>
                    </div>
                  }
                />
              ))}
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default BusinessRSD;
