import { DICTONARY } from 'const/Dictonary';
import { formateaNumeroPuntosPorDecimales } from 'helpers/formateoNumeros';

export const showExpandAddUser = (loggedUser, codigosEmpresas) => {
  console.log(loggedUser, codigosEmpresas);
  // console.log('loggedUser, removableUser :>> ', removableUser.nombreUsuario, {loggedUser, removableUser});
  let isAdmin = false;
  let codEmpresaUsuario = 0;
  try {
    isAdmin = loggedUser.perfiles.find(p => p.nombrePerfil === 'Administrador');
    codEmpresaUsuario = loggedUser.sucursal.empresa.codigoEmpresa;
  } catch (error) {
    console.log(error);
  }

  if (!isAdmin) return false;

  return codigosEmpresas.includes(codEmpresaUsuario);
};

export const showRemoveUser = (loggedUser, removableUser) => {
  // console.log('loggedUser, removableUser :>> ', removableUser.nombreUsuario, {loggedUser, removableUser});
  try {
    const isAdmin = loggedUser.perfiles.find(
      p => p.nombrePerfil === 'Administrador'
    );
    return (
      isAdmin &&
      loggedUser.sucursal.empresa.codigoEmpresa == removableUser.codigoEmpresa
    );
  } catch (error) {
    return false;
  }
};

export const deshabilitarRoles = usuarios => {
  let roles = [];

  for (let index = 0; index < usuarios.length; index++) {
    const usuario = usuarios[index];
    for (
      let index = 0;
      index < usuario.sucursal.empresa.roles.length;
      index++
    ) {
      const rol = usuario.sucursal.empresa.roles[index];
      roles.push(rol.nombreRol.toUpperCase());
    }
  }

  return {
    incluyeOferente: roles.includes(
      DICTONARY.ROL.GENERADOR.NOMBRE.toUpperCase()
    ),
    incluyeDemandante: roles.includes(
      DICTONARY.ROL.RECEPTOR.NOMBRE.toUpperCase()
    ),
    incluyeAsesor: roles.includes(DICTONARY.ROL.ASESOR.NOMBRE.toUpperCase()),
    incluyeTransportista: roles.includes(
      DICTONARY.ROL.TRANSPORTISTA.NOMBRE.toUpperCase()
    ),
    incluyeGestor: roles.includes(DICTONARY.ROL.GESTOR.NOMBRE.toUpperCase())
  };
};

export const ordenarUsuariosPorEmpresa = usuarios => {
  const usuarioData = usuarios;
  usuarioData.sort((a, b) => {
    const nombreA = a.sucursal.empresa.razonSocial.toUpperCase();
    const nombreB = b.sucursal.empresa.razonSocial.toUpperCase();

    if (nombreA < nombreB) {
      return -1;
    } else if (nombreA > nombreB) {
      return 1;
    } else {
      return 0;
    }
  });
  return usuarioData;
};

export const determinaRoles = empresas => {
  let roles = {
    demandante: null,
    oferente: null,
    asesor: null,
    gestor: null,
    transportista: null
  };

  for (let index = 0; index < empresas.length; index++) {
    const empresa = empresas[index];
    const rolesEmpresa = empresa.roles;

    for (let index = 0; index < rolesEmpresa.length; index++) {
      const rol = rolesEmpresa[index];
      if (
        rol.nombreRol.toLowerCase() ===
        DICTONARY.ROL.GENERADOR.NOMBRE.toLowerCase()
      ) {
        roles.oferente = empresa.codigoEmpresa;
      }

      if (
        rol.nombreRol.toLowerCase() ===
        DICTONARY.ROL.RECEPTOR.NOMBRE.toLowerCase()
      ) {
        roles.demandante = empresa.codigoEmpresa;
      }

      if (
        rol.nombreRol.toLowerCase() ===
        DICTONARY.ROL.ASESOR.NOMBRE.toLowerCase()
      ) {
        roles.asesor = empresa.codigoEmpresa;
      }

      if (
        rol.nombreRol.toLowerCase() ===
        DICTONARY.ROL.GESTOR.NOMBRE.toLowerCase()
      ) {
        roles.gestor = empresa.codigoEmpresa;
      }

      if (
        rol.nombreRol.toLowerCase() ===
        DICTONARY.ROL.TRANSPORTISTA.NOMBRE.toLowerCase()
      ) {
        roles.transportista = empresa.codigoEmpresa;
      }
    }
  }

  return roles;
};

export const downloadFile = file => {
  const downloadLink = document.createElement('a');
  downloadLink.href = getFileURL(file);
  downloadLink.download = file.nombreArchivo;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

const getFileURL = file => {
  if (file.tipoArchivo.includes('pdf')) {
    return `data:application/pdf;base64,${file.base64Archivo}`;
  } else {
    return `data:image/png;base64,${file.base64Archivo}`;
  }
};

/**
 * 
 * @param {*} rsd // residuo obligatorio
 * @description retorna el formato de cantidad y unidad de un residuo 
 * @returns 
 */

export  const getFormatoCantidadUnidad = (rsd) => {

  try {
    return `${formateaNumeroPuntosPorDecimales(rsd.cantidad) || ''} ${rsd.unidad}` || '' ;
  } catch (error) {
    return '';
  }
}