import { AccountCircle, Clear } from '@mui/icons-material';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText
} from '@mui/material';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import Text from 'components/design-system/Text/Text';
import { DICTONARY } from 'const/Dictonary';
import store from 'states/store';

export const ListaParticipantes = ({
  usuarios,
  handleRemoveUserClick,
  empresasIniciales
}) => {
  const { logeduser } = store.loguedUser;

  const handleEliminarUsuario = (ev, user) => {
    ev.preventDefault();
    handleRemoveUserClick(user);
  };

  const isEliminable = user => {
    //Solo puedo eliminar usuarios correspondientes a mi empresa y que almenos exista mas de un usuario en la sala.
    //Adicionalmente puedo eliminar cualquier usuario que pertenezca a otro rol empresa que no sea quienes iniciaron la sala.
    const miCodigoEmpresa = logeduser.sucursal.empresa.codigoEmpresa;
    const userCodigoEmpresa = user.sucursal.empresa.codigoEmpresa;
    const userCodigoSucursal = user.sucursal.codigoSucursal;
    const isChatMismaEmpresa = new Set(empresasIniciales.map( empresa => empresa.codigoEmpresa)).size === 1;
    
    const numeroUsuariosEmpresaEnSala = usuarios.reduce((count, obj) => {
      if (obj.sucursal.empresa.codigoEmpresa === userCodigoEmpresa) {
        count++;
      }
      return count;
    }, 0);
    const numeroUsuariosSucursalEnSala = usuarios.reduce((count, obj) => {
      if (obj.sucursal.codigoSucursal === userCodigoSucursal) {
        count++;
      }
      return count;
    }, 0);
    console.log(empresasIniciales);
    console.log(isChatMismaEmpresa, 'isChatMismaEmpresa');
    if(isChatMismaEmpresa){
      //Lógica para cuando la transacción es iniciada entre usuarios de una misma empresa.
      const codigoSucursalesEmpresasIniciales = usuarios
                    .filter( usuario => usuario.sucursal.empresa.codigoEmpresa === empresasIniciales[0].codigoEmpresa)
                    .map( usuario => usuario.sucursal.codigoSucursal );
      
      return codigoSucursalesEmpresasIniciales.includes(userCodigoSucursal) && numeroUsuariosSucursalEnSala > 1;
    }

    const empresaInicial = empresasIniciales.find(empresa => empresa.codigoEmpresa === userCodigoEmpresa);
    console.log(empresaInicial, 'empresaInicial');
    if (empresaInicial) {
      return (
        numeroUsuariosEmpresaEnSala > 1 &&
        empresaInicial.codigoEmpresa === miCodigoEmpresa
      );
    } else {
      return true;
    }
  };

  return usuarios.map(user => (
    <ListItem className="px-4 py-1" key={user.codigoUsuario}>
      <ListItemAvatar>
        <Avatar className="w-9 h-9">
          <AccountCircle />
        </Avatar>
      </ListItemAvatar>
      <ListItemText>
        <Text>{user.nombreUsuario}</Text>
        <Text className="text-xs opacity-60">
          {user.sucursal.empresa.razonSocial
            ? user.sucursal.empresa.razonSocial.length > 20
              ? user.sucursal.empresa.razonSocial.slice(0, 20).concat('...')
              : user.sucursal.empresa.razonSocial
            : ''}
        </Text>
      </ListItemText>

      {isEliminable(user) && (
        <ListItemSecondaryAction className="w-8 h-8">
          <IconButtonComponent
            type="error"
            className="w-8 h-8"
            onClick={ev => handleEliminarUsuario(ev, user)}
            edge="end"
          >
            <Clear />
          </IconButtonComponent>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  ));
};
