import React from 'react';
import List from '@mui/material/List';
import { CloudUpload, FindInPage, Link } from '@mui/icons-material';
import ListItemButtonComponent from 'components/design-system/ListItemButton/ListItemButton';
import { RUTAS } from 'const/Dictonary';
const portalInformacion = 'https://plataforma-industria-circular.cl/';
const videoTutorialesURL =
  'https://plataforma-industria-circular.cl/tutoriales/';

const SubMenuTutoriales = React.forwardRef(
  (
    {
      isDemandante,
      isOferente,
      loguedUser,
      forceRefresh,
      isSuperAdmin,
      isAdmin,
      isFuncionario
    },
    ref
  ) => {
    return (
      <List>
        <ListItemButtonComponent
          onClick={forceRefresh(RUTAS.MATERIAL_DIFUSION)}
          icon={<FindInPage />}
          label={'Buscar material de información'}
        />

        {isSuperAdmin ? (
          <ListItemButtonComponent
            onClick={forceRefresh(RUTAS.MATERIAL_DIFUSION_CARGAR)}
            icon={<CloudUpload />}
            label={'Subir material de información'}
          />
        ) : null}
        <ListItemButtonComponent
          onClick={() => window.open(videoTutorialesURL, '_blank')}
          icon={<Link />}
          label={'Ver tutoriales'}
        />
        <ListItemButtonComponent
          onClick={() => window.open(portalInformacion, '_blank')}
          icon={<Link />}
          label={'Portal de información'}
        />
      </List>
    );
  }
);

export default SubMenuTutoriales;
