import { useState } from 'react';
import Aviso from 'components/Messages/Aviso';
import ButtonComponent from 'components/design-system/Button/Button';
import LabelComponent from 'components/design-system/Label/Label';
import Paper from '@mui/material/Paper';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { rowsInfo } from 'views/Residuos/data/espesificacionOferta';

import { makeStyles, withStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(0),
    width: '100%'
    // border: '1px solid #ced4da',
  },
  selectEmpty: {
    // marginTop: theme.spacing(2)
  },
  table: {
    minWidth: 650
  }
}));

export const TablaConversion = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <LabelComponent>Tabla de conversión:</LabelComponent>
      <Aviso>
        <span className="mb-1 d-block">
          Si desconoce las toneladas de recurso, puede revisar una tabla de
          conversión aquí.
        </span>
        <ButtonComponent
          onClick={() => {
            setOpen(true);
          }}
          variant="contained"
          className="btn btn-primary"
        >
          Ver tabla
        </ButtonComponent>
      </Aviso>
      <Dialog
        open={open}
        className="uv-dialog"
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Tabla de conversión</DialogTitle>
        <DialogContent>
          <Grid container spacing={0}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <p>
                Si desconoce las toneladas del residuo o recurso, puede revisar
                una tabla de conversión aquí.
              </p>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Tipo Residuo</TableCell>
                      <TableCell>Subtipo Residuo genéricos</TableCell>
                      <TableCell>Toneladas por Volumen (m3)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsInfo.map((row, index) => (
                      <TableRow key={row.index}>
                        <TableCell>{row.tipoRSD}</TableCell>
                        <TableCell>{row.subtipoRSD}</TableCell>
                        <TableCell>{row.toneladasPorVolumen}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <p>
                Nota: La conversión considera la <i>densidad aparente</i> por la
                existencia de aire o "factor de esponjamiento" al momento de
                disponer los residuos, salvo donde se indica con *, en cuyos
                casos se utiliza la densidad del material. Fuente: NCh3562 y
                WRAP (2014) Waste & Resources Programme.
              </p>
              <p>s/i = sin información por el momento.</p>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            spacing={4}
            alignItems="center"
            direction="row"
            justifyContent="flex-between"
          >
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <ButtonComponent
                onClick={handleClickClose}
                className="btn btn-gray"
              >
                Cerrar
              </ButtonComponent>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
