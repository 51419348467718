import axios from 'axios';
import Qs from 'qs';
import URLS from '../URLS';
import storeMessages from '../states/alertMessages/store';
import store from '../../src/states/store';

const tokenExpired = localStorage.getItem('tokenExpired');
const API = {
  axios: axios.create({
    baseURL: URLS.baseURL,
    withCredentials: false,
    paramsSerializer: function (params) {
      return Qs.stringify(params, { arrayFormat: 'repeat' });
    }
  })
};

API.axios.interceptors.response.use(
  response => {
    // almacena el nuevo token en el localstorage con y sin interceptor
    response.headers.uid && store.loguedUser.setToken(response.headers.uid);

    if (response.config.interceptor) {
      if (response.headers.uid) {
        store.loguedUser.setToken(response.headers.uid);
      }
      if (response.data && response.data.error) {
        storeMessages.message.setApiMessage({
          codigoError: response.data.error.codigo
        });
        if (response.data.error === 'UV403-1') {
          store.loguedUser.requestNewLogin(null);
          store.loguedUser.logout();
        }
      }
    }

    return response;
  },
  error => {
    try {
      const codigoError = error.response.data.error.codigo;

      // TODO: ojo el servicio esta respondiendo un 403 y no 401
      if (codigoError === 'UV403-2') {
        console.log('credenciales invalidas');

        storeMessages.message.setApiMessage({
          codigoError
        });
        // se asigna el valor de invalidCredentials a true para que no se vuelva a ejecutar este bloque
        localStorage.setItem('invalidCredentials', true);

        // Limpiar el estado y realizar acciones adicionales
        store.loguedUser.requestNewLogin(null);

        return Promise.reject(error);
      }

      // Si el servicio arroja un codigo 403, se valida token y se cierra sesión si es necesario
      if (codigoError === 'UV403-1' && !tokenExpired) {
        console.log('Token expirado');

        storeMessages.message.setApiMessage({
          codigoError
        });

        // Evitar ejecutar el bloque nuevamente
        localStorage.setItem('tokenExpired', true);

        // Limpiar el estado y realizar acciones adicionales
        store.loguedUser.requestNewLogin(null);
        store.loguedUser.logout();

        return Promise.reject(error);
      }

      if (error.response.config.interceptor) {
        if (error.response) {
          if (
            error.response.status === 401 &&
            error.response.data === 'Credenciales_inválidas'
          ) {
            store.loguedUser.requestNewLogin(null);
          } else if (error.response.status === 404) {
            storeMessages.message.setApiMessage({
              codigoError: error.response.data?.error?.codigo || 'UV400'
            });
          } else {
            if (error.response.data.error.codigo) {
              storeMessages.message.setApiMessage({
                codigoError: error.response.data.error.codigo
              });
            }
            // if (error.response.data.error.codigo === 'UV403-1') {
            //   store.loguedUser.requestNewLogin(null);
            //   storeMessages.message.setApiMessage({
            //     codigoError: error.response.data?.error?.codigo
            //   });
            // }
          }
        } else {
          storeMessages.message.setApiMessage({
            codigoError: 'UV404'
          });
        }
      }
      return Promise.reject(error);
    } catch (error) {
      return Promise.reject(null);
    }
  }
);

export default API;
