import { Menu } from "@mui/icons-material";
import { Drawer, IconButton } from "@mui/material";
import { useState } from "react";

export const ResponsiveButtonMenu = ({ children }) => {

   const [state, setState] = useState({ left: false });
   const toggleDrawer = (anchor, open) => event => {
      if (
         event.type === 'keydown' &&
         (event.key === 'Tab' || event.key === 'Shift')
      ) {
         return;
      }
      setState({ ...state, [anchor]: open });
   };

   return (
      <>
         <IconButton
            color="inherit"
            onClick={toggleDrawer('left', true)}
         >
            <Menu />
         </IconButton>
         <Drawer
            anchor="left"
            open={state['left']}
            onClose={toggleDrawer('left', false)}
         >
            {children}
         </Drawer>
      </>
   )
};