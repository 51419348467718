export const OrdenarAscendente = ( arreglo, propiedad, tipo = 'string') => {
  return arreglo.sort((a , b) => {

    if(tipo === 'string'){
      let nombreA = a[propiedad].toUpperCase();
      let nombreB = b[propiedad].toUpperCase();
      return (nombreA < nombreB ? -1 : 1 )
    }
    
    return ( a < b ? -1 : 1 )
  })

}