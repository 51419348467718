import { Switch, Redirect } from 'react-router-dom';
import { RouteWithLayout } from '../components';
import { Main as MainLayout } from 'layouts';
import { RUTAS } from 'const/Dictonary';
import {
  NotFound as NotFoundView,
  /* Usuario */
  ListadoResiduos as ListadoResiduosView,
  IngresarResiduo as IngresarResiduoView,
  EditarResiduo as EditarResiduoView,
  DuplicarResiduo as DuplicarResiduoView,

  /* Admin */
  // ListadoEmpresas as ListadoEmpresasView,
  CargarDifusion as CargarDifusionView,

  /* LOG Actividades */
  ListadoActividad as ListadoActividadView,

  /* Modulo de difusión */
  BuscarDifusion as BuscarDifusionView,

  /* Transacciones */
  // Transacciones as TransaccionesView,
  Resumen as ResumenTransaccionesView,

  /* V2 */
  HomeUser,
  BusinessNew,
  BusinessEdit,
  Insight,
  IndicadoresListaDatos,
  InsightEdit,
  MarketplaceResiduos,
  UsuariosInvitar,
  Usuarios,
  UsuariosEditar,
  Sucursales,
  SucursalesForm,
  MiCuenta,
  Negocios,
  Reportes,
  V2Chat,
  ATerritorialEmpresas,
  PotencialSinergias,
  TransaccionesNew,
  GestionTerritorial,
  ListaEmpresasAT,
  ListaResiduosAT,
  IngresarEmpresaAT,
  IngresarResiduoAT,
  EditarEmpresaAT,
  EditarResiduoAT,
  ResiduoDeclarado,
  IngresarResiduoDeclarado,
  EditarResiduoDeclarado,
  DuplicarResiduoDeclarado,
  RecursoAdquirido,
  IngresarRecursoAdquirido,
  EditarRecursoAdquirido,
  DuplicarRecursoAdquirido,
  VerificaUsuarioSucursal,
  VerificaUsuario,
  ConfirmEmail,
} from 'views';
import { CalculadoraIndicadores } from 'views/Indicadores/Calculadora/CalculadoraIndicadores';

export const RutasPrivadas = ({ store }) => {
  return (
    // Rutas publicas para usuarios no logueados
    // Rutas privadas para usuarios logueados
    <Switch>
      {/* PAGINA NO ENCONTRADA */}
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MainLayout}
        path={RUTAS.NOT_FOUND}
      />

      {/* Listado 
      
      RESIDUOS Nuevo */}
      <RouteWithLayout
        component={ListadoResiduosView}
        exact
        layout={MainLayout}
        path={RUTAS.RESIDUOS}
      />
      {/* RESIDUOS INGRESAR */}
      <RouteWithLayout
        component={IngresarResiduoView}
        exact
        layout={MainLayout}
        path={RUTAS.RESIDUOS_INGRESAR}
      />

      {/* RESIDUOS EDITAR */}
      <RouteWithLayout
        component={EditarResiduoView}
        exact
        layout={MainLayout}
        path={RUTAS.RESIDUOS_EDITAR}
      />

      {/* RESIDUOS DUPLICAR */}
      <RouteWithLayout
        component={DuplicarResiduoView}
        exact
        layout={MainLayout}
        path={RUTAS.RESIDUOS_DUPLICAR}
      />

      {/* ADMIN */}
      <RouteWithLayout
        component={ListadoActividadView}
        exact
        store={store}
        layout={MainLayout}
        path={RUTAS.ADMINISTRAR_LOGS_ACTIVIDAD}
      />

      {/* DIFUSION */}
      <RouteWithLayout
        component={CargarDifusionView}
        exact
        store={store}
        layout={MainLayout}
        path={RUTAS.MATERIAL_DIFUSION_CARGAR}
      />
      <RouteWithLayout
        component={CargarDifusionView}
        exact
        store={store}
        layout={MainLayout}
        path={RUTAS.MATERIAL_DIFUSION_EDITAR}
      />
      <RouteWithLayout
        component={BuscarDifusionView}
        exact
        store={store}
        layout={MainLayout}
        path={RUTAS.MATERIAL_DIFUSION}
      />

      {/* MI CUENTA */}
      <RouteWithLayout
        component={MiCuenta}
        exact
        store={store}
        layout={MainLayout}
        path={RUTAS.EDITAR_CUENTA}
      />

      {/* TRANSACCIONES */}
      <RouteWithLayout
        component={TransaccionesNew}
        exact
        store={store}
        layout={MainLayout}
        path={RUTAS.TRANSACCIONES}
      />
      <RouteWithLayout
        component={ResumenTransaccionesView}
        exact
        store={store}
        layout={MainLayout}
        path={RUTAS.TRANSACCIONES_RESUMEN}
      />

      {/* CHAT */}
      <RouteWithLayout
        component={V2Chat}
        exact
        store={store}
        layout={MainLayout}
        path={RUTAS.CHAT_ROOM}
      />

      {/* ROUTES V2 New design. */}

      {/* NEGOCIO */}
      <RouteWithLayout
        component={Negocios}
        exact
        store={store}
        layout={MainLayout}
        path={RUTAS.ADMINISTRAR_NEGOCIOS}
      />
      <RouteWithLayout
        component={BusinessNew}
        exact
        layout={MainLayout}
        path={RUTAS.REGISTRAR_NEGOCIO}
      />
      <RouteWithLayout
        component={BusinessEdit}
        exact
        layout={MainLayout}
        path={RUTAS.EDITAR_NEGOCIO}
      />

      {/* INDICADORES */}
      <RouteWithLayout
        component={IndicadoresListaDatos}
        layout={MainLayout}
        exact
        path={RUTAS.INDICADORES_DATOS}
      />
      <RouteWithLayout
        component={InsightEdit}
        exact
        layout={MainLayout}
        path={RUTAS.INDICADORES_EDITAR}
      />
      <RouteWithLayout
        component={Insight}
        exact
        layout={MainLayout}
        path={RUTAS.INDICADORES}
      />

      <RouteWithLayout
        component={CalculadoraIndicadores}
        exact
        layout={MainLayout}
        path={RUTAS.FORMULARIO_CALCULO_INDICADORES}
      />

      {/* MARKETPLACE */}
      <RouteWithLayout
        component={MarketplaceResiduos}
        exact
        store={store}
        layout={MainLayout}
        path={RUTAS.MARKETPLACE_RESIDUOS}
      />
      {/* USUARIOS */}
      <RouteWithLayout
        component={Usuarios}
        exact
        store={store}
        layout={MainLayout}
        path={RUTAS.USUARIOS}
      />
      <RouteWithLayout
        component={UsuariosInvitar}
        exact
        store={store}
        layout={MainLayout}
        path={RUTAS.INVITAR_USUARIOS}
      />
      <RouteWithLayout
        component={UsuariosEditar}
        exact
        store={store}
        layout={MainLayout}
        path={RUTAS.USUARIOS_EDITAR}
      />

      {/* SUCURSALES */}
      <RouteWithLayout
        component={Sucursales}
        exact
        store={store}
        layout={MainLayout}
        path={RUTAS.SUCURSALES}
      />

      <RouteWithLayout
        component={SucursalesForm}
        exact
        store={store}
        layout={MainLayout}
        path={RUTAS.SUCURSALES_EDITAR}
      />

      {/* REPORTES */}
      <RouteWithLayout
        component={Reportes}
        exact
        layout={MainLayout}
        path={RUTAS.EXPORTAR_REPORTES}
      />

      <RouteWithLayout
        component={ATerritorialEmpresas}
        exact
        layout={MainLayout}
        path={RUTAS.ANALISIS_TERRITORIAL_BASE}
      />
      <RouteWithLayout
        component={PotencialSinergias}
        exact
        layout={MainLayout}
        path={RUTAS.ANALISIS_TERRITORIAL_SINERGIAS}
      />

      {/* CARGA MASIVA */}
      <RouteWithLayout
        component={GestionTerritorial}
        exact
        layout={MainLayout}
        path={RUTAS.GESTION_TERRITORIAL}
      />

      {/* Lista Empresas AT */}
      <RouteWithLayout
        component={ListaEmpresasAT}
        exact
        layout={MainLayout}
        path={RUTAS.LISTA_EMPRESAS_AT}
      />

      {/* Ingresar Empresa AT */}
      <RouteWithLayout
        component={IngresarEmpresaAT}
        exact
        layout={MainLayout}
        path={RUTAS.INGRESAR_EMPRESA_AT}
      />

      {/* Editar Empresa AT */}
      <RouteWithLayout
        component={EditarEmpresaAT}
        exact
        layout={MainLayout}
        path={RUTAS.EDITAR_EMPRESA_AT}
      />

      {/* Lista Residuos AT */}
      <RouteWithLayout
        component={ListaResiduosAT}
        exact
        layout={MainLayout}
        path={RUTAS.LISTA_RESIDUOS_AT}
      />

      {/* Ingresar Residuo AT */}
      <RouteWithLayout
        component={IngresarResiduoAT}
        exact
        layout={MainLayout}
        path={RUTAS.INGRESAR_RESIDUO_AT}
      />

      {/* Editar Residuo AT */}
      <RouteWithLayout
        component={EditarResiduoAT}
        exact
        layout={MainLayout}
        path={RUTAS.EDITAR_RESIDUO_AT}
      />

      {/* Lista de Residuos declarados */}
      <RouteWithLayout
        component={ResiduoDeclarado}
        exact
        layout={MainLayout}
        path={RUTAS.GESTIONA_RESIDUO_DECLARADO}
      />

      {/* Ingresar Residuos declarados */}
      <RouteWithLayout
        component={IngresarResiduoDeclarado}
        exact
        layout={MainLayout}
        path={RUTAS.INGRESAR_RESIDUO_DECLARADO}
      />

      {/* Editar Residuos declarados */}
      <RouteWithLayout
        component={EditarResiduoDeclarado}
        exact
        layout={MainLayout}
        path={RUTAS.EDITAR_RESIDUO_DECLARADO}
      />

      {/* Duplicar Residuos declarados */}
      <RouteWithLayout
        component={DuplicarResiduoDeclarado}
        exact
        layout={MainLayout}
        path={RUTAS.DUPLICAR_RESIDUO_DECLARADO}
      />

      {/* Lista de Recursos Adquiridos */}
      <RouteWithLayout
        component={RecursoAdquirido}
        exact
        layout={MainLayout}
        path={RUTAS.GESTIONA_RECURSO_ADQUIRIDO}
      />

      {/* Ingresar Recursos Adquiridos */}
      <RouteWithLayout
        component={IngresarRecursoAdquirido}
        exact
        layout={MainLayout}
        path={RUTAS.INGRESAR_RECURSO_ADQUIRIDO}
      />

      {/* Editar Recursos Adquiridos */}
      <RouteWithLayout
        component={EditarRecursoAdquirido}
        exact
        layout={MainLayout}
        path={RUTAS.EDITAR_RECURSO_ADQUIRIDO}
      />

      {/* Duplicar Recursos Adquiridos */}
      <RouteWithLayout
        component={DuplicarRecursoAdquirido}
        exact
        layout={MainLayout}
        path={RUTAS.DUPLICAR_RECURSO_ADQUIRIDO}
      />

      {/* HOME USUARIO */}
      <RouteWithLayout
        component={HomeUser}
        exact
        layout={MainLayout}
        path={RUTAS.HOME_USER}
      />
      {/* /verifica-cuenta */}
      <RouteWithLayout
        component={ConfirmEmail}
        exact
        layout={MainLayout}
        path={RUTAS.VERIFICAR_CUENTA}
      />
      {/* /verifica-usuario */}
      <RouteWithLayout
        component={VerificaUsuario}
        exact
        layout={MainLayout}
        path={RUTAS.VERIFICAR_USUARIO}
      />
      {/* /verifica-usuario-sucursal */}
      <RouteWithLayout
        component={VerificaUsuarioSucursal}
        exact
        layout={MainLayout}
        path={RUTAS.VERIFICAR_USUARIO_SUCURSAL}
      />
      {/* HOME  */}
      <RouteWithLayout
        component={HomeUser}
        exact
        layout={MainLayout}
        path={RUTAS.HOME}
      />

      <RouteWithLayout
        component={() => <h1>403</h1>}
        exact
        layout={MainLayout}
        path={RUTAS.NOT_ALLOWED}
      />

      <Redirect to={RUTAS.NOT_FOUND} />
    </Switch>
  );
};
