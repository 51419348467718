import React, { useState } from 'react';

import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import Hidden from '@mui/material/Hidden';
import StepperComponent from 'components/design-system/Stepper/Stepper';
import { Close, NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Divider } from '@mui/material';
import storeMessages from 'states/alertMessages/store';
// Formik
import { Formik, Form } from 'formik';

// Steppers
import {
  BusinessCertifications,
  BusinessInfo,
  BusinessRole,
  BusinessRSD,
  BusinessSuccess,
  BusinessType
} from './steppers';

import InitialFormValues from './data/initalValues';
import { schema } from './schema/Schema';

import { useHistory } from 'react-router-dom';
import { RUTAS } from 'const/Dictonary';

// Services
import serviceEmpresas from 'services/empresas';
import serviceDocumentos from 'services/documentos';

import { stepsIngresar } from './helpers';
import { refrescaUsuario } from '../../hooks';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
// import { GuardaArchivos } from 'views/Business/hooks/useServicios';

const NewBusiness = () => {
  const history = useHistory();
  // Inicia con estado = 0
  const [step, setStep] = useState(0);

  const [stateValidation, setStateValidation] = useState(false);
  const [estadoServicio, setEstadoServicio] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [mensajeError, setMensajeError] = useState('');
  const [errorService, setErrorService] = useState(false);

  const [steppers, setSteppers] = useState(stepsIngresar);

  const switchSteps = props => {
    // eslint-disable-next-line default-case
    switch (props.step) {
      case 0:
        return (
          <BusinessType
            values={props.values}
            errors={props.errors}
            touched={props.touched}
            onChange={props.handleChange}
            handleBlur={props.handleBlur}
            handleReset={props.handleReset}
            setFieldValue={props.setFieldValue}
            setFieldTouched={props.setFieldTouched}
            setFieldError={props.setFieldError}
          />
        );
      case 1:
        return (
          <BusinessInfo
            values={props.values}
            errors={props.errors}
            touched={props.touched}
            onChange={props.handleChange}
            handleBlur={props.handleBlur}
            setFieldValue={props.setFieldValue}
            setFieldTouched={props.setFieldTouched}
            setFieldError={props.setFieldError}
          />
        );
      case 2:
        return (
          <BusinessRole
            values={props.values}
            errors={props.errors}
            touched={props.touched}
            onChange={props.handleChange}
            handleBlur={props.handleBlur}
            setFieldValue={props.setFieldValue}
            setFieldTouched={props.setFieldTouched}
            setFieldError={props.setFieldError}
            steppers={steppers}
            setSteppers={setSteppers}
          />
        );
      case 3:
        return (
          <BusinessRSD
            setFieldValue={props.setFieldValue}
            values={props.values}
            errors={props.errors}
            touched={props.touched}
            onChange={props.handleChange}
            handleBlur={props.handleBlur}
            setFieldTouched={props.setFieldTouched}
            setFieldError={props.setFieldError}
            setStep={props.setStep}
            handleSubmit={props.handleSubmit}
          />
        );
      case 4:
        return (
          <BusinessCertifications
            setFieldValue={props.setFieldValue}
            values={props.values}
            errors={props.errors}
            touched={props.touched}
            onChange={props.handleChange}
            handleBlur={props.handleBlur}
            setFieldTouched={props.setFieldTouched}
            setFieldError={props.setFieldError}
            setStep={props.setStep}
            handleSubmit={props.handleSubmit}
          />
        );
      case 5:
        return (
          <div className="px-4 md:container md:mx-auto">
            <BusinessSuccess
              setFieldValue={props.setFieldValue}
              values={props.values}
              errors={props.errors}
              touched={props.touched}
              onChange={props.handleChange}
              handleBlur={props.handleBlur}
              setFieldTouched={props.setFieldTouched}
              setFieldError={props.setFieldError}
              isLoading={isLoading}
              errorService={props.errorService}
              mensajeError={props.mensajeError}
            />
          </div>
        );
    }
  };

  return (
    <SidebarLayout>
      <Formik
        initialValues={InitialFormValues}
        validationSchema={schema[step]}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setIsLoading(true);
          console.log('submiting final..');
          // cambio de formato
          const codigoRolesEmpresa = values.codigoRolesEmpresa.map(cadena =>
            parseInt(cadena)
          );
          const codigosTipoResiduo = values.codigosTipoResiduo.map(cadena =>
            parseInt(cadena)
          );

          let respRquest = {};
          const formatoArchivos = values.archivos.map(archivo => {
            return {
              nombreArchivo: archivo.nombreArchivo,
              base64: archivo.base64.split(';base64,').pop(),
              tipoArchivo: archivo.tipoArchivo
            };
          });

          if (values.tipoNegocio === 'empresa') {
            respRquest = {
              tipoNegocio: values.tipoNegocio,
              razonSocial: values.razonSocial,
              rutEmpresa: values.rutEmpresa,
              codigoRolesEmpresa: codigoRolesEmpresa,
              codigoComuna: values.comuna.codigoComuna,
              nombreVia: values.nombreVia,
              pisoBloque: '',
              latitudDireccion: values.latitudDireccion,
              longitudDireccion: values.longitudDireccion,
              codigoActividad: values.actividad.codigoCiuActividad,
              codigosTipoResiduo: codigosTipoResiduo
              // archivos: formatoArchivos ? formatoArchivos : []
            };
          } else if (values.tipoNegocio === 'persona-natural') {
            respRquest = {
              tipoNegocio: values.tipoNegocio,
              nombreEmpresa: values.nombre,
              codigoRolesEmpresa: codigoRolesEmpresa,
              codigoComuna: values.comuna.codigoComuna,
              nombreVia: values.nombreVia,
              pisoBloque: '',
              latitudDireccion: values.latitudDireccion,
              longitudDireccion: values.longitudDireccion,
              codigosTipoResiduo: codigosTipoResiduo
              // archivos: formatoArchivos ? formatoArchivos : []
            };
          }
          // console.log('respRquest ::> ', respRquest);
          // console.log('values ::> ', values);

          try {
            await serviceEmpresas
              .empresa(respRquest)
              .then(response => {
                refrescaUsuario();
                setEstadoServicio(true);
                setErrorService(false);
                storeMessages.message.setMessage({
                  mensaje: 'Se ha ingresado correctamente el negocio',
                  tipo: 'exito'
                });
                // console.log(response.data);
                const { codigoEmpresa } = response.data;

                const dataArchivos = {
                  codigoEmpresa: codigoEmpresa,
                  archivos: formatoArchivos ? formatoArchivos : []
                };
                // Servicios Archivos
                try {
                  setIsLoading(true);
                  const response = serviceDocumentos.guardar(dataArchivos);
                  if (response.status === 200) {
                    const data = response.data;
                    console.log(data);
                    storeMessages.message.setMessage({
                      mensaje: 'Se han guardado los archivos correctamente',
                      tipo: 'exito'
                    });
                  }
                } catch (error) {
                  console.log(error);
                  storeMessages.message.setMessage({
                    mensaje:
                      'Se ha producido un error al guardar los archivos, Si el problema persiste comunícate con el administrador del sitio',
                    tipo: 'error'
                  });
                } finally {
                  setIsLoading(false);
                }
              })
              .catch(error => {
                if (error.response.data.error) {
                  console.log(
                    'error.response.data.error :>> ',
                    error.response.data.error
                  );
                  setMensajeError(error.response.data.error);
                }
                setErrorService(true);
                setEstadoServicio(false);
              });
          } catch (error) {
            setErrorService(true);
            setEstadoServicio(false);
            storeMessages.message.setMessage({
              mensaje:
                'Ha ocurrido un error al crear el negocio vuelve a intentarlo. Si el problema persiste comunícate con el administrador del sitio',
              tipo: 'error'
            });
            console.error('error: ', error);
          } finally {
            setIsLoading(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleReset,
          handleSubmit,
          setFieldValue,
          handleBlur,
          setFieldError,
          setFieldTouched,
          isSubmitting,
          validateForm
        }) => (
          <Form
            onChange={event => {
              // console.log('errors :>> ', errors);
              // console.log('touched :>> ', touched);
              //  console.log('values :>> ', values);
              setStateValidation(true);
            }}
          >
            <div className="px-4 md:container md:mx-auto">
              <Heading type="h2" className="mb-4">
                Registro de negocio o actividad
              </Heading>
              <div className="bg-white py-5 rounded shadow-md">
                <div className="grid grid-cols-12 gap-0 xs:gap-8 pt-2">
                  <Hidden mdDown>
                    <div className="col-span-3 pl-6">
                      <StepperComponent activeStep={step} steps={steppers} />
                    </div>
                  </Hidden>

                  <div className="col-span-12 lg:col-span-9 pl-4 pr-5">
                    {switchSteps({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      handleBlur,
                      setFieldValue,
                      setFieldTouched,
                      setFieldError,
                      handleReset,
                      step,
                      setStep,
                      errorService,
                      mensajeError
                    })}
                  </div>

                  <div className="col-span-12 px-5 pb-4 xs:pb-0">
                    <Divider />
                  </div>

                  <div className="col-span-12 px-5 justify-between">
                    <div className="flex justify-between">
                      <>
                        <ButtonComponent
                          type="secondary"
                          className="justify-center"
                          hidden={step === 5}
                          onClick={() => {
                            // Función Cancelar
                            if (step === 0) history.push(RUTAS.HOME_USER);

                            // Función volver
                            if (step > 0) {
                              window.scrollTo(0, 0);
                              setStep(step - 1);
                            }
                          }}
                          disabled={step === 5}
                        >
                          {step === 0 ? (
                            <>
                              <Hidden smDown>Cancelar</Hidden>
                              <Hidden smUp>
                                <Close />
                              </Hidden>
                            </>
                          ) : (
                            <>
                              <Hidden smDown>
                                <NavigateBefore /> Volver
                              </Hidden>
                              <Hidden smUp>
                                <NavigateBefore />
                              </Hidden>
                            </>
                          )}
                        </ButtonComponent>

                        <ButtonComponent
                          buttonType={step === 5 ? 'submit' : 'button'}
                          hidden={step === 5}
                          onClick={() => {
                            validateForm()
                              .then(resp => {
                                window.scrollTo(0, 0);

                                const validaciones = Object.keys(resp);
                                if (validaciones.length !== 0) {
                                  validaciones.forEach(campo => {
                                    setFieldTouched(campo, true);
                                  });
                                  return;
                                }

                                if (step >= 1 && step < 4) {
                                  setStep(step + 1);
                                } else if (estadoServicio) {
                                  setStep(step + 1);
                                } else {
                                  console.log(
                                    'El servicio no está funcionando correctamente'
                                  );
                                }
                              })
                              .catch(error => {
                                console.log(error);
                              });
                          }}
                          disabled={
                            stateValidation && Object.keys(errors).length === 0
                              ? false
                              : true
                          }
                        >
                          {step === 4 ? (
                            <>Finalizar registro</>
                          ) : (
                            <>
                              Continuar
                              <NavigateNext />
                            </>
                          )}
                        </ButtonComponent>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </SidebarLayout>
  );
};

export default NewBusiness;
