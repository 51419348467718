import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { AppBar, Toolbar, List } from '@mui/material';
import {
  CompareArrows,
  Menu as MenuIcon,
  TrendingUp,
  Search,
  Home,
  HubOutlined,
  Dashboard,
  Link
} from '@mui/icons-material';
import img_logoHeader from '../../../../assets/images/plataforma-industria-circular.svg';
import img_logoHeaderResponsive from '../../../../assets/images/responsive-plataforma-industria-circular.svg';
import store from '../../../../states/store';
import ResponsiveMenu from './menu/ResponsiveMenu';
import SubMenuDifusion from './menu/SubMenuDifusion';
import MiCuenta from './menu/MiCuenta';
import { RUTAS } from 'const/Dictonary';
import Text from 'components/design-system/Text/Text';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import ButtonComponent from 'components/design-system/Button/Button';
import { PopperComponent } from 'components/design-system/Popper/Popper';
import { LoginForm, LoginFormContainer } from './menu/LoginForm';
import ListItemButtonComponent from 'components/design-system/ListItemButton/ListItemButton';
import { ResponsiveButtonMenu } from './menu/ResponsiveButtonMenu';
import { useContext } from 'react';
import { AuthContext } from 'context';
import SubMenuAnalisisTerritorial from './menu/SubMenuAnalisisTerritorial';
import SubMenuTutoriales from './menu/SubMenuTutoriales';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import { useLogout } from 'hooks/useLogout';
import InventoryIcon from '@mui/icons-material/Inventory';
import SubMenuGestiona from './menu/SubMenuGestiona';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Topbar = props => {
  const { history, match, className, ...rest } = props;
  const [formState, setFormState] = useState({});
  const [isLogged, setIsLogged] = useState(false);
  // Logout Context
  const { logout } = useContext(AuthContext);
  /* 
  const { setVisible: setVisibleLoading } =
    storeBackdropLoading.backdropLoading; */

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const classes = useStyles();

  const handleLogout = useLogout();

  useEffect(() => {
    if (store.loguedUser.expiredUserEmail) {
      setFormState(formState => ({
        ...formState,
        email: store.loguedUser.expiredUserEmail
      }));
    }
  }, [store.loguedUser.expiredUserEmail]);

  useEffect(() => {
    setIsLogged(!!store.loguedUser.logeduser);
  }, [store.loguedUser.logeduser]);

  const { isSuperAdmin, isAdmin, isFuncionario, isAnalistaTerritorial } =
    store.loguedUser.perfiles;
  const { isDemandante, isOferente } = store.loguedUser.tipos;
  const tieneNegocio = store.loguedUser?.logeduser?.sucursal ? true : false;

  const disableOpacity = disabled => {
    return {
      opacity: disabled ? 0.5 : 1
    };
  };

  const forceRefresh = to => e => {
    e.preventDefault();
    history.push(to);
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <div className="md:container md:mx-auto py-2 pt-3 pb-4 sm:pt-0 sm:pb-0 w-full">
          <div className="grid grid-cols-12 gap-4 items-center justify-between content-between">
            <div className="col-span-4 lg:col-span-3">
              <RouterLink to={isLogged ? RUTAS.HOME_USER : RUTAS.HOME}>
                <img
                  alt="Logo"
                  src={img_logoHeader}
                  className="hidden sm:block max-w-[220px]"
                />
                <img
                  alt="Logo"
                  src={img_logoHeaderResponsive}
                  className="block sm:hidden"
                />
              </RouterLink>
            </div>

            {isLogged ? (
              <div className="hidden lg:block md:col-span-0 lg:col-span-7">
                <div className="flex justify-center">
                  <RouterLink
                    to={isLogged ? RUTAS.HOME_USER : RUTAS.HOME}
                    onClick={ev => {
                      if (!isLogged) {
                        ev.preventDefault();
                      }
                    }}
                    style={disableOpacity(!isLogged)}
                  >
                    <IconButtonComponent className="text-white">
                      <Home />
                    </IconButtonComponent>
                  </RouterLink>

                  {isAnalistaTerritorial ? null : (
                    <>
                      <PopperComponent
                        Button={
                          <IconButtonComponent
                            className="text-white rounded"
                            aria-controls="conecta-menu"
                            aria-haspopup="true"
                          >
                            <HubOutlined />
                            <Text className="text-white ml-1 font-secondary font-normal">
                              Marketplace
                            </Text>
                          </IconButtonComponent>
                        }
                      >
                        <div className="pop-section-item px-3">
                          <List>
                            <ListItemButtonComponent
                              onClick={forceRefresh(RUTAS.MARKETPLACE_RESIDUOS)}
                              icon={<Search />}
                              label={'Búsqueda'}
                            />

                            {isSuperAdmin || tieneNegocio ? (
                              <ListItemButtonComponent
                                onClick={forceRefresh(RUTAS.TRANSACCIONES)}
                                icon={<CompareArrows />}
                                label={'Transacciones'}
                              />
                            ) : null}

                            {isDemandante || isOferente || isSuperAdmin ? (
                              <ListItemButtonComponent
                                onClick={forceRefresh(RUTAS.RESIDUOS)}
                                icon={<InventoryIcon />}
                                label={'Publicar Residuo-recurso'}
                              />
                            ) : null}
                          </List>
                        </div>
                      </PopperComponent>

                      <PopperComponent
                        Button={
                          <IconButtonComponent
                            className="text-white rounded"
                            aria-controls="gestiona-menu"
                            aria-haspopup="true"
                          >
                            <ManageHistoryIcon />
                            <Text className="text-white ml-1 font-secondary font-normal">
                              Gestiona
                            </Text>
                          </IconButtonComponent>
                        }
                      >
                        <SubMenuDirectorio
                          isSuperAdmin={isSuperAdmin}
                          loguedUser={store.loguedUser}
                          forceRefresh={forceRefresh}
                        />
                      </PopperComponent>
                    </>
                  )}

                  {isAnalistaTerritorial ? null : (
                    <PopperComponent
                      Button={
                        <IconButtonComponent
                          className="text-white rounded"
                          aria-controls="impacta-menu"
                          aria-haspopup="true"
                        >
                          <TrendingUp />
                          <Text className="text-white ml-1 font-secondary font-normal">
                            Reportes
                          </Text>
                        </IconButtonComponent>
                      }
                    >
                      <div className="pop-section-item px-3 max-w-md">
                        <SubMenuDifusion
                          isSuperAdmin={isSuperAdmin}
                          isDemandante={isDemandante}
                          isAdmin={isAdmin}
                          isOferente={isOferente}
                          loguedUser={store.loguedUser}
                          forceRefresh={forceRefresh}
                        />
                      </div>
                    </PopperComponent>
                  )}

                  {isLogged && !isAnalistaTerritorial ? (
                    <>
                      <PopperComponent
                        Button={
                          <IconButtonComponent
                            className="text-white rounded"
                            aria-controls="gestiona-menu"
                            aria-haspopup="true"
                          >
                            <Link />
                            <Text
                              size="M"
                              className="text-white ml-1 font-secondary font-normal"
                            >
                              Tutoriales
                            </Text>
                          </IconButtonComponent>
                        }
                      >
                        <div className="px-3">
                          <SubMenuTutoriales
                            isSuperAdmin={isSuperAdmin}
                            isDemandante={isDemandante}
                            isAdmin={isAdmin}
                            isOferente={isOferente}
                            loguedUser={store.loguedUser}
                            forceRefresh={forceRefresh}
                            isFuncionario={isFuncionario}
                          />
                        </div>
                      </PopperComponent>
                    </>
                  ) : null}

                  {isSuperAdmin || isAnalistaTerritorial ? (
                    <PopperComponent
                      Button={
                        <IconButtonComponent
                          className="text-white rounded"
                          aria-controls="gestiona-menu"
                          aria-haspopup="true"
                        >
                          <Dashboard />
                          <Text className="text-white ml-1 font-secondary font-normal">
                            {isSuperAdmin
                              ? 'A.Territorial'
                              : 'Análisis Territorial'}
                          </Text>
                        </IconButtonComponent>
                      }
                    >
                      <div className="pop-section-item px-3">
                        <SubMenuAnalisisTerritorial
                          isSuperAdmin={isSuperAdmin}
                          forceRefresh={forceRefresh}
                          isAnalistaTerritorial={isAnalistaTerritorial}
                        />
                      </div>
                    </PopperComponent>
                  ) : null}
                </div>
              </div>
            ) : null}
            <div
              className={`justify-end flex ${
                isLogged
                  ? 'col-span-8 lg:col-span-2'
                  : 'col-span-8 lg:col-span-9'
              } `}
            >
              {store.loguedUser.logeduser ? (
                <MiCuenta
                  logeduser={store.loguedUser.logeduser}
                  handleLogout={handleLogout}
                  forceRefresh={forceRefresh}
                  history={history}
                  match={match}
                  isSuperAdmin={isSuperAdmin}
                />
              ) : (
                <div className="loginMenu d-hidden flex">
                  <LoginFormContainer>
                    <LoginForm
                      handleChange={handleChange}
                      formState={formState}
                      setFormState={setFormState}
                    />
                  </LoginFormContainer>
                  <RouterLink to={RUTAS.REGISTRARSE}>
                    <ButtonComponent
                      type="ghost"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      className="text-white hover:text-uv-primary-0 !px-2 sm:!px-4 !text-xs sm:text-sm"
                    >
                      Registrarse
                    </ButtonComponent>
                  </RouterLink>
                </div>
              )}
              <div className="lg:hidden">
                {isLogged && (
                  <ResponsiveButtonMenu>
                    <ResponsiveMenu
                      isAdmin={isAdmin}
                      isSuperAdmin={isSuperAdmin}
                      isDemandante={isDemandante}
                      isOferente={isOferente}
                      forceRefresh={forceRefresh}
                      loguedUser={store.loguedUser}
                      handleLogout={handleLogout}
                      isFuncionario={isFuncionario}
                      isLogged={isLogged}
                      disableOpacity={disableOpacity}
                      isAnalistaTerritorial={isAnalistaTerritorial}
                      tieneNegocio={tieneNegocio}
                    />
                  </ResponsiveButtonMenu>
                )}
              </div>
            </div>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

const SubMenuDirectorio = React.forwardRef(
  ({ loguedUser, forceRefresh, isSuperAdmin }, ref) => {
    const tieneNegocio = loguedUser.logeduser.sucursal ? true : false;
    return (
      <div className="px-3 max-w-md">
        <div className="grid grid-cols-12 gap-5">
          <div className="col-span-12">
            <div className="pop-section-item">
              <SubMenuGestiona
                isSuperAdmin={isSuperAdmin}
                forceRefresh={forceRefresh}
                tieneNegocio={tieneNegocio}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default Topbar;

Topbar.propTypes = {
  className: PropTypes.string
};
