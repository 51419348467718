import API from './api';
import store from '../states/store.js';

const indicadores = {
  disponibilidadCalculadora: function (conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/indicadores/disponibilidad-calculadora',
      interceptor: conInterceptor,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      }
    });
  }
};

export default indicadores;
