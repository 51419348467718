export const formeateaDireccion = object => {
  if (!object || !Object.hasOwn(object, 'direccion')) {
    return '';
  }

  let direccion = '';

  if (
    Object.hasOwn(object.direccion, 'nombreVia') &&
    object.direccion.nombreVia
  ) {
    direccion += object.direccion.nombreVia;
  }

  if (Object.hasOwn(object.direccion, 'numero') && object.direccion.numero) {
    direccion += ' #' + object.direccion.numero + ', ';
  }

  if (
    Object.hasOwn(object.direccion, 'comuna') &&
    Object.hasOwn(object.direccion.comuna, 'nombreComuna') &&
    object.direccion.comuna.nombreComuna
  ) {
    direccion += object.direccion.comuna.nombreComuna + ', ';
  }

  if (
    Object.hasOwn(object.direccion, 'comuna') &&
    Object.hasOwn(object.direccion.comuna, 'region') &&
    Object.hasOwn(object.direccion.comuna.region, 'nombreRegion') &&
    object.direccion.comuna.region.nombreRegion
  ) {
    direccion += object.direccion.comuna.region.nombreRegion;
  }

  return direccion;
};
