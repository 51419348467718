import { useEffect, useState } from "react";
import { Clear, Done } from "@mui/icons-material";
import Loading from "components/Loading/Loading";
import Text from "components/design-system/Text/Text";

export const EnvioFormulario = ({
  errors,
  handleBlur,
  handleChange,
  handleReset,
  handleSubmit,
  isSubmitting,
  setFieldError,
  setFieldTouched,
  setFieldValue,
  touched,
  validateForm,
  values,
  // Props adicionales
  estadoGuardado,
  isloading,
  tipoFormularioSeleccionado,
}) => {

  const [statusText,
    // setStatusText
  ] = useState('Guardando...');

  useEffect(() => {
    if(!estadoGuardado) return;

    console.log(estadoGuardado);
    return () => {
      // console.log('unmounting envio formulario...');
    }
  }, [estadoGuardado])
  

  return isloading ? (
    <Loading />
  ) : (
    <div className="container m-auto">
      <div className="col-12 text-center">
      {estadoGuardado ? (
        <div className="py-32  ">
            <Text size="M" className="">
              <Clear className="mr-05 text-red-300" />
               {`No se ha podido ${tipoFormularioSeleccionado.ingresar ? 'ingresar' : tipoFormularioSeleccionado.editar ? 'editar' : 'duplicar'} el residuo`}
             </Text>
         </div>
      ) : (
        <div className="py-32">
          <Text size="M" className="">
            <Done className="mr-05 text-green-300" />
            {` Residuo ${tipoFormularioSeleccionado.ingresar ? 'ingresado' : tipoFormularioSeleccionado.editar ? 'actualizado' : 'duplicado'} correctamente`}
          </Text>
        </div>
      )}  
      </div>
    </div>
 
  );
};
