import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
// Layouts
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
// Design System
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import Text from 'components/design-system/Text/Text';
// Material UI
import { ChevronLeft, NavigateBefore, NavigateNext } from '@mui/icons-material';

// Refactoring
import { CamposResiduosFormik } from './CamposResiduosFormik';

// Steps
import { stepsLine } from 'views/Residuos/data/stepsLine';
import { SteppersComponent } from '../Steppers/Steppers';

// Const
import { DICTONARY, RUTAS } from 'const/Dictonary';
import { useSubmitResiduos } from 'views/Residuos/hooks/Submit/useSubmitResiduos';
import Loading from 'components/Loading/Loading';
import { SchemaDemandante } from 'views/Residuos/schema/SchemaResiduoDemandante';
import { SchemaOferente } from 'views/Residuos/schema/SchemaResiduoOferente';
import { SaltoLinia } from 'views/Residuos/utils/utils';

const ResiduosFormik = () => {
  let params = useParams();
  let { nombreRol, tipoForm, codigoResiduo } = params;

  const navigate = useHistory();

  // Steps formulario
  const [step, setStep] = useState(1);

  const [estadoServicio, setEstadoServicio] = useState(true);
  const [operacionFinalizada, setOperacionFinalizada] = useState(false);

  // Tipo de formulario
  const [tipoFormulario, setTipoFormulario] = useState(tipoForm || '');

  // Tipos de Formulario
  const tiposPosibles = ['ingresar', 'editar', 'duplicar'];
  const tipoFormularioSeleccionado = tiposPosibles.reduce((obj, tipo) => {
    obj[tipo] = tipoFormulario === tipo;
    return obj;
  }, {});

  // Validar si el rol es oferente o demandante
  const isNombreRolOferente   = nombreRol   === DICTONARY.ROL.GENERADOR.NOMBRE ? true : false;
  const isNombreRolDemandante = nombreRol   === DICTONARY.ROL.RECEPTOR.NOMBRE ? true : false;

  useEffect(() => {
    return () => {
      // console.log('unmounting formik ...');
    };
  }, [isNombreRolDemandante, isNombreRolOferente, nombreRol, tipoForm]);

  const {
    residuoDemandante,
    setResiduoDemandante,
    residuoOferente,
    setResiduoOferente,
    isLoading,
    setIsLoading,
    handleSubmit,
    cargaResiduo,
    estadoGuardado
  } = useSubmitResiduos(codigoResiduo, nombreRol);

  useEffect(() => {
    if (!codigoResiduo) return;
    cargaResiduo();
  }, [cargaResiduo, codigoResiduo, nombreRol]);

  if (isLoading) return <Loading />;

  return (
    <SidebarLayout>
      <Heading type="h2" className="mb-4 text-lg md:text-2xl">
        <div className="flex items-center">
          <IconButtonComponent
            onClick={() => navigate.goBack()}
            type="secondary"
            className="mr-2"
          >
            <ChevronLeft />
          </IconButtonComponent>
          <span>
            {` 
           ${
             tipoFormularioSeleccionado.ingresar
               ? 'Ingreso de '
               : tipoFormularioSeleccionado.editar
               ? 'Edición de '
               : tipoFormularioSeleccionado.duplicar
               ? 'Duplicación de '
               : ''
           }
            Residuo
             `}
            <Text>{/* {nombreRol} */}</Text>
          </span>
        </div>
      </Heading>

      {/* Stepper */}
      {isNombreRolOferente ? (
        <SteppersComponent
          stepsLine={stepsLine}
          step={step}
          setStep={setStep}
        />
      ) : null}

      <Formik
        initialValues={
          isNombreRolOferente ? residuoOferente : residuoDemandante
        }
        validationSchema={
          isNombreRolOferente ? SchemaOferente[step] : SchemaDemandante
        }
        validateOnChange={true}
        validateOnBlur={true}
        validateOnMount={true}
        onSubmit={async (values, { resetForm }) => {
          console.log('submit finalizado ::>');
          handleSubmit(values, tipoFormularioSeleccionado);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleReset,
          handleSubmit,
          isSubmitting,
          setFieldError,
          setFieldTouched,
          setFieldValue,
          touched,
          validateForm,
          values
        }) => (
          <Form
            id={`form-${tipoForm}-residuo-${nombreRol}`}
            onChange={event => {
              //  console.log(errors);
              //  console.log(values);
            }}
            className="bg-white rounded-lg shadow p-5 mb-8"
          >
            <CamposResiduosFormik
              codigoResiduo={codigoResiduo}
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleReset={handleReset}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              setFieldError={setFieldError}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              touched={touched}
              validateForm={validateForm}
              values={values}
              // Props adicionales
              estadoGuardado={estadoGuardado}
              isLoading={isLoading}
              step={step}
              setStep={setStep}
              setOperacionFinalizada={setOperacionFinalizada}
              setEstadoServicio={setEstadoServicio}
              isNombreRolOferente={isNombreRolOferente}
              isNombreRolDemandante={isNombreRolDemandante}
              tipoFormulario={tipoFormulario}
              tipoFormularioSeleccionado={tipoFormularioSeleccionado}
            />
            <SaltoLinia />
            <div className="col-span-12 justify-between mt-2">
              {isNombreRolOferente ? (
                <div
                  className={
                    step !== 4 ? `flex justify-between` : `flex justify-end`
                  }
                >
                  <ButtonComponent
                    type="secondary"
                    className="mt-3 mb-3"
                    hidden={step === 4}
                    onClick={() => {
                      // Función Cancelar
                      if (step === 1) navigate.push(RUTAS.RESIDUOS);

                      // Función volver
                      if (step > 0) {
                        window.scrollTo(0, 0);
                        setStep(step - 1);
                      }
                    }}
                    disabled={step === 4}
                  >
                    {step === 1 ? (
                      'Cancelar'
                    ) : (
                      <>
                        <NavigateBefore /> Volver
                      </>
                    )}
                  </ButtonComponent>

                  <ButtonComponent
                    buttonType={step === 4 ? 'submit' : 'button'}
                    type="primary"
                    // variant="contained"
                    className="mt-3 mb-3"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      if (step >= 1 && step < 5) {
                        setStep(step + 1);
                        // step.nextStep();
                      } else if (estadoServicio) {
                        setStep(step + 1);
                        // step.nextStep();
                      } else {
                        console.log(
                          'El servicio no está funcionando correctamente'
                        );
                      }
                    }}
                    disabled={Object.keys(errors).length === 0 ? false : true}
                  >
                    {step === 3 ? (
                      <>
                        <span className="pr-2">
                          {tipoFormularioSeleccionado.ingresar
                            ? DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD
                                .INGRESAR + ' residuo'
                            : tipoFormularioSeleccionado.editar
                            ? DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD.EDITAR
                            : tipoFormularioSeleccionado.duplicar
                            ? DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD
                                .DUPLICAR + ' residuo'
                            : ''}{' '}
                        </span>
                        {/* <Save /> */}
                      </>
                    ) : step === 4 ? (
                      <>
                        <span className="pr-2 ">Finalizar</span>
                        {/* <ExitToApp /> */}
                      </>
                    ) : (
                      <>
                        <span className="">Continuar</span>
                        <NavigateNext />
                      </>
                    )}
                  </ButtonComponent>
                </div>
              ) : (
                <div className={`flex justify-between`}>
                  <ButtonComponent
                    type="secondary"
                    className="mt-3 mb-3"
                    onClick={() => {
                      // Función Cancelar
                      if (step === 1) navigate.push(RUTAS.RESIDUOS);

                      // Función volver
                      if (step > 0) {
                        window.scrollTo(0, 0);
                        setStep(step - 1);
                      }
                    }}
                  >
                    {step === 1 ? (
                      'Cancelar'
                    ) : (
                      <>
                        <NavigateBefore /> Volver
                      </>
                    )}
                  </ButtonComponent>
                  <ButtonComponent
                    buttonType={'submit'}
                    type="primary"
                    // variant="contained"
                    className="mt-3 mb-3"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      if (step === 1 && estadoServicio) {
                        setStep(step + 1);
                      } else if (step === 2 && estadoServicio) {
                        navigate.push(RUTAS.RESIDUOS);
                      } else {
                        console.log(
                          'El servicio no está funcionando correctamente'
                        );
                      }
                    }}
                    disabled={Object.keys(errors).length === 0 ? false : true}
                  >
                    {step === 1 ? (
                      <>
                        <span className="pr-2">
                          {tipoFormularioSeleccionado.ingresar
                            ? DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD
                                .INGRESAR + ' residuo'
                            : tipoFormularioSeleccionado.editar
                            ? DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD.EDITAR
                            : tipoFormularioSeleccionado.duplicar
                            ? DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD
                                .DUPLICAR + ' residuo'
                            : ''}{' '}
                        </span>
                        {/* <Save /> */}
                      </>
                    ) : (
                      step === 2 && (
                        <>
                          <span className="pr-2 ">Finalizar</span>
                          {/* <ExitToApp /> */}
                        </>
                      )
                    )}
                  </ButtonComponent>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </SidebarLayout>
  );
};

export default ResiduosFormik;
