import * as yup from 'yup';

const SchemaDemandante = yup.object().shape({
  codigoSucursal: yup
    .number()
    .typeError('Seleccione una sucursal')
    .required('Este campo es obligatorio'),

  nombreResiduo: yup.string().required('Este campo es obligatorio'),

  tipoResiduo: yup
    .object()
    .typeError('Seleccione tipo de residuo')
    .required('Este campo es obligatorio'),

  subTipoResiduo: yup
    .object()
    .typeError('Seleccione un subtipo de residuo')
    .required('Este campo es obligatorio'),

    tipoRecurso: yup.array().when(['tieneTipoRecursos', 'otroTipoRecurso'], {
      is: (tieneTipoRecursos, otroTipoRecurso) => tieneTipoRecursos && !otroTipoRecurso.length,
    then: () => {
      return yup
        .array()
        .of(yup.object())
        .min(1, 'Debe seleccionar al menos un recurso')
        .required('Este campo es obligatorio');
    }
  }),

  otroTipoRecurso: yup.array().when('tieneTipoRecursos', {
    is: false,
    then: () => {
      return yup
        .array()
        .of(yup.object())
        .min(1, 'Debe seleccionar al menos un recurso distinto')
        .required('Este campo es obligatorio');
    }
  }),
  _otraMateriaPrima: yup.string().min(3, 'El nombre es muy corto'),

  recursoReemplazo: yup
    .object()
    .typeError('Seleccione un recurso de reemplazo')
    .required('Este campo es obligatorio'),

  subTipoRecursoReemplazo: yup.object().when('tieneTipoRecursosReemplazo', {
    is: true,
    then: () => {
      return yup
        .object()
        .typeError('Seleccione un subtipo de recurso de reemplazo')
        .required('Este campo es obligatorio');
    }
  }),

  descripcionAdicional: yup
    .string()
    .min(3, 'El nombre es muy corto')
    // .max(100,'El nombre no puede ser tan largo'),
    .required('Este campo es obligatorio')
});
export { SchemaDemandante };
