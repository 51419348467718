import ListSubheaderComponent from 'components/design-system/ListSubheader/ListSubheader';
import ListItemButtonComponent from 'components/design-system/ListItemButton/ListItemButton';
import ListComponent from 'components/design-system/List/List';
import { Divider, List } from '@mui/material';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { CollapseMenu } from 'components/design-system/CollapseMenu/CollapseMenu';
import { SIDEBAR_MENU } from './SidebarMenus';
import store from 'states/store';

const Sidebar = () => {
  const { perfiles, logeduser, tipos } = store.loguedUser;
  const codigoEmpresa = logeduser?.sucursal
    ? logeduser.sucursal.empresa.codigoEmpresa
    : null;
  const tieneNegocio = codigoEmpresa ? true : false;
  const MENU = SIDEBAR_MENU(
    { ...perfiles, ...tipos },
    codigoEmpresa,
    tieneNegocio
  );

  return (
    <aside className="pt-12">
      <List
        sx={{ width: '100%', maxWidth: 180 }}
        component="nav"
        aria-labelledby="uv-aside-navigation"
      >
        {MENU.map((ruta, key) => (
          <div key={`${ruta.section + key}`}>
            {ruta.enabled && (
              <ListSubheaderComponent>{ruta.section}</ListSubheaderComponent>
            )}
            {ruta.items.map((item, index) => (
              <div key={`section-${index}-items-${item.nombre}`}>
                {item.subItems.length > 0 && item.enabled ? (
                  <CollapseMenu nombre={item.nombre} icono={item.icon}>
                    <ListComponent
                      disablePadding
                      className="bg-neutral-90 rounded"
                    >
                      {item.subItems.map((subitem, indexkey) => (
                        <div
                          key={`section-${ruta.section}-items-${
                            item.nombre
                          }-subitems-${subitem.nombre + indexkey}`}
                        >
                          {subitem.enabled && (
                            <Link to={subitem.path}>
                              <ListItemButtonComponent
                                nivel={2}
                                label={subitem.nombre}
                              />
                            </Link>
                          )}
                        </div>
                      ))}
                    </ListComponent>
                  </CollapseMenu>
                ) : (
                  <>
                    {item.enabled && (
                      <Link onClick={item.onClick} to={item.path}>
                        <ListItemButtonComponent
                          icon={item.icon}
                          label={item.nombre}
                        />
                      </Link>
                    )}
                  </>
                )}
              </div>
            ))}
            {ruta.enabled && <Divider className="my-6" />}
          </div>
        ))}
      </List>
    </aside>
  );
};

export default Sidebar;
