import { useContext, useEffect } from 'react';
import { MatchContext } from './context/MatchContext';
import { MatchResiduos } from './MatchResiduos';
import { PreviaMatch } from './componentes/PreviaMatch/PreviaMatch';
import store from 'states/store';

/**
 * @name InitMatch
 * Lógica inicial donde determina que vista mostrar según si tiene negocio o no.
 */
export const InitMatch = () => {
  const {
    getDatosUsuarioConNegocio,
    handleCargarMatch,
    matchStep,
    setMatchStep
  } = useContext(MatchContext);
  const { logeduser } = store.loguedUser;
  const tieneNegocio = logeduser.sucursal || null;

  const cargarDatosUsuario = async () => {
    const datosUsuario = await getDatosUsuarioConNegocio();
    handleCargarMatch(datosUsuario);
  };

  useEffect(() => {
    if (tieneNegocio) {
      cargarDatosUsuario();
    } else {
      setMatchStep('previa_match');
    }
  }, [tieneNegocio]);

  return (
    <div className="w-full">
      {matchStep === 'loading' && <div></div>}
      {matchStep === 'previa_match' && <PreviaMatch />}
      {matchStep === 'match' && <MatchResiduos />}
    </div>
  );
};
