import * as yup from 'yup';

const schemaNuevoIndicador = yup.object().shape({
  trabajadoresMasculino: yup
    .string()
    .max(15, "El valor es demasiado largo")
    .required('Este campo es obligatorio')
    .typeError('La cantidad máxima es 1.000.000'),

  trabajadoresFemenino: yup
    .string()
    .max(15, "El valor es demasiado largo")
    .required('Este campo es obligatorio')
    .typeError('La cantidad máxima es 1.000.000'),

  consumoEnergetico: yup
    .string()
    .max(15, "El valor es demasiado largo")
    .required('Este campo es obligatorio'),

  consumoCombustible: yup
    .string()
    .max(15, "El valor es demasiado largo")
    .required('Este campo es obligatorio'),

  //Solo para oferente
  residuosGenerados: yup.string().when('role', {
    is: roles => roles.includes('oferente'),
    then: residuosGenerados =>
      residuosGenerados
        .max(15, "El valor es demasiado largo")
        .typeError('El número no es válido')
        .required('Campo obligatorio')
  }),
  residuosProyectados: yup.string().when('role', {
    is: roles => roles.includes('oferente'),
    then: residuosProyectados =>
      residuosProyectados
        .max(15, "El valor es demasiado largo")
        .required('Campo obligatorio')
        .typeError('El número no es válido')
  }),

  //Solo para demandante
  residuosCompradosRecibidos: yup.string().when('role', {
    is: roles => roles.includes('demandante'),
    then: residuosCompradosRecibidos =>
      residuosCompradosRecibidos
        .max(15, "El valor es demasiado largo")
        .required('Campo obligatorio')
        .typeError('El número no es válido')
  }),

  //Solo para oferente o demandante
  trabajadoresMasGestion: yup.string().when('role', {
    is: roles => roles.includes('oferente') || roles.includes('demandante'),
    then: trabajadoresMasGestion =>
      yup.string().when('procesoGestion', {
        is: true,
        then: trabajadoresMasGestion =>
          trabajadoresMasGestion
            .max(15, "El valor es demasiado largo")
            .required('Campo obligatorio')
      })
  }),

  trabajadoresFemGestion: yup.string().when('role', {
    is: roles => roles.includes('oferente') || roles.includes('demandante'),
    then: trabajadoresFemGestion =>
      yup.string().when('procesoGestion', {
        is: true,
        then: trabajadoresFemGestion =>
          trabajadoresFemGestion
            .max(15, "El valor es demasiado largo")
            .required('Campo obligatorio')
            .typeError('El número no es válido')
      })
  }),

  totalHH: yup.string().when('role', {
    is: roles => roles.includes('oferente') || roles.includes('demandante'),
    then: totalHH =>
      yup.string().when('procesoGestion', {
        is: true,
        then: totalHH =>
          totalHH
            .max(15, "El valor es demasiado largo")
            .required('Campo obligatorio')
            .typeError('El número no es válido')
      })
  }),

  remuneracionesPagadas: yup.string().when('role', {
    is: roles => roles.includes('oferente') || roles.includes('demandante'),
    then: remuneracionesPagadas =>
      yup.string().when('procesoGestion', {
        is: true,
        then: remuneracionesPagadas =>
          remuneracionesPagadas
            .required('Campo obligatorio')
      })
  }),

  //Solo para transportista
  capacidadProyectada: yup.string().when('role', {
    is: roles => roles.includes('transportista'),
    then: capacidadProyectada =>
      capacidadProyectada
        .max(15, "El valor es demasiado largo")
        .required('Campo obligatorio')
        .typeError('El número no es válido')
  }),

  residuosTransportados: yup.string().when('role', {
    is: roles => roles.includes('transportista'),
    then: residuosTransportados =>
      residuosTransportados
        .max(15, "El valor es demasiado largo")
        .required('Campo obligatorio')
        .typeError('El número no es válido')
  }),

  kilometrosRecorridos: yup.string().when('role', {
    is: roles => roles.includes('transportista'),
    then: kilometrosRecorridos =>
      kilometrosRecorridos
        .max(15, "El valor es demasiado largo")
        .required('Campo obligatorio')
        .typeError('El número no es válido')
  }),

  //Solo para gestor
  residuosGestionar: yup.string().when('role', {
    is: roles => roles.includes('gestor'),
    then: residuosGestionar =>
      residuosGestionar
        .max(15, "El valor es demasiado largo")
        .required('Campo obligatorio')
        .typeError('El número no es válido')
  }),

  residuosGestionados: yup.string().when('role', {
    is: roles => roles.includes('gestor'),
    then: residuosGestionados =>
      residuosGestionados
        .max(15, "El valor es demasiado largo")
        .required('Campo obligatorio')
        .typeError('El número no es válido')
  }),

  //Solo para asesor
  residuosAsesorados: yup.string().when('role', {
    is: roles => roles.includes('asesor'),
    then: residuosAsesorados =>
      residuosAsesorados
        .max(15, "El valor es demasiado largo")
        .required('Campo obligatorio')
        .typeError('El número no es válido')
  })
});

export { schemaNuevoIndicador };
