import { useEffect, useState } from "react";
import store from "states/store";

export const useObtieneCodigoEmpresa = ({
  setFieldTouched,
}) => {
  const { isSuperAdmin, isAdmin, isFuncionario } = store.loguedUser.perfiles;
  const [codigoEmpresaState, setCodigoEmpresaState] = useState(null);
  // Obtengo Codigo Empresa
  useEffect(() => {
    setFieldTouched('codigoSucursal');
    setFieldTouched('nombreSucursal');
    if (!store.loguedUser.logeduser) {
      return;
    }
    if (isAdmin) {
      setCodigoEmpresaState(
        store.loguedUser.logeduser.sucursal?.empresa.codigoEmpresa
      );
    } else if (isFuncionario) {
      const codigoEmpresa = store.loguedUser.logeduser?.sucursal?.empresa?.codigoEmpresa;
      setCodigoEmpresaState(codigoEmpresa);
    }
  }, [isAdmin, isFuncionario, setFieldTouched]);

  return {
    codigoEmpresaState,
  }
}
