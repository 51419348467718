import { useCallback, useEffect, useState } from 'react';
import { DICTONARY } from 'const/Dictonary';
import analisisTerritorial from 'services/analisisTerritorial';
import storeMessages from 'states/alertMessages/store';

export const useMapaEmpresas = (filtrosAplicados, setCargando) => {
  const [datosMapaEmpresa, setDatosMapaEmpresa] = useState([]);

  const cargarMapa = useCallback(async () => {
    if (Object.keys(filtrosAplicados).length === 0) return;
    try {
      setCargando('mapaCargando', true);
      let params = {
        potencialSinergia: false
      };

      if (Object.hasOwn(filtrosAplicados, 'codigoComuna')) {
        params.codigoComuna = filtrosAplicados.codigoComuna;
      }
      if (Object.hasOwn(filtrosAplicados, 'codigosRegion')) {
        params.codigoRegion = filtrosAplicados.codigosRegion;
      }

      if (Object.hasOwn(filtrosAplicados, 'aplicarFiltros')) {
        params.codigoRegion = filtrosAplicados.aplicarFiltros.codigosRegion;
      }

      if (Object.hasOwn(filtrosAplicados, 'codigoSectorEconomico')) {
        params.codigoSectorEconomico = filtrosAplicados.codigoSectorEconomico;
      }

      const response = await analisisTerritorial.mapaAnalisisEmpresas(
        params,
        DICTONARY.INTERCEPTOR.DESACTIVADO
      );
      if (response.data.error) throw new Error('SIN_REGISTROS');
      if (response.status === 200) {
        setDatosMapaEmpresa(response.data);
      }
    } catch (error) {
      if (error.message === 'SIN_REGISTROS') {
        storeMessages.message.setMessage({
          mensaje: 'No existen registros para la solicitud realizada',
          tipo: 'info'
        });
      } else {
        storeMessages.message.setMessage({
          mensaje:
            'Por un error en el servicio no se ha obtenido la información para el mapa de sectores económicos',
          tipo: 'error'
        });
      }
      setDatosMapaEmpresa([]);
    } finally {
      setCargando('mapaCargando', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtrosAplicados]);

  // Se carga el mapa al iniciar el componente
  useEffect(() => {
    if (!Object.hasOwn(filtrosAplicados, 'codigoEmpresaAT')) {
      cargarMapa();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtrosAplicados]);

  return {
    datosMapaEmpresa
  };
};
