import { DICTONARY } from 'const/Dictonary';
import { format } from 'date-fns';
import { OrdenarAscendente } from 'helpers/manejoArreglos';
import { useEffect, useReducer } from 'react';
import residuosServices from 'services/residuos';
import commons from 'services/commons';
import empresasService from 'services/empresas';
import residuos from 'services/residuos';
import store from 'states/store';
import {
  initOrdernarMatch,
  initRadioBusqueda,
  INIT_CONTEXT_MATCH
} from '../utils/initialValues';
import { MatchContext } from './MatchContext';
import { MatchReducer } from './MatchReducer';
import chatServices from 'services/chat';
import analisisTerritorial from 'services/analisisTerritorial';
import {
  ordenarDisponibilidadDistancia,
  ordenarMatchResiduos,
  ordenarPorCriterio
} from '../utils/ordenarMatch';

export const MatchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MatchReducer, INIT_CONTEXT_MATCH);
  const { logeduser } = store.loguedUser;
  const { isGestor, isTransportista, isAsesor } = store.loguedUser.tipos;
  const DISABLE_TAB_RESIDUOS = isGestor || isTransportista || isAsesor || false;
  const DISABLE_TAB_EMPRESAS = false;

  const setCargandoMatch = isCargandoMatch => {
    dispatch({ type: 'SET_IS_CARGANDO_MATCH', payload: isCargandoMatch });
  };

  const setIsCargandoMatchResiduos = isCargando => {
    dispatch({ type: 'SET_IS_CARGANDO_MATCH_RESIDUOS', payload: isCargando });
  };

  const setIsCargandoMatchEmpresas = isCargando => {
    dispatch({ type: 'SET_IS_CARGANDO_MATCH_EMPRESAS', payload: isCargando });
  };

  const setIsCargandoMatchAmpliado = isCargando => {
    dispatch({ type: 'SET_IS_CARGANDO_MATCH_AMPLIADO', payload: isCargando });
  };

  const setMatchStep = matchStep => {
    dispatch({ type: 'SET_MATCH_STEP', payload: matchStep });
  };

  const setMatch = match => {
    //setMatch y setMatchFiltrado
    dispatch({ type: 'SET_MATCH', payload: match });
  };

  const setMatchEmpresas = matchEmpresas => {
    dispatch({ type: 'SET_MATCH_EMPRESAS', payload: matchEmpresas });
  };

  const setMatchAmpliado = matchAmpliado => {
    dispatch({ type: 'SET_MATCH_AMPLIADO', payload: matchAmpliado });
  };

  const setOrdenarMatch = (ev, ordenamiento) => {
    dispatch({ type: 'SET_ORDENAR_MATCH', payload: ordenamiento });
  };

  const setIsNuevosCriterios = value => {
    dispatch({ type: 'SET_IS_NUEVOS_CRITERIOS', payload: value });
  };

  const setState = state => {
    dispatch({ type: 'SET_STATE', payload: state });
  };

  const setMatchFiltrado = matchFiltrado => {
    dispatch({ type: 'SET_MATCH_FILTRADO', payload: matchFiltrado });
  };

  const setRadioBusqueda = radioBusqueda => {
    dispatch({ type: 'SET_RADIO_BUSQUEDA', payload: radioBusqueda });
  };

  const setCoordenadasCentroMapa = coordenadas => {
    dispatch({ type: 'SET_COORDENADAS_CENTRO_MAPA', payload: coordenadas });
  };

  const setResiduoSeleccionado = item => {
    dispatch({ type: 'SET_SELECCIONAR_ITEM', payload: item });
  };

  const setSucursalUsuario = usuario => {
    dispatch({ type: 'SET_SUCURSAL_USUARIO', payload: usuario });
  };

  const setTransacciones = transacciones => {
    dispatch({ type: 'SET_TRANSACCIONES', payload: transacciones });
  };

  const setEmpresaSeleccionada = empresaSeleccionada => {
    dispatch({
      type: 'SET_EMPRESA_SELECCIONADA',
      payload: empresaSeleccionada
    });
  };

  const setSucursalATSeleccionada = item => {
    dispatch({ type: 'SET_SELECCIONAR_EMPRESAAT', payload: item });
  };

  const setSucursalSeleccionado = item => {
    dispatch({ type: 'SET_SELECCIONAR_EMPRESA', payload: item });
  };

  const setSalasChat = salasChat => {
    dispatch({ type: 'SET_SALAS_CHAT', payload: salasChat });
  };

  const setTabActiva = tab => {
    dispatch({ type: 'SET_TAB_ACTIVA', payload: tab });
  };

  const setFiltrosAplicados = filtrosActivos => {
    dispatch({ type: 'SET_FILTROS_APLICADOS', payload: filtrosActivos });
  };

  /**
   * FUNCIONES MATCH AUTOMATICO CON NEGOCIO
   *  Función que se ejecuta por un usuario con negocio y llama a las demás funciones asociadas para efectuar un match automático.
   * @param datosUsuario los datos del usuario obtenidos en función getDatosUsuarioConNegocio
   */
  const handleCargarMatch = async datosUsuario => {
    setCargandoMatch(true);
    setState(datosUsuario);
    setMatchStep('match');
    cargarSalasChat(datosUsuario);
    await handleMatchData(datosUsuario);
  };

  const cargarSalasChat = datosUsuario => {
    //Se implementa mediante fetch ya que Axios tirá error en la configuración global.
    if (datosUsuario.tieneNegocio) {
      chatServices
        .salaChat(
          {
            totalPagina: DICTONARY.PAGINACION.ALL_DATA
          },
          DICTONARY.INTERCEPTOR.DESACTIVADO
        )
        .then(async response => {
          if (response.data.error) {
            return;
          }

          const salasResiduos = response.data.content
            .filter(sala => sala.residuo)
            .map(sala => parseInt(sala.residuo.codigoResiduo));

          const salasEmpresas = response.data.content
            .filter(sala => sala.residuo === null)
            .map(sucursal => {
              return sucursal.nombreSalaChat.replace(
                DICTONARY.CHAT.PREFIJO_NOMBRE_SALA,
                ''
              );
            });

          setSalasChat({
            residuos: salasResiduos,
            empresas: salasEmpresas
          });
        })
        .catch(error => {
          setSalasChat({
            residuos: [],
            empresas: []
          });
        });
    }
  };

  /**
   * Función que ejecuta un match automático, solamente corre la primera vez que se carga la vista de match y para usuario con negocio.
   * Los usuarios sin negocio deben usar match manual.
   */
  const handleMatchData = async datosUsuario => {
    try {
      if (!DISABLE_TAB_EMPRESAS) {
        setIsCargandoMatchEmpresas(true);
        empresasService
          .matchAutomatico(null, DICTONARY.INTERCEPTOR.DESACTIVADO)
          .then(response => {
            setMatchEmpresas({
              ...response.data
            });
          })
          .catch(error => {
            setMatchEmpresas({
              sucursales: [],
              numeroSucursales: 0
            });
          })
          .finally(() => {
            setIsCargandoMatchEmpresas(false);
          });
      }

      if (!DISABLE_TAB_RESIDUOS) {
        setIsCargandoMatchResiduos(true);
        const responseMatch = await residuos.matchAutomatico();
        if (responseMatch.status === 200) {
          setMatch(ordenarMatchResiduos(responseMatch));
          setIsCargandoMatchResiduos(false);
        }
      }

      setTimeout(() => {
        setCargandoMatch(false);
      }, 5000);
    } catch (error) {
      setTimeout(() => {
        setCargandoMatch(false);
        setIsCargandoMatchResiduos(false);
      }, 5000);
      console.log(error);
    }
  };

  /**
   * Función ejecutada para la obtención de datos del usuario como residuos, tipo de recursos, sucursales, dirección de la empresa obtenidas desde la sesión del usuario.
   */
  const getDatosUsuarioConNegocio = async () => {
    try {
      let dataUsuario = {};
      let tipoRecursos = [];
      let tipoResiduos = [];
      const tipoResiduosIds = [];

      const sucursal = logeduser.sucursal;
      const { latitudDireccion, longitudDireccion } = sucursal.direccion;
      dataUsuario = {
        sucursal: sucursal,
        coordenadas: [latitudDireccion, longitudDireccion]
      };

      const sucursalTipoResiduos =
        dataUsuario.sucursal.sucursalesTipoResiduo.map(tipo => {
          tipoResiduosIds.push(tipo.codigoTipoResiduo);
          return { nombre: tipo.nombreTipo, codigo: tipo.codigoTipoResiduo };
        });

      const allTipoResiduos = await residuosServices.tipoResiduos(
        {
          ordenarPor: 'codigoTipoResiduo',
          nivel: DICTONARY.TIPO_RESIDUOS.NIVEL_2
        },
        DICTONARY.INTERCEPTOR.DESACTIVADO
      );

      if (allTipoResiduos && allTipoResiduos.status === 200) {
        if (allTipoResiduos.data.error) {
          tipoResiduos = sucursalTipoResiduos;
        } else {
          tipoResiduos = allTipoResiduos.data.content
            .filter(tipo =>
              tipoResiduosIds.includes(tipo.codigoNivelAnteriorTipoResiduo)
            )
            .map(tipo => {
              if (tipo.recursos) {
                for (let index = 0; index < tipo.recursos.length; index++) {
                  const recurso = tipo.recursos[index];

                  if (
                    !tipoRecursos.find(
                      item => item.codigo === recurso.codigoRecurso
                    )
                  ) {
                    tipoRecursos.push({
                      nombre: recurso.nombreRecurso,
                      codigo: recurso.codigoRecurso
                    });
                  }
                }
              }
              return {
                nombre: tipo.nombreTipo,
                codigo: tipo.codigoTipoResiduo
              };
            });

          tipoResiduos = [...tipoResiduos, ...sucursalTipoResiduos];
        }
      }

      tipoRecursos = OrdenarAscendente(tipoRecursos, 'nombre', 'string');
      tipoResiduos = OrdenarAscendente(tipoResiduos, 'nombre', 'string');

      const TAB_ACTIVA_INICIAL =
        isGestor || isTransportista || isAsesor
          ? DICTONARY.MATCH.TABS.EMPRESAS
          : DICTONARY.MATCH.TABS.RESIDUOS;
      const data = {
        criteriosMatch: {
          tipoRecursos: tipoRecursos,
          tipoResiduos: tipoResiduos,
          manual: false,
          isBusquedaAmpliada: false
        },
        sucursalUsuario: dataUsuario,
        coordenadasCentroMapa: dataUsuario.coordenadas,
        tieneNegocio: true,
        listaTipoResiduos: tipoResiduos.map(residuo => ({
          nombreTipo: residuo.nombre,
          codigoTipoResiduo: residuo.codigo
        })),
        listaTipoRecursos: tipoRecursos.map(recurso => ({
          nombreRecurso: recurso.nombre,
          codigoRecurso: recurso.codigo
        })),
        tabActiva: TAB_ACTIVA_INICIAL
      };

      return data;
    } catch (error) {
      return INIT_CONTEXT_MATCH;
    }
  };

  /**
   * FUNCIONES MATCH MANUAL SIN NEGOCIO
   * Función ejecutada al momento de cambiar los criterios del match realizando un match manual según los criterios que el usuario desea.
   * @param finalData data obtenida por servicio de match manual
   * @param formikValues valores retornados de formulario formik
   */
  const handleMatchManual = formValues => {
    const tipoResiduos = formValues.codigosTipoResiduo
      ? [
          {
            nombre: formValues.codigosTipoResiduo.nombreTipo,
            codigo: formValues.codigosTipoResiduo.codigoTipoResiduo
          }
        ]
      : [];

    const tipoRecursos = formValues.codigosRecurso
      ? [
          {
            nombre: formValues.codigosRecurso.nombreRecurso,
            codigo: formValues.codigosRecurso.codigoRecurso
          }
        ]
      : [];

    const TAB_ACTIVA_INICIAL =
      isGestor || isTransportista || isAsesor
        ? DICTONARY.MATCH.TABS.EMPRESAS
        : DICTONARY.MATCH.TABS.RESIDUOS;

    const data = {
      isNuevosCriterios: false,
      criteriosMatch: {
        tipoRecursos: tipoRecursos,
        tipoResiduos: tipoResiduos,
        manual: true,
        isBusquedaAmpliada: formValues.busquedaExtendida
      },
      matchFiltrado: {
        sucursales: [],
        numeroSucursales: 0
      },
      matchEmpresasFiltrado: {
        sucursales: [],
        numeroSucursales: 0
      },
      matchAmpliadaFiltrado: {
        sucursales: [],
        numeroSucursales: 0
      },
      radioBusqueda: initRadioBusqueda,
      ordenarMatch: initOrdernarMatch,
      empresaSeleccionada: null,
      residuoSeleccionado: null,
      sucursalSeleccionada: null,
      sucursalATSeleccionada: null,
      tabActiva: TAB_ACTIVA_INICIAL
    };

    if (!DISABLE_TAB_RESIDUOS) {
      data.criteriosMatch = {
        ...data.criteriosMatch,
        fechaDisponibilidadInicio: format(
          formValues.fechaDisponibilidadInicio,
          'yyyy-MM-dd'
        ),
        fechaDisponibilidadFin: format(
          formValues.fechaDisponibilidadFin,
          'yyyy-MM-dd'
        )
      };
    }

    setState(data);
  };

  /**
   * Función ejecutada para actualizar el state cuando un usuario no tiene negocio, esta función se ejecuta en la vista previaMatch establece en el state que se pase a la siguiente vista "match" y además indica en state que el usuario tieneNegocio = false
   */
  const handleMatchSinNegocio = () => {
    const { latitudDireccion, longitudDireccion } =
      DICTONARY.MAPA.DEFAULT_CORDENADAS;

    const data = {
      sucursalUsuario: null,
      coordenadasCentroMapa: [latitudDireccion, longitudDireccion],
      tieneNegocio: false,
      matchStep: 'match',
      isCargandoMatch: false
    };
    setState(data);
  };

  /**
   * FUNCION DE ORDENAMIENTO DE MATCH
   * Implementa la lógica de botones de ordenamiento Disponibildiad / distancia los cuales son desplegados en la BarraLateral y son utilizados para organizar el resultado del match según los criterios que el usuario desea.
   */
  useEffect(() => {
    let residuosAux = [];
    let residuoFiltradoAux = [];

    //Ambos filtros aplican orden tipo match por puntaje.
    if (
      (state.ordenarMatch.includes('distancia') &&
        state.ordenarMatch.includes('disponibilidad')) ||
      state.ordenarMatch.length === 0
    ) {
      residuosAux = ordenarDisponibilidadDistancia(state.match.residuos);
      residuoFiltradoAux = ordenarDisponibilidadDistancia(
        state.matchFiltrado.residuos
      );
    } else {
      if (state.ordenarMatch.includes('disponibilidad')) {
        residuosAux = ordenarPorCriterio(
          state.match.residuos,
          'calculoDisponibilidad'
        );
        residuoFiltradoAux = ordenarPorCriterio(
          state.matchFiltrado.residuos,
          'calculoDisponibilidad'
        );
      }

      if (state.ordenarMatch.includes('distancia')) {
        residuosAux = ordenarPorCriterio(state.match.residuos, 'distancia');
        residuoFiltradoAux = ordenarPorCriterio(
          state.matchFiltrado.residuos,
          'distancia'
        );
      }
    }

    const data = {
      match: {
        ...state.match,
        residuos: residuosAux
      },
      matchFiltrado: {
        ...state.matchFiltrado,
        residuos: residuoFiltradoAux
      }
    };

    setState(data);
  }, [state.ordenarMatch]);

  /**
   * Carga las listas iniciales para usuarios sin negocio.
   */
  const getTipodeResiduos = async () => {
    if (
      state.listaTipoResiduos &&
      state.listaTipoResiduos.length > 0 &&
      state.listaTipoRecursos &&
      state.listaTipoRecursos.length > 0
    ) {
      return;
    }

    try {
      let tipoResiduoOrdenado = [];
      let tipoRecursoOrdenado = [];

      const responseTipoResiduos = await commons.tipoRSD(
        { totalPagina: 1000 },
        DICTONARY.INTERCEPTOR.DESACTIVADO
      );
      if (responseTipoResiduos.status === 200) {
        const listaTipos = responseTipoResiduos.data.content || [];
        tipoResiduoOrdenado = listaTipos.sort((a, b) => {
          let nombreA = a.nombreTipo.toUpperCase();
          let nombreB = b.nombreTipo.toUpperCase();
          return nombreA < nombreB ? -1 : 1;
        });
      }

      const responseTipoRecursos = await commons.recursos(
        { totalPagina: 1000 },
        DICTONARY.INTERCEPTOR.DESACTIVADO
      );
      if (responseTipoRecursos.status === 200) {
        const listaRecursos = responseTipoRecursos.data.content || [];
        tipoRecursoOrdenado = listaRecursos.sort((a, b) => {
          let nombreA = a.nombreRecurso.toUpperCase();
          let nombreB = b.nombreRecurso.toUpperCase();
          return nombreA < nombreB ? -1 : 1;
        });
      }

      const data = {
        listaTipoResiduos: tipoResiduoOrdenado,
        listaTipoRecursos: tipoRecursoOrdenado
      };

      setState(data);
    } catch (error) {
      setState({
        listaTipoResiduos: [],
        listaTipoRecursos: []
      });
    }
  };

  /**
   * Función ejecutada en cuadro Filtros, e implementa la lógica para filtrar el match según lo que el usuario haya seleccionado dentro de las opciones de filtro disponibles.
   */
  const handleResetFiltros = () => {
    setState({
      matchFiltrado: state.match,
      matchEmpresasFiltrado: state.matchEmpresas,
      matchAmpliadaFiltrado: state.matchAmpliada,
      residuoSeleccionado: null,
      sucursalSeleccionada: null,
      sucursalATSeleccionada: null,
      empresaSeleccionada: null,
      radioBusqueda: initRadioBusqueda
    });
  };

  /**
   * Función utilizada para usuarios sin negocio en donde presionan una empresa y se muestran los residuos en ventana popup sobre el mapa.
   * @param sucursal la sucursal asociada al market en el mapa en donde el usuario ha realizado el clic
   */
  const handleSeleccionarEmpresa = sucursal => {
    if (state.tabActiva === DICTONARY.MATCH.TABS.RESIDUOS) {
      const data = {
        empresaSeleccionada: {
          empresa: sucursal,
          residuos: state.matchFiltrado.residuos.filter(
            residuo =>
              residuo.sucursal.codigoSucursal === sucursal.codigoSucursal
          )
        }
      };
      setState(data);
    } else {
      setSucursalSeleccionado(sucursal);
    }
  };

  useEffect(() => {
    if (state.criteriosMatch.manual) {
      if (state.criteriosMatch.isBusquedaAmpliada) {
        setIsCargandoMatchAmpliado(true);
      }
      try {
        let paramServicio = {};
        if (
          state.criteriosMatch.tipoResiduos &&
          state.criteriosMatch.tipoResiduos.length > 0
        ) {
          paramServicio['codigosTipoResiduo'] =
            state.criteriosMatch.tipoResiduos[0].codigo;
        }

        if (
          state.criteriosMatch.tipoRecursos &&
          state.criteriosMatch.tipoRecursos.length > 0
        ) {
          paramServicio['codigosRecurso'] =
            state.criteriosMatch.tipoRecursos[0].codigo;
        }

        if (!DISABLE_TAB_RESIDUOS) {
          setIsCargandoMatchResiduos(true);
          residuosServices
            .matchManual(
              {
                ...paramServicio,
                fechaDisponibilidadInicio:
                  state.criteriosMatch.fechaDisponibilidadInicio,
                fechaDisponibilidadFin:
                  state.criteriosMatch.fechaDisponibilidadFin
              },
              DICTONARY.INTERCEPTOR.DESACTIVADO
            )
            .then(response => {
              if (response.data.error) {
                throw new Error('No hay registros');
              }
              setMatch(ordenarMatchResiduos(response));
            })
            .catch(error => {
              setMatch({
                sucursales: [],
                numeroSucursales: 0,
                numeroResiduos: 0,
                residuos: []
              });
            })
            .finally(() => {
              setIsCargandoMatchResiduos(false);
            });
        }

        if (!DISABLE_TAB_EMPRESAS) {
          setIsCargandoMatchEmpresas(true);
          empresasService
            .matchManual(
              {
                ...paramServicio,
                fechaDisponibilidadInicio:
                  state.criteriosMatch.fechaDisponibilidadInicio,
                fechaDisponibilidadFin:
                  state.criteriosMatch.fechaDisponibilidadFin
              },
              DICTONARY.INTERCEPTOR.DESACTIVADO
            )
            .then(response => {
              if (response.data.error) {
                throw new Error('No hay registros');
              }
              setMatchEmpresas({
                ...response.data
              });
            })
            .catch(error => {
              setMatchEmpresas({
                sucursales: [],
                numeroSucursales: 0
              });
            })
            .finally(() => {
              setIsCargandoMatchEmpresas(false);
            });
        }

        if (state.criteriosMatch.isBusquedaAmpliada) {
          analisisTerritorial
            .match(paramServicio, DICTONARY.INTERCEPTOR.DESACTIVADO)
            .then(response => {
              if (response.data.error) {
                throw new Error('No hay registros');
              }

              setMatchAmpliado({
                sucursales: response.data.empresasAT.slice(0, 30),
                numeroSucursales: response.data.numeroEmpresasAT
              });
            })
            .catch(error => {
              setMatchAmpliado({
                sucursales: [],
                numeroSucursales: 0
              });
            })
            .finally(() => {
              setIsCargandoMatchAmpliado(false);
            });
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  }, [state.criteriosMatch]);

  return (
    <MatchContext.Provider
      value={{
        // States
        ...state,
        // Methods
        setOrdenarMatch,
        setIsNuevosCriterios,
        setMatchFiltrado,
        setRadioBusqueda,
        setCoordenadasCentroMapa,
        setResiduoSeleccionado,
        setSucursalSeleccionado,
        setSucursalATSeleccionada,
        setSucursalUsuario,
        setTransacciones,
        setTabActiva,
        setState,
        setEmpresaSeleccionada,
        setMatchStep,
        setFiltrosAplicados,
        getDatosUsuarioConNegocio,
        getTipodeResiduos,

        //handlers - funciones con lógica
        handleMatchManual,
        handleMatchSinNegocio,
        handleResetFiltros,
        handleCargarMatch,
        handleSeleccionarEmpresa,

        DISABLE_TAB_RESIDUOS,
        DISABLE_TAB_EMPRESAS
      }}
    >
      {children}
    </MatchContext.Provider>
  );
};
