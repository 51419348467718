import * as yup from 'yup';
import {
  verificaEmpresaRegistrada,
  verificaEmpresaRegistradaNombre
} from '../helpers/verificaCorreoRegistrado';
// import ValidateRut from 'helpers/validateRut';
let respuestaNombreFinal = '';
let respuestaRutFinal = '';

const SchemaBusinessInfo = yup.object().shape({
  rutEmpresa: yup.string().when('tipoNegocio', {
    is: 'empresa',
    then: () => {
      return yup
        .string()
        .trim()
        .matches(/^[^.\s]*$/, 'No se permiten puntos en este campo')
        .max(11, 'La cantidad máxima de caracteres es 11')
        .min(9, 'La cantidad mínima de caracteres es 9')
        .required('Es un campo obligatorio');
      // .test(
      //   'Evaluación de rut',
      //   'El rut ingresado está registrado',
      //   async(value, { parent }) => {

      //     const rutModificado = parent.rutModificado;

      //     if(value.length >= 9 && rutModificado){
      //       // verifica si el rut existe en la base de datos
      //       respuestaRutFinal = await verificaEmpresaRegistrada(value).then((resp) => !resp);

      //     }
      //     return respuestaRutFinal;
      //   }

      // )
    }
  }),

  razonSocial: yup.string().when('tipoNegocio', {
    is: 'empresa',
    then: () => {
      return yup.string().required('Es un campo obligatorio');
    }
  }),

  rubro: yup
    .object()
    .required('Este campo es obligatorio')
    .when('tipoNegocio', {
      is: 'empresa',
      then: () => {
        return yup
          .object()
          .typeError('Este campo es obligatorio')
          .required('Este campo es obligatorio');
      }
    }),

  actividad: yup.object().when('tipoNegocio', {
    is: 'empresa',
    then: () => {
      return yup
        .object()
        .typeError('Este campo es obligatorio')
        .required('Este campo es obligatorio');
    }
  }),

  nombreEmpresa: yup.string().when('tipoNegocio', {
    is: 'empresa',
    then: () => {
      return yup.string().required('Es un campo obligatorio');
    }
  }),

  nombrePersona: yup.string().when('tipoNegocio', {
    is: 'persona-natural',
    then: () => {
      return yup
        .string()
        .trim()
        .required('Es un campo obligatorio')
        .test(
          'Evaluación de nombre',
          'El nombre ingresado está registrado',
          async (value, { parent }) => {
            const nombreModificado = parent.nombreModificado;

            if (value.length && nombreModificado) {
              // Limpieza valor
              // verifica si el nombre existe en la base de datos
              respuestaNombreFinal = await verificaEmpresaRegistradaNombre(
                value
              ).then(resp => !resp);
            }
            return respuestaNombreFinal;
          }
        );
    }
  })
});

export { SchemaBusinessInfo };
