import storeMessages from 'states/alertMessages/store';

// Mensajes de Alerta
export const ValidacionConexion = () =>
  storeMessages.message.setMessage({
    mensaje: 'Debe iniciar sesión antes de poder ver los residuos.',
    tipo: 'warning'
  });

export const AlertaSinRegistros = () =>
  storeMessages.message.setMessage({
    mensaje: 'No existen registros para la solicitud realizada',
    tipo: 'info'
  });

export const AlertaError = ({ mensaje }) =>
  storeMessages.message.setMessage({
    mensaje: mensaje,
    tipo: 'error'
  });
