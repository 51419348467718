import authentication from 'services/authentication';
import store from 'states/store';

export const refrescaUsuario = () => {
  const refrescaInforacionUsuario = async () => {
    if (!store.loguedUser.logeduser) return;

    await authentication
      .refrescarSesion(localStorage.token)
      .then(result => {
        // console.log('DATOS STORED >> ', store);
        // console.log('DATOS REFRESCADOS >> ', result.data);
        try {
          if (result.data.usuario) {
            const dataUsuario = result.data.usuario;
            store.loguedUser.setLogeduser(dataUsuario);

            // Se agregó una verificación de la propiedad sucursal de dataUsuario antes de asignarla en store.loguedUser
            if (dataUsuario.sucursal) {
              store.loguedUser.setSucursal(dataUsuario.sucursal);
            }
          }
        } catch (error) {
          // console.log('ERROR AL ACTUALIZAR LOS DATOS DEL USUARIO: ');
          console.error(error);
        }
      })
      .catch(err => {
        // console.log('ERROR AL OBTENER LOS DATOS ACTUALIZADOS DEL USUARIO: ');
        console.error(err);
      });
  };

  return refrescaInforacionUsuario();
};
