import InventoryIcon from '@mui/icons-material/Inventory';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import GroupIcon from '@mui/icons-material/Group';
import InsightsIcon from '@mui/icons-material/Insights';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import Home from '@mui/icons-material/Home';
import Assignment from '@mui/icons-material/Assignment';
import CompareArrows from '@mui/icons-material/CompareArrows';
import Dashboard from '@mui/icons-material/Dashboard';
import Factory from '@mui/icons-material/Factory';
import FindReplace from '@mui/icons-material/FindReplace';
import { DICTONARY, RUTAS } from 'const/Dictonary';
// Hooks
import { useLogout } from 'hooks/useLogout';

const SIDEBAR_MI_CUENTA = () => {
  const handleLogout = useLogout();

  return {
    section: 'Cuenta',
    enabled: true,
    items: [
      {
        nombre: 'Mi cuenta',
        icon: <PersonIcon />,
        path: RUTAS.EDITAR_CUENTA,
        subItems: [],
        enabled: true
      },
      {
        nombre: 'Cerar Sesión',
        onClick: () => handleLogout(),
        icon: <LogoutIcon />,
        path: RUTAS.HOME,
        subItems: [],
        enabled: true
      }
    ]
  };
};

export const SIDEBAR_MENU = (perfiles, codigoNegocio, tieneNegocio) => {
  if (perfiles.isSuperAdmin) {
    return SUPER_ADMIN_MENU();
  } 
  else {
    return MENU_USER(perfiles, codigoNegocio, tieneNegocio);
  }
};

const SUPER_ADMIN_MENU = () => {
  return [
    {
      section: 'Market Place',
      enabled: true,
      items: [
        {
          nombre: 'Transacciones',
          icon: <CompareArrows />,
          path: RUTAS.TRANSACCIONES,
          subItems: [],
          enabled: true
        }
      ]
    },
    {
      section: 'Administración',
      enabled: true,
      items: [
        {
          nombre: 'Inicio',
          icon: <Home />,
          path: RUTAS.HOME_USER,
          subItems: [],
          enabled: true
        },
        {
          nombre: 'Negocios',
          icon: <AccountBalanceIcon />,
          path: RUTAS.ADMINISTRAR_NEGOCIOS,
          subItems: [],
          enabled: true
        },
        {
          nombre: 'Sucursales',
          icon: <WarehouseIcon />,
          subItems: [],
          path: RUTAS.ADMINISTRAR_SUCURSALES,
          enabled: true
        },
        {
          nombre: 'Usuarios',
          icon: <GroupIcon />,
          subItems: [],
          path: RUTAS.ADMINISTRAR_USUARIOS,
          enabled: true
        },
        {
          nombre: 'Indicadores',
          icon: <InsightsIcon />,
          subItems: [
            {
              nombre: 'Reporte de indicadores',
              path: `${RUTAS.NEGOCIO}/admin/indicadores`,
              enabled: true
            },
            {
              nombre: 'Configuración',
              path: `${RUTAS.NEGOCIO}/admin/datos-indicadores`,
              enabled: true
            }
          ],
          enabled: true
        },
        {
          nombre: 'Residuo-recurso',
          icon: <InventoryIcon />,
          subItems: [],
          path: RUTAS.RESIDUOS,
          enabled: true
        },
        {
          nombre: 'Registro de actividad',
          icon: <Assignment />,
          path: RUTAS.ADMINISTRAR_LOGS_ACTIVIDAD,
          subItems: [],
          enabled: true
        },
        {
          nombre: 'A. Territorial',
          icon: <Dashboard />,
          subItems: [
            {
              nombre: 'Cargar base de datos',
              path: RUTAS.GESTION_TERRITORIAL,
              enabled: true
            },
            {
              nombre: 'Empresas de análisis territorial',
              path: RUTAS.LISTA_EMPRESAS_AT,
              enabled: true
            },
            {
              nombre: 'Residuos de análisis territorial',
              path: RUTAS.LISTA_RESIDUOS_AT,
              enabled: true
            }
          ],
          enabled: true
        }
      ]
    },
    { ...SIDEBAR_MI_CUENTA() }
  ];
};

const MENU_USER = (perfiles, codigoNegocio, tieneNegocio) => {

  const { isOferente, isDemandante, isAnalistaTerritorial, isAdmin, isFuncionario } = perfiles;

  return [
    {
      section: 'Market Place',
      enabled: tieneNegocio,
      items: [
        {
          nombre: 'Transacciones',
          icon: <CompareArrows />,
          path: RUTAS.TRANSACCIONES,
          subItems: [],
          enabled: tieneNegocio
        }
      ]
    },
    {
      section: 'Negocio',
      enabled: tieneNegocio,
      items: [
        {
          nombre: 'Mi negocio',
          icon: <AccountBalanceIcon />,
          path: RUTAS.HOME_USER,
          subItems: [
            {
              nombre: 'Editar Negocio',
              path: `${RUTAS.NEGOCIO}/editar/${codigoNegocio}`,
              enabled: true
            }
          ],
          enabled: !isFuncionario && tieneNegocio
        },
        {
          nombre: 'Sucursales',
          icon: <WarehouseIcon />,
          subItems: [
            {
              nombre: 'Administrar sucursales',
              path: `${RUTAS.NEGOCIO}/${codigoNegocio}/sucursales`,
              enabled: true
            },
            {
              nombre: 'Nueva sucursal',
              path: `${RUTAS.NEGOCIO}/${codigoNegocio}/sucursales/nuevo`,
              enabled: true
            }
          ],
          enabled: !isFuncionario && tieneNegocio
        },
        {
          nombre: 'Usuarios',
          icon: <GroupIcon />,
          subItems: [
            {
              nombre: 'Administrar usuarios',
              path: `${RUTAS.NEGOCIO}/${codigoNegocio}/usuarios`,
              enabled: true
            },
            {
              nombre: 'Invitar usuarios',
              path: RUTAS.INVITAR_USUARIOS,
              enabled: true
            }
          ],
          enabled: !isFuncionario && tieneNegocio
        },
        {
          nombre: 'Indicadores',
          icon: <InsightsIcon />,
          subItems: [
            {
              nombre: 'Reporte de indicadores',
              path: `${RUTAS.NEGOCIO}/${codigoNegocio}/indicadores`,
              enabled: true
            },
            {
              nombre: 'Configuración',
              path: `${RUTAS.NEGOCIO}/${codigoNegocio}/datos-indicadores`,
              enabled: !isFuncionario  ? true : false,
            }
          ],
          enabled: tieneNegocio
        },
        {
          nombre: 'Residuo-recurso',
          icon: <InventoryIcon />,
          subItems: [
            {
              nombre: 'Publicados',
              path: RUTAS.RESIDUOS,
              enabled: true
            },
            {
              nombre: 'Requerir residuo',
              path: `${RUTAS.RESIDUOS}/${DICTONARY.ROL.RECEPTOR.NOMBRE}/ingresar`,
              enabled: isDemandante
            },
            {
              nombre: 'Ofrecer residuo',
              path: `${RUTAS.RESIDUOS}/${DICTONARY.ROL.GENERADOR.NOMBRE}/ingresar`,
              enabled: isOferente
            }
          ],
          enabled: isDemandante || isOferente
        },
        {
          nombre: 'Registro de actividad',
          icon: <Assignment />,
          path: RUTAS.ADMINISTRAR_LOGS_ACTIVIDAD,
          subItems: [],
          enabled: isAdmin
        }
      ]
    },
    {
      section: 'Análisis Territorial',
      enabled: isAnalistaTerritorial,
      items: [
        {
          nombre: 'Análisis línea base',
          icon: <Factory />,
          path: RUTAS.ANALISIS_TERRITORIAL_BASE,
          subItems: [],
          enabled: isAnalistaTerritorial
        },
        {
          nombre: 'Potencial de Sinergías',
          icon: <FindReplace />,
          path: RUTAS.ANALISIS_TERRITORIAL_SINERGIAS,
          subItems: [],
          enabled: isAnalistaTerritorial
        },
        {
          nombre: 'Transacciones',
          icon: <CompareArrows />,
          path: RUTAS.TRANSACCIONES,
          subItems: [],
          enabled: isAnalistaTerritorial
        }
      ]
    },
    { ...SIDEBAR_MI_CUENTA() }
  ];
};
