import { useEffect } from 'react'; 

import { RUTAS } from 'const/Dictonary';
import { useHistory } from 'react-router-dom';
import { EnvioFormulario, EspesificacionOferta, IdentificacionResiduo, MaterialAdjunto } from '../Secciones';

export const SwitchStepsComponent = ({
  isLoading,
  setStateValidation,
  step,
  estadoGuardado,
  ...restProps
}) => {
  const navigate = useHistory();

  useEffect(() => {
    // console.log('mounting switch steps ...');


    return () => {
      // console.log('unmounting switch steps...');
    }
  }, [step]);

  
  switch (step) {
    case 1:
      return (
        <IdentificacionResiduo
          setStateValidation={setStateValidation}
          {...restProps}
        />
      );
    case 2:
      return (
        <EspesificacionOferta
          setStateValidation={setStateValidation}
          {...restProps}
        />
      );
    case 3:
      return (
        <MaterialAdjunto
          {...restProps}
          setStateValidation={setStateValidation}
          handleSubmit={restProps.handleSubmit} // Solo se pasan los props necesarios en este caso
          setStep={restProps.setStep}
        />
      );
    case 4:
      return (
        <EnvioFormulario
          {...restProps}
          setStateValidation={setStateValidation}
          onChange={restProps.handleChange} // Se modificó el nombre del prop
          isLoading={isLoading}
          estadoGuardado={estadoGuardado}
        />
      );
    default:
       return <>{navigate.replace(RUTAS.RESIDUOS)}</>;
  }
};