import { useEffect, useState } from 'react';
import empresasService from 'services/empresas';
import store from 'states/store';
import storeMessages from 'states/alertMessages/store';
import { DICTONARY } from 'const/Dictonary';

export const useSucursales = codigoEmpresa => {
  const { perfiles } = store.loguedUser;
  const isSuperAdmin = perfiles.isSuperAdmin;

  const [isLoading, setIsLoading] = useState(false);
  const [sucursales, setSucursales] = useState([]);

  const [empresaSeleccionada, setEmpresaSeleccionada] = useState(null);
  const [empresas, setEmpresas] = useState([]);
  const [loadingEmpresas, setLoadingEmpresas] = useState(false);

  const cargarSucursales = async () => {
    try {
      setIsLoading(true);      
      const response = await empresasService.obtieneSucursales({
        codigoEmpresa: empresaSeleccionada
          ? empresaSeleccionada.codigoEmpresa
          : codigoEmpresa,
          totalPagina: DICTONARY.PAGINACION.ALL_DATA,
      });

      const data = response.data.content ? response.data.content : [];
      setSucursales(data);

    } catch (error) {
      setSucursales([]);
      console.log('Error al obtener las sucursales ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const cargarEmpresas = async () => {
    setLoadingEmpresas(true);
    try {
      const response = await empresasService.obtieneEmpresas({
        totalPagina: 1000,
        ordenarPor: 'nombreEmpresa'
      });
      if (response.status === 200) {
        const empresasAux = response.data.content.map(empresa => ({
          codigoEmpresa: empresa.codigoEmpresa,
          nombreEmpresa: empresa.nombreEmpresa,
          rutEmpresa: empresa.rutEmpresa
        }));

        if (!isNaN(codigoEmpresa)) {
          setEmpresaSeleccionada(
            empresasAux.find(item => item.codigoEmpresa === codigoEmpresa)
          );
        }

        setEmpresas(empresasAux);
      }
    } catch (error) {
      console.log('error al cargar las empresas', error);
    }
    setLoadingEmpresas(false);
  };

  useEffect(() => {
    if (
      isSuperAdmin &&
      empresaSeleccionada &&
      empresaSeleccionada.codigoEmpresa === codigoEmpresa &&
      sucursales.length > 0 
    ) {
      return;
    }

    if(isSuperAdmin && isNaN(codigoEmpresa) && empresaSeleccionada === null){
      return;
    }

    cargarSucursales();
  }, [empresaSeleccionada]);

  useEffect(() => {
    if (isSuperAdmin){
      cargarEmpresas();
    }
  }, []);

  //ELIMINACION DE SUCURSAL
  const [openConfirmacion, setOpenConfirmacion] = useState(false);
  const [sucursalEliminar, setSucursalEliminar] = useState({});

  const handleIniciarEliminacion = sucursal => {
    setSucursalEliminar(sucursal);
    setOpenConfirmacion(true);
  };

  const handleConfirmarEliminacion = async () => {
    try {
      const response = await empresasService.eliminarSucursal(
        sucursalEliminar.codigoSucursal
      );
      if (response.status === 200) {
        setSucursales(
          sucursales.filter(
            sucursal =>
              sucursal.codigoSucursal !== sucursalEliminar.codigoSucursal
          )
        );
        storeMessages.message.setMessage({
          mensaje: 'Se ha eliminado correctamente la sucursal',
          tipo: 'exito'
        });
        setOpenConfirmacion(false);
        setSucursalEliminar({});
      }
    } catch (error) {
      console.log('Error en la confirmación ', error);
      setOpenConfirmacion(false);
    }
  };

  return {
    isLoading,
    isSuperAdmin,
    loadingEmpresas,

    sucursales,
    setSucursales,

    empresas,
    empresaSeleccionada,
    setEmpresaSeleccionada,

    openConfirmacion,
    setOpenConfirmacion,
    sucursalEliminar,
    handleIniciarEliminacion,
    handleConfirmarEliminacion
  };
};
