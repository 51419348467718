import React, { useEffect, useState, useMemo } from 'react';
// Meterial
import { Divider } from '@mui/material';
import { GpsOff, MyLocation } from '@mui/icons-material';
// Design System
import AlertComponent from 'components/design-system/Alert/Alert';
import Heading from 'components/design-system/Heading/Heading';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import Text from 'components/design-system/Text/Text';
import ButtonComponent from 'components/design-system/Button/Button';
// Views
import MapAddress from 'components/MapAddress/MapAddress';
// Services
import serviceEmpresas from 'services/empresas';
import { handleKeyPress } from 'views/Business/utils/handleKeyPress';
import { DICTONARY } from 'const/Dictonary';
import { CargaActividades } from 'views/Business/hooks';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
// Hooks
import { useDireccion } from 'hooks/useDireccion';
import { InputAsyncController } from 'components/design-system/InputAsync/InputAsyncController';
import { verificaEmpresaRegistradaNombre } from '../helpers/verificaCorreoRegistrado';

/**
 * Utilizamos selección de tipo de negocio para aplicar condicionantes
 *
 *
 * Reglas de Negocio : RN
 *
 */

export const BusinessInfo = ({
  values,
  errors,
  touched,
  onChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  isSubmitting
}) => {
  const [tipoNegocio, setTipoNegocio] = useState(values.tipoNegocio);

  // Custom Hooks de llamada a servicios

  const [defaultRubro, setDefaultRubro] = useState({});

  const [actividadesBD, setActividadesBD] = useState([]);
  const [rubrosBD, setRubrosBD] = useState([]);
  const [rubrosArray, setRubrosArray] = useState([]);
  const [cargandoServicioSII, setCargandoServicioSII] = useState(false);

  // Modificaciones para llamar servicio de validación
  const [originalRutValue, setOriginalRutValue] = useState('');
  const {
    latLon,
    setLatLon,
    addressQuerysearch,
    addressQueryParams,
    regiones,
    cargaListaRegiones,
    handleObtenerDireccion,
    handleObtenerComunas,
    listadoComunas,
    cargaListaComunas,
    setBuscandoDireccion,
    buscandoDireccion
  } = useDireccion();

  useEffect(() => {
    if (values.tipoNegocio === 'persona-natural') return;
    // console.log('values.actividad', values.actividad)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched, values.rubro, values.tipoNegocio]);

  // Servicio Datos SII
  useEffect(() => {
    if (values.tipoNegocio === 'persona-natural') return;
    const rutEmpresa = values.rutEmpresa;
    const tipoNegocio = values.tipoNegocio;

    if (
      rutEmpresa &&
      tipoNegocio === 'empresa' &&
      !errors.rutEmpresa &&
      rutEmpresa.length >= 9 &&
      // validar si el rut tiene guion en su penultima posicion
      rutEmpresa.charAt(values.rutEmpresa.length - 2) === '-'
    ) {
      // Llama servicio datos empresa SII
      cargaDatosEmpresasSII(rutEmpresa);
    } else {
      // Limpieza de campos
      setFieldValue('razonSocial', '');
      setFieldValue('nombreEmpresa', '');
      setFieldValue('rubro', {});
      setFieldValue('actividad', {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.rutEmpresa, setFieldValue, values.rutEmpresa, values.tipoNegocio]);

  const cargaDatosEmpresasSII = async rutEmpresa => {
    const { tipoNegocio } = values;

    if (tipoNegocio === 'persona-natural') return;

    try {
      setCargandoServicioSII(true);

      const params = { rutEmpresa };
      const response = await serviceEmpresas.datosEmpresasSII(
        params,
        DICTONARY.INTERCEPTOR.DESACTIVADO
      );
      const { data } = response;

      // Asignar razón social
      setFieldValue('razonSocial', data.razonSocial);

      // Inicializar arrays de rubros y actividades
      const nuevoRubrosArray = [];

      // Verificar la longitud de actividades
      if (data.actividades.length === 1) {
        const unicaActividad = data.actividades[0];
        setActividadesBD(data.actividades);
        setRubrosBD([unicaActividad.rubro]);
        setFieldValue('actividad', unicaActividad);
        setFieldValue('rubro', unicaActividad.rubro);
      } else {
        setActividadesBD(data.actividades);
        data.actividades.forEach(actividad => {
          if (actividad.codigoActividad === data.actividad) {
            setFieldValue('actividad', actividad);
            setFieldValue('rubro', actividad.rubro);
          }
          nuevoRubrosArray.push({
            nombreRubro: actividad.rubro.nombreRubro,
            codigoCiuRubro: actividad.rubro.codigoCiuRubro,
            codigoCiuActividad: actividad.codigoCiuActividad
          });
        });
        setRubrosArray(nuevoRubrosArray);
      }
    } catch (error) {
      console.error('Error en llamada a servicio de SII:', error);
    } finally {
      setCargandoServicioSII(false);
    }
  };

  //  Servicio Comunas
  useMemo(() => {
    if (!values.region) return;
    if (values.region && values.region.codigoRegion) {
      handleObtenerComunas(values.region.codigoRegion);
    }
  }, [handleObtenerComunas, values.region]);

  // Estados de carga de servicios
  useMemo(() => {
    if (isSubmitting) return;
    handleObtenerDireccion(values);
  }, [isSubmitting, handleObtenerDireccion, values]);

  // Verificacion de cambios en los campos de direccion
  useMemo(() => {
    const { nombreVia, comuna, region, isPointEditing } = values;

    if (isSubmitting || !latLon) return;

    const hasNombreVia = nombreVia && touched.nombreVia;
    const hasComuna = comuna && touched.comuna;
    const hasRegion = region && touched.region;

    if (hasNombreVia || hasComuna || hasRegion || isPointEditing) {
      setBuscandoDireccion(true);
      setFieldValue('latitudDireccion', latLon[0]);
      setFieldValue('longitudDireccion', latLon[1]);
    }
  }, [
    isSubmitting,
    latLon,
    setBuscandoDireccion,
    setFieldValue,
    touched.comuna,
    touched.nombreVia,
    touched.region,
    values
  ]);

  useEffect(() => {
    const { isPointEditing } = values;
    /* if (isPointEditing === false) {
      setFieldValue('nombreVia', '');
    } */
  }, [setFieldValue, values]);

  useEffect(() => {}, [defaultRubro]);

  const obtenerTodasEmpresasAT = async ({ setLoadingFetch, value }) => {
    try {
      setLoadingFetch(true);
      const nombreExiste = await verificaEmpresaRegistradaNombre(value);
      if (nombreExiste) {
        setFieldError('nombre', 'El nombre ingresado ya ha sido registrado');
      }
    } catch (error) {
      return error;
    } finally {
      setLoadingFetch(false);
    }
  };

  return (
    <div>
      <Heading type="h3">Información del negocio</Heading>
      {values.tipoNegocio === 'empresa' ? (
        <Text className="mb-6">La empresa debe estar escrita legalmente</Text>
      ) : (
        <div className="mb-6"></div>
      )}

      <div className="grid grid-cols-12 gap-4 pb-6">
        {tipoNegocio === 'empresa' && (
          <>
            {/* RUT empresa */}
            <div className="col-span-12 xs:col-span-6  lg:col-span-4">
              <InputComponent
                autoComplete={'off'}
                fullWidth
                label={<LabelComponent>Rut de la empresa</LabelComponent>}
                name="rutEmpresa"
                onChange={e => {
                  setFieldValue('rutModificado', false);
                  onChange(e);
                }}
                onPaste={e => {
                  if (
                    originalRutValue !== e.target.value &&
                    e.target.value.length >= 9
                  ) {
                    setFieldTouched('rutEmpresa');
                    setFieldValue('rutModificado', true);
                    onChange(e);
                  }
                }}
                onClick={e => {
                  setFieldValue('rutModificado', false);
                }}
                onBlur={e => {
                  if (
                    originalRutValue !== e.target.value &&
                    e.target.value.length >= 9
                  ) {
                    setFieldTouched('rutEmpresa');
                    setFieldValue('rutModificado', true);
                    setFieldTouched('razonSocial');
                    onChange(e);
                  }
                }}
                onFocus={e => {
                  setOriginalRutValue(e.target.value);
                }}
                value={values.rutEmpresa.trim()}
                outerInputProps={{
                  inputProps: {
                    maxLength: 11,
                    onKeyPress: handleKeyPress
                  }
                }}
                touched={touched.rutEmpresa}
                estado={
                  errors.rutEmpresa
                    ? { tipo: 'error', mensaje: errors.rutEmpresa }
                    : null
                }
              />
            </div>

            <div className="col-span-12 xs:col-span-12  lg:col-span-12">
              <AlertComponent>
                <strong>La razón social, rubro y actividad</strong> han sido{' '}
                <strong>obtenidos de forma automática</strong> con la
                información entregada por Servicio de Impuestos Internos (SII),
                <strong>
                  {' '}
                  según su última actualización de mayo 2024 .
                </strong>{' '}
                <br />
                En el caso de que estos datos automáticos no corresponden con la
                situación actual de tu empresa, por favor registra tu negocio y
                posterior a esto solicita mediante el botón de Ayuda la
                actualización adjuntando los datos del SII correctos.
              </AlertComponent>
            </div>
            {/* Razon Social */}
            <div className="col-span-12 xs:col-span-12  lg:col-span-12">
              <InputComponent
                autoComplete={'off'}
                disabled={
                  errors.rutEmpresa || !values.rutEmpresa ? true : false
                }
                fullWidth
                label={<LabelComponent>Razón social</LabelComponent>}
                name="razonSocial"
                onBlur={e => {
                  setFieldValue('nombreModificado', false);
                  setFieldValue('rutModificado', false);
                  handleBlur(e);
                }}
                onChange={ev => {
                  setFieldValue('nombreModificado', false);
                  setFieldValue('rutModificado', false);
                  ev && onChange(ev) && setFieldTouched('rubro');
                  !ev && setFieldValue('razonSocial', '');
                }}
                value={values.razonSocial ? values.razonSocial : ''}
                touched={touched.razonSocial}
                estado={
                  errors.razonSocial
                    ? { tipo: 'error', mensaje: errors.razonSocial }
                    : null
                }
              />
            </div>
            {/* Rubro */}
            <div className="col-span-12 xs:col-span-6  lg:col-span-6">
              <AutocompleteComponent
                options={rubrosBD}
                clearIcon={null}
                clearOnEscape={false}
                openOnFocus={true}
                isLoading={cargandoServicioSII}
                name="rubro"
                disabled={
                  cargandoServicioSII || errors.rutEmpresa || !values.rutEmpresa
                }
                accesor={'nombreRubro'}
                loadingText={cargandoServicioSII && 'Cargando...'}
                noOptionsText="No hay coincidencias"
                onChange={(ev, rubroSelected, reason) => {
                  const data = { name: 'rubro', value: rubroSelected };
                  setFieldValue('nombreModificado', false);
                  setFieldValue('rutModificado', false);
                  if (reason === 'clear') {
                    data.value = '';
                    setFieldValue('rubro', '');
                    setFieldValue('actividad', '');
                    return;
                  }

                  if (data.value) {
                    setFieldValue('actividad', '');
                    setFieldValue('rubro', data.value);
                    setFieldTouched('actividad');
                  } else {
                    setFieldValue('rubro', '');
                    setFieldValue('actividad', '');
                  }
                }}
                onBlur={e => {
                  setFieldValue('nombreModificado', false);
                  setFieldValue('rutModificado', false);
                  handleBlur(e);
                }}
                getOptionLabel={option => option.nombreRubro || ''}
                value={values.rubro ? values.rubro : ''}
              >
                <InputComponent
                  autoComplete={'off'}
                  fullWidth
                  label={<LabelComponent>Rubro</LabelComponent>}
                  touched={touched.rubro}
                  estado={
                    errors.rubro
                      ? { tipo: 'error', mensaje: errors.rubro }
                      : null
                  }
                />
              </AutocompleteComponent>
            </div>
            {/* Actividad */}
            <div className="col-span-12 xs:col-span-6  lg:col-span-6">
              <AutocompleteComponent
                clearIcon={null}
                clearOnEscape={false}
                options={actividadesBD}
                isLoading={cargandoServicioSII}
                disabled={
                  errors.rutEmpresa || cargandoServicioSII || !values.rubro
                }
                accesor={'nombreActividad'}
                loadingText={
                  (!actividadesBD.length || cargandoServicioSII) &&
                  'Cargando...'
                }
                noOptionsText="No hay coincidencias"
                name="actividad"
                onChange={(ev, actividadSelected, reason) => {
                  const data = { name: 'actividad', value: actividadSelected };

                  if (reason === 'clear') {
                    data.value = '';
                  }

                  const filter = rubrosArray.filter(
                    rubro =>
                      rubro.codigoCiuActividad === data.value.codigoCiuActividad
                  );

                  setFieldValue('rubro', filter[0]);

                  setFieldValue('nombreModificado', false);
                  setFieldValue('rutModificado', false);
                  data.value
                    ? setFieldValue('actividad', data.value)
                    : setFieldValue('actividad', '');
                }}
                onBlur={() => setFieldTouched('actividad')}
                getOptionLabel={option => option.nombreActividad || ''}
                value={values.actividad ? values.actividad : ''}
              >
                <InputComponent
                  autoComplete={'off'}
                  disabled={errors.rutEmpresa}
                  fullWidth
                  label={<LabelComponent>Actividad</LabelComponent>}
                  touched={touched.actividad}
                  estado={
                    errors.actividad
                      ? { tipo: 'error', mensaje: errors.actividad }
                      : null
                  }
                />
              </AutocompleteComponent>
            </div>
            <div className="col-span-12 xs:col-span-12  lg:col-span-12 mt-2 mb-3">
              <Divider />
            </div>
          </>
        )}
        {/* RN : solo mostrar cuando el tipo de negocio es persona-natural */}
        {tipoNegocio === 'persona-natural' && (
          <div className="col-span-12">
            <Heading type="h4" className="mb-0">
              Nombre
            </Heading>
            <Text className="mb-2">
              Corresponde al nombre por el cual los usuarios te identificarán
            </Text>

            <InputAsyncController
              name="nombre"
              touched={touched.nombre ? true : false}
              fullWidth
              setFieldValue={data => setFieldValue('nombre', data)}
              setFieldTouched={() => setFieldTouched('nombre')}
              value={values.nombre}
              isSubmitting={isSubmitting}
              onBlur={() => setFieldTouched('nombre')}
              onChange={event => {
                setFieldValue('nombre', event.target.value);
                setFieldTouched('nombre');
              }}
              fetchCall={controllerValues =>
                obtenerTodasEmpresasAT({
                  setLoadingFetch: controllerValues.setIsLoading,
                  value: controllerValues.inputValue
                })
              }
              estado={
                errors.nombre ? { tipo: 'error', mensaje: errors.nombre } : null
              }
            />
          </div>
        )}
        <div className="col-span-12">
          <Heading type="h4" className="mb-0">
            Dirección
          </Heading>
          <Text className="mb-2">
            Considera que la dirección que ingreses será usada para entregarte
            resultados basados en tu ubicación
          </Text>
        </div>
        <div className="col-span-12 xs:col-span-6 lg:col-span-4">
          <AutocompleteComponent
            name="region"
            clearOnEscape={true}
            accesor="nombreRegion"
            openOnFocus={true}
            options={regiones}
            disabled={!cargaListaRegiones}
            onChange={(ev, region, reason) => {
              let data = { name: 'region', value: region };
              if (reason === 'clear') {
                data.value = null;
                setFieldValue('comuna', null);
              }
              setFieldValue('comuna', null);
              setFieldValue('region', data.value);
            }}
            onBlur={() => setFieldTouched('region')}
            value={values.region}
            getOptionLabel={option => option.nombreRegion || ''}
            isOptionEqualToValue={(option, value) =>
              option.nombreRegion === values.region?.nombreRegion
            }
          >
            <InputComponent
              autoComplete={'off'}
              fullWidth
              touched={touched.region ? true : false}
              estado={
                errors.region ? { tipo: 'error', mensaje: errors.region } : null
              }
              label={<LabelComponent>Región</LabelComponent>}
            />
          </AutocompleteComponent>
        </div>
        <div className="col-span-12 xs:col-span-6 lg:col-span-4">
          <AutocompleteComponent
            name="comuna"
            accesor="nombreComuna"
            options={listadoComunas}
            disabled={!cargaListaComunas}
            onChange={(ev, comuna, reason) => {
              let data = { name: 'comuna', value: comuna };

              if (reason === 'clear') {
                data.value = null;
              }

              setFieldValue('comuna', data.value);
            }}
            value={values.comuna}
            getOptionLabel={option => option.nombreComuna || ''}
            isOptionEqualToValue={(option, value) =>
              option.nombreComuna === values.comuna?.nombreComuna
            }
            onBlur={() => setFieldTouched('comuna')}
          >
            <InputComponent
              autoComplete={'off'}
              fullWidth
              touched={touched.comuna ? true : false}
              estado={
                errors.comuna ? { tipo: 'error', mensaje: errors.comuna } : null
              }
              label={<LabelComponent>Comuna</LabelComponent>}
            />
          </AutocompleteComponent>
        </div>

        <div className="col-span-12 xs:col-span-12 lg:col-span-8">
          <div className="col-span-6 xxs:col-span-12  xs:col-span-12  lg:col-span-6">
            <InputComponent
              autoComplete={'off'}
              fullWidth
              label={<LabelComponent>Nombre y número calle</LabelComponent>}
              name="nombreVia"
              onChange={e => {
                onChange(e);
                // Tocar los 3 campos para realizar la busqueda
                setFieldTouched('region');
                setFieldTouched('comuna');
                setFieldTouched('nombreVia');
              }}
              onBlur={e => {
                handleBlur(e);
              }}
              value={values.nombreVia}
              placeholder="Ej: Providencia, 1091"
              touched={touched.nombreVia}
              estado={
                errors.nombreVia
                  ? { tipo: 'error', mensaje: errors.nombreVia }
                  : null
              }
            />
          </div>
        </div>
        {/* Mapa */}
        <div className="col-span-12  xxs:col-span-12 xs:col-span-12 ">
          <div
            className={`${
              values.isPointEditing ? ' bg-warning-light' : 'bg-info-light '
            }
                           sm:flex px-4 py-2.5 justify-between rounded items-center`}
          >
            <Text>
              {values.isPointEditing
                ? DICTONARY.MAPA_MENSAJES.INFO_DESACTIVAR_EDICION
                : DICTONARY.MAPA_MENSAJES.INFO_ACTIVAR_EDICION}
            </Text>
            <ButtonComponent
              className="min-w-[190px] md:min-w-fit mt-4 sm:mt-0"
              onClick={() => {
                setFieldValue('isPointEditing', !values.isPointEditing);
              }}
            >
              {values.isPointEditing && latLon ? (
                <>
                  Desactivar edición <GpsOff className="ml-2" />
                </>
              ) : (
                <>
                  Edición manual <MyLocation className="ml-2" />
                </>
              )}
            </ButtonComponent>
          </div>
        </div>
        <div className="col-span-12 rounded overflow-hidden">
          <MapAddress
            addressQuerysearch={addressQuerysearch}
            addressQueryParams={addressQueryParams}
            latLon={latLon}
            setLatLon={setLatLon}
            isPointEditing={values.isPointEditing}
            setIsPointEditing={value => setFieldValue('isPointEditing', value)}
            isLoading={isSubmitting}
            buscandoDireccion={buscandoDireccion}
          />
        </div>
      </div>
    </div>
  );
};

export default BusinessInfo;
