import { DICTONARY } from 'const/Dictonary';
import serviceEmpresas from 'services/empresas';
import storeMessages from 'states/alertMessages/store';

export const verificaEmpresaRegistrada = async (rutEmpresa) => {
  try {
    const params = { rutEmpresa: rutEmpresa };
    const response = await serviceEmpresas.empresaRegistrada(
      params,
      DICTONARY.INTERCEPTOR.ACTIVADO
    );
     
    if (response.status === 200 ) {

      const data = response.data;
      if(data){
      storeMessages.message.setMessage({
        mensaje: 'El rut ingresado no está disponible',
        tipo: 'warning'
      });
      return true;

      } else {

        storeMessages.message.setMessage({
          mensaje: 'El rut ingresado está disponible',
          tipo: 'exito'
        });
        return false;
      }
    }
  } catch (error) {
    console.log(error);
    storeMessages.message.setMessage({
      mensaje: error,
      tipo: 'error'
    });
    return false;
  }

  return false;
}


export const verificaEmpresaRegistradaNombre = async (nombre) => {

  try {
    const params = { nombreEmpresa: nombre };
    const response = await serviceEmpresas.empresaRegistrada(
      params,
      DICTONARY.INTERCEPTOR.DESACTIVADO
    );

    if (response.status === 200 ) {

      const data = response.data;
      if(data){
      storeMessages.message.setMessage({
        mensaje: 'El nombre ingresado no está disponible',
        tipo: 'warning'
      });
      return true;

      } else {

        storeMessages.message.setMessage({
          mensaje: 'El nombre ingresado está disponible',
          tipo: 'exito'
        });
        return false;
      }
    }
  } catch (error) {
    console.log(error);
    storeMessages.message.setMessage({
      mensaje: error,
      tipo: 'error'
    });
  }

  return false;
}
