import moment from 'moment';

export const convertirStringFecha = stringFecha => {
  //IN     2020-12-29T00:00:00.000+00:00
  //RETURN 29-12-2020
  if (!stringFecha) return '';
  const fechaCortada = stringFecha.substring(0, stringFecha.indexOf('T'));
  const fechaMoment = moment(fechaCortada, 'YYYY-MM-DD');
  const fechaFormateada = fechaMoment.format('DD-MM-YYYY');

  return fechaFormateada;
};

export const formatoFecha = fecha => {
  //IN     2020-12-29T00:00:00.000+00:00
  //RETURN 29-12-2020
  if (!fecha) return '';
  const fechaMoment = moment(fecha, 'YYYY-MM-DD');
  const fechaFormateada = fechaMoment.format('DD-MM-YYYY');

  return fechaFormateada;
}
