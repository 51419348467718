import { useContext } from 'react';
import { Marker } from 'react-leaflet';
import { MatchContext } from '../../../context/MatchContext';
import { PopupMarker } from 'components/PopupMarker';

/**
 * @name MarcadorEmpresa
 * Componente que muestra marcador en el mapa
 * @param sucursal datos de la sucursal asociada al marcador
 * @param markers referencia al marcador posicionado en el mapa
 * @param getMapIcon renderiza el tipo de icono a desplegar según el rol de la empresa
 */
export const MarcadorEmpresa = ({ sucursal, markers, getMapIcon }) => {
  const {
    tieneNegocio,
    sucursalSeleccionada,
    setCoordenadasCentroMapa,
    setSucursalSeleccionado,
    filtrosAplicados
  } = useContext(MatchContext);

  const marcador = {
    codigoSucursal: sucursal.codigoSucursal,
    nombreEmpresa: sucursal.empresa,
    nombreSucursal: sucursal.nombreSucursal,
    direccion: `${sucursal?.direccion?.nombreVia}, ${sucursal?.direccion?.comuna}, Región ${sucursal?.direccion?.region}`,
    coordenadas: [sucursal.direccion.latitud, sucursal.direccion.longitud],
    rol: sucursal.rol
  };

  const getMarcador = () => {
    const isRolesFiltrados = filtrosAplicados.rolesFiltrado.length === 0;
    const isMultipleRolesFiltrados = filtrosAplicados.rolesFiltrado.length > 1;

    if (isRolesFiltrados) {
      return sucursal.rol;
    }

    if (isMultipleRolesFiltrados) {
      const rolesEncontrados = sucursal.rol.filter(roles =>
        filtrosAplicados.rolesFiltrado.find(
          filtrado => filtrado === roles.nombreRol
        )
      );
      return rolesEncontrados;
    } else {
      const rolEncontrado = sucursal.rol.find(
        filtrado => filtrado.nombreRol === filtrosAplicados.rolesFiltrado[0]
      );
      return [rolEncontrado];
    }
  };

  const handleSeleccionarSucursal = ev => {
    setCoordenadasCentroMapa(marcador.coordenadas);
    setSucursalSeleccionado(sucursal);
  };

  const getOpacity = () => {
    if (sucursalSeleccionada === null) {
      return 1;
    }

    return sucursalSeleccionada.codigoSucursal === marcador.codigoSucursal
      ? 1
      : 0.2;
  };

  const getIndex = () => {
    if (sucursalSeleccionada === null) {
      return 1;
    }
    return sucursalSeleccionada.codigoSucursal === marcador.codigoSucursal
      ? 100
      : 1;
  };

  return (
    <Marker
      eventHandlers={{
        click: e => {
          handleSeleccionarSucursal();
        }
      }}
      position={marcador.coordenadas}
      boundsOptions={[marcador.coordenadas]}
      ref={el => {
        markers.current = {
          ...markers.current,
          [marcador.codigoSucursal]: el
        };
      }}
      key={marcador.codigoSucursal}
      icon={getMapIcon(getMarcador())}
      opacity={getOpacity()}
      zIndexOffset={getIndex()}
    >
      {tieneNegocio && (
        <PopupMarker
          nombreEmpresa={marcador.nombreEmpresa || 'No encontrado'}
          nombreSucursal={marcador.nombreSucursal || 'Sucursal'}
          direccion={marcador.direccion || 'No definido'}
        />
      )}
    </Marker>
  );
};
