import { Form, Formik } from "formik";
import { useCargarMaterial } from "./hooks/useCargarMaterial";
import { CargarMaterialCampos } from "./CargarMaterialCampos.jsx";
import { Divider } from "@mui/material";
import { schemaCargarMaterial } from "./schema/schemaCargarMaterial";
import { Link } from "react-router-dom";
import { DICTONARY, RUTAS } from "const/Dictonary";
import { ChevronLeft } from "@mui/icons-material";
import { SkeletonCargarMaterial } from "./skeleton/SkeletonCargarMaterial";
import FileUpload from "@mui/icons-material/FileUpload";
import Search from "@mui/icons-material/Search";
import ButtonComponent from "components/design-system/Button/Button";
import storeMessages from 'states/alertMessages/store';
import Text from "components/design-system/Text/Text";
import Heading from "components/design-system/Heading/Heading";
import IconButtonComponent from "components/design-system/IconButton/IconButton";
import { SidebarLayout } from "layouts/Sidebar/SidebarLayout";

export const CargarMaterialFormik = ({
  documento = null,
  archivo = false,
  isEdicion = false
}) => {

  const {
    isLoading,
    isLoadingComunas,
    listaMarcoLegal,
    listaNormativaTecnica,
    listaPublicaciones,
    listaTipoTratamiento,
    listaRegiones,
    listaComunas,
    listaTipoResiduo,
    handleObtenerComunas,
    onSubmit,
    valoresIniciales,
  } = useCargarMaterial(documento, archivo, isEdicion);

  if (isLoading) { return <SkeletonCargarMaterial /> }

  return (
    <>
      <div className="mb-4">
        <Heading type="h2">
          {isEdicion &&
            <Link to={RUTAS.MATERIAL_DIFUSION}>
              <IconButtonComponent type="secondary" className="mr-2">
                <ChevronLeft />
              </IconButtonComponent>
            </Link>
          }

          {isEdicion ? 'Editar material' : 'Cargar material de información'}
        </Heading>
        <Text className="mb-0">
          {isEdicion
            ? 'Modifica los campos para actualizar el material de información'
            : 'Completa el formulario para cargar un nuevo material de información'
          }
        </Text>
      </div>
      <div className="bg-white rounded shadow-md p-5 mb-5">
        <Formik
          initialValues={valoresIniciales}
          validateOnMount={false}
          validationSchema={schemaCargarMaterial}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={async (values, { setSubmitting, resetForm }) => {

            // resetear el formulario  de un campo especifico
            try {
              setSubmitting(true);
              const response = await onSubmit(values);
              if (response.data.error) {
                throw new Error(`No se ha ${isEdicion ? 'editado' : 'cargado'} el documento`);
              }

              if (response.status === 200) {
                storeMessages.message.setMessage({ mensaje: `El material de información ha sido ${isEdicion ? 'editado' : 'cargado'} éxitosamente`, tipo: 'success' });
                
                // si es edicion, no se debe resetear el formulario
                !isEdicion && resetForm();

              }

            } catch (error) {
              console.log('error >', error);
              storeMessages.message.setMessage({ mensaje: `Ha ocurrido un error en ${isEdicion ? 'edición' : 'carga' } de material de información`, tipo: 'error' });
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            handleChange,
            isSubmitting,
            validateForm
          }) => (
            <Form id="busquedaMaterialDifusion"
              onChange={event => {
                //  console.log('values :>> ', values);
              }}
            >
              <div className="grid grid-cols-12 gap-4">
                <CargarMaterialCampos
                  values={values}
                  handleChange={handleChange}
                  setFieldTouched={setFieldTouched}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  listaMarcoLegal={listaMarcoLegal}
                  listaNormativaTecnica={listaNormativaTecnica}
                  listaPublicaciones={listaPublicaciones}
                  listaTipoResiduo={listaTipoResiduo}
                  listaTipoTratamiento={listaTipoTratamiento}
                  listaRegiones={listaRegiones}
                  listaComunas={listaComunas}
                  handleObtenerComunas={handleObtenerComunas}
                  isSubmitting={isSubmitting}
                  isEdicion={isEdicion}
                  isLoadingComunas={isLoadingComunas}
                />
              </div>

              <div className="grid grid-cols-12">
                <div className="col-span-12 mt-10 mb-4">
                  <Divider />
                </div>

                <div className="col-span-12">
                  <div className="flex justify-between w-full">
                    <Link to={RUTAS.MATERIAL_DIFUSION}>
                      <ButtonComponent
                        type="secondary"
                        onClick={() => { }}
                        disabled={isSubmitting}>
                        Cancelar
                      </ButtonComponent>
                    </Link>

                    <ButtonComponent
                      form="busquedaMaterialDifusion"
                      buttonType="submit"
                      type="primary"
                      className="ml-4"
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                      onClick={() => {
                        validateForm()
                          .then(result => {
                            console.log('result :>> ', result);
                          })
                          .catch(err => {
                            console.log(err);
                          });
                      }}
                    >
                      {isSubmitting ? (
                        <>Cargando... <Search className="ml-2" /></>
                      ) : (
                        <>
                          {isEdicion
                            ? DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD.EDITAR
                            : <>
                              Subir material <FileUpload className="ml-2" />
                            </>
                          }
                        </>
                      )}
                    </ButtonComponent>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>

  )
};
