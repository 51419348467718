import { DICTONARY } from 'const/Dictonary';
import { useState } from 'react';
import documentos from 'services/documentos';
import storeMessages from 'states/alertMessages/store';

export const useDetalleMaterial = (listaDocumentos, setListaDocumentos) => {
  const [open, setOpen] = useState(false);
  const [isEliminando, setIsEliminando] = useState(false);
  const [selectedDocumento, setSelectedDocumento] = useState({});

  const handleClickOpen = (event, documento) => {
    setSelectedDocumento(documento);
    setOpen(true);
  };

  const handleClickClose = () => {
    setSelectedDocumento({});
    setOpen(false);
  };

  const handleClickEliminar = (event, material) => {
    const codigoMaterialInformacion = material.codigoMaterialInformacion;
    
    documentos
      .eliminarMaterialInformacion(codigoMaterialInformacion, DICTONARY.INTERCEPTOR.DESACTIVADO)
      .then(result => {
        storeMessages.message.setMessage({
          mensaje: 'Se ha eliminado el material de información exitosamente.',
          tipo: 'exito'
        });
        // Actualizar la lista de material de información
        
        setListaDocumentos(
          listaDocumentos.filter(documento => documento.codigoMaterialInformacion !== codigoMaterialInformacion)
        );
        setIsEliminando(false);
        setOpen(false);
      })
      .catch(err => {
        if (
          !err.response ||
          !err.response.data ||
          !err.response.data.codigoError
        ) {
          storeMessages.message.setMessage({
            mensaje: 'Ha ocurrido un error al intentar eliminar un material de información',
            tipo: 'error'
          });
        }
        setIsEliminando(false);
        setOpen(false);
      });
  };

  return {
    open,
    isEliminando,
    handleClickOpen,
    handleClickClose,
    handleClickEliminar,
    selectedDocumento
  };
};
