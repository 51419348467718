
import bgHome from 'assets/images/home/bg-home.png';
import { Layout3Opciones } from "./Layout3Opciones";
import { Layout2Opciones } from './Layout2Opciones.jsx';
import { Divider, Hidden } from '@mui/material';
import { BotonBase } from './BotonBase';
import { Layout1Opciones } from './Layout1Opciones';

export const LayoutOpciones = ({
  opciones
}) => {

  const LAYOUT = () => {
    switch(opciones.length){
      case 3:
        return <Layout3Opciones opciones={opciones} />
      
      case 2:  
        return <Layout2Opciones opciones={opciones} />
      
      default:
        return <Layout1Opciones opciones={opciones} />
    }
  }
  
  return(
    <>
      <Hidden smDown>
        <div className="flex justify-center items-center bg-no-repeat bg-top bg-cover pt-4 pb-8"
          style={{ backgroundImage: `url(${bgHome})`}}
        >
          {LAYOUT()}
        </div>
      </Hidden>

      <Hidden smUp>
        <div className="bg-white shadow-md rounded mb-5">
          <div className="p-4">
            <span className="font-secondary text-xs text-neutral-40 tracking-wider font-medium mb-0 block">
              ACCESOS RÁPIDOS
            </span>
          </div>

          {opciones.map((opcion, i) => {
            return <div key={i}>
                <BotonBase
                  esResponsive
                  onClick={opcion.onClick}
                  texto={opcion.texto}
                  icono={opcion.icono}
                />

                {i < opciones.length &&
                  <Divider />
                }
              </div>
          })}
        </div>
      </Hidden>
    </>
  )
};