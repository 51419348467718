import Heading from 'components/design-system/Heading/Heading';
import IconComponent from 'components/design-system/icon/Icon';
import Text from 'components/design-system/Text/Text';

import { ChevronLeft, Factory, People, Warehouse } from '@mui/icons-material';
import { Divider } from '@mui/material';
import LinkComponent from 'components/design-system/Link/Link';
import { RUTAS } from 'const/Dictonary';
import Loading from 'components/Loading/Loading';

export const BusinessSuccess = ({ values, codigoEmpresa, guardando }) => {
  const {
    razonSocial,
    actividad,
    nombreEmpresa,
    rutEmpresa,
    nombrePersona,
    rubro,
    tipoNegocio
  } = values;

  return guardando ? (
    <Loading />
  ) : (
    <>
      <div className="bg-white rounded lg:shadow-md">
        <div className="grid grid-cols-12 py-6 lg:px-8 gap-5">
          <div className="col-span-12 text-center">
            <Heading type="h2">Tu negocio ha sido editado</Heading>
            <Text className="mb-2">
              Se ha editado correctamente tu{' '}
              <b className="text-black">Negocio </b> en la plataforma.
            </Text>
          </div>

          <div className="col-span-12">
            <div className="border border-uv-primary-80 rounded p-4 pb-5 mt-2">
              <div className="grid grid-cols-12 gap-4 items-center">
                <div className="col-span-12 sm:col-span-2 text-center m-auto">
                  <div className="w-12 h-12 rounded-full bg-[#2E8567] align-middle justify-center content-center items-center min-w-[48px]">
                    <IconComponent color="primary" className="text-white">
                      <Factory className="w-8 h-8" />
                    </IconComponent>
                  </div>
                </div>
                <div className="col-span-12 sm:col-span-10">
                  <Heading type="h4" className="font-normal mb-0">
                    Información del{' '}
                    <b className="text-black font-semibold">Negocio</b>
                  </Heading>
                </div>
              </div>

              <div className="grid grid-cols-12 sm:gap-4">
                <div className="col-span-12 mt-4">
                  <Divider />
                </div>
                {tipoNegocio === 'empresa' ? (
                  <>
                    <div className="col-span-12 sm:col-span-2 sm:text-right pt-2 sm:pt-0">
                      <Text className="opacity-60">Razon Social</Text>
                    </div>
                    <div className="col-span-12 sm:col-span-10 sm:text-left">
                      <Text>{razonSocial}</Text>
                    </div>
                    <div className="col-span-12 sm:col-span-2 sm:text-right pt-2 sm:pt-0">
                      <Text className="opacity-60">Nombre Empresa </Text>
                    </div>
                    <div className="col-span-12 sm:col-span-10 sm:text-left">
                      <Text>{nombreEmpresa}</Text>
                    </div>
                    <div className="col-span-12 sm:col-span-2 sm:text-right pt-2 sm:pt-0">
                      <Text className="opacity-60">Rut</Text>
                    </div>
                    <div className="col-span-12 sm:col-span-10 sm:text-left">
                      <Text>{rutEmpresa || ''}</Text>
                    </div>

                    <div className="col-span-12 sm:col-span-2 sm:text-right pt-2 sm:pt-0">
                      <Text className="opacity-60">Rubro</Text>
                    </div>
                    <div className="col-span-12 sm:col-span-10 sm:text-left">
                      <Text>{rubro.nombreRubro || ''} </Text>
                    </div>

                    <div className="col-span-12 sm:col-span-2 sm:text-right pt-2 sm:pt-0">
                      <Text className="opacity-60">Actividad</Text>
                    </div>
                    <div className="col-span-12 sm:col-span-10 sm:text-left">
                      <Text>{actividad.nombreActividad || ''} </Text>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-span-12 sm:col-span-2 sm:text-right pt-2 sm:pt-0">
                      <Text className="opacity-60">Nombre Empresa </Text>
                    </div>
                    <div className="col-span-12 sm:col-span-10 sm:text-left">
                      <Text>
                        {nombrePersona ? nombrePersona : nombreEmpresa}
                      </Text>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center my-8">
        <Heading type="h4">¿Que puedo hacer ahora?</Heading>
      </div>

      <div className="grid grid-cols-12 gap-4 md:gap-6">
        <div className="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-3 text-center bg-white shadow-md rounded p-4 mb-4 lg:mb-0">
          <div className="w-12 h-12 rounded-full bg-[#2E8567] align-middle justify-center content-center items-center min-w-[48px] m-auto mb-2 -mt-8">
            <IconComponent color="primary" className="text-white">
              <Warehouse className="w-8 h-8" />
            </IconComponent>
          </div>
          <Heading type="h3" className="mb-4">
            Sucursales
          </Heading>
          <Text className="mb-4 px-4">
            Administra distintas sucursales para tu negocio
          </Text>
          <LinkComponent
            href={`${RUTAS.NEGOCIO}/${codigoEmpresa}/sucursales`}
            type="button"
          >
            Administrar
          </LinkComponent>
        </div>

        <div className="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-3 text-center bg-white shadow-md rounded p-4 mb-4 lg:mb-0">
          <div className="w-12 h-12 rounded-full bg-[#2E8567] align-middle justify-center content-center items-center min-w-[48px] m-auto mb-2 -mt-8">
            <IconComponent color="primary" className="text-white">
              <People className="w-8 h-8" />
            </IconComponent>
          </div>
          <Heading type="h3" className="mb-4">
            Usuarios
          </Heading>
          <Text className="mb-4 px-4">
            Invita nuevos usuarios a participar en tu negocio
          </Text>
          <LinkComponent
            href={`${RUTAS.NEGOCIO}/${codigoEmpresa}/usuarios`}
            type="button"
          >
            Administrar
          </LinkComponent>
        </div>

        <div className="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-3 text-left bg-white shadow-md rounded px-6 py-4">
          <div className="h-full flex flex-col items-start justify-center">
            <Heading type="h3" className="mb-4">
              Volver al panel de administración
            </Heading>
            <LinkComponent
              href={RUTAS.HOME_USER}
              type="button"
              className="pl-2"
            >
              <ChevronLeft className="mr-2" /> Volver
            </LinkComponent>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessSuccess;
