import React from 'react';
import List from '@mui/material/List';
import { Insights, TrendingUp } from '@mui/icons-material';
import ListItemButtonComponent from 'components/design-system/ListItemButton/ListItemButton';
import { RUTAS } from 'const/Dictonary';
import InsightsIcon from '@mui/icons-material/Insights';
import { ContenidoRestringido } from 'components/design-system/ContenidoRestringido/ContenidoRestringido';
import LinkComponent from 'components/design-system/Link/Link';
import Text from 'components/design-system/Text/Text';
import store from 'states/store';


const SubMenuDifusion = React.forwardRef(
  (
    {
      isDemandante,
      isOferente,
      loguedUser,
      forceRefresh,
      isSuperAdmin,
      isAdmin,
      isFuncionario
    },
    ref
  ) => {
    const tieneNegocio = store.loguedUser?.logeduser?.sucursal ? true : false;
    return (
      <List>
        {loguedUser.logeduser &&
        tieneNegocio &&
        !isFuncionario ? (
          <ListItemButtonComponent
            onClick={forceRefresh(
              `${RUTAS.NEGOCIO}/${loguedUser.logeduser.sucursal?.empresa?.codigoEmpresa}/indicadores`
            )}
            icon={<InsightsIcon />}
            label={'Indicadores'}
          />
        ) : 
        !isSuperAdmin &&
        <div className="col-span-12">
          <ContenidoRestringido>
            <Text size="S">
              Solo{' '}
              <b className="text-black">
                usuarios con negocios registrados
              </b>{' '}
              pueden acceder a las opciones de reportes.
              <LinkComponent
                href={RUTAS.REGISTRAR_NEGOCIO}
                className="ml-2"
              >
                Registrar un negocio
              </LinkComponent>
            </Text>
          </ContenidoRestringido>
          </div>
        }
        {isSuperAdmin ? (
          <ListItemButtonComponent
            onClick={forceRefresh(RUTAS.ADMINISTRAR_INDICADORES)}
            icon={<Insights />}
            label={'Indicadores'}
          />
        ) : null}

        {isAdmin || isSuperAdmin ? (
          <ListItemButtonComponent
            onClick={forceRefresh(RUTAS.EXPORTAR_REPORTES)}
            icon={<TrendingUp />}
            label={'Descargar reportes'}
          />
        ) : null}
      </List>
    );
  }
);

export default SubMenuDifusion;
