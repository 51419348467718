
import { useHistory } from 'react-router-dom';
import { RUTAS } from 'const/Dictonary';
import storeMessages from 'states/alertMessages/store';
import { useState } from 'react';

import { useSubmitResiduosDeclarados } from './useSubmitResiduosDeclarados';

export const useHelpersResiduosDeclarados = () => {

  const navigate = useHistory();

  const [open, setOpen] = useState(false);

  const [selectedRow, setSelectedRow] = useState({});
  // PARA EL FUNCIONAMIENTO DEL MENSAJE DE CONFIRMACIÓN
  const handleClickOpen = (row, table) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedRow({});
    setOpen(false);
  };
  const {
    handleEliminarResiduoDeclarado,
  } = useSubmitResiduosDeclarados();


  const handleClickIngresar = () => {
    navigate.push(`${RUTAS.GESTIONA_RESIDUO_DECLARADO}/ingresar`,{formulario:{tipo:'ingresar'}});
  };

  const handleClickEditar = (row, table) => {
    const original = row.original;

    // Se verifica si original y original.codigoResiduo no son nulos ni indefinidos
    if (!original || !original.codigoResiduoDeclarado) {
      // Si alguno de los dos es nulo o indefinido
      // se muestra un mensaje de error en la pantalla
      storeMessages.message.setMessage({
        mensaje:
          'Ha ocurrido un error al tratar de editar el Residuo declarado: no es un Residuo válido.',
        tipo: 'error'
      });

      // Se devuelve el control inmediatamente y no se hace nada más
      return;
    }

      navigate.push(
        `${RUTAS.GESTIONA_RESIDUO_DECLARADO}/editar/${original.codigoResiduoDeclarado}`,{formulario:{tipo:'editar'}}
      );
  };

  // LÓGICA PARA ELIMINAR UN RSD
  const handleClickEliminar = (
    row,
    residuosDeclarados,
    setResiduosDeclarados,
    setOpen,
    cargaResiduosDeclarados
  ) => {
    const original = row.original;
    // Se verifica si original y original.codigoResiduo no son nulos ni indefinidos
    if (!original || !original.codigoResiduoDeclarado) {
      // Si alguno de los dos es nulo o indefinido
      // se muestra un mensaje de error en la pantalla
      storeMessages.message.setMessage({
        mensaje:
          'Ha ocurrido un error al tratar de eliminar el Residuo declarado: no es un  Residuo válido.',
        tipo: 'error'
      });
      // Se devuelve el control inmediatamente y no se hace nada más
      return;
    }
    const codigoResiduoDeclarado = original.codigoResiduoDeclarado;
        // Hooks
   
   
        handleEliminarResiduoDeclarado(codigoResiduoDeclarado,cargaResiduosDeclarados);
        setResiduosDeclarados(
          residuosDeclarados.filter(r => r.codigoResiduoDeclarado !== codigoResiduoDeclarado)
        );
        // cargaResiduosDeclarados();
              

    // Eliminación de Residuo
    // eliminarResiduo(codigoResiduo, residuos, setResiduos,cargaResiduos);
    setOpen(false);
  };

  const handleClickDuplicar = (row, cargaResiduos) => {
    const original = row.original;
    // Se verifica si original y original.codigoResiduo no son nulos ni indefinidos
    if (!original || !original.codigoResiduoDeclarado) {
      // Si alguno de los dos es nulo o indefinido
      // se muestra un mensaje de error en la pantalla
      storeMessages.message.setMessage({
        mensaje:
          'Ha ocurrido un error al tratar de duplicar el Residuo: no es un Residuo válido.',
        tipo: 'error'
      });

      // Se devuelve el control inmediatamente y no se hace nada más
      return;
    }
    const codigoResiduoDeclarado = original.codigoResiduoDeclarado;

    if(codigoResiduoDeclarado){
       navigate.push({
        pathname: `${RUTAS.GESTIONA_RESIDUO_DECLARADO}/duplicar/${original.codigoResiduoDeclarado}`,
        state: {
          codigoResiduo: codigoResiduoDeclarado,
          original: original,
          formulario: {tipo:'duplicar'},
        }
      });
    }



  };
  return {
    handleClickIngresar,
    handleClickEditar,
    handleClickEliminar,
    handleClickDuplicar,
    handleClickOpen,
    handleClose,
    setOpen,
    open,
    selectedRow,
  };
}
