import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Librerias externas
import { withRouter } from 'react-router-dom';
import { Form, Formik } from 'formik';

// Components
import { Info } from '../components/Info';

// Mui Material
import { Divider, FormHelperText } from '@mui/material';

// Design System
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import NumberCircleComponent from 'components/design-system/NumberCircle/NumberCircle';
import Text from 'components/design-system/Text/Text';

// Layouts
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';

// Data
import InitialFormValuesCargaMasiva from '../data/initialValuesCargaMasiva';
import { DICTONARY } from 'const/Dictonary';

import FileDownloadIcon from '@mui/icons-material/FileDownload';

// Schema
import { schemaCargaMasiva } from '../schema/schemaCargaMasiva';
import {
  descargaBaseDatosPs,
  descargaBaseDatosRECT,
  descargaBaseDatosSII,
  subirArchivoPS,
  subirArchivoRECT,
  subirArchivoSII
} from '../hooks/useServices';
import DropzoneFile from 'components/design-system/DropzoneFile/DropzoneFile';
import { Upload } from '@mui/icons-material';
import AlertComponent from 'components/design-system/Alert/Alert';
//  Fecha
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
const GestionTerritorial = () => {
  const [loadingBDSII, setLoadingBDSII] = useState(false);
  const [loadingBDRECT, setLoadingBDRECT] = useState(false);
  const [loadingBDPS, setLoadingBDPS] = useState(false);

  const getIcon = tipo => {
    const BASE = DICTONARY.ESTADO[tipo.toUpperCase()];
    return <BASE.ICONO className="mr-1 text-lg" />;
  };


  const AvisoServicioCargando = () => {
    return (
      <AlertComponent type="info" className="mt-4">
        <Text>
          Este proceso de carga puede tomar varios minutos e incluso horas
          dependiendo de la cantidad de datos.
          <b className="text-black">
            Te notificaremos por correo cuando el proceso haya finalizado.
          </b>
        </Text>
      </AlertComponent>
    );
  };

  return (
    <SidebarLayout>
      <div className="mb-10">
        <div className="mb-5">
          <Heading className="!text-uv-primary-0 capitalize" type="h2">
            Carga masiva gestión territorial
          </Heading>
        </div>
        <div className="mb-6 bg-white h-full relative rounded">
          <div className="py-6 px-6 ">
            <Info />
          </div>

          <div className="col-span-12 my-4">
            <Divider />
          </div>

          <Formik
            initialValues={InitialFormValuesCargaMasiva}
            validationSchema={schemaCargaMasiva}
            validateOnChange={true}
            onSubmit={async (values, { setSubmitting, resetForm }) => { }}
          >
            {({ values, errors, touched, setFieldValue, setFieldTouched, handleChange, isSubmitting }) => (
              <Form onChange={event => { }}>
                {/* Base datos SII */}
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-12 lg:col-span-3 py-2 px-5 lg:px-6">
                    <Heading type="h3" className="!text-uv-primary-0">
                      Empresas de Análisis Territorial
                    </Heading>
                    <Text>
                      Contiene la información de empresas obtenidas del servicio
                      de impuestos internos
                    </Text>
                  </div>
                  <div className="col-span-12 lg:col-span-9 px-5 lg:pr-5">
                    {/* Paso 1 */}
                    <div className="grid grid-cols-12 gap-4">
                      <div className="col-span-12 md:col-span-1 lg:col-span-1">
                        <NumberCircleComponent
                          className=" bg-transparent  text-uv-primary-0 border-dashed border-uv-primary-0 border"
                          numero={1}
                          variant="circular"
                        />
                      </div>
                      <div className="col-span-12 md:col-span-11 md:pl-2 lg:pl-0 lg:col-span-11 py-2">
                        <Heading type="h4" className="">
                          Descarga el formato base
                        </Heading>

                        <ButtonComponent
                          type="primary"
                          onClick={() => descargaBaseDatosSII()}
                        >
                          Descargar formato
                          <FileDownloadIcon className="ml-2" />
                        </ButtonComponent>
                      </div>
                    </div>

                    {/* Paso 2 */}
                    <div className="grid grid-cols-12 mt-3">
                      <div className="col-span-12 md:col-span-1 lg:col-span-1">
                        <NumberCircleComponent
                          className=" bg-transparent  text-uv-primary-0 border-dashed border-uv-primary-0 border"
                          numero={2}
                          variant="circular"
                        />
                      </div>
                      <div className="col-span-12 md:col-span-11 md:pl-2 lg:pl-0 lg:col-span-11 py-2">
                        <Heading type="h4" className="">
                          Cargar base de datos
                        </Heading>

                        <DropzoneFile
                          enablePreviewFile={false}
                          setDataFiles={data => {
                            setFieldValue('baseDatosSII', data);
                            setFieldTouched(
                              'baseDatosSII',
                              data.length > 0 ? true : false
                            );
                          }}
                          loadingbd={loadingBDSII}
                          dataFiles={values.baseDatosSII}
                          enableEditingFile={false}
                          disabled={isSubmitting ? true : false}
                          {...DICTONARY.DROPZONE.CONFIG_UPLOAD_CARGA_MASIVA}
                        ></DropzoneFile>

                        {loadingBDSII && AvisoServicioCargando()}

                   

                        <ButtonComponent
                          className="mt-4"
                          onClick={() => {
                            console.log('values', values);
                            setLoadingBDSII(true);
                            subirArchivoSII(
                              values.baseDatosSII,
                              setLoadingBDSII
                            );
                          }}
                          isLoading={loadingBDSII}
                          disabled={
                            loadingBDSII || values.baseDatosSII.length === 0 
                          }
                        >
                          Subir base de datos <Upload />
                        </ButtonComponent>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-span-12 my-4">
                  <Divider />
                </div>

                {/* Base datos RECT */}
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-12 lg:col-span-3 py-2 px-5 lg:px-6">
                    <Heading type="h3" className="!text-uv-primary-0">
                      Residuos de Análisis Territorial
                    </Heading>
                    <Text>
                      Contiene la información de residuos declarados en RETC
                    </Text>
                  </div>
                  <div className="col-span-12 lg:col-span-9 px-5 lg:pr-5">
                    {/* Paso 1 */}
                    <div className="grid grid-cols-12">
                      <div className="col-span-12 md:col-span-1 lg:col-span-1">
                        <NumberCircleComponent
                          className=" bg-transparent  text-uv-primary-0 border-dashed border-uv-primary-0 border"
                          numero={1}
                          variant="circular"
                        />
                      </div>
                      <div className="col-span-12 md:col-span-11 md:pl-2 lg:pl-0 lg:col-span-11 py-2">
                        <Heading type="h4" className="">
                          Descarga el formato base
                        </Heading>

                        <ButtonComponent
                          type="primary"
                          onClick={() => descargaBaseDatosRECT()}
                        >
                          Descargar formato
                          <FileDownloadIcon className="ml-2" />
                        </ButtonComponent>
                      </div>
                    </div>

                    {/* Paso 2 */}
                    <div className="grid grid-cols-12 mt-3">
                      <div className="col-span-12 md:col-span-1 lg:col-span-1">
                        <NumberCircleComponent
                          className=" bg-transparent  text-uv-primary-0 border-dashed border-uv-primary-0 border"
                          numero={2}
                          variant="circular"
                        />
                      </div>
                      <div className="col-span-12 md:col-span-11 md:pl-2 lg:pl-0 lg:col-span-11 py-2">
                        <Heading type="h4" className="">
                          Cargar base de datos
                        </Heading>

                        <DropzoneFile
                          enablePreviewFile={false}
                          setDataFiles={data => {
                            setFieldValue('baseDatosRECT', data);
                            setFieldTouched(
                              'baseDatosRECT',
                              data.length > 0 ? true : false
                            );
                            setFieldTouched('anyoDeclaracion', data.length > 0 ? true : false);
                          }}
                          loadingbd={loadingBDRECT}
                          dataFiles={values.baseDatosRECT}
                          enableEditingFile={false}
                          disabled={isSubmitting ? true : false}
                          {...DICTONARY.DROPZONE.CONFIG_UPLOAD_CARGA_MASIVA}
                        ></DropzoneFile>

                        {loadingBDRECT && AvisoServicioCargando()}
                        <div className="grid grid-cols-12  mt-4">
                          <div className="col-span-12 sm:col-span-4">
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              adapterLocale={es}
                            >
                              <DesktopDatePicker
                                className={`${errors.anyoDeclaracion && 'border border-red-500'}mb-0`}
                                name="anyoDeclaracion"
                                inputFormat="yyyy"
                                label={<LabelComponent>Seleccionar año</LabelComponent>}
                                value={values.anyoDeclaracion}
                                defaultValue={values.anyoDeclaracion}
                                maxDate={new Date(DICTONARY.CURRENT_YEAR, 11, 31)} // Fecha máxima permitida: 31 de diciembre del año actual
                                views={['year']}
                                onChange={value => {
                                  handleChange({
                                    target: {
                                      name: 'anyoDeclaracion',
                                      value: value
                                    }
                                  });
                                }}
                                renderInput={params => (
                                  <InputComponent
                                    fullWidth
                                    className="pr-8"
                                    outerInputProps={params.InputProps}
                                    {...params}
                                  />
                                )}
                              />
                              {errors.anyoDeclaracion && touched.anyoDeclaracion ? (
                                <Text
                                  size="s"
                                  className="font-medium flex items-center py-0 mt-0 text-error-dark"
                                >
                                  {getIcon('error')}
                                  {errors.anyoDeclaracion}
                                </Text>
                              ) : null}
                            </LocalizationProvider>
                          </div>
                        </div>
                        <ButtonComponent
                          className="mt-4"
                          onClick={() => {
                            setLoadingBDRECT(true);
                            subirArchivoRECT(
                              values.baseDatosRECT,
                              values.anyoDeclaracion,
                              setLoadingBDRECT
                            );
                          }}
                          isLoading={loadingBDRECT}
                          disabled={
                            loadingBDRECT || values.baseDatosRECT.length === 0 || errors.anyoDeclaracion
                          }
                        >
                          Subir base de datos <Upload />
                        </ButtonComponent>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-span-12 my-4">
                  <Divider />
                </div>

                {/* Base datos PS */}
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-12 lg:col-span-3 py-2 px-5 lg:px-6">
                    <Heading type="h3" className="!text-uv-primary-0">
                      Potencial de Sinergia
                    </Heading>
                    <Text>
                      Contiene la información de match de empresas empresas de
                      Análisis Territorial
                    </Text>
                  </div>
                  <div className="col-span-12 lg:col-span-9 px-5 lg:pr-5">
                    {/* Paso 1 */}
                    <div className="grid grid-cols-12">
                      <div className="col-span-12 md:col-span-1 lg:col-span-1">
                        <NumberCircleComponent
                          className=" bg-transparent  text-uv-primary-0 border-dashed border-uv-primary-0 border"
                          numero={1}
                          variant="circular"
                        />
                      </div>
                      <div className="col-span-12 md:col-span-11 md:pl-2 lg:pl-0 lg:col-span-11 py-2">
                        <Heading type="h4" className="">
                          Descarga el formato base
                        </Heading>

                        <ButtonComponent
                          type="primary"
                          onClick={() => descargaBaseDatosPs()}
                        >
                          Descargar formato
                          <FileDownloadIcon className="ml-2" />
                        </ButtonComponent>
                      </div>
                    </div>

                    {/* Paso 2 */}
                    <div className="grid grid-cols-12 mt-3">
                      <div className="col-span-12 md:col-span-1 lg:col-span-1">
                        <NumberCircleComponent
                          className=" bg-transparent  text-uv-primary-0 border-dashed border-uv-primary-0 border"
                          numero={2}
                          variant="circular"
                        />
                      </div>
                      <div className="col-span-12 md:col-span-11 md:pl-2 lg:pl-0 lg:col-span-11 py-2">
                        <Heading type="h4" className="">
                          Cargar base de datos
                        </Heading>

                        <DropzoneFile
                          enablePreviewFile={false}
                          setDataFiles={data => {
                            setFieldValue('baseDatosPosiblesSinergias', data);
                            setFieldTouched(
                              'baseDatosPosiblesSinergias',
                              data.length > 0 ? true : false
                            );
                          }}
                          loadingbd={loadingBDPS}
                          dataFiles={values.baseDatosPosiblesSinergias}
                          enableEditingFile={false}
                          disabled={isSubmitting ? true : false}
                          {...DICTONARY.DROPZONE.CONFIG_UPLOAD_CARGA_MASIVA}
                        ></DropzoneFile>

                        {loadingBDPS && AvisoServicioCargando()}

                        <ButtonComponent
                          className="mt-4"
                          onClick={() => {
                            setLoadingBDPS(true);
                            subirArchivoPS(
                              values.baseDatosPosiblesSinergias,
                              setLoadingBDPS
                            );
                          }}
                          isLoading={loadingBDPS}
                          disabled={
                            loadingBDPS ||
                            values.baseDatosPosiblesSinergias.length === 0
                          }
                        >
                          Subir base de datos <Upload />
                        </ButtonComponent>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-span-12 my-4">
                  <Divider />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </SidebarLayout>
  );
};

GestionTerritorial.propTypes = {
  history: PropTypes.object
};

export default withRouter(GestionTerritorial);
