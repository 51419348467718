import React, { useState } from 'react';

export default function useBackdropLoading() {
  const [message, setMessage] = useState();
  const [visible, setVisible] = useState(false);
  const [maxTime, setMaxTime] = useState();

  return {
    message,
    setMessage,
    visible,
    setVisible,
    maxTime,
    setMaxTime
  };
}
