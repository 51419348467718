import { Switch } from "@mui/material";
import styles from './switch.module.css';
import './switch.css';

const SwitchComponent = ({
  ...rest
}) => {
  return(
    <Switch
      className={"asd"}
      color="primary"
      classes={{
        root:styles.root,
        switchBase:styles.base,
        track: styles.track,
        checked: styles.checked
      }}
      {...rest}
    />
  )
};

export default SwitchComponent;