
export const UseTocarCamposFormik = (values, setFieldTouched) => {
  // evitar tocar campos nombreNumeroVia, comuna y region
  // Exclusiones
  const camposNoTocar = ['nombreNumeroVia', 'comuna', 'region', 'nombreVia', 'numero','direccion'];
  const camposTocar = Object.keys(values).filter(item => !camposNoTocar.includes(item));
  camposTocar.forEach((campo) => {
    setFieldTouched(campo);
  });
  return {
    camposTocar
  }
}

export const formatoArchivos = (archivos) => {
  const formattedFiles = archivos.map(archivo => {

    const partesBase = archivo.base64.split(',');
    const base64 = partesBase[1];
    const partesTipo = archivo.base64.split(';');
    const tipoArchivo = partesTipo[0].split(':')[1];

    return {
      nombreArchivo: archivo.nombreArchivo,
      base64: base64,
      tipoArchivo: tipoArchivo,
      codigoArchivoResiduoDeclarado: archivo.codigoArchivoResiduoDeclarado ? parseInt(archivo.codigoArchivoRecursoAdquirido) : null

    };

  });

  return {
    formattedFiles
  }
}