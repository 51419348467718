import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Hidden,
  Tooltip
} from '@mui/material';
import Heading from 'components/design-system/Heading/Heading';
import Table from 'components/design-system/Table/Table';
import Text from 'components/design-system/Text/Text';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import { useEffect, useMemo, useState } from 'react';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import { AccountBalance, Close, Delete, Edit } from '@mui/icons-material';
import AlertComponent from 'components/design-system/Alert/Alert';
import ButtonComponent from 'components/design-system/Button/Button';
import storeMessages from 'states/alertMessages/store';
import { Link } from 'react-router-dom';
import { DICTONARY, RUTAS } from 'const/Dictonary';
import empresasService from 'services/empresas';

const Negocios = () => {
  const [empresas, setEmpresas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(1);
  const [pagination, setPagination] = useState(DICTONARY.PAGINACION.DEFAULT);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);

  const cargarDatos = async () => {
    setIsLoading(true);
    const query = {};
    let roles = null;
    let nombreEmpresa = null;

    if (columnFilters) {
      roles = columnFilters.find(filtro => filtro.id.includes('roles'));
      nombreEmpresa = columnFilters.find(filtro =>
        filtro.id.includes('nombreEmpresa')
      );
    }

    if (roles) {
      query.nombreRol = roles.value;
    }

    if (nombreEmpresa) {
      query.nombreEmpresa = nombreEmpresa.value;
    }

    if (pagination) {
      query.numPagina = pagination.pageIndex;
      query.totalPagina = pagination.pageSize;
    }

    try {
      const response = await empresasService.obtieneEmpresas(query);
      if (response.status === 200) {
        const { content, empty } = response.data;
        if (empty) {
          setEmpresas([]);
          setRowCount(0);
          return;
        } else if (Array.isArray(content)) {
          const empresas = content.map(empresa => {
            // Se cambian los roles de 'oferente' y 'demandante' por 'generador' y 'receptor' respectivamente solo a nivel de front
            const nuevosRoles = empresa.roles.map(rol => {
              switch (rol.nombreRol) {
                case 'oferente':
                  return { ...rol, nombreRol: 'generador' };
                case 'demandante':
                  return { ...rol, nombreRol: 'receptor' };
                default:
                  return rol; // Si el rol no es ni 'oferente' ni 'demandante', se devuelve tal cual
              }
            });
          
            return {
              ...empresa,
              roles: nuevosRoles
            };
          });
          setEmpresas(empresas);
          setRowCount(response.data.totalElements);
        }
      }
    } catch (error) {
      setEmpresas([]);
      return error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    cargarDatos();
  }, [pagination.pageIndex, pagination.pageSize, columnFilters]);

  const columnTables = useMemo(
    () => [
      {
        header: 'Nombre',
        accessorKey: 'nombreEmpresa',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>
      },
      {
        header: 'Rut',
        accessorKey: 'rutEmpresa',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>,
        enableColumnActions: false,
        enableColumnFilter: false
      },
      {
        header: 'Razon social',
        accessorKey: 'razonSocial',
        Cell: ({ renderedCellValue }) => (
          <Text>{renderedCellValue ? renderedCellValue : '-'}</Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false
      },
      {
        header: 'Rubro',
        accessorKey: 'actividad.rubro.nombreRubro',
        Cell: ({ renderedCellValue }) => (
          <Text>{renderedCellValue ? renderedCellValue : '-'}</Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false
      },
      {
        header: 'Tipo de negocio',
        accessorKey: 'roles',
        Cell: ({ renderedCellValue }) => (
          <Text>
            {renderedCellValue.map(rol => (
              <div className="capitalize">{rol.nombreRol}</div>
            ))}
          </Text>
        )
      }
    ],
    []
  );

  //ELIMINACION DE SUCURSAL
  const [openConfirmacion, setOpenConfirmacion] = useState(false);
  const [registroEliminar, setRegistroEliminar] = useState({});

  const handleIniciarEliminacion = registro => {
    setRegistroEliminar(registro.original);
    setOpenConfirmacion(true);
  };

  const handleConfirmarEliminacion = async () => {
    try {
      const response = await empresasService.eliminarEmpresa(
        registroEliminar.codigoEmpresa
      );
      if (response.status === 200) {
        storeMessages.message.setMessage({
          mensaje: 'La empresa ha sido eliminado correctamente',
          tipo: 'exito'
        });
        const empresasAux = empresas.filter(
          m => m.codigoEmpresa !== registroEliminar.codigoEmpresa
        );
        setEmpresas(empresasAux);
        setOpenConfirmacion(false);
        setRegistroEliminar({});
      }
    } catch (error) {
      console.log('Error en la confirmación ', error);
      setOpenConfirmacion(false);
    }
  };

  return (
    <SidebarLayout>
      <div className="mb-10">
        <div className="mb-5">
          <Heading type="h2">Negocios</Heading>
          <Text>
            Administración de los negocios registrados en la plataforma
          </Text>
        </div>

        <Table
          headTable={
            <Heading type="h4" className="p-2">
              Negocios registrados
            </Heading>
          }
          data={empresas}
          columns={columnTables}
          isLoading={isLoading}
          editing={true}
          rowCount={rowCount}
          manualFiltering
          enableGlobalFilter={false}
          onPaginationChange={setPagination}
          state={{
            columnFilters,
            pagination,
            isLoading,
            showProgressBars: false,
            globalFilter
          }}
          manualPagination
          enableEditing={true}
          actions={({ row, table }) => (
            <div>
              <Tooltip arrow placement="left" title="Editar">
                <Link
                  to={`${RUTAS.NEGOCIO}/editar/${row.original.codigoEmpresa}`}
                >
                  <IconButtonComponent
                    size="small"
                    className="mr-2"
                    type="secondary"
                  >
                    <Edit />
                  </IconButtonComponent>
                </Link>
              </Tooltip>
              <Tooltip arrow placement="right" title="Eliminar">
                <IconButtonComponent
                  size="small"
                  type="error"
                  onClick={() => handleIniciarEliminacion(row, table)}
                >
                  <Delete />
                </IconButtonComponent>
              </Tooltip>
            </div>
          )}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
        />
      </div>

      <Dialog
        open={openConfirmacion}
        onClose={() => setOpenConfirmacion(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="border-b border-neutral-90"
        >
          <Heading type="h3" className="mb-0">
            Confirmación de eliminación
          </Heading>
        </DialogTitle>
        <DialogContent className="p-5">
          <DialogContentText id="alert-dialog-description" className="pt-4">
            <Text>
              ¿Estás seguro que deseas eliminar completamente la empresa?
            </Text>
            <div className="my-4">
              <div className="flex items-center mb-2">
                <AccountBalance className="mr-2 text-uv-secondary-10" />
                <Heading type="h5" className="pr-10 mb-0">
                  {' '}
                  {registroEliminar.nombreEmpresa}
                </Heading>
              </div>
            </div>
            <AlertComponent type="error">
              Esta operación es irreversible y{' '}
              <b>la empresa quedará completamente eliminada de la plataforma</b>
            </AlertComponent>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="border-t border-neutral-90 p-4 justify-between">
          <ButtonComponent
            type="secondary"
            onClick={() => setOpenConfirmacion(false)}
            className="justify-center"
          >
            <Hidden smDown>Cancelar</Hidden>
            <Hidden smUp>
              <Close />
            </Hidden>
          </ButtonComponent>
          <ButtonComponent
            type="primary"
            onClick={() => handleConfirmarEliminacion()}
            autoFocus
            className="w-full xs:w-auto justify-center"
          >
            Confirmar
          </ButtonComponent>
        </DialogActions>
      </Dialog>
    </SidebarLayout>
  );
};

export default Negocios;
