import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import store from 'states/store';
// Material-UI Components
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Chip from '@mui/material/Chip';
import Delete from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';
import Edit from '@mui/icons-material/Edit';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FolderOff from '@mui/icons-material/FolderOff';
import GetApp from '@mui/icons-material/GetApp';
import Heading from 'components/design-system/Heading/Heading';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import Info from '@mui/icons-material/Info';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Text from 'components/design-system/Text/Text';
import Tooltip from '@mui/material/Tooltip';
import { CircularProgress } from '@mui/material';

// Design System
import IconComponent from 'components/design-system/icon/Icon';
// Components
import { EliminarMaterial } from './EliminarMaterial';
// Hooks
import { useDetalleMaterial } from './hooks/useDetalleMaterial';
import { useOpcionesTabla } from './hooks/useOpcionesTabla';
// Helpers
import { formatoFecha } from 'helpers/formatFecha';

export const ResultadoBusqueda = ({
  buscando,
  busquedaEfectuada,
  listaDocumentos,
  setListaDocumentos
}) => {
  const {
    open,
    handleClickOpen,
    handleClickClose,
    handleClickEliminar,
    selectedDocumento,
    isEliminando
  } = useDetalleMaterial(listaDocumentos, setListaDocumentos);

  const {
    isNotEmptyOrNull,
    isUrlNotEmptyOrNull,
    handleClickDescargar,
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
    descargando
  } = useOpcionesTabla();

  const { isSuperAdmin } = store.loguedUser.perfiles;
  const resultadoDiv = useRef(null);

  useEffect(() => {
    if (buscando) {
      resultadoDiv.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [buscando]);

  function isNotEmptyArray(arr) {
    return Array.isArray(arr) && arr.length > 0;
  }

  function isNotEmptyObject(obj) {
    return obj !== null && typeof obj === 'object' && Object.keys(obj).length > 0;
  }
  
  return (
    <div ref={resultadoDiv}>
      {buscando ? (
        <div className="bg-white rounded shadow-md mb-5">
          <div className="text-center py-10">
            <CircularProgress
              thickness={4}
              size={40}
              className="text-neutral-50 relative z-10 visible"
            />
          </div>
        </div>
      ) : (
        <div>
          {busquedaEfectuada &&
            listaDocumentos &&
            listaDocumentos.length > 0 && (
              <div className="bg-white rounded shadow-md mb-5">
                <div className="p-5">
                  <Heading type="h3" className="mb-0">
                    {listaDocumentos.length === 1 &&
                      'Se ha encontrado 1 material de información'}
                    {listaDocumentos.length > 1 &&
                      listaDocumentos.length +
                        ' materiales de información encontrados'}
                  </Heading>
                </div>
                <TableContainer>
                  <Table>
                    <TableBody>
                      {listaDocumentos
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((material, index) => (
                          // console.log(documento),
                          <TableRow key={index}>
                            <Accordion
                              key={material.nombreArchivo}
                              className="border-t border-b shadow-none"
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1c-content"
                                id="panel1c-header"
                              >
                                <Heading type="h5" className="font-normal">
                                  Nombre del material de información:{' '}
                                  <b>{material.nombreArchivo}</b>
                                </Heading>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="grid grid-cols-12 gap-5">
                                  <div className="col-span-12 md:col-span-8 lg:col-span-8">
                                    <div className="grid grid-cols-12 gap-5">
                                      {isNotEmptyArray(
                                        material.marcosLegales
                                      ) ? (
                                        <div className="col-span-12 sm:col-span-6 md:col-span-4">
                                          <div className="mb-1">
                                            <Heading type="h5" className="mb-3">
                                              Marco legal y tramitación
                                            </Heading>
                                            <Text>
                                              {material.marcosLegales.map(
                                                ({nombreMarcoLegal}, index) => {
                                                  return index ===
                                                  material.marcosLegales
                                                      .length -1
                                                    ? nombreMarcoLegal
                                                    : nombreMarcoLegal + ', ';
                                                }
                                              )}
                                            </Text>
                                          </div>
                                          <Chip
                                            className="required-document bg-uv-secondary-0"
                                            icon={<Info />}
                                            label="Documento legal obligatorio"
                                            color="primary"
                                          />
                                        </div>
                                      ) : null}

                                      {isNotEmptyArray(
                                        material.normativasTecnicas
                                      ) ? (
                                        <div className="col-span-12 sm:col-span-6 md:col-span-4">
                                          <div className="mb-1">
                                            <Heading type="h5" className="mb-3">
                                              Normativa técnica
                                            </Heading>
                                            <Text>
                                              {material.normativasTecnicas.map(
                                                ({nombreNormativaTecnica}, index) => {
                                                  return index ===
                                                  material.normativasTecnicas
                                                      .length -
                                                      1
                                                    ? nombreNormativaTecnica
                                                    : nombreNormativaTecnica + ', ';
                                                }
                                              )}
                                            </Text>
                                          </div>
                                          {material.obligatorio ? (
                                            <Chip
                                              className="required-document"
                                              icon={<Info />}
                                              label="Documento legal obligatorio"
                                              color="primary"
                                            />
                                          ) : null}
                                        </div>
                                      ) : null}

                                      {isNotEmptyArray(
                                        material.publicaciones
                                      ) ? (
                                        <div className="col-span-12 sm:col-span-6 md:col-span-4">
                                          <Heading type="h5" className="mb-3">
                                            Publicaciones
                                          </Heading>
                                          <Text>
                                            {material.publicaciones.map(
                                              ({nombrePublicacion}, index) => {
                                                return index ===
                                                material.publicaciones
                                                    .length -
                                                    1
                                                  ? nombrePublicacion
                                                  : nombrePublicacion + ', ';
                                              }
                                            )}
                                          </Text>
                                        </div>
                                      ) : null}

                                      {isNotEmptyObject(material.tipoResiduo) ? (
                                        <div className="col-span-12 sm:col-span-6 md:col-span-4">
                                          <Heading type="h5" className="mb-3">
                                            Residuo
                                          </Heading>
                                          <Text>{material.tipoResiduo.nombreTipo}</Text>
                                        </div>
                                      ) : null}

                                      {isNotEmptyArray(
                                        material.tipoTratamiento
                                      ) ? (
                                        <div className="col-span-12 sm:col-span-6 md:col-span-4">
                                          <Heading type="h5" className="mb-3">
                                            Tipo de tratamiento de Residuo
                                          </Heading>
                                          <Text>
                                            {material.tipoTratamiento}
                                          </Text>
                                        </div>
                                      ) : null}

                                      {isNotEmptyArray(
                                          material.comunas
                                        ) ? (
                                        <div className="col-span-12 sm:col-span-6 md:col-span-4">
                                          <Heading type="h5" className="mb-3">
                                            Región
                                          </Heading>
                                          <Text>{
                                            // Regiones unicas
                                            material.comunas.map( ({ region}) => 
                                                                     region.nombreRegion).filter((value, index, self) => 
                                                                     self.indexOf(value) === index).join(', ')
                                            }</Text>
                                        </div>
                                      ) : null}


                                      {
                                       isNotEmptyArray(
                                        material.comunas
                                        ) ? (
                                          <div className="col-span-12 sm:col-span-6 md:col-span-4">
                                            <Heading type="h5" className="mb-3">
                                              Comunas 
                                            </Heading>
                                            <Text>
                                              {
                                                // devolver comunas
                                                material.comunas.map( ({ nombreComuna}) => 
                                                                         nombreComuna).join(', ')
                                              }
                                            </Text>
                                          </div>
                                        ) : null
                                      }

                                     
                                      {isNotEmptyOrNull(material.fechaPublicacion) ? (
                                        <div className="col-span-12 sm:col-span-6 md:col-span-4">
                                          <Heading type="h5" className="mb-3">
                                            Fecha de publicación
                                          </Heading>
                                          <Text>{formatoFecha(material.fechaPublicacion)}</Text>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>

                                  {isUrlNotEmptyOrNull(material.urls) ? (
                                    <div className="col-span-12 md:col-span-4">
                                      <div className="mb-1">
                                        <Heading type="h5" className="mb-3">
                                          Enlaces externos
                                        </Heading>
                                      </div>
                                      {material.urls
                                        ? material.urls.map((url, id) => {
                                            return (
                                              <div
                                                className="link-detail mb-1"
                                                key={id}
                                              >
                                                <Chip
                                                  label={url.url}
                                                  size="small"
                                                  component="a"
                                                  href={url.url}
                                                  target="_blank"
                                                  clickable
                                                  variant="outlined"
                                                  className="mb-2"
                                                />
                                                <Text size="S">
                                                  {url.descripcion}
                                                </Text>
                                              </div>
                                            );
                                          })
                                        : null}
                                    </div>
                                  ) : null}
                                </div>
                              </AccordionDetails>
                              <Divider />
                              <AccordionActions>
                                {material.rutaArchivo ? (
                                  <IconButtonComponent
                                    type={descargando ? 'disabled' : 'secondary'}
                                    className={`mr-3`}
                                    disabled={descargando ? true : false}
                                    onClick={e => handleClickDescargar(e, material)}

                                  >
                                    <Tooltip title={`${descargando ? ' Descargando...' : 'Descargar'}`}>
                                      <GetApp className={`color-edit${descargando ? ' disabled' : ''}`} />
                                    </Tooltip>
                                  </IconButtonComponent>
                                ) : null}

                                {isSuperAdmin ? (
                                  <Link
                                    to={
                                      '/admin/editar-difusion/' + material.codigoMaterialInformacion
                                    }
                                  >
                                    <IconButtonComponent type="secondary">
                                      <Tooltip title="Editar">
                                        <Edit className="color-edit" />
                                      </Tooltip>
                                    </IconButtonComponent>
                                  </Link>
                                ) : null}

                                {isSuperAdmin ? (
                                  <IconButtonComponent
                                    type="error"
                                    size="small"
                                    onClick={e => handleClickOpen(e, material)}
                                  >
                                    <Tooltip title="Eliminar">
                                      <Delete />
                                    </Tooltip>
                                  </IconButtonComponent>
                                ) : null}
                              </AccordionActions>
                            </Accordion>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    component="div"
                    count={listaDocumentos.length}
                    page={page}
                    rowsPerPageOptions={[5, 10, 15]}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={<Text>Resultados por página:</Text>}
                  />
                </TableContainer>
              </div>
            )}

          {busquedaEfectuada && listaDocumentos.length === 0 && (
            <div className="bg-white rounded shadow-md mb-5">
              <div className="p-5 text-center">
                <IconComponent color="secondary">
                  <FolderOff className="text-4xl mb-2" />
                </IconComponent>
                <Heading type="h4">No se han encontrado resultados</Heading>
                <Text>
                  No hay materiales de información que coincidan con los
                  parámetros de busqueda seleccionados.
                </Text>
              </div>
            </div>
          )}
        </div>
      )}

      <EliminarMaterial
        open={open}
        handleClickOpen={handleClickOpen}
        handleClickClose={handleClickClose}
        handleClickEliminar={handleClickEliminar}
        selectedDocumento={selectedDocumento}
        isEliminando={isEliminando}
      />
    </div>
  );
};
