import { useEffect, useState } from 'react';
import usuariosService from 'services/usuarios';
import store from 'states/store';

export const useEditarCuenta = () => {
  const { logeduser, setLogeduser } = store.loguedUser;
  const [isLoading, setIsLoading] = useState(true);
  const [usuario, setUsuario] = useState(null);

  const getInformacionUsuario = async () => {
    setIsLoading(true);
    try {
      const response = await usuariosService.getUsuario(
        logeduser.codigoUsuario
      );
      if (response.status === 200) {
        setTimeout(() => {
          setUsuario(response.data);
          setIsLoading(false);
        }, 1500);
      }
    } catch (error) {
      setIsLoading(false);
      console.log('Error al obtener la información del usuario', error);
    }
  };

  const valorInicial = () => {
    //Extraigo del nombre firstnmame and secondname
    //Ej: Luis Alejandro, [Luis] [del Carmen]
    if (usuario) {
      const nombre = usuario.nombreUsuario.split(' ');
      const primerNombre = nombre[0];
      const segundoNombre =
        nombre.length > 2 ? nombre[1] + ' ' + nombre[2] : nombre[1];

      return {
        rutUsuario: usuario.rutUsuario || '',
        primerNombre: primerNombre || '',
        segundoNombre: segundoNombre || '',
        apellidoPaterno: usuario.apellidoPaterno || '',
        apellidoMaterno: usuario.apellidoMaterno || '',
        correoElectronico: usuario.correoElectronico || '',
        telefonoUsuario: usuario.telefonoUsuario || '',
        profesion: usuario.profesion || '',
        imagenBase64: '',
        files: [],
        sucursal: usuario.sucursal 
          ? { codigoSucursal: usuario.sucursal.codigoSucursal } 
          : null,
        emailModificado: false
      };
    }

    return {
      rutUsuario: '',
      primerNombre: '',
      segundoNombre: '',
      apellidoPaterno: '',
      apellidoMaterno: '',
      correoElectronico: '',
      telefonoUsuario: '',
      profesion: '',
      imagenBase64: '',
      files: [],
      sucursal: null
    };
  };

  const onSubmit = async valores => {
    try {
      const valoresRequest = {};
      //Campos optativos
      valoresRequest.rutUsuario =
        valores.rutUsuario.length > 0 ? valores.rutUsuario : null;
      valoresRequest.profesion =
        valores.profesion.length > 0 ? valores.profesion : null;
      valoresRequest.telefonoUsuario =
        valores.telefonoUsuario.length > 0 ? valores.telefonoUsuario : null;
      if (valores.files && valores.files.length > 0) {
        const nombreArchivo = valores.files[0].nombreArchivo;
        const imagenBase64 = valores.files[0].base64.replace(
          /^data:image\/[a-z]+;base64,/,
          ''
        );

        valoresRequest.nombreImagen = nombreArchivo;
        valoresRequest.imagenBase64 = imagenBase64;
      }

      //Campos obligatorios
      valoresRequest.sucursal = valores.sucursal;
      valoresRequest.apellidoMaterno = valores.apellidoMaterno;
      valoresRequest.apellidoPaterno = valores.apellidoPaterno;
      valoresRequest.correoElectronico = valores.correoElectronico;
      if (valores.segundoNombre && valores.segundoNombre.length > 0) {
        valoresRequest.nombreUsuario =
          valores.primerNombre + ' ' + valores.segundoNombre;
      } else {
        valoresRequest.nombreUsuario = valores.primerNombre;
      }

      const response = await usuariosService.actualizarUsuario(
        logeduser.codigoUsuario,
        valoresRequest
      );

      if (response.status === 200) {
        const usuarioState = {
          ...logeduser,
          ...valores,
          ...response.data,
          sucursal: logeduser.sucursal,
          base64Avatar:
            valores.files && valores.files.length > 0
              ? valoresRequest.imagenBase64
              : logeduser.base64Avatar
        };
        if (valoresRequest.imagenBase64) {
          usuarioState.base64Avatar = valoresRequest.imagenBase64;
        }
        setLogeduser(usuarioState);
        return true;
      }
    } catch (error) {
      throw new Error('error en onSubmit ', error);
    }
    return false;
  };

  useEffect(() => {
    getInformacionUsuario();
  }, []);

  return {
    usuario,
    isLoading,
    valorInicial,
    onSubmit,
    imagenPerfil: logeduser.base64Avatar
  };
};
