import API from './api';
import store from '../states/store.js';

const residuos = {
  listaCoincidencia: function (filtros, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/lista-coincidencia',
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: filtros,
      interceptor: conInterceptor
    });
  },

  listaMatch: function (filtros, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/lista-match',
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: filtros,
      interceptor: conInterceptor
    });
  },

  tipoResiduos: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/tipo-residuos',
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: params,
      interceptor: conInterceptor
    });
  },

  matchAutomatico: function (conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/residuos/match-automatico',
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  matchManual: function (filtros, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/residuos/match',
      params: filtros,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  iniciarTransaccion: function (params, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: '/transacciones',
      data: params,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  resumenTransaccion: function (codigoTrasaccion, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/transacciones/${codigoTrasaccion}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  cerrarTransacciones: function (
    params,
    codigoTrasaccion,
    conInterceptor = true
  ) {
    return API.axios({
      method: 'put',
      url: `/transacciones/${codigoTrasaccion}`,
      data: params,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  residuos: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/residuos`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: params,
      interceptor: conInterceptor
    });
  },

  obtenerResiduo: function (codigoResiduo, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/residuos/${codigoResiduo}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  eliminarResiduo: function (codigoResiduo, conInterceptor = true) {
    return API.axios({
      method: 'delete',
      url: `/residuos/${codigoResiduo}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  editarResiduo: function (codigoResiduo, data, conInterceptor = true) {
    return API.axios({
      method: 'put',
      url: `/residuos/${codigoResiduo}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      data: data,
      interceptor: conInterceptor
    });
  },

  crearResiduo: function (data, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: `/residuos`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      data: data,
      interceptor: conInterceptor
    });
  },

  // GET - Residuos  análisis territorial (Lista paginada)
  obtenerListaResiduosAT: function (params, conInterceptor = true) {
    return API.axios({
      method: 'GET',
      url: '/residuos/analisis-territorial',
      params: params,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  //  GET - Residuos análisis territorial (un registro)
  obtenerListaResiduoAT: function (codigoResiduoAT, conInterceptor = true) {
    return API.axios({
      method: 'GET',
      url: `/residuos/analisis-territorial/${codigoResiduoAT}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  // DELETE - Residuos análisis territorial (un registro)
  eliminarResiduoAT: function (codigoResiduoAT, conInterceptor = true) {
    return API.axios({
      method: 'DELETE',
      url: `/residuos/analisis-territorial/${codigoResiduoAT}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  // PUT - Residuos análisis territorial (un registro)
  editarResiduoAT: function (codigoResiduoAT, data, conInterceptor = true) {
    return API.axios({
      method: 'PUT',
      url: `/residuos/analisis-territorial/${codigoResiduoAT}`,
      data: data,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  // POST - Residuos análisis territorial (un registro)
  crearResiduoAT: function (data, conInterceptor = true) {
    return API.axios({
      method: 'POST',
      url: '/residuos/analisis-territorial',
      data: data,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  // Obtiene una lista de residuos declarados
  obtenerListaResiduosDeclarados: function (params, conInterceptor = true) {
    return API.axios({
      method: 'GET',
      url: rutas.residuosDeclarados,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: params,
      interceptor: conInterceptor
    });
  },

  // Obtiene un residuo declarado
  obtieneResiduoDeclarado: function (
    codigoResiduoDeclarado,
    conInterceptor = true
  ) {
    return API.axios({
      method: 'GET',
      url: `${rutas.residuosDeclarados}/${codigoResiduoDeclarado}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  // Crea un residuo declarado
  crearResiduoDeclarado: function (data, conInterceptor = true) {
    return API.axios({
      method: 'POST',
      url: rutas.residuosDeclarados,
      data: data,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  // Actualiza un residuo declarado
  actualizaResiduoDeclarado: function (
    codigoResiduoDeclarado,
    data,
    conInterceptor = true
  ) {
    return API.axios({
      method: 'PUT',
      url: `${rutas.residuosDeclarados}/${codigoResiduoDeclarado}`,
      data: data,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  // Elimina un residuo declarado
  eliminaResiduoDeclarado: function (
    codigoResiduoDeclarado,
    conInterceptor = true
  ) {
    return API.axios({
      method: 'DELETE',
      url: `${rutas.residuosDeclarados}/${codigoResiduoDeclarado}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  // Obtiene una lista de formato de entrega
  obtenerListaFormatoEntrega: function (params, conInterceptor = true) {
    return API.axios({
      method: 'GET',
      url: rutas.formatoEntrega,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: params,
      interceptor: conInterceptor
    });
  },

  // Levantamiento de datos
  obtieneLevantamientoDatos: function (conInterceptor = true) {
    return API.axios({
      method: 'GET',
      url: `${rutas.levantamientoDatos}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  // Obtiene una lista de tipo de tratamiento
  obtenerListaTipoTratamiento: function (params, conInterceptor = true) {
    return API.axios({
      method: 'GET',
      url: rutas.tipoTratamiento,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: params,
      interceptor: conInterceptor
    });
  }
};

// Rutas de los servicios
const rutas = {
  residuos: '/residuos',
  residuosDeclarados: '/residuos-declarados',
  formatoEntrega: '/formato-entrega',
  levantamientoDatos: '/levantamiento-datos',
  tipoTratamiento: '/tipos-tratamiento'
};

export default residuos;
