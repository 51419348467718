import Heading from 'components/design-system/Heading/Heading';
import imgHomeUser from 'assets/images/home/grafica-home-user.svg';
import { CuadroAdminResumen } from './componentes/CuadroAdminResumen';
import { CuadroRecomendaciones } from './componentes/CuadroRecomendaciones';
import {
  AccountBalance  as AccountBalanceIcon,
  ArrowOutward    as ArrowOutwardIcon,
  ArticleOutlined as ArticleOutlinedIcon,
  CallReceived    as CallReceivedIcon,
  Domain          as DomainIcon,
  FindReplace     as FindReplaceIcon,
  Handshake       as HandshakeIcon,
  InvertColors    as InvertColorsIcon,
  Person          as PersonIcon,
  Warehouse       as WarehouseIcon,
} from '@mui/icons-material';


export const HomeSuperAdmin = ({ logeduser, isLoading, data }) => {
  const resumen = [
    {
      accesor: 'numeroEmpresas',
      titulo: 'NEGOCIOS',
      icono: <AccountBalanceIcon className="w-8 h-8" />,
      descripcion: (
        <>
          Negocios
          <br />
          <b>registrados</b>
        </>
      )
    },
    {
      accesor: 'numeroSucursales',
      titulo: 'SUCURSALES',
      icono: <WarehouseIcon className="w-8 h-8" />,
      descripcion: (
        <>
          Sucursales
          <br />
          <b>registradas</b>
        </>
      )
    },
    {
      accesor: 'numeroTransacciones',
      titulo: 'TRANSACCIONES',
      icono: <HandshakeIcon className="w-8 h-8" />,
      descripcion: (
        <>
          Transacciones
          <br />
          <b>concretadas</b>
        </>
      )
    },
    {
      accesor: 'numeroResiduos',
      titulo: 'DEMANDAS & OFERTAS',
      icono: <ArticleOutlinedIcon className="w-8 h-8" />,
      descripcion: (
        <>
          Solicitudes
          <br />
          <b>activas</b>
        </>
      )
    },
    {
      accesor: 'numeroEmpresasAT',
      titulo: 'EMPRESAS',
      icono: <DomainIcon className="w-8 h-8" />,
      descripcion: (
        <>
          Empresas 
          <br />
          <b>de análisis territorial</b>
        </>
      )
    },
    {
      accesor: 'numeroResiduosAT',
      titulo: 'RESIDUOS',
      icono: <InvertColorsIcon className="w-8 h-8" />,
      descripcion: (
        <>
          Residuos 
          <br />
          <b>de análisis territorial</b>
        </>
      )
    },
    {
      accesor: 'numeroPotencialSinergia',
      titulo: 'POTENCIAL DE SINERGIA',
      icono: <FindReplaceIcon className="w-8 h-8" />,
      descripcion: (
        <>
          Potencial 
          <br />
          <b>de sinergia de empresas</b>
        </>
      )
    },
    {
      accesor: 'numeroUsuarios',
      titulo: 'USUARIOS',
      icono: <PersonIcon className="w-8 h-8" />,
      descripcion: (
        <>
          Usuarios 
          <br />
          <b>registrados</b>
        </>
      )
    },
    {
      accesor: 'numeroResiduosDeclarados',
      titulo: 'RESIDUOS',
      icono: <ArrowOutwardIcon className="w-8 h-8" />,
      descripcion: (
        <>
          Residuos 
          <br />
          <b>declarados</b>
        </>
      )
    },
    {
      accesor: 'numeroRecursosAdquiridos',
      titulo: 'RECURSOS ',
      icono: <CallReceivedIcon className="w-8 h-8" />,
      descripcion: (
        <>
          Recursos  
          <br />
          <b>adquiridos</b>
        </>
      )
    },
  ];

  return (
    <>
      <div
        className="hidden md:block h-56 bg-no-repeat bg-[center_bottom_-24px] relative"
        style={{ backgroundImage: `url(${imgHomeUser})` }}
      >
        <Heading
          className="!text-uv-primary-0 absolute bottom-2 left-0 capitalize"
          type="H1"
        >
          Hola {logeduser.nombreUsuario.toLowerCase()}
        </Heading>
      </div>

      <div className="block md:hidden">
        <Heading className="!text-uv-primary-0 capitalize pb-4" type="H1">
          Hola {logeduser.nombreUsuario.toLowerCase()}
        </Heading>
      </div>

      <CuadroAdminResumen
        usuario={logeduser}
        resumenCuenta={data}
        isLoading={isLoading}
        data={resumen}
      />

      <CuadroRecomendaciones isSuperAdmin={true} />
    </>
  );
};
