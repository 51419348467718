import React from 'react';
import { List } from '@mui/material';
import { List as ListIcon, Assignment, Insights } from '@mui/icons-material';
import ListItemButtonComponent from 'components/design-system/ListItemButton/ListItemButton';
import { RUTAS } from 'const/Dictonary';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import GroupIcon from '@mui/icons-material/Group';
import InventoryIcon from '@mui/icons-material/Inventory';

const SubMenuEmpresa = React.forwardRef(
  (
    {
      isDemandante,
      isOferente,
      loguedUser,
      forceRefresh,
      isSuperAdmin,
      isFuncionario
    },
    ref
  ) => {
    return (
      <List ref={ref}>
        {loguedUser.logeduser &&
        loguedUser.logeduser.empresa &&
        !isFuncionario ? (
          <ListItemButtonComponent
            onClick={forceRefresh(
              '/negocio/editar/' +
                loguedUser.logeduser.sucursal.empresa.codigoEmpresa
            )}
            icon={<AccountBalanceIcon />}
            label={'Mi Negocio'}
          />
        ) : null}

        {loguedUser.logeduser &&
        loguedUser.logeduser.empresa &&
        !isFuncionario ? (
          <ListItemButtonComponent
            onClick={forceRefresh(
              '/negocio/' +
                loguedUser.logeduser.sucursal.empresa.codigoEmpresa +
                '/sucursales'
            )}
            icon={<WarehouseIcon />}
            label={'Sucursales'}
          />
        ) : null}

        {isSuperAdmin ? (
          <ListItemButtonComponent
            onClick={forceRefresh(RUTAS.ADMINISTRAR_NEGOCIOS)}
            icon={<AccountBalanceIcon />}
            label={'Negocios'}
          />
        ) : null}

        {!isFuncionario ? (
          <ListItemButtonComponent
            onClick={forceRefresh(
              `${RUTAS.NEGOCIO}/${
                isSuperAdmin
                  ? 'admin'
                  : loguedUser.logeduser?.sucursal?.empresa?.codigoEmpresa
              }/usuarios`
            )}
            icon={<GroupIcon />}
            label={'Usuarios'}
          />
        ) : null}

        {isDemandante || isOferente || isSuperAdmin ? (
          <ListItemButtonComponent
            onClick={forceRefresh(RUTAS.RESIDUOS)}
            icon={<InventoryIcon />}
            label={'Residuo-recurso'}
          />
        ) : null}

        {!isFuncionario ? (
          <ListItemButtonComponent
            onClick={forceRefresh(RUTAS.ADMINISTRAR_LOGS_ACTIVIDAD)}
            icon={<Assignment />}
            label={'Registro de actividad'}
          />
        ) : null}
      </List>
    );
  }
);

export default SubMenuEmpresa;
