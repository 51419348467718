import { DICTONARY } from 'const/Dictonary';
import styles from './role.module.css';

/**
 *
 * @param rol 'generador' 'receptor' 'asesor' 'gestor' 'transportista' 'oferentedemandante'
 * @param tipo  'etiqueta' 'marcador', 'icono'
 * @param verbo  'accion' 'nombre'
 * @param size 'm', 's'
 * @returns
 */
export const RoleComponent = props => {
  const { arrayRol, ...rest } = props;
  if (arrayRol && arrayRol.length > 0) {
    return <MultipleRole roles={arrayRol} {...rest} />;
  } else {
    return <SingleRole roles={arrayRol} {...rest} />;
  }
};

const MultipleRole = props => {
  const { roles, ...rest } = props;
  const arrayRole = roles.map(item => item.codigoRol);
  if (
    arrayRole.includes(DICTONARY.ROL.GENERADOR.CODIGO) &&
    arrayRole.includes(DICTONARY.ROL.RECEPTOR.CODIGO)
  ) {
    return <SingleRole rol="oferentedemandante" {...rest} />;
  } else if (arrayRole.includes(DICTONARY.ROL.GENERADOR.CODIGO)) {
    return <SingleRole rol="generador" {...rest} />;
  } else if (arrayRole.includes(DICTONARY.ROL.RECEPTOR.CODIGO)) {
    return <SingleRole rol="receptor" {...rest} />;
  } else if (arrayRole.includes(DICTONARY.ROL.TRANSPORTISTA.CODIGO)) {
    return <SingleRole rol="transportista" {...rest} />;
  } else if (arrayRole.includes(DICTONARY.ROL.ASESOR.CODIGO)) {
    return <SingleRole rol="asesor" {...rest} />;
  } else {
    return <SingleRole rol="gestor" {...rest} />;
  }
};

const SingleRole = props => {
  let {
    rol =  DICTONARY.ROL.RECEPTOR.NOMBRE ,
    tipo = 'etiqueta',
    verbo = 'accion',
    className,
    ...rest
  } = props;

  // Si el rol es oferente o demandante, se cambia a generador o receptor
  switch (rol) {
    case 'oferente':
      rol = DICTONARY.ROL.GENERADOR.NOMBRE;
      break;
    case 'demandante':
      rol = DICTONARY.ROL.RECEPTOR.NOMBRE;
      break;
    default:
      break;
  }


  return (
    <>
      {tipo === 'etiqueta' && (
        <Etiqueta verbo={verbo} className={className} rol={rol} {...rest} />
      )}

      {tipo === 'marcador' && (
        <Marcador verbo={verbo} className={className} rol={rol} {...rest} />
      )}

      {tipo === 'icono' && <Icono className={className} rol={rol} {...rest} />}
    </>
  );
};

const Etiqueta = props => {
  const { verbo, className, rol, ...rest } = props;
  const { ICONO, ACCION, NOMBRE } = DICTONARY.ROL[rol.toUpperCase()]; 

  return (
    <div
      className={`
        ${className}
        ${styles['etiqueta']}
        ${styles[rol]}
      `}
      {...rest}
    >
      <ICONO className={styles.iconoEtiqueta} />
      {verbo === 'accion' ? <span>{ACCION}</span> : <span>{NOMBRE}</span>}
    </div>
  );
};

const Marcador = props => {
  const { verbo, className, rol, ...rest } = props;
  const { ICONO } = DICTONARY.ROL[rol.toUpperCase()] || DICTONARY.ROL.GENERADOR;
  return (
    <div
      className={`
        ${className}
        ${styles['marcador']}
        ${styles[rol]}
      `}
      {...rest}
    >
      <ICONO className={styles.iconoMarcador} />
    </div>
  );
};

const Icono = props => {
  const { className, rol } = props;
  const { ICONO } = DICTONARY.ROL[rol.toUpperCase()];

  return (
    <ICONO
      className={`
        ${className}
        ${styles.iconoMarcador}
        ${styles['icono-' + rol]}
      `}
    />
  );
};

// const getArrayRole = role => {};
