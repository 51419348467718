import { useCallback, useEffect } from 'react';
// Librarys
// import { useHistory } from 'react-router-dom';
import moment from 'moment';
// Material
import { Add, Close, Delete, Edit } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Hidden,
  Tooltip,
  DialogActions
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// Store
// import store from 'states/store';
// Layouts
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
// Design System
import AlertComponent from 'components/design-system/Alert/Alert';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import Table from 'components/design-system/Table/Table';
import Text from 'components/design-system/Text/Text';
// Rutas
import { useServicesRecursosAdquiridos } from '../hooks/useServicesRecursosAdquiridos';
import { useColumnTablesListadoRecursosAdquiridos } from '../hooks/useColumnTablesListadoRecursosAdquiridos';
import { useHelpersRecursosAdquiridos } from '../hooks/useHelpersRecursosAdquiridos';
import { diccionarioVista } from '../utils/utils';

const ListadoRecursosAdquiridos = () => {
  // const history = useHistory();
  // const { isSuperAdmin } = store?.loguedUser?.perfiles;

  // Columns
  const columnTables = useColumnTablesListadoRecursosAdquiridos();

  // Helpers
  const {
    handleClickIngresar,
    handleClickEditar,
    handleClickEliminar,
    handleClickDuplicar,
    handleClickOpen,
    handleClose,
    setOpen,
    open,
    selectedRow
  } = useHelpersRecursosAdquiridos();

  // Exportaciones de useServicesRecursosAdquiridos
  const {
    //Funciones
    ObtieneListaRecursosAdquiridos,
    // States
    recursosAdquiridos,
    setRecursosAdquiridos,
    isLoadingLista,
    setIsLoadingLista,
    pagination,
    setPagination,
    rowCount,
    setRowCount,
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters
  } = useServicesRecursosAdquiridos();

  const cargaRecursosAdquiridos = useCallback(async () => {
    await ObtieneListaRecursosAdquiridos(
      columnFilters,
      setIsLoadingLista,
      setRecursosAdquiridos,
      pagination,
      setRowCount
    );
  }, [
    columnFilters,
    ObtieneListaRecursosAdquiridos,
    pagination,
    setIsLoadingLista,
    setRecursosAdquiridos,
    setRowCount
  ]);

  useEffect(
    () => {
      ObtieneListaRecursosAdquiridos(
        columnFilters,
        setIsLoadingLista,
        setRecursosAdquiridos,
        pagination,
        setRowCount
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      pagination.pageIndex,
      pagination.pageSize,
      columnFilters,
      pagination,
      setIsLoadingLista,
      setRecursosAdquiridos,
      setRowCount
    ]
  );

  return (
    <SidebarLayout>
      <div className="flex flex-col sm:flex-row sm:items-center mb-4 sm:justify-between gap-4">
        <div>
          <Heading type="h2" className="mb-0">
            {diccionarioVista.tituloListado || 'Sin título'}
          </Heading>
        </div>
        <div>
          <ButtonComponent type="primary" onClick={handleClickIngresar}>
            <Add className="mr-2" />{' '}
            {`Nuevo ${diccionarioVista.nombrePrincipalSingular}`}
          </ButtonComponent>
        </div>
      </div>
      <Table
        headTable={
          <Heading type="h4" className="p-2">
            {diccionarioVista.tituloTabla || 'Sin título'}
          </Heading>
        }
        data={recursosAdquiridos || []}
        columns={columnTables}
        rowCount={rowCount}
        isLoading={isLoadingLista}
        editing={true}
        enablePagination={true}
        enableGlobalFilter={false}
        enableEditing={true}
        onPaginationChange={setPagination}
        state={{
          columnFilters,
          pagination,
          isLoading: isLoadingLista,
          showProgressBars: isLoadingLista,
          globalFilter
          // columnVisibility: {
          //   'sucursal.empresa.nombreEmpresa': isSuperAdmin ? true : false
          // }
        }}
        manualPagination
        actions={({ row, table }) => (
          <div>
            <Tooltip arrow placement="left" title="Duplicar">
              <IconButtonComponent
                size="small"
                className="mr-2"
                disabled={isLoadingLista}
                type={isLoadingLista ? 'disabled' : 'secondary'}
                onClick={() => {
                  handleClickDuplicar(row, cargaRecursosAdquiridos);
                }}
              >
                <ContentCopyIcon />
              </IconButtonComponent>
            </Tooltip>
            <Tooltip arrow placement="left" title="Editar">
              <IconButtonComponent
                size="small"
                className="mr-2"
                disabled={isLoadingLista}
                type={isLoadingLista ? 'disabled' : 'secondary'}
                onClick={() => handleClickEditar(row, table)}
              >
                <Edit />
              </IconButtonComponent>
            </Tooltip>
            <Tooltip arrow placement="right" title="Eliminar">
              <IconButtonComponent
                size="small"
                disabled={isLoadingLista}
                type={isLoadingLista ? 'disabled' : 'error'}
                onClick={() => handleClickOpen(row, table)}
              >
                <Delete />
              </IconButtonComponent>
            </Tooltip>
          </div>
        )}
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="border-b border-neutral-90"
        >
          <Heading type="h3" className="mb-0">
            Confirmación de eliminación de recurso adquirido
          </Heading>
        </DialogTitle>
        <DialogContent className="p-5">
          <DialogContentText id="alert-dialog-description" className="pt-4">
            <Text>
              ¿Estás seguro que deseas eliminar completamente este recurso
              adquirido?
            </Text>
            <div className="my-4">
              <div className="grid grid-cols-12 sm:gap-4">
                <div className="col-span-12 sm:col-span-3 sm:text-end">
                  <Text type="span">SUCURSAL DE RECEPCIÓN</Text>
                </div>
                <div className="col-span-12 sm:col-span-9 mb-4 sm:mb-0">
                  <Text>
                    {selectedRow.original?.sucursal?.nombreSucursal ||
                      'sin información'}
                  </Text>
                </div>

                <div className="col-span-12 sm:col-span-3 sm:text-end">
                  <Text type="span">CANTIDAD (unidad medida)</Text>
                </div>
                <div className="col-span-12 sm:col-span-9 mb-4 sm:mb-0">
                  <Text>{selectedRow.original?.cantidadUnidad || 'N/A'}</Text>
                </div>

                <div className="col-span-12 sm:col-span-3 sm:text-end">
                  <Text type="span">TIPO RECURSO ADQUIRIDO</Text>
                </div>
                <div className="col-span-12 sm:col-span-9 mb-4 sm:mb-0">
                  <Text>
                    {selectedRow.original?.tipoRecursoAdquirido
                      ?.nombreTipoRecursoAdquirido || 'sin información'}
                  </Text>
                </div>

                <div className="col-span-12 sm:col-span-3 sm:text-end">
                  <Text type="span">FECHA DE ADQUISICIÓN</Text>
                </div>
                <div className="col-span-12 sm:col-span-9 mb-4 sm:mb-0">
                  <Text>
                    {selectedRow.original?.fechaAdquisicion !== 'N/A'
                      ? moment(selectedRow.original?.fechaAdquisicion).format(
                          'DD-MM-YYYY'
                        )
                      : 'N/A'}
                  </Text>
                </div>
              </div>
            </div>
            <AlertComponent type="error">
              Esta operación es irreversible y{' '}
              <b>
                se perderán todos los datos asociados a este recurso adquirido
              </b>
            </AlertComponent>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="border-t border-neutral-90 p-4 justify-between">
          <ButtonComponent
            type="secondary"
            onClick={handleClose}
            className="justify-center"
          >
            <Hidden smDown>Cancelar</Hidden>
            <Hidden smUp>
              <Close />
            </Hidden>
          </ButtonComponent>
          <ButtonComponent
            type="primary"
            onClick={() =>
              handleClickEliminar(
                selectedRow,
                recursosAdquiridos,
                setRecursosAdquiridos,
                setOpen,
                cargaRecursosAdquiridos
              )
            }
            autoFocus
            className="w-full xs:w-auto justify-center"
          >
            Confirmar
          </ButtonComponent>
        </DialogActions>
      </Dialog>
    </SidebarLayout>
  );
};

export default ListadoRecursosAdquiridos;
