import API from './api';
import store from '../states/store.js';

const adminService = {
  getPerfiles: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/perfiles`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: params,
      interceptor: conInterceptor
    });
  },

  getPerfil: function (codigoPerfil, params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/perfiles/${codigoPerfil}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: params,
      interceptor: conInterceptor
    });
  }
};

export default adminService;
