import { useMemo } from 'react';
import moment from 'moment';
import store from 'states/store';
import Text from 'components/design-system/Text/Text';

export const useColumnTablesListadoResiduos= () => {
  const { isSuperAdmin, isAdmin, isFuncionario } = store.loguedUser.perfiles;
  const { isDemandante, isOferente } = store.loguedUser.tipos;

  const columnTables = useMemo(
    () => [
      {
        header: 'Nombre rol',
        enableGlobalFilter: false,
        accessorKey: 'rol.nombreRol',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>
      },
      {
        header: isSuperAdmin
          ? 'Nombre de residuo / Nombre LER'
          : isDemandante
          ? 'Nombre de residuo'
          : isOferente
          ? 'Nombre LER'
          : '',
        accessorKey: 'nombre',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>,
        enableColumnActions: false,
        enableColumnFilter: false
      },
      {
        header: 'Empresa',
        accessorKey: 'sucursal.empresa.nombreEmpresa',
        Cell: ({ renderedCellValue }) => (
          <Text>{renderedCellValue ? renderedCellValue : '-'}</Text>
        )
      },
      {
        header: 'Sucursal',
        accessorKey: 'sucursal.nombreSucursal',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>
      },

      {
        header: 'Cantidad',
        enableGlobalFilter: false,
        accessorKey: 'cantidad',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>,
        enableColumnActions: false,
        enableColumnFilter: false
      },
      {
        header: 'Fecha disponibilidad',
        enableGlobalFilter: false,
        accessorKey: 'fechaDisponibilidad',
        Cell: ({ renderedCellValue }) => (
          <Text>
            {' '}
            {renderedCellValue !== 'N/A'
              ? moment(renderedCellValue).format('DD-MM-YYYY')
              : 'N/A'}
          </Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false
      }
    ],
    [isDemandante, isOferente, isSuperAdmin]
  );

  return columnTables;
};
