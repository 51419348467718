
import {
  SchemaIndentificarRSD,
  SchemaEspecificacionOferta,
  SchemaMaterialAdjunto,
  SchemaEnvioFormulario,
} from './oferente';


const SchemaOferente = [
  null, // El índice 0 se mantiene nulo para que los índices coincidan con los números que mencionas.
  SchemaIndentificarRSD,
  SchemaEspecificacionOferta,
  SchemaMaterialAdjunto,
  SchemaEnvioFormulario,
];

export { SchemaOferente };
