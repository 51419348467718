

// importar archivo css
import { Container, Grid } from '@mui/material';
import './steppers.scss';

export const SteppersComponent = ({
  stepsLine,
  step,
  setStep,
}) => {

  return (

    <div className={"steped-bread"}>.
      <Container maxWidth="lg">
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-start"
        >
          {stepsLine.map((s, i) => {
            return (
              <Grid key={i + s.number} item lg={4} md={4} sm={4} xs={12} >
                <span 
                  className="step-span"
                  onClick={() => setStep(s.number)}
                >
                <div
                  className={`step ${step === s.number ? 'step-active' : ''}`}
                >
                  <div className="step-number-div">
                    <span className="step-number">{s.number}</span>
                    <div
                      className={`behind-line ${
                        s.classLine ? s.classLine : ''
                      }`}
                    ></div>
                  </div>
                  <span className="step-name">
                    {s.title}
                    <br />
                    {s.subtitle}
                  </span>
                </div>
              </span>
            </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  )
}
