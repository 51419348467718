import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
// Services
import usuariosService from 'services/usuarios';
import Skeleton from '@mui/material/Skeleton';

// JWT decode
import jwt from 'jwt-decode';
import { RUTAS } from 'const/Dictonary';
import Loading from 'components/Loading/Loading';
import { useLogout } from 'hooks/useLogout';

import storeMessages from '../../states/alertMessages/store';

const VerificaUsuarioSucursal = props => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const history = useHistory();
  const handleLogout = useLogout();

  const [verificandoCorreo, setVeficandoCorreo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const [localStorageUser, setLocalStorageUser] = useState(localStorage.getItem('logeduser') || null);


  // decifrando jwt
  const decifrarToken = token => {
    try {
      const decifrado = jwt(token);
      return decifrado;
    } catch (error) {
      console.log(error);
      history.push(`${RUTAS.HOME}`);
      return null;
    }
  
  };

  const [tokenDecifrado] = useState(decifrarToken(token) || {});
  const {
    correoElectronico
    /* 
                    - nombre usuario invitado
                    - nombre usuario que lo invitó
                    - nombre empresa
                    - nombre sucursal ( actualmente solo recibo el codigo )
      */
  } = tokenDecifrado;


  useEffect(() => {
    let isMounted = true;
  
    const redirectToHome = () => {
      setTimeout(() => {
        localStorage.setItem('verificaUsuarioSucursal', true);
        setVeficandoCorreo(true);
        storeMessages.message.setMessage({
          mensaje: 'Verificación completada correctamente',
          tipo: 'exito'
        });
        history.push(`${RUTAS.HOME}`, {
          correoElectronico: correoElectronico
        });
      }, 3000);
    };
  
    const handleConfirmation = async () => {
      try {
        const result = await usuariosService.confirmarInvitacion(token, false);
        if (isMounted && result.status === 200) {
          setIsLoading(false);
          setVeficandoCorreo(true);
          redirectToHome();
        }
      } catch (error) {
        setIsLoading(false);
        console.log('error :>> ', error);
        setVeficandoCorreo(false);
        setError(error);
        redirectToHome(); // Redireccionar en caso de error
      }
    };
  
    // Handle confirmation on mount
    if (token) {
      handleConfirmation();
    }
  
    return () => {
      isMounted = false;
    };
  }, [correoElectronico, history, token]);
  
  useEffect(() => {
    // Redirect to home if localStorageUser changes
    const redirectToHome = () => {
      setTimeout(() => {
        history.push(`${RUTAS.HOME}`, {
          correoElectronico: correoElectronico
        });
      }, 3000);
    };
  
    if (!localStorageUser) {
      setTimeout(redirectToHome, 3000);
      return;
    }
  
    const user = JSON.parse(localStorageUser);
    const correoElectronicoUser = user.correoElectronico;
  
    if (correoElectronicoUser === correoElectronico) {
      setVeficandoCorreo(true);
      setTimeout(redirectToHome, 3000);
      return;
    }
  
    setTimeout(() => {
      handleLogout();    
    }, 3000);

  }, [correoElectronico, localStorageUser, handleLogout, history]);
  

  return !isLoading ? (
    <div className="max-w-[550px] w-full text-center m-auto">
      {!error ? (
        <div className="bg-white rounded shadow-md py-10 px-14 mt-5">
          <Heading type="h1" className="mb-8">
            {verificandoCorreo ? 'Verificación completada' : 'Verificando'}
          </Heading>
          {verificandoCorreo ? (
            <Text>Serás redireccionado automáticamente a tu cuenta</Text>
          ) : (
            <> </>
          )}

          <div className="flex items-center justify-center pt-5">
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              className="m-1"
            />
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              className="m-1"
            />
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              className="m-1"
            />
          </div>
        </div>
      ) : (
        <div className="bg-white rounded shadow-md py-10 px-14 mt-5">
          <Heading type="h1" className="mb-8">
            Error de verificación
          </Heading>
          <Text>
            No se ha podido verificar correctamente el correo electrónico.
            <br />
            Puede que la URL a la cual has ingresado no es correcta, para eso
            vuelve a revisar el enlace original recibido.
          </Text>
        </div>
      )}
    </div>
  ) : (
    <Loading />
  );
};

export default VerificaUsuarioSucursal;
