// Importaciones de react
import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useInitalValues } from './useInitalValues';
import storeMessages from 'states/alertMessages/store';
import serviceResiduos from 'services/residuos';
import serviceDocumentos from 'services/documentos';
import { unionRecursos } from '../Helpers/useTransformaciones';
import {
  calculoSubTipoResiduos,
  cargaRecursoReemplazoFiltrado
} from '../useServicesResiduos';
import { numeroFrontABaseDatos } from 'helpers/formateoNumeros';
import { DICTONARY } from 'const/Dictonary';

export const useSubmitResiduos = (codigoResiduo, nombreRol) => {
  const navigate = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const [estadoGuardado, setEstadoGuardado] = useState(false);

  const {
    residuoDemandante,
    setResiduoDemandante,
    residuoOferente,
    setResiduoOferente
  } = useInitalValues();

  const [codigoNivelAnterior, setCodigoNivelAnterior] = useState(false);
  const [residuoEditar, setResiduoEditar] = useState(false);
  const [codigoNivelAnteriorReemplazo, setCodigoNivelAnteriorReemplazo] =
    useState(false);
  const [recursoReemplazoEditar, setRecursoReemplazoEditar] = useState(false);

  useEffect(() => {
    cargaRecursoReemplazoFiltrado({
      codigoRecursoReemplazo: codigoNivelAnteriorReemplazo,
      setRecursoReemplazo: setRecursoReemplazoEditar
    });
  }, [codigoNivelAnteriorReemplazo]);

  useEffect(() => {
    calculoSubTipoResiduos({
      codigoTipoResiduo: codigoNivelAnterior,
      setResiduo: setResiduoEditar
    });
  }, [codigoNivelAnterior]);

  const cargaResiduo = useCallback(async () => {
    // Esperando que tome el codigo por parametro
    if (!codigoResiduo) return;
    setIsLoading(true);

    try {
      const response = await serviceResiduos.obtenerResiduo(codigoResiduo);

      const { data } = response;

      if (response.status === 200) {
        if (nombreRol === DICTONARY.ROL.RECEPTOR.NOMBRE) {
          setCodigoNivelAnterior(
            data.tipoResiduo.codigoNivelAnteriorTipoResiduo
          );
          setCodigoNivelAnteriorReemplazo(
            data.recursoReemplazos[0].codigoNivelAnteriorReemplazo
          );

          let dataFiltrada = {};

          dataFiltrada = {
            rol: {
              codigoRol: 2
            },
            codigoSucursal: data.sucursal.codigoSucursal || '',
            nombreSucursal: data.sucursal.nombreSucursal || '',
            tipoResiduo: residuoEditar,
            subTipoResiduo: data.tipoResiduo || {}, // TODO: Cambiar por subTipoResiduo
            tieneSubTipoResiduo: data.tipoResiduo ? true : false,

            nombreResiduo: data.nombreResiduo || '',
            tipoRecurso: data.recursos || [],
            tieneTipoRecursos: data.recursos.length > 0 ? true : false,
            otroTipoRecurso: data.recursosOtros || [],

            sucursal: data.sucursal || {},
            recursoReemplazo: recursoReemplazoEditar,
            subTipoRecursoReemplazo: data.recursoReemplazos[0] || {},
            tieneTipoRecursosReemplazo:
              data.recursoReemplazos.length > 0 ? true : false,
            descripcionAdicional: data.descripcionAdicional || '',
            direccion: data.sucursal.direccion || {}
          };

          const { nombreVia, comuna } = dataFiltrada.direccion;
          const { nombreComuna, region } = comuna;
          const direccionFormateada = `${nombreVia}, ${nombreComuna}, Región ${region.nombreRegion}`;

          dataFiltrada.nombreDireccionSucursal = direccionFormateada;
          setResiduoDemandante(dataFiltrada);
        } else if (nombreRol ===  DICTONARY.ROL.GENERADOR.NOMBRE) {
          setCodigoNivelAnterior(
            data.tipoResiduo.codigoNivelAnteriorTipoResiduo
          );
          let dataFiltrada = {};

          dataFiltrada = {
            rol: {
              codigoRol: 1
            },
            // Nombre LER
            nombreLER: [],
            // Nombre Sub Tipo LER
            nombreSubLER: [],
            // Nombre Sub Sub Tipo LER
            nombreSubSubLER: {
              codigoLER: data.nombreLER[0].codigoLER,
              nombreLER: data.nombreLER[0].nombreLER,
              codigoNivelAnterior: data.nombreLER[0].codigoNivelAnterior
            },
            // Tipo de Residuo
            tipoResiduo: residuoEditar,
            // SubTipo de Residuo
            subTipoResiduo: {
              codigoTipoResiduo: data.tipoResiduo.codigoTipoResiduo,
              nombreTipo: data.tipoResiduo.nombreTipo,
              nivel: data.tipoResiduo.nivel,
              codigoNivelAnteriorTipoResiduo:
                data.tipoResiduo.codigoNivelAnteriorTipoResiduo
            },

            tieneSubTipoResiduo: true,

            // Otro Tipo de Residuo
            otroSubtipoResiduo: '',
            tieneOtroSubtipoResiduo: false,

            // Tipo de Recursos
            tipoRecurso: data.recursos || [],
            otroTipoRecurso: [],

            // Descipción adicional
            descripcionAdicional: data.descripcionAdicional,

            // PASO 2
            codigoSucursal: data.sucursal.codigoSucursal,
            nombreSucursal: data.sucursal.nombreSucursal,
            precioReferencial: data.precioReferencial,
            frecuencia: data.frecuencia,
            fechaDisponibilidad: moment(data.fechaDisponibilidad),
            formatoEntrega: data.formatoEntrega,
            cantidad: data.cantidad,
            unidad: data.unidad,
            levantamientoDatos: data.levantamientoDatos,

            // Others
            direccion: data.sucursal.direccion,

            // PASO 3
            // Archivos

            documentos: [],
            imagenes: [],

            interactuandoImagenes: false,
            interactuandoDocumentos: false,

            imagenesEliminadas: [],
            documentosEliminados: [],

            eliminandoImagenes: false,
            eliminandoDocumentos: false,

            // Datos de la sucursal
            empresa: data.sucursal.empresa
          };

          const { nombreVia, comuna } = dataFiltrada.direccion;
          const { nombreComuna, region } = comuna;
          const direccionFormateada = `${nombreVia}, ${nombreComuna}, Región ${region.nombreRegion}`;

          dataFiltrada.nombreDireccionSucursal = direccionFormateada;

          setResiduoOferente(dataFiltrada);
        }
      }
    } catch (error) {
      setResiduoDemandante({});
      setResiduoOferente({});
      console.log('Error en cargaResiduo ', error);
      throw new Error('Error en cargaResiduo ', error);
    } finally {
      if (residuoEditar && recursoReemplazoEditar) {
        setIsLoading(false);
      } else if (residuoEditar && nombreRol === DICTONARY.ROL.GENERADOR.NOMBRE) {
        setIsLoading(false);
      }
    }
  }, [
    codigoResiduo,
    nombreRol,
    recursoReemplazoEditar,
    residuoEditar,
    setResiduoDemandante,
    setResiduoOferente
  ]);

  const handleSubmit = async (valores, tipoFormularioSeleccionado) => {
    try {
      if (nombreRol === DICTONARY.ROL.RECEPTOR.NOMBRE) {
        if (
          tipoFormularioSeleccionado.ingresar ||
          tipoFormularioSeleccionado.duplicar
        ) {
          await submitNuevoResiduoDemandante(
            valores,
            tipoFormularioSeleccionado
          );
        } else if (tipoFormularioSeleccionado.editar) {
          await submitActualizarResiduoDemandante(valores);
        }
      } else if (nombreRol === DICTONARY.ROL.GENERADOR.NOMBRE) {
        if (
          tipoFormularioSeleccionado.ingresar ||
          tipoFormularioSeleccionado.duplicar
        ) {
          await submitNuevoResiduoOferente(valores, tipoFormularioSeleccionado);
        } else if (tipoFormularioSeleccionado.editar) {
          await submitActualizarResiduoOferente(valores);
        }
      }
    } catch (error) {
      console.log('Error en handleSubmit ', error);
      setEstadoGuardado({
        guardado: false,
        errorGuardado: true,
        mensajeError: error
      });
      // throw new Error('Error en handleSubmit ', error);
    }
  };

  /**
   * DEMANDANTE
   * @param {*} tipoFormulario
   *
   */

  const submitNuevoResiduoDemandante = async (valores, tipoFormulario) => {
    const generar = tipoFormulario.ingresar ? 'crear' : 'duplicar';
    const generarado = tipoFormulario.ingresar ? 'creado' : 'duplicado';
    try {
      const residuosData = estructuraServicioNuevoResiduoDemandante(valores);

      const response = await serviceResiduos.crearResiduo(residuosData);
      if (response.status === 200) {
        storeMessages.message.setMessage({
          mensaje: `Se ha ${generarado} el residuo correctamente`,
          tipo: 'exito'
        });
        // history.push(RUTAS.);
      }
    } catch (error) {
      console.log(`Error en ${generar} un residuo ${error}`);
      storeMessages.message.setMessage({
        mensaje: `No se ha podido ${generar} el residuo`,
        tipo: 'error'
      });
      throw new Error('Error en submitNuevoResiduoDeclarado ', error);
    }
  };

  const submitActualizarResiduoDemandante = async valores => {
    try {
      const residuosData = estructuraServicioNuevoResiduoDemandante(valores);

      const response = await serviceResiduos.editarResiduo(
        codigoResiduo,
        residuosData
      );
      if (response.status === 200) {
        storeMessages.message.setMessage({
          mensaje: `Se ha actualizado el residuo correctamente`,
          tipo: 'exito'
        });
        // history.push(RUTAS.);
      }
    } catch (error) {
      console.log(`Error en actualizar un residuo ${error}`);
      storeMessages.message.setMessage({
        mensaje: `No se ha podido actualizar el residuo`,
        tipo: 'error'
      });
      throw new Error('Error en submitActualizarResiduo ', error);
    }
  };

  /**
   * OFERENTE
   * @param {*} tipoFormulario
   *
   *
   */

  const submitNuevoResiduoOferente = async (valores, tipoFormulario) => {
    const generar = tipoFormulario.ingresar ? 'crear' : 'duplicar';
    const generarado = tipoFormulario.ingresar ? 'creado' : 'duplicado';

    const formatoImagenes = valores.imagenes.map(archivo => {
      return {
        nombreArchivo: archivo.nombreArchivo,
        base64: archivo.base64.split(';base64,').pop(),
        tipoArchivo: archivo.tipoArchivo
      };
    });

    const formatoArchivos = valores.archivos.map(archivo => {
      return {
        nombreArchivo: archivo.nombreArchivo,
        base64: archivo.base64.split(';base64,').pop(),
        tipoArchivo: archivo.extension
      };
    });

    try {
      const residuosData = estructuraServicioNuevoResiduoOferente(valores);

      const response = await serviceResiduos.crearResiduo(residuosData);
      if (response.status === 200) {
        storeMessages.message.setMessage({
          mensaje: `Se ha ${generarado} el residuo correctamente`,
          tipo: 'exito'
        });
        // history.push(RUTAS.);
        const { codigoResiduo } = response.data;
        const dataImagenes = {
          codigoResiduo: codigoResiduo,
          archivos: formatoImagenes ? formatoImagenes : []
        };

        const dataArchivos = {
          codigoResiduo: codigoResiduo,
          archivos: formatoArchivos ? formatoArchivos : []
        };

        // Servicios Imagenes
        try {
          setIsLoading(true);
          const response = serviceDocumentos.guardar(dataImagenes);
          if (response.status === 200) {
            storeMessages.message.setMessage({
              mensaje: 'Se han guardado las imagenes correctamente',
              tipo: 'exito'
            });
          }
        } catch (error) {
          console.log(error);
          storeMessages.message.setMessage({
            mensaje:
              'Se ha producido un error al guardar los imagenes, Si el problema persiste comunícate con el administrador del sitio',
            tipo: 'error'
          });
        } finally {
          setIsLoading(false);
        }

        // Servicios Archivos
        try {
          setIsLoading(true);
          const response = serviceDocumentos.guardar(dataArchivos);
          if (response.status === 200) {
            storeMessages.message.setMessage({
              mensaje: 'Se han guardado los archivos correctamente',
              tipo: 'exito'
            });
          }
        } catch (error) {
          storeMessages.message.setMessage({
            mensaje:
              'Se ha producido un error al guardar los archivos, Si el problema persiste comunícate con el administrador del sitio',
            tipo: 'error'
          });
        } finally {
          setIsLoading(false);
        }
      }
    } catch (error) {
      storeMessages.message.setMessage({
        mensaje: `No se ha podido ${generar} el residuo`,
        tipo: 'error'
      });
      throw new Error('Error en submitNuevoResiduoDeclarado ', error);
    }
  };

  const submitActualizarResiduoOferente = async valores => {
    try {
      const residuosData = estructuraServicioNuevoResiduoOferente(valores);

      const response = await serviceResiduos.editarResiduo(
        codigoResiduo,
        residuosData
      );
      if (response.status === 200) {
        storeMessages.message.setMessage({
          mensaje: `Se ha actualizado el residuo correctamente`,
          tipo: 'exito'
        });
        // history.push(RUTAS.);
      }
    } catch (error) {
      storeMessages.message.setMessage({
        mensaje: `No se ha podido actualizar el residuo`,
        tipo: 'error'
      });
      throw new Error('Error en submitActualizarResiduo ', error);
    }
  };

  const estructuraServicioNuevoResiduoDemandante = valores => {
    try {
      const arrayTipoRecurso = unionRecursos(valores);

      const estructuraServicio = {
        rol: {
          codigoRol: valores.rol.codigoRol // Integer (codigoRol registrado en la base de datos)
        },
        sucursal: {
          codigoSucursal: valores.codigoSucursal // Integer (codigoSucursal registrado en la base de datos)
        },
        tipoResiduo: {
          codigoTipoResiduo: valores.subTipoResiduo.codigoTipoResiduo // Integer (codigoTipoResiduo registrado en la base de datos)
        },
        nombreResiduo: valores.nombreResiduo, // String
        recursos: arrayTipoRecurso,
        recursoReemplazos: [
          {
            codigoRecursoReemplazo:
              valores.subTipoRecursoReemplazo.codigoRecursoReemplazo
          }
        ],
        descripcionAdicional: valores.descripcionAdicional // String
      };

      return estructuraServicio;
    } catch (error) {
      console.log('Error en obtenerEstructuraServicio ', error);
      throw new Error('Error en obtenerEstructuraServicio ', error);
    }
  };

  const estructuraServicioNuevoResiduoOferente = valores => {
    try {
      const arrayTipoRecurso = unionRecursos(valores);
      const nombreLER = valores.nombreSubSubLER.codigoLER;
      const estructuraServicio = {
        rol: {
          codigoRol: valores.rol.codigoRol // Integer (codigoRol registrado en la base de datos)
        },
        nombreLER: [
          {
            codigoLER: nombreLER
          }
        ],
        tipoResiduo: {
          codigoTipoResiduo: valores.subTipoResiduo.codigoTipoResiduo // Integer (codigoTipoResiduo registrado en la base de datos)
        },
        recursos: arrayTipoRecurso,
        descripcionAdicional: valores.descripcionAdicional, // String
        sucursal: {
          codigoSucursal: valores.codigoSucursal // Integer (codigoSucursal registrado en la base de datos)
        },
        precioReferencial: numeroFrontABaseDatos(valores.precioReferencial),
        frecuencia: valores.frecuencia,
        fechaDisponibilidad: moment(valores.fechaDisponibilidad).format(
          'YYYY-MM-DD'
        ),
        cantidad: numeroFrontABaseDatos(valores.cantidad),
        unidad: valores.unidad,
        formatoEntrega: valores.formatoEntrega,
        imagenes: valores.imagenes,
        archivos: valores.archivos
      };

      if (valores.levantamientoDatos) {
        estructuraServicio.levantamientoDatos = {
          codigoLevantamientoDatos:
            valores.levantamientoDatos.codigoLevantamientoDatos
        };
      }

      return estructuraServicio;
    } catch (error) {
      console.log('Error en obtenerEstructuraServicio ', error);
      throw new Error('Error en obtenerEstructuraServicio ', error);
    }
  };

  return {
    residuoDemandante,
    setResiduoDemandante,
    residuoOferente,
    setResiduoOferente,
    isLoading,
    setIsLoading,
    estadoGuardado,

    cargaResiduo,
    handleSubmit,
    submitNuevoResiduoDemandante,
    estructuraServicioNuevoResiduoDemandante
  };
};
