import { Delete, FilterList } from '@mui/icons-material';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import ButtonComponent from 'components/design-system/Button/Button';
import LabelComponent from 'components/design-system/Label/Label';
import InputComponent from 'components/design-system/input/Input';
import { DICTONARY } from 'const/Dictonary';
import { Form, Formik } from 'formik';
import { OrdenarAscendente } from 'helpers/manejoArreglos';
import { useEffect, useState } from 'react';
import empresasService from 'services/empresas';
import recursosServices from 'services/recursos';
import roleServices from 'services/roles';
import transaccionesServices from 'services/transacciones';
import store from 'states/store';

export const FiltroForm = ({ isLoading, setFiltros }) => {
  const { isSuperAdmin, isAnalistaTerritorial } = store.loguedUser.perfiles;
  const [cargando, setCargando] = useState(true);
  const [empresas, setEmpresas] = useState([]);
  const [recursos, setRecursos] = useState([]);
  const [estadosTransacciones, setEstadosTransacciones] = useState([]);
  const [roles, setRoles] = useState([]);
  const { sucursal } = store.loguedUser.logeduser;

  useEffect(() => {
    const requestRoles = new Promise((resolve, reject) => {
      roleServices
        .roles(null, DICTONARY.INTERCEPTOR.DESACTIVADO)
        .then(result => {
          if (Array.isArray(result.data.content)) {
            resolve(result.data.content);
          } else {
            resolve([]);
          }
        })
        .catch(error => {
          reject(error);
          console.log('ERROR al cargar roles ', error);
        });
    });

    const requestEstados = new Promise((resolve, reject) => {
      transaccionesServices
        .estados(null, DICTONARY.INTERCEPTOR.DESACTIVADO)
        .then(result => {
          if (Array.isArray(result.data.content)) {
            resolve(result.data.content);
          } else {
            resolve([]);
          }
        })
        .catch(error => {
          reject(error);
          console.log('ERROR al cargar estados de transacciones', error);
        });
    });

    const requestEmpresas = new Promise((resolve, reject) => {
      let params = {
        totalPagina: DICTONARY.PAGINACION.ALL_DATA
      };

      if (isAnalistaTerritorial) {
        const codigoRegion =
          store?.loguedUser?.logeduser?.region?.codigoRegion ??
          DICTONARY.DEFAULT_REGION.REGION_METROPOLITANA;
        params.codigosRegion = codigoRegion;
      }

      empresasService
        .obtieneEmpresas(params, DICTONARY.INTERCEPTOR.DESACTIVADO)
        .then(result => {
          if (Array.isArray(result.data.content)) {
            resolve(
              result.data.content.map(item => ({
                codigoEmpresa: item.codigoEmpresa,
                nombreEmpresa: item.nombreEmpresa
              }))
            );
          } else {
            resolve([]);
          }
        })
        .catch(error => {
          reject(error);
          console.log('ERROR al cargar estados de transacciones', error);
        });
    });

    const requestRecursos = new Promise((resolve, reject) => {
      recursosServices
        .recursos(
          {
            codigoEmpresa: sucursal?.empresa?.codigoEmpresa || null,
            totalPagina: DICTONARY.PAGINACION.ALL_DATA
          },
          DICTONARY.INTERCEPTOR.DESACTIVADO
        )
        .then(result => {
          if (Array.isArray(result.data.content)) {
            resolve(result.data.content);
          } else {
            resolve([]);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

    Promise.all([
      requestEstados,
      requestRoles,
      requestEmpresas,
      requestRecursos
    ])
      .then(result => {
        const listaEstados = result[0];
        const listaRoles = result[1];
        const listaEmpresas = result[2];
        const listaRecursos = result[3];

        setEstadosTransacciones(listaEstados);
        setRoles(
          isSuperAdmin || isAnalistaTerritorial
            ? listaRoles
            : sucursal.empresa.roles
        );
        setEmpresas(
          OrdenarAscendente(listaEmpresas, 'nombreEmpresa', 'string')
        );
        setRecursos(
          OrdenarAscendente(listaRecursos, 'nombreRecurso', 'string')
        );
      })
      .catch(err => {
        console.error('Error al tratar de obtener las listas >> ', err);
      })
      .finally(() => {
        setCargando(false);
      });
  }, []);

  return (
    <Formik
      initialValues={{
        empresa: { codigoEmpresa: '', nombreEmpresa: '' },
        recurso: { codigoRecurso: '', nombreRecurso: '' },
        estadoTransaccion: { codigoEstado: '', nombreEstado: '' },
        rol: { codigoRol: '', nombreRol: '' }
      }}
      onSubmit={(values, { setSubmitting, setFieldValue }) => {
        try {
          setTimeout(async () => {
            let filtros = {};
            if (values.estadoTransaccion.nombreEstado.length > 0) {
              filtros.codigoEstado = values.estadoTransaccion.codigoEstado;
            }
            if (values.empresa.nombreEmpresa.length > 0) {
              if (isSuperAdmin || isAnalistaTerritorial) {
                filtros.codigoEmpresaTransaccion = values.empresa.codigoEmpresa;
              } else {
                filtros.codigoEmpresa = values.empresa.codigoEmpresa;
              }
            }
            if (values.recurso.nombreRecurso.length > 0) {
              filtros.codigoRecurso = values.recurso.codigoRecurso;
            }
            if (values.rol.nombreRol.length > 0) {
              filtros.nombreRol = values.rol.nombreRol;
            }

            setFiltros(filtros);
            setSubmitting(false);
          }, 1500);
        } catch (error) {
          console.log('error >', error);
        }
      }}
      validateOnChange
      validateOnBlur
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        handleChange,
        isSubmitting,
        resetForm
      }) => (
        <Form id="transacciones">
          <div className="grid grid-cols-12 gap-4 ">
            <div className="col-span-12 sm:col-span-6 lg:col-span-3">
              <AutocompleteComponent
                name="empresa"
                fullWidth
                accesor="nombreEmpresa"
                options={empresas}
                disabled={cargando || isLoading || isSubmitting}
                value={values.empresa}
                getOptionLabel={option => (option ? option.nombreEmpresa : '')}
                clearOnEscape={true}
                openOnFocus={true}
                noOptionsText="Sin opciones"
                onBlur={() => setFieldTouched('empresa')}
                onChange={(ev, valueObject, reason) => {
                  let data = {
                    target: {
                      name: 'empresa',
                      value: valueObject
                    }
                  };
                  if (reason === 'clear') {
                    data.target.value = {
                      codigoEmpresa: '',
                      nombreEmpresa: ''
                    };
                  }
                  handleChange(data);
                }}
              >
                <InputComponent
                  autoComplete={'off'}
                  fullWidth
                  label={<LabelComponent>Empresa</LabelComponent>}
                  value={values.empresa.nombreEmpresa}
                  onBlur={handleBlur}
                  touched={touched.empresa}
                  estado={
                    errors.empresa
                      ? { tipo: 'error', mensaje: errors.empresa }
                      : null
                  }
                />
              </AutocompleteComponent>
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-3">
              <AutocompleteComponent
                name="recurso"
                fullWidth
                accesor="nombreRecurso"
                options={recursos}
                disabled={cargando || isLoading || isSubmitting}
                onChange={(ev, valueObject, reason) => {
                  let data = {
                    target: {
                      name: 'recurso',
                      value: valueObject
                    }
                  };
                  if (reason === 'clear') {
                    data.target.value = {
                      codigoRecurso: '',
                      nombreRecurso: ''
                    };
                  }
                  handleChange(data);
                }}
                onBlur={() => setFieldTouched('recurso')}
                value={values.recurso}
                getOptionLabel={option => (option ? option.nombreRecurso : '')}
                clearOnEscape={true}
                openOnFocus={true}
                noOptionsText="Sin opciones"
              >
                <InputComponent
                  autoComplete={'off'}
                  fullWidth
                  label={<LabelComponent>Recurso</LabelComponent>}
                  estado={
                    errors.recurso
                      ? { tipo: 'error', mensaje: errors.recurso }
                      : null
                  }
                />
              </AutocompleteComponent>
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-2">
              <AutocompleteComponent
                name="estadoTransaccion"
                accesor="nombreEstado"
                disabled={cargando || isLoading || isSubmitting}
                options={estadosTransacciones}
                value={values.estadoTransaccion}
                getOptionLabel={option => (option ? option.nombreEstado : '')}
                clearOnEscape={true}
                openOnFocus={true}
                noOptionsText="Sin opciones"
                onBlur={() => setFieldTouched('estadoTransaccion')}
                onChange={(ev, estadoTransaccion, reason) => {
                  let data = {
                    target: {
                      name: 'estadoTransaccion',
                      value: estadoTransaccion
                    }
                  };
                  if (reason === 'clear') {
                    data.target.value = { codigoEstado: '', nombreEstado: '' };
                  }
                  handleChange(data);
                }}
              >
                <InputComponent
                  className="h-9"
                  fullWidth
                  label={<LabelComponent>Estado transacción</LabelComponent>}
                  touched={touched.codigosTipoResiduo ? true : false}
                  estado={
                    errors.estadoTransaccion
                      ? { tipo: 'error', mensaje: errors.estadoTransaccion }
                      : null
                  }
                />
              </AutocompleteComponent>
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-2">
              <AutocompleteComponent
                name="rol"
                accesor="nombreRol"
                disabled={cargando || isLoading || isSubmitting}
                options={roles}
                value={values.rol}
                getOptionLabel={option => (option ? option.nombreRol : '')}
                clearOnEscape={true}
                openOnFocus={true}
                noOptionsText="Sin opciones"
                onBlur={() => setFieldTouched('rol')}
                onChange={(ev, value, reason) => {
                  let data = {
                    target: {
                      name: 'rol',
                      value: value
                    }
                  };
                  if (reason === 'clear') {
                    data.target.value = { codigoRol: '', nombreRol: '' };
                  }
                  handleChange(data);
                }}
              >
                <InputComponent
                  className="h-9"
                  fullWidth
                  label={<LabelComponent>Rol</LabelComponent>}
                  touched={touched.rol ? true : false}
                  estado={
                    errors.rol ? { tipo: 'error', mensaje: errors.rol } : null
                  }
                />
              </AutocompleteComponent>
            </div>
            <div className="col-span-4 xs:col-span-4 sm:col-span-2 md:col-span-2 lg:col-span-1 pt-7">
              <ButtonComponent
                fullWidth
                type="secondary"
                className="justify-center"
                onClick={() => resetForm()}
              >
                <Delete />
              </ButtonComponent>
            </div>
            <div className="col-span-8 xs:col-span-8 sm:col-span-5 md:col-span-4 lg:col-span-1 pt-7">
              <ButtonComponent
                fullWidth
                form="transacciones"
                buttonType="submit"
                type="primary"
                isLoading={isSubmitting}
                disabled={isSubmitting || isLoading || cargando}
                className="justify-center"
              >
                <span className="hidden xs:block lg:hidden mr-2">
                  Filtrar información
                </span>
                <FilterList className="" />
              </ButtonComponent>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
