import { Divider } from '@mui/material';
import Heading from 'components/design-system/Heading/Heading';
import { useEffect, useState } from 'react';
import GraficoNodos from './GraficoNodos';
import Filtro from './Filtro';
import { MapaSinergias } from './MapaSinergias/MapaSinergias';
import analisisTerritorial from 'services/analisisTerritorial';
import { Paginacion } from 'views/Transaccion/hooks';
import { DICTONARY } from 'const/Dictonary';
import { TablaSinergias } from './Tabla/TablaSinergias';
import storeMessages from 'states/alertMessages/store';
import store from 'states/store';
import { OrdenarAscendente } from 'helpers/manejoArreglos';
import commons from 'services/commons';
import { Help } from '@mui/icons-material';
import { PopperComponent } from 'components/design-system/Popper/Popper';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import Text from 'components/design-system/Text/Text';
import { getCoordenadasMapa } from 'utils';

const PotencialSinergias = () => {
  const { isAnalistaTerritorial } = store.loguedUser.perfiles;
  const [filtro, setFiltros] = useState({
    codigoEmpresa: null,
    codigoEmpresaGraficoNodos: null,
    tipoPeticion: DICTONARY.LINEA_BASE_ESTADOS.DEFAULT,
    codigoEmpresaAT: null
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingNodos, setIsLoadingNodos] = useState(false);
  const [sucursales, setSucursales] = useState({
    sucursales: [],
    seleccionados: []
  });
  const [graphData, setGraphData] = useState();
  const [registroSeleccionado, setRegistroSeleccionado] = useState(null);
  const [filaTablaSeleccionada, setFilaTablaSeleccionada] = useState([]);
  const [dataPotencialSinergias, setDataPotencialSinergias] = useState([]);
  const { total, setTotal, pagination, setPagination } =
    Paginacion(setIsLoading);

  //Mapa Sinergias
  const [mapaPotencialSinergias, setMapaPotencialSinergias] = useState([]);
  const [mostrarMensajeSinDireccion, setMostrarMensajeSinDireccion] =
    useState(false);

  const [isLoadingMapaPotencialSinergias, setIsLoadingMapaPotencialSinergias] =
    useState(true);

  const [sectoresEconomicos, setSectoresEconomicos] = useState([]);
  const [coordenadasMapa, setCoordenadasMapa] = useState(
    getCoordenadasMapa(store?.loguedUser)
  );

  useEffect(() => {
    try {
      setIsLoading(true);
      let params = {
        numPagina: pagination.pageIndex,
        totalPagina: pagination.pageSize
      };
      if (
        filtro.tipoPeticion === DICTONARY.LINEA_BASE_ESTADOS.SELECCIONA_PUNTO ||
        filtro.tipoPeticion ===
          DICTONARY.LINEA_BASE_ESTADOS.SELECCIONA_PUNTO_TABLA
      ) {
        params.numPagina = DICTONARY.PAGINACION.DEFAULT.pageIndex;
        params.totalPagina = DICTONARY.PAGINACION.DEFAULT.pageSize;
      }

      if (filtro && Object.hasOwn(filtro, 'codigoEmpresa')) {
        params.codigosEmpresaAT = filtro.codigoEmpresa;
      }

      if (filtro && Object.hasOwn(filtro, 'codigoEmpresaAT')) {
        params.codigosEmpresaAT = [filtro.codigoEmpresaAT];
      }

      analisisTerritorial
        .potencialSinergias(params, DICTONARY.INTERCEPTOR.DESACTIVADO)
        .then(response => {
          if (response.data.error) throw new Error('SIN_REGISTROS');
          if (response.data.content) {
            if (response.empty) {
              setDataPotencialSinergias([]);
              setTotal(0);
              return;
            } else if (Array.isArray(response.data.content)) {
              setDataPotencialSinergias(response.data.content);
              setTotal(response.data.totalElements);
            }
          }
          setIsLoading(false);
        })
        .catch(error => {
          if (error.message === 'SIN_REGISTROS') {
            storeMessages.message.setMessage({
              mensaje: 'No existen registros para la solicitud realizada',
              tipo: 'info'
            });
          } else {
            storeMessages.message.setMessage({
              mensaje:
                'Por un error en el servicio no se ha obtenido la información para la tabla de potencial de sinergías',
              tipo: 'error'
            });
          }
          setDataPotencialSinergias([]);
          setTotal(0);
        })
        .finally(() => {
          setFiltros({
            ...filtro,
            tipoPeticion: DICTONARY.LINEA_BASE_ESTADOS.DEFAULT
          });
          setIsLoading(false);
        });

      let paramMapa = {
        potencialSinergia: true
      };

      if (filtro.codigoEmpresaAT === null) {
        setIsLoadingMapaPotencialSinergias(true);
        if (Object.hasOwn(filtro, 'codigoEmpresa') && filtro.codigoEmpresa) {
          paramMapa.codigosEmpresaAT = filtro.codigoEmpresa;
        }

        if (isAnalistaTerritorial) {
          paramMapa.codigoRegion =
            store?.loguedUser?.logeduser?.region?.codigoRegion ??
            DICTONARY.DEFAULT_REGION.REGION_METROPOLITANA;
        }

        analisisTerritorial
          .mapaAnalisisEmpresas(paramMapa, DICTONARY.INTERCEPTOR.DESACTIVADO)
          .then(response => {
            if (response.data.error) throw new Error('SIN_REGISTROS');
            if (response.data) {
              if (response.empty) {
                setMapaPotencialSinergias([]);
                return;
              } else if (Array.isArray(response.data)) {
                setMapaPotencialSinergias(response.data);
              }
            }
            setIsLoading(false);
            setIsLoadingMapaPotencialSinergias(false);
          })
          .catch(error => {
            if (error.message === 'SIN_REGISTROS') {
              storeMessages.message.setMessage({
                mensaje: 'No existen registros para la solicitud realizada',
                tipo: 'info'
              });
            } else {
              storeMessages.message.setMessage({
                mensaje:
                  'Por un error en el servicio no se ha obtenido la información el mapa de potencial de sinergías.',
                tipo: 'error'
              });
            }
            setDataPotencialSinergias([]);
            setMapaPotencialSinergias([]);
            setTotal(0);
          })
          .finally(() => {
            setIsLoading(false);
            setIsLoadingMapaPotencialSinergias(false);
          });
      }
    } catch (error) {
      setDataPotencialSinergias([]);
      setTotal(0);
      setIsLoading(false);
    }
  }, [
    filtro.codigoEmpresa,
    pagination.pageIndex,
    pagination.pageSize,
    filtro.codigoEmpresaAT
  ]);

  useEffect(() => {
    let params = {};
    let gData = {};
    if (
      (filtro && filtro.codigoEmpresaGraficoNodos) ||
      (filtro && filtro.codigoEmpresa)
    ) {
      if (
        Object.hasOwn(filtro, 'codigoEmpresaGraficoNodos') &&
        filtro.codigoEmpresaGraficoNodos
      ) {
        params.codigosEmpresaAT = filtro.codigoEmpresaGraficoNodos;
      }
      if (Object.hasOwn(filtro, 'codigoEmpresa') && filtro.codigoEmpresa) {
        params.codigosEmpresaAT = filtro.codigoEmpresa;
      }

      setIsLoadingNodos(true);
      analisisTerritorial
        .graficoRelaciones(params, DICTONARY.INTERCEPTOR.DESACTIVADO)
        .then(response => {
          if (response.data.error) throw new Error('SIN_REGISTROS');
          gData = {
            ...response.data,
            nodes: response.data.nodes.map(reg => ({
              ...reg,
              id: reg.codigoEmpresaAT
            }))
          };

          gData.links.forEach(link => {
            const a = gData.nodes.find(
              item => item.codigoEmpresaAT === link.source
            );
            const b = gData.nodes.find(
              item => item.codigoEmpresaAT === link.target
            );
            !a.neighbors && (a.neighbors = []);
            !b.neighbors && (b.neighbors = []);
            a.neighbors.push(b);
            b.neighbors.push(a);

            !a.links && (a.links = []);
            !b.links && (b.links = []);
            a.links.push(link);
            b.links.push(link);
          });
          setIsLoadingNodos(false);
          setGraphData(gData);
        })
        .catch(error => {
          if (error.message === 'SIN_REGISTROS') {
            storeMessages.message.setMessage({
              mensaje: 'No existen registros para la solicitud realizada',
              tipo: 'info'
            });
          } else {
            storeMessages.message.setMessage({
              mensaje:
                'Por un error en el servicio no se ha obtenido la información para el gráfico de nodos',
              tipo: 'error'
            });
          }
          setGraphData(null);
          setIsLoadingNodos(false);
        });
    }
  }, [filtro]);

  //Cargar sectores economicos
  useEffect(() => {
    try {
      commons
        .secoresEconomicos({
          totalPagina: DICTONARY.PAGINACION.ALL_DATA
        })
        .then(response => {
          if (response.status === 200) {
            setSectoresEconomicos(
              OrdenarAscendente(
                response.data.content,
                'nombreSectorEconomico',
                'string'
              )
            );
          }
        })
        .catch(() => {
          console.log('ERROR cargar sectoresEconomicos');
        });
    } catch (error) {
      console.log('Error en cargaInicial', error);
    }
  }, []);

  return (
    <div className="w-full">
      <Filtro isLoading={isLoading} setFiltros={setFiltros} />
      <div className="px-4 md:mx-auto py-6">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-6">
            <MapaSinergias
              sucursales={mapaPotencialSinergias}
              setRegistroSeleccionado={setRegistroSeleccionado}
              setFilaTablaSeleccionada={setFilaTablaSeleccionada}
              isLoading={isLoadingMapaPotencialSinergias}
              coordenadasMapa={coordenadasMapa}
              setFiltros={setFiltros}
              setCoordenadasMapa={setCoordenadasMapa}
              filtro={filtro}
              setMostrarMensajeSinDireccion={setMostrarMensajeSinDireccion}
              mostrarMensajeSinDireccion={mostrarMensajeSinDireccion}
            />
          </div>
          <div className="col-span-12 lg:col-span-6">
            <TablaSinergias
              dataPotencialSinergias={dataPotencialSinergias}
              total={total}
              pagination={pagination}
              isLoading={isLoading}
              setPagination={setPagination}
              sucursales={sucursales}
              setSucursales={setSucursales}
              registroSeleccionado={registroSeleccionado}
              setRegistroSeleccionado={setRegistroSeleccionado}
              setFiltros={setFiltros}
              setCoordenadasMapa={setCoordenadasMapa}
              setFilaTablaSeleccionada={setFilaTablaSeleccionada}
              filaTablaSeleccionada={filaTablaSeleccionada}
              setMostrarMensajeSinDireccion={setMostrarMensajeSinDireccion}
            />
          </div>
          <div className="col-span-12">
            <div className="bg-white shadow-md rounded h-full">
              <div className="py-4 px-5">
                <Heading
                  type="h4"
                  className="text-uv-primary-0 m-0 flex content-center items-center relative z-10"
                >
                  <span>Gráfico de relaciones y potencial de sinergías</span>
                  <PopperComponent
                    Button={
                      <IconButtonComponent size="small" type="neutral">
                        <Help className="text-uv-secondary-0" />
                      </IconButtonComponent>
                    }
                  >
                    <div className="max-w-md px-5 py-4">
                      <Text className="mb-2">
                        El algoritmo identifica el código de actividad económica
                        de las empresas según su registro en el SII, y determina
                        los potenciales residuos generados por una “empresa
                        origen” y los recursos requeridos por otras “empresas de
                        destino”, usando una base de datos desarrollada por la
                        Universidad de Valparaíso, y asumiendo que los residuos
                        pueden ser considerados como recursos bajo ciertas
                        condiciones (valorización, tratamiento, etc).
                      </Text>

                      <Text className="mb-2">
                        La relación{' '}
                        <em className="text-uv-primary-0">
                          “empresa-residuo-recurso-empresa”
                        </em>{' '}
                        es lo que denominamos una potencial sinergía. Una
                        empresa puede tener sinergías con más de una empresa, y
                        a su vez una sinergia entre dos empresas puede basarse
                        en más de un residuo o recurso debido a la gran cantidad
                        de potencial de sinergias.{' '}
                      </Text>

                      <Text>
                        En el caso de la Región Metropolitana, las potenciales
                        sinergias consideran la relación entre empresas de una
                        misma comuna, considerando la gran cantidad de empresas
                        en dicha región.{' '}
                      </Text>
                    </div>
                  </PopperComponent>
                </Heading>
              </div>
              <Divider />
              <div className="relative">
                <GraficoNodos
                  graphData={graphData}
                  isLoadingNodos={isLoadingNodos}
                  filtro={filtro}
                  sectoresEconomicos={sectoresEconomicos}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PotencialSinergias;
