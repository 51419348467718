import * as yup from 'yup';

const SchemaBusinessRole = yup.object().shape({
  codigoRolesEmpresa: yup
    .array()
    .of(yup.number())
    .min(1, 'Debe seleccionar al menos un tipo de usuario')
    .required('El campo es obligatorio.')
});

export { SchemaBusinessRole };
