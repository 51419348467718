
const codigosError = {
  UV404: {
    codigoError: 'UV404',
    codigoHttp: '404 - Not found',
    mensaje: 'El servicio solicitado no se encuentra disponible.'
  },
  UV403: {
    codigoError: 'UV403',
    codigoHttp: '403-Forbidden',
    mensaje:
      'El usuario no tiene los permisos suficientes para procesar la solicitud.'
  },
  'UV403-1': {
    codigoError: 'UV403-1',
    codigoHttp: '403-Forbidden',
    // mensaje: 'Sesion expirada o token invalido.'
    mensaje: 'Tu sesión ha expirado. Has sido redirigido automáticamente  a la página de inicio para que vuelvas a iniciar sesión.'
  },
  'UV403-2': {
    codigoError: 'UV403-2',
    codigoHttp: '403-Forbidden',
    mensaje: 'Las credenciales o el usuario es incorrecto'
  },
  'UV400-1': {
    codigoError: 'UV400-1',
    codigoHttp: '400-Bad Request',
    mensaje: 'La contraseña ingresada en el campo contraseña actual es incorrecta'
  },
  UV400: {
    codigoError: 'UV400',
    codigoHttp: '400-Bad Request',
    mensaje: 'Error en la solicitud del servicio, parámetros incorrectos.'
  },
  UV500: {
    codigoError: 'UV500',
    codigoHttp: '500-Internal Server Error',
    mensaje: 'Error interno en el servidor al procesar la solicitud.'
  },
  'UV500-1': {
    codigoError: 'UV500-1',
    codigoHttp: '500-Internal Server Error',
    mensaje:
      'Error interno en el servidor al procesar la solicitud en la base de datos.'
  },
  'UV500-2': {
    codigoError: 'UV500-2',
    codigoHttp: '500-Internal Server Error',
    mensaje:
      'Error interno en el servidor al procesar la solicitud en el repositorio de archivos.'
  },
  'UV500-3': {
    codigoError: 'UV500-3',
    codigoHttp: '500-Internal Server Error',
    mensaje: 'Error interno en el servidor al actualizar los datos.'
  },
  'UV500-4': {
    codigoError: 'UV500-4',
    codigoHttp: '500-Internal Server Error',
    mensaje:
      'Error interno en el servidor al generar el token de seguridad para el usuario.'
  },
  'UV500-5': {
    codigoError: 'UV500-5',
    codigoHttp: '500-Internal Server Error',
    mensaje: 'Error interno en el servidor, no se pudo obtener la contraseña.'
  },
  'UV500-6': {
    codigoError: 'UV500-6',
    codigoHttp: '500-Internal Server Error',
    mensaje: 'Error interno en el servidor al eliminar los datos.'
  },
  'UV500-7': {
    codigoError: 'UV500-7',
    codigoHttp: '500-Internal Server Error',
    mensaje:
      'Error interno en el servidor al subir los archivos al repositorio.'
  },
  'UV500-8': {
    codigoError: 'UV500-8',
    codigoHttp: '500-Internal Server Error',
    mensaje:
      'Error interno en el servidor al indexar el repositorio de archivos.'
  },
  'UV500-9': {
    codigoError: 'UV500-9',
    codigoHttp: '500-Internal Server Error',
    mensaje: 'Error interno en el servidor al guardar los datos.'
  },
  'UV500-10': {
    codigoError: 'UV500-10',
    codigoHttp: '500-Internal Server Error',
    mensaje:
      'Error interno en el servidor al comunicarse con el servidor de correos.'
  },
  'UV200-1': {
    codigoError: 'UV200-1',
    codigoHttp: '200-OK',
    mensaje: 'No existen registros para la solicitud realizada.'
  },
  'UV200-2': {
    codigoError: 'UV200-2',
    codigoHttp: '200-OK',
    mensaje:
      'No se puede iniciar sesión, la contraseña ingresada se encuentra expirada.'
  },
  'UV200-3': {
    codigoError: 'UV200-3',
    codigoHttp: '200-OK',
    mensaje:
      'No se puede iniciar sesión, el usuario ingresado no ha sido confirmado previamente.'
  },
  'UV200-4': {
    codigoError: 'UV200-4',
    codigoHttp: '200-OK',
    mensaje: 'No se puede iniciar sesión, el usuario ingresado no ha sido validado por el administrador de la plataforma.'
  },
  'UV200-5': {
    codigoError: 'UV200-5',
    codigoHttp: '200-OK',
    mensaje:
      'No se puede iniciar sesión, el usuario ingresado ha sido eliminado.'
  },
  'UV200-6': {
    codigoError: 'UV200-6',
    codigoHttp: '200-OK',
    mensaje: 'No se puede iniciar sesión, la contraseña es incorrecta.'
  },
  'UV200-7': {
    codigoError: 'UV200-7',
    codigoHttp: '200-OK',
    mensaje: 'No se puede procesar la solicitud, el usuario no existe.'
  },
  'UV200-8': {
    codigoError: 'UV200-8',
    codigoHttp: '200-OK',
    mensaje: 'Usuario duplicado en la base de datos.'
  },
  'UV200-9': {
    codigoError: 'UV200-9',
    codigoHttp: '200-OK',
    mensaje: 'Empresa duplicada en la base de datos.'
  },
  'UV200-9a': {
    codigoError: 'UV200-9a',
    codigoHttp: '200-OK',
    mensaje: 'Empresa duplicada en la base de datos.'
  },
  'UV200-10': {
    codigoError: 'UV200-10',
    codigoHttp: '200-OK',
    mensaje:
      'No se pudo almacenar el archivo, documento duplicado en el repositorio de datos.'
  },
  'UV200-11': {
    codigoError: 'UV200-10',
    codigoHttp: '200-OK',
    mensaje:
      'Los datos fueron procesados correctamente. Sin embargo, se produjo un error al comunicarse con el servidor de correos al enviar la notificación.'
  },
  'UV200-12': {
    codigoError: 'UV200-12',
    codigoHttp: '200-OK',
    mensaje:
      'No se puede asignar el perfil a usuario con negocio registrado.'
  },
  'undefined':{
    codigoError: 'undefined',
    codigoHttp: '',
    mensaje:
      'Error al comunicarse con el servidor, no se pudo establecer la conexión'
  },
  'CHAT500':{
    codigoError: 'CHAT500',
    codigoHttp: '',
    mensaje:
      'Error de conexion con el servidor de chat'
  }
};

export default codigosError;