import React, { useCallback, useEffect, useState } from 'react';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Divider } from '@mui/material';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import StepperComponent from 'components/design-system/Stepper/Stepper';
import { Formik, Form } from 'formik';
import {
  BusinessCertifications,
  BusinessInfo,
  BusinessSuccess
} from './steppers';
import { schema } from './schema/Schema';
import serviceEmpresas from 'services/empresas';
import { useHistory } from 'react-router-dom';
import { DICTONARY, RUTAS } from 'const/Dictonary';
import Loading from 'components/Loading/Loading';
import storeMessages from 'states/alertMessages/store';
import { refrescaUsuario } from '../../hooks';
import store from 'states/store';
import serviceDocumentos from 'services/documentos';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';

const EditBusiness = ({ match }) => {
  const { params } = match;
  const history = useHistory();
  const { isSuperAdmin } = store.loguedUser.perfiles;
  const { isAsesor, isGestor, isTransportista } = store.loguedUser.tipos;
  const [isElegibleAcreditaciones, setIsElegibleAcreditaciones] =
    useState(false);
  const isAsesorGestorTransportista = isAsesor || isGestor || isTransportista;

  if (!params) history.push('/inicio');
  // Logica de Stepper
  const { codigoEmpresa } = params;
  const [codEmpresa] = useState(codigoEmpresa);
  const [ListaEmpresa, setListaEmpresa] = useState([]);

  const [cargandoListaEmpresa, setCargandoListaEmpresa] = useState(true);
  const [guardando, setGuardando] = useState(true);

  const [estadoServicio, setEstadoServicio] = useState(true);
  const [tieneRutEmpresa, setTieneRutEmpresa] = useState(null);

  const cargaEmpresa = useCallback(async () => {
    try {
      setCargandoListaEmpresa(true);
      const response = await serviceEmpresas.obtenerEmpresa(codEmpresa);
      const errorSucursal = response.data.error;

      if (errorSucursal) throw new Error('Error al obtener empresa');
      if (response.status === 200) {
        const { data } = response;
        setListaEmpresa({
          ...data,
          rubro: data.actividad?.rubro,
          rolesFormat: data.roles.map(r => r.codigoRol.toString())
        });
        setTieneRutEmpresa(data.rutEmpresa !== null ? true : false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCargandoListaEmpresa(false);
    }
  }, [codEmpresa]);

  const [step, setStep] = useState(1);
  const [steps, setSteps] = useState([
    {
      label: 'Información del negocio',
      description: '',
      visible: true
    }
  ]);

  useEffect(() => {
    if (ListaEmpresa?.roles) {
      const rolesHabilitados = ListaEmpresa.roles.find(
        rol =>
          rol.nombreRol === DICTONARY.ROL.ASESOR.NOMBRE ||
          rol.nombreRol === DICTONARY.ROL.GESTOR.NOMBRE ||
          rol.nombreRol === DICTONARY.ROL.TRANSPORTISTA.NOMBRE
      );
      if (rolesHabilitados?.codigoRol || isAsesorGestorTransportista) {
        setSteps([
          {
            label: 'Información del negocio',
            description: '',
            visible: true
          },
          {
            label: 'Acreditaciones y certificaciones',
            description: '',
            visible: true
          }
        ]);
        setIsElegibleAcreditaciones(true);
      }
    }
  }, [ListaEmpresa]);

  useEffect(() => {
    cargaEmpresa();
    return () => {
      setListaEmpresa([]);
      setCargandoListaEmpresa(true);
    };
  }, [cargaEmpresa, tieneRutEmpresa]);

  useEffect(() => {
    return () => {
      setGuardando(false);
    };
  }, [ListaEmpresa]);

  useEffect(() => {
    // return () => {
    //   refrescaUsuario();
    // };
  }, [guardando]);

  const switchSteps = props => {
    // eslint-disable-next-line default-case
    switch (props.step) {
      case 1:
        return (
          <BusinessInfo
            values={props.values}
            errors={props.errors}
            touched={props.touched}
            onChange={props.handleChange}
            handleBlur={props.handleBlur}
            setFieldValue={props.setFieldValue}
            setFieldTouched={props.setFieldTouched}
            setFieldError={props.setFieldError}
          />
        );
      case isElegibleAcreditaciones ? 2 : 0:
        return (
          <BusinessCertifications
            setFieldValue={props.setFieldValue}
            values={props.values}
            errors={props.errors}
            touched={props.touched}
            onChange={props.handleChange}
            handleBlur={props.handleBlur}
            setFieldTouched={props.setFieldTouched}
            setFieldError={props.setFieldError}
            setStep={props.setStep}
            handleSubmit={props.handleSubmit}
            codEmpresa={codEmpresa}
          />
        );
      case isElegibleAcreditaciones ? 3 : 2:
        return (
          <div className="px-4 md:container md:mx-auto">
            <BusinessSuccess
              setFieldValue={props.setFieldValue}
              values={props.values}
              errors={props.errors}
              touched={props.touched}
              onChange={props.handleChange}
              handleBlur={props.handleBlur}
              setFieldTouched={props.setFieldTouched}
              setFieldError={props.setFieldError}
              codigoEmpresa={codEmpresa}
              estadoServicio={estadoServicio}
              guardando={guardando}
            />
          </div>
        );
    }
  };

  return (
    <SidebarLayout>
      {cargandoListaEmpresa ? (
        <Loading />
      ) : (
        <section className="py-10 px-4 md:container md:mx-auto w-full">
          <Formik
            initialValues={{
              tipoNegocio: tieneRutEmpresa ? 'empresa' : 'persona-natural',

              rutEmpresa: ListaEmpresa.rutEmpresa || '',
              nombreEmpresa: ListaEmpresa.nombreEmpresa || '',
              nombrePersona: ListaEmpresa.nombreEmpresa || '', // solo en caso de persona natural
              razonSocial: ListaEmpresa.razonSocial || '',

              rubro: ListaEmpresa.actividad?.rubro || {},
              actividad: ListaEmpresa.actividad || {},

              codigoRolesEmpresa: ListaEmpresa.rolesFormat,
              archivos: [],
              archivosGuardados: [],
              interactuandoArchivos: false,
              archivosEliminados: [],
              eliminandoArchivos: false
            }}
            validationSchema={schema[step]}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setGuardando(true);
              // console.log('submiting final..');
              //  console.log('values ::> ', values);

              const formatoArchivos = values.archivos.map(archivo => {
                return {
                  nombreArchivo: archivo.nombreArchivo,
                  base64: archivo.base64.split(';base64,').pop(),
                  tipoArchivo: archivo.tipoArchivo
                };
              });

              // Formato de request
              let respRquest = {};
              if (values.tipoNegocio === 'empresa') {
                respRquest = {
                  tipoNegocio: values.tipoNegocio,
                  rutEmpresa: values.rutEmpresa,
                  razonSocial: values.razonSocial,
                  nombreEmpresa: values.nombreEmpresa,
                  codigoActividad: values.actividad.codigoCiuActividad
                  // archivos: formatoArchivos ? formatoArchivos : []
                };
              } else if (values.tipoNegocio === 'persona-natural') {
                respRquest = {
                  tipoNegocio: values.tipoNegocio,
                  nombreEmpresa: values.nombrePersona
                  // archivos: formatoArchivos ? formatoArchivos : []
                };
              }

              // console.log('respRquest ::> ', respRquest);

              const codigoEmpresa = ListaEmpresa.codigoEmpresa;

              const dataArchivos = {
                codigoEmpresa: codigoEmpresa,
                archivos: formatoArchivos ? formatoArchivos : []
              };

              const archivosEliminados = values.archivosEliminados;
              const eliminandoArchivos = values.eliminandoArchivos;
              const interactuandoArchivos = values.interactuandoArchivos;

              // Servicio Eliminar Archivos
              if (archivosEliminados.length > 0 && eliminandoArchivos) {
                values.archivosEliminados.map(async id => {
                  try {
                    const response = await serviceDocumentos.eliminaArchivos(
                      id
                    );
                    if (response.status === 200) {
                      // const data = response.data;
                      storeMessages.message.setMessage({
                        mensaje: 'Se han eliminado los archivos correctamente',
                        tipo: 'exito'
                      });
                    }
                  } catch (error) {
                    console.log(error);
                    storeMessages.message.setMessage({
                      mensaje:
                        'Se ha producido un error al eliminar los archivos, Si el problema persiste comunícate con el administrador del sitio',
                      tipo: 'error'
                    });
                  } finally {
                    setGuardando(false);
                  }
                });
              }

              // Servicio Crear Archivos
              if (interactuandoArchivos) {
                try {
                  const response = await serviceDocumentos.guardar(
                    dataArchivos
                  );
                  if (response.status === 200) {
                    const data = response.data;
                    // console.log(data);
                    storeMessages.message.setMessage({
                      mensaje: 'Se han guardado los archivos correctamente',
                      tipo: 'exito'
                    });
                  }
                } catch (error) {
                  console.log(error);
                  storeMessages.message.setMessage({
                    mensaje:
                      'Se ha producido un error al guardar los archivos, Si el problema persiste comunícate con el administrador del sitio',
                    tipo: 'error'
                  });
                } finally {
                  setGuardando(false);
                }
              }

              // Servicio Editar negocio
              try {
                await serviceEmpresas
                  .editarEmpresa(codigoEmpresa, respRquest)
                  .then(response => {
                    refrescaUsuario();
                    console.log('response ::>', response);
                    storeMessages.message.setMessage({
                      mensaje: 'Se ha editado correctamente el negocio',
                      tipo: 'exito'
                    });

                    setEstadoServicio(true);
                    // editaDocumentos(codigoArchivo);
                    setGuardando(false);
                  });
              } catch (error) {
                setEstadoServicio(false);
                storeMessages.message.setMessage({
                  mensaje:
                    'Ha ocurrido un error al editar el negocio vuelve a intentarlo. Si el problema persiste comunícate con el administrador del sitio',
                  tipo: 'error'
                });
                console.log(error);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleReset,
              handleSubmit,
              setFieldValue,
              handleBlur,
              setFieldError,
              setFieldTouched
            }) => (
              <Form
                onChange={event => {
                  //  console.log(values);
                  // console.log(errors);
                }}
              >
                <Heading type="h2" className="mb-4">
                  Edición de negocio o actividad
                </Heading>
                <div className="bg-white py-5 rounded shadow-md">
                  <div className="grid grid-cols-12 md:gap-8 pt-2">
                    <div className="col-span-12 lg:col-span-3 pl-6 mb-8">
                      <StepperComponent activeStep={step} steps={steps} />
                    </div>

                    <div className="col-span-12 lg:col-span-9 lg:pl-4 lg:pr-5">
                      {switchSteps({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError,
                        handleReset,
                        handleSubmit,
                        setStep,
                        step,
                        guardando
                      })}
                    </div>

                    <div className="col-span-12 px-5 mb-4">
                      <Divider />
                    </div>

                    <div className="col-span-12 px-5 justify-between">
                      <div className="flex justify-between">
                        <ButtonComponent
                          type="secondary"
                          onClick={() => {
                            // Función Cancelar
                            if (step === 1) history.push(RUTAS.HOME_USER);

                            // Función volver
                            if (step > 0) {
                              window.scrollTo(0, 0);
                              setStep(step - 1);
                            }
                          }}
                          // disabled={step < 1}
                        >
                          {step === 1 ? (
                            'Cancelar'
                          ) : (
                            <>
                              <NavigateBefore /> Volver
                            </>
                          )}
                        </ButtonComponent>

                        <ButtonComponent
                          buttonType={
                            step === 3 && isElegibleAcreditaciones
                              ? 'submit'
                              : step === 2 && !isElegibleAcreditaciones
                              ? 'submit'
                              : 'button'
                          }
                          onClick={() => {
                            window.scrollTo(0, 0);
                            if (step === 3 && isElegibleAcreditaciones)
                              history.push(RUTAS.HOME_USER);
                            if (step === 2 && !isElegibleAcreditaciones)
                              history.push(RUTAS.HOME_USER);
                            if (
                              step >= 1 && step < isElegibleAcreditaciones
                                ? 3
                                : 2
                            ) {
                              setStep(step + 1);
                            } else {
                              console.log(
                                'El servicio no está funcionando correctamente'
                              );
                            }
                          }}
                          disabled={
                            Object.keys(errors).length === 0 ? false : true
                          }
                        >
                          <>
                            {isElegibleAcreditaciones &&
                              step === 3 &&
                              'Finalizar'}
                            {!isElegibleAcreditaciones &&
                              step === 2 &&
                              'Finalizar'}
                            {!isElegibleAcreditaciones &&
                              step === 1 &&
                              'Editar'}
                            {isElegibleAcreditaciones && step === 2 && 'Editar'}
                            {isElegibleAcreditaciones &&
                              step < 2 &&
                              'Siguiente'}
                            {isElegibleAcreditaciones && step < 2 && (
                              <NavigateNext />
                            )}
                          </>
                        </ButtonComponent>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </section>
      )}
    </SidebarLayout>
  );
};

export default EditBusiness;
