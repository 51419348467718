import { useEffect, useState } from 'react';
import AlertComponent from 'components/design-system/Alert/Alert';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import CheckboxComponent from 'components/design-system/Checkbox/Checkbox';
import Dropzone from 'components/design-system/Dropzone/Dropzone';
import Heading from 'components/design-system/Heading/Heading';
import IconComponent from 'components/design-system/icon/Icon';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import SwitchComponent from 'components/design-system/Switch/Switch';
import Text from 'components/design-system/Text/Text';
import { AgregarEnlaces } from './componentes/AgregarEnlaces/agregarEnlaces';
import { DICTONARY } from 'const/Dictonary';
import { Divider, FormControlLabel } from '@mui/material';
import { FilethumbComponent } from 'components/design-system/Filethumb/Filethumb';
import { TaskAlt } from '@mui/icons-material';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';

// import { listaMarcoLegal } from './utils/listaMarcoLegal';
// import { listaNormativaTecnica } from './utils/listaNormativaTecnica';
// import { listaPublicaciones } from './utils/listaPublicaciones';

export const CargarMaterialCampos = ({
  values,
  handleChange,
  setFieldTouched,
  errors,
  touched,
  setFieldValue,
  listaMarcoLegal,
  listaNormativaTecnica,
  listaPublicaciones,
  listaTipoResiduo,
  listaTipoTratamiento,
  listaRegiones,
  listaComunas,
  handleObtenerComunas,
  isSubmitting,
  isEdicion,
  isLoadingComunas
}) => {

  const [isAdjuntaArchivo, setIsAdjuntaArchivo] = useState(true);

  useEffect(() => {

    if (!values.region || values.region.length === 0) {
      return;
    }
    const listaCodigoRegiones = values.region.map(region => region.codigoRegion);

    if (listaCodigoRegiones.length > 0) {
      handleObtenerComunas(listaCodigoRegiones, setFieldValue);
    }
  }, [values.region, handleObtenerComunas, setFieldValue]);


  useEffect(() => {
    if (values.normativaTecnica && values.normativaTecnica.length === 0) {
      setFieldValue('obligatorio', false);
    }
  }, [setFieldValue, values.normativaTecnica]);

  const handleDownload = file => {
    const downloadLink = document.createElement('a');
    downloadLink.href = file.preview;
    downloadLink.download = file.name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };


  const base64ToBlob = (base64PDF) => {
    const base64Only = base64PDF.split(',')[1];
    const binaryPDF = atob(base64Only);
  

    // Crear un array buffer a partir del array de bytes
    const arrayBuffer = new ArrayBuffer(binaryPDF.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryPDF.length; i++) {
      uint8Array[i] = binaryPDF.charCodeAt(i);
    }

    // Crear un objeto Blob a partir del array buffer
    const pdfBlob = new Blob([uint8Array], { type: 'application/pdf' });

    // Crear una URL de datos para el Blob
    const dataUrl = URL.createObjectURL(pdfBlob);

    return dataUrl;
  } 

  return (
    <>
      <div className="col-span-12 md:col-span-3 lg:col-span-3">
        <Heading type="h4">Detalle del material de información</Heading>
        <Text>{`Puedes ${!isEdicion ? 'adjuntar un archivo o solo ' : ''}añadir enlaces externos asociados al material de información`}</Text>
      </div>
      <div className="col-span-12 md:col-span-9 lg:col-span-9">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12">
            { !isEdicion &&
            <>
              <FormControlLabel
                className="mb-4 block xs:flex xs:flex-col sm:flex-row xs:mx-0"
                name="adjuntaArchivo"
                control={
                  <SwitchComponent
                    disabled={isSubmitting}
                    checked={isAdjuntaArchivo}
                    onChange={(ev, val) => {
                      setIsAdjuntaArchivo(val);
                      setFieldValue('vieneArchivo', val);
                    }}
                    name="adjuntaArchivo"
                  />
                }
                label={
                  // por defecto es true
                  <Text className="font-semibold">
                    Adjuntar archivo
                  </Text>
                }
              />
              {isAdjuntaArchivo &&
                <div className="col-span-12 md:col-span-9 lg:col-span-9">
                  <div className="grid grid-cols-12">
                    <div
                      className={`
                              col-span-12
                              ${errors.ERROR_SIN_DOCUMENTOS && touched.archivos
                          ? 'border-error-dark border rounded'
                          : ''
                        }
                            `}
                    >
                      {/* suelta tu archivo seleccionar archivo */}
                      <Dropzone
                        setDataFiles={data => {
                          if (data.length > 0) {
                            setFieldValue('vieneArchivo', data.length > 0);
                            setFieldValue('archivos', data);
                          }
                          if (data.length > 0) {
                            // quitar extension 
                            const nombreSinExtension = data[0].nombreArchivo = data[0].nombreArchivo.split('.').slice(0, -1).join('.');
                            setFieldValue('nombreArchivo', nombreSinExtension);
                          }
                        }}
                        dataFiles={values.archivos}
                        enableEditingFile={false}
                        disabled={isSubmitting ? true : false}
                        {...DICTONARY.DROPZONE.CONFIG_UPLOAD_MATERIAL_INFORMACION}
                      >
                        <Text className="text-black font-medium">
                          Solo se aceptan los siguientes tipos de archivos
                        </Text>
                        <ul className="lg:flex mt-1">
                          <li className="d-flex items-center mb-1">
                            <IconComponent className="mr-2">
                              <TaskAlt className="text-base text-uv-primary-20" />
                            </IconComponent>
                            <Text className="text-neutral-40">
                              Formatos PDF, JPG, JPGE y PNG
                            </Text>
                          </li>
                          <li className="d-flex items-center mb-1 lg:ml-6">
                            <IconComponent className="mr-2">
                              <TaskAlt className="text-base text-uv-primary-20" />
                            </IconComponent>
                            <Text className="text-neutral-40">
                              Tamaño no superior a 25 MB
                            </Text>
                          </li>
                        </ul>
                      </Dropzone>

                   
                    </div>

                    <div className="col-span-12">
                      {errors.ERROR_SIN_DOCUMENTOS && touched.url && touched.archivos ? (
                        <AlertComponent className="mt-4" type="error">
                          Es obligatorio <b>cargar un documento</b> o{' '}
                          <b>al menos añadir un enlace externo</b>.<br />
                          (es posible cargar un material de información y añadir múltiple enlaces
                          externos)
                        </AlertComponent>
                      ) : null}
                    </div>
                  </div>
                </div>
              }

              <div className="col-span-12 my-4">
                {/* <Divider /> */}
              </div>

              <div className="col-span-12 md:col-span-9 lg:col-span-9">
                <InputComponent
                  fullWidth
                  name="nombreArchivo"
                  value={values.nombreArchivo}
                  disabled={isSubmitting || isEdicion ? true : false}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('nombreArchivo')}
                  touched={touched.nombreArchivo ? true : false}
                  label={<LabelComponent>Nombre de material de información</LabelComponent>}
                  estado={
                    errors.nombreArchivo
                      ? { tipo: 'error', mensaje: errors.nombreArchivo }
                      : null
                  }
                />
                </div>
              </>
              }

              {isEdicion && values.archivos.length > 0 && (
                  <div className=" col-span-4">
                        <div className="grid grid-cols-4">
                          {values.archivos.map((file, key) => (
                          
                          <FilethumbComponent
                            key={key}
                            enablePreviewFile={true}
                            file={{
                              preview: file.tipoArchivo === 'pdf' ? base64ToBlob(file.base64Archivo) : file.base64Archivo,
                              type: file.tipoArchivo,
                              name: file.nombreArchivo
                            }}
                            onDownload={file => handleDownload(file)}
                          />
                          ))}
                        </div>
                      </div>  
                      )}

            <div className="col-span-12 my-4">
              {/* <Divider /> */}
            </div>

            <div className="col-span-12 md:col-span-3 lg:col-span-3">
              <LabelComponent>Enlaces</LabelComponent>
            </div>

            <div className="col-span-12 md:col-span-9 lg:col-span-9">
              <AgregarEnlaces
                error={errors.ERROR_SIN_DOCUMENTOS && touched.urls ? true : false}
                enlaces={values.url}
                onChange={url => {
                  setFieldValue('url', url);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="col-span-12 my-4">
        <Divider />
      </div>

      <div className="col-span-12 md:col-span-3 lg:col-span-3">
        <Heading type="h4">Categorizar el material de información</Heading>
        <Text>
          Selecciona una o varias categorías para categorizar el material de información que
          vas a cargar
        </Text>
      </div>

      <div className="col-span-12 md:col-span-9 lg:col-span-9">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-10">
            <AlertComponent className="mb-4" type="info">
              Cuando se selecciona alguna categoría de{' '}
              <strong>Marco legal y tramitación</strong> se establece por
              defecto que el  material de información es de carácter{' '}
              <strong>obligatorio</strong>.
            </AlertComponent>

            <AlertComponent className="mb-4" type="info">
              Debes seleccionar al menos <strong>una categoría</strong> entre{' '}
              <strong>Normativa técnica, Marco legal y Publicaciones</strong>
            </AlertComponent>
          </div>

          <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
            <AutocompleteComponent
              id="normativaTecnica"
              disableCloseOnSelect
              limitTags={1}
              multiple
              name="normativaTecnica"
              options={listaNormativaTecnica}
              disabled={isSubmitting ? true : false}
              onChange={(ev, object) => {
                setFieldValue('normativaTecnica', object);
              }}
              accesor="nombreNormativaTecnica"
              value={values.normativaTecnica}
              getOptionLabel={option => option.nombreNormativaTecnica || ''}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <CheckboxComponent
                    size="small"
                    className="py-1"
                    checked={selected}
                  />
                  {option.nombreNormativaTecnica}
                </li>
              )}
            >
              <InputComponent
                fullWidth
                touched={touched.normativaTecnica ? true : false}
                label={<LabelComponent>Normativa técnica</LabelComponent>}
                estado={
                  errors.ERROR_SIN_CATEGORIAS
                    ? {
                      tipo: 'error',
                      mensaje: 'Debes completar al menos 1 campo'
                    }
                    : null
                }
              />
            </AutocompleteComponent>
          </div>

          <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
            <FormControlLabel
              className={`mb-1 mx-0
                ${values.normativaTecnica &&
                  values.normativaTecnica.length > 0 &&
                  !isSubmitting
                  ? 'opacity-100'
                  : 'opacity-40'
                }
              `}
              name="obligatorio"
              disabled={
                values.normativaTecnica &&
                  values.normativaTecnica.length > 0 &&
                  !isSubmitting
                  ? false
                  : true
              }
              control={
                <SwitchComponent
                  name="obligatorio"
                  checked={values.obligatorio}
                  onChange={(ev, val) => {
                    console.log(val);
                    handleChange({
                      target: { name: 'obligatorio', value: val }
                    });
                  }}
                />
              }
              label={
                <Heading
                  type="h5"
                  className={`
                ${values.normativaTecnica &&
                      values.normativaTecnica.length > 0 &&
                      !isSubmitting
                      ? 'opacity-100'
                      : 'opacity-40'
                    }
              `}
                >
                  Normativa técnica obligatoria
                </Heading>
              }
            />
            <Text
              className={`
              ${values.normativaTecnica &&
                  values.normativaTecnica.length > 0 &&
                  !isSubmitting
                  ? 'opacity-100'
                  : 'opacity-40'
                }
            `}
            >
              Marca el campo para establecer que el  material de información es obligatorio
              para alguna de las{' '}
              <strong>Normativas técnicas seleccionadas</strong>
            </Text>
          </div>

          <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
            <AutocompleteComponent
              disableCloseOnSelect
              limitTags={1}
              id="marcoLegal"
              multiple
              name="marcoLegal"
              accesor="nombreMarcoLegal"
              options={listaMarcoLegal}
              disabled={isSubmitting ? true : false}
              onChange={(ev, object) => {
                setFieldValue('marcoLegal', object);
              }}
              //onBlur={() => setFieldTouched('marcoLegal', true)}
              value={values.marcoLegal}
              getOptionLabel={option => option.nombreMarcoLegal || ''}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <CheckboxComponent
                    size="small"
                    className="py-1"
                    checked={selected}
                  />
                  {option.nombreMarcoLegal}
                </li>
              )}
            >
              <InputComponent
                fullWidth
                touched={touched.marcoLegal ? true : false}
                label={
                  <LabelComponent>Marco legal y tramitación</LabelComponent>
                }
                estado={
                  errors.ERROR_SIN_CATEGORIAS
                    ? {
                      tipo: 'error',
                      mensaje: 'Debes completar al menos 1 campo'
                    }
                    : null
                }
              />
            </AutocompleteComponent>
          </div>
          <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
            <AutocompleteComponent
              id="publicaciones"
              disableCloseOnSelect
              limitTags={1}
              multiple
              accesor="nombrePublicacion"
              name="publicaciones"
              options={listaPublicaciones}
              disabled={isSubmitting ? true : false}
              onChange={(ev, object) => {
                setFieldValue('publicaciones', object);
              }}
              //onBlur={() => setFieldTouched('publicaciones', true)}
              value={values.publicaciones}
              getOptionLabel={option => option.nombrePublicacion || ''}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <CheckboxComponent
                    size="small"
                    className="py-1"
                    checked={selected}
                  />
                  {option.nombrePublicacion}
                </li>
              )}
            >
              <InputComponent
                fullWidth
                className="h-9"
                touched={touched.publicaciones ? true : false}
                label={<LabelComponent>Publicaciones</LabelComponent>}
                estado={
                  errors.ERROR_SIN_CATEGORIAS
                    ? {
                      tipo: 'error',
                      mensaje: 'Debes completar al menos 1 campo'
                    }
                    : null
                }
              />
            </AutocompleteComponent>
          </div>

          <div className="col-span-12 my-4">
            <Divider />
          </div>
        </div>
      </div>

      <div className="col-span-12 md:col-span-3 lg:col-span-3">
        <Text>
          Categorías opcionales adicionales para catalogar el material de información  a cargar
        </Text>
      </div>
      <div className="col-span-12 md:col-span-9 lg:col-span-9">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
            <AutocompleteComponent
              id="tipoResiduo"
              name="tipoResiduo"
              accesor="nombreTipo"
              options={listaTipoResiduo}
              disabled={isSubmitting ? true : false}
              onChange={(ev, object) => {
                setFieldValue('tipoResiduo', object);
              }}
              onBlur={() => setFieldTouched('tipoResiduo', true)}
              value={values.tipoResiduo}
              getOptionLabel={option => option.nombreTipo || ''}
              isOptionEqualToValue={(option, value) =>
                option.codigoTipoResiduo === value.codigoTipoResiduo
              }
            >
              <InputComponent
                fullWidth
                className="h-9"
                touched={touched.tipoResiduo ? true : false}
                label={<LabelComponent esOpcional>Tipo residuo</LabelComponent>}
              />
            </AutocompleteComponent>
          </div>

          <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
            <AutocompleteComponent
              id="tipoTratamientoResiduo"
              name="tipoTratamientoResiduo"
              options={listaTipoTratamiento}
              accesor="nombreTipoTratamiento"
              disabled={isSubmitting ? true : false}
              onChange={(ev, object) => {
                setFieldValue('tipoTratamientoResiduo', object);
              }}
              onBlur={() => setFieldTouched('tipoTratamientoResiduo', true)}
              value={values.tipoTratamientoResiduo}
              getOptionLabel={option => option.nombreTipoTratamiento || ''}
              isOptionEqualToValue={(option, value) =>
                option.codigoTipoTratamiento === value.codigoTipoTratamiento
              }
            >
              <InputComponent
                fullWidth
                className="h-9"
                touched={touched.tipoTratamientoResiduo ? true : false}
                label={
                  <LabelComponent esOpcional>
                    Tipo de tratamiento de Residuo
                  </LabelComponent>
                }
              />
            </AutocompleteComponent>
          </div>

          <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
            <AutocompleteComponent
              id="region"
              name="region"
              accesor="nombreRegion"
              disableCloseOnSelect
              options={listaRegiones}
              // Multiple Opciones con select all
              disabled={isSubmitting ? true : false}
              multiple
              limitTags={1}
              freeSolo={false}
              isSelectAll
              labelSelectAll="Alcance nacional"

              onChange={(ev, region, reason) => {

                let data = { name: 'region', value: region };

                if (reason === 'clear') {
                  data.value = [];
                  setFieldValue('comuna', []);
                }
                // Multiple Opciones con select all
                if (region.find(option => option.all)) {

                  setFieldValue('region', values.region.length === listaRegiones.length
                    ? []
                    : listaRegiones)

                } else {
                  setFieldValue('region', data.value);
                }
              }}
              onBlur={() => setFieldTouched('region', true)}
              value={values.region}
              getOptionLabel={option => option.nombreRegion || ''}
              isOptionEqualToValue={(option, value) =>
                option.codigoRegion === value.codigoRegion
              }
              renderOption={(props, option, { selected }) => (
                <div className={(option.all ? 'border-b-2 border-primary-60 mt-0 pb-0.5 mb-0.5 bg-transparent  pt-0' : "")}>
                  <li {...props}>
                    <CheckboxComponent
                      size="small"
                      className={"py-1"}
                      // checked={selected}
                      checked={option.all ? !!(values.region.length === listaRegiones.length) : selected}
                    />
                    {option.nombreRegion}
                  </li>
                </div>
              )}
            >
              <InputComponent
                fullWidth
                className="h-9"
                touched={touched.region}
                placeholder={
                  values.region.length === listaRegiones.length
                    ? 'Todas las regiones'
                    : 'Selecciona una o varias regiones'
                }
                label={<LabelComponent esOpcional>Región</LabelComponent>}
              />
            </AutocompleteComponent>
          </div>

          <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
            <AutocompleteComponent
              id="comuna"
              name="comuna"
              options={listaComunas}
              accesor="nombreComuna"
              disabled={
                !values.region.some(obj => obj.hasOwnProperty('nombreRegion')) || isSubmitting ? true : false
              }
              loading={isLoadingComunas}
              // Multiple Opciones con select all
              disableCloseOnSelect
              multiple
              limitTags={1}
              freeSolo={false}
              isSelectAll
              labelSelectAll="Alcance regional"
              onChange={(ev, comuna, reason) => {

                let data = { name: 'comuna', value: comuna };

                if (reason === 'clear') {
                  data.value = [];
                }

                // Multiple Opciones con select all
                if (comuna.find(option => option.all)) {
                  setFieldValue('comuna', values.comuna.length === listaComunas.length
                    ? []
                    : listaComunas)
                } else {
                  setFieldValue('comuna', data.value);
                }
              }}
              onBlur={() => setFieldTouched('comuna', true)}
              value={values.comuna}
              getOptionLabel={option => option.nombreComuna || ''}
              isOptionEqualToValue={(option, value) =>
                option.codigoComuna === value.codigoComuna
              }
              renderOption={(props, option, { selected }) => (
                <div className={(option.all ? 'border-b-2 border-primary-60 mt-0 pb-0.5 mb-0.5 bg-transparent  pt-0' : "")}>
                  <li {...props} >
                    <CheckboxComponent
                      size="small"
                      className={"py-1"}
                      // checked={selected}
                      checked={option.all ? !!(values.comuna.length === listaComunas.length) : selected}
                    />
                    {option.nombreComuna}
                  </li>
                </div>
              )}
            >
              <InputComponent
                fullWidth
                className="h-9"
                touched={touched.comuna}
                placeholder={
                  !values.region.some(obj => obj.hasOwnProperty('nombreRegion'))
                    ? 'Debes seleccionar una región'
                    : values.comuna.length === listaComunas.length
                      ? 'Todas las comunas'
                      : 'Selecciona una o varias comunas'
                }
                label={<LabelComponent>Comuna</LabelComponent>}
                estado={
                  errors.ERROR_SIN_COMUNA
                    ? { tipo: 'error', mensaje: errors.ERROR_SIN_COMUNA }
                    : null
                }
              />
            </AutocompleteComponent>
          </div>
        </div>
      </div>



    </>
  );
};
