import { AnnouncementOutlined } from "@mui/icons-material";
import Heading from "components/design-system/Heading/Heading";
import { RoleComponent } from "components/design-system/Role/Role";
import Text from "components/design-system/Text/Text";

export const ListaSalas = ({
  roomList,
  codigoSalaChat,
  handleRoomClick
}) => {
    return(
      <div className="h-full border-r shadow-md bg-white min-h-[34vh] md:min-h-[80vh]">
        <div className="p-4 border-b">
          <Heading type="h4" className="my-0 py-0 text-uv-primary-0">Salas de Reunión</Heading>
        </div>

        {roomList.length === 0 ? (
          <div className="p-4 text-center">
            <AnnouncementOutlined className="w-14 h-14 text-uv-primary-40 my-4" />
            <Text size="M">No tienes <b className="text-black">salas<br/>de reunión activas</b></Text>
          </div>
        ) : null}

        <div className="max-h-[80vh] overflow-y-auto scrollbar-uv">
        {roomList.map(room => (
          <div className="chatItemDemanda" key={room.codigoSalaChat}>
            <div className="grid grid-cols-12 content-between items-center">
              <div className="col-span-12">
                <div 
                  className={'p-4 rounded-none block h-auto w-full border-b border-b-neutral-90 border-solid text-start hover:bg-uv-primary-90 cursor-pointer' +
                    (codigoSalaChat === room.codigoSalaChat
                      ? 'border-l-4 border-l-success-dark bg-uv-primary-90 shadow-sm'
                      : '')
                        }
                  onClick={handleRoomClick(room)}>
                  <div>
                    {(room => {
                      try {
                        return <RoleComponent rol={room.residuo.rol.nombreRol} tipo="etiqueta" verbo="accion" size="s" />
                      } catch (error) {}
                    })(room)}
                  </div>
                  <Heading type="h5" className="mb-0 text-sm font-medium">{room.nombreSalaChat}</Heading>
                </div>
              </div>
            </div>
          </div>
        ))}
        </div>
      </div>
    )
};