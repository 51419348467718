import React from 'react';
import { List } from '@mui/material';
import ListItemButtonComponent from 'components/design-system/ListItemButton/ListItemButton';
import { RUTAS } from 'const/Dictonary';
import ArrowOutward from '@mui/icons-material/ArrowOutward';
import CallReceived from '@mui/icons-material/CallReceived';
import CalculateIcon from '@mui/icons-material/Calculate';
import { ContenidoRestringido } from 'components/design-system/ContenidoRestringido/ContenidoRestringido';
import Text from 'components/design-system/Text/Text';
import LinkComponent from 'components/design-system/Link/Link';

const SubMenuGestiona = React.forwardRef(
  ({ forceRefresh, isSuperAdmin, tieneNegocio }, ref) => {
    return (
      <List ref={ref}>

        {!tieneNegocio && !isSuperAdmin ?
          <div className="col-span-12">
            <ContenidoRestringido>
              <Text size="S">
                Solo{' '}
                <b className="text-black">
                  usuarios con negocios registrados
                </b>{' '}
                pueden acceder a las opciones de reportes.
                <LinkComponent
                  href={RUTAS.REGISTRAR_NEGOCIO}
                  className="ml-2"
                >
                  Registrar un negocio
                </LinkComponent>
              </Text>
            </ContenidoRestringido>
          </div>
        : null }


        {tieneNegocio || isSuperAdmin ? (
          <ListItemButtonComponent
            onClick={forceRefresh(RUTAS.GESTIONA_RECURSO_ADQUIRIDO)}
            icon={<CallReceived />}
            label={'Recurso Adquirido'}
          />
        ) : null}

        {tieneNegocio || isSuperAdmin ? (
          <ListItemButtonComponent
            onClick={forceRefresh(RUTAS.GESTIONA_RESIDUO_DECLARADO)}
            icon={<ArrowOutward />}
            label={'Residuo Declarado'}
          />
        ) : null}

        {tieneNegocio || isSuperAdmin ? (
          <ListItemButtonComponent
            onClick={forceRefresh(RUTAS.FORMULARIO_CALCULO_INDICADORES)}
            icon={<CalculateIcon />}
            label={'Calculadora Indicadores'}
          />
        ) : null}
      </List>
    );
  }
);

export default SubMenuGestiona;
