import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import storeMessages from '../../states/alertMessages/store';

const AvisoAUsuario = () => {
  const [tipoClass, setTipoClass] = useState();
  const [open, setOpen] = React.useState(false);
  const {
    mensaje,
    tipo,
    elevation = 6,
    ...snackBarProps
  } = storeMessages.message.message;

  useEffect(() => {
    if (tipo === 'exito' || tipo === 'success') {
      setTipoClass('success');
    } else if (tipo === 'error') {
      setTipoClass('error');
    } else if (
      tipo === 'advertencia' ||
      tipo === 'danger' ||
      tipo === 'warning'
    ) {
      setTipoClass('warning');
    } else {
      setTipoClass('info');
    }

    if (mensaje) {
      setOpen(true);
    }
    //}, [mensaje, tipo]);
  }, [storeMessages.message.message]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top',  horizontal: 'center' }}

      {...snackBarProps}
    >
      <Alert
        onClose={handleClose}
        severity={tipoClass}
        className="avisoAUsuario"
        elevation={elevation}
        variant="filled"
      >
        {mensaje}
      </Alert>
    </Snackbar>
  );
};

export default AvisoAUsuario;