import React, { useEffect, useState } from 'react';
import store from 'states/store';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import { HomeSuperAdmin } from './HomeSuperAdmin';
import { HomeUsuario } from './HomeUsuario';
import usuariosService from 'services/usuarios';
import { DICTONARY } from 'const/Dictonary';
import { HomeAnalistaTerritorial } from './HomeAnalistaTerritorial';
import { useCallback } from 'react';
import { formateoDataSeparadorMiles } from 'helpers/formateoNumeros';
import { refrescaUsuario } from 'views/Business/hooks';

const HomeUsuarioLogeado = () => {
  const { perfiles, logeduser } = store.loguedUser;
  const isSuperAdmin = perfiles.isSuperAdmin;
  const isAnalistaTerritorial = perfiles.isAnalistaTerritorial;

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const tieneNegocioRegistrado = logeduser && logeduser.sucursal ? true : false;

  const [usuarioInvitado, setUsuarioInvitado] = useState(localStorage.getItem('verificaUsuarioSucursal') || null);

  useEffect(() => {
  // Refrescar usuario si el usuario es un usuario invitado al cual se le agrego una sucursal
  usuarioInvitado && refrescaUsuario();
  }, [usuarioInvitado])
  

  const cargarDatos = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await usuariosService.getResumenUsuario(
        logeduser.codigoUsuario,
        DICTONARY.INTERCEPTOR.DESACTIVADO
      );
      const error = response.data.error;
      if (error) throw new Error('ERROR_CARGAR_RESUMEN');

      const dataResumenUsuarios = response.data;

      const datosFormateados = formateoDataSeparadorMiles(dataResumenUsuarios);

      setData(datosFormateados);
    } catch (error) {
      setData({});
      console.log('Error al cargar resumen ', error);
    }
    setIsLoading(false);
  }, [logeduser?.codigoUsuario]);

  useEffect(() => {
    cargarDatos();
  }, [cargarDatos, logeduser?.codigoUsuario]);

  return (
    <SidebarLayout>
      {isSuperAdmin ? (
        <HomeSuperAdmin
          logeduser={logeduser}
          isLoading={isLoading}
          data={data}
        />
      ) : null}

      {!isSuperAdmin && !isAnalistaTerritorial ? (
        <HomeUsuario
          logeduser={logeduser}
          data={data}
          tieneNegocioRegistrado={tieneNegocioRegistrado}
          isLoading={isLoading}
        />
      ) : null}

      {isAnalistaTerritorial ? (
        <HomeAnalistaTerritorial
          logeduser={logeduser}
          isLoading={isLoading}
          data={data}
        />
      ) : null}
    </SidebarLayout>
  );
};

export default HomeUsuarioLogeado;
