import API from './api';
import store from '../states/store.js';

const archivos = {
  
  getArchivos: async function (params, conInterceptor = true) {
    return await API.axios({
      method: 'get',
      url: '/archivos',
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: params,
      interceptor : conInterceptor
    })
  },

  getArchivoUnico: async function (codigoArchivo, conInterceptor = true){
    return API.axios({
      method: 'get',
      url: `/archivos/${codigoArchivo}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor : conInterceptor
    })
  },

  getReporteSinader : async function (params, conInterceptor = true){
    return API.axios({
      method: 'get',
      url: `/empresas/exportar-archivo/sinader?${params}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      responseType: 'blob',
      interceptor : conInterceptor
    })
  },

  getReporteResiduos : async function (params, conInterceptor = true){
    return API.axios({
      method: 'get',
      url: `/residuos/exportar-archivo?${params}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      responseType: 'blob',
      interceptor : conInterceptor
    })
  },

  getReporteUsuarios : async function (params, conInterceptor = true){
    return API.axios({
      method: 'get',
      url: `/usuarios/exportar-archivo?${params}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      responseType: 'blob',
      interceptor : conInterceptor
    })
  },

  getReporteIndicadores : async function (params, conInterceptor = true){  
    return API.axios({
      method: 'get',
      url: `/indicadores/exportar-archivo?${params}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      responseType: 'blob',
      interceptor : conInterceptor
    })
  },

  getExportarCargaMasivaSii : async function (conInterceptor = true){
    return API.axios({
      method: 'get',
      url: `/empresas/exportar-formato-sii`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor : conInterceptor
    })
  },

  getExportarCargaMasivaRect : async function (conInterceptor = true){
    return API.axios({
      method: 'get',
      url: `/residuos/exportar-formato-retc`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor : conInterceptor
    })
  },

  getExportarCargaMasivaPs : async function (conInterceptor = true){
    return API.axios({
      method: 'get',
      url: `/empresas/exportar-formato-ps`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor : conInterceptor
    })
  },

  postCargaMasivaSii : async function (formData, conInterceptor = true){
    return API.axios({
      method: 'post',
      url: `/empresas/carga-masiva-sii`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: 'Bearer ' + store.loguedUser.token,
      },
      data: formData,
      responseType: 'blob',
      interceptor : conInterceptor
    })
  },

  postCargaMasivaRect : async function (formData, conInterceptor = true){
    return API.axios({
      method: 'post',
      url: `/residuos/carga-masiva`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: 'Bearer ' + store.loguedUser.token,
      },
      data: formData,
      responseType: 'blob',
      interceptor : conInterceptor
    })
  },

  postCargaMasivaPs : async function (formData, conInterceptor = true){
      return API.axios({
        method: 'post',
        url: `/empresas/carga-masiva-ps`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: 'Bearer ' + store.loguedUser.token,
        },
        data: formData,
        responseType: 'blob',
        interceptor : conInterceptor
      })
  },


};

export default archivos;
