import React from 'react';
import styles from './label.module.css';

/**
 * 
 * @param esOpcional 
 * @returns 
 */
const LabelComponent = (props) => {
  const { className, esOpcional, children, ...rest } = props;
  const ES_OPCIONAL = esOpcional ? true : false;


  return(
    <label
      className={`
        ${className ? className : ''}
        ${styles.label}
      `}
    
      {...rest}
    >
      {children} {ES_OPCIONAL && <span className="text-neutral-50">(opcional)</span>}
    </label>  
  )
}

export default LabelComponent;