import { useEffect, useState } from 'react';
import ButtonComponent from 'components/design-system/Button/Button';
import { Dialog, DialogActions, DialogContent, Hidden } from '@mui/material';
import { Close } from '@mui/icons-material';
import AlertComponent from 'components/design-system/Alert/Alert';
import LinkComponent from 'components/design-system/Link/Link';
import { DICTONARY } from 'const/Dictonary';
import ChevronRight from '@mui/icons-material/ChevronRight';
import store from 'states/store';

export const MensajeCuota = ({ disponibilidad, disableButton, children }) => {
  const { cuotaActual, cuotaMaxima, habilitado } = disponibilidad;
  const { isSuperAdmin } = store.loguedUser.perfiles;
  const [openModal, setOpenModal] = useState(false);
  const [esUltimoIntento, setUltimoIntento] = useState(
    cuotaActual >= cuotaMaxima || false
  );

  useEffect(() => {
    setUltimoIntento(cuotaActual >= cuotaMaxima);
  }, [disponibilidad.cuotaActual]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      {isSuperAdmin ? (
        children
      ) : (
        <ButtonComponent
          disabled={disableButton}
          buttonType="button"
          onClick={handleOpenModal}
        >
          Ingresar Calculo
        </ButtonComponent>
      )}

      <Dialog
        onClose={handleCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
      >
        <DialogContent>
          {esUltimoIntento && (
            <>
              <AlertComponent type="warning">
                Este es tu <b>último intento disponible</b> para generar los
                indicadores (tienes un límite máximo de {cuotaMaxima} intentos
                al mes). Si necesitas habilitar más intentos, por favor, ponte
                en contacto con nosotros.
                <div className="mt-2">
                  <LinkComponent
                    href={{ pathname: DICTONARY.JIRA_CONTACTO_URL }}
                    type="button"
                    target="_blank"
                  >
                    Contactar <ChevronRight />
                  </LinkComponent>
                </div>
              </AlertComponent>
            </>
          )}

          {!esUltimoIntento && (
            <>
              <AlertComponent type="info">
                Has utilizado <b>{cuotaActual ? cuotaActual : '0'}</b>{' '}
                {cuotaActual === 1 ? 'intento' : 'intentos'} de un{' '}
                <b>límite máximo de {cuotaMaxima}</b> (tienes un total de{' '}
                {cuotaMaxima} intentos disponibles al mes). Si necesitas
                habilitar más intentos, por favor, ponte en contacto con
                nosotros.
                <div className="mt-2">
                  <LinkComponent
                    href={{ pathname: DICTONARY.JIRA_CONTACTO_URL }}
                    type="button"
                    target="_blank"
                  >
                    Contactar <ChevronRight />
                  </LinkComponent>
                </div>
              </AlertComponent>
            </>
          )}
        </DialogContent>
        <DialogActions className="border-t border-neutral-90 p-4 justify-between">
          <ButtonComponent
            type="secondary"
            onClick={() => {
              handleCloseModal();
            }}
            className="justify-center"
          >
            <Hidden smDown>Cancelar</Hidden>
            <Hidden smUp>
              <Close />
            </Hidden>
          </ButtonComponent>

          {habilitado === true && children}
        </DialogActions>
      </Dialog>
    </>
  );
};
