import { DICTONARY } from 'const/Dictonary';
import { OrdenarAscendente } from 'helpers/manejoArreglos';
import adminService from 'services/admin';
import storeMessages from 'states/alertMessages/store';
import store from 'states/store';

export const useServices = () => {
  const cargarPerfiles = async (setPerfiles, setIsLoadingPerfiles, params) => {
    try {
      setIsLoadingPerfiles(true);
      const { data } = await adminService.getPerfiles(params);
      const { content } = data;
      let isSuperAdmin = store.loguedUser.perfiles.isSuperAdmin;

      if (isSuperAdmin) {
        setPerfiles(OrdenarAscendente(content, 'nombrePerfil'));
      } else {
        setPerfiles(
          OrdenarAscendente(
            content.filter(
              perfil =>
                perfil.codigoPerfil !==
                  DICTONARY.CODIGOS_PERFILES.SUPER_ADMINISTRADOR &&
                perfil.codigoPerfil !==
                  DICTONARY.CODIGOS_PERFILES.ANALISTA_TERRITORIAL &&
                perfil.codigoPerfil !== DICTONARY.CODIGOS_PERFILES.USUARIO
            ),
            'nombrePerfil'
          )
        );
      }
    } catch (error) {
      console.log(error);
      storeMessages.message.setMessage({
        mensaje: 'Error al cargar los perfiles',
        tipo: 'error'
      });
    } finally {
      setIsLoadingPerfiles(false);
    }
  };

  return { cargarPerfiles };
};
