import { SchemaBusinessType } from './SchemaBusinessType';
import { SchemaBusinessRSD } from './SchemaBusinessRSD';
import { SchemaBusinessRole } from './SchemaBusinessRole';
import { SchemaBusinessInfo } from './SchemaBusinessInfo';
import { SchemaBusinessCertifications } from './SchemaBusinessCertifications';

const schema = [];
schema[0] = SchemaBusinessType;
schema[1] = SchemaBusinessInfo;
schema[2] = SchemaBusinessRole;
schema[3] = SchemaBusinessRSD;
schema[4] = SchemaBusinessCertifications;

export { schema };
