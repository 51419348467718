import store from 'states/store';
import API from './api';

const roles = {
  roles: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/roles',
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: params,
      interceptor: conInterceptor
    });
  }
};

export default roles;
