import { DICTONARY } from 'const/Dictonary';
import { useEffect, useState } from 'react';
import documentos from 'services/documentos';
import storeMessages from 'states/alertMessages/store';

export const useOpcionesTabla = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [descargando, setDescargando] = useState(false);


  

  const isNotEmptyOrNull = categoria => {
    if (categoria) {
      try {
        return categoria[0].length > 0;
      } catch (err) {
        return false;
      }
    } else {
      return false;
    }
  };

  const isUrlNotEmptyOrNull = urls => {
    if (urls) {
      try {
        if (urls.length == 0) {
          return false;
        }

        if (urls[0].url.length == 0) {
          return false;
        }

        return true;
      } catch (err) {
        return false;
      }
    } else {
      return false;
    }
  };

  const downloadFile = (file) => {
    const downloadLink = document.createElement('a');
    const fileURL = getFileURL(file);
    if (!file.base64Archivo) {
      return;
    }
    if (!fileURL) {
      console.error('URL invalida');
      return;
    }
    downloadLink.href = fileURL;
    downloadLink.download = file.nombreArchivo;
    document.body.appendChild(downloadLink);

    try {
      downloadLink.click();
    } catch (error) {
      console.error('Error en activar la descarga:', error);
    } finally {
      document.body.removeChild(downloadLink);
    }
  };

  const getFileURL = file => {

    const tipoArchivo = file.nombreArchivo.split('.').pop();

    if (tipoArchivo === 'pdf') {
      return `data:application/pdf;base64,${file.base64Archivo}`;
    }
    if (tipoArchivo === 'jpg' || tipoArchivo === 'jpeg' || tipoArchivo === 'png') {
      return `data:image/${tipoArchivo};base64,${file.base64Archivo}`;
    }
    else {
      return `data:application/${tipoArchivo};base64,${file.base64Archivo}`;
    }

  };

  const handleClickDescargar = async (event, documento) => {
    // Evitar que se descargue el archivo múltiples veces
    if (descargando) {
      return;
    }

    // Activar el estado de descarga
    setDescargando(true);

      documentos
        .obtenerMaterialInformacionBase64(documento.codigoMaterialInformacion, DICTONARY.INTERCEPTOR.DESACTIVADO)
        .then(res => {
          downloadFile(res.data);
        })
      .catch(
        (err => {
          if (
            !err.response ||
            !err.response.data ||
            !err.response.data.codigoError
          ) {
            storeMessages.message.setMessage({
              mensaje:
                'No se ha logrado descargar el documento, puede que el documento ya no exista o no este temporalmente disponible.',
              tipo: 'warning'
            });
          }
        })
      )
      .finally(() => {
        setDescargando(false);
      });
    }


    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    return {
      isNotEmptyOrNull,
      isUrlNotEmptyOrNull,
      handleClickDescargar,
      handleChangePage,
      handleChangeRowsPerPage,
      descargando,
      page,
      rowsPerPage
    };
  };
