const InitialFormValues = {
  tipoNegocio: '',

  rutEmpresa: '',
  nombre: '',
  razonSocial: '',

  rubro: '',
  actividad: '',
  region: null,
  comuna: null,
  nombreVia: '',

  longitudDireccion: 0,
  latitudDireccion: 0,

  codigoRolesEmpresa: [],
  codigosTipoResiduo: [],
  residuos: [],

  archivos: [],
  // TODO:

  codigoActividad: '',
  codigoComuna: 0,

  pisoBloque: '',
  nombreResiduosSeleccionados: []
};
export default InitialFormValues;
