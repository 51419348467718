import { useState,useEffect, useCallback } from 'react';

import Loading from 'components/Loading/Loading';
import LabelComponent from 'components/design-system/Label/Label';
import IconComponent from 'components/design-system/icon/Icon';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import Dropzone from 'components/design-system/Dropzone/Dropzone';
import Text from 'components/design-system/Text/Text';

import { DICTONARY } from 'const/Dictonary';
import Heading from 'components/design-system/Heading/Heading';
import servicesDocumentos from 'services/documentos';
import storeMessages from 'states/alertMessages/store';
import { FilethumbComponent } from 'components/design-system/Filethumb/Filethumb';

export const MaterialAdjunto = ({
  codigoResiduo,
  errors,
  handleBlur,
  handleChange,
  handleReset,
  handleSubmit,
  isSubmitting,
  setFieldError,
  setFieldTouched,
  setFieldValue,
  touched,
  validateForm,
  values,
  // Props adicionales
  dataNueva,
  tipoFormularioSeleccionado,
}) => {
  const [imagenes, setImagenes] = useState([]);
  const [archivos, setArchivos] = useState([]);
  const [imagenesLocal, setImagenesLocal] = useState([]);
  const [documentosLocal, setDocumentosLocal] = useState([]);

  const [cargandoArchivos, setCargandoArchivos] = useState(true);
  const [guardando, setGuardando] = useState(false);


  const cargaArchivos = useCallback(async () => {
    setCargandoArchivos(true);
    try {
      const response = await servicesDocumentos.obtenerArchivos({
        codigoResiduo: codigoResiduo
      });
      if (response.data) {
        const resultTemp = response.data;
        resultTemp.length > 0 &&
          resultTemp.map(item => {
            const extension =
              item.tipoArchivo === '.png' || item.tipoArchivo === 'png'
                ? 'data:image/png;base64,'
                : item.tipoArchivo === '.jpg' || item.tipoArchivo === 'jpg'
                ? 'data:image/jpeg;base64,'
                : item.tipoArchivo === '.pdf' || item.tipoArchivo === 'pdf'
                ? 'data:application/pdf;base64,'
                : item.tipoArchivo === '.doc' || item.tipoArchivo === 'doc'
                ? 'data:application/msword;base64,'
                : item.tipoArchivo === '.docx' || item.tipoArchivo === 'docx'
                ? 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,'
                : item.tipoArchivo === '.xls' || item.tipoArchivo === 'xls'
                ? 'data:application/vnd.ms-excel;base64,'
                : item.tipoArchivo === '.xlsx' || item.tipoArchivo === 'xlsx'
                ? 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
                : item.tipoArchivo === '.ppt' || item.tipoArchivo === 'ppt'
                ? 'data:application/vnd.ms-powerpoint;base64,'
                : item.tipoArchivo === '.pptx' || item.tipoArchivo === 'pptx'
                ? 'data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,'
                : item.tipoArchivo === '.txt' || item.tipoArchivo === 'txt'
                ? 'data:text/plain;base64,'
                : '';
            item.base64 = extension + item.base64Archivo;

            // string sin puntos al comienzo
            item.extension = item.tipoArchivo.replace(/^\.+/, '');
            return item;
          });
        if (resultTemp.length > 0) {
          // filtrar si el objeto tienen el atributo extension y es pdf
          const resultTempPDFFiltrado = resultTemp.filter(
            item => item.extension === 'pdf'
          );
          setArchivos(resultTempPDFFiltrado);
          setDocumentosLocal(resultTempPDFFiltrado);

          const resultTempFiltrado = resultTemp.filter(
            item => item.extension !== 'pdf'
          );
          setImagenes(resultTempFiltrado);
          setImagenesLocal(resultTempFiltrado);
        } else {
          setImagenes([]);
          setImagenesLocal([]);
          setArchivos([]);
          setDocumentosLocal([]);
          setFieldValue('imagenes', []);
          setFieldValue('archivos', []);
        }
      } else {
        setImagenes([]);
        setImagenesLocal([]);
      }
    } catch (error) {
      console.log('Error al cargar documentos:', error);
      setImagenes([]);
      setImagenesLocal([]);
      setArchivos([]);
      setDocumentosLocal([]);
    } finally {
      setCargandoArchivos(false);
    }
  }, [codigoResiduo, setFieldValue]);

  useEffect(() => {
    if(tipoFormularioSeleccionado.editar || tipoFormularioSeleccionado.duplicar) {
    cargaArchivos();
    }

    return () => {
      setImagenes([]);
      setImagenesLocal([]);
      setArchivos([]);
      setDocumentosLocal([]);
    };
  }, [cargaArchivos, tipoFormularioSeleccionado.duplicar, tipoFormularioSeleccionado.editar]);

  useEffect(() => {
    //entrego solo el ultimo valor del array
    let nuevasImagenes = [];

    if (imagenes !== imagenesLocal) {
      setFieldTouched('imagenes', true);
      setFieldValue('interactuandoImagenes', true);

      nuevasImagenes = imagenes.filter(
        ({ codigoArchivo: id1 }) =>
          !imagenesLocal.some(({ codigoArchivo: id2 }) => id2 === id1)
      );

      if (
        imagenesLocal.length <= 4 &&
        imagenesLocal.length + imagenes.length <= 4
      ) {
        setFieldValue('imagenes', [...nuevasImagenes]);
      } else {
        console.log('maximo de archivos alcanzado');

        setFieldTouched('imagenes', true);
        setFieldValue('interactuandoImagenes', true);

        storeMessages.message.setMessage({
          mensaje:
            'Haz alcanzado el máximo de imagenes permitidos. Si deseas agregar más archivos, elimina alguno de los que ya has cargado.',
          tipo: 'warning'
        });
      }
    } else {
      setFieldValue('interactuandoImagenes', false);
      nuevasImagenes = imagenesLocal;
    }

    return () => {
      // setFieldValue('interactuandoImagenes', false);
      // setFieldTouched('imagenes', false);
    };
  }, [imagenes, imagenesLocal, setFieldTouched, setFieldValue]);

  useEffect(() => {
    let nuevosDocumentos = [];
    // let antiguosDocumentos = [];

    if (archivos !== documentosLocal) {
      setFieldTouched('archivos', true);
      setFieldValue('interactuandoDocumentos', true);

      nuevosDocumentos = archivos.filter(
        ({ codigoArchivo: id1 }) =>
          !documentosLocal.some(({ codigoArchivo: id2 }) => id2 === id1)
      );

      if (
        documentosLocal.length <= 4 &&
        documentosLocal.length + archivos.length <= 4
      ) {
        setFieldValue('archivos', [...nuevosDocumentos]);
      } else {
        console.log('maximo de archivos alcanzado');

        setFieldTouched('archivos', true);
        setFieldValue('interactuandoDocumentos', true);

        storeMessages.message.setMessage({
          mensaje:
            'Haz alcanzado el máximo de documentos permitidos. Si deseas agregar más archivos, elimina alguno de los que ya has cargado.',
          tipo: 'warning'
        });
      }
    } else {
      setFieldValue('interactuandoDocumentos', false);
      nuevosDocumentos = documentosLocal;
    }

    return () => {
      // setFieldValue('interactuandoDocumentos', false);
      // setFieldTouched('documentos', false);
    };
  }, [archivos, documentosLocal, setFieldTouched, setFieldValue]);

  const eliminarImagen = useCallback(
    file => {
      const imagenesEliminadas = file.codigoArchivo
        ? [...values.imagenesEliminadas, parseInt(file.codigoArchivo)]
        : values.imagenesEliminadas;
      setFieldValue('imagenesEliminadas', imagenesEliminadas);
      setFieldValue('eliminandoImagenes', true);

      const imagenesFiltradas = imagenesLocal.filter(
        archivo => archivo.codigoArchivo !== file.codigoArchivo
      );

      //sin que se repita el mismo valor
      setImagenesLocal(imagenesFiltradas);
      setImagenes([]);
      setFieldValue('imagenes', imagenesFiltradas);

      setFieldValue('interactuandoImagenes', true);
      setFieldTouched('imagenes', true);
      return;
    },
    [imagenesLocal, setFieldTouched, setFieldValue, values.imagenesEliminadas]
  );

  const eliminarDocumento = useCallback(
    file => {
      const documentosEliminados = file.codigoArchivo
        ? [...values.documentosEliminados, parseInt(file.codigoArchivo)]
        : values.documentosEliminados;
      setFieldValue('documentosEliminados', documentosEliminados);
      setFieldValue('eliminandoDocumentos', true);

      const documentosFiltrados = documentosLocal.filter(
        archivo => archivo.codigoArchivo !== file.codigoArchivo
      );
      setDocumentosLocal(documentosFiltrados);
      setArchivos([]);
      setFieldValue('archivos', documentosFiltrados);

      setFieldValue('interactuandoDocumentos', true);
      setFieldTouched('archivos', true);
      return;
    },
    [
      documentosLocal,
      setFieldTouched,
      setFieldValue,
      values.documentosEliminados
    ]
  );

  useEffect(() => {

  }, [
    values.eliminandoImagenes,
    values.imagenesEliminadas,
    values.imagenes,
    values.interactuandoImagenes,
    values.imagenesLocal,
    imagenesLocal,
    imagenes.length
  ]);


  return (
    <>

      <div className='col-span-12'>
        {/* Fila 1 */}
        <div className='col-span-12 xs:col-span-12'>
        <div className='col-span-12 md:col-span-12'>
          <Heading type='h4' className='mb-0 text-uv-primary-10'>
            Material adjunto
          </Heading>
            <p>
              Incluye material adjunto para que los demandantes puedan
              visualizar la información y tener una mejor visión sobre lo que
              estás ofreciendo.
            </p>
        </div>
        
        </div>
        {/* Fila 2 */}
        <div className='col-span-12 xs:col-span-12'>
          <div className='grid grid-cols-12 gap-1 align-middle mt-2'>
            {/* Imagenes */}
            <div className='col-span-12 xs:col-span-6'>
              <LabelComponent>
                <span>Imagenes</span>
              </LabelComponent>
              <Dropzone
                name="imagenes"
                enableEditingFile={false}
                setDataFiles={setImagenes}
                dataFiles={values.imagenes || []}
                multiple={true}
                disabled={
                  ( !tipoFormularioSeleccionado.ingresar && cargandoArchivos)  ||
                    (tipoFormularioSeleccionado.ingresar && imagenesLocal.length >= 4)
                  }
                customClassProps={{
                  main:     'grid grid-cols-12 py-5 px-2 min-h-64 ',
                  colLeft:  'col-span-12 xs:col-span-2 xl:col-span-2 mx-auto',
                  colRight: 'col-span-12 xs:col-span-10 xl:col-span-10',
                }}
                {...DICTONARY.DROPZONE.CONFIG_UPLOAD_CREATE_RSD_IMAGES}
              >
                <Text className="text-black font-medium text-xs">
                  Solo se admiten los siguientes formatos de imágen:
                </Text>
                <ul className="grid grid-cols-12 text-xs mt-2">
                  <li className="col-span-5 flex">
                    <IconComponent className="mr-1">
                      <TaskAltIcon className="text-base text-uv-primary-20" />
                    </IconComponent>
                    <Text className="text-neutral-40">
                      JPG, JPGE, PNG y GIF
                    </Text>
                  </li>
                  <li className="col-span-7 flex">
                    <IconComponent className="mr-1">
                      <TaskAltIcon className="text-base text-uv-primary-20" />
                    </IconComponent>
                    <Text className="text-neutral-40">
                      Tamaño no superior a 5 MB
                    </Text>
                  </li>
                </ul>
              </Dropzone>
              {imagenesLocal.length > 0 && !cargandoArchivos && (
            <aside className="mt-5">
              <Text className="mb-2">Archivos Guardados</Text>
              <div className="rounded bg-background-secondary p-5">
                <div className="grid grid-cols-4">
                  {imagenesLocal.map((file, key) => (
                    <FilethumbComponent
                      key={key}
                      disabled={cargandoArchivos}
                      file={{
                        preview: imagenesLocal[key].base64,
                        type: imagenesLocal[key].extension,
                        name: imagenesLocal[key].nombreArchivo
                      }}
                      onRemove={() => {
                        eliminarImagen(file);
                      }}
                    />
                  ))}
                </div>
              </div>
            </aside>
          )}
            </div>

            {/* Subida de archivos */}
            <div className='col-span-12 xs:col-span-6'>
              <LabelComponent>Otros documentos</LabelComponent>

              <Dropzone
                name="archivos"
                setDataFiles={setArchivos}
                enableEditingFile={false}
                dataFiles={archivos}
                multiple={true}
                disabled={
                  ( !tipoFormularioSeleccionado.ingresar && cargandoArchivos)  ||
                    (tipoFormularioSeleccionado.ingresar && documentosLocal.length >= 4)
                  }
                customClassProps={{
                  main:     'grid grid-cols-12 py-5 px-2 min-h-64',
                  colLeft:  'col-span-12 xs:col-span-2 xl:col-span-2 mx-auto',
                  colRight: 'col-span-12 xs:col-span-10 xl:col-span-10',
                }}
                {...DICTONARY.DROPZONE.CONFIG_UPLOAD_CREATE_RSD_OTROS_ARCHIVOS}
              >
                <Text className="text-black font-medium text-xs">
                  Adjunta documentos relacionados al residuo.
                </Text>
                <ul className="grid grid-cols-12 text-xs mt-2">
                  <li className="col-span-4 flex">
                    <IconComponent className="mr-2">
                    <TaskAltIcon className="text-base !text-uv-primary-20" />
                    </IconComponent>
                    <Text className="text-neutral-40">
                      PDF
                    </Text>
                  </li>
                  <li className="col-span-8 flex">
                    <IconComponent className="mr-2">
                    <TaskAltIcon className="text-base !text-uv-primary-20" />
                    </IconComponent>
                    <Text className="text-neutral-40">
                      Tamaño no superior a 25 MB
                    </Text>
                  </li>
                </ul>
              </Dropzone>
              {documentosLocal.length > 0 && !cargandoArchivos && (
                <aside className="mt-5">
                  <Text className="mb-2">Archivos Guardados</Text>
                  <div className="rounded bg-background-secondary p-5">
                    <div className="grid grid-cols-4">
                      {documentosLocal.map((file, key) => (
                        <FilethumbComponent
                          key={key}
                          disabled={cargandoArchivos}
                          file={{
                            preview: documentosLocal[key].base64,
                            type: documentosLocal[key].extension,
                            name: documentosLocal[key].nombreArchivo
                          }}
                          onRemove={() => {
                            eliminarDocumento(file);
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </aside>
          )}
              <p>
                {errors.archivos ? (
                  <div className="text-red-500">{errors.archivos}</div>
                ) : null}
              </p>

              {/* </div> */}
            </div>

          </div>
        </div>
      </div>

  </>
  )
}
