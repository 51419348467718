import { useContext } from 'react';
import { AuthContext } from 'context';
import { RUTAS } from 'const/Dictonary';
export const useLogout = () => {
  // Logout Context
  const { logout } = useContext(AuthContext);
  const handleLogout = () => {
    localStorage.removeItem('logeduser');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    logout();
    window.location.pathname = RUTAS.HOME;
  };

  return handleLogout;
};
