import React from 'react';
import List from '@mui/material/List';
import Factory from '@mui/icons-material/Factory';
import FindReplace from '@mui/icons-material/FindReplace';
import FileUpload from '@mui/icons-material/FileUpload';
import ListItemButtonComponent from 'components/design-system/ListItemButton/ListItemButton';
import { RUTAS } from 'const/Dictonary';
import ListSubheaderComponent from 'components/design-system/ListSubheader/ListSubheader';
import { CompareArrows, Domain, InvertColors } from '@mui/icons-material';

const SubMenuAnalisisTerritorial = React.forwardRef(
  ({ isSuperAdmin, forceRefresh, isAnalistaTerritorial }, ref) => {
    return (
      <List>
        <ListItemButtonComponent
          onClick={forceRefresh(RUTAS.ANALISIS_TERRITORIAL_BASE)}
          icon={<Factory />}
          label={'Análisis línea base'}
        />

        <ListItemButtonComponent
          onClick={forceRefresh(RUTAS.ANALISIS_TERRITORIAL_SINERGIAS)}
          icon={<FindReplace />}
          label={'Potencial de Sinergías'}
        />

        <ListItemButtonComponent
          onClick={forceRefresh(RUTAS.TRANSACCIONES)}
          icon={<CompareArrows />}
          label={'Transacciones'}
        />

        {isSuperAdmin ? (
          <>
            <ListSubheaderComponent className="mt-5">
              Administración
            </ListSubheaderComponent>
            <ListItemButtonComponent
              onClick={forceRefresh(RUTAS.GESTION_TERRITORIAL)}
              icon={<FileUpload />}
              label={'Cargar base de datos'}
            />

            <ListItemButtonComponent
              onClick={forceRefresh(RUTAS.LISTA_EMPRESAS_AT)}
              icon={<Domain />}
              label={'Empresas análisis territorial'}
            />

            <ListItemButtonComponent
              onClick={forceRefresh(RUTAS.LISTA_RESIDUOS_AT)}
              icon={<InvertColors />}
              label={'Residuos análisis territorial'}
            />
          </>
        ) : null}
      </List>
    );
  }
);

export default SubMenuAnalisisTerritorial;
