import { DICTONARY } from 'const/Dictonary';
import { useMedia } from 'react-use';

export const useResponsive = customMediaQuery => {
  const isMDDown = useMedia(DICTONARY.RESPONSIVE_BREAKPOINT.MD);
  const customBreakPoint = useMedia(
    customMediaQuery || DICTONARY.RESPONSIVE_BREAKPOINT.MD
  );

  return {
    isMDDown,
    customBreakPoint
  };
};
