import * as yup from 'yup';
import ValidateRut from 'helpers/validateRut';



const schemaResiduosDeclarados = yup.object().shape({

  // Seccion 1 - Residuos Declarados

  nombreLER: yup
    .object()
    .required('Este campo es obligatorio')
    .typeError('Este campo es obligatorio')
  ,
  nombreSubLER: yup
    .object()
    .required('Este campo es obligatorio')
    .typeError('Este campo es obligatorio')
  ,

  nombreSubSubLER: yup
    .object()
    .required('Este campo es obligatorio')
    .typeError('Este campo es obligatorio')
  ,

  tipoResiduo: yup
    .object()
    .required('Este campo es obligatorio')
    .typeError('Este campo es obligatorio')
  ,

  subTipoResiduo: yup
    .object()
    .required('Este campo es obligatorio')
    .typeError('Este campo es obligatorio')
  ,

  sucursal: yup
    .object()
    .typeError('Este campo es obligatorio')
    .required('Este campo es obligatorio')
  ,

  // formatoEntrega: yup
  //   .object()
  // ,

  fechaTransaccion: yup
    .date()
    .min(new Date(1900, 0, 1), 'La fecha no puede ser menor a 1900-01-01')
    .max(new Date(), 'La fecha no puede ser mayor a la fecha actual')
    // debe tener formato yyy-mm-dd
    .required('Este campo es obligatorio')
    .typeError('No es una fecha válida')
  ,

  cantidad: yup
    .number()
    .required('Este campo es obligatorio')
    .typeError('Debe ser un número')
    .typeError('Este campo es obligatorio')
  ,


  // levantamientoDatos: yup
  //   .object()
  // ,


  // Seccion 2 - Transporte

  rutEmpresaTransporte: yup // Integer (max 12), opcional
    .string()
    .trim()
    .nullable()
    .matches(/^[^.\s]*$/, 'No se permiten puntos en este campo')
    .max(12, 'La cantidad máxima de caracteres es 11')
    .min(9, 'La cantidad mínima de caracteres es 9')
    // si tiene guion
    .matches(
      /^[0-9]+[-|‐]{1}[0-9kK]{1}$/,
      'El rut debe ser del tipo 12345678-9'
    )
    .test(
      'Evaluación de rut',
      'El rut ingresado es inválido',
      value => !value || ValidateRut.isValid(value)
    )
  ,

  tipoTransporte: yup
    .object()
    .required('Este campo es obligatorio')
    .typeError('Este campo es obligatorio')
  ,

  // Seccion 3 - Receptor

  rutEmpresaReceptor: yup // Integer (max 12), obligatorio
    .string()
    .trim()
    .matches(/^[^.\s]*$/, 'No se permiten puntos en este campo')
    .max(12, 'La cantidad máxima de caracteres es 11')
    .min(9, 'La cantidad mínima de caracteres es 9')
    // si tiene guion
    .matches(
      /^[0-9]+[-|‐]{1}[0-9kK]{1}$/,
      'El rut debe ser del tipo 12345678-9'
    )
    .test(
      'Evaluación de rut',
      'El rut ingresado es inválido',
      value => !value || ValidateRut.isValid(value)
    )
    .required('Este campo es obligatorio')
  ,

  region : yup
    .object()
    .required('Este campo es obligatorio')
    .typeError('Este campo es obligatorio')
  ,

  comuna : yup
    .object()
    .required('Este campo es obligatorio')
    .typeError('Este campo es obligatorio')
  ,

  nombreVia: yup
    .string()
    .trim()
    // validacion para direccion nombre y numero  de via
    // .matches(
    //   /^[A-Za-z0-9ñÑ\s]+(?: [A-Za-z0-9ñÑ\s]+)*,\s*\d+$/,
    //   'La dirección debe ser del tipo "nombre de calle, número"'
    // )
    .max(100, 'La cantidad máxima de caracteres es 100')
    .min(3, 'La cantidad mínima de caracteres es 3')
    .required('Este campo es obligatorio')
  ,

  tipoTratamiento: yup
    .object()
    .required('Este campo es obligatorio')
    .typeError('Este campo es obligatorio')
  ,

  codigoSINADER: yup // Integer (max 12), opcional
    .string()
    .nullable()
    .min(4, 'La cantidad mínima de caracteres es de 4 dígitos')
    .max(7, 'La cantidad máxima de caracteres es de 7 dígitos')
  ,



});

export { schemaResiduosDeclarados };