import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import store from 'states/store';
import iconReduccionCambioClimatico from 'assets/images/reportes/reduccion-cambio-climatico.svg';
import iconReduccionDemandaAcumulada from 'assets/images/reportes/reduccion-demanda-acumulada.svg';
import iconReduccionFormacionMaterial from 'assets/images/reportes/reduccion-formacion-material.svg';
import iconReduccionRecursoFosil from 'assets/images/reportes/reduccion-recurso-fosil.svg';
import iconTransaccionesRealizadas from 'assets/images/reportes/transacciones-realizadas.svg';
import iconResiduosValorizado from 'assets/images/reportes/residuos-valorizado.svg';
import iconHorasDeTrabajo from 'assets/images/reportes/horas-de-trabajo.svg';
import iconSalario from 'assets/images/reportes/salario.svg';
import iconParidadMujeres from 'assets/images/reportes/paridad-mujeres.svg';
import iconResiduosDeclarado from 'assets/images/reportes/residuos-declarado.svg';
import serviceCommons from 'services/commons';
import Text from 'components/design-system/Text/Text';
import empresasService from 'services/empresas';
import { formatoANumeroIngles } from 'helpers/formateoNumeros';

export const useReporte = () => {
  const { logeduser } = store.loguedUser;
  const { isSuperAdmin } = store.loguedUser.perfiles;

  const conIndicadores =
    logeduser?.sucursal?.consumoCombustible !== null &&
    logeduser?.sucursal?.consumoEnergetico !== null &&
    logeduser?.sucursal?.trabajadoresMasculino !== null &&
    logeduser?.sucursal?.trabajadoresFemenino !== null
      ? true
      : false;

  const { codigoEmpresa } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [indicadores, setIndicadores] = useState([
    {
      heading: null,
      items: [
        {
          image: iconTransaccionesRealizadas,
          imageAlt: 'Transacciones realizadas',
          heading: <Text>Transacciones realizadas</Text>,
          description: 'Transacciones',
          valor: '-',
          element: null
        },
        {
          image: iconResiduosValorizado,
          imageAlt: 'Residuos valorizado',
          heading: <Text>Residuos valorizado</Text>,
          description: 'Ton',
          valor: '-',
          element: null
        },
        {
          image: iconResiduosDeclarado,
          imageAlt: 'Residuos declarados',
          heading: <Text>Residuos declarados</Text>,
          description: 'Ton',
          valor: '-',
          element: null
        },
      ]
    },

    {
      heading: 'Reducción impactos ambientales',
      items: [
        {
          image: iconReduccionCambioClimatico,
          imageAlt: 'Reduccion Recurso Fosil',
          heading: (
            <Text>
              Cambio
              <br />
              climático
            </Text>
          ),
          description: 'kg CO2eq',
          valor: '-',
          element: null
        },
        {
          image: iconReduccionRecursoFosil,
          imageAlt: 'Reduccion Recurso Fosil',
          heading: (
            <Text>
              Agotamiento de
              <br />
              recurso fósil
            </Text>
          ),
          description: 'kg petróleo eq',
          valor: '-',
          element: null
        },
        {
          image: iconReduccionFormacionMaterial,
          imageAlt: 'Reduccion Formacion Material',
          heading: (
            <Text>
              Formación de
              <br />
              material particulado
            </Text>
          ),
          description: 'kgMP 2,5eq',
          valor: '-',
          element: null
        },
        {
          image: iconReduccionDemandaAcumulada,
          imageAlt: 'Reduccion Demanda Acumulada',
          heading: (
            <Text>
              Demanda acumulada
              <br />
              de energía
            </Text>
          ),
          description: 'MJ eq',
          valor: '-',
          element: null
        }
      ]
    },

    {
      heading: 'Indicadores Sociales',
      items: [
        {
          image: iconHorasDeTrabajo,
          imageAlt: 'Horas de trabajo',
          heading: <Text>Horas de trabajo</Text>,
          description: 'H totales',
          valor: '-',
          element: null
        },
        {
          image: iconParidadMujeres,
          imageAlt: 'Paridad Mujeres',
          heading: <Text>Paridad Mujeres</Text>,
          description: 'HM',
          valor: '-',
          element: null
        },
        {
          image: iconSalario,
          imageAlt: 'Salario',
          heading: <Text>Salario</Text>,
          description: 'Mil pesos',
          valor: '-',
          element: null
        }
      ]
    }
  ]);

  const [resumenIndicadores, setResumenIndicadores] = useState({
    demanda: [],
    oferta: []
  });
  const [empresas, setEmpresas] = useState([]);

  const renderEstadisticas = data => {
    setIndicadores([
      {
        heading: null,
        items: [
          {
            image: iconTransaccionesRealizadas,
            imageAlt: 'Transacciones realizadas',
            heading: <Text>Transacciones realizadas</Text>,
            description: 'Transacciones',
            valor: '-',
            element: data.transaccion
              ? formatoANumeroIngles(data.transaccion.toString())
              : data.transaccion
          },
          {
            image: iconResiduosValorizado,
            imageAlt: 'Residuos valorizado',
            heading: <Text>Residuos valorizado</Text>,
            description: 'Ton',
            valor: '-',
            element: data.residuo?.valorizado
              ? formatoANumeroIngles(data.residuo.valorizado.toString())
              : data.residuo.valorizado
          },
          {
            image: iconResiduosDeclarado,
            imageAlt: 'Residuos declarados',
            heading: <Text>Residuos declarados</Text>,
            description: 'Ton',
            valor: '-',
            element: data.residuo?.declarado
              ? formatoANumeroIngles(data.residuo.declarado.toString())
              : data.residuo.declarado
          },
        ]
      },

      {
        heading: 'Reducción impactos ambientales',
        items: [
          {
            image: iconReduccionCambioClimatico,
            imageAlt: 'Reduccion Recurso Fosil',
            heading: (
              <Text>
                Cambio
                <br />
                climático
              </Text>
            ),
            description: 'kg CO2eq',
            valor: '-',
            element: data.reduccion.reduccionCambioClimatico
              ? formatoANumeroIngles(
                  data.reduccion.reduccionCambioClimatico.toString()
                )
              : data.reduccion.reduccionCambioClimatico
          },
          {
            image: iconReduccionRecursoFosil,
            imageAlt: 'Reduccion Recurso Fosil',
            heading: (
              <Text>
                Agotamiento de
                <br />
                recurso fósil
              </Text>
            ),
            description: 'kg petróleo eq',
            valor: '-',
            element: data.reduccion.reduccionRecursoFosil
              ? formatoANumeroIngles(
                  data.reduccion.reduccionRecursoFosil.toString()
                )
              : data.reduccion.reduccionRecursoFosil
          },
          {
            image: iconReduccionFormacionMaterial,
            imageAlt: 'Reduccion Formacion Material',
            heading: (
              <Text>
                Formación de
                <br />
                material particulado
              </Text>
            ),
            description: 'kgMP 2,5eq',
            valor: '-',
            element: data.reduccion.reduccionMaterialParticulado
              ? formatoANumeroIngles(
                  data.reduccion.reduccionMaterialParticulado.toString()
                )
              : data.reduccion.reduccionMaterialParticulado
          },
          {
            image: iconReduccionDemandaAcumulada,
            imageAlt: 'Reduccion Demanda Acumulada',
            heading: (
              <Text>
                Demanda acumulada
                <br />
                de energía
              </Text>
            ),
            description: 'MJ eq',
            valor: '-',
            element: data.reduccion.reduccionDemandaAcumulada
              ? formatoANumeroIngles(
                  data.reduccion.reduccionDemandaAcumulada.toString()
                )
              : data.reduccion.reduccionDemandaAcumulada
          }
        ]
      },

      {
        heading: 'Indicadores Sociales',
        items: [
          {
            image: iconHorasDeTrabajo,
            imageAlt: 'Horas de trabajo',
            heading: <Text>Horas de trabajo</Text>,
            description: 'H totales',
            valor: '-',
            element: data.social.indicador.horasTrabajo
              ? formatoANumeroIngles(
                  data.social.indicador.horasTrabajo.toString()
                )
              : data.social.indicador.horasTrabajo
          },
          {
            image: iconParidadMujeres,
            imageAlt: 'Paridad Mujeres',
            heading: <Text>Paridad Mujeres</Text>,
            description: 'HM',
            valor: '-',
            element: data.social.indicador.horasTrabajoMujer
              ? formatoANumeroIngles(
                  data.social.indicador.horasTrabajoMujer.toString()
                )
              : data.social.indicador.horasTrabajoMujer
          },
          {
            image: iconSalario,
            imageAlt: 'Salario',
            heading: <Text>Salario</Text>,
            description: 'Mil pesos',
            valor: '-',
            element: data.social.indicador.salario
              ? formatoANumeroIngles(data.social.indicador.salario.toString())
              : data.social.indicador.salario
          }
        ]
      }
    ]);
  };

  const init = async () => {
    try {
      let empresas = [];

      if (isSuperAdmin) {
        const responseEmpresas = await empresasService.obtieneEmpresas({
          totalPagina: 1000,
          ordenarPor: 'nombreEmpresa'
        });
        if (responseEmpresas.status === 200) {
          empresas = responseEmpresas.data.content.map(empresa => ({
            codigoEmpresa: empresa.codigoEmpresa,
            nombreEmpresa: empresa.nombreEmpresa,
            rutEmpresa: empresa.rutEmpresa
          }));
        }
        setEmpresas(empresas);
        setIsLoading(false);
      }

      cargaIndicadores(isSuperAdmin ? null : codigoEmpresa);
    } catch (error) {
      setIsLoading(false);
      console.log('Error en cargaIndicadoresIniciales ', error);
    }
  };

  const cargaIndicadores = async codigoEmpresa => {
    try {
      // se evita que se llame a la api si no se ha seleccionado una empresa
      if (!codigoEmpresa) return;
      setIsLoading(true);
      const queryIndicadores = {
        tipoIndicador: 'todos',
        codigoEmpresa: codigoEmpresa
      };

      const response = await serviceCommons.indicadores(queryIndicadores);
      if (response.status === 200) {
        renderEstadisticas(response.data);
        setResumenIndicadores(response.data.resumen);
      }
      setIsLoading(false);
    } catch (error) {
      console.log('Error en cargaIndicadoresIniciales ', error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return {
    isLoading,
    indicadores,
    conIndicadores,
    isSuperAdmin,
    empresas,
    cargaIndicadores,
    resumenIndicadores
  };
};
