import * as yup from 'yup';
import { verificaEmpresaRegistrada } from '../helpers/verificaCorreoRegistrado';
import ValidateRut from 'helpers/validateRut';

let respuestaRutFinal = '';

const SchemaBusinessInfo = yup.object().shape({
  rutEmpresa: yup.string().when('tipoNegocio', {
    is: 'empresa',
    then: () => {
      return (
        yup
          .string()
          .trim()
          .matches(/^[^.\s]*$/, 'No se permiten puntos en este campo')
          .max(11, 'La cantidad máxima de caracteres es 11')
          .min(9, 'La cantidad mínima de caracteres es 9')
          .required('Es un campo obligatorio')
          // .test(
          //   'Evaluación de rut',
          //   'El rut ingresado es inválido',
          //   value => !value || ValidateRut.isValid(value)
          // )
          .test(
            'Evaluación de rut',
            'El rut ingresado está registrado',
            async (value, { parent }) => {
              const rutModificado = parent.rutModificado;

              // rut incluye guion
              let rutValido = /^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(value.trim());
              let rutChileno = ValidateRut.isValid(value);

              if (
                value.length >= 9 &&
                rutModificado &&
                rutValido &&
                rutChileno
              ) {
                // verifica si el rut existe en la base de datos
                respuestaRutFinal = await verificaEmpresaRegistrada(value).then(
                  resp => !resp
                );
              }
              return respuestaRutFinal;
            }
          )
      );
    }
  }),

  razonSocial: yup.string().when('tipoNegocio', {
    is: 'empresa',
    then: () => {
      return yup.string().required('Es un campo obligatorio');
    }
  }),

  rubro: yup.object().when('tipoNegocio', {
    is: 'empresa',
    then: () => {
      return yup
        .object()
        .typeError('Este campo es obligatorio')
        .required('Este campo es obligatorio');
    }
  }),

  actividad: yup.object().when('tipoNegocio', {
    is: 'empresa',
    then: () => {
      return yup
        .object()
        .typeError('Este campo es obligatorio')
        .required('Este campo es obligatorio');
    }
  }),

  nombre: yup.object().when('tipoNegocio', {
    is: 'persona-natural',
    then: () => {
      return yup
        .string()
        .max(100, 'La cantidad máxima de caracteres es 100')
        .min(3, 'La cantidad mínima de caracteres es 3')
        .required('Es un campo obligatorio');
    }
  }),

  region: yup
    .object()
    .typeError('Este campo es obligatorio')
    .required('Este campo es obligatorio'),

  comuna: yup
    .object()
    .typeError('Este campo es obligatorio')
    .required('Este campo es obligatorio'),

  nombreVia: yup
    .string()
    .trim()
    // validacion para direccion nombre y numero  de via
    // .matches(
    //   /^[A-Za-z0-9ñÑ\s]+(?: [A-Za-z0-9ñÑ\s]+)*,\s*\d+$/,
    //   'La dirección debe ser del tipo "nombre de calle, número"'
    // )
    .max(100, 'La cantidad máxima de caracteres es 100')
    .min(3, 'La cantidad mínima de caracteres es 3')
    .required('Es un campo obligatorio')
});

export { SchemaBusinessInfo };
