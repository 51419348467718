import storeMessages from 'states/alertMessages/store';
import store from 'states/store';
import { useEffect, useState } from 'react';
import usuariosService from 'services/usuarios';
import { refrescaUsuario } from 'views/Business/hooks';
import { RUTAS } from 'const/Dictonary';

export const useOlvidoContrasena = () => {
  const { logeduser } = store.loguedUser;
  const [openRedirect, setOpenRedirect] = useState(true);

  const handleClickCloseRedirect = () => {
    setOpenRedirect(false);
  };

  useEffect(() => {
    if (logeduser && !logeduser.passwordTemporal) {
      window.location.pathname = RUTAS.HOME_USER;
    }
  }, [logeduser]);

  const handleOnSubmit = async (values, resetForm) => {
    const response = await usuariosService.actualizarPassword(
      logeduser.codigoUsuario,
      {
        passwordActual: values.passwordActual,
        passwordNueva: values.password
      }
    );

    if (response.status === 200) {
      resetForm();
      storeMessages.message.setMessage({
        mensaje: 'La contraseña se ha actualizado correctamente',
        tipo: 'success'
      });
      await refrescaUsuario();
    }
  };

  return {
    openRedirect,
    handleClickCloseRedirect,
    handleOnSubmit
  }
};