import getEnv from 'helpers/getEnv';

const URLS = {
  baseURL:
    getEnv('REACT_APP_API_URL') ||
    'https://residuo-recurso.cl/api-industria-circular/v1'
};

console.log('URLS :>> ', URLS);
export default URLS;
