import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import IconComponent from 'components/design-system/icon/Icon';
import Text from 'components/design-system/Text/Text';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import React, { useEffect, useState } from 'react';
import {
  ChevronLeft,
  ExpandLess,
  ExpandMore,
  Factory,
  Insights,
  People,
  Warehouse
} from '@mui/icons-material';
import { Divider } from '@mui/material';
import LinkComponent from 'components/design-system/Link/Link';
import Loading from 'components/Loading/Loading';
import { DICTONARY, RUTAS } from 'const/Dictonary';
import store from 'states/store';

export const BusinessSuccess = ({
  values,
  isLoading,
  errorService,
  mensajeError
}) => {
  const { nombreVia, comuna, region, codigoRolesEmpresa } = values;
  const { nombreComuna } = comuna;
  const { nombreRegion } = region;

  const { logeduser } = store.loguedUser;
  const codigoEmpresa = logeduser?.sucursal
    ? logeduser.sucursal.empresa.codigoEmpresa
    : null;
  const [residuo, setResiduo] = useState(values.nombreResiduosSeleccionados);

  const direccionSucursal = `${nombreVia} , ${nombreComuna} , ${nombreRegion}`;

  const [isDisplayMore, setIsDisplayMore] = useState(false);

  mensajeError = mensajeError.mensaje
    ? mensajeError.mensaje
    : 'Se ha producido un error al crear la sucursal';

  useEffect(() => {}, [codigoRolesEmpresa]);

  const asignaNombreRol = codigoRolesEmpresa => {
    const valores = codigoRolesEmpresa;

    const valoresNuevos = valores.map(valor => {
      switch (valor) {
        case '1':
          return DICTONARY.ROL.GENERADOR.NOMBRE;
        case '2':
          return DICTONARY.ROL.RECEPTOR.NOMBRE
        case '3':
          return 'Transportista';
        case '4':
          return 'Gestor';
        case '5':
          return 'Asesor';
        default:
          return valor;
      }
    });

    // transformar array en texto separado por comas
    let nombreRoles = valoresNuevos.join(', ');
    return nombreRoles;
  };
  useEffect(() => {
    // console.log(errorService);
  }, [errorService]);

  return (
    <>
      {!isLoading ? (
        !errorService ? (
          <>
            <div className="bg-white rounded shadow-md">
              <div className="grid grid-cols-12 py-6 px-8 gap-5">
                <div className="col-span-12 text-center">
                  <Heading type="h2">Tu negocio ha sido registrado</Heading>
                  <Text className="mb-2">
                    Se ha creado correctamente tu{' '}
                    <b className="text-black">Negocio </b> inicial
                  </Text>
                </div>

                <div className="col-span-12 xs:col-span-12  lg:col-span-12">
                  <div className="border border-uv-primary-80 rounded p-4 mt-2">
                    <div className="grid grid-cols-12 gap-4 items-center">
                      <div className="col-span-2 text-center m-auto">
                        <div className="w-12 h-12 rounded-full bg-[#2E8567] align-middle justify-center content-center items-center min-w-[48px]">
                          <IconComponent color="primary" className="text-white">
                            <Factory className="w-8 h-8" />
                          </IconComponent>
                        </div>
                      </div>
                      <div className="col-span-10">
                        <Heading type="h4" className="font-normal mb-0">
                          Información del{' '}
                          <b className="text-black font-semibold">
                            Negocio
                            {values.tipoNegocio === 'empresa'
                              ? ' como Empresa'
                              : ' como Persona Natural'}
                          </b>
                        </Heading>
                      </div>
                    </div>

                    <div className="grid grid-cols-12 gap-4">
                      <div className="col-span-12 mt-4">
                        <Divider />
                      </div>
                      {values.tipoEmpresa === 'persona-natural' && (
                        <>
                          <div className="col-span-2 text-right">
                            <Text className="opacity-60">Nombre</Text>
                          </div>

                          <div className="col-span-10 text-left">
                            <Text>{values.nombre}</Text>
                          </div>
                        </>
                      )}
                      {values.tipoEmpresa === 'empresa' && (
                        <>
                          <div className="col-span-2 text-right">
                            <Text className="opacity-60">Razon Social</Text>
                          </div>
                          <div className="col-span-10 text-left">
                            <Text>{values.razonSocial}</Text>
                          </div>

                          <div className="col-span-2 text-right">
                            <Text className="opacity-60">Rut</Text>
                          </div>
                          <div className="col-span-10 text-left">
                            <Text>{values.rutEmpresa || ''}</Text>
                          </div>

                          <div className="col-span-2 text-right">
                            <Text className="opacity-60">Rubro</Text>
                          </div>
                          <div className="col-span-10 text-left">
                            <Text>{values.rubro.nombreRubro || ''} </Text>
                          </div>

                          <div className="col-span-2 text-right">
                            <Text className="opacity-60">Actividad</Text>
                          </div>
                          <div className="col-span-10 text-left">
                            <Text>
                              {values.actividad.nombreActividad || ''} 
                            </Text>
                          </div>
                        </>
                      )}

                      <div className="col-span-2 text-right">
                        <Text className="opacity-60">Dirección</Text>
                      </div>
                      <div className="col-span-10 text-left">
                        <Text>{direccionSucursal}</Text>
                      </div>
                      <div className="col-span-2 text-right">
                        <Text className="opacity-60">Rol</Text>
                      </div>
                      <div className="col-span-10 text-left">
                        <Text>{asignaNombreRol(codigoRolesEmpresa) || ''}</Text>
                      </div>
                      <div className="col-span-2 text-right">
                        <Text className="opacity-60">Residuos</Text>
                      </div>
                      <div className="col-span-10 text-left">
                        <ul className="mb-3">
                          {residuo.map((item, key) => (
                            <>
                              {key <= 2 && (
                                <li className="d-flex items-center mb-1">
                                  <IconComponent className="mr-2">
                                    <TaskAltIcon className="text-base" />
                                  </IconComponent>
                                  <Text>{item.nombre}</Text>
                                </li>
                              )}
                            </>
                          ))}

                          {isDisplayMore &&
                            residuo.map((item, key) => (
                              <>
                                {key > 2 && (
                                  <li className="d-flex items-center mb-1">
                                    <IconComponent className="mr-2">
                                      <TaskAltIcon className="text-base" />
                                    </IconComponent>
                                    <Text>{item.nombre}</Text>
                                  </li>
                                )}
                              </>
                            ))}
                        </ul>
                        <ButtonComponent
                          type="secondary"
                          onClick={() => {
                            setIsDisplayMore(!isDisplayMore);
                          }}
                        >
                          {isDisplayMore ? (
                            <>
                              Ocultar <ExpandLess />
                            </>
                          ) : (
                            <>
                              Ver más <ExpandMore />
                            </>
                          )}
                        </ButtonComponent>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center my-8">
              <Heading type="h4">¿Que puedo hacer ahora?</Heading>
            </div>

            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-3 text-center bg-white shadow-md rounded p-4">
                <div className="w-12 h-12 rounded-full bg-[#2E8567] align-middle justify-center content-center items-center min-w-[48px] m-auto mb-2 -mt-8">
                  <IconComponent color="primary" className="text-white">
                    <Warehouse className="w-8 h-8" />
                  </IconComponent>
                </div>
                <Heading type="h3" className="mb-4">
                  Sucursales
                </Heading>
                <Text className="mb-4 px-4">
                  Administra distintas sucursales para tu negocio
                </Text>
                <LinkComponent
                  href={`${RUTAS.NEGOCIO}/${codigoEmpresa}/sucursales`}
                  type="button"
                >
                  Administrar
                </LinkComponent>
              </div>

              <div className="col-span-3 text-center bg-white shadow-md rounded p-4">
                <div className="w-12 h-12 rounded-full bg-[#2E8567] align-middle justify-center content-center items-center min-w-[48px] m-auto mb-2 -mt-8">
                  <IconComponent color="primary" className="text-white">
                    <People className="w-8 h-8" />
                  </IconComponent>
                </div>
                <Heading type="h3" className="mb-4">
                  Usuarios
                </Heading>
                <Text className="mb-4 px-4">
                  Invita nuevos usuarios a participar en tu negocio
                </Text>
                <LinkComponent
                  href={`${RUTAS.NEGOCIO}/${codigoEmpresa}/usuarios`}
                  type="button"
                >
                  Administrar
                </LinkComponent>
              </div>

              <div className="col-span-3 text-center bg-white shadow-md rounded p-4">
                <div className="w-12 h-12 rounded-full bg-[#2E8567] align-middle justify-center content-center items-center min-w-[48px] m-auto mb-2 -mt-8">
                  <IconComponent color="primary" className="text-white">
                    <Insights className="w-8 h-8" />
                  </IconComponent>
                </div>
                <Heading type="h3" className="mb-4">
                  Indicadores
                </Heading>
                <Text className="mb-4 px-4">
                  Administra tus indicadores para conocer el impacto ambiental
                </Text>
                <LinkComponent
                  href={`${RUTAS.NEGOCIO}/${codigoEmpresa}/indicadores`}
                  type="button"
                >
                  Administrar
                </LinkComponent>
              </div>

              <div className="col-span-3 text-left bg-white shadow-md rounded px-6 py-4">
                <div className="h-full flex flex-col items-start justify-center">
                  <Heading type="h3" className="mb-4">
                    Volver al panel
                    <br />
                    de administración
                  </Heading>
                  <LinkComponent
                    href={RUTAS.HOME_USER}
                    type="button"
                    className="pl-2"
                  >
                    <ChevronLeft className="mr-2" /> Volver
                  </LinkComponent>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="bg-white rounded text-center  m-auto h-full ">
              <div className="flex flex-col items-center justify-center">
                <Heading type="h3" className="mb-10 text-error ">
                  {mensajeError}
                </Heading>
                <Heading type="h3" className="mt-10">
                  Volver al panel
                  <br />
                  de administración
                </Heading>
                <LinkComponent
                  href={RUTAS.HOME_USER}
                  type="button"
                  className="pl-2"
                >
                  <ChevronLeft className="mr-2" /> Volver
                </LinkComponent>
              </div>
            </div>
          </>
        )
      ) : (
        <Loading />
      )}
    </>
  );
};

export default BusinessSuccess;
