// Services
import { useState } from 'react';
import { DICTONARY } from 'const/Dictonary';
import serviceEmpresas from 'services/empresas';
// import serviceDocumentos from 'services/documentos';
// import storeMessages from 'states/alertMessages/store';
// Custom hook

// Llama servicio Rubros
export const CargaRubros = () => {
  const [rubros, setRubros] = useState([]);
  const [isLoadingRubros, setIsLoadingRubros] = useState(true);

  const loadRubros = async () => {
    // Lamma servicio rubros
    try {
      setIsLoadingRubros(true);
      const response = await serviceEmpresas.rubros({
        totalPagina: DICTONARY.PAGINACION.ALL_DATA,
        ordenarPor: 'codigoCiuRubro'
      });

      const { data } = response;

      if (response.status === 200) {
        const rubrosData = data.content;
        setRubros(rubrosData);
      }

      data.empty && setRubros([]);
    } catch (error) {
      setRubros([]);
      console.error('error: ', error);
    } finally {
      setIsLoadingRubros(false);
    }
  };

  return { rubros, loadRubros, isLoadingRubros, setRubros };
};

// Llama servicio Actividades
export const CargaActividades = () => {
  const [actividades, setActividades] = useState([]);

  const [isLoadingActividades, setIsLoadingActividades] = useState(true);

  const loadActividades = async codigoRubro => {
    if (!codigoRubro) return;
    // Lamma servicio actividades
    const params = {
      codigoRubro: codigoRubro,
      // totalPagina: DICTONARY.PAGINACION.ALL_DATA,
      ordenarPor: 'codigoCiuActividad'
    };

    try {
      setIsLoadingActividades(true);
      const response = await serviceEmpresas.actividades(params);

      const { data } = response;

      if (response.status === 200) {
        const actividadesData = data.content;
        setActividades(actividadesData);
      }
      data.empty && setActividades([]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingActividades(false);
    }
  };

  return { actividades, setActividades, loadActividades, isLoadingActividades };
};
