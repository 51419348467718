import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import logoPEC from './../../../../assets/images/footer/footerPlataforma.svg';
import ticblueLogo from './../../../../assets/images/footer/ticbluefooter.svg';
import { MailOutline, Instagram, Twitter, LinkedIn } from '@mui/icons-material';
import uvpackage from '../../../../../package.json';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import { RUTAS } from 'const/Dictonary';
import { AuthContext } from 'context';
import { useLogout } from 'hooks/useLogout';

const Footer = props => {
  const { className, ...rest } = props;
  // Uso de context para saber si el usuario esta logeado o no
  const { logged } = useContext(AuthContext);
  const handleLogout = useLogout();

  return (
    <footer className="mt-auto" {...rest}>
      <div className="bg-uv-primary-0 pt-10 pb-2">
        <div className="px-4 md:container md:mx-auto">
          <div className="grid grid-cols-12 gap-5 pb-8">
            <div className="col-span-12 md:col-span-3">
              <img src={logoPEC} className="pb-4 mx-auto" />

              <div className="flex mx-auto justify-center">
                <div className="p-2">
                  <a
                    className="inline-block text-white opacity-70 hover:opacity-100"
                    href="https://twitter.com/IndCircular"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Twitter />
                  </a>
                </div>
                <div className="p-2">
                  <a
                    className="inline-block text-white opacity-70 hover:opacity-100"
                    href="https://www.linkedin.com/company/plataforma-industria-circular/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkedIn />
                  </a>
                </div>
                <div className="p-2">
                  <a
                    className="inline-block text-white opacity-70 hover:opacity-100"
                    href="https://www.instagram.com/plataformaindustriacircular_/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Instagram />
                  </a>
                </div>
                <div className="p-2">
                  <a
                    className="inline-block text-white opacity-70 hover:opacity-100"
                    href="mailto:contacto@plataforma-industria-circular.cl"
                  >
                    <div className="d-flex">
                      <MailOutline />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-span-1"></div>
            <div className="col-span-12 md:col-span-8">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12 xs:col-span-6 sm:col-span-4 mb-5 md:mb-0">
                  <Heading type="h5" className="text-white mb-8">
                    Nosotros
                  </Heading>
                  <ul>
                    <li className="mb-2">
                      <a
                        href="https://plataforma-industria-circular.cl/#quienes-somos"
                        className="inline-block"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <Text className="text-white opacity-70 hover:opacity-100">
                          Quienes somos
                        </Text>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://plataforma-industria-circular.cl/sala-de-prensa/"
                        rel="noreferrer"
                        target="_blank"
                        className="inline-block"
                      >
                        <Text className="text-white opacity-70 hover:opacity-100">
                          Sala de Prensa
                        </Text>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-span-12 xs:col-span-6 sm:col-span-4 mb-5 md:mb-0">
                  <Heading type="h5" className="text-white mb-8">
                    Información
                  </Heading>
                  <ul>
                    <li className="mb-2">
                      <a
                        href="https://plataforma-industria-circular.cl/"
                        rel="noreferrer"
                        target="_blank"
                        className="inline-block"
                      >
                        <Text className="text-white opacity-70 hover:opacity-100">
                          Portal de difusión
                        </Text>
                      </a>
                    </li>

                    <li className="mb-2">
                      <a
                        href="https://residuo-recurso.atlassian.net/servicedesk/customer/portal/1/group/1/create/5"
                        rel="noreferrer"
                        target="_blank"
                        className="inline-block"
                      >
                        <Text className="text-white opacity-70 hover:opacity-100">
                          Reportar problema
                        </Text>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://residuo-recurso.atlassian.net/servicedesk/customer/portal/1/group/1/create/4"
                        rel="noreferrer"
                        target="_blank"
                        className="inline-block"
                      >
                        <Text className="text-white opacity-70 hover:opacity-100">
                          Contacto comercial
                        </Text>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-span-12 xs:col-span-6 sm:col-span-4 mb-5 md:mb-0">
                  <Heading type="h5" className="text-white mb-8">
                    Mi cuenta
                  </Heading>
                  <ul>
                    {logged ? (
                      <>
                        <li className="mb-2">
                          <a
                            href={RUTAS.EDITAR_CUENTA}
                            className="inline-block"
                          >
                            <Text className="text-white opacity-70 hover:opacity-100">
                              Perfíl
                            </Text>
                          </a>
                        </li>
                        <li className="mb-2">
                          <a
                            href="#"
                            onClick={handleLogout}
                            className="inline-block"
                          >
                            <Text className="text-white opacity-70 hover:opacity-100">
                              Cerrar sesión
                            </Text>
                          </a>
                        </li>
                      </>
                    ) : (
                      <>
                        <li className="mb-2">
                          <a href={RUTAS.REGISTRARSE} className="inline-block">
                            <Text className="text-white opacity-70 hover:opacity-100">
                              Registro
                            </Text>
                          </a>
                        </li>
                        <li className="mb-2">
                          <a href="/" className="inline-block">
                            <Text className="text-white opacity-70 hover:opacity-100">
                              Inicio de Sesión
                            </Text>
                          </a>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-5 pt-2 border-t border-t-uv-primary-20">
            <div className="col-span-12 md:col-span-6 order-2 md:order-1">
              <Text className="text-white opacity-80 font-normal text-center md:text-start">
                v{uvpackage.version} © 2023 Todos los derechos reservados
              </Text>
            </div>
            <div className="col-span-12 md:col-span-6 text-center md:text-end order-1 md:order-2">
              <a
                href="https://ticblue.cl"
                target="_blank"
                className="inline-block"
                rel="noreferrer"
              >
                <div className="d-flex justify-center">
                  <Text className="text-white opacity-50 mr-2 pt-1 font-normal">
                    Desarrollado por
                  </Text>
                  <img src={ticblueLogo} alt="TICBLUE" className="ml-1 w-32" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
