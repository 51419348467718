import { Close, LocationOff, MoreVert } from '@mui/icons-material';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import Text from 'components/design-system/Text/Text';
import { useContext, useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import Leaflet from 'leaflet';
import Heading from 'components/design-system/Heading/Heading';
import { MapaBaseSkeleton } from './MapaBaseSkeleton';
import { useMapaEmpresas } from '../hooks/useMapaEmpresas';
import { LineaBaseContext } from '../context/LineaBaseContext';
import { MensajeMapa } from './MensajeMapa';
import { numeroBaseDatosAFront } from 'helpers/formateoNumeros';
import { useMapaMatch } from 'hooks/useMapaMatch';
import { RenderCanvasData } from './RenderCanvasData';

export const MapaBase = () => {
  const {
    aplicarFiltros,
    sectoresEconomicos,
    setCargando,
    isCargando,
    coordenadasMapa,
    mostrarMensajeSinDireccion
  } = useContext(LineaBaseContext);
  const { datosMapaEmpresa } = useMapaEmpresas(aplicarFiltros, setCargando);
  const isCargandoMapaEmpresa = isCargando.mapaCargando;
  const isCargandoSectores = isCargando.sectoresEconomicos;
  const [showSimbologia, setShowSimbologia] = useState(false);
  const [inner, setInner] = useState(coordenadasMapa);
  const { titlesurl, attribution } = useMapaMatch();
  const map = useRef(null);

  useEffect(() => {
    if(coordenadasMapa){
      setInner(coordenadasMapa);
      if (map != null && map.current != null) {
        map.current.flyTo(coordenadasMapa, 14);
      }
    }
  }, [coordenadasMapa]);

  if (isCargandoMapaEmpresa || isCargandoSectores) {
    return <MapaBaseSkeleton />;
  }

  return (
    <MapContainer
      className="h-full w-full relative min-h-[600px] lg:min-h-full shadow-md"
      center={inner}
      ref={map}
      zoom="12"
      bounds={null}
      scrollWheelZoom={false}
      preferCanvas={true}
      renderer={Leaflet.canvas()}
    >
      <div className="py-4 px-5 bg-white absolute left-0 right-0 top-0 z-[1000] cursor-auto">
        <Heading type="h4" className="text-uv-primary-0 m-0">
          Sectores económicos de empresas
        </Heading>
      </div>

      <TileLayer url={titlesurl} attribution={attribution} />

      {mostrarMensajeSinDireccion &&
        <div className="absolute w-full z-[1000] p-3 top-[45%]">
          <div className="shadow-md max-w-md mx-auto rounded-3xl p-4 bg-white">
            <div className="relative flex justify-center items-center">
              <Text size="M"><LocationOff className='text-red-600' /> <b>No hay dirección</b> para la empresa seleccionada</Text>
            </div>
          </div>
        </div>
      }

      <div className="absolute w-full z-[1000] p-3 top-14">
        <MensajeMapa>
          <Text size="S">
            Se {datosMapaEmpresa.length === 1 ? 'ha' : 'han'} graficado{' '}
            <b>{numeroBaseDatosAFront(datosMapaEmpresa.length)}</b>{' '}
            {datosMapaEmpresa.length === 1 ? 'empresa' : 'empresas'} debido a
            datos incompletos o a que las direcciones no pudieron ser
            encontradas.
          </Text>
        </MensajeMapa>
      </div>


      {showSimbologia === true ? (
        <div className="bg-white absolute bottom-0 left-0 w-full p-5 z-[1000]">
          <div>
            <div className="relative">
              <span className="mb-8 block font-secondary text-xs text-neutral-60 tracking-wider font-medium">
                Simbología Sector Económico
              </span>
              <div className="absolute -right-1 -top-1.5 z-10">
                <IconButtonComponent
                  onClick={() => setShowSimbologia(false)}
                  size="small"
                  type="neutral"
                >
                  <Close />
                </IconButtonComponent>
              </div>
            </div>
            <ul className="columns-2">
              {sectoresEconomicos.map(sector => {
                return (
                  <li
                    key={sector.codigoSectorEconomico}
                    className="relative mb-1.5 pl-6 break-inside-avoid-column"
                  >
                    <div
                      className="w-3 h-3 rounded-full block mr-2 absolute left-0 top-1"
                      style={{ backgroundColor: sector.codigoHex }}
                    ></div>
                    <Text className="text-xs">
                      {sector.nombreSectorEconomico}
                    </Text>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      ) : (
        <button
          onClick={() => setShowSimbologia(true)}
          className="absolute right-2 bottom-2 rounded bg-white shadow-md z-[1000] h-14 w-14  justify-center items-center flex"
        >
          <MoreVert className="text-uv-secondary-0" />
        </button>
      )}

      <RenderCanvasData
        datosMapaEmpresa={datosMapaEmpresa}
        sectoresEconomicos={sectoresEconomicos}
      />
    </MapContainer>
  );
};
