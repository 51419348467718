import React from 'react';
import { Info } from '@mui/icons-material';

const Aviso = ({ className, children }) => {
  return (
    <div className={className + ' info-message info-mesasge-icon'}>
      <Info />
      {children}
    </div>
  );
};
export default Aviso;
