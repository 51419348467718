import * as yup from 'yup';

const SchemaIndentificarRSD = yup.object().shape({

  tieneSubTipoResiduo: yup.boolean(),

  nombreLER: yup
    .object()
    .typeError('Seleccione nombre LER')
    .required('Este campo es obligatorio'),

  nombreSubLER: yup
    .object()
    .typeError('Seleccione Subnombre LER')
    .required('Este campo es obligatorio'),

  nombreSubSubLER: yup
    .object()
    .typeError('Seleccione Subnombre LER')
    .required('Este campo es obligatorio'),

  tipoResiduo: yup
    .object()
    .typeError('Seleccione el residuo')
    .required('Este campo es obligatorio'),

  subTipoResiduo: yup
    .object()
    .typeError('Seleccione un subtipo de residuo')
    .required('Este campo es obligatorio'),

  tipoRecurso: yup.array().when(['tieneTipoRecursos', 'otroTipoRecurso'], {
    is: (tieneTipoRecursos, otroTipoRecurso) => tieneTipoRecursos && !otroTipoRecurso.length,
    then: () => {
      return yup
        .array()
        .of(yup.object())
        .min(1, 'Debe seleccionar al menos un recurso')
        .required('Este campo es obligatorio');
    }
  }),

  otroTipoRecurso: yup.array().when('tieneTipoRecursos', {
    is: false,
    then: () => {
      return yup
        .array()
        .of(yup.object())
        .min(1, 'Debe seleccionar al menos un recurso distinto')
        .required('Este campo es obligatorio');
    }
  }),

  _otraMateriaPrima: yup.string().min(3, 'El nombre es muy corto'),

  descripcionAdicional: yup
    .string()
    .min(3, 'El nombre es muy corto')
    // .max(100,'El nombre no puede ser tan largo'),
    .required('Este campo es obligatorio'),
});
export { SchemaIndentificarRSD };
