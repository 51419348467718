import Heading from 'components/design-system/Heading/Heading';
import imgHomeUser from 'assets/images/home/grafica-home-user.svg';
import { CuadroAdminResumen } from './componentes/CuadroAdminResumen';
import {
  Domain          as DomainIcon,
  FindReplace     as FindReplaceIcon,
  Handshake       as HandshakeIcon,
  InvertColors    as InvertColorsIcon
} from '@mui/icons-material';

export const HomeAnalistaTerritorial = ({ logeduser, isLoading, data }) => {
  const resumen = [
    {
      accesor: 'numeroEmpresasAT',
      titulo: 'EMPRESAS',
      icono: <DomainIcon className="w-8 h-8" />,
      descripcion: (
        <>
          Empresas 
          <br />
          <b>de análisis territorial</b>
        </>
      )
    },
    {
      accesor: 'numeroResiduosAT',
      titulo: 'RESIDUOS',
      icono: <InvertColorsIcon className="w-8 h-8" />,
      descripcion: (
        <>
          Residuos 
          <br />
          <b>de análisis territorial</b>
        </>
      )
    },
    {
      accesor: 'numeroPotencialSinergia',
      titulo: 'POTENCIAL DE SINERGIA',
      icono: <FindReplaceIcon className="w-8 h-8" />,
      descripcion: (
        <>
          Potencial 
          <br />
          <b>de sinergia de empresas</b>
        </>
      )
    },
    {
      accesor: 'numeroTransacciones',
      titulo: 'TRANSACCIONES',
      icono: <HandshakeIcon className="w-8 h-8" />,
      descripcion: (
        <>
          Transacciones
          <br />
          <b>concretadas</b>
        </>
      )
    },
  ];

  return (
    <>
      <div
        className="hidden md:block h-56 bg-no-repeat bg-[center_bottom_-24px] relative"
        style={{ backgroundImage: `url(${imgHomeUser})` }}
      >
        <Heading
          className="!text-uv-primary-0 absolute bottom-2 left-0 capitalize"
          type="H1"
        >
          Hola {logeduser.nombreUsuario.toLowerCase()}
        </Heading>
      </div>

      <div className="block md:hidden">
        <Heading className="!text-uv-primary-0 capitalize pb-4" type="H1">
          Hola {logeduser.nombreUsuario.toLowerCase()}
        </Heading>
      </div>

      <CuadroAdminResumen
        resumenCuenta={data}
        isLoading={isLoading}
        data={resumen}
      />
    </>
  );
};
