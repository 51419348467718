import { Divider } from '@mui/material';
import Heading from 'components/design-system/Heading/Heading';
import { RoleComponent } from 'components/design-system/Role/Role';
import Text from 'components/design-system/Text/Text';

/**
 * @name DescripcionDemandante
 * Despliega la descripción de la oferta de residuo para un publicante con rol demandante.
 */
export const DescripcionDemandante = ({ residuo }) => {
  return (
    <div>
      <Divider className="my-3" />
      <RoleComponent rol={residuo.rol.nombreRol} tipo="etiqueta" />
      
      <div className="pl-3">
        <Heading type="h5">{residuo.nombreResiduo}</Heading>
        <div className="grid grid-cols-12 gap-2 items-baseline">
          {residuo.descripcionAdicional && (
            <>
              <div className="col-span-4">
                <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-normal pt-0.5">
                  DESCRIPCION ADICIONAL
                </span>
              </div>
              <div className="col-span-8">
                <Text>{residuo.descripcionAdicional}</Text>
              </div>
            </>
          )}

          <div className="col-span-4">
            <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-normal pt-0.5">
              TRATAMIENTO
            </span>
          </div>
          <div className="col-span-8">
            {residuo.tipoResiduo.tipoTratamiento.map((tratamiento, index) => (
              <Text key={index} className="mb-2 flex">
                <span className="block rounded-full w-1 h-1 bg-uv-secondary-0 mt-2 mr-2"></span>
                {tratamiento.nombreTipoTratamiento}
              </Text>
            ))}
          </div>

          <div className="col-span-4">
            <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-normal pt-0.5">
              TIPO RESIDUO
            </span>
          </div>
          <div className="col-span-8">
            <Text>{residuo?.tipoResiduo?.nombreTipo || ''}</Text>
          </div>

        </div>
      </div>
    </div>
  );
};
