import { DICTONARY } from 'const/Dictonary';
import { numeroFrontABaseDatos } from 'helpers/formateoNumeros';
import { useEffect, useState } from 'react';
import empresasService from 'services/empresas';
import storeMessages from 'states/alertMessages/store';
import store from 'states/store';
import { refrescaUsuario } from 'views/Business/hooks';

export const useEditarIndicador = (codigoSucursal, codigoEmpresa) => {
  const [isLoading, setIsLoading] = useState(true);
  const [sucursal, setSucursal] = useState();
  const { logeduser } = store.loguedUser;

  /**
   * @name cargarSucursal
   * Función para cargar la sucursal que se está desplegando, en el caso de que el código no viene se asume que es una nueva sucursal.
   */
  const cargarSucursal = async () => {
    setIsLoading(true);
    if (codigoSucursal !== 'nuevo') {
      try {
        const responseSucursal = await empresasService.getSucursal(codigoSucursal);
        const error = responseSucursal.data.error;
        if(error) throw new Error("ERROR_CREAR_REUNION");

        setSucursal(responseSucursal.data);
      } catch (error) {
        console.log('Error al cargar la sucursal', error);
        setSucursal(null);
      }
    }
    setIsLoading(false);
  };

  const formularioInicial = sucursalSeleccionada => {
    // console.log(sucursalSeleccionada);
    const roles = sucursalSeleccionada ? sucursalSeleccionada.empresa.roles.map(rol => rol.nombreRol) : [DICTONARY.ROL.GENERADOR.NOMBRE];
    console.log(roles);
    // TODO: ojo con los valores ya que si cambian los nombres de los roles se debe cambiar aquí
    const initialValue = {
      base: {
        role: roles,
        trabajadoresMasculino: sucursalSeleccionada?.trabajadoresMasculino,
        trabajadoresFemenino: sucursalSeleccionada?.trabajadoresFemenino,
        consumoEnergetico: sucursalSeleccionada?.consumoEnergetico,
        consumoCombustible: sucursalSeleccionada?.consumoCombustible 
      },
      generador: {
        residuosGenerados: sucursalSeleccionada?.residuosGenerados,
        residuosProyectados: sucursalSeleccionada?.residuosProyectados,
        procesoGestion: sucursalSeleccionada?.trabajadoresMasGestion
          ? true
          : false,
        trabajadoresMasGestion: sucursalSeleccionada?.trabajadoresMasGestion || '',
        trabajadoresFemGestion: sucursalSeleccionada?.trabajadoresFemGestion || '',
        totalHH: sucursalSeleccionada?.totalHH || '',
        remuneracionesPagadas: sucursalSeleccionada?.remuneracionesPagadas || ''
      },
      receptor: {
        residuosCompradosRecibidos: sucursalSeleccionada?.residuosCompradosRecibidos,
        procesoGestion: sucursalSeleccionada?.trabajadoresMasGestion
          ? true
          : false,
        trabajadoresMasGestion: sucursalSeleccionada?.trabajadoresMasGestion || '',
        trabajadoresFemGestion: sucursalSeleccionada?.trabajadoresFemGestion || '',
        totalHH: sucursalSeleccionada?.totalHH || '',
        remuneracionesPagadas: sucursalSeleccionada?.remuneracionesPagadas || ''
      },
      transportista: {
        capacidadProyectada: sucursalSeleccionada?.capacidadProyectada,
        residuosTransportados: sucursalSeleccionada?.residuosTransportados,
        kilometrosRecorridos: sucursalSeleccionada?.kilometrosRecorridos 
      },
      gestor: {
        residuosGestionar: sucursalSeleccionada?.residuosGestionar,
        residuosGestionados: sucursalSeleccionada?.residuosGestionados 
      },
      asesor: {
        residuosAsesorados: sucursalSeleccionada?.residuosAsesorados 
      }
    };

    const externalObj = {};
    roles.map(rol => {
      let data = initialValue[rol];
      Object.keys(data).forEach(key => {
        externalObj[key] = data[key];
      });
      return data;
    });

    return {
      ...initialValue.base,
      ...externalObj
    };
  };

  const onSubmit = async (codigoSucursal, values) => {
    const cleanValues = { ...values };
    delete cleanValues.role;

    Object.keys(cleanValues).forEach(key => {
      if (typeof cleanValues[key] === 'string') {
        const numeroFormateado = numeroFrontABaseDatos(cleanValues[key]);
        cleanValues[key] =  numeroFormateado;
      }
    });

    try {
      const response = await empresasService.actualizarIndicadores(
        codigoSucursal,
        cleanValues
      );
      if(codigoSucursal === logeduser?.sucursal?.codigoSucursal){
        refrescaUsuario()
      }

      const error = response.data.error;
      if(error) throw new Error("ERROR_AL_GUARDAR_EL_INDICADOR");

      storeMessages.message.setMessage({
        mensaje: 'Se han guardado los cambios correctamente',
        tipo: 'exito'
      });
    } catch (error) {
      console.log(error);
      storeMessages.message.setMessage({
        mensaje:
          'Ha ocurrido un error al guardar los datos vuelve a intentarlo. Si el problema persiste comunicate con el administrador del sitio',
        tipo: 'error'
      });
    }
  };

  useEffect(() => {
    cargarSucursal();
  }, []);

  return {
    isLoading,
    sucursal,
    formularioInicial: formularioInicial,
    onSubmit: onSubmit
  };
};
