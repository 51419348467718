import storeMessages from 'states/alertMessages/store';
import { DICTONARY } from 'const/Dictonary';
// Services
import usuariosService from 'services/usuarios';

export const verificaCorreoRegistrado = async(email) => {
let resp = false; 
  await usuariosService.compruebaCorreoRegistrado({
    correoElectronico: email.trim()
  }, DICTONARY.INTERCEPTOR.DESACTIVADO
  )
    .then(response => {
      const { data } = response;
      if (data === true) {

        storeMessages.message.setMessage({
          mensaje: 'El correo ingresado ya está registrado',
          tipo: 'warning'
        });
        resp = true;
      }else{
        storeMessages.message.setMessage({
          mensaje: 'El correo ingresado está disponible',
          tipo: 'exito'
        });
        resp = false;
      }
    })
    .catch(error => {
      console.log('error :>> ', error);
    })
    .finally(() => {
      // console.log('finally');
    });

    return resp;
};