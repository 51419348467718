import { useState, useContext } from 'react';
// Context
import { AuthContext } from 'context';
// Constants
import { DICTONARY } from 'const/Dictonary';
// Services
import recursosServices from 'services/recursos';
import empresasServices from 'services/empresas';
// Utils
import { diccionarioVista } from '../utils/utils';
import { AlertaError, AlertaSinRegistros, ValidacionConexion, } from 'utils/Alertas';

// store
import store from 'states/store';
import { toLocaleStringPLus } from 'utils';

export const useServicesRecursosAdquiridos = () => {

  const { logged } = useContext(AuthContext);

  const { isSuperAdmin } = store?.loguedUser?.perfiles;

  const sucursal = store?.loguedUser?.logeduser?.sucursal;

  // States Campos Listado de Recursos Adquiridos
  const [recursosAdquiridos, setRecursosAdquiridos] = useState([]);
  const [isLoadingLista, setIsLoadingLista] = useState(true);

  // States Paginacion
  const [rowCount, setRowCount] = useState(1);
  const [pagination, setPagination] = useState(DICTONARY.PAGINACION.DEFAULT);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);

  // States Campos de Sucursales
  const [sucursales, setSucursales] = useState([]);
  const [isLoadingSucursales, setIsLoadingSucursales] = useState(true);

  // States Campos de Tipos de Recursos Adquiridos
  const [tipoRecursosAdquiridos, setTipoRecursosAdquiridos] = useState([]);
  const [isLoadingTipoRecursosAdquiridos, setIsLoadingTipoRecursosAdquiridos] = useState(true);


  // States Campos de Levantamiento de datos
  const [levantamiento, setLevantamiento] = useState([]);
  const [isLoadingLevantamiento, setIsLoadingLevantamiento] = useState(true);

  // States Campos de Unidad de Medida
  const [unidadMedida, setUnidadMedida] = useState([]);
  const [isLoadingUnidadMedida, setIsLoadingUnidadMedida] = useState(true);

  // States Campos de RUT empresas SII
  const [empresasSII, setEmpresasSII] = useState([]);
  const [isLoadingEmpresasSII, setIsLoadingEmpresasSII] = useState(true);

  // States Tipo de Transporte
  const [tipoTransporte, setTipoTransporte] = useState([]);
  const [isLoadingTipoTransporte, setIsLoadingTipoTransporte] = useState(true);

  // States Campos de Envase y Embalaje
  const [tipoEmbalaje, setTipoEmbalaje] = useState([]);
  const [isLoadingTipoEmbalaje, setIsLoadingTipoEmbalaje] = useState(true);



  const ObtieneListaRecursosAdquiridos = async () => {

    setIsLoadingLista(true);

    if (!logged) {
      ValidacionConexion()
      return;
    }

    const query = {};

    let nombreSucursal = null;
    let nombreTipoRecursoAdquirido = null;

    if (columnFilters) {
      nombreSucursal = columnFilters.find(filtro =>
        filtro.id.includes('nombreSucursal')
      );
      nombreTipoRecursoAdquirido = columnFilters.find(filtro =>
        filtro.id.includes('nombreTipoRecursoAdquirido')
      );
    }

    if (nombreSucursal) {
      query.nombreSucursal = nombreSucursal.value;
    }

    if (nombreTipoRecursoAdquirido) {
      query.tipoRecursoAdquirido = nombreTipoRecursoAdquirido.value;
    }

    if (pagination) {
      query.numPagina = pagination.pageIndex;
      query.totalPagina = pagination.pageSize;
    }

    if (!isSuperAdmin) {
      query.codigoEmpresa = sucursal?.empresa?.codigoEmpresa;
    }

    try {
      const response = await recursosServices.obtenerListaRecursosAdquiridos(query);
      if (response.status === 200) {
        const { content, totalElements, empty } = response.data;
        if (empty) {
          console.log(`No hay ${diccionarioVista.nombreSingular} disponibles ::>`);
          setRecursosAdquiridos([]);
          throw new Error('SIN_REGISTROS');

        } else if (Array.isArray(content)) {
          setRecursosAdquiridos(
            content.map(recursosAdquirido => ({
              ...recursosAdquirido,
              cantidadUnidad: recursosAdquirido.cantidad
                ? `${toLocaleStringPLus(recursosAdquirido.cantidad)} ${recursosAdquirido.unidadMedida.nombreUnidadMedida}`
                : 'N/A',
            }))
          );
          setRowCount(totalElements);
        }
      }
    } catch (err) {
      console.log('err :>> ', err);
      setRecursosAdquiridos([]);
      setRowCount(0);
      if (err.message === 'SIN_REGISTROS') {
        AlertaSinRegistros();
      }
      if (
        !err.response ||
        !err.response.data ||
        !err.response.data.codigoError
      ) {
        AlertaError({
          mensaje: 'Ha ocurrido un error al tratar de obtener la lista de recursos.'
        });
      }
    } finally {
      setIsLoadingLista(false);
    }


  }

  const ObtieneSucursales = async () => {

    setIsLoadingSucursales(true);

    if (!logged) {
      ValidacionConexion()
      return;
    }

    const query = {};

    if (!isSuperAdmin) {
      query.codigoEmpresa = sucursal?.empresa?.codigoEmpresa;
    }

    query.totalPagina = DICTONARY.PAGINACION.ALL_DATA;

    try {
      const response = await empresasServices.obtieneSucursales(query);
      if (response.status === 200) {
        const { content, totalElements, empty } = response.data;
        if (empty) {
          console.log('No hay sucursales disponibles ::>');
          setSucursales([]);
          throw new Error('SIN_REGISTROS');

        } else if (Array.isArray(content)) {
          setSucursales(content);
        }
      }
    } catch (err) {
      console.log('err :>> ', err);
      setSucursales([]);
      if (err.message === 'SIN_REGISTROS') {
        AlertaSinRegistros();
      }
      if (
        !err.response ||
        !err.response.data ||
        !err.response.data.codigoError
      ) {
        AlertaError({
          mensaje: 'Ha ocurrido un error al tratar de obtener la lista de sucursales.'
        });
      }
    } finally {
      setIsLoadingSucursales(false);
    }
  }

  const ObtieneTiposRecursosAdquiridos = async () => {

    setIsLoadingTipoRecursosAdquiridos(true);

    if (!logged) {
      ValidacionConexion()
      return;
    }

    try {
      const response = await recursosServices.obtieneTiposRecursoAdquirido();
      if (response.status === 200) {
        const { content, totalElements, empty } = response.data;
        if (empty) {
          console.log('No hay tipos de recursos adquiridos disponibles ::>');
          setTipoRecursosAdquiridos([]);
          throw new Error('SIN_REGISTROS');

        } else if (Array.isArray(content)) {
          setTipoRecursosAdquiridos(content);
        }
      }
    } catch (err) {
      console.log('err :>> ', err);
      setTipoRecursosAdquiridos([]);
      if (err.message === 'SIN_REGISTROS') {
        AlertaSinRegistros();
      }
      if (
        !err.response ||
        !err.response.data ||
        !err.response.data.codigoError
      ) {
        AlertaError({
          mensaje: 'Ha ocurrido un error al tratar de obtener la lista de tipos de recursos adquiridos.'
        });
      }
    } finally {
      setIsLoadingTipoRecursosAdquiridos(false);
    }


  }

  const ObtieneLevantamiento = async () => {

    setIsLoadingLevantamiento(true);

    if (!logged) {
      ValidacionConexion()
      return;
    }

    try {
      const response = await recursosServices.obtieneLevantamientoDatos();
      if (response.status === 200) {
        const { content, totalElements, empty } = response.data;
        if (empty) {
          console.log('No hay levantamientos de datos disponibles ::>');
          setLevantamiento([]);
          throw new Error('SIN_REGISTROS');

        } else if (Array.isArray(content)) {
          setLevantamiento(content);
        }
      }
    } catch (err) {
      console.log('err :>> ', err);
      setLevantamiento([]);
      if (err.message === 'SIN_REGISTROS') {
        AlertaSinRegistros();
      }
      if (
        !err.response ||
        !err.response.data ||
        !err.response.data.codigoError
      ) {
        AlertaError({
          mensaje: 'Ha ocurrido un error al tratar de obtener la lista de levantamientos de datos.'
        });
      }
    } finally {
      setIsLoadingLevantamiento(false);
    }
  }

  const ObtieneUnidadMedida = async () => {

    setIsLoadingUnidadMedida(true);

    if (!logged) {
      ValidacionConexion()
      return;
    }

    try {
      const response = await recursosServices.obtieneUnidadMedida();
      if (response.status === 200) {
        const { content, totalElements, empty } = response.data;
        if (empty) {
          console.log('No hay unidades de medida disponibles ::>');
          setUnidadMedida([]);
          throw new Error('SIN_REGISTROS');

        } else if (Array.isArray(content)) {
          setUnidadMedida(content);

        }
      }
    } catch (err) {
      console.log('err :>> ', err);
      setUnidadMedida([]);
      if (err.message === 'SIN_REGISTROS') {
        AlertaSinRegistros();
      }
      if (
        !err.response ||
        !err.response.data ||
        !err.response.data.codigoError
      ) {

        AlertaError({
          mensaje: 'Ha ocurrido un error al tratar de obtener la lista de unidades de medida.'
        });
      }
    } finally {
      setIsLoadingUnidadMedida(false);
    }
  }

  const ObtieneEmpresasSII = async (rutEmpresa) => {
    setIsLoadingEmpresasSII(true);

    if (!logged) {
      ValidacionConexion()
      return;
    }

    try {
      const params = { rutEmpresa: rutEmpresa };
      const response = await empresasServices.datosEmpresasSII(
        params,
        DICTONARY.INTERCEPTOR.DESACTIVADO
      );
      // console.log(response);
      if (response.status === 200) {
        const { data } = response;
        if (data.error) {
          setEmpresasSII([]);
          throw new Error('SIN_REGISTROS');
        }
        if (data) {
          setEmpresasSII(data);
        } else {
          console.log('Sin información de empresas SII ::>');
          setEmpresasSII([]);
        }

      }
    } catch (err) {
      console.log('err :>> ', err);
      setEmpresasSII([]);
      if (err.message === 'SIN_REGISTROS') {
        AlertaSinRegistros();
      }
      else if (
        !err.response ||
        !err.response.data ||
        !err.response.data.codigoError
      ) {
        AlertaError({
          mensaje: 'Ha ocurrido un error al tratar de obtener la información de la empresa.'
        });
      }
    } finally {
      setIsLoadingEmpresasSII(false);
    }
  }

  const ObtieneTipoTransporte = async () => {

    setIsLoadingTipoTransporte(true);

    if (!logged) {
      ValidacionConexion()
      return;
    }

    try {
      const response = await empresasServices.obtenerTipoTransaporte();
      if (response.status === 200) {
        const { content, totalElements, empty } = response.data;
        if (empty) {
          console.log('No hay tipo de transporte disponibles ::>');
          setTipoTransporte([]);
          throw new Error('SIN_REGISTROS');

        } else if (Array.isArray(content)) {
          setTipoTransporte(content);
        }
      }
    }
    catch (err) {
      console.log('err :>> ', err);
      setTipoTransporte([]);
      if (err.message === 'SIN_REGISTROS') {
        AlertaSinRegistros();
      }
      if (
        !err.response ||
        !err.response.data ||
        !err.response.data.codigoError
      ) {
        AlertaError({
          mensaje: 'Ha ocurrido un error al tratar de obtener la lista de tipos de transporte.'
        });
      }
    } finally {
      setIsLoadingTipoTransporte(false);
    }
  }

  const ObtieneEnvaseEmbalaje = async () => {

    setIsLoadingTipoEmbalaje(true);

    if (!logged) {
      ValidacionConexion()
      return;
    }

    try {

      const response = await recursosServices.obtieneTiposEmbalaje();

      if (response.status === 200) {
        const { content, totalElements, empty } = response.data;
        if (empty) {
          console.log('No hay tipo de embalajes disponibles ::>');
          setTipoEmbalaje([]);
          throw new Error('SIN_REGISTROS');

        } else if (Array.isArray(content)) {
          setTipoEmbalaje(content);

        }
      }
    }
    catch (err) {
      console.log('err :>> ', err);
      setTipoEmbalaje([]);
      if (err.message === 'SIN_REGISTROS') {
        AlertaSinRegistros();
      }
      if (
        !err.response ||
        !err.response.data ||
        !err.response.data.codigoError
      ) {
        AlertaError({
          mensaje: 'Ha ocurrido un error al tratar de obtener la lista de tipos de embalajes.'
        });
      }
    } finally {
      setIsLoadingTipoEmbalaje(false);
    }

  }


  return {
    // Funciones
    ObtieneEmpresasSII,
    ObtieneEnvaseEmbalaje,
    ObtieneLevantamiento,
    ObtieneListaRecursosAdquiridos,
    ObtieneSucursales,
    ObtieneTipoTransporte,
    ObtieneTiposRecursosAdquiridos,
    ObtieneUnidadMedida,
    // States Listado de Recursos Adquiridos
    columnFilters,
    globalFilter,
    isLoadingLista,
    pagination,
    recursosAdquiridos,
    rowCount,
    setColumnFilters,
    setGlobalFilter,
    setIsLoadingLista,
    setPagination,
    setRecursosAdquiridos,
    setRowCount,

    // States Tipos de Recursos Adquiridos
    isLoadingTipoRecursosAdquiridos,
    setIsLoadingTipoRecursosAdquiridos,
    setTipoRecursosAdquiridos,
    tipoRecursosAdquiridos,

    // States Sucursales
    sucursales,
    setSucursales,
    isLoadingSucursales,
    setIsLoadingSucursales,

    // States Levantamiento de datos
    levantamiento,
    setLevantamiento,
    isLoadingLevantamiento,
    setIsLoadingLevantamiento,

    // States Unidad de Medida
    unidadMedida,
    setUnidadMedida,
    isLoadingUnidadMedida,
    setIsLoadingUnidadMedida,

    // States Rut Empresas SII
    empresasSII,
    setEmpresasSII,
    isLoadingEmpresasSII,
    setIsLoadingEmpresasSII,

    // States Tipo de Transporte
    tipoTransporte,
    setTipoTransporte,
    isLoadingTipoTransporte,
    setIsLoadingTipoTransporte,

    // States Envase y Embalaje
    tipoEmbalaje,
    setTipoEmbalaje,
    isLoadingTipoEmbalaje,
    setIsLoadingTipoEmbalaje,

  }
}
