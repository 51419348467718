import { FiletypeComponent } from '../FileType/Filetype';
import IconButtonComponent from '../IconButton/IconButton';
import Close from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import ZoomIn from '@mui/icons-material/ZoomIn';
import Text from '../Text/Text';
import styles from './filethumb.module.css';
import { FileDownload } from '@mui/icons-material';

/**
 *
 * @param file {object} { name: 'hi', preview: 'URL', type: 'image/jpg'  }
 * @param onRemove función que se ejecuta y despliega botón de eliminado
 * @param onEdit función que se ejecuta y despliega botón de edición
 * @param onPreview función que se ejecuta y despliega botón de ver más
 * @returns
 */
export const FilethumbComponent = ({
  file,
  enablePreviewFile = true,
  onRemove,
  onEdit,
  onPreview,
  onDownload,
  children,
  disabled = false,
  ...rest
}) => {
  if (!file.type) return null;
  return (
    <div className="col-span-1">
      <div className={styles.thumbnail}>
        {file.type.includes('pdf') ? (
          <div className={styles.thumbnailInner}>
                {onRemove && (
                  <div className="absolute right-1 top-1 shadow-md rounded-full bg-white z-20">
                    <IconButtonComponent
                      size="small"
                      disabled={disabled}
                      type="error"
                      onClick={() => onRemove(file)}
                    >
                      <Close />
                    </IconButtonComponent>
                  </div>
                )}
                {onEdit && (
                  <IconButtonComponent
                    disabled={disabled}
                    size="small"
                    onClick={() => onEdit(file)}
                  >
                    <Edit />
                  </IconButtonComponent>
                )}

                {onPreview && (
                  <IconButtonComponent
                    disabled={disabled}
                    size="small"
                    onClick={() => onPreview(file)}
                  >
                    <ZoomIn />
                  </IconButtonComponent>
                )}

                {onDownload && (
                 <div className="flex items-center mx-auto my-auto shadow-md rounded-full bg-black z-20">
                  <IconButtonComponent
                    disabled={disabled}
                    size="small"
                    onClick={() => onDownload(file)}
                  >
                    <FileDownload />
                  </IconButtonComponent>
                  </div>
                )}
                {enablePreviewFile && (
                  <object
                    data={file.preview}
                    type="application/pdf"
                    title="preview"
                    className="w-40 h-44 absolute left-0 top-0 z-10"
                  >

                    
                  </object>
            )}
          </div>
        ) : (
          <div
            className={styles.thumbnailInner}
            style={{ backgroundImage: `url(${file.preview})` }}
          >
            {onRemove && (
              <div className="absolute right-1 top-1 shadow-md rounded-full bg-white z-20">
                <IconButtonComponent
                  size="small"
                  disabled={disabled}
                  type="error"
                  onClick={() => onRemove(file)}
                >
                  <Close />
                </IconButtonComponent>
              </div>
            )}

            <div className="inline-block shadow-md rounded-full bg-neutral-50 z-20">
              {onEdit && (
                <IconButtonComponent
                  disabled={disabled}
                  size="small"
                  onClick={() => onEdit(file)}
                >
                  <Edit />
                </IconButtonComponent>
              )}

              {onPreview && (
                <IconButtonComponent
                  disabled={disabled}
                  size="small"
                  onClick={() => onPreview(file)}
                >
                  <ZoomIn />
                </IconButtonComponent>
              )}

              {onDownload && (
                <IconButtonComponent
                  disabled={disabled}
                  size="small"
                  onClick={() => onDownload(file)}
                >
                  <FileDownload />
                </IconButtonComponent>
              )}
            </div>
          </div>
        )}

        <div className="flex">
          <FiletypeComponent type={file.type} />
          <Text className="truncate ...">{file.name}</Text>
        </div>
      </div>
    </div>
  );
};
