import React from 'react';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import { makeStyles } from '@mui/styles';
import store from '../../../../../states/chatNotification/store';
import useChatNotification from '../../../../../states/chatNotification/use.chatNotification';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    },
    display: 'inline',
    marginRight: '10px',
    cursor: 'pointer'
  }
}));

const NotificationArea = ({ history, match, isSuperAdmin }) => {
  store.notification = useChatNotification();

  const classes = useStyles();

  React.useEffect(() => {
    store.notification.setMatch(match)
  },[match])

  const handelClick = event => {
    const last = store.notification.getLast();
    if (last) {
      history.push('/chat/' + last.room);
    } else {
      history.push('/chat/0');
    }
  };

  const notRead = store.notification.getNumberNotRead();

  if(isSuperAdmin){
    return;
  }

  return (
    <div className={classes.root} onClick={handelClick}>
      <Badge
        badgeContent={notRead > 9 ? '9+' : notRead}
        color="primary"
      >
        <MailIcon />
      </Badge>
    </div>
  );
};

export default NotificationArea;
