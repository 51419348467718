import { useMemo } from 'react';
import store from 'states/store';
import moment from 'moment';
import Text from 'components/design-system/Text/Text';

export const useColumnTablesListadoResiduosDeclarados = () => {
  const { isSuperAdmin, isAdmin, isFuncionario } = store.loguedUser.perfiles;
  const { isDemandante, isOferente } = store.loguedUser.tipos;

  const columnTables = useMemo(
    () => [
      {
        header: 'Código',
        enableGlobalFilter: false,
        accessorKey: 'codigoResiduoDeclarado',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>,
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 20, 
        // maxSize: 20,
        // minSize: 10,
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
        },
      },
      {
        header: 'Sucursal de origen',
        accessorKey: 'sucursal.nombreSucursal',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>,
        size: 230,
        // maxSize: 220,
        // minSize: 100,
      },
      {
        header: 'Residuo',
        accessorKey: 'tipoResiduo.nombreTipo',
        Cell: ({ renderedCellValue }) =><Text>{renderedCellValue ? renderedCellValue : '-'}</Text>,
        size: 230,
        // maxSize: 220,
        // minSize: 100,
      },
      {
        header: 'Cantidad',
        enableGlobalFilter: false,
        accessorKey: 'cantidad',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue ? renderedCellValue : '-'}</Text>,
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 30, 
      },
      
      {
        header: 'Fecha de transacción',
        enableGlobalFilter: false,
        accessorKey: 'fechaTransaccion',
        Cell: ({ renderedCellValue }) => (
          <Text>
            {' '}
            {renderedCellValue !== 'N/A'
              ? moment(renderedCellValue).format('DD-MM-YYYY')
              : 'N/A'}
          </Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false
      }
    ],
    []
  );

  return columnTables;
};
