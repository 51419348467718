export const LineaBaseReducer = (state, action) => {
  console.log('LineaBaseReducer', state, action);

  switch (action.type) {
    case 'SET_STATE':
      return {
        ...state,
        ...action.payload
      };

    case 'SET_SECTORES_ECONOMICOS':
      return {
        ...state,
        sectoresEconomicos: action.payload
      };

    case 'SET_APLICAR_FILTROS':
      return {
        ...state,
        aplicarFiltros: action.payload
      };

    case 'SET_CODIGO_EMPRESA_SELECCIONADA':
      return {
        ...state,
        codigoEmpresaSeleccionada: action.payload
      };

    case 'SET_COORDENADAS_MAPA':
      return {
        ...state,
        coordenadasMapa: action.payload
      };

    case 'SET_CARGANDO':
      return {
        ...state,
        isCargando: {
          ...state.isCargando,
          ...action.payload
        }
      };

    default:
      return state;
  }
};
