import React, { useEffect, useState, useContext } from 'react';
import codigosError from './codigosError';
import { AuthContext } from 'context';

const errorMessage = codigoError => {
  const error = codigosError[codigoError];

  return (
    <p>
      Código mensaje: {codigoError}
      <br />
      <b>{error && error.mensaje}</b>
    </p>
  );
};

export default function useAlertMessage() {
  const [message, setMessage] = useState({});
  const { logged } = useContext(AuthContext);

  useEffect(() => {
    const tokenExpired       = localStorage.getItem('tokenExpired');
    const invalidCredentials = localStorage.getItem('invalidCredentials');
  
    if (logged) {
      if (tokenExpired) {
        setMessage({});
        localStorage.removeItem('tokenExpired');
      }
  
      if (invalidCredentials) {
        setMessage({});
        localStorage.removeItem('invalidCredentials');
      }
    }
  }, [logged]);

  const setApiMessage = ({ codigoError, ...props }) => {
    const error = codigosError[codigoError];
    let tipo = 'error';
    if (error && error.codigoHttp) {
      const [cod] = error.codigoHttp.split('-');
      if (cod != '500') {
        tipo = 'warning';
      }
      if (error.codigoError === 'UV200-1') {
        tipo = 'info';
      }
    }

    setMessage({
      mensaje: errorMessage(codigoError),
      tipo: tipo,
      autoHideDuration: 10000,
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
      ...props
    });

  };

  return {
    message,
    setMessage,
    setApiMessage
  };
}
