import React, { useEffect, useState } from 'react';
// Meterial
import { Divider } from '@mui/material';

// Design System
import AlertComponent from 'components/design-system/Alert/Alert';
import Heading from 'components/design-system/Heading/Heading';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import Text from 'components/design-system/Text/Text';

// Services
import serviceEmpresas from 'services/empresas';
import { handleKeyPress } from 'views/Business/utils/handleKeyPress';
import { DICTONARY } from 'const/Dictonary';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import { CargaActividades, CargaRubros } from 'views/Business/hooks';

/**
 * Utilizamos selección de tipo de negocio para aplicar condicionantes
 *
 *
 * Reglas de Negocio : RN
 *
 */

export const BusinessInfo = ({
  values,
  errors,
  touched,
  onChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  setFieldError
}) => {
  const [tipoNegocio, setTipoNegocio] = useState(values.tipoNegocio);

  /**
   * !Data para completar el campo de Región y comuna: SucursalFormulario.js
   */

  // Custom Hooks de llamada a servicios
  const { rubros, loadRubros, isLoadingRubros } = CargaRubros();

  const { actividades, loadActividades, isLoadingActividades } =
    CargaActividades();

  const [cargandoServicioSII, setCargandoServicioSII] = useState(false);

  // Modificaciones para llamar servicio de validación
  const [originalRutValue, setOriginalRutValue] = useState('');
  const [originalNombreValue, setOriginalNombreValue] = useState('');

  // Servicio Datos SII

  useEffect(() => {
    if (values.rubro && touched !== undefined) {
      const codigoCiuRubro = values.rubro.codigoCiuRubro;
      codigoCiuRubro && loadRubros();
      codigoCiuRubro && loadActividades(codigoCiuRubro);
    }
  }, [touched, values.rubro]);

  useEffect(() => {
    if (values.tipoNegocio === 'persona-natural') return;
    const rutEmpresa = values.rutEmpresa;
    if (
      touched.rutEmpresa &&
      tipoNegocio === 'empresa' &&
      !errors.rutEmpresa &&
      rutEmpresa.length >= 9 &&
      // validar si el rut tiene guion en su penultima posicion
      rutEmpresa.charAt(values.rutEmpresa.length - 2) === '-'
    ) {
      // Llama servicio datos empresa SII
      cargaDatosEmpresasSII(rutEmpresa);
    }
  }, [errors.rutEmpresa, tipoNegocio, touched.rutEmpresa, values.rutEmpresa]);

  // Llama servicio datos empresa SII
  const cargaDatosEmpresasSII = async rutEmpresa => {
    if (values.tipoNegocio === 'persona-natural') return;
    setCargandoServicioSII(true);

    setFieldValue('razonSocial', '');
    setFieldValue('nombreEmpresa', '');
    setFieldValue('rubro', {});
    setFieldValue('actividad', {});

    const params = { rutEmpresa: rutEmpresa };
    const response = await serviceEmpresas.datosEmpresasSII(
      params,
      DICTONARY.INTERCEPTOR.DESACTIVADO
    );

    const data = response.data;
    setFieldValue('razonSocial', data.razonSocial);
    setFieldValue('nombreEmpresa', data.razonSocial);

    // LLama servicio rubros
    setFieldValue('actividad', {});
    loadRubros();
    setCargandoServicioSII(false);
  };

  useEffect(() => {}, [actividades, cargandoServicioSII, rubros]);

  useEffect(() => {
    if (values.tipoNegocio === 'persona-natural') return;
    // if si values.actividad es un objeto vacio o null
    if (
      values.actividad &&
      values.rubro &&
      Object.keys(values.rubro).length === 0 &&
      Object.keys(values.actividad).length === 0
    ) {
      setFieldTouched('actividad');
      setFieldTouched('rubro');
      setFieldValue('rubro', '');
      setFieldValue('actividad', '');
    }
  }, [
    setFieldError,
    setFieldTouched,
    setFieldValue,
    values.actividad,
    values.rubro,
    values.tipoNegocio
  ]);

  return (
    <div className="px-4">
      <Heading type="h3">Información del negocio</Heading>

      <div className="grid grid-cols-12 gap-4 pb-6">
        {tipoNegocio === 'empresa' && (
          <>
            <div className="col-span-12 md:col-span-6">
              <InputComponent
                autoComplete={'off'}
                disabled={false}
                fullWidth
                label={<LabelComponent>Rut de la empresa</LabelComponent>}
                name="rutEmpresa"
                onClick={e => {
                  setFieldValue('rutModificado', false);
                }}
                onBlur={e => {
                  if (
                    originalRutValue !== e.target.value &&
                    e.target.value.length >= 9
                  ) {
                    setFieldTouched('rutEmpresa');
                    setFieldValue('rutModificado', true);
                  }
                  setFieldTouched('razonSocial');
                }}
                onChange={e => {
                  setFieldValue('rutModificado', false);
                  onChange(e);
                }}
                onFocus={e => {
                  setOriginalRutValue(e.target.value);
                }}
                value={values.rutEmpresa}
                touched={touched.rutEmpresa}
                estado={
                  errors.rutEmpresa
                    ? {
                        tipo: 'error',
                        mensaje: errors.rutEmpresa
                      }
                    : null
                }
                outerInputProps={{
                  inputProps: {
                    maxLength: 11,
                    onKeyPress: handleKeyPress
                  }
                }}
              />
            </div>

            <div className="col-span-12">
            <AlertComponent>
                <strong>La razón social, rubro y actividad</strong> han sido <strong>obtenidos de forma automática</strong> con la información entregada por Servicio de Impuestos Internos (SII),
                <strong> según su última actualización de noviembre 2022 con datos registrados hasta el 2021.</strong> <br />
                En el caso de que estos datos automáticos no corresponden con la situación actual de tu empresa, por favor registra tu negocio y posterior a esto solicita mediante el botón de Ayuda la actualización adjuntando los datos del SII correctos.
              </AlertComponent>
            </div>

            <div className="col-span-12">
              <InputComponent
                autoComplete={'off'}
                // disabled={
                //   errors.rutEmpresa || cargandoServicioSII ? true : false
                // }
                fullWidth
                label={<LabelComponent>Razón social</LabelComponent>}
                name="razonSocial"
                onBlur={e => {
                  setFieldValue('nombreModificado', false);
                  setFieldValue('rutModificado', false);
                  setFieldTouched('razonSocial');
                  setFieldTouched('nombreEmpresa');
                }}
                touched={touched.razonSocial}
                onChange={e => {
                  setFieldValue('nombreModificado', false);
                  setFieldValue('rutModificado', false);
                  onChange(e);
                }}
                value={values.razonSocial ? values.razonSocial : ''}
                estado={
                  errors.razonSocial
                    ? {
                        tipo: 'error',
                        mensaje: errors.razonSocial
                      }
                    : null
                }
              />
            </div>
            <div className="col-span-12">
              <InputComponent
                autoComplete={'off'}
                // disabled={
                //   errors.rutEmpresa || !values.rutEmpresa || cargandoServicioSII
                //     ? true
                //     : false
                // }
                fullWidth
                label={<LabelComponent>Nombre Empresa</LabelComponent>}
                name="nombreEmpresa"
                onBlur={e => {
                  setFieldValue('nombreModificado', false);
                  setFieldValue('rutModificado', false);
                  setFieldTouched('nombreEmpresa');
                }}
                onChange={e => {
                  setFieldValue('nombreModificado', false);
                  setFieldValue('rutModificado', false);
                  onChange(e);
                }}
                value={values.nombreEmpresa ? values.nombreEmpresa : ''}
                touched={touched.nombreEmpresa ? true : false}
                estado={
                  errors.nombreEmpresa
                    ? {
                        tipo: 'error',
                        mensaje: errors.nombreEmpresa
                      }
                    : null
                }
              />
            </div>

            <div className="col-span-12 lg:col-span-6">
              <AutocompleteComponent
                options={rubros}
                id="rubro"
                name="rubro"
                // disabled={errors.rutEmpresa}
                loading={isLoadingRubros}
                accesor={'nombreRubro'}
                onChange={(ev, selected,reason) => {
                  const data = { name: 'rubro', value: selected };
                  if (reason === 'clear') {
                    data.value = '';
                  }
                  if (data.value) {
                    setFieldValue('nombreModificado', false);
                    setFieldValue('rutModificado', false);

                    setFieldValue('actividad', null);
                    setFieldValue('rubro', data.value);
                    setFieldTouched('actividad');
                  } else {
                    setFieldValue('rubro', '');
                    setFieldValue('actividad', '');
                  }
                }}
                onBlur={e => {
                  setFieldValue('nombreModificado', false);
                  setFieldValue('rutModificado', false);
                  setFieldTouched('rubro');
                }}
                getOptionLabel={option => option.nombreRubro || ''}
                value={values.rubro}
              >
                <InputComponent
                  autoComplete={'off'}
                  touched={touched.rubro}
                  disabled={
                    errors.rutEmpresa || cargandoServicioSII ? true : false
                  }
                  fullWidth
                  label={<LabelComponent>Rubro</LabelComponent>}
                  estado={
                    errors.rubro
                      ? {
                          tipo: 'error',
                          mensaje: errors.rubro
                        }
                      : null
                  }
                />
              </AutocompleteComponent>
            </div>

            <div className="col-span-12 lg:col-span-6">
              <AutocompleteComponent
                id="actividades"
                loading={isLoadingActividades}
                // disabled={errors.rutEmpresa}
                name="actividad"
                accesor={'nombreActividad'}
                options={actividades}
                onChange={(ev, selected, reason) => {
                  const data = { name: 'actividad', value: selected };
                  if (reason === 'clear') {
                    data.value = '';
                  }
                  if (data.value) {
                    setFieldValue('nombreModificado', false);
                    setFieldValue('rutModificado', false);

                    setFieldValue('actividad', data.value);
                  }
                }}
                onBlur={e => {
                  setFieldValue('nombreModificado', false);
                  setFieldValue('rutModificado', false);
                  setFieldTouched('actividad');
                }}
                getOptionLabel={option => option.nombreActividad || ''}
                value={values.actividad}
              >
                <InputComponent
                  autoComplete={'off'}
                  disabled={
                    errors.rutEmpresa ||
                    errors.razonSocial ||
                    errors.nombreEmpresa
                      ? true
                      : false
                  }
                  fullWidth
                  label={<LabelComponent>Actividad</LabelComponent>}
                  touched={touched.actividad}
                  estado={
                    errors.actividad
                      ? {
                          tipo: 'error',
                          mensaje: errors.actividad
                        }
                      : null
                  }
                />
              </AutocompleteComponent>
            </div>
            <div className="col-span-12 mt-2 mb-3">
              <Divider />
            </div>
          </>
        )}
        {tipoNegocio === 'persona-natural' && (
          <>
            <div className="col-span-12">
              <InputComponent
                autoComplete={'off'}
                fullWidth
                label={<LabelComponent>Nombre Empresa</LabelComponent>}
                name="nombrePersona"
                onChange={e => {
                  setFieldValue('nombreModificado', false);
                  setFieldValue('rutModificado', false);
                  onChange(e);
                }}
                onClick={e => {
                  setFieldValue('nombreModificado', false);
                }}
                onBlur={e => {
                  if (originalNombreValue !== e.target.value) {
                    setFieldTouched('nombrePersona');
                    setFieldValue('nombreModificado', true);
                  }
                }}
                onFocus={e => {
                  setOriginalNombreValue(e.target.value);
                }}
                value={values.nombrePersona}
                touched={touched.nombrePersona}
                estado={
                  errors.nombrePersona
                    ? {
                        tipo: 'error',
                        mensaje: errors.nombrePersona
                      }
                    : null
                }
              />
            </div>

            <div className="col-span-12 mt-2 mb-3">
              <Divider />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BusinessInfo;
