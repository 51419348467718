import * as yup from 'yup';
import { SchemaIndentificarRSD } from './SchemaIndentificarRSD';
import { SchemaEspecificacionOferta } from './SchemaEspecificacionOferta';

// Combinar los esquemas
const SchemaMaterialAdjunto = yup.object().shape({
  ...SchemaIndentificarRSD.fields,
  ...SchemaEspecificacionOferta.fields,
});



export { SchemaMaterialAdjunto };
