import { useEffect, useState } from 'react';
import commons from 'services/commons';
import { initFormularioFiltros } from '../utils/initialValues';
import { DICTONARY } from 'const/Dictonary';

/**
 * FUNCIONES PREVIAS UTILIZADAS PARA CALCULO DE DISTANCIA SOLO SE COPIARON DE LA VERSION ANTERIOR.
 */
var rad = function (x) {
  return (x * Math.PI) / 180;
};
/**
 * FUNCIONES PREVIAS UTILIZADAS PARA CALCULO DE DISTANCIA SOLO SE COPIARON DE LA VERSION ANTERIOR.
 */
var getDistance = function (p1, p2) {
  var R = 6378137; // Earth’s mean radius in meter
  var dLat = rad(p2[0] - p1[0]);
  var dLong = rad(p2[1] - p1[1]);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1[0])) *
      Math.cos(rad(p2[0])) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
};

export const useFilterMatch = sucursalUsuario => {
  const [listRubros, setListRubros] = useState([]);
  const [filters, setFilters] = useState(initFormularioFiltros);
  const usuario = sucursalUsuario;
  const roles = DICTONARY.ROLES || [];

  useEffect(() => {
    commons
      .rubros({
        totalPagina: DICTONARY.PAGINACION.ALL_DATA
      })
      .then(result => {
        if (Array.isArray(result.data.content)) {
          const orderData = result.data.content.sort((a, b) =>
            a.codigoCiuRubro > b.codigoCiuRubro ? 1 : -1
          );
          setListRubros(orderData);
        } else {
          setListRubros([]);
        }
      })
      .catch(err => {
        console.error(err);
        setListRubros([]);
      });
  }, []);

  const handleOnChangeRole = (event, value) => {
    const nombreRol = event.target.name;
    if (value) {
      let rolesFiltradoAux = filters.rolesFiltrado;
      rolesFiltradoAux.push(nombreRol);
      setFilters({
        ...filters,
        rolesFiltrado: rolesFiltradoAux
      });
    } else {
      setFilters({
        ...filters,
        rolesFiltrado: filters.rolesFiltrado.filter(item => item !== nombreRol)
      });
    }
  };

  //Maneja los cambios en los inputs
  const handleChange = event => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value
    });
  };

  //Maneja la eliminación de los filtros
  const handlerBorrarFiltros = borrarFiltros => {
    borrarFiltros();
    setFilters({
      empresa: '',
      rubro: { codigoCiuRubro: '', nombreRubro: '' },
      radio: 10,
      limitarRadio: false,
      rolesFiltrado: []
    });
  };

  //Maneja el submit de filtrar
  const handlerFiltrar = (dataset, setState) => {
    try {
      const auxDataset = dataset;
      let { matchResiduos, matchEmpresas, matchAmpliada } = auxDataset;

      const matchResiduosFiltrado = filtrarMatchResiduos(matchResiduos);
      const matchEmpresasFiltrado = filtrarMatchEmpresas(matchEmpresas);
      const matchAmpliadoFiltrado = filtrarMatchAmpliado(matchAmpliada);

      setState({
        matchFiltrado: matchResiduosFiltrado,
        matchEmpresasFiltrado: matchEmpresasFiltrado,
        matchAmpliadaFiltrado: matchAmpliadoFiltrado,
        residuoSeleccionado: null,
        sucursalSeleccionada: null,
        sucursalATSeleccionada: null,
        empresaSeleccionada: null
      });
    } catch (error) {
      console.log('Ha ocurrido un error en el filtro de resultados');
    }
  };

  const filtrarMatchResiduos = matchResiduos => {
    let { sucursales, residuos } = matchResiduos;

    //SUCURSALES.
    //Filtrar nombre empresa.
    if (filters.empresa.trim().length > 0) {
      const nombreEmpresa = filters.empresa.toLowerCase();
      sucursales = sucursales.filter(sucursal =>
        sucursal.empresa.toLowerCase().includes(nombreEmpresa)
      );
      const sucursalesId = sucursales.map(sucursal => sucursal.codigoSucursal);
      residuos = residuos.filter(residuo =>
        sucursalesId.includes(residuo.sucursal.codigoSucursal)
      );
    }

    //Filtrar por rubro
    if (filters.rubro.codigoCiuRubro) {
      const codigoCiuRubro = filters.rubro.codigoCiuRubro;
      sucursales = sucursales.filter(
        sucursal => sucursal.codigoCiuRubro === codigoCiuRubro
      );
      residuos = residuos.filter(
        residuo => residuo.sucursal.codigoCiuRubro === codigoCiuRubro
      );
    }

    if (filters.rolesFiltrado.length > 0) {
      sucursales = sucursales.filter(sucursal => {
        let esRol = false;
        for (let index = 0; index < filters.rolesFiltrado.length; index++) {
          const nombreRol = filters.rolesFiltrado[index];
          if (sucursal.rol.findIndex(rol => rol.nombreRol === nombreRol) >= 0) {
            esRol = true;
            break;
          }
        }
        return esRol;
      });
      residuos = residuos.filter(residuo => {
        let esRol = false;
        for (let index = 0; index < filters.rolesFiltrado.length; index++) {
          const nombreRol = filters.rolesFiltrado[index];
          if (residuo.rol.nombreRol === nombreRol) {
            esRol = true;
            break;
          }
        }
        return esRol;
      });
    }

    //Filtrar por radio
    if (filters.limitarRadio) {
      const radioComparable = parseInt(filters.radio) * 1000;
      sucursales = sucursales.filter(sucursal => {
        const direccion = sucursal.direccion || null;
        if (!direccion) {
          return null;
        }

        let latitud = direccion.latitud;
        let longitud = direccion.longitud;
        let distanciaSucursal = getDistance(
          [latitud, longitud],
          usuario.coordenadas
        );

        return distanciaSucursal <= radioComparable;
      });

      residuos = residuos.filter(residuo => {
        const direccion = residuo.sucursal.direccion || null;
        if (!direccion) {
          return null;
        }

        let latitud = direccion.latitud;
        let longitud = direccion.longitud;
        let distanciaSucursal = getDistance(
          [latitud, longitud],
          usuario.coordenadas
        );

        return distanciaSucursal <= radioComparable;
      });
    }

    return {
      numeroResiduos: residuos ? residuos.length : 0,
      numeroSucursales: sucursales ? sucursales.length : 0,
      residuos: residuos ? residuos : [],
      sucursales: sucursales ? sucursales : []
    };
  };

  const filtrarMatchEmpresas = matchEmpresas => {
    let { sucursales } = matchEmpresas;

    //SUCURSALES.
    //Filtrar nombre empresa.
    if (filters.empresa.trim().length > 0) {
      const nombreEmpresa = filters.empresa.toLowerCase();
      sucursales = sucursales.filter(sucursal =>
        sucursal.empresa.toLowerCase().includes(nombreEmpresa)
      );
    }
    //Filtrar por rubro
    if (filters.rubro && filters.rubro.codigoCiuRubro) {
      const codigoCiuRubro = filters.rubro.codigoCiuRubro;
      sucursales = sucursales.filter(
        sucursal => sucursal.codigoCiuRubro === codigoCiuRubro
      );
    }
    //Filtrar por radio
    if (filters.limitarRadio) {
      const radioComparable = parseInt(filters.radio) * 1000;
      sucursales = sucursales.filter(sucursal => {
        const direccion = sucursal.direccion || null;
        if (!direccion) {
          return null;
        }
        let latitud = direccion.latitud;
        let longitud = direccion.longitud;
        let distanciaSucursal = getDistance(
          [latitud, longitud],
          usuario.coordenadas
        );
        return distanciaSucursal <= radioComparable;
      });
    }
    if (filters.rolesFiltrado.length > 0) {
      sucursales = sucursales.filter(sucursal => {
        let esRol = false;
        for (let index = 0; index < filters.rolesFiltrado.length; index++) {
          const nombreRol = filters.rolesFiltrado[index];
          if (sucursal.rol.findIndex(rol => rol.nombreRol === nombreRol) >= 0) {
            esRol = true;
            break;
          }
        }
        return esRol;
      });
    }

    return {
      numeroSucursales: sucursales ? sucursales.length : 0,
      sucursales: sucursales ? sucursales : []
    };
  };

  const filtrarMatchAmpliado = matchAmpliada => {
    let { sucursales } = matchAmpliada;

    //SUCURSALES.
    //Filtrar nombre empresa.
    if (filters.empresa.trim().length > 0) {
      const nombreEmpresa = filters.empresa.toLowerCase();
      sucursales = sucursales.filter(sucursal =>
        sucursal.razonSocial.toLowerCase().includes(nombreEmpresa)
      );
    }
    //Filtrar por rubro
    if (filters.rubro && filters.rubro.codigoCiuRubro) {
      const codigoCiuRubro = filters.rubro.codigoCiuRubro;
      sucursales = sucursales.filter(
        sucursal => sucursal.codigoCiuRubro === codigoCiuRubro
      );
    }

    //Filtrar por radio
    if (filters.limitarRadio) {
      const radioComparable = parseInt(filters.radio) * 1000;
      sucursales = sucursales.filter(sucursal => {
        const direccion = sucursal.direccion || null;
        if (!direccion) {
          return null;
        }
        let latitud = direccion.latitud;
        let longitud = direccion.longitud;
        let distanciaSucursal = getDistance(
          [latitud, longitud],
          usuario.coordenadas
        );
        return distanciaSucursal <= radioComparable;
      });
    }

    return {
      numeroSucursales: sucursales ? sucursales.length : 0,
      sucursales: sucursales ? sucursales : []
    };
  };

  return {
    listRubros,
    filters,
    handleChange,
    handlerFiltrar,
    handlerBorrarFiltros,
    roles,
    handleOnChangeRole
  };
};
