import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import { Form, Formik } from 'formik';
import { SucursalCampos } from './SucursalCampos';
import { Divider } from '@mui/material';
import { SucursalResiduos } from './SucursalResiduos.jsx';
import { useSucursal } from '../hooks/useSucursal';
import { schemaNuevaSucursal } from '../schema/schemaNuevaSucursal';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import storeMessages from 'states/alertMessages/store';
import Text from 'components/design-system/Text/Text';
import { ChevronLeft, Close } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import { SucursalSkeleton } from './SucursalSkelenton';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import { DICTONARY, RUTAS } from 'const/Dictonary';

const SucursalFormik = () => {
  const { codigoSucursal, codigoEmpresa } = useParams();

  const {
    isLoading,
    sucursal,
    isNuevaSucursal,
    tipoResiduoSeleccionados,
    handleSubmit
  } = useSucursal(codigoSucursal || null, codigoEmpresa || null);

  window.scrollTo(0, 0);

  return (
    <SidebarLayout>
      <Heading type="h3" className="mb-5">
        <Link to={`${RUTAS.NEGOCIO}/${codigoEmpresa}/sucursales`}>
          <IconButtonComponent type="secondary" className="mr-2">
            <ChevronLeft />
          </IconButtonComponent>
        </Link>

        {isNuevaSucursal ? 'Nueva sucursal' : 'Editar sucursal'}
      </Heading>
      <div className="bg-white rounded shadow-md p-5 mb-10">
        {isLoading ? (
          <SucursalSkeleton />
        ) : (
          <Formik
            initialValues={sucursal}
            validateOnMount={false}
            validationSchema={schemaNuevaSucursal}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={(values, { setSubmitting }) => {
              try {
                handleSubmit(values).finally(() => {
                  setSubmitting(false);
                });
              } catch (error) {
                storeMessages.message.setMessage({
                  mensaje:
                    'Ha ocurrido un error al guardar los datos vuelve a intentarlo recargando la página y completando nuevamente el formulario. Si el problema persiste comunícate con el administrador del sitio',
                  tipo: 'error'
                });
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              handleChange,
              validateForm
            }) => (
              <Form 
              id="editarSucursal"
              onChange={() => {
                // console.log('error', errors);
              }}
              >
                <SucursalCampos
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  isSubmitting={isSubmitting}
                />

                <SucursalResiduos
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  tipoResiduoSeleccionados={tipoResiduoSeleccionados}
                />

                {errors.codigosTipoResiduo && touched.codigosTipoResiduo && (
                  <Text className="text-error-dark py-2">
                    <Close className="mr-1 text-lg" />
                    {errors.codigosTipoResiduo}
                  </Text>
                )}

                <div className="col-span-12 my-5">
                  <Divider />
                </div>

                <div className="col-span-12 justify-between">
                  <div className="flex flex-col sm:flex-row justify-between">
                    <Link
                      className="order-2 md:order-1"
                      to={`${RUTAS.NEGOCIO}/${codigoEmpresa}/sucursales`}
                    >
                      <ButtonComponent
                        className="w-full sm:w-auto mt-4 sm:mt-0"
                        type="secondary"
                      >
                        Cancelar
                      </ButtonComponent>
                    </Link>
                    <ButtonComponent
                      form="editarSucursal"
                      buttonType="submit"
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                      className="w-full sm:w-auto order-1 md:order-2"
                    >
                       {isSubmitting
                                  ?  DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD.EDITAR_IS_LOADING
                                  :  DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD.EDITAR
                       }
                    </ButtonComponent>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </SidebarLayout>
  );
};

export default SucursalFormik;
