// Importaciones de react
import { useState } from 'react';

export const useInitalValues = () => {
  const [residuoDemandante, setResiduoDemandante] = useState({
    rol: {
      codigoRol: 2
    },
    // Seleccionar la sucursal de origen
    codigoSucursal: '',
    nombreSucursal: '',

    // Tipo de Residuo
    tipoResiduo: [],
    // SubTipo de Residuo
    subTipoResiduo: [],
    tieneSubTipoResiduo: false,

    //Nombre Residuo
    nombreResiduo: '',

    // Tipo de Recursos
    tipoRecurso: [],
    tieneTipoRecursos: false,

    // Otro Tipo de Recursos
    otroTipoRecurso: [],
    tieneOtroTipoRecurso: false,

    // Tipo de Recurso de Reemplazo
    recursoReemplazo: [],
    tieneTipoRecursosReemplazo: false,
    // Sub Tipo de Recurso de Reemplazo
    subTipoRecursoReemplazo: {},

    // Descripción adicional
    descripcionAdicional: ''
  });

  const [residuoOferente, setResiduoOferente] = useState({
    // PASO 1

    rol: {
      codigoRol: 1
    },

    // Nombre LER
    nombreLER: [],

    // Nombre Sub Tipo LER
    nombreSubLER: [],

    // Nombre Sub Sub Tipo LER
    nombreSubSubLER: [],

    // nombreResiduo: '',

    // Tipo de Residuo
    tipoResiduo: [],

    // SubTipo de Residuo
    subTipoResiduo: [],

    tieneSubTipoResiduo: false,
    tieneTipoRecursos: false,
    tieneOtroTipoRecurso: false,

    // Otro Tipo de Residuo
    otroSubtipoResiduo: '',
    tieneOtroSubtipoResiduo: false,

    // Tipo de Recursos
    tipoRecurso: [],

    // Otro Tipo de Recursos
    otroTipoRecurso: [],

    // Descipción adicional
    descripcionAdicional: '',

    // PASO 2
    codigoSucursal: 0,
    nombreSucursal: '',
    precioReferencial: '',
    frecuencia: '',
    fechaDisponibilidad: new Date(),
    formatoEntrega: '',
    cantidad: 0,
    unidad: 'ton',

    // Paso 3
    imagenes: [],
    archivos: []
  });

  return {
    residuoDemandante,
    setResiduoDemandante,
    residuoOferente,
    setResiduoOferente
  }
}
