import { Avatar, Divider } from '@mui/material';
import imgEditarInformacion from 'assets/images/usuario/editar-informacion.svg';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import Dropzone from 'components/design-system/Dropzone/Dropzone';
import Text from 'components/design-system/Text/Text';
import IconComponent from 'components/design-system/icon/Icon';
import TaskAlt from '@mui/icons-material/TaskAlt';
import { DICTONARY } from 'const/Dictonary';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import { useEffect, useState } from 'react';
import { useServices } from './hooks/useServices';
import AlertComponent from 'components/design-system/Alert/Alert';
import commons from 'services/commons';

export const CuentaCampos = ({
  isSubmitting,
  isLoadingSucursales,
  isLoadingEmpresas,
  isSuperAdmin,
  values,
  handleChange,
  setFieldTouched,
  setFieldValue,
  touched,
  errors,
  imagenPerfil,
  empresas,
  sucursales,
  handleObtenerSucursales,
  isNuevoUsuario
}) => {
  useEffect(() => {
    if (values.empresa && values.empresa.codigoEmpresa) {
      handleObtenerSucursales(values.empresa.codigoEmpresa);
    }
  }, [values.empresa?.codigoEmpresa]);

  // Obtiene los perfiles
  const [perfiles, setPerfiles] = useState([]);
  const [isLoadingPerfiles, setIsLoadingPerfiles] = useState(true);
  const { cargarPerfiles } = useServices();
  useEffect(() => {
    cargarPerfiles(setPerfiles, setIsLoadingPerfiles);
  }, []);

  //Carga Regiones solo perfil AT
  const [regiones, setRegiones] = useState([]);
  const IS_PERFIL_AT =
    values.perfiles?.codigoPerfil ===
      DICTONARY.CODIGOS_PERFILES.ANALISTA_TERRITORIAL || false;
  useEffect(() => {
    if (IS_PERFIL_AT) {
      commons
        .obtieneRegiones()
        .then(response => {
          setRegiones(response.data.content);
        })
        .catch(error => {
          setRegiones([]);
        });
    }
  }, [values.perfiles, IS_PERFIL_AT]);

  return (
    <div>
      <div className="grid grid-cols-12 gap-4 items-center">
        <div className="col-span-12 md:col-span-3 text-center mx-auto">
          <img
            src={imgEditarInformacion}
            alt="Actualizar información de contacto"
          />
        </div>
        <div className="col-span-12 md:col-span-9">
          <div className="grid grid-cols-12 gap-4 mb-4">
            <div className="col-span-12 sm:col-span-6">
              <AutocompleteComponent
                name="perfiles"
                accesor="nombrePerfil"
                clearOnEscape={true}
                disabled={isLoadingPerfiles || isSubmitting}
                openOnFocus={true}
                options={perfiles || []}
                onChange={(ev, perfiles, reason) => {
                  let data = { name: 'perfiles', value: perfiles };
                  if (reason === 'clear') {
                    data.value = '';
                  }
                  if (
                    data.value.codigoPerfil !==
                      DICTONARY.CODIGOS_PERFILES.ADMINISTRADOR ||
                    data.value.codigoPerfil !==
                      DICTONARY.CODIGOS_PERFILES.FUNCIONARIO
                  ) {
                    setFieldValue('empresa', '');
                    setFieldValue('sucursal', '');
                  }
                  if (
                    data.value.codigoPerfil ===
                    DICTONARY.CODIGOS_PERFILES.ANALISTA_TERRITORIAL
                  ) {
                    setFieldValue('region', '');
                  }

                  setFieldValue('perfiles', data.value);
                }}
                onBlur={() => setFieldTouched('perfiles')}
                value={values.perfiles}
                getOptionLabel={option => option.nombrePerfil || ''}
                isOptionEqualToValue={(option, value) =>
                  option.codigoPerfil === values.perfiles.codigoPerfil
                }
              >
                <InputComponent
                  autoComplete={'off'}
                  fullWidth
                  touched={touched.perfiles ? true : false}
                  estado={
                    errors.perfiles
                      ? { tipo: 'error', mensaje: errors.perfiles }
                      : null
                  }
                  label={<LabelComponent>Perfil</LabelComponent>}
                />
              </AutocompleteComponent>
            </div>

            {IS_PERFIL_AT && (
              <div className="col-span-12 sm:col-span-6">
                <AutocompleteComponent
                  name="region"
                  accesor="nombreRegion"
                  clearOnEscape={true}
                  disabled={isLoadingPerfiles || isSubmitting}
                  openOnFocus={true}
                  options={regiones || []}
                  onChange={(ev, region, reason) => {
                    let data = { name: 'region', value: region };
                    if (reason === 'clear') {
                      data.value = '';
                    }
                    setFieldValue('region', data.value);
                  }}
                  onBlur={() => setFieldTouched('region')}
                  value={values.region}
                  getOptionLabel={option => option.nombreRegion || ''}
                  isOptionEqualToValue={(option, value) =>
                    option.codigoRegion === values.region.codigoRegion
                  }
                >
                  <InputComponent
                    autoComplete={'off'}
                    fullWidth
                    touched={touched.region ? true : false}
                    estado={
                      errors.region
                        ? { tipo: 'error', mensaje: errors.region }
                        : null
                    }
                    label={<LabelComponent>Región</LabelComponent>}
                  />
                </AutocompleteComponent>
              </div>
            )}

            {isSuperAdmin &&
              (values.perfiles?.codigoPerfil ===
                DICTONARY.CODIGOS_PERFILES.ADMINISTRADOR ||
                values.perfiles?.codigoPerfil ===
                  DICTONARY.CODIGOS_PERFILES.FUNCIONARIO) && (
                <div className="col-span-12 md:col-span-6">
                  <AutocompleteComponent
                    name="empresa"
                    accesor="nombreEmpresa"
                    clearOnEscape={true}
                    disabled={isLoadingEmpresas || isSubmitting}
                    openOnFocus={true}
                    options={empresas || []}
                    onChange={(ev, empresa, reason) => {
                      let data = { name: 'empresa', value: empresa };
                      if (reason === 'clear') {
                        data.value = '';
                      }
                      setFieldValue('empresa', data.value);
                      setFieldValue('sucursal', '');
                    }}
                    onBlur={() => setFieldTouched('empresa')}
                    value={values.empresa}
                    getOptionLabel={option => option.nombreEmpresa || ''}
                    isOptionEqualToValue={(option, value) =>
                      option.nombreEmpresa === values.empresa.nombreEmpresa
                    }
                  >
                    <InputComponent
                      autoComplete={'off'}
                      fullWidth
                      touched={touched.empresa ? true : false}
                      estado={
                        errors.empresa
                          ? { tipo: 'error', mensaje: errors.empresa }
                          : null
                      }
                      label={<LabelComponent>Empresa</LabelComponent>}
                    />
                  </AutocompleteComponent>
                </div>
              )}

            {(values.perfiles?.codigoPerfil ===
              DICTONARY.CODIGOS_PERFILES.ADMINISTRADOR ||
              values.perfiles?.codigoPerfil ===
                DICTONARY.CODIGOS_PERFILES.FUNCIONARIO) && (
              <div className="col-span-12 sm:col-span-6 mb-4">
                <AutocompleteComponent
                  disabled={
                    isLoadingSucursales || isLoadingEmpresas || isSubmitting
                  }
                  name="sucursal"
                  clearOnEscape={true}
                  openOnFocus={true}
                  accesor="nombreSucursal"
                  options={sucursales || []}
                  onChange={(ev, sucursal, reason) => {
                    let data = { name: 'sucursal', value: sucursal };
                    if (reason === 'clear') {
                      data.value = '';
                    }
                    setFieldValue('sucursal', data.value);
                  }}
                  onBlur={() => setFieldTouched('sucursal')}
                  value={values.sucursal}
                  getOptionLabel={option => option.nombreSucursal || ''}
                  isOptionEqualToValue={(option, value) =>
                    option.codigoSucursal === values.sucursal.codigoSucursal
                  }
                >
                  <InputComponent
                    autoComplete={'off'}
                    fullWidth
                    touched={touched.sucursal ? true : false}
                    estado={
                      errors.sucursal
                        ? { tipo: 'error', mensaje: errors.sucursal }
                        : null
                    }
                    label={<LabelComponent>Sucursal</LabelComponent>}
                  />
                </AutocompleteComponent>
              </div>
            )}

            <div className="col-span-12">
              <Divider />
            </div>

            <div className="col-span-12 sm:col-span-6">
              <InputComponent
                fullWidth
                disabled={isSubmitting ? true : false}
                name="rutUsuario"
                value={values.rutUsuario}
                onChange={handleChange}
                label={<LabelComponent esOpcional>Rut</LabelComponent>}
                onBlur={() => setFieldTouched('rutUsuario')}
                touched={touched.rutUsuario ? true : false}
                estado={
                  errors.rutUsuario
                    ? { tipo: 'error', mensaje: errors.rutUsuario }
                    : null
                }
                helperText={
                  <Text size="S">Sin puntos y con guión, ej: 11200300-K</Text>
                }
              />
            </div>
            <div className="col-span-12 sm:col-span-6">
              <InputComponent
                fullWidth
                disabled={isSubmitting ? true : false}
                name="profesion"
                value={values.profesion}
                onChange={handleChange}
                label={<LabelComponent esOpcional>Profesión</LabelComponent>}
                onBlur={() => setFieldTouched('profesion')}
                touched={touched.profesion ? true : false}
                estado={
                  errors.profesion
                    ? { tipo: 'error', mensaje: errors.profesion }
                    : null
                }
              />
            </div>
            <div className="col-span-12 sm:col-span-6">
              <InputComponent
                fullWidth
                disabled={isSubmitting ? true : false}
                name="primerNombre"
                value={values.primerNombre}
                onChange={handleChange}
                label={<LabelComponent>Primer nombre</LabelComponent>}
                onBlur={() => setFieldTouched('primerNombre')}
                touched={touched.primerNombre ? true : false}
                estado={
                  errors.primerNombre
                    ? { tipo: 'error', mensaje: errors.primerNombre }
                    : null
                }
              />
            </div>
            <div className="col-span-12 sm:col-span-6">
              <InputComponent
                fullWidth
                disabled={isSubmitting ? true : false}
                name="segundoNombre"
                value={values.segundoNombre}
                onChange={handleChange}
                label={
                  <LabelComponent esOpcional>Segundo nombre</LabelComponent>
                }
                onBlur={() => setFieldTouched('segundoNombre')}
                touched={touched.segundoNombre ? true : false}
                estado={
                  errors.segundoNombre
                    ? { tipo: 'error', mensaje: errors.segundoNombre }
                    : null
                }
              />
            </div>
            <div className="col-span-12 sm:col-span-6">
              <InputComponent
                fullWidth
                disabled={isSubmitting ? true : false}
                name="apellidoPaterno"
                value={values.apellidoPaterno}
                onChange={handleChange}
                label={<LabelComponent>Apellido paterno</LabelComponent>}
                onBlur={() => setFieldTouched('apellidoPaterno')}
                touched={touched.apellidoPaterno ? true : false}
                estado={
                  errors.apellidoPaterno
                    ? { tipo: 'error', mensaje: errors.apellidoPaterno }
                    : null
                }
              />
            </div>
            <div className="col-span-12 sm:col-span-6">
              <InputComponent
                fullWidth
                disabled={isSubmitting ? true : false}
                name="apellidoMaterno"
                value={values.apellidoMaterno}
                onChange={handleChange}
                label={<LabelComponent>Apellido materno</LabelComponent>}
                onBlur={() => setFieldTouched('apellidoMaterno')}
                touched={touched.apellidoMaterno ? true : false}
                estado={
                  errors.apellidoMaterno
                    ? { tipo: 'error', mensaje: errors.apellidoMaterno }
                    : null
                }
              />
            </div>
            <div className="col-span-12 sm:col-span-6">
              <InputComponent
                fullWidth
                disabled={isSubmitting ? true : false}
                name="correoElectronico"
                value={values.correoElectronico}
                onChange={e => {
                  handleChange(e);
                  setFieldValue('emailModificado', true);
                }}
                label={<LabelComponent>Email</LabelComponent>}
                onBlur={() => {
                  setFieldTouched('correoElectronico');
                  setFieldValue('emailModificado', false);
                }}
                touched={touched.correoElectronico ? true : false}
                estado={
                  errors.correoElectronico
                    ? { tipo: 'error', mensaje: errors.correoElectronico }
                    : null
                }
              />
            </div>
            <div className="col-span-12 sm:col-span-6">
              <InputComponent
                fullWidth
                disabled={isSubmitting ? true : false}
                name="telefonoUsuario"
                value={values.telefonoUsuario}
                onChange={handleChange}
                label={<LabelComponent esOpcional>Teléfono</LabelComponent>}
                onBlur={() => setFieldTouched('telefonoUsuario')}
                touched={touched.telefonoUsuario ? true : false}
                estado={
                  errors.telefonoUsuario
                    ? { tipo: 'error', mensaje: errors.telefonoUsuario }
                    : null
                }
              />
            </div>
          </div>
          {isNuevoUsuario && (
            <div className="col-span-12">
              <AlertComponent type="info">
                <Text>
                  Se le enviará un correo al usuario de verificación. Cuando el
                  usuario ingrese por primera vez a la plataforma se le{' '}
                  <strong className="text-black">
                    solicitará registrar la contraseña de su cuenta.
                  </strong>
                </Text>
              </AlertComponent>
            </div>
          )}
        </div>
      </div>

      {!isNuevoUsuario && (
        <div className="col-span-12 my-5">
          <Divider />
        </div>
      )}

      {!isNuevoUsuario && (
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-3">
            <div className="logo-thumbnail pt-05">
              <Avatar
                alt="Imagen de perfil"
                src={`data:image/jpg;base64,${
                  imagenPerfil ? imagenPerfil : DICTONARY.DEFAULT_IMAGEN_PERFIL
                }`}
                className="biggest-logo"
              />
            </div>
          </div>
          <div className="col-span-12 md:col-span-9">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 md:col-span-12">
                <LabelComponent esOpcional>Imagen de perfíl</LabelComponent>
                <Dropzone
                  setDataFiles={data => setFieldValue('files', data)}
                  dataFiles={values.files}
                  enableEditingFile={false}
                  disabled={isSubmitting ? true : false}
                  {...DICTONARY.DROPZONE.CONFIG_UPLOAD_PROFILE_FILES}
                >
                  <Text className="text-black font-medium">
                    Solo se aceptan los siguientes tipos de archivos
                  </Text>
                  <ul className="lg:flex mt-1">
                    <li className="d-flex items-center mb-1">
                      <IconComponent className="mr-2">
                        <TaskAlt className="text-base text-uv-primary-20" />
                      </IconComponent>
                      <Text className="text-neutral-40">
                        Formatos JPG, JPGE y PNG
                      </Text>
                    </li>
                    <li className="d-flex items-center mb-1 lg:ml-6">
                      <IconComponent className="mr-2">
                        <TaskAlt className="text-base text-uv-primary-20" />
                      </IconComponent>
                      <Text className="text-neutral-40">
                        Tamaño no superior a 5 MB
                      </Text>
                    </li>
                  </ul>
                </Dropzone>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
