export const stepsIngresar = [
  {
    label: 'Tipo de negocio',
    description: ``,
    id: 'tipo-negocio'
  },
  {
    label: 'Información del negocio',
    description: '',
    id: 'informacion-negocio'
  },
  {
    label: 'Rol del negocio',
    description: ``,
    id: 'rol-negocio'
  },
  {
    label: 'Tipos de residuos',
    description: ``,
    id: 'tipos-residuos'
  },
  {
    label: 'Acreditaciones y certificaciones',
    description: ``,
    id: 'acreditaciones'
  }
];
