export const stepsLine = [
  {
    title: 'Identificación del residuo o recurso',
    subtitle: ``,
    number: 1,
    classLine: 'behind-first'
  },
  {
    title: 'Especificación de oferta',
    subtitle: ``,
    number: 2,
    classLine: ''
  },
  {
    title: 'Material adjunto',
    subtitle: ``,
    number: 3,
    classLine: 'behind-last'
  }
];
