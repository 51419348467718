import React from 'react';
import { CuadroNegocioResumen } from './componentes/CuadroNegocioResumen';
import { CuadroRegistrarNegocio } from './componentes/CuadroRegistrarNegocio';
import { CuadroCompletarCuenta } from './componentes/CuadroCompletarCuenta';
import { CuadroAccesosRapidos } from './componentes/AccesosRapidos/CuadroAccesosRapidos';

export const HomeUsuario = ({
  logeduser,
  data,
  tieneNegocioRegistrado,
  isLoading
}) => {

  return (
    <>
      <CuadroAccesosRapidos />

      {tieneNegocioRegistrado ? (
        <>
          <CuadroNegocioResumen
            usuario={logeduser}
            resumenCuenta={data}
            isLoading={isLoading}
          />
        </>
      ) : (
        <CuadroRegistrarNegocio
        porcentajeUsuarioPendiente={data.porcentajeUsuarioPendiente}
        />
      )}

      {
        //Para cuando tiene negocio registrado pero no ha editado sus datos.
        tieneNegocioRegistrado && data && !isLoading && (
          <CuadroCompletarCuenta resumenCuenta={data} />
        )
      }
    </>
  );
};
