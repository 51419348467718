import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import empresasService from 'services/empresas';
import usuariosService from 'services/usuarios';
import store from 'states/store';
import storeMessages from 'states/alertMessages/store';
import { DICTONARY } from 'const/Dictonary';
import { OrdenarAscendente } from 'helpers/manejoArreglos';
const SIN_LIMITE = 999_999_999;

export const useEditarCuenta = isNuevoUsuario => {
  const { codigoUsuario } = useParams();
  const { logeduser, setLogeduser } = store.loguedUser;
  const { isSuperAdmin } = store.loguedUser.perfiles;
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSucursales, setIsLoadingSucursales] = useState(false);
  const [isLoadingEmpresas, setIsLoadingEmpresas] = useState(false);
  const [usuario, setUsuario] = useState(null);
  const [empresas, setEmpresas] = useState([]);
  const [sucursales, setSucursales] = useState([]);

  const valorInicial = () => {
    if (usuario) {
      const nombre = usuario.nombreUsuario.split(' ');
      const primerNombre = nombre[0];
      const segundoNombre =
        nombre.length > 2 ? nombre[1] + ' ' + nombre[2] : nombre[1];

      const perfil = usuario.perfiles ? usuario.perfiles[0] : '';

      return {
        rutUsuario: usuario.rutUsuario || '',
        primerNombre: primerNombre || '',
        segundoNombre: segundoNombre || '',
        apellidoPaterno: usuario.apellidoPaterno || '',
        apellidoMaterno: usuario.apellidoMaterno || '',
        correoElectronico: usuario.correoElectronico || '',
        telefonoUsuario: usuario.telefonoUsuario || '',
        profesion: usuario.profesion || '',
        imagenBase64: '',
        files: [],
        sucursal: {
          codigoSucursal: usuario.sucursal?.codigoSucursal,
          nombreSucursal: usuario.sucursal?.nombreSucursal
        },
        empresa: {
          codigoEmpresa: usuario.sucursal?.empresa?.codigoEmpresa,
          nombreEmpresa: usuario.sucursal?.empresa?.nombreEmpresa
        },
        perfiles: perfil,
        region: usuario.region || ''
      };
    }

    return {
      rutUsuario: '',
      primerNombre: '',
      segundoNombre: '',
      apellidoPaterno: '',
      apellidoMaterno: '',
      correoElectronico: '',
      telefonoUsuario: '',
      profesion: '',
      imagenBase64: '',
      files: [],
      sucursal: '',
      empresa: '',
      perfiles: ''
    };
  };

  const onSubmit = async valores => {
    try {
      const valoresRequest = isNuevoUsuario
        ? formatearValoresNuevoUsuario(valores)
        : formatearValores(valores);
      const response = isNuevoUsuario
        ? await usuariosService.nuevoUsuario(valoresRequest)
        : await usuariosService.actualizarUsuario(
            codigoUsuario,
            valoresRequest
          );

      if (response.status === 200) {
        const data = response.data;
        if (data.error) return false;
        else {
          if (logeduser.codigoUsuario === codigoUsuario) {
            const usuarioState = {
              ...logeduser,
              ...valores,
              ...response.data,
              base64Avatar:
                valores.files && valores.files.length > 0
                  ? valoresRequest.imagenBase64
                  : logeduser.base64Avatar
            };
            if (valoresRequest.imagenBase64) {
              usuarioState.base64Avatar = valoresRequest.imagenBase64;
            }
            setLogeduser(usuarioState);
          }

          storeMessages.message.setMessage({
            mensaje: isNuevoUsuario
              ? 'El usuario ha sido creado correctamente'
              : 'Se han actualizado correctamente los datos del usuario',
            tipo: 'success'
          });
          return true;
        }
      }
    } catch (error) {
      // throw new Error('error en onSubmit ', error);
    }
    return false;
  };

  const formatearValores = valores => {
    const valoresRequest = {};

    //Campos optativos
    valoresRequest.rutUsuario =
      valores.rutUsuario.length > 0 ? valores.rutUsuario : null;
    valoresRequest.profesion =
      valores.profesion.length > 0 ? valores.profesion : null;
    valoresRequest.telefonoUsuario =
      valores.telefonoUsuario.length > 0 ? valores.telefonoUsuario : null;

    if (valores.files && valores.files.length > 0) {
      const nombreArchivo = valores.files[0].nombreArchivo;
      const imagenBase64 = valores.files[0].base64.replace(
        /^data:image\/[a-z]+;base64,/,
        ''
      );

      valoresRequest.nombreImagen = nombreArchivo;
      valoresRequest.imagenBase64 = imagenBase64;
    }

    //Campos obligatorios
    valoresRequest.apellidoMaterno = valores.apellidoMaterno;
    valoresRequest.apellidoPaterno = valores.apellidoPaterno;
    valoresRequest.correoElectronico = valores.correoElectronico;
    if (valores.segundoNombre && valores.segundoNombre.length > 0) {
      valoresRequest.nombreUsuario =
        valores.primerNombre + ' ' + valores.segundoNombre;
    } else {
      valoresRequest.nombreUsuario = valores.primerNombre;
    }

    valoresRequest.sucursal = {
      codigoSucursal: valores.sucursal.codigoSucursal
    };
    valoresRequest.perfiles = [{ codigoPerfil: valores.perfiles.codigoPerfil }];

    if (
      valores.perfiles?.codigoPerfil ===
      DICTONARY.CODIGOS_PERFILES.ANALISTA_TERRITORIAL
    ) {
      valoresRequest.region = { codigoRegion: valores.region.codigoRegion };
    }

    return valoresRequest;
  };

  const formatearValoresNuevoUsuario = valores => {
    const valoresRequest = {};
    valoresRequest.rutUsuario =
      valores.rutUsuario.length > 0 ? valores.rutUsuario : null;
    valoresRequest.profesion =
      valores.profesion.length > 0 ? valores.profesion : null;
    valoresRequest.telefonoUsuario =
      valores.telefonoUsuario.length > 0 ? valores.telefonoUsuario : null;

    valoresRequest.apellidoMaterno = valores.apellidoMaterno;
    valoresRequest.apellidoPaterno = valores.apellidoPaterno;
    valoresRequest.correoElectronico = valores.correoElectronico;
    valoresRequest.perfiles = [{ codigoPerfil: valores.perfiles.codigoPerfil }];

    if (valores.segundoNombre && valores.segundoNombre.length > 0) {
      valoresRequest.nombreUsuario =
        valores.primerNombre + ' ' + valores.segundoNombre;
    } else {
      valoresRequest.nombreUsuario = valores.primerNombre;
    }

    if (
      valores.perfiles?.codigoPerfil ===
        DICTONARY.CODIGOS_PERFILES.ADMINISTRADOR ||
      valores.perfiles?.codigoPerfil === DICTONARY.CODIGOS_PERFILES.FUNCIONARIO
    ) {
      valoresRequest.sucursal = {
        codigoSucursal: valores.sucursal.codigoSucursal
      };
    }

    if (
      valores.perfiles?.codigoPerfil ===
      DICTONARY.CODIGOS_PERFILES.ANALISTA_TERRITORIAL
    ) {
      valoresRequest.region = { codigoRegion: valores.region.codigoRegion };
    }

    return valoresRequest;
  };

  const handleObtenerSucursales = async codigoEmpresa => {
    cargarSucursales({
      totalPagina: DICTONARY.PAGINACION.ALL_DATA,
      codigoEmpresa: codigoEmpresa
    });
  };

  const cargarEmpresas = async () => {
    try {
      setIsLoadingEmpresas(true);
      const responseEmpresas = await empresasService.obtieneEmpresas({
        totalPagina: DICTONARY.PAGINACION.ALL_DATA
      });
      if (responseEmpresas.status === 200) {
        setEmpresas(
          OrdenarAscendente(
            responseEmpresas.data.content.map(empresa => ({
              codigoEmpresa: empresa.codigoEmpresa,
              nombreEmpresa: empresa.nombreEmpresa
            })),
            'nombreEmpresa'
          )
        );
      }
    } catch (error) {
      throw new Error('error en cargarEmpresas ', error);
    } finally {
      setIsLoadingEmpresas(false);
    }
  };

  const cargarSucursales = async query => {
    try {
      setIsLoadingSucursales(true);
      const responseSucursales = await empresasService.obtieneSucursales(query);
      if (responseSucursales.status === 200) {
        setSucursales(
          OrdenarAscendente(
            responseSucursales.data.content.map(sucursal => ({
              codigoSucursal: sucursal.codigoSucursal,
              nombreSucursal: sucursal.nombreSucursal
            })),
            'nombreSucursal'
          )
        );
      }
    } catch (error) {
      throw new Error('error en cargarSucursales ', error);
    } finally {
      setIsLoadingSucursales(false);
    }
  };

  const cargarVistaUsuarioNuevo = async () => {
    try {
      setIsLoading(true);
      await cargarEmpresas();
    } catch (error) {
      console.log('Error al obtener la información del usuario', error);
    } finally {
      setIsLoading(false);
    }
  };

  const cargarVistaEditarUsuario = async () => {
    setIsLoading(true);
    const querySucursales = { totalPagina: SIN_LIMITE };

    try {
      const response = await usuariosService.getUsuario(codigoUsuario);
      if (response.status === 200) {
        setUsuario(response.data);
      }

      if (isSuperAdmin) {
        await cargarEmpresas();
      }

      const tieneSucursal =
        response.data.sucursal && response.data.sucursal.empresa
          ? response.data.sucursal.empresa.codigoEmpresa
          : null;

      if (tieneSucursal) {
        querySucursales.codigoEmpresa =
          response.data.sucursal.empresa.codigoEmpresa;
      }

      await cargarSucursales(querySucursales);
    } catch (error) {
      setIsLoading(false);
      console.log('Error al obtener la información del usuario', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isNuevoUsuario) {
      cargarVistaUsuarioNuevo();
    } else {
      cargarVistaEditarUsuario();
    }
  }, []);

  return {
    //Boolean
    isLoading,
    isSuperAdmin,
    isLoadingSucursales,
    isLoadingEmpresas,

    //Objects & Arrays
    empresas,
    usuario,
    sucursales,
    imagenPerfil: usuario?.base64Avatar,

    //Functions
    valorInicial,
    onSubmit,
    handleObtenerSucursales
  };
};
