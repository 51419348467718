import React, { useState } from 'react';
import SubMenuRSD from './SubMenuRSD';
import SubMenuEmpresa from './SubMenuEmpresa';
import SubMenuDifusion from './SubMenuDifusion';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import TrendingUp from '@mui/icons-material/TrendingUp';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Home from '@mui/icons-material/Home';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Create from '@mui/icons-material/Create';
import CompareArrows from '@mui/icons-material/CompareArrows';
import ExitToApp from '@mui/icons-material/ExitToApp';
import ListSubheaderComponent from 'components/design-system/ListSubheader/ListSubheader';
import ListComponent from 'components/design-system/List/List';
import Text from 'components/design-system/Text/Text';
import ListItemButtonComponent from 'components/design-system/ListItemButton/ListItemButton';
import { RUTAS } from 'const/Dictonary';
import {
  Dashboard,
  HubOutlined,
  Link,
  ManageHistory,
  Search
} from '@mui/icons-material';
import SubMenuTutoriales from './SubMenuTutoriales';
import store from 'states/alertMessages/store';
import SubMenuAnalisisTerritorial from './SubMenuAnalisisTerritorial';
import Inventory from '@mui/icons-material/Inventory';
import SubMenuGestiona from './SubMenuGestiona';
import AccountBalance from '@mui/icons-material/AccountBalance';

const ResponsiveMenu = ({
  isDemandante,
  isOferente,
  loguedUser,
  forceRefresh,
  isSuperAdmin,
  isAdmin,
  handleLogout,
  isFuncionario,
  isLogged,
  disableOpacity,
  isAnalistaTerritorial,
  tieneNegocio
}) => {
  const MENU_ITEMS = [
    {
      nombre: 'Inicio',
      icono: <Home className="text-uv-primary-0" />,
      subItems: <></>,
      collapsable: false,
      onClickBotonPadre: forceRefresh('/'),
      isShow: true
    },
    {
      nombre: 'Market Place',
      icono: <HubOutlined className="text-uv-primary-0" />,
      subItems: (
        <>
          <List>
            <ListItemButtonComponent
              onClick={forceRefresh(RUTAS.MARKETPLACE_RESIDUOS)}
              icon={<Search />}
              label={'Buscar'}
            />
            <ListItemButtonComponent
              onClick={forceRefresh(RUTAS.TRANSACCIONES)}
              icon={<CompareArrows />}
              label={'Transacciones'}
            />
            {isDemandante || isOferente || isSuperAdmin ? (
              <ListItemButtonComponent
                onClick={forceRefresh(RUTAS.RESIDUOS)}
                icon={<Inventory />}
                label={'Publicar Residuo-recurso'}
              />
            ) : null}
          </List>
        </>
      ),
      collapsable: true,
      isShow: !isAnalistaTerritorial
    },
    {
      nombre: 'Gestiona',
      icono: <ManageHistory className="text-uv-primary-0" />,
      subItems: (
        <>
          <div className="px-2">
            <SubMenuGestiona
              isSuperAdmin={isSuperAdmin}
              forceRefresh={forceRefresh}
              loguedUser={loguedUser}
              tieneNegocio={tieneNegocio}
            />
          </div>
        </>
      ),
      collapsable: true,
      isShow: !isAnalistaTerritorial
    },
    {
      nombre: 'Reportes',
      icono: <TrendingUp className="text-uv-primary-0" />,
      subItems: (
        <>
          <div className="px-2">
            <SubMenuDifusion
              isSuperAdmin={isSuperAdmin}
              isDemandante={isDemandante}
              isOferente={isOferente}
              loguedUser={loguedUser}
              forceRefresh={forceRefresh}
            />
          </div>
        </>
      ),
      collapsable: true,
      isShow: !isAnalistaTerritorial
    },
    {
      nombre: 'Mi Negocio',
      icono: <AccountBalance className="text-uv-primary-0" />,
      subItems: (
        <>
          <div>
            {isDemandante || isOferente ? (
              <ListSubheaderComponent
                component="div"
                className="pt-4 pb-2 my-0"
              >
                Residuos o recursos
              </ListSubheaderComponent>
            ) : null}
            <div className="px-2">
              <SubMenuRSD
                isSuperAdmin={isSuperAdmin}
                isDemandante={isDemandante}
                isOferente={isOferente}
                forceRefresh={forceRefresh}
              />
            </div>

            <ListSubheaderComponent component="div" className="pt-4 pb-2 my-0">
              Gestión de Empresa
            </ListSubheaderComponent>
            <div className="px-2">
              <SubMenuEmpresa
                isSuperAdmin={isSuperAdmin}
                isDemandante={isDemandante}
                isOferente={isOferente}
                forceRefresh={forceRefresh}
                loguedUser={loguedUser}
              />
            </div>
          </div>
        </>
      ),
      collapsable: true,
      isShow: tieneNegocio
    },
    {
      nombre: 'Tutoriales',
      icono: <Link className="text-uv-primary-0" />,
      subItems: (
        <>
          <div className="px-2">
            <SubMenuTutoriales
              isSuperAdmin={isSuperAdmin}
              isDemandante={isDemandante}
              isAdmin={isAdmin}
              isOferente={isOferente}
              loguedUser={store.loguedUser}
              forceRefresh={forceRefresh}
              isFuncionario={isFuncionario}
            />
          </div>
        </>
      ),
      collapsable: true,
      isShow: !isAnalistaTerritorial
    },
    {
      nombre: 'Análisis Territorial',
      icono: <Dashboard className="text-uv-primary-0" />,
      subItems: (
        <>
          <div className="px-2">
            <SubMenuAnalisisTerritorial
              isSuperAdmin={isSuperAdmin}
              forceRefresh={forceRefresh}
              isAnalistaTerritorial={isAnalistaTerritorial}
            />
          </div>
        </>
      ),
      collapsable: true,
      isShow: isAnalistaTerritorial || isSuperAdmin
    }
  ];

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheaderComponent component="div" id="nested-list-subheader">
          Navegación
        </ListSubheaderComponent>
      }
      className="py-4 max-w-[280px]"
    >
      {MENU_ITEMS.map((menu, key) => {
        if (menu.isShow) {
          return (
            <MenuItem
              key={key}
              nombre={menu.nombre}
              icono={menu.icono}
              subItems={menu.subItems}
              collapsable={menu.collapsable}
              onClickBotonPadre={menu.onClickBotonPadre}
            />
          );
        } else {
          return null;
        }
      })}

      <div className="mt-8">
        <ListSubheaderComponent component="div">
          Mi Cuenta
        </ListSubheaderComponent>

        <ListItemButton onClick={forceRefresh(RUTAS.EDITAR_CUENTA)}>
          <ListItemIcon>
            <Create className="text-uv-primary-0" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Text
                className="font-secondary text-uv-primary-0 tracking-wider"
                size="M"
              >
                Editar Cuenta
              </Text>
            }
          />
        </ListItemButton>

        <ListItemButton onClick={handleLogout}>
          <ListItemIcon>
            <ExitToApp className="text-uv-primary-0" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Text
                className="font-secondary text-uv-primary-0 tracking-wider"
                size="M"
              >
                Cerrar sesión
              </Text>
            }
          />
        </ListItemButton>
      </div>
    </List>
  );
};

const MenuItem = ({
  nombre,
  icono,
  subItems,
  collapsable,
  onClickBotonPadre
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <ListItemButton
        onClick={ev => (collapsable ? setOpen(!open) : onClickBotonPadre(ev))}
      >
        <ListItemIcon>{icono}</ListItemIcon>
        <ListItemText
          primary={
            <Text
              className="font-secondary text-uv-primary-0 tracking-wider"
              size="M"
            >
              {nombre}
            </Text>
          }
        />
        {collapsable && <>{open ? <ExpandLess /> : <ExpandMore />}</>}
      </ListItemButton>
      {collapsable ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <ListComponent disablePadding className="bg-neutral-90 rounded">
            {subItems}
          </ListComponent>
        </Collapse>
      ) : null}
    </>
  );
};

export default ResponsiveMenu;
