import React from 'react';
import { IconButton } from "@mui/material";
import styles from './iconbutton.module.css';

/**
 * IconButtonComponent
 * @param type 'primary' 'secondary' 'error' 
 * @param className tailwind className
 */
const IconButtonComponent = (props) => {
  const { type, className, children, ...rest} = props;
  const typeTag = type? type.toLowerCase() : 'primary'; 

  return(
    <IconButton
      className={`
        ${styles[typeTag]}
        ${className ? className : ''}
      `}
      {...rest}
    >
      {children}
    </IconButton>
  )
}

export default IconButtonComponent;