import { useEffect, useState } from 'react';
// Design System
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
// Images
import imgEmpresa from 'assets/images/business/new/empresa-icono.svg';
import imgPersonaNatural from 'assets/images/business/new/persona-natural.svg';
// Data
import { businessOptValues } from '../data/optionsValues';
import DOMPurify from 'dompurify';
import InputComponent from 'components/design-system/input/Input';

export const BusinessType = ({ values, setFieldValue, handleReset }) => {
  // Manejo Estados Locales
  const [tipoNegocioLocal, setTipoNegocioLocal] = useState(values.tipoNegocio);
  useEffect(() => {}, [setFieldValue, tipoNegocioLocal]);

  return (
    <div>
      <Heading type="h3">¿Como deseas participar en la plataforma?</Heading>
      <Text className="mb-8">
        Haz clic sobre un cuadro para seleccionar tu opción
      </Text>

      <div className="block sm:flex sm:pr-8 pb-6 gap-4">
        {businessOptValues.map((opt, index) => {
          const { text, value, id, label } = opt;

          const textoLimpio = DOMPurify.sanitize(text);
          const texto = (
            <div dangerouslySetInnerHTML={{ __html: textoLimpio }} />
          );

          return (
            <div
              key={index}
              className={`
                  ${
                    tipoNegocioLocal === value
                      ? 'w-full sm:w-7/12'
                      : 'w-full sm:w-5/12'
                  }
                  flex-grow transition-[width] duration-300 ease-in-out lg:h-40 mb-4 sm:mb-0
                `}
            >
              <div className="h-full">
                <InputComponent
                  onChange={ev => {
                    handleReset();
                    setFieldValue('tipoNegocio', ev.target.value);
                    setTipoNegocioLocal(ev.target.value);

                    if (values.tipoNegocio === 'persona-natural') {
                      setFieldValue('rutEmpresa', '');
                      setFieldValue('razonSocial', '');
                      setFieldValue('rubro', {
                        codigoCiuRubro: 0,
                        nombreRubro: ''
                      });
                      setFieldValue('actividad', {
                        codigoCiuActividad: 0,
                        nombreActividad: ''
                      });
                    } else if (values.tipoNegocio === 'empresa') {
                      setFieldValue('nombre', '');
                    }
                  }}
                  type="radio"
                  id={id}
                  name="tipoNegocio"
                  value={value}
                  className="absolute h-0 w-0 border-none overflow-hidden"
                  {...(tipoNegocioLocal === value ? 'checked' : '')}
                />
                <div>
                  <label htmlFor={id} className="h-full">
                    <div
                      className={`
                                ${
                                  tipoNegocioLocal === value
                                    ? 'border-success-dark shadow-md'
                                    : 'shadow-sm'
                                }
                                lg:flex border border-neutral-80 rounded px-4 py-2 items-center h-full cursor-pointer
                    `}
                    >
                      <div className="basis-40 pr-4 transition-[width] ease-linear">
                        <img
                          src={
                            value === 'empresa' ? imgEmpresa : imgPersonaNatural
                          }
                          alt={label}
                        />
                      </div>

                      <div className="flex-1 flex-grow transition-opacity">
                        {tipoNegocioLocal !== value && (
                          <Heading type="h3">{label}</Heading>
                        )}

                        <div
                          className={`
                                     ${
                                       tipoNegocioLocal === value
                                         ? 'opacity-100'
                                         : 'opacity-0'
                                     }
                                      delay-200 ease-in duration-500
                                    `}
                        >
                          {tipoNegocioLocal === value && (
                            <>
                              <Heading type="h4">{label}</Heading>
                              <Text>{texto}</Text>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BusinessType;
