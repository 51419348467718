export function createData(tipoRSD, subtipoRSD, toneladasPorVolumen) {
  return { tipoRSD, subtipoRSD, toneladasPorVolumen };
}

export const rowsInfo = [
  createData('Aceites y grasas', 's/i', 's/i'),
  createData('Cenizas', 's/i', 's/i'),
  createData('Lodos', 's/i', 's/i'),
  createData('Metales', 's/i', 's/i'),
  createData('Neumáticos fuera de uso', 's/i', 's/i'),
  createData('Orgánico', 's/i', 's/i'),
  createData('Papel y cartón	Papel', '*', '0,09'),
  createData('Papel y cartón	Cartón', '*', '0,05'),
  createData('Plásticos	PVC', '*', '0,93'),
  createData('Construcción y demolición', 'Asfalto', '2,4'),
  createData('Construcción y demolición', 'Despuntes acero', '0,2'),
  createData('Construcción y demolición', 'Despuntes aluminio', '0,9'),
  createData('Construcción y demolición', 'Despuntes Madera', '0,3'),
  createData('Construcción y demolición', 'Embalajes (general)', '0,2'),
  createData('Construcción y demolición', 'Escombros mixtos', '0,3'),
  createData(
    'Construcción y demolición',
    'Excavación: tierra y piedras',
    '1,3'
  ),
  createData(
    'Construcción y demolición',
    'Inerte: concreto, ladrillos, baldosas',
    '1,2'
  ),
  createData('Construcción y demolición', 'Paneles yeso cartón', '0,3'),
  createData('Eléctricos y electrónicos', 's/i', 's/i'),
  createData('Textiles', 's/i', 's/i'),
  createData('Vidrios', 's/i', '0,3')
];

export const frecuencia_options = [
  { value: 'diario', label: 'Diario' },
  { value: 'semanal', label: 'Semanal' },
  { value: 'mensual', label: 'Mensual' },
  { value: 'solo una vez', label: 'Solo una vez' }
];
