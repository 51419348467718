import React, { useState, useEffect, useContext } from 'react';
import iconHorasDeTrabajo from 'assets/images/reportes/horas-de-trabajo.svg';
import iconParidadMujeres from 'assets/images/reportes/paridad-mujeres.svg';
import iconReduccionCambioClimatico from 'assets/images/reportes/reduccion-cambio-climatico.svg';
import iconReduccionDemandaAcumulada from 'assets/images/reportes/reduccion-demanda-acumulada.svg';
import iconReduccionFormacionMaterial from 'assets/images/reportes/reduccion-formacion-material.svg';
import iconReduccionRecursoFosil from 'assets/images/reportes/reduccion-recurso-fosil.svg';
import iconResiduosValorizado from 'assets/images/reportes/residuos-valorizado.svg';
import iconSalario from 'assets/images/reportes/salario.svg';
import iconTransaccionesRealizadas from 'assets/images/reportes/transacciones-realizadas.svg';
import iconUsuariosRegistrados from 'assets/images/reportes/usuarios-registrados.svg';
import Heading from 'components/design-system/Heading/Heading';
import Loading from '../../components/Loading/Loading';
import Text from 'components/design-system/Text/Text';
import iconHomeDemandas from 'assets/images/home/homedemandas.svg';
import iconHomeOfertas from 'assets/images/home/homeofertas.svg';
import store from 'states/store';
import { RUTAS } from 'const/Dictonary';
import { Indicador } from '../Indicadores/Reporte/Indicador';
import { Link } from 'react-router-dom';
import { PlayCircleOutline, NavigateNext } from '@mui/icons-material';
import ButtonComponent from 'components/design-system/Button/Button';

import imgHomeUser from 'assets/images/home/grafica-home-user.svg';
import { AuthContext } from 'context';
import { formatoANumeroIngles } from 'helpers/formateoNumeros';

import useServices from './hooks/useServices';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import jwt from 'jwt-decode';
  // decifrando jwt
  const decifrarToken = token => {
    const decifrado = jwt(token);
    return decifrado;
  };
const videoTutorialesURL =
  'https://plataforma-industria-circular.cl/tutoriales/';

const HomeSitio = (props) => {
  const { logged } = useContext(AuthContext);
  const location = useLocation();
  const history = useHistory();

  const [verificaUsuarioNoRegistrado , setVerificaUsuarioNoRegistrado] = useState(localStorage.getItem('verificaUsuario') || false);
  
  console.log(verificaUsuarioNoRegistrado);
  // Si el usuario no está logueado y recibe un token, redirigir a la página de registro
  if(location.search !== '' && !logged && location.search.includes('token') && verificaUsuarioNoRegistrado){
    const tokenCompleto = location.search;
    const token = location.search.split('=')[1];
    const tokenDecifrado = (decifrarToken(tokenCompleto));
    const {
      correoElectronico,
      codigoSucursal
    } = tokenDecifrado;
    history.push(`${RUTAS.REGISTRARSE}?token=${token}`, {
      token: token,
      correoElectronico: correoElectronico,
      codigoSucursal: codigoSucursal
    });
  }
  
  // TODO: salario momentaneo en construcción
  const [salarioEnConstruccion, setSalarioEnConstruccion] = useState(true);

  const [indicadores, setIndicadores] = useState([
    {
      heading: null,
      items: [
        {
          image: iconUsuariosRegistrados,
          imageAlt: 'Usuarios registrados',
          heading: <Text>Usuarios registrados</Text>,
          description: 'usuarios',
          valor: '-',
          element: null
        },
        {
          image: iconTransaccionesRealizadas,
          imageAlt: 'Transacciones realizadas',
          heading: <Text>Transacciones realizadas</Text>,
          description: 'Transacciones',
          valor: '-',
          element: null
        },
        {
          image: iconResiduosValorizado,
          imageAlt: 'Residuos valorizados',
          heading: <Text>Residuos valorizado</Text>,
          description: 'Ton',
          valor: '-',
          element: null
        }
      ]
    },

    {
      heading: 'Reducción impactos ambientales',
      items: [
        {
          image: iconReduccionCambioClimatico,
          imageAlt: 'Reduccion Recurso Fosil',
          heading: (
            <Text>
              Cambio
              <br />
              climático
            </Text>
          ),
          description: 'kg CO2eq',
          valor: '-',
          element: null
        },
        {
          image: iconReduccionRecursoFosil,
          imageAlt: 'Reduccion Recurso Fosil',
          heading: (
            <Text>
              Agotamiento de
              <br />
              recurso fósil
            </Text>
          ),
          description: 'kg petróleo eq',
          valor: '-',
          element: null
        },
        {
          image: iconReduccionFormacionMaterial,
          imageAlt: 'Reduccion Formacion Material',
          heading: (
            <Text>
              Formación de
              <br />
              material particulado
            </Text>
          ),
          description: 'kgMP 2,5eq',
          valor: '-',
          element: null
        },
        {
          image: iconReduccionDemandaAcumulada,
          imageAlt: 'Reduccion Demanda Acumulada',
          heading: (
            <Text>
              Demanda acumulada
              <br />
              de energía
            </Text>
          ),
          description: 'MJ eq',
          valor: '-',
          element: null
        }
      ]
    },

    {
      heading: 'Indicadores Sociales',
      items: [
        {
          image: iconHorasDeTrabajo,
          imageAlt: 'Horas de trabajo',
          heading: <Text>Horas de trabajo</Text>,
          description: 'H totales',
          valor: '-',
          element: null
        },
        {
          image: iconParidadMujeres,
          imageAlt: 'Paridad Mujeres',
          heading: <Text>Paridad Mujeres</Text>,
          description: '%',
          valor: '-',
          element: null
        },
        {
          image: iconSalario,
          imageAlt: 'Salario',
          heading: <Text>Salario</Text>,
          description: 'Mil pesos',
          valor: '-',
          element: null
        }
      ]
    }
  ]);

  // Refactorización de carga de indicadores
  const {
    cargaUsuarios,
    cargaTransacciones,
    cargaResiduos,
    cargaReduccion,
    cargaIndicadoresSociales,
    cargaResumenIndicadores,
    isLoadingUsuarios,
    isLoadingTransacciones,
    isLoadingResiduos,
    isLoadingReduccion,
    isLoadingSocial,
    isLoadingResumen,
    dataUsuario,
    dataTransaccion,
    dataResiduo,
    dataReduccion,
    dataSocial,
    dataResumen
  } = useServices();

  useEffect(() => {
    cargaUsuarios();
    cargaTransacciones();
    cargaResiduos();
    cargaReduccion();
    cargaIndicadoresSociales();
    cargaResumenIndicadores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIndicadores([
      {
        heading: null,
        items: [
          {
            image: iconUsuariosRegistrados,
            imageAlt: 'Usuarios registrados',
            heading: <Text>Usuarios registrados</Text>,
            description: 'usuarios',
            valor: '-',
            loading: isLoadingUsuarios,
            element: dataUsuario?.usuario
              ? formatoANumeroIngles(dataUsuario?.usuario.toString())
              : dataUsuario?.usuario
          },
          {
            image: iconTransaccionesRealizadas,
            imageAlt: 'Transacciones realizadas',
            heading: <Text>Transacciones realizadas</Text>,
            description: 'Transacciones',
            valor: '-',
            loading: isLoadingTransacciones,
            element: dataTransaccion?.transaccion
              ? formatoANumeroIngles(dataTransaccion?.transaccion.toString())
              : dataTransaccion?.transaccion
          },
          {
            image: iconResiduosValorizado,
            imageAlt: 'Residuos valorizado',
            heading: <Text>Residuos valorizados</Text>,
            description: 'Ton',
            valor: '-',
            loading: isLoadingResiduos,
            element: dataResiduo?.residuo?.valorizado
              ? formatoANumeroIngles(
                  dataResiduo?.residuo?.valorizado.toString()
                )
              : dataResiduo?.residuo?.valorizado
          }
        ]
      },

      {
        heading: 'Reducción impactos ambientales',
        items: [
          {
            image: iconReduccionCambioClimatico,
            imageAlt: 'Reduccion Recurso Fosil',
            heading: (
              <Text>
                Cambio
                <br />
                climático
              </Text>
            ),
            description: 'kg CO2eq',
            valor: '-',
            loading: isLoadingReduccion,
            element: dataReduccion?.reduccion?.reduccionCambioClimatico
              ? formatoANumeroIngles(
                  dataReduccion?.reduccion?.reduccionCambioClimatico.toString()
                )
              : dataReduccion?.reduccion?.reduccionCambioClimatico
          },
          {
            image: iconReduccionRecursoFosil,
            imageAlt: 'Reduccion Recurso Fosil',
            heading: (
              <Text>
                Agotamiento de
                <br />
                recurso fósil
              </Text>
            ),
            description: 'kg petróleo eq',
            valor: '-',
            loading: isLoadingReduccion,
            element: dataReduccion?.reduccion?.reduccionRecursoFosil
              ? formatoANumeroIngles(
                  dataReduccion?.reduccion?.reduccionRecursoFosil.toString()
                )
              : dataReduccion?.reduccion?.reduccionRecursoFosil
          },
          {
            image: iconReduccionFormacionMaterial,
            imageAlt: 'Reduccion Formacion Material',
            heading: (
              <Text>
                Formación de
                <br />
                material particulado
              </Text>
            ),
            description: 'kgMP 2,5eq',
            valor: '-',
            loading: isLoadingReduccion,
            element: dataReduccion?.reduccion?.reduccionMaterialParticulado
              ? formatoANumeroIngles(
                  dataReduccion?.reduccion?.reduccionMaterialParticulado.toString()
                )
              : dataReduccion?.reduccion?.reduccionMaterialParticulado
          },
          {
            image: iconReduccionDemandaAcumulada,
            imageAlt: 'Reduccion Demanda Acumulada',
            heading: (
              <Text>
                Demanda acumulada
                <br />
                de energía
              </Text>
            ),
            description: 'MJ eq',
            valor: '-',
            loading: isLoadingReduccion,
            element: dataReduccion?.reduccion?.reduccionDemandaAcumulada
              ? formatoANumeroIngles(
                  dataReduccion?.reduccion?.reduccionDemandaAcumulada.toString()
                )
              : dataReduccion?.reduccion?.reduccionDemandaAcumulada
          }
        ]
      },

      {
        heading: 'Indicadores Sociales',
        items: [
          {
            image: iconHorasDeTrabajo,
            imageAlt: 'Horas de trabajo',
            heading: <Text>Horas de trabajo</Text>,
            description: 'H totales',
            valor: '-',
            loading: isLoadingSocial,
            element: dataSocial?.social?.indicador?.horasTrabajo
              ? formatoANumeroIngles(
                  dataSocial?.social?.indicador?.horasTrabajo.toString()
                )
              : dataSocial?.social?.indicador?.horasTrabajo
          },
          {
            image: iconParidadMujeres,
            imageAlt: 'Paridad Mujeres',
            heading: <Text>Paridad Mujeres</Text>,
            description: '%',
            valor: '-',
            loading: isLoadingSocial,
            element: dataSocial?.social?.indicador.horasTrabajoMujer
              ? formatoANumeroIngles(
                  dataSocial?.social?.indicador.horasTrabajoMujer.toString()
                )
              : dataSocial?.social?.indicador.horasTrabajoMujer
          },
          {
            image: iconSalario,
            imageAlt: 'Salario',
            heading: <Text>Salario</Text>,
            description: salarioEnConstruccion ? '' : 'Mil pesos',
            valor: '-',
            loading: salarioEnConstruccion ? false : salarioEnConstruccion,
            element: salarioEnConstruccion
              ? 'En construcción'
              : dataSocial?.social?.indicador.salario
              ? formatoANumeroIngles(
                  dataSocial?.social?.indicador.salario.toString()
                )
              : dataSocial?.social?.indicador.salario
          }
        ]
      }
    ]);

    // Almacenar indicadores en localStorage
    localStorage.setItem(
      'indicadores',
      JSON.stringify({
        usuario: dataUsuario?.usuario,
        transaccion: dataTransaccion?.transaccion,
        residuo: dataResiduo?.residuo?.valorizado,
        reduccionCambioClimatico:
          dataReduccion?.reduccion?.reduccionCambioClimatico,
        reduccionRecursoFosil: dataReduccion?.reduccion?.reduccionRecursoFosil,
        reduccionMaterialParticulado:
          dataReduccion?.reduccion?.reduccionMaterialParticulado,
        reduccionDemandaAcumulada:
          dataReduccion?.reduccion?.reduccionDemandaAcumulada,
        horasTrabajo: dataSocial?.social?.indicador?.horasTrabajo,
        horasTrabajoMujer: dataSocial?.social?.indicador.horasTrabajoMujer,
        salario: dataSocial?.social?.indicador.salario,
        demanda: dataResumen?.resumen?.demanda,
        oferta: dataResumen?.resumen?.oferta
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingUsuarios,
    isLoadingTransacciones,
    isLoadingResiduos,
    isLoadingReduccion,
    isLoadingSocial,
    isLoadingResumen
  ]);

  useEffect(() => {
    // eliminar localStorage de indicadores cada 2 horas
    const interval = setInterval(() => {
      localStorage.removeItem('indicadores');
    }, 7200000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full bg-gradient-to-b from-[#EFF5F4] to-white mb-3 pt-3">
      <div className="px-4 md:container md:mx-auto">
        <div className="bg-white shadow-md rounded mb-5 mt-4">
          <div className="grid grid-cols-12 items-center">
            <div className="col-span-12 md:col-span-6 lg:col-span-4 p-5 md:py-10 md:px-12 bg-uv-primary-0">
              {!logged ? (
                <Heading type="h1" className="text-white mb-6 font-light">
                  Únete a la
                  <br />
                  <b className="font-semibold">Industria Circular</b>
                </Heading>
              ) : (
                <>
                  <Heading type="h1" className="text-white mb-6 font-light">
                    ¡Bienvenido/a
                    <br />
                    {store.loguedUser.logeduser.nombreUsuario ||
                      'Nombre de usuario no detectado'}
                    <br />a la plataforma de{' '}
                    <b className="font-semibold">Industria Circular</b>!
                  </Heading>
                  <Text
                    className="text-white opacity-80 tracking-wider font-normal"
                    size="L"
                  >
                    El Market Place permite conectar oportunidades de negocio
                    entre organizaciones para la reutilización y reciclado de
                    materias y residuos.
                  </Text>
                </>
              )}

              {!logged ? (
                <>
                  <div>
                    <Link to={RUTAS.REGISTRARSE}>
                      <ButtonComponent type="primary" className="mb-4">
                        Registro <NavigateNext />
                      </ButtonComponent>
                    </Link>
                  </div>
                  <div>
                    <a
                      href={videoTutorialesURL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ButtonComponent
                        type="ghost"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        className="text-white hover:text-uv-primary-0 !bg-uv-primary-20"
                      >
                        Ver tutoriales{' '}
                        <PlayCircleOutline style={{ marginLeft: '10px' }} />
                      </ButtonComponent>
                    </a>
                  </div>
                </>
              ) : null}
            </div>
            <div
              className="hidden md:block md:col-span-6 lg:col-span-8 h-full bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${imgHomeUser})` }}
            ></div>
          </div>
        </div>

        <div className="">
          {indicadores.map((section, i) => (
            <div key={i} className="bg-white shadow-md rounded p-5 mb-5">
              <div className="flex flex-wrap justify-center">
                {section.heading && (
                  <div className="w-full border-b mb-4">
                    <Heading type="h4">{section.heading}</Heading>
                  </div>
                )}

                {section.items.map((item, index) => (
                  <Indicador
                    heading={item.heading}
                    image={item.image}
                    imageAlt={item.imageAlt}
                    element={item.element}
                    description={item.description}
                    isLoading={item.loading}
                    key={index}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="bg-white shadow-md rounded p-5 mb-5">
          <div className="grid grid-cols-12 gap-4 items-center">
            <div className="col-span-12 md:col-span-3">
              <img src={iconHomeOfertas} alt="Ofertas recientes" />
              <Heading type="h4" className="mb-0">
                Ofertas
                <br />
                más recientes
              </Heading>
            </div>
            <div className="col-span-12 md:col-span-9">
              <ul>
                {!isLoadingResumen ? (
                  dataResumen?.resumen?.oferta?.map((oferta, i) => (
                    <li key={i} className="d-flex items-center my-3">
                      <div className="w-2 h-2 rounded-full bg-uv-secondary-0 mr-2"></div>
                      <Text className="text-sm font-normal text-black">
                        {oferta}
                      </Text>
                    </li>
                  ))
                ) : (
                  <Loading
                    classWrapper="wrapperLoadingIndicadores"
                    classChase="sk-chaseIndicadores"
                  />
                )}
              </ul>
            </div>
          </div>
        </div>

        <div className="bg-white shadow-md rounded p-5 mb-5">
          <div className="grid grid-cols-12 gap-4 items-center">
            <div className="col-span-12 md:col-span-3">
              <img src={iconHomeDemandas} alt="Ofertas recientes" />
              <Heading type="h4" className="mb-0">
                Demandas
                <br />
                más recientes
              </Heading>
            </div>
            <div className="col-span-12 md:col-span-9">
              <ul>
                {!isLoadingResumen ? (
                  dataResumen?.resumen?.demanda?.map((demanda, i) => (
                    <li key={i} className="d-flex items-center my-3">
                      <div className="w-2 h-2 rounded-full bg-uv-secondary-0 mr-2"></div>
                      <Text className="text-sm font-normal text-black">
                        {demanda}
                      </Text>
                    </li>
                  ))
                ) : (
                  <Loading
                    classWrapper="wrapperLoadingIndicadores"
                    classChase="sk-chaseIndicadores"
                  />
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSitio;
