import imgMarkerDemandante from 'assets/images/smp/markerDemandante.svg';
import imgMarkerOferente from 'assets/images/smp/markerOferente.svg';
import imgMarkerAmbos from 'assets/images/smp/markerAmbos.svg';
import imgShadow from 'assets/images/smp/marker-shadow.png';
import imgMarkerTransportista from 'assets/images/smp/markerTransportista.svg';
import imgMarkerGestor from 'assets/images/smp/markerGestor.svg';
import imgMarkerAsesor from 'assets/images/smp/markerAsesor.svg';
import imgMarkerEmpresaDefault from 'assets/images/smp/marcadorEmpresa.svg';
import imgMarkerUsuario from 'assets/images/smp/marcadorUsuario.svg';
import L from 'leaflet';
import { DICTONARY } from 'const/Dictonary';

export const useMapaMatch = () => {
  const titlesurl = DICTONARY.MAPA.TITLE_URL;
  const attribution = DICTONARY.MAPA.ATTRIBUTION;

  const markerDemandante = new L.Icon({
    iconUrl: imgMarkerDemandante,
    iconRetinaUrl: imgMarkerDemandante,
    iconAnchor: [20, 40],
    popupAnchor: [0, -35],
    iconSize: [50, 50],
    shadowUrl: imgShadow,
    shadowSize: [29, 40],
    shadowAnchor: [7, 40]
  });

  const markerOferente = new L.Icon({
    iconUrl: imgMarkerOferente,
    iconRetinaUrl: imgMarkerOferente,
    iconAnchor: [20, 40],
    popupAnchor: [0, -35],
    iconSize: [50, 50],
    shadowUrl: imgShadow,
    shadowSize: [29, 40],
    shadowAnchor: [7, 40]
  });

  const markerDemandanteOferente = new L.Icon({
    iconUrl: imgMarkerAmbos,
    iconRetinaUrl: imgMarkerAmbos,
    iconAnchor: [20, 40],
    popupAnchor: [0, -35],
    iconSize: [50, 50],
    shadowUrl: imgShadow,
    shadowSize: [29, 40],
    shadowAnchor: [7, 40]
  });

  const markerTransportista = new L.Icon({
    iconUrl: imgMarkerTransportista,
    iconRetinaUrl: imgMarkerTransportista,
    iconAnchor: [20, 40],
    popupAnchor: [0, -35],
    iconSize: [50, 50],
    shadowUrl: imgShadow,
    shadowSize: [29, 40],
    shadowAnchor: [7, 40]
  });

  const markerGestor = new L.Icon({
    iconUrl: imgMarkerGestor,
    iconRetinaUrl: imgMarkerGestor,
    iconAnchor: [20, 40],
    popupAnchor: [0, -35],
    iconSize: [50, 50],
    shadowUrl: imgShadow,
    shadowSize: [29, 40],
    shadowAnchor: [7, 40]
  });

  const markerAsesor = new L.Icon({
    iconUrl: imgMarkerAsesor,
    iconRetinaUrl: imgMarkerAsesor,
    iconAnchor: [20, 40],
    popupAnchor: [0, -35],
    iconSize: [50, 50],
    shadowUrl: imgShadow,
    shadowSize: [29, 40],
    shadowAnchor: [7, 40]
  });

  const markerDefault = new L.Icon({
    iconUrl: imgMarkerEmpresaDefault,
    iconRetinaUrl: imgMarkerEmpresaDefault,
    iconAnchor: [50, 50],
    popupAnchor: [0, -35],
    iconSize: [50, 50],
    shadowUrl: imgShadow,
    shadowSize: [50, 50],
    shadowAnchor: [40, 50]
  });

  const markerUsuario = new L.Icon({
    iconUrl: imgMarkerUsuario,
    iconRetinaUrl: imgMarkerUsuario,
    iconAnchor: [50, 50],
    popupAnchor: [0, -35],
    iconSize: [50, 50],
    shadowUrl: imgShadow,
    shadowSize: [50, 50],
    shadowAnchor: [40, 50]
  });

  const getMapIcon = role => {
    if (role) {
      const arrayRole = role.map(item => item.codigoRol);

      if (
        arrayRole.includes(DICTONARY.ROL.GENERADOR.CODIGO) &&
        arrayRole.includes(DICTONARY.ROL.RECEPTOR.CODIGO)
      ) {
        return markerDemandanteOferente;
      } else if (arrayRole.includes(DICTONARY.ROL.GENERADOR.CODIGO)) {
        return markerOferente;
      } else if (arrayRole.includes(DICTONARY.ROL.RECEPTOR.CODIGO)) {
        return markerDemandante;
      } else if (arrayRole.includes(DICTONARY.ROL.TRANSPORTISTA.CODIGO)) {
        return markerTransportista;
      } else if (arrayRole.includes(DICTONARY.ROL.GESTOR.CODIGO)) {
        return markerGestor;
      } else if (arrayRole.includes(DICTONARY.ROL.ASESOR.CODIGO)) {
        return markerAsesor;
      } else {
        return markerDefault;
      }
    }
    return markerDefault;
  };

  return {
    getMapIcon,
    titlesurl,
    attribution,
    markerUsuario
  };
};
