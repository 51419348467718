import Sidebar from './Sidebar';

export const SidebarLayout = ({ children }) => {
  return (
    <section className="w-full bg-gradient-to-b from-[#EFF5F4] to-white">
      <div className="px-4 md:container md:mx-auto">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-2 hidden lg:block">
            <Sidebar />
          </div>
          <div className="col-span-12 lg:col-span-10 pt-10">{children}</div>
        </div>
      </div>
    </section>
  );
};
