import AlertComponent from 'components/design-system/Alert/Alert';
import CircleIcon from '@mui/icons-material/Circle';
import Heading from 'components/design-system/Heading/Heading';
import IconComponent from 'components/design-system/icon/Icon';
import Text from 'components/design-system/Text/Text';

export const Info = props => {
  return (
    <AlertComponent type="warning" className=" rounded">
      <Heading className="text-warning-dark" type="h4">
        Atención
      </Heading>

      <Text>
        Para no tener problemas con la carga{' '}
        <strong>debes cumplir con las siguientes condiciones</strong>
      </Text>
      <Text>
        <ul className="mt-6">
          <li className="d-flex mb-1 ">
            <IconComponent className="mr-2">
              <CircleIcon className="text-sm " />
            </IconComponent>
            <Text>
              Al editar el documento debes{' '}
              <strong>respetar el orden de las columnas y encabezados</strong>
            </Text>
          </li>
          <li className="d-flex mb-1">
            <IconComponent className="mr-2">
              <CircleIcon className="text-sm " />
            </IconComponent>
            <Text>
              El documento debe estar en{' '}
              <strong>formarto .txt (documento de texto)</strong>
            </Text>
          </li>
          <li className="d-flex mb-1 ">
            <IconComponent className="mr-2">
              <CircleIcon className="text-sm " />
            </IconComponent>
            <Text>
              El documento no puede pesar más de <strong>100 mb</strong>
            </Text>
          </li>
        </ul>
      </Text>
    </AlertComponent>
  );
};
