import React, { useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import { formatNumber } from 'utils';
import { withRouter, Link as RouterLink } from 'react-router-dom';

import {
  ArrowBack,
  HelpOutline,
  PlaceRounded,
  ChevronRight,
  ErrorOutline
} from '@mui/icons-material';

// import iconCheckInCircle from 'assets/images/checkInCircle.svg';
import iconResiduoValorizado from 'assets/images/reportes/icon-residuo-valorizado.svg';

import Tooltip from '@mui/material/Tooltip';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import { RoleComponent } from 'components/design-system/Role/Role';
import LinkComponent from 'components/design-system/Link/Link';
import Loading from 'components/Loading/Loading';

import { ObtieneUltimaTransaccion } from './utils/ObtieneUltimaTransaccion';
import { ServicioResumenTransaccion } from './hooks';
import {
  FormateaInfoResumen,
  ObtieneSucursalesTransaccion
} from './utils/FormateaInfoResumen';
import { DICTONARY, RUTAS } from 'const/Dictonary';

const Resumen = props => {
  const { location } = props;

  const [
    codigoTransaccion
    // setCodigoTransaccion
  ] = useState(location.state.codigoTransaccion);
  const [tipoTransaccion, setTipoTransaccion] = useState();

  const [estadoTr, setEstadoTr] = useState({});
  const [cantidad, setCantidad] = useState(0);
  const [formatoEntrega, setFormatoEntrega] = useState('');
  const [materiaSeleccionada, setMateriaSeleccionada] = useState('');
  const [nombreEmpresa, setNombreEmpresa] = useState('');
  const [nombreTipoTransporte, setNombreTipoTransporte] = useState('');
  const [nombreTipoTratamiento, setNombreTipoTratamiento] = useState('');
  const [fechaTransaccion, setFechaTrasacción] = useState('');
  const [sucursalAsesor, setSucursalAsesor] = useState();
  const [sucursalDemandante, setSucursalDemandante] = useState();
  const [sucursalGestor, setSucursalGestor] = useState();
  const [sucursalOferente, setSucursalOferente] = useState();
  const [sucursalTransportista, setSucursalTransportista] = useState();
  const [sucursalTratamiento, setSucursalTratamiento] = useState();

  // Mensajes a mostrar
  const [finalizadoOCerradoText, setFinalizadoOCerradoText] = useState('');
  const [aprobadoORechazadoText, setAprobadoORechazadoText] =
    useState('iniciado');

  const { transaccion, cargandoResumen } =
    ServicioResumenTransaccion(codigoTransaccion);

  useEffect(() => {
    if (!transaccion && cargandoResumen) return;

    const {
      cantidad,
      formatoEntrega,
      nombreTipo,
      nombreEmpresa,
      tipoTransporte,
      tipoTratamiento,
      sucursalTransaccion
    } = FormateaInfoResumen(transaccion);

    // asigno valores a nuevos estados
    setCantidad(cantidad);
    setFormatoEntrega(formatoEntrega);
    setMateriaSeleccionada(nombreTipo);
    setNombreEmpresa(nombreEmpresa);

    // sucursalTransaccion
    const {
      sucursalAsesor,
      sucursalDemandante,
      sucursalGestor,
      sucursalOferente,
      sucursalTransportista
    } = ObtieneSucursalesTransaccion(
      codigoTransaccion,
      sucursalTransaccion,
      transaccion
    );

    sucursalAsesor && setSucursalAsesor(sucursalAsesor?.sucursal);
    sucursalDemandante && setSucursalDemandante(sucursalDemandante?.sucursal);
    sucursalGestor && setSucursalGestor(sucursalGestor?.sucursal);
    sucursalOferente && setSucursalOferente(sucursalOferente?.sucursal);
    sucursalTransportista &&
      setSucursalTransportista(sucursalTransportista?.sucursal);

    // tipoTransporte
    setNombreTipoTransporte(tipoTransporte?.nombreTipoTransporte);

    // tipoTratamiento
    setSucursalTratamiento(
      transaccion.sucursalTransaccion.find(
        sucursal => sucursal.ejecutoTratamiento
      )
    );
    setNombreTipoTratamiento(tipoTratamiento?.nombreTipoTratamiento);

    // Sucursales fechaRecepción
    setFechaTrasacción(transaccion.fechaRecepcion || 'N/A');

    setEstadoTr(
      ObtieneUltimaTransaccion(codigoTransaccion, transaccion.transaccionEstado)
    );
  }, [cargandoResumen, codigoTransaccion, transaccion]);

  const [msgDatosCierre, setMsgDatosCierre] = useState(null);

  // PARA DARLE EL FORMATO CORRECTO A LA FECHA
  const dateFns = new DateFnsUtils();

  // OBTENER DATOS DE LA TRANSACCION, INDICADORES, RSD
  // Y MENSAJES A MOSTRAR EN BASE AL ESTADO DE LA TRANSACCIÓN
  useEffect(() => {
    // if (!props.location.state) return;

    try {
      if (estadoTr.nombreEstado === 'Finalizado No Concretado') {
        setMsgDatosCierre('Transacción rechazada, información no ingresada.');
      } else if (estadoTr.nombreEstado === 'Cerrada Oferta Concretada') {
        setMsgDatosCierre(
          'A la espera del datos por parte del Demandante de la transacción'
        );
      } else {
        setMsgDatosCierre(null);
      }

      if (estadoTr.nombreEstado === 'Iniciada') {
        setAprobadoORechazadoText('iniciado');
      }

      if (
        estadoTr.nombreEstado === 'Cerrada Demanda Concretada' ||
        estadoTr.nombreEstado === 'Finalizado Concretado' ||
        estadoTr.nombreEstado === 'Cerrada Oferta Concretada'
      ) {
        // LA TRANSACCIÓN FUE APROBADA
        setAprobadoORechazadoText('aprobado');
      } else if (
        estadoTr.nombreEstado === 'Cerrada Demanda No Concretada' ||
        estadoTr.nombreEstado === 'Finalizado No Concretado' ||
        estadoTr.nombreEstado === 'Cerrada Oferta No Concretada'
      ) {
        // LA TRANSACCIÓN FUE RECHAZADA
        setAprobadoORechazadoText('rechazado');
      }

      if (
        estadoTr.nombreEstado === 'Cerrada Demanda Concretada' ||
        estadoTr.nombreEstado === 'Cerrada Oferta Concretada' ||
        estadoTr.nombreEstado === 'Cerrada Demanda No Concretada' ||
        estadoTr.nombreEstado === 'Cerrada Oferta No Concretada'
      ) {
        // LA TRANSACCIÓN FUE CERRADA
        setFinalizadoOCerradoText('Cerrada');
      } else if (
        estadoTr.nombreEstado === 'Finalizado Concretado' ||
        estadoTr.nombreEstado === 'Finalizado No Concretado'
      ) {
        // LA TRANSACCIÓN FUE FINALIZADA
        setFinalizadoOCerradoText('Finalizada');
      } else if (estadoTr.nombreEstado === 'Iniciada') {
        setFinalizadoOCerradoText('Iniciada');
      }
    } catch (err) {
      console.log('err DETERMINAR MENSAJES A MONSTRAR :>> ', err);
      // storeMessages.message.setMessage({
      //   mensaje: 'Ha ocurrido un error al cargar los datos.',
      //   tipo: 'error'
      // });
    }
  }, [estadoTr, tipoTransaccion]);

  var getFormatedDate = strDate => {
    if (dateFns.isValid(strDate)) {
      const [date] = strDate.split(' ');
      const tdate = dateFns.parse(date, 'yyyy-MM-dd');
      return dateFns.format(tdate, 'dd/MM/yyyy');
    } else {
      return strDate;
    }
  };

  const estilosEstadoTransaccion = {
    aprobado: {
      FONDO: 'bg-uv-primary-80',
      TEXTO: 'text-success-dark',
      ICONO: DICTONARY.ESTADOS_TRANSACCION['FINALIZADO CONCRETADO'].ICONO
    },
    rechazado: {
      FONDO: 'bg-red-200',
      TEXTO: 'text-error-dark',
      ICONO: DICTONARY.ESTADOS_TRANSACCION['FINALIZADO NO CONCRETADO'].ICONO
    },
    iniciado: {
      FONDO: 'bg-neutral-90',
      TEXTO: 'text-neutral-40',
      ICONO: DICTONARY.ESTADOS_TRANSACCION['INICIADA'].ICONO
    },
    null: {
      FONDO: 'bg-neutral-90',
      TEXTO: 'text-neutral-40',
      ICONO: DICTONARY.ESTADOS_TRANSACCION['INICIADA'].ICONO
    }
  };

  return (
    <>
      {cargandoResumen ? (
        <Loading />
      ) : (
        <div className="w-full">
          <div className="px-4 md:container md:mx-auto pt-5 pb-4">
            <div className="flex justify-center items-center">
              <div className="w-11/12">
                <div className="bg-white rounded shadow-md">
                  <div className="grid grid-cols-12 md:gap-5">
                    <div
                      className={`col-span-12 md:col-span-6 p-5 text-center
                      ${estilosEstadoTransaccion[aprobadoORechazadoText].FONDO}
                    `}
                    >
                      <div className="bg-white h-20 w-20 rounded-full mx-auto flex items-center mb-2">
                        <img
                          src={
                            estilosEstadoTransaccion[aprobadoORechazadoText]
                              .ICONO
                          }
                          alt="Resumen de la transacción"
                          className="mx-auto h-16 w-16 justify-center"
                        />
                      </div>
                      <Heading
                        type="h3"
                        className={`mb-0
                          ${estilosEstadoTransaccion[aprobadoORechazadoText].TEXTO}
                        `}
                      >
                        Transacción {finalizadoOCerradoText}
                      </Heading>
                      <Text
                        className={
                          estilosEstadoTransaccion[aprobadoORechazadoText].TEXTO
                        }
                      >
                        Se ha <strong>{aprobadoORechazadoText}</strong> la
                        transacción.
                      </Text>
                    </div>

                    <div className="col-span-12 md:col-span-6 p-5 text-center">
                      <Text type="label" className="!mb-6">
                        Actualización de indicador
                      </Text>
                      <Heading type="h3">Cantidad</Heading>

                      <div className="flex items-center justify-center">
                        <img
                          src={iconResiduoValorizado}
                          className="w-8 h-8"
                          alt="Cantidad"
                        />
                        <Heading type="H1" className="ml-3 mr-4 my-0">
                          {cantidad ? formatNumber(cantidad, '0') : 'N/A'}
                        </Heading>
                        <Text className="text-base leading-5 font-normal">
                          /Ton
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="px-4 md:container md:mx-auto pb-5">
            <div className="flex justify-center items-center">
              <div className="w-11/12">
                <div className="bg-white rounded shadow-md">
                  <div className="grid grid-cols-12">
                    <div className="col-span-12 p-5">
                      <Heading type="h4" className="mb-0">
                        Resumen de la transacción
                      </Heading>
                    </div>
                    <div className="col-span-12">
                      <Divider />
                    </div>
                    <div className="col-span-12">
                      <div className="grid grid-cols-12 gap-5">
                        <div className="col-span-12 md:col-span-3 p-5 md:pr-0">
                          <RoleComponent
                            verbo="nombre"
                            tipo="etiqueta"
                            rol="oferente"
                          />
                          {sucursalOferente &&
                          Object.keys(sucursalOferente).length > 0 ? (
                            <>
                              <Heading type="h3" className="pr-10">
                                {sucursalOferente.empresa.nombreEmpresa}
                              </Heading>
                              <Text type="label">
                                {sucursalOferente.nombreSucursal}
                              </Text>
                              <div className="flex items-center">
                                <PlaceRounded className="mr-2 text-uv-secondary-10" />
                                <Text>
                                  {`${sucursalOferente.direccion?.nombreVia}, ${sucursalOferente.direccion?.comuna?.nombreComuna}, Región ${sucursalOferente.direccion?.comuna?.region?.nombreRegion}`}
                                </Text>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="my-3">
                                <ErrorOutline className="w-10 h-10 text-neutral-70" />
                              </div>
                              <Heading type="h5">
                                No se ha encontrado el registro
                              </Heading>
                              <Text>
                                La sucursal ha sido eliminada de la plataforma
                              </Text>
                            </>
                          )}
                        </div>

                        <div className="col-span-12 md:col-span-6 px-5 md:px-0 py-5 relative">
                          {transaccion ? (
                            <>
                              <div className="grid grid-cols-12">
                                <ChevronRight className="text-3xl text-neutral-60 relative md:-ml-5 lg:-ml-2" />

                                <div className="col-span-10 pr-2">
                                  <Text type="label">
                                    Materia transaccionada
                                  </Text>
                                  <Heading
                                    type="h4"
                                    className="text-uv-primary-0"
                                  >
                                    {materiaSeleccionada
                                      ? materiaSeleccionada
                                      : 'N/A'}
                                  </Heading>

                                  <Text type="label">Formato de entrega</Text>
                                  <Text className="font-bold text-black">
                                    {formatoEntrega ? formatoEntrega : 'N/A'}
                                  </Text>

                                  <Divider className="my-4" />

                                  <Heading className="mb-3" type="h5">
                                    Datos del cierre de transacción{' '}
                                    <Tooltip
                                      style={{ position: 'absolute' }}
                                      title="Información agregada por la empresa demandante de la transacción, solo en el caso de dar por terminado y aprobada la transacción"
                                    >
                                      <HelpOutline fontSize="small" />
                                    </Tooltip>
                                  </Heading>

                                  {msgDatosCierre ? (
                                    <Text>{msgDatosCierre}</Text>
                                  ) : (
                                    <>
                                      <Text type="label">
                                        Empresa que realizó el tratamiento
                                      </Text>
                                      <Text className="mb-3 font-bold text-black">
                                        {sucursalTratamiento
                                          ? sucursalTratamiento.sucursal.empresa
                                              .nombreEmpresa
                                          : 'Sin registro'}
                                      </Text>

                                      <Text type="label">
                                        Rut del transportista
                                      </Text>
                                      <Text className="mb-3">
                                        {!transaccion.rutEmpresaTransportista ||
                                        transaccion.rutEmpresaTransportista ===
                                          '-'
                                          ? 'Sin registro'
                                          : transaccion.rutEmpresaTransportista}
                                      </Text>

                                      <Text type="label">Tipo transporte</Text>
                                      <Text className="mb-3">
                                        {!nombreTipoTransporte ||
                                        nombreTipoTransporte === '-'
                                          ? 'Sin registro'
                                          : nombreTipoTransporte}
                                      </Text>

                                      <Text type="label">Patente</Text>
                                      <Text className="mb-3">
                                        {!transaccion.patente ||
                                        transaccion.patente === '-'
                                          ? 'Sin registro'
                                          : transaccion.patente}
                                      </Text>

                                      <Text type="label">Tipo tratamiento</Text>
                                      <Text className="mb-3">
                                        {!nombreTipoTratamiento ||
                                        nombreTipoTratamiento === '-'
                                          ? 'Sin registro'
                                          : nombreTipoTratamiento}
                                      </Text>

                                      <Text type="label">
                                        Fecha de realización de transacción
                                      </Text>
                                      <Text className="mb-3">
                                        {!fechaTransaccion ||
                                        fechaTransaccion === '-'
                                          ? 'Sin registro'
                                          : getFormatedDate(fechaTransaccion)}
                                      </Text>
                                    </>
                                  )}
                                </div>

                                <ChevronRight className="text-3xl text-neutral-60 relative" />
                              </div>
                            </>
                          ) : null}
                        </div>

                        <div className="col-span-12 md:col-span-3 p-5 md:pl-0">
                          <RoleComponent
                            verbo="nombre"
                            tipo="etiqueta"
                            rol="demandante"
                          />
                          {sucursalDemandante &&
                          Object.keys(sucursalDemandante).length > 0 ? (
                            <>
                              <Heading type="h3" className="pr-10">
                                {sucursalDemandante.empresa.nombreEmpresa}
                              </Heading>
                              <Text type="label">
                                {sucursalDemandante.nombreSucursal}
                              </Text>
                              <div className="flex items-center">
                                <PlaceRounded className="mr-2 text-uv-secondary-10" />
                                <Text>
                                  {`${sucursalDemandante.direccion?.nombreVia}, ${sucursalDemandante.direccion?.comuna?.nombreComuna}, Región ${sucursalDemandante.direccion?.comuna?.region?.nombreRegion}`}
                                </Text>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="my-3">
                                <ErrorOutline className="w-10 h-10 text-neutral-70" />
                              </div>
                              <Heading type="h5">
                                No se ha encontrado el registro
                              </Heading>
                              <Text>
                                La sucursal ha sido eliminada de la plataforma
                              </Text>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="px-4 md:container md:mx-auto pb-5">
            <div className="flex justify-center items-center">
              <div className="w-11/12">
                <div className="bg-white rounded shadow-md">
                  <div className="grid grid-cols-12 p-5">
                    {sucursalGestor ? (
                      <div className="col-span-4">
                        <RoleComponent
                          verbo="nombre"
                          tipo="etiqueta"
                          rol="gestor"
                        />
                        {Object.keys(sucursalGestor).length > 0 ? (
                          <>
                            <Heading type="h3" className="pr-10">
                              {sucursalGestor.empresa.nombreEmpresa}
                            </Heading>
                            <Text type="label">
                              {sucursalGestor.nombreSucursal}
                            </Text>
                            <div className="flex items-center">
                              <PlaceRounded className="mr-2 text-uv-secondary-10" />
                              <Text>
                                {`${sucursalGestor.direccion?.nombreVia}, ${sucursalGestor.direccion?.comuna?.nombreComuna}, Región ${sucursalGestor.direccion?.comuna?.region?.nombreRegion}`}
                              </Text>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="my-3">
                              <ErrorOutline className="w-10 h-10 text-neutral-70" />
                            </div>
                            <Heading type="h5">
                              No se ha encontrado el registro
                            </Heading>
                            <Text>
                              La sucursal ha sido eliminada de la plataforma
                            </Text>
                          </>
                        )}
                      </div>
                    ) : null}

                    {sucursalAsesor ? (
                      <div className="col-span-4">
                        <RoleComponent
                          verbo="nombre"
                          tipo="etiqueta"
                          rol="asesor"
                        />
                        {Object.keys(sucursalAsesor).length > 0 ? (
                          <>
                            <Heading type="h3" className="pr-10">
                              {sucursalAsesor.empresa.nombreEmpresa}
                            </Heading>
                            <Text type="label">
                              {sucursalAsesor.nombreSucursal}
                            </Text>
                            <div className="flex items-center">
                              <PlaceRounded className="mr-2 text-uv-secondary-10" />
                              <Text>
                                {`${sucursalAsesor.direccion?.nombreVia}, ${sucursalAsesor.direccion?.comuna?.nombreComuna}, Región ${sucursalAsesor.direccion?.comuna?.region?.nombreRegion}`}
                              </Text>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="my-3">
                              <ErrorOutline className="w-10 h-10 text-neutral-70" />
                            </div>
                            <Heading type="h5">
                              No se ha encontrado el registro
                            </Heading>
                            <Text>
                              La sucursal ha sido eliminada de la plataforma
                            </Text>
                          </>
                        )}
                      </div>
                    ) : null}

                    {sucursalTransportista ? (
                      <div className="col-span-4">
                        <RoleComponent
                          verbo="nombre"
                          tipo="etiqueta"
                          rol="transportista"
                        />
                        {Object.keys(sucursalTransportista).length > 0 ? (
                          <>
                            <Heading type="h3" className="pr-10">
                              {sucursalTransportista.empresa.nombreEmpresa}
                            </Heading>
                            <Text type="label">
                              {sucursalTransportista.nombreSucursal}
                            </Text>
                            <div className="flex items-center">
                              <PlaceRounded className="mr-2 text-uv-secondary-10" />
                              <Text className="mb-3">
                                {`${sucursalTransportista.direccion?.nombreVia}, ${sucursalTransportista.direccion?.comuna?.nombreComuna}, Región ${sucursalTransportista.direccion?.comuna?.region?.nombreRegion}`}
                              </Text>
                            </div>

                            <Text type="label">Rut del transportista</Text>
                            <Text className="mb-3">
                              {!transaccion.rutEmpresaTransportista ||
                              transaccion.rutEmpresaTransportista === '-'
                                ? 'Sin registro'
                                : transaccion.rutEmpresaTransportista}
                            </Text>

                            <Text type="label">Patente</Text>
                            <Text className="mb-3">
                              {!transaccion.patente ||
                              transaccion.patente === '-'
                                ? 'Sin registro'
                                : transaccion.patente}
                            </Text>
                          </>
                        ) : (
                          <>
                            <div className="my-3">
                              <ErrorOutline className="w-10 h-10 text-neutral-70" />
                            </div>
                            <Heading type="h5">
                              No se ha encontrado el registro
                            </Heading>
                            <Text>
                              La sucursal ha sido eliminada de la plataforma
                            </Text>
                          </>
                        )}
                      </div>
                    ) : null}

                    {!sucursalTransportista &&
                    !sucursalGestor &&
                    !sucursalAsesor ? (
                      <div className="col-span-12 text-center">
                        <Text>
                          En esta transacción no han participado otros roles.
                        </Text>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="px-4 md:container md:mx-auto pb-5">
            <div className="flex justify-center items-center">
              <div className="w-11/12">
                <LinkComponent
                  href={RUTAS.TRANSACCIONES}
                  type="button"
                  color="primary"
                >
                  <ArrowBack className="mr-1" /> Volver a transacciones
                </LinkComponent>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Resumen.propTypes = {
  history: PropTypes.object
};

export default withRouter(Resumen);
