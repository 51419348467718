import { useState, useEffect, useCallback } from 'react';
// Design System

import Dropzone from 'components/design-system/Dropzone/Dropzone';
import Heading from 'components/design-system/Heading/Heading';
import IconComponent from 'components/design-system/icon/Icon';
import LabelComponent from 'components/design-system/Label/Label';
import Text from 'components/design-system/Text/Text';
// Diccionarity
import { DICTONARY } from 'const/Dictonary';
// Material
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { FilethumbComponent } from 'components/design-system/Filethumb/Filethumb';

// Library

const DocumentosDeRespaldo = ({
  // Formik
  // errors,
  // handleBlur,
  // handleChange,
  // handleReset,
  // handleSubmit,
  isSubmitting,
  // setFieldError,
  // setFieldTouched,
  setFieldValue,
  // touched,
  // validateForm,
  values,

  // Dependencias
  tipoForm
}) => {
  const [archivos, setArchivos] = useState([]);
  const [archivosLocal, setArchivosLocal] = useState(values.archivos);
  const [eliminandoArchivo, setEliminandoArchivo] = useState(false);
  const [errorArchivos, setErrorArchivos] = useState(false);

  useEffect(() => {
    if (archivos && archivos.length > 0) {
      if (tipoForm.ingresar) {
        setFieldValue('archivos', archivos);
      } else if (tipoForm.editar || tipoForm.duplicar) {
        if (archivosLocal.length > 0) {
          setFieldValue('archivos', archivosLocal.concat(archivos));
        } else {
          setFieldValue('archivos', archivos);
        }
      }
    }
  }, [
    archivos,
    archivosLocal,
    setFieldValue,
    tipoForm.duplicar,
    tipoForm.editar,
    tipoForm.ingresar
  ]);

  const eliminarArchivo = useCallback(
    file => {
      const archivosFiltrados = archivosLocal.filter(
        archivo => archivo.codigoArchivo !== file.codigoArchivo
      );
      setArchivosLocal(archivosFiltrados);
      setFieldValue('archivos', archivosFiltrados);
      setEliminandoArchivo(true);
    },
    [archivosLocal, setFieldValue]
  );
  return (
    <div className="col-span-12">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-12">
          <Heading type="h4" className="mb-0">
            Documentos de respaldo
          </Heading>
        </div>
        <div className="col-span-12 xs:col-span-12">
          <div className="grid grid-cols-12 gap-4">
            {/* Documentos */}
            <div className="col-span-12 xs:col-span-12">
              <LabelComponent>
                Cargar documento de respaldo, Ej: Guía de despacho, factura u
                otro tipo de documento.{' '}
                <span className="text-neutral-60">(opcional)</span>
              </LabelComponent>
              <Dropzone
                name="archivos"
                //disabled={cargandoDocumentos}
                multiple={true}
                enableEditingFile={false}
                setDataFiles={setArchivos}
                dataFiles={archivos}
                disabled={isSubmitting}
                {...DICTONARY.DROPZONE.CONFIG_UPLOAD_RECURSOS_ADQUIRIDOS}
              >
                <Text className="text-black font-medium">
                  Puedes adjuntar los siguientes tipos de documentos
                </Text>
                <ul className="flex mt-1">
                  <li className="d-flex items-center mb-1">
                    <IconComponent className="mr-2">
                      <TaskAltIcon className="text-base text-uv-primary-20" />
                    </IconComponent>
                    <Text className="text-neutral-40">PDF y JPG</Text>
                  </li>
                  <li className="d-flex items-center mb-1 ml-6">
                    <IconComponent className="mr-2">
                      <TaskAltIcon className="text-base text-uv-primary-20" />
                    </IconComponent>
                    <Text className="text-neutral-40">
                      Tamaño no superior a 4 MB
                    </Text>
                  </li>
                </ul>
                {errorArchivos && (
                  <Text className="text-xs text-red-500">
                    Haz alcanzado el máximo de documentos permitidos. Si deseas
                    agregar más archivos, elimina alguno de los que ya has
                    cargado.
                  </Text>
                )}
              </Dropzone>
              {archivosLocal && archivosLocal.length > 0 && (
                <aside className="mt-5">
                  <Text className="mb-2">Archivos Guardados</Text>
                  <div className="rounded bg-background-secondary p-5">
                    <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                      {archivosLocal.map((file, key) => (
                        <FilethumbComponent
                          key={key}
                          disabled={isSubmitting}
                          file={{
                            preview: archivosLocal[key].base64,
                            type: archivosLocal[key].extension,
                            name: archivosLocal[key].nombreArchivo
                          }}
                          onRemove={() => {
                            eliminarArchivo(file);
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </aside>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentosDeRespaldo;
