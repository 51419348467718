import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Button, Typography, Container } from '@mui/material';
import { Create, Person } from '@mui/icons-material';
import PropTypes from 'prop-types';
import store from '../../states/store';
import { RUTAS } from 'const/Dictonary';

const modulos = {
  users: [
    {
      text: 'Ver usuarios',
      icon: <Person className="" />,
      link: '/empresa/usuarios'
    }
  ],

  rsd: [
    {
      text: 'Requerir Residuo',
      icon: <Create className="" />,
      link: RUTAS.RESIDUOS_DEMANDANTE_NUEVO,
      tipo: 'isDemandante'
    },
    {
      text: 'Ofertar Residuo',
      icon: <Create className="" />,
      link: RUTAS.RESIDUOS_OFERENTE_NUEVO,
      tipo: 'isOferente'
    }
  ],
  empresa: [
    {
      text: 'Sucursales',
      icon: <Create className="" />,
      link: '/empresa/Sucursales'
    }
  ],

  difusion: [
    {
      text: 'Residuo demandado',
      icon: <Create className="" />,
      link: RUTAS.RESIDUOS_DEMANDANTE_NUEVO
    },
    {
      text: 'Residuo ofertado',
      icon: <Create className="" />,
      link: RUTAS.RESIDUOS_OFERENTE_NUEVO
    },
    {
      text: 'Usuarios',
      icon: <Create className="" />,
      link: '/empresa/usuarios'
    }
  ]
};

const SubMenu = props => {
  const {
    childButtons,
    // className,
    description,
    history,
    icon,
    modulo,
    // onChange,
    // style,
    title,
    disabled
    // ...rest
  } = props;

  const [tipos, setTipos] = useState({
    isDemandante: false,
    isOferente: false
  });

  const forceRefresh = to => () => {
    if (history.location.pathname === to) {
      window.location.pathname = to;
    } else {
      history.push(to);
    }
  };

  useEffect(() => {
    if (!store.loguedUser.logeduser) {
      return;
    }
    setTipos(store.loguedUser.tipos);
  }, [store.loguedUser.logeduser, store.loguedUser.tipos]);

  return (
    <div className="sectitle admin-submenu">
      <Container maxWidth="lg">
        <Grid container direction="row" alignItems="center">
          <Grid item lg={6} md={6} sm={12} xs={12} className="admin-menu">
            <div className="subheading-title">
              <div className="subheading-icon">{icon}</div>
              <div className="subheading-content">
                <Typography variant="h2" className="pr-2">
                  <strong>{title}</strong>
                </Typography>
                <span>{description}</span>
              </div>
            </div>
          </Grid>

          {modulo &&
            modulos[modulo].map((item, i) => {
              const { tipo, ...rest } = item;
              if (!tipos[tipo]) {
                return null;
              }

              return tipo === 'isDemandante' || tipo === 'isOferente' ? (
                <Grid item xs key={i}>
                  <div className="admin-option align-right sm-mt-mb-1">
                    <Button
                      variant="contained"
                      className={`${
                        disabled
                          ? 'btn btn-secondary pointer-events-none '
                          : 'btn btn-primary'
                      }`}
                      disabled={disabled}
                      onClick={forceRefresh(rest.link)}
                    >
                      {rest.text} {rest.icon}
                    </Button>
                  </div>
                </Grid>
              ) : (
                <Grid item xs key={i}>
                  <div className="admin-option align-right">
                    <RouterLink to={rest.link}>
                      <Button variant="contained" className="btn btn-primary">
                        {rest.text} {rest.icon}
                      </Button>
                    </RouterLink>
                  </div>
                </Grid>
              );
            })}

          {childButtons ? childButtons : null}
        </Grid>
      </Container>
    </div>
  );
};

SubMenu.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  modulo: PropTypes.string,
  style: PropTypes.object
};

export default SubMenu;
