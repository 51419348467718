import { useCallback, useEffect, useState } from 'react';
import { DICTONARY } from 'const/Dictonary';
import analisisTerritorial from 'services/analisisTerritorial';
import storeMessages from 'states/alertMessages/store';

export const useTablaRETC = (
  refTablaRETC,
  setCargando,
  codigoEmpresaSeleccionada
) => {
  const [dataTablaRETC, setDataTablaRETC] = useState([]);
  const [totalRegistros, setTotalRegistros] = useState(1);
  const [pagination, setPagination] = useState(DICTONARY.PAGINACION.DEFAULT);

  const cargarDatosRETCEmpresaSeleccionada = useCallback(
    async codigoEmpresaAt => {
      if (!codigoEmpresaAt) {
        return setDataTablaRETC([]);
      }

      try {
        setCargando('tablaRETCargando', true);
        let requestParams = {
          numPagina: pagination.pageIndex,
          totalPagina: pagination.pageSize,
          codigoEmpresaATOrigen: codigoEmpresaAt
        };

        const response = await analisisTerritorial.analisisEmpresaRETC(
          requestParams,
          DICTONARY.INTERCEPTOR.DESACTIVADO
        );
        if (response.data.error) throw new Error('SIN_REGISTROS');
        if (response.status === 200) {
          if (response.empty) {
            setDataTablaRETC([]);
            setTotalRegistros(0);
            return;
          } else if (Array.isArray(response.data.content)) {
            setDataTablaRETC(response.data.content);
            setTotalRegistros(response.data.totalElements);
          }
        } else {
          setDataTablaRETC([]);
          setTotalRegistros(0);
        }
      } catch (error) {
        if (error.message === 'SIN_REGISTROS') {
          storeMessages.message.setMessage({
            mensaje: 'No existen registros para la solicitud realizada',
            tipo: 'info'
          });
        } else {
          storeMessages.message.setMessage({
            mensaje:
              'Por un error en el servicio no se ha obtenido la información para la tabla RETC',
            tipo: 'error'
          });
        }
        setDataTablaRETC([]);
        setTotalRegistros(0);
      } finally {
        setCargando('tablaRETCargando', false);
      }
    },
    [pagination.pageIndex, pagination.pageSize, refTablaRETC, setCargando]
  );

  useEffect(() => {
    cargarDatosRETCEmpresaSeleccionada(codigoEmpresaSeleccionada);
  }, [codigoEmpresaSeleccionada, pagination.pageIndex, pagination.pageSize]);

  return {
    dataTablaRETC,
    cargarDatosRETCEmpresaSeleccionada,
    pagination,
    setPagination,
    totalRegistros
  };
};
