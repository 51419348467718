import store from 'states/store';
import API from './api';

const recursosServices = {
  recursos: function (params, conInterceptor = true) {
    return API.axios({
      method: 'GET',
      url: rutas.recursos,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: params,
      interceptor: conInterceptor
    });
  },
  // Obtiene una lista de recursos adquiridos
  obtenerListaRecursosAdquiridos: function (params, conInterceptor = true) {
    return API.axios({
      method: 'GET',
      url: rutas.recursosAdquiridos,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: params,
      interceptor: conInterceptor
    });
  },
  // Obtiene un recurso adquirido
  obtieneRecursoAdquirido: function (codigoRecursoAdquirido, conInterceptor = true) {
    return API.axios({
      method: 'GET',
      url: `${rutas.recursosAdquiridos}/${codigoRecursoAdquirido}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },
  // Crea un recurso adquirido
  crearRecursoAdquirido: function (data, conInterceptor = true) {
    return API.axios({
      method: 'POST',
      url: rutas.recursosAdquiridos,
      data: data,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },
 // Actualiza un recurso adquirido
  actualizaRecursoAdquirido: function (codigoRecursoAdquirido, data, conInterceptor = true) {
    return API.axios({
      method: 'PUT',
      url: `${rutas.recursosAdquiridos}/${codigoRecursoAdquirido}`,
      data: data,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },
  // Elimina un recurso adquirido
  eliminaRecursoAdquirido: function (codigoRecursoAdquirido, conInterceptor = true) {
    return API.axios({
      method: 'DELETE',
      url: `${rutas.recursosAdquiridos}/${codigoRecursoAdquirido}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  // Tipos de recursos adquiridos
  obtieneTiposRecursoAdquirido: function (conInterceptor = true) {
    return API.axios({
      method: 'GET',
      url: `${rutas.tiposRecursosAdquirido}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },
  // Levantamiento de datos
  obtieneLevantamientoDatos: function (conInterceptor = true) {
    return API.axios({
      method: 'GET',
      url: `${rutas.levantamientoDatos}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },
  // Tipos de embalaje
  obtieneTiposEmbalaje: function (conInterceptor = true) {
    return API.axios({
      method: 'GET',
      url: `${rutas.tiposEmbalaje}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },
  // Unidades de medida
  obtieneUnidadMedida: function (conInterceptor = true) {
    return API.axios({
      method: 'GET',
      url: `${rutas.unidadMedida}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

};

// Rutas de los servicios
const rutas = {
  recursos: '/recursos',
  recursosAdquiridos: '/recursos-adquiridos',
  tiposRecursosAdquirido: '/tipos-recurso-adquirido',
  levantamientoDatos: '/levantamiento-datos',
  tiposEmbalaje: '/tipos-embalaje',
  unidadMedida: '/unidad-medida',
};

export default recursosServices;
