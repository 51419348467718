import { useEffect } from 'react';

// Dependencias

// Secciones
import {
  ResiduoDeclarado, // Sección 1
  Transporte, // Sección 2
  Receptor, // Sección 3
  DocumentosDeRespaldo // Sección 4
} from '../SeccionesResiduosDeclarados';

// Utils
import { SaltoLinia } from 'views/ResiduoDeclarado/utils/utils';
import { UseTocarCamposFormik } from 'views/ResiduoDeclarado/hooks/useUtilsFormik';

const CamposResiduosDeclarados = ({
  // Formik
  errors,
  handleBlur,
  handleChange,
  handleReset,
  handleSubmit,
  // isSubmitting,
  setFieldError,
  setFieldTouched,
  setFieldValue,
  touched,
  validateForm,
  values,

  // Dependencias

  // Seccion 1 - ResiduoDeclarado

  // Levantamiento
  levantamiento,
  setLevantamiento,
  isLoadingLevantamiento,
  setIsLoadingLevantamiento,

  // Sucursales
  sucursales,
  setSucursales,
  isLoadingSucursales,
  setIsLoadingSucursales,

  // Formato Entrega
  formatoEntrega,
  setFormatoEntrega,
  isLoadingFormatoEntrega,
  setIsLoadingFormatoEntrega,

  // Seccion 2 - Transporte

  // States Empresas SII Transporte
  cargaEmpresasSIITransporte,
  empresasSIITransporte,
  setEmpresasSIITransporte,
  isLoadingEmpresasSIITransporte,
  setIsLoadingEmpresasSIITransporte,

  // States Empresas SII Receptor
  cargaEmpresasSIIReceptor,
  empresasSIIReceptor,
  setEmpresasSIIReceptor,
  isLoadingEmpresasSIIReceptor,
  setIsLoadingEmpresasSIIReceptor,

  // Tipo de Transporte
  tipoTransporte,
  setTipoTransporte,
  isLoadingTipoTransporte,
  setIsLoadingTipoTransporte,

  // Seccion 3 - Receptor


  // Tipo de Tratamiento
  tipoTratamiento,
  setTipoTratamiento,
  isLoadingTipoTratamiento,
  setIsLoadingTipoTratamiento,

  // Seccion 4 - DocumentosDeRespaldo

  // Tipo Formulario
  tipoForm,

  // isLoading
  isLoading,

  cargaNombreLER,
  setCargaNombreLER,

  cargaTipoResiduos,
  setCargaTipoResiduos
}) => {
  const { ingresar, editar, duplicar } = tipoForm;

  useEffect(() => {
    // console.log(touched);
    // Tocar todos los campos para que se muestren los errores
    if (editar || duplicar) {
      UseTocarCamposFormik(values, setFieldTouched);
    }
  }, [
    cargaNombreLER,
    cargaTipoResiduos,
    duplicar,
    editar,
    setFieldTouched,
    values
  ]);

  return (
    <>
      <div className="col-span-12">
        <div className="bg-white rounded shadow-sm mb-5">
          <div className="col-span-12">
            {/* <ResiduoDeclarado /> */}
            <ResiduoDeclarado
              // Formik
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleReset={handleReset}
              setFieldError={setFieldError}
              handleSubmit={handleSubmit}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              touched={touched}
              validateForm={validateForm}
              values={values}
              // Dependencias

              // Seccion 1 - ResiduoDeclarado

              // Levantamiento
              isLoadingLevantamiento={isLoadingLevantamiento}
              levantamiento={levantamiento}
              // Sucursales
              isLoadingSucursales={isLoadingSucursales}
              sucursales={sucursales}
              // Formato Entrega
              formatoEntrega={formatoEntrega}
              isLoadingFormatoEntrega={isLoadingFormatoEntrega}
              // Tipo Formulario
              tipoForm={tipoForm}
              // isLoading
              isLoading={isLoading}
              setCargaNombreLER={setCargaNombreLER}
              setCargaTipoResiduos={setCargaTipoResiduos}
            />

            <SaltoLinia />

            {/* <Transporte /> */}
            <Transporte
              // Formik
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleReset={handleReset}
              setFieldError={setFieldError}
              handleSubmit={handleSubmit}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              touched={touched}
              validateForm={validateForm}
              values={values}
              // Dependencias

              // Empresas SII
              cargaEmpresasSII={cargaEmpresasSIITransporte}
              empresasSII={empresasSIITransporte}
              isLoadingEmpresasSII={isLoadingEmpresasSIITransporte}
              setEmpresasSII={setEmpresasSIITransporte}
              setIsLoadingEmpresasSII={setIsLoadingEmpresasSIITransporte}
              // Tipo de Transporte
              tipoTransporte={tipoTransporte}
              setTipoTransporte={setTipoTransporte}
              isLoadingTipoTransporte={isLoadingTipoTransporte}
              setIsLoadingTipoTransporte={setIsLoadingTipoTransporte}
              // Tipo Formulario
              tipoForm={tipoForm}
            />

            <SaltoLinia />

            {/* <Receptor /> */}
            <Receptor
              // Formik
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleReset={handleReset}
              setFieldError={setFieldError}
              handleSubmit={handleSubmit}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              touched={touched}
              validateForm={validateForm}
              values={values}
              // Dependencias

              // Empresas SII
              cargaEmpresasSII={cargaEmpresasSIIReceptor}
              empresasSII={empresasSIIReceptor}
              isLoadingEmpresasSII={isLoadingEmpresasSIIReceptor}
              setEmpresasSII={setEmpresasSIIReceptor}
              setIsLoadingEmpresasSII={setIsLoadingEmpresasSIIReceptor}
              // Tipo de Tratamiento
              tipoTratamiento={tipoTratamiento}
              setTipoTratamiento={setTipoTratamiento}
              isLoadingTipoTratamiento={isLoadingTipoTratamiento}
              setIsLoadingTipoTratamiento={setIsLoadingTipoTratamiento}
              // Tipo Formulario
              tipoForm={tipoForm}
            />

            <SaltoLinia />

            {/* <DocumentosDeRespaldo /> */}
            <DocumentosDeRespaldo
              // Formik
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleReset={handleReset}
              setFieldError={setFieldError}
              handleSubmit={handleSubmit}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              touched={touched}
              validateForm={validateForm}
              values={values}
              // Tipo Formulario
              tipoForm={tipoForm}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CamposResiduosDeclarados;
