import ChatBubble from '@mui/icons-material/ChatBubble';
import Close from '@mui/icons-material/Close';
import PlaceRounded from '@mui/icons-material/PlaceRounded';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import Text from 'components/design-system/Text/Text';
import { DescripcionDemandante } from './Descripcion/DescripcionDemandante';
import { useReunion } from 'hooks/useReunion';
import { MatchContext } from 'views/Marketplace/Residuos/context/MatchContext';
import { useContext, useEffect, useState } from 'react';

/**
 * @name ResiduoDetalleDemandante
 * Despliega el detalle del residuo seleccionado para un publicante demandante
 * @param residuo residuo por el cual se está visualizando el detalle
 * @param onCerrar función para cuando se desea cerrar el popup de detalle.
 */
export const ResiduoDetalleDemandante = ({ residuo, onCerrar }) => {
  const { handleCrearReunion, isCreandoReunion } = useReunion();
  const { salasChat, tieneNegocio } = useContext(MatchContext);
  const [isSalaChatCreada, setIsSalaChatCreada] = useState(false);
  const handleSolicitarReunion = async () => {
    try {
      handleCrearReunion({
        codigoSucursal: residuo.sucursal.codigoSucursal,
        origenSalaChat: 'residuo',
        codigoResiduo: residuo.codigoResiduo
      });
      setIsSalaChatCreada(true);
    } catch (error) {
      console.log('ERROR_AL_CREAR_SALA_CHAT ', error);
    }
  };

  window.scrollTo(0, 0);


  return (
    <>
      <div className="h-full scrollbar-uv overflow-y-auto p-4 pb-20 w-full">
        <div>
          <div className="relative">
            <Heading type="h3" className="pr-10">
              {residuo.sucursal.empresa
                ? residuo.sucursal.empresa
                : 'Nombre no definido'}
              <div className="absolute -right-1 top-0">
                <IconButtonComponent
                  onClick={onCerrar}
                  size="small"
                  type="neutral"
                >
                  <Close />
                </IconButtonComponent>
              </div>
            </Heading>
            <div className="relative">
              <span className="pl-8 mb-1 block font-secondary text-xs text-neutral-60 tracking-wider font-medium uppercase">
                {residuo.sucursal.nombreSucursal
                  ? residuo.sucursal.nombreSucursal
                  : 'Sucursal no definida'}
              </span>

              <div className="flex items-center">
                <PlaceRounded className="mr-2 text-uv-secondary-10" />
                <Text>
                  {residuo.sucursal?.direccion ? (
                    <>
                      {residuo.sucursal.direccion?.nombreVia}{', '}
                      {residuo.sucursal.direccion?.comuna}{',  Región '}
                      {residuo.sucursal.direccion?.region}
                    </>
                  ) : (
                    'Dirección no definida'
                  )}
                </Text>
              </div>
            </div>
          </div>
        </div>

        <DescripcionDemandante residuo={residuo} />
      </div>

      {tieneNegocio && (
        <div className="absolute bottom-0 left-0 w-full p-3 bg-white shadow-top rounded">
          <div className="flex gap-4">
            <div className="w-1/2">
              <ButtonComponent
                disabled={
                  isSalaChatCreada || 
                  isCreandoReunion ||
                  (salasChat &&
                    salasChat.residuos.includes(residuo.codigoResiduo))
                }
                isLoading={isCreandoReunion}
                onClick={() => handleSolicitarReunion()}
                className="px-2 w-full"
                type="secondary"
              >
                <ChatBubble className="mr-1" /> Solicitar Reunión
              </ButtonComponent>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
