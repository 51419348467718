import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { CallReceived } from '@mui/icons-material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { RUTAS } from 'const/Dictonary';
import store from 'states/store';
import { LayoutOpciones } from './LayoutOpciones';

export const CuadroAccesosRapidos = () => {
  const history = useHistory();
  const { tipos } = store.loguedUser;
  const { isDemandante, isOferente } = tipos || false;
  const { perfiles } = store.loguedUser;
  const { 
    isAdmin,  
    isAnalistaTerritorial,
    isFuncionario, 
    isSuperAdmin, 
  } = perfiles;

  const obtenerBotones = () => {
    let botonesNavegacion = [
      {
        onClick: () => history.push(RUTAS.MARKETPLACE_RESIDUOS),
        texto: (
          <>
             Iniciar una búsqueda<br/>
            <strong>Residuo-recurso</strong>
            <b className="text-black"></b>
          </>
        ),
        icono: <Inventory2OutlinedIcon />
      },
    ];

    if (isOferente) {
      botonesNavegacion.push({
        onClick: () => history.push(RUTAS.OFRECER_RESIDUO),
        texto: (
          <>
            <b className="text-oferente">Ofrecer</b> un residuo
          </>
        ),
        icono: (
          <>
            <Inventory2OutlinedIcon />
            <div className="absolute right-0 top-0 w-5 h-5 bg-oferente text-white rounded">
              <CallReceived className="rotate-180 -ml-1 mt-0" />
            </div>
          </>
        )
      });
    }

    if (isDemandante) {
      botonesNavegacion.push({
        onClick: () => history.push(RUTAS.REQUERIR_RESIDUO),
        texto: (
          <>
            <b className="text-demandante">Requerir</b> un residuo
          </>
        ),
        icono: (
          <>
            <Inventory2OutlinedIcon />
            <div className="absolute right-0 top-0 w-5 h-5 bg-demandante text-white rounded">
              <CallReceived className="ml-0 -mt-1.5" />
            </div>
          </>
        )
      });
    }

    return botonesNavegacion;
  };

  return <LayoutOpciones opciones={obtenerBotones()} />;
};
