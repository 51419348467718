import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Link, useParams, useHistory } from 'react-router-dom';
// Layout
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';

// Desgin System
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';

// Material
import { ChevronLeft } from '@mui/icons-material';

// Diccionary
import { RUTAS } from 'const/Dictonary';
import { diccionarioVista } from 'views/ResiduoDeclarado/utils/utils';

// Hooks
import { useServicesResiduosDeclarados } from 'views/ResiduoDeclarado/hooks/useServicesResiduosDeclarados';
import { useSubmitResiduosDeclarados } from 'views/ResiduoDeclarado/hooks/useSubmitResiduosDeclarados';
import useCargaServiciosResiduosDeclarados from 'views/ResiduoDeclarado/hooks/useCargaServiciosResiduosDeclarados';
// Components
import CamposResiduosDeclarados from './CamposResiduosDeclarados';
// Schema
import { schemaResiduosDeclarados } from 'views/ResiduoDeclarado/schema/schemaResiduosDeclarados';
// Skeleton
import { SkeletonResiduoDeclarado } from 'views/ResiduoDeclarado/skeleton/SkeletonResiduoDeclarado';

const ResiduosDeclaradosFormik = () => {
  const params = useParams();
  const history = useHistory();
  const { location } = history;

  // Tipo de Formulario
  const tipo = location?.state?.formulario?.tipo;

  const [codigoResiduoDeclarado, setCodigoResiduoDeclarado] = useState(
    params.codigoResiduoDeclarado || 'nuevo'
  ); // codigoResiduoDeclarado registrado en la base de datos (Integer) [nuevo
  const [tipoFormulario, setTipoFormulario] = useState(tipo || '');
  const [operacionFinalizada, setOperacionFinalizada] = useState(false);

  const [cargaNombreLER, setCargaNombreLER] = useState(true);
  const [cargaTipoResiduos, setCargaTipoResiduos] = useState(true);

  /**
   *  LLamada de servicios de useServicesResiduosDeclarados
   *  @description  hooks utilizado en la creación de servicios para Recursos Adquiridos
   *  @see          src/views/ResiduoDeclarado/hooks/useServicesResiduosDeclarados
   *  @params       no aplica
   *  @returns      states y funciones de servicios
   */
  const {
    // Funciones
    // ObtieneListaResiduosDeclarados,
    ObtieneComunasPorRegion,
    ObtieneEmpresasSIIReceptor,
    ObtieneEmpresasSIITransporte,
    ObtieneFormatoEntrega,
    ObtieneLevantamiento,
    ObtieneRegiones,
    ObtieneSucursales,
    ObtieneTipoTransporte,
    ObtieneTipoTratamiento,

    // States Sucursales
    sucursales,
    setSucursales,
    isLoadingSucursales,
    setIsLoadingSucursales,

    // States Levantamiento de datos
    levantamiento,
    setLevantamiento,
    isLoadingLevantamiento,
    setIsLoadingLevantamiento,

    // States formato de entrega
    formatoEntrega,
    setFormatoEntrega,
    isLoadingFormatoEntrega,
    setIsLoadingFormatoEntrega,

    // States Empresas SII Transporte
    empresasSIITransporte,
    setEmpresasSIITransporte,
    isLoadingEmpresasSIITransporte,
    setIsLoadingEmpresasSIITransporte,

    // States Empresas SII Receptor
    empresasSIIReceptor,
    setEmpresasSIIReceptor,
    isLoadingEmpresasSIIReceptor,
    setIsLoadingEmpresasSIIReceptor,

    // States Tipo de transporte
    tipoTransporte,
    setTipoTransporte,
    isLoadingTipoTransporte,
    setIsLoadingTipoTransporte,

    // States Tipo de Tratamiento
    tipoTratamiento,
    setTipoTratamiento,
    isLoadingTipoTratamiento,
    setIsLoadingTipoTratamiento
  } = useServicesResiduosDeclarados();

  /**
   *  Cargando Servicios de useCargaServicios
   *  @description  hooks utilizado para la ejecución de  servicios para Recursos Adquiridos
   *  @see          src/views/ResiduoDeclarado/hooks/useCargaServicios
   *  @params       todas las funciones a cargar en el hook
   *  @returns      funciones de carga de servicios para ser utilizadas en otra parte del formulario
   *
   * */
  const { cargaEmpresasSIITransporte, cargaEmpresasSIIReceptor } =
    useCargaServiciosResiduosDeclarados({
      ObtieneComunasPorRegion,
      ObtieneEmpresasSIITransporte,
      ObtieneEmpresasSIIReceptor,
      ObtieneFormatoEntrega,
      ObtieneLevantamiento,
      ObtieneRegiones,
      ObtieneSucursales,
      ObtieneTipoTransporte,
      ObtieneTipoTratamiento
    });

  // Hooks
  const {
    residuoDeclarado,
    cargarResiduoDeclarado,
    isLoading,
    // setIsLoading,
    cargaData,
    handleSubmit
  } = useSubmitResiduosDeclarados(codigoResiduoDeclarado);

  // ejecutar cargarResiduoDeclarado() si se refresca la pagina
  useEffect(() => {
    if (!tipoFormulario) return;
    cargarResiduoDeclarado();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoFormulario]);

  useEffect(() => {
    if (!cargaData) return;
    // console.log('residuoDeclarado formik ::>', residuoDeclarado);
  }, [cargaData, isLoading, residuoDeclarado]);

  // Tipos de Formulario
  const tiposPosibles = ['ingresar', 'editar', 'duplicar'];
  const tipoForm = tiposPosibles.reduce((obj, tipo) => {
    obj[tipo] = tipoFormulario === tipo;
    return obj;
  }, {});
  const isFormIngresar = tipoFormulario === 'ingresar';
  return (
    <SidebarLayout>
      <Heading type="h2" className="mb-4 text-lg md:text-2xl">
        <div className="flex items-center">
          <Link to={`${RUTAS.GESTIONA_RESIDUO_DECLARADO}`}>
            <IconButtonComponent type="secondary" className="mr-2">
              <ChevronLeft />
            </IconButtonComponent>
          </Link>
          <span>
            {` ${
              tipoForm.ingresar
                ? 'Ingresar'
                : tipoForm.editar
                ? 'Editar'
                : tipoForm.duplicar
                ? 'Duplicar'
                : ''
            }
                    ${diccionarioVista.nombrePrincipalSingular}`}
          </span>
        </div>
      </Heading>

      {isLoading && !cargaData && !isFormIngresar ? (
        <SkeletonResiduoDeclarado tipoForm={tipoForm} />
      ) : (
        <Formik
          initialValues={residuoDeclarado}
          validationSchema={schemaResiduosDeclarados}
          validateOnChange={true}
          validateOnBlur={true}
          validateOnMount={true}
          onSubmit={async (values, { resetForm }) => {
            console.log('submit finalizado ::>');

            await handleSubmit(values, tipoFormulario);
            // resetForm();
            setOperacionFinalizada(true);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleReset,
            handleSubmit,
            isSubmitting,
            setFieldError,
            setFieldTouched,
            setFieldValue,
            touched,
            validateForm,
            values
          }) => (
            <Form
              id="formResiduoDeclarado"
              onChange={event => {
                // console.log('values ::>', values);
                //  console.log('touched ::>', touched);
                //  console.log('errors ::>', errors);
              }}
            >
              <div className="bg-white rounded p-5 shadow-sm mb-5">
                <div className="grid grid-cols-12 gap-4">
                  <CamposResiduosDeclarados
                    // Formik
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleReset={handleReset}
                    handleSubmit={handleSubmit}
                    setFieldError={setFieldError}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    validateForm={validateForm}
                    values={values}
                    // Dependencias

                    // Levantamiento de datos
                    levantamiento={levantamiento}
                    setLevantamiento={setLevantamiento}
                    isLoadingLevantamiento={isLoadingLevantamiento}
                    setIsLoadingLevantamiento={setIsLoadingLevantamiento}
                    // Sucursales
                    sucursales={sucursales}
                    setSucursales={setSucursales}
                    isLoadingSucursales={isLoadingSucursales}
                    setIsLoadingSucursales={setIsLoadingSucursales}
                    // Formato de entrega
                    formatoEntrega={formatoEntrega}
                    setFormatoEntrega={setFormatoEntrega}
                    isLoadingFormatoEntrega={isLoadingFormatoEntrega}
                    setIsLoadingFormatoEntrega={setIsLoadingFormatoEntrega}
                    // Empresas SII Transporte
                    cargaEmpresasSIITransporte={cargaEmpresasSIITransporte}
                    empresasSIITransporte={empresasSIITransporte}
                    isLoadingEmpresasSIITransporte={
                      isLoadingEmpresasSIITransporte
                    }
                    setEmpresasSIITransporte={setEmpresasSIITransporte}
                    setIsLoadingEmpresasSIITransporte={
                      setIsLoadingEmpresasSIITransporte
                    }
                    // Empresas SII Receptor
                    cargaEmpresasSIIReceptor={cargaEmpresasSIIReceptor}
                    empresasSIIReceptor={empresasSIIReceptor}
                    isLoadingEmpresasSIIReceptor={isLoadingEmpresasSIIReceptor}
                    setEmpresasSIIReceptor={setEmpresasSIIReceptor}
                    setIsLoadingEmpresasSIIReceptor={
                      setIsLoadingEmpresasSIIReceptor
                    }
                    // Tipo de transporte
                    tipoTransporte={tipoTransporte}
                    setTipoTransporte={setTipoTransporte}
                    isLoadingTipoTransporte={isLoadingTipoTransporte}
                    setIsLoadingTipoTransporte={setIsLoadingTipoTransporte}
                    // Tipo de Tratamiento
                    tipoTratamiento={tipoTratamiento}
                    setTipoTratamiento={setTipoTratamiento}
                    isLoadingTipoTratamiento={isLoadingTipoTratamiento}
                    setIsLoadingTipoTratamiento={setIsLoadingTipoTratamiento}
                    // Tipo de Formulario
                    tipoForm={tipoForm}
                    //isLoading
                    isLoading={isLoading}
                    cargaNombreLER={cargaNombreLER}
                    setCargaNombreLER={setCargaNombreLER}
                    cargaTipoResiduos={cargaTipoResiduos}
                    setCargaTipoResiduos={setCargaTipoResiduos}
                  />

                  <div className="col-span-12 justify-between">
                    <div className="flex justify-between">
                      <Link to={`${RUTAS.GESTIONA_RESIDUO_DECLARADO}`}>
                        <ButtonComponent type="secondary" className="mr-2">
                          <ChevronLeft /> Volver
                        </ButtonComponent>
                      </Link>

                      {operacionFinalizada ? (
                        <Link to={`${RUTAS.GESTIONA_RESIDUO_DECLARADO}`}>
                          <ButtonComponent type="primary" className="mr-2">
                            Finalizar
                          </ButtonComponent>
                        </Link>
                      ) : (
                        <ButtonComponent
                          form="formResiduoDeclarado"
                          buttonType="submit"
                          // isLoading={isSubmitting || isLoading}
                          disabled={
                            isSubmitting || Object.keys(errors).length > 0
                          }
                        >
                          {tipoFormulario === 'ingresar'
                            ? 'Ingresar   ' +
                              diccionarioVista.primerNombrePrincipal
                            : tipoFormulario === 'editar'
                            ? 'Editar     ' +
                              diccionarioVista.primerNombrePrincipal
                            : tipoFormulario === 'duplicar'
                            ? 'Duplicar   ' +
                              diccionarioVista.primerNombrePrincipal
                            : ''}
                        </ButtonComponent>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </SidebarLayout>
  );
};

export default ResiduosDeclaradosFormik;
