import * as yup from 'yup';
import { SchemaIndentificarRSD } from './SchemaIndentificarRSD';

const SchemaEspecificacionOferta = yup.object().shape({
  codigoSucursal: yup
    .number()
    .min(1, 'Seleccione Sucursal')
    .required('Seleccione Sucursal'),
  precioReferencial: yup.string().required('Ingrese precio referencial'),
  frecuencia: yup.string().required('Ingrese frecuencia'),
  cantidad: yup
    .number()
    .min(0.01, 'Ingrese cantidad debe ser mayor a 0')
    .required('Ingrese cantidad'),
  formatoEntrega: yup
    .string()
    .min(3, 'El formato de entrega es muy corto')
    .required('Ingrese formato de entrega'),
    ...SchemaIndentificarRSD.fields,
});

export { SchemaEspecificacionOferta };
