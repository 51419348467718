import { useCallback, useEffect, useState } from 'react';
import { DICTONARY } from 'const/Dictonary';
import analisisTerritorial from 'services/analisisTerritorial';
import storeMessages from 'states/alertMessages/store';

export const useTablaLineaBase = (
  filtrosAplicados,
  setCargando,
  isCargandoDataInicial
) => {
  const [dataEmpresas, setDataEmpresas] = useState([]);
  const [empresaSeleccionada, setEmpresaSeleccionada] = useState({ id: null });
  const [totalRegistros, setTotalRegistros] = useState(1);
  const [pagination, setPagination] = useState(DICTONARY.PAGINACION.DEFAULT);

  const requestSeleccionarPunto = async params => {
    if (
      Object.hasOwn(filtrosAplicados, 'codigoEmpresaAT') &&
      filtrosAplicados.codigoEmpresaAT
    ) {
      params.codigoEmpresaAT = [filtrosAplicados.codigoEmpresaAT];
    }

    const response = await analisisTerritorial.analisisEmpresa(
      {
        ...params,
        numPagina: DICTONARY.PAGINACION.DEFAULT.pageIndex,
        totalPagina: DICTONARY.PAGINACION.DEFAULT.pageSize
      },
      DICTONARY.INTERCEPTOR.DESACTIVADO
    );
    return response;
  };

  const requestIni = async params => {
    const response = await analisisTerritorial.analisisEmpresa(
      params,
      DICTONARY.INTERCEPTOR.DESACTIVADO
    );
    return response;
  };

  const cargarEmpresas = useCallback(async () => {
    try {
      console.log(filtrosAplicados);
      setCargando('tablaEmpresasCargando', true);
      let requestParams = {
        numPagina: pagination.pageIndex,
        totalPagina: pagination.pageSize
      };
      if (
        Object.hasOwn(filtrosAplicados, 'codigoComuna') &&
        filtrosAplicados.codigoComuna
      ) {
        requestParams.codigoComuna = filtrosAplicados.codigoComuna;
      }
      if (
        Object.hasOwn(filtrosAplicados, 'codigosRegion') &&
        filtrosAplicados.codigosRegion
      ) {
        requestParams.codigoRegion = filtrosAplicados.codigosRegion;
      }
      if (
        Object.hasOwn(filtrosAplicados, 'codigoSectorEconomico') &&
        filtrosAplicados.codigoSectorEconomico
      ) {
        requestParams.codigoSectorEconomico =
          filtrosAplicados.codigoSectorEconomico;
      }

      let response;
      switch (filtrosAplicados.tipoPeticion) {
        case DICTONARY.LINEA_BASE_ESTADOS.FILTRA_FORMULARIO:
          response = await requestIni(requestParams);
          break;

        case DICTONARY.LINEA_BASE_ESTADOS.SELECCIONA_PUNTO:
          response = await requestSeleccionarPunto(requestParams);
          break;

        case DICTONARY.LINEA_BASE_ESTADOS.DESELECCIONA_PUNTO:
          response = await requestIni(requestParams);
          break;

        default:
          response = await requestIni(requestParams);
          break;
      }

      if (response.data.error) throw new Error('SIN_REGISTROS');
      if (response.status === 200) {
        if (response.empty) {
          setDataEmpresas([]);
          setTotalRegistros(0);
          return;
        } else if (Array.isArray(response.data.content)) {
          const data = response.data.content.map(empresa => ({
            ...empresa,
            tablaEmpresa: {
              razonSocial: empresa.razonSocial,
              residuoATOrigen: empresa.residuoATOrigen
            }
          }));
          setDataEmpresas(data);
          setTotalRegistros(response.data.totalElements);
        }
      }
    } catch (error) {
      if (error.message === 'SIN_REGISTROS') {
        storeMessages.message.setMessage({
          mensaje: 'No existen registros para la solicitud realizada',
          tipo: 'info'
        });
      } else {
        storeMessages.message.setMessage({
          mensaje:
            'Por un error en el servicio no se ha obtenido la información para la tabla de empresas',
          tipo: 'error'
        });
      }

      setDataEmpresas([]);
      setTotalRegistros(0);
    } finally {
      setCargando('tablaEmpresasCargando', false);
    }
  }, [filtrosAplicados, pagination]);

  // Se carga el mapa al aplicar filtros
  useEffect(() => {
    if (isCargandoDataInicial) return;
    if (
      filtrosAplicados.tipoPeticion ===
      DICTONARY.LINEA_BASE_ESTADOS.SELECCIONA_PUNTO_TABLA
    ) {
      return;
    }
    cargarEmpresas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtrosAplicados, pagination]);

  return {
    dataEmpresas,
    empresaSeleccionada,
    setEmpresaSeleccionada,
    pagination,
    setPagination,
    totalRegistros
  };
};
