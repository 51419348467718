import { useEffect } from 'react';
import { useState } from 'react';
import AutocompleteComponent from '../Autocomplete/Autocomplete';
import InputComponent from '../input/Input';
import { CircularProgress } from '@mui/material';

export const AutoCompleteAsync = props => {
  const {
    setFieldValue,
    isSubmitting,
    values,
    data,
    label,
    setFieldTouched,
    fetchCall,
    isLoading,
    disabled,
    ...rest
  } = props;

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const debouncedValue = useDebounce(inputValue, 1000);

  useEffect(() => {
    if (debouncedValue) {
      (async () => {
        await fetchCall(debouncedValue);
        setOpen(true);
      })();
    }
  }, [debouncedValue]);

  const handleInputChange = (e, v) => {
    setInputValue(v);
  };

  return (
    <AutocompleteComponent
      loading={isLoading}
      open={open}
      onOpen={() => {
        if (data && data.length > 0) {
          setOpen(true);
        }
      }}
      onInputChange={handleInputChange}
      onChange={(ev, valueObject, reason) => {
        if (reason === 'clear') {
          setFieldValue(null);
        }
        setFieldValue(valueObject);
      }}
      noOptionsText="Sin datos"
      onClose={() => setOpen(false)}
      options={!data ? [{ label: 'Loading...', id: 0 }] : data}
      getOptionDisabled={(option) => option.label === 'Loading...'} // Disable the loading option
      disabled={disabled}
      //value={values.empresa}
      //clearOnEscape={true}
      //openOnFocus={true}
      getOptionLabel={option => (option ? option.label : '')}
      onBlur={() => setFieldTouched()}
      renderInput={params => (
        <InputComponent
          {...params}
          label={label}
          placeholder="Buscar por razón social..."
          outerInputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
      {...rest}
    />
  );
};

function useDebounce(value, delay, initialValue) {
  const [state, setState] = useState(initialValue);

  useEffect(() => {
    const timer = setTimeout(() => setState(value), delay);
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return state;
}
