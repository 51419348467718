// Design System
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import Text from 'components/design-system/Text/Text';
// Components
import Heading from 'components/design-system/Heading/Heading';

// Diccionarity

// Material

import { CircularProgress } from '@mui/material';
import { useMemo, useState } from 'react';
import { useEffect } from 'react';
import {
  handleKeyPress,
  handlePaste
} from 'views/Business/utils/handleKeyPress';

// Library

const Transporte = ({
  // Formik
  errors,
  handleBlur,
  handleChange,
  // handleReset,
  // handleSubmit,
  isSubmitting,
  // setFieldError,
  setFieldTouched,
  setFieldValue,
  touched,
  // validateForm,
  values,

  // Dependencias

  // Empresas SII
  cargaEmpresasSII,
  empresasSII,
  isLoadingEmpresasSII,
  setEmpresasSII,

  // Tipo de Transporte
  isLoadingTipoTransporte,
  setIsLoadingTipoTransporte,
  setTipoTransporte,
  tipoTransporte,

  // Tipo Formulario
  tipoForm
}) => {
  const [razonSocialEmpresaTransporte, setRazonSocialEmpresaTransporte] =
    useState('');

  const { duplicar, editar } = tipoForm;

  useEffect(() => {
    // console.log('empresaSIItransporte', empresasSII);
    if (!empresasSII) return;
    if (empresasSII.razonSocial) {
      setRazonSocialEmpresaTransporte(empresasSII.razonSocial);
    } else {
      setRazonSocialEmpresaTransporte('Sin información');
    }
  }, [empresasSII, isLoadingEmpresasSII]);

  // Carga RUT al ingresar al formulario
  useMemo(() => {
    if (
      (duplicar || editar) &&
      !touched.rutEmpresaTransporte &&
      values.rutEmpresaTransporte
    ) {
      cargaEmpresasSII(values.rutEmpresaTransporte);
    }
  }, [
    cargaEmpresasSII,
    duplicar,
    editar,
    touched.rutEmpresaTransporte,
    values.rutEmpresaTransporte
  ]);

  return (
    <div className="col-span-12">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-12">
          <Heading type="h4" className="mb-0">
            Transporte
          </Heading>
        </div>
        <div className="col-span-12 xs:col-span-12">
          <div className="grid grid-cols-12 gap-4">
            {/* Rut empresa de transporte */}
            <div className="col-span-12 md:col-span-6">
              <InputComponent
                autoComplete="off"
                disabled={isSubmitting}
                fullWidth
                name={'rutEmpresaTransporte'}
                value={values.rutEmpresaTransporte || ''}
                onChange={event => {
                  const { value } = event.target;
                  if (
                    value.length >= 9 &&
                    value.charAt(value.length - 2) === '-'
                  ) {
                    setFieldValue('rutEmpresaTransporte', value.trim());
                    cargaEmpresasSII(value);
                  } else {
                    setFieldValue('rutEmpresaTransporte', value.trim());
                    setRazonSocialEmpresaTransporte('Sin información');
                  }
                }}
                onBlur={() => {
                  setFieldTouched('rutEmpresaTransporte');
                }}
                outerInputProps={{
                  inputProps: {
                    maxLength: 12,
                    // minLenth: 9,
                    onKeyPress: handleKeyPress
                  }
                }}
                onPaste={handlePaste}
                touched={touched.rutEmpresaTransporte}
                placeholder="Ej: 12345678-9"
                estado={
                  errors.rutEmpresaTransporte
                    ? { tipo: 'error', mensaje: errors.rutEmpresaTransporte }
                    : null
                }
                label={
                  <LabelComponent>
                    Rut <span className="text-neutral-60">(opcional)</span>
                  </LabelComponent>
                }
              />
            </div>
            {/* Razon Social */}
            <div className="col-span-12 md:col-span-6 md:pt-0.5">
              <div className="rounded bg-neutral-90 p-2.5 md:mt-6 relative">
                <Text className="min-h-[1.25rem]">
                  {empresasSII &&
                  empresasSII.razonSocial &&
                  !isLoadingEmpresasSII &&
                  !errors.rutEmpresaTransporte &&
                  touched.rutEmpresaTransporte
                    ? razonSocialEmpresaTransporte
                    : !touched.rutEmpresaTransporte &&
                      !errors.rutEmpresaTransporte
                    ? ''
                    : 'Sin información'}
                  {isLoadingEmpresasSII &&
                    touched.rutEmpresaTransporte &&
                    !errors.rutEmpresaTransporte && (
                      <CircularProgress
                        color="inherit"
                        className="opacity-40 !w-5 !h-5 absolute right-2.5 top-2.5"
                      />
                    )}
                </Text>
              </div>
            </div>
            {/* Tipo Transporte */}
            <div className="col-span-12 md:col-span-6 xl:col-span-4">
              <AutocompleteComponent
                accesor="nombreTipoTransporte"
                clearOnEscape={true}
                disabled={isSubmitting || isLoadingTipoTransporte}
                fullWidth
                name={'tipoTransporte'}
                options={tipoTransporte || []}
                value={values.tipoTransporte || ''}
                onChange={(event, newValue, reason) => {
                  let data = { name: 'tipoTransporte', value: newValue };
                  if (reason === 'clear') {
                    data.value = '';
                  }
                  setFieldValue('tipoTransporte', data.value);
                }}
                getOptionLabel={option => option.nombreTipoTransporte || ''}
                noOptionsText={
                  isLoadingTipoTransporte ? 'Cargando...' : 'Sin opciones'
                }
              >
                <InputComponent
                  autoComplete="off"
                  disabled={isSubmitting || isLoadingTipoTransporte}
                  fullWidth
                  onBlur={() => {
                    setFieldTouched('tipoTransporte');
                  }}
                  touched={touched.tipoTransporte}
                  placeholder={
                    isLoadingTipoTransporte
                      ? 'Cargando...'
                      : 'Selecciona una opción'
                  }
                  estado={
                    errors.tipoTransporte
                      ? { tipo: 'error', mensaje: errors.tipoTransporte }
                      : null
                  }
                  label={<LabelComponent>Tipo de transporte </LabelComponent>}
                />
              </AutocompleteComponent>
            </div>
            {/* Patente */}
            <div className="col-span-12 md:col-span-6 xl:col-span-4">
              <InputComponent
                fullWidth
                disabled={isSubmitting}
                name={'patente'}
                value={(values.patente || '').toUpperCase()}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.patente}
                placeholder="Ej: AB1234"
                outerInputProps={{
                  inputProps: {
                    maxLength: 6
                  }
                }}
                onPaste={handlePaste}
                estado={
                  errors.patente
                    ? { tipo: 'error', mensaje: errors.patente }
                    : null
                }
                label={
                  <LabelComponent>
                    Patente <span className="text-neutral-60">(opcional)</span>
                  </LabelComponent>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transporte;
