import { SchemaTrasaccion } from 'views/Transaccion/schema/SchemaTrasaccion';
import { Dialog, DialogActions, DialogContent, Hidden } from '@mui/material';
import { Form, Formik } from 'formik';
import ButtonComponent from 'components/design-system/Button/Button';
import serviceRSD from 'services/residuos';
import storeMessages from 'states/alertMessages/store';
import { formateaFechaDate, formateaSucursales } from 'views/Transaccion/utils';
import { useHistory } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import { DICTONARY } from 'const/Dictonary';
import empresasServices from 'services/empresas';
import { useEffect, useState } from 'react';
import { ModalTransaccionesFormik } from './ModalTransaccionesFormik';

export const ModalTransacciones = ({
  listadoTiposTransporte,
  listadoEmpresasGestor,
  listadoEmpresasAsesor,
  listadoEmpresasTransportista,
  openModal,
  transaccionSelected,
  handleCloseModal
}) => {
  const history = useHistory();

  const [listadoTiposTratamiento, setListadoTiposTratamiento] = useState([]);

  useEffect(() => {
    if (transaccionSelected) {
      let params = {
        totalPagina: DICTONARY.PAGINACION.ALL_DATA,
        codigoTipoResiduo:
          transaccionSelected.residuo.tipoResiduo.codigoTipoResiduo
      };

      empresasServices
        .obtenerTipoTratamiento(params, DICTONARY.INTERCEPTOR.DESACTIVADO)
        .then(response => {
          const content = response.data.content;
          setListadoTiposTratamiento(content);
        })
        .catch(error => {
          console.log(error);
          setListadoTiposTratamiento([]);
        });
    }
  }, [transaccionSelected]);

  // SOLO PARA DEMANDANTES - erminar y aprobar de usuario
  const handleCerrarTransaccion = values => {
    let data = JSON.parse(JSON.stringify(values));

    let {
      sucursalAsesor,
      sucursalGestor,
      sucursalTransportista,
      ejecutoTratamiento,
      tipoTransporte,
      tipoTratamiento,
      fechaRecepcion,
      rutTransportista,
      patenteTransportista
    } = data;

    const formatoSucursalesTr = formateaSucursales(
      transaccionSelected.sucursalTransaccion
    );
    const formatoAsesor = sucursalAsesor
      ? formatoSucursal(sucursalAsesor, 'asesor', ejecutoTratamiento)
      : null;
    const formatoGestor = sucursalGestor
      ? formatoSucursal(sucursalGestor, 'gestor', ejecutoTratamiento)
      : null;
    formatoAsesor && formatoSucursalesTr.push(formatoAsesor);
    formatoGestor && formatoSucursalesTr.push(formatoGestor);

    if (
      sucursalTransportista &&
      sucursalTransportista.codigoSucursal &&
      sucursalTransportista.codigoSucursal !== 0
    ) {
      const formatoTransportista = sucursalTransportista
        ? formatoSucursal(
            sucursalTransportista,
            'transportista',
            ejecutoTratamiento
          )
        : null;
      formatoTransportista && formatoSucursalesTr.push(formatoTransportista);
    }

    const dataFormateada = {
      fechaRecepcion: formateaFechaDate(fechaRecepcion),
      tipoTransporte: {
        codigoTipoTransporte: tipoTransporte.codigoTipoTransporte
      },
      tipoTratamiento: {
        codigoTipoTratamiento: tipoTratamiento.codigoTipoTratamiento
      },

      transaccionEstado: [
        {
          estado: {
            codigoEstado: 2
          }
        }
      ],
      sucursalTransaccion: formatoSucursalesTr,
      ejecutoTratamiento: ejecutoTratamiento
    };

    if (patenteTransportista) {
      Object.assign(dataFormateada, {
        patente: patenteTransportista
      });
    }

    if (rutTransportista) {
      Object.assign(dataFormateada, {
        rutEmpresaTransportista: rutTransportista
      });
    }

    console.log('DATA A ENVIAR PARA TERMINAR TRANSACCION >> ', dataFormateada);
    console.log('CERRAR TRANSACCIONES SERVICE');

    let codigoTransaccion = transaccionSelected.codigoTransaccion;
    serviceRSD
      .cerrarTransacciones(dataFormateada, codigoTransaccion)
      .then(result => {
        let stateTemp = {
          codigoTransaccion: codigoTransaccion,
          tipo: 'aprobar'
        };
        history.push({
          pathname: '/transacciones/resumen',
          state: stateTemp
        });
      })
      .catch(err => {
        console.log('ERROR AL CERRAR LA TRANSACCION:');
        console.error('err ::> ', err);
        storeMessages.message.setMessage({
          mensaje:
            'Ha ocurrido un error al tratar de cerrar la transacción. Por favor inténtelo más tarde.',
          tipo: 'error'
        });
      });
  };

  // SOLO PARA DEMANDANTES
  const formatoSucursal = (sucursal, nombreRol, ejecutoTratamiento) => {
    return {
      ejecutoTratamiento:
        ejecutoTratamiento && nombreRol === 'gestor' ? true : false,
      nombreRol: sucursal ? nombreRol : '',
      sucursal: { codigoSucursal: sucursal.codigoSucursal }
    };
  };

  return (
    <Formik
      initialValues={{
        tipoTratamiento: '',
        tipoTransporte: '',
        ejecutoTratamiento: '',
        fechaRecepcion: new Date(),
        sucursalGestor: '',
        sucursalAsesor: '',
        sucursalTransportista: '',
        rutTransportista: '',
        patenteTransportista: ''
      }}
      validateOnMount={true}
      validateOnChange={true}
      validationSchema={SchemaTrasaccion}
    >
      {({
        handleReset,
        values,
        errors,
        handleChange,
        handleBlur,
        touched,
        setFieldTouched,
        setFieldValue
      }) => (
        <Form className="w-full">
          <Dialog
            onClose={handleCloseModal}
            maxWidth="md"
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
          >
            <DialogContent>
              <ModalTransaccionesFormik
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                values={values}
                errors={errors}
                listadoTiposTransporte={listadoTiposTransporte}
                listadoEmpresasGestor={listadoEmpresasGestor}
                listadoEmpresasAsesor={listadoEmpresasAsesor}
                listadoEmpresasTransportista={listadoEmpresasTransportista}
                listadoTiposTratamiento={listadoTiposTratamiento}
              />
            </DialogContent>
            <DialogActions className="border-t border-neutral-90 p-4 justify-between">
              <ButtonComponent
                type="secondary"
                onClick={() => {
                  handleReset();
                  handleCloseModal();
                }}
                className="justify-center"
              >
                <Hidden smDown>Cancelar</Hidden>
                <Hidden smUp>
                  <Close />
                </Hidden>
              </ButtonComponent>
              <ButtonComponent
                type="primary"
                disabled={Object.keys(errors).length !== 0}
                onClick={() => {
                  handleCerrarTransaccion(values);
                }}
                autoFocus
                className="w-full xs:w-auto justify-center"
              >
                Cerrar transacción
              </ButtonComponent>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};
