
import { useState } from 'react';
// Importaciones de terceros
import storeMessages from 'states/alertMessages/store';
import { CrearEmpresaAT, EditarSubmitEmpresaAT } from 'views/CargaMasiva/hooks/useServices';

export const useSubmitEmpresasAT = (codigoEmpresaAT) => {

  const [isNuevaEmpresaAT, setIsNuevaEmpresaAT] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const diccionarioVista = {
    tituloListado: 'Listado Empresas Análisis territorial',
    subTituloListado: 'Administración de las empresas que se encuentran registradas en la plataforma',
    tituloTabla: 'Empresas Análisis territorial registradas',
    nombrePrincipal: 'Empresas Análisis territorial',
    nombrePrincipalMayuscula: 'EMPRESAS ANÁLISIS TERRITORIAL',
    nombrePrincipalSingular: 'empresa análisis territorial',
    nombrePrincipalPlural: 'empresas análisis territorial',
    primerNombrePrincipal: 'empresa',
  }

  const obtieneCodigoEmpresasATDestino = (potencialSinergias) => {
    let codigosEmpresasDestino = [];

    potencialSinergias.forEach((element) => {

      codigosEmpresasDestino.push(element.codigoEmpresaAT || element.empresaATDestino.codigoEmpresaAT);
    });
    const data = [];
    for (const numero of codigosEmpresasDestino) {
      const item = {
        "empresaATDestino": {
          "codigoEmpresaAT": numero
        }
      };
      data.push(item);
    }
    // console.log(data);
    return data;
  };

  const handleSubmit = async (valores, tipoFormulario) => {
    try {

      const actionMap = {
        'ingresar': isNuevaEmpresaAT ? 'SUBMITING NUEVA EMPRESA AT' : '',
        'duplicar': !isNuevaEmpresaAT ? 'SUBMITING DUPLICANDO EMPRESA AT' : '',
        'editar': !isNuevaEmpresaAT ? 'SUBMITING EDITANDO EMPRESA AT' : '',
      };

      const submitAction = actionMap[tipoFormulario];
      // console.log(submitAction);

      if (tipoFormulario === 'ingresar' || tipoFormulario === 'duplicar') {
        await submitNuevoEmpresaAT(valores,tipoFormulario);
      } else if (tipoFormulario === 'editar') {
        await submitActualizarEmpresaAT(valores);
      }

    } catch (error) {
      console.log('Error en handleSubmit ', error);
      storeMessages.message.setMessage({
        mensaje: `No se ha podido realizar la operación de ${diccionarioVista.nombrePrincipalSingular}`,
        tipo: 'error'
      });
      throw new Error('Error en handleSubmit ', error);
    }
  };

  const submitActualizarEmpresaAT = async(valores) => {
    try {
      const empresasAtData = estructuraServicioActualizaEmpresaAT(valores);

      const codigoEmpresaAT = parseInt(valores.codigoEmpresaAT);

      // console.log(empresasAtData);
      // console.log(codigoEmpresaAT);

      await EditarSubmitEmpresaAT({ setIsLoading, empresasAtData, codigoEmpresaAT });

    } catch (error) {
      console.log(`Error en la edición de una ${diccionarioVista.nombrePrincipalSingular} ${error}`);
      storeMessages.message.setMessage({
        mensaje: `No se ha podido realizar la operación de edición de una ${diccionarioVista.nombrePrincipalSingular}`,
        tipo: 'error'
      });
    }
  }

  const submitNuevoEmpresaAT = async(valores,tipoFormulario) => {
    
    try {
      const empresasAtData = estructuraServicioNuevoEmpresaAT(valores);

      // console.log(empresasAtData);

      await CrearEmpresaAT({ setIsLoading, empresasAtData });

    } catch (error) {
      console.log(`Error en el ${tipoFormulario === 'ingresar ' ? 'ingreso' : 'duplicación'}  de una ${diccionarioVista.nombrePrincipalSingular} ${error}`);
      storeMessages.message.setMessage({
        mensaje: `No se ha podido realizar la operación de ${tipoFormulario === 'ingresar ' ? 'ingreso' : 'duplicación'} de una  ${diccionarioVista.nombrePrincipalSingular}`,
        tipo: 'error'
      });

    }
  }


  const estructuraServicioActualizaEmpresaAT = (values) => {

    try {
      const estructuraServicio = {
        rutEmpresa: values.rutEmpresa,
        razonSocial: values.razonSocial,
        tramo: parseInt(values.tramo),
        actividad: values.actividad?.codigoCiuActividad || values.actividad?.value,
        nombreVia: values.nombreVia,
        direccion: {
          comuna: {
            codigoComuna: values.comuna?.codigoComuna || values.comuna?.value,
          },
          nombreVia: values.nombreVia,
          latitudDireccion: values.latitudDireccion,
          longitudDireccion: values.longitudDireccion,
        },
        
        potencialSinergias: obtieneCodigoEmpresasATDestino(values.potencialSinergias),
      };

      // console.log('request :>> ', estructuraServicio);
      return estructuraServicio;
    } catch (error) {
      console.log('error :>> ', error);
    }
  }


  const estructuraServicioNuevoEmpresaAT = (values) => {
      
      try {
        const estructuraServicio = {
          rutEmpresa: values.rutEmpresa,
          razonSocial: values.razonSocial,
          tramo: parseInt(values.tramo),
          actividad: values.actividad?.codigoCiuActividad || values.actividad?.value,
          nombreVia: values.nombreVia,
          direccion: {
            comuna: {
              codigoComuna: values.comuna?.codigoComuna || values.comuna?.value,
            },
            nombreVia: values.nombreVia,
            latitudDireccion: values.latitudDireccion,
            longitudDireccion: values.longitudDireccion,
          },
          
          potencialSinergias: obtieneCodigoEmpresasATDestino(values.potencialSinergias),
        };
  
        // console.log('request :>> ', estructuraServicio);
        return estructuraServicio;
      } catch (error) {
        console.log('error :>> ', error);
      }
    }


  return {
    handleSubmit,
    isLoading,
    setIsLoading,
  }

}
