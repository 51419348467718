import { useEffect, useMemo, useState } from 'react';

// Design System
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import Heading from 'components/design-system/Heading/Heading';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import Text from 'components/design-system/Text/Text';

// Diccionarity

// Material

import {
  handleKeyPress,
  handlePaste
} from 'views/Business/utils/handleKeyPress';
import { useDireccion } from 'hooks/useDireccion';
import MapAddress from 'components/MapAddress/MapAddress';
import InputNumber from 'components/design-system/input/InputNumber';
import { DICTONARY } from 'const/Dictonary';
import { CircularProgress } from '@mui/material';
import ButtonComponent from 'components/design-system/Button/Button';
import { GpsOff, MyLocation } from '@mui/icons-material';

// Library

const Receptor = ({
  // Formik
  errors,
  // handleBlur,
  handleChange,
  // handleReset,
  // handleSubmit,
  isSubmitting,
  // setFieldError,
  setFieldTouched,
  setFieldValue,
  touched,
  // validateForm,
  values,

  // Dependencias

  // Empresas SII
  cargaEmpresasSII,
  empresasSII,
  isLoadingEmpresasSII,
  setEmpresasSII,
  setIsLoadingEmpresasSII,

  // Tipo de Tratamiento
  tipoTratamiento,
  setTipoTratamiento,
  isLoadingTipoTratamiento,
  setIsLoadingTipoTratamiento,

  // Tipo Formulario
  tipoForm
}) => {
  const [razonSocialEmpresaReceptor, setRazonSocialEmpresaReceptor] =
    useState('');

  const { duplicar, editar } = tipoForm;

  const {
    latLon,
    setLatLon,
    addressQuerysearch,
    addressQueryParams,
    regiones,
    cargaListaRegiones,
    handleObtenerDireccion,
    handleObtenerComunas,
    listadoComunas,
    cargaListaComunas,
    setBuscandoDireccion,
    buscandoDireccion
  } = useDireccion();

  useEffect(() => {
    if (!empresasSII) return;
    if (empresasSII.razonSocial) {
      setRazonSocialEmpresaReceptor(empresasSII.razonSocial);
    } else {
      setRazonSocialEmpresaReceptor('Sin información');
    }
  }, [empresasSII, isLoadingEmpresasSII]);

  // Carga RUT al ingresar al formulario
  useMemo(() => {
    if (
      (duplicar || editar) &&
      !touched.rutEmpresaReceptor &&
      values.rutEmpresaReceptor
    ) {
      cargaEmpresasSII(values.rutEmpresaReceptor);
    }
  }, [
    cargaEmpresasSII,
    duplicar,
    editar,
    touched.rutEmpresaReceptor,
    values.rutEmpresaReceptor
  ]);

  //  Servicio Comunas
  useMemo(() => {
    if (!values.region) return;
    if (values.region && values.region.codigoRegion) {
      handleObtenerComunas(values.region.codigoRegion);
    }
  }, [handleObtenerComunas, values.region]);

  // Estados de carga de servicios
  useMemo(() => {
    if (isSubmitting) return;
    handleObtenerDireccion(values);
  }, [isSubmitting, handleObtenerDireccion, values]);

  // Verificacion de cambios en los campos de direccion
  useMemo(() => {
    const { nombreVia, comuna, region, isPointEditing } = values;

    if (isSubmitting || !latLon) return;

    const hasNombreVia = nombreVia && touched.nombreVia;
    const hasComuna = comuna && touched.comuna;
    const hasRegion = region && touched.region;

    if (hasNombreVia || hasComuna || hasRegion || isPointEditing) {
      setBuscandoDireccion(true);
      setFieldValue('latitudDireccion', latLon[0]);
      setFieldValue('longitudDireccion', latLon[1]);
    }
  }, [
    isSubmitting,
    latLon,
    setBuscandoDireccion,
    setFieldValue,
    touched.comuna,
    touched.nombreVia,
    touched.region,
    values
  ]);

  return (
    <div className="col-span-12">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-12">
          <Heading type="h4" className="mb-0">
            Receptor
          </Heading>
        </div>
        <div className="col-span-12 xs:col-span-12">
          <div className="grid grid-cols-12 gap-4">
            {/* Rut Empresa Receptor */}
            <div className="col-span-12 xs:col-span-6">
              <InputComponent
                autoComplete="off"
                disabled={isSubmitting}
                fullWidth
                name={'rutEmpresaReceptor'}
                value={values.rutEmpresaReceptor || ''}
                onChange={event => {
                  const { value } = event.target;

                  // verificar si el rut es valido

                  if (
                    value.length >= 9 &&
                    value.charAt(value.length - 2) === '-'
                  ) {
                    setFieldValue('rutEmpresaReceptor', value.trim());
                    cargaEmpresasSII(value);
                  } else {
                    setFieldValue('rutEmpresaReceptor', value.trim());
                    setRazonSocialEmpresaReceptor('Sin información');
                  }
                  setFieldTouched('tipoTratamiento');
                }}
                onBlur={() => {
                  setFieldTouched('rutEmpresaReceptor');
                }}
                outerInputProps={{
                  inputProps: {
                    maxLength: 12,
                    // minLenth: 9,
                    onKeyPress: handleKeyPress
                  }
                }}
                onPaste={handlePaste}
                touched={touched.rutEmpresaReceptor}
                placeholder="Ej: 12345678-9"
                estado={
                  errors.rutEmpresaReceptor
                    ? { tipo: 'error', mensaje: errors.rutEmpresaReceptor }
                    : null
                }
                label={<LabelComponent>Rut </LabelComponent>}
              />
            </div>
            {/* Razon Social */}
            <div className="col-span-12 xs:col-span-6 pt-0.5">
              <div className="rounded bg-neutral-90 p-2.5 mt-6 relative">
                <Text className="min-h-[1.25rem]">
                  {empresasSII &&
                  empresasSII.razonSocial &&
                  !isLoadingEmpresasSII &&
                  !errors.rutEmpresaReceptor &&
                  touched.rutEmpresaReceptor
                    ? razonSocialEmpresaReceptor
                    : !touched.rutEmpresaReceptor && !errors.rutEmpresaReceptor
                    ? ''
                    : 'Sin información'}
                  {isLoadingEmpresasSII &&
                    touched.rutEmpresaReceptor &&
                    !errors.rutEmpresaReceptor && (
                      <CircularProgress
                        color="inherit"
                        className="opacity-40 !w-5 !h-5 absolute right-2.5 top-2.5"
                      />
                    )}
                </Text>
              </div>
            </div>
            {/* Region */}
            <div className="col-span-12 md:col-span-6 xl:col-span-3">
              <AutocompleteComponent
                name="region"
                clearOnEscape={true}
                accesor="nombreRegion"
                openOnFocus={true}
                options={regiones}
                disabled={!cargaListaRegiones}
                onChange={(ev, region, reason) => {
                  let data = { name: 'region', value: region };
                  if (reason === 'clear') {
                    data.value = [];
                    setFieldValue('comuna', []);
                  }
                  setFieldValue('comuna', []);
                  setFieldValue('region', data.value);

                  // Tocar los 3 campos para realizar la busqueda
                  setFieldTouched('region');
                  setFieldTouched('comuna');
                  setFieldTouched('nombreVia');
                }}
                onBlur={() => setFieldTouched('region')}
                value={values.region}
                getOptionLabel={option => option.nombreRegion || ''}
                isOptionEqualToValue={(option, value) =>
                  option.nombreRegion === values.region.nombreRegion
                }
              >
                <InputComponent
                  autoComplete={'off'}
                  fullWidth
                  touched={touched.region ? true : false}
                  estado={
                    errors.region
                      ? { tipo: 'error', mensaje: errors.region }
                      : null
                  }
                  label={<LabelComponent>Región</LabelComponent>}
                />
              </AutocompleteComponent>
            </div>

            {/* Comuna */}
            <div className="col-span-12 md:col-span-6 xl:col-span-3">
              <AutocompleteComponent
                name="comuna"
                accesor="nombreComuna"
                options={listadoComunas}
                disabled={!cargaListaComunas}
                onChange={(ev, comuna, reason) => {
                  let data = { name: 'comuna', value: comuna };

                  if (reason === 'clear') {
                    data.value = [];
                  }

                  setFieldValue('comuna', data.value);

                  // Tocar los 3 campos para realizar la busqueda
                  setFieldTouched('region');
                  setFieldTouched('comuna');
                  setFieldTouched('nombreVia');
                }}
                value={values.comuna}
                getOptionLabel={option => option.nombreComuna || ''}
                isOptionEqualToValue={(option, value) =>
                  option.nombreComuna === values.comuna.nombreComuna
                }
                onBlur={() => setFieldTouched('comuna')}
              >
                <InputComponent
                  autoComplete={'off'}
                  fullWidth
                  touched={touched.comuna ? true : false}
                  estado={
                    errors.comuna
                      ? { tipo: 'error', mensaje: errors.comuna }
                      : null
                  }
                  label={<LabelComponent>Comuna</LabelComponent>}
                />
              </AutocompleteComponent>
            </div>
            {/* Tipo de Via */}
            <div className="col-span-12 md:col-span-6 xl:col-span-6">
              <InputComponent
                autoComplete={'off'}
                fullWidth
                label={<LabelComponent>Nombre y número calle</LabelComponent>}
                name="nombreVia"
                value={values.nombreVia}
                onChange={e => {
                  handleChange(e);
                  // Tocar los 3 campos para realizar la busqueda
                  setFieldTouched('region');
                  setFieldTouched('comuna');
                  setFieldTouched('nombreVia');
                }}
                onBlur={() => setFieldTouched('nombreVia')}
                touched={touched.nombreVia ? true : false}
                placeholder="Ej: Providencia, 1091"
                estado={
                  errors.nombreVia
                    ? { tipo: 'error', mensaje: errors.nombreVia }
                    : null
                }
              />
            </div>
            {/* Mapa */}
            <div className="col-span-12  xxs:col-span-12 xs:col-span-12 ">
              <div
                className={`${
                  values.isPointEditing ? ' bg-warning-light' : 'bg-info-light '
                }
                              sm:flex px-4 py-2.5 justify-between rounded items-center`}
              >
                <Text>
                  {values.isPointEditing
                    ? DICTONARY.MAPA_MENSAJES.INFO_DESACTIVAR_EDICION
                    : DICTONARY.MAPA_MENSAJES.INFO_ACTIVAR_EDICION}
                </Text>
                <ButtonComponent
                  className="min-w-[190px] md:min-w-fit mt-4 sm:mt-0"
                  onClick={() => {
                    setFieldValue('isPointEditing', !values.isPointEditing);
                  }}
                >
                  {values.isPointEditing && latLon ? (
                    <>
                      Desactivar edición <GpsOff className="ml-2" />
                    </>
                  ) : (
                    <>
                      Edición manual <MyLocation className="ml-2" />
                    </>
                  )}
                </ButtonComponent>
              </div>
            </div>

            <div className="col-span-12 rounded overflow-hidden">
              <MapAddress
                addressQuerysearch={addressQuerysearch}
                addressQueryParams={addressQueryParams}
                latLon={latLon}
                setLatLon={setLatLon}
                isPointEditing={values.isPointEditing}
                setIsPointEditing={value =>
                  setFieldValue('isPointEditing', value)
                }
                isLoading={isSubmitting}
                buscandoDireccion={buscandoDireccion}
              />
            </div>
            {/* Codigo Sinader */}
            <div className="col-span-12 xs:col-span-4">
              <InputNumber
                autoComplete="off"
                disabled={isSubmitting}
                maxDecimals={DICTONARY.NUMERO_DECIMALES}
                name={'codigoSINADER'}
                placeholder={!values.codigoSINADER ? '' : ''}
                touched={touched.codigoSINADER}
                type="text"
                value={values.codigoSINADER_str || values.codigoSINADER || ''}
                onChange={event => {
                  const { value } = event.target;
                  const sanitizedValue = value
                    .replaceAll('.', '')
                    .replaceAll(',', '.');
                  const floatValue = parseFloat(sanitizedValue);
                  // maximo 10 digitos

                  if (!isNaN(floatValue)) {
                    setFieldValue('codigoSINADER', floatValue);
                  }
                }}
                onFocus={event => {
                  const { value } = event.target;
                  setFieldTouched('codigoSINADER');
                  if (
                    !event ||
                    !event.target ||
                    typeof event.target.value !== 'string'
                  ) {
                    return;
                  }
                  let sanitizedValue = value.split('.').join('') || '';
                  setFieldValue('codigoSINADER_str', sanitizedValue);
                }}
                onBlur={() => {
                  setFieldTouched('codigoSINADER');
                }}
                label={
                  <LabelComponent>
                    Código Sinader{' '}
                    <span className="text-neutral-60">(opcional)</span>
                  </LabelComponent>
                }
                estado={
                  errors.codigoSINADER
                    ? { tipo: 'error', mensaje: errors.codigoSINADER }
                    : null
                }
              />
            </div>
            {/* Tipo de Tratamiento */}
            <div className="col-span-12 md:col-span-6 xl:col-span-4">
              <AutocompleteComponent
                fullWidth
                accesor="nombreTipoTratamiento"
                clearOnEscape={true}
                disabled={isSubmitting || isLoadingTipoTratamiento}
                name="tipoTratamiento"
                touched={touched.tipoTratamiento}
                onBlur={() => setFieldTouched('tipoTratamiento')}
                value={values.tipoTratamiento || ''}
                onChange={(event, newValue, reason) => {
                  let data = { name: 'tipoTratamiento', value: newValue };
                  if (reason === 'clear') {
                    data.value = '';
                  }
                  setFieldValue('tipoTratamiento', data.value);
                }}
                options={tipoTratamiento || []}
                getOptionLabel={option => option.nombreTipoTratamiento || ''}
                noOptionsText={
                  isLoadingTipoTratamiento ? 'Cargando...' : 'Sin resultados'
                }
              >
                <InputComponent
                  fullWidth
                  autoComplete="off"
                  disabled={isSubmitting || isLoadingTipoTratamiento}
                  touched={touched.tipoTratamiento}
                  onBlur={() => {
                    setFieldTouched('tipoTratamiento');
                  }}
                  placeholder={
                    isLoadingTipoTratamiento
                      ? 'Cargando...'
                      : tipoTratamiento.length === 0 &&
                        !isLoadingTipoTratamiento
                      ? ''
                      : 'Seleccione una opción'
                  }
                  estado={
                    errors.tipoTratamiento
                      ? { tipo: 'error', mensaje: errors.tipoTratamiento }
                      : null
                  }
                  label={<LabelComponent>Tipo de tratamiento</LabelComponent>}
                />
              </AutocompleteComponent>
            </div>
            {/* Nº Guia de Despacho */}
            <div className="col-span-12 md:col-span-6  xs:col-span-4">
              <InputNumber
                autoComplete="off"
                disabled={isSubmitting}
                maxDecimals={DICTONARY.NUMERO_DECIMALES}
                name={'numeroGuiaDespacho'}
                placeholder={!values.numeroGuiaDespacho ? '' : ''}
                touched={touched.numeroGuiaDespacho}
                type="text"
                value={
                  values.numeroGuiaDespacho_str ||
                  values.numeroGuiaDespacho ||
                  ''
                }
                onChange={event => {
                  const { value } = event.target;
                  const sanitizedValue = value
                    .replaceAll('.', '')
                    .replaceAll(',', '.');
                  const floatValue = parseFloat(sanitizedValue);
                  if (!isNaN(floatValue)) {
                    setFieldValue('numeroGuiaDespacho', floatValue);
                  }
                }}
                onFocus={event => {
                  const { value } = event.target;
                  setFieldTouched('numeroGuiaDespacho');
                  if (
                    !event ||
                    !event.target ||
                    typeof event.target.value !== 'string'
                  ) {
                    return;
                  }
                  let sanitizedValue = value.split('.').join('') || '';
                  setFieldValue('numeroGuiaDespacho_str', sanitizedValue);
                }}
                onBlur={() => {
                  setFieldTouched('numeroGuiaDespacho');
                }}
                label={
                  <LabelComponent>
                    Nº guía de despacho{' '}
                    <span className="text-neutral-60">(opcional)</span>
                  </LabelComponent>
                }
                estado={
                  errors.numeroGuiaDespacho
                    ? { tipo: 'error', mensaje: errors.numeroGuiaDespacho }
                    : null
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Receptor;
