import Close from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Hidden from '@mui/material/Hidden';
// Components
import AlertComponent from 'components/design-system/Alert/Alert';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';

import Text from 'components/design-system/Text/Text';

export const EliminarMaterial = ({
  open,
  handleClickClose,
  handleClickEliminar,
  selectedDocumento,
  isEliminando
}) => {
  return (
    <Dialog
      open={open}
      className="uv-dialog"
      onClose={handleClickClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Heading type="h3" className="mb-0">
          Confirmación de eliminación
        </Heading>
      </DialogTitle>
      <DialogContent className="p-5">
        <DialogContentText id="alert-dialog-description">
          <Text>
            ¿Estás seguro que deseas eliminar completamente el siguiente
            material de información?
          </Text>
          <div className="my-4">
            <div className="grid grid-cols-12 gap-4">
              {/* {selectedDocumento.nombre && (
                <>
                  <div className="col-span-4 text-end">
                    <Text
                      type="s"
                      className="uppercase font-secondary !text-neutral-50 !font-normal"
                    >
                      Nombre del documento
                    </Text>
                  </div>
                  <div className="col-span-8">
                    <Heading type="h5" className="pr-10 mb-0 font-medium">
                      {selectedDocumento.nombre}
                    </Heading>
                  </div>
                </>
              )} */}

              {/* {selectedDocumento.marcoLegal && (
                <>
                  <div className="col-span-4 text-end">
                    <Text
                      type="s"
                      className="uppercase font-secondary !text-neutral-50 !font-normal"
                    >
                      Marco legal y tramitación
                    </Text>
                  </div>
                  <div className="col-span-8">
                    <Heading type="h5" className="pr-10 mb-0 font-medium">
                      {selectedDocumento.marcoLegal}
                    </Heading>
                  </div>
                </>
              )} */}

              {/* {selectedDocumento.normativaTecnica && (
                <>
                  <div className="col-span-4 text-end">
                    <Text
                      type="s"
                      className="uppercase font-secondary !text-neutral-50 !font-normal"
                    >
                      Normativa técnica
                    </Text>
                  </div>
                  <div className="col-span-8">
                    <Heading type="h5" className="pr-10 mb-0 font-medium">
                      {selectedDocumento.normativaTecnica}
                    </Heading>
                  </div>
                </>
              )} */}

              {/* {selectedDocumento.publicaciones && (
                <>
                  <div className="col-span-4 text-end">
                    <Text
                      type="s"
                      className="uppercase font-secondary !text-neutral-50 !font-normal"
                    >
                      Publicaciones
                    </Text>
                  </div>
                  <div className="col-span-8">
                    <Heading type="h5" className="pr-10 mb-0 font-medium">
                      {selectedDocumento.publicaciones}
                    </Heading>
                  </div>
                </>
              )} */}

              {/* {selectedDocumento.tipoTratamientoRSD && (
                <>
                  <div className="col-span-4 text-end">
                    <Text
                      type="s"
                      className="uppercase font-secondary !text-neutral-50 !font-normal"
                    >
                      Tipo de tratamiento de Residuo
                    </Text>
                  </div>
                  <div className="col-span-8">
                    <Heading type="h5" className="pr-10 mb-0 font-medium">
                      {selectedDocumento.tipoTratamientoRSD}
                    </Heading>
                  </div>
                </>
              )} */}

              {/* {selectedDocumento.comuna && (
                <>
                  <div className="col-span-4 text-end">
                    <Text
                      type="s"
                      className="uppercase font-secondary !text-neutral-50 !font-normal"
                    >
                      Comunas de la región
                    </Text>
                  </div>
                  <div className="col-span-8">
                    <Heading type="h5" className="pr-10 mb-0 font-medium">
                      {selectedDocumento.comuna
                        ? selectedDocumento.comuna.nombreComuna ||
                          selectedDocumento.comuna.valueOf()
                        : null}
                    </Heading>
                  </div>
                </>
              )} */}
            </div>
          </div>
          <AlertComponent type="error">
            Esta operación es irreversible y{' '}
            <b>el material de información quedará completamente eliminado de la plataforma</b>
          </AlertComponent>
        </DialogContentText>
      </DialogContent>

      <DialogActions className="border-t border-neutral-90 p-4">
        <div className="flex justify-between">
          <ButtonComponent type="secondary" onClick={handleClickClose}>
            <Hidden xsDown>Cancelar</Hidden>
            <Hidden smUp>
              <Close />
            </Hidden>
          </ButtonComponent>
          <ButtonComponent
            type="primary"
            disabled={isEliminando}
            isLoading={isEliminando}
            onClick={e => handleClickEliminar(e, selectedDocumento)}
            autoFocus
            className="w-full xs:w-auto"
          >
            {isEliminando ? 'Eliminando...' : 'Confirmar'}
          </ButtonComponent>
        </div>
      </DialogActions>
    </Dialog>
  );
};
