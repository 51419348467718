import { withStyles } from '@mui/styles';
// Date Picker
import { es } from 'date-fns/locale';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// Material UI
import { ListItem, ListItemText, Tooltip } from '@mui/material';
import { Place } from '@mui/icons-material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// Design System
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import Heading from 'components/design-system/Heading/Heading';
import InputComponent from 'components/design-system/input/Input';
import InputNumber from 'components/design-system/input/InputNumber';
import LabelComponent from 'components/design-system/Label/Label';
import ListComponent from 'components/design-system/List/List';
// Hooks
import {
  ObtieneLevantamiento,
  ObtieneListaSucursales,
  ObtieneListaTipoDisposicion
} from 'hooks';
import { useObtieneCodigoEmpresa } from 'views/Residuos/hooks/Helpers/useObtieneCodigoEmpresa';
// Components
import { TablaConversion } from '../Others/TablaConversion';
// Const
import { DICTONARY } from 'const/Dictonary';
// Helpers
import usePrecioReferencial from 'views/Residuos/hooks/Helpers/usePrecioReferencial';
// Utils
import { tooltipText } from 'views/Residuos/utils/utils';
// Data
import { frecuencia_options } from 'views/Residuos/data/espesificacionOferta';
import store from 'states/store';

const Tooltipcolor = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: '12px'
  }
}))(Tooltip);

export const EspesificacionOferta = ({
  errors,
  // handleBlur,
  handleChange,
  // handleReset,
  // handleSubmit,
  isSubmitting,
  // setFieldError,
  setFieldTouched,
  setFieldValue,
  touched,
  // validateForm,
  values,
  tipoFormularioSeleccionado
}) => {
  const { isSuperAdmin } = store.loguedUser.perfiles;
  const {ingresar, editar, duplicar } = tipoFormularioSeleccionado;
  const { codigoEmpresaState } = useObtieneCodigoEmpresa({
    setFieldTouched
  });

  const codigoEmpresa = (editar || duplicar)
  ? (isSuperAdmin ? values?.empresa?.codigoEmpresa : (!isSuperAdmin ? values?.empresa?.codigoEmpresa : null))
  : (ingresar ? codigoEmpresaState : null);

  const {
    listaSucursales,
    cargandoListaSucursales,
  } = ObtieneListaSucursales(codigoEmpresa);


  const { handleFocusPrecioReferencial } = usePrecioReferencial({
    setFieldTouched,
    setFieldValue
  });

  const { listaTipoDisposicion } = ObtieneListaTipoDisposicion(
    values.tipoResiduo?.codigoTipoResiduo
  );

  const { levantamiento, isLoadingLevantamiento } = ObtieneLevantamiento();

  return (
    <div className="col-span-12">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-12">
          <Heading type="h4" className="mb-0 text-uv-primary-10">
            Especificación de oferta
          </Heading>
        </div>
        <div className="col-span-12 xs:col-span-12">
          {/* Fila 1 */}
          <div className="grid grid-cols-12 gap-4 align-middle">
            {/* Sucursales */}
            <div className="col-span-12 xs:col-span-4">
              <AutocompleteComponent
                className="mb-3 "
                clearOnEscape={true}
                disabled={cargandoListaSucursales}
                id="codigoSucursal"
                name="codigoSucursal"
                openOnFocus={true}
                variant="outlined"
                accesor="nombreSucursal"
                onBlur={() => {
                  setFieldTouched('codigoSucursal');
                  setFieldTouched('nombreSucursal');
                }}
                options={listaSucursales}
                value={
                  listaSucursales.find(
                    s => s.codigoSucursal === values.codigoSucursal
                  ) || ''
                }
                getOptionLabel={option => option.nombreSucursal || ''}
                noOptionsText={
                  cargandoListaSucursales ? 'Cargando...' : 'Sin opciones'
                }
                onChange={(ev, selected) => {
                  if (!selected) {
                    setFieldValue('codigoSucursal', '');
                    setFieldValue('nombreSucursal', '');
                    setFieldValue('nombreDireccionSucursal', '');
                    return;
                  }
                  const direccion = selected ? selected.direccion : {};
                  const nombreDireccion = `${direccion.nombreVia}, ${direccion.comuna?.nombreComuna}, Región ${direccion.comuna?.region?.nombreRegion} `;

                  setFieldValue('nombreDireccionSucursal', nombreDireccion);

                  setFieldValue(
                    'codigoSucursal',
                    selected ? selected.codigoSucursal : 0
                  );
                  setFieldValue(
                    'nombreSucursal',
                    selected ? selected.nombreSucursal : ''
                  );

                  setFieldTouched('precioReferencial', true);
                }}
              >
                <InputComponent
                  className="h-9"
                  fullWidth
                  placeholder={
                    listaSucursales.length ? 'Seleccione sucursal' : ''
                  }
                  touched={touched.codigoSucursal}
                  estado={
                    errors.codigoSucursal
                      ? {
                          tipo: 'error',
                          mensaje: errors.codigoSucursal
                        }
                      : null
                  }
                  label={
                    <>
                      <LabelComponent>
                        Seleccionar la sucursal de origen
                      </LabelComponent>
                    </>
                  }
                />
              </AutocompleteComponent>
            </div>

            {/* Dirección */}
            <div className="col-span-12 xs:col-span-8  pl-4 pt-2  mt-7">
              <div className="address-icon ">
                <Place />
                {values.nombreDireccionSucursal
                  ? values.nombreDireccionSucursal
                  : 'Seleccionar sucursal'}
              </div>
            </div>
          </div>

          {/* Fila 2 */}
          <div className="grid grid-cols-12 gap-4 mt-2">
            {/* Precio Referencial */}
            <div className="col-span-12 xs:col-span-4">
              <InputNumber
                maxDecimals={DICTONARY.NUMERO_DECIMALES}
                type="text"
                prefix={'$'}
                onFocus={handleFocusPrecioReferencial}
                label={
                  <LabelComponent>
                    Precio Referencial
                    <Tooltipcolor
                      style={{ marginLeft: '5px', marginBottom: '-5px' }}
                      title={tooltipText}
                    >
                      <HelpOutlineIcon color="primary" fontSize="small" />
                    </Tooltipcolor>
                  </LabelComponent>
                }
                name="precioReferencial"
                onChange={handleChange}
                touched={touched.precioReferencial}
                onBlur={e => {
                  setFieldTouched('precioReferencial');
                  setFieldTouched('frecuencia', true);
                }}
                value={values.precioReferencial_str || values.precioReferencial}
                placeholder={
                  !values.precioReferencial ? 'Ingrese precio referencia' : ''
                }
                estado={
                  errors.precioReferencial
                    ? {
                        tipo: 'error',
                        mensaje: errors.precioReferencial
                      }
                    : null
                }
              />
            </div>

            {/* Frecuencia */}
            <div className="col-span-12 xs:col-span-4">
              <AutocompleteComponent
                className="mb-3 "
                clearOnEscape={true}
                disabled={cargandoListaSucursales}
                id="frecuencia"
                name="frecuencia"
                accesor="label"
                openOnFocus={false}
                size="small"
                options={frecuencia_options}
                onBlur={() => {
                  setFieldTouched('frecuencia');
                  setFieldTouched('cantidad', true);
                }}
                value={
                  frecuencia_options.find(c => c.value === values.frecuencia) ||
                  ''
                }
                getOptionLabel={option => (option ? option.label : '')}
                noOptionsText={'Sin opciones'}
                onChange={(ev, selected) => {
                  if (!selected) return;
                  setFieldValue('frecuencia', selected.value);
                }}
              >
                <InputComponent
                  className="h-9"
                  fullWidth
                  placeholder={
                    frecuencia_options.length ? 'Seleccione frecuencia' : ''
                  }
                  touched={touched.frecuencia}
                  estado={
                    errors.frecuencia
                      ? {
                          tipo: 'error',
                          mensaje: errors.frecuencia
                        }
                      : null
                  }
                  label={
                    <>
                      <LabelComponent>Frecuencia</LabelComponent>
                    </>
                  }
                />
              </AutocompleteComponent>
            </div>

            {/* Fecha de disponibilidad */}

            <div className="col-span-12 xs:col-span-4">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={es}
              >
                <DesktopDatePicker
                  className="mb-4"
                  name="fechaDisponibilidad"
                  label={<LabelComponent>Fecha Disponibilidad</LabelComponent>}
                  inputFormat="dd/MM/yyyy"
                  value={values.fechaDisponibilidad}
                  defaultValue={values.fechaDisponibilidad}
                  maxDate={values.fechaDisponibilidadFin}
                  onChange={value => {
                    handleChange({
                      target: {
                        name: 'fechaDisponibilidad',
                        value: value
                      }
                    });
                  }}
                  renderInput={params => (
                    <InputComponent
                      fullWidth
                      className="pr-8"
                      outerInputProps={params.InputProps}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>

          {/* Fila 3 */}
          <div className="grid grid-cols-12 gap-4 mt-2 ">
            {/* Tabla de conversión */}
            <div className="col-span-12 xs:col-span-4">
              <TablaConversion />
            </div>

            <div className="col-span-12 xs:col-span-4">
              {/* Cantidad */}
              <InputNumber
                autoComplete={'off'}
                maxDecimals={DICTONARY.NUMERO_DECIMALES}
                type="text"
                label={<LabelComponent>Cantidad (Toneladas):</LabelComponent>}
                name="cantidad"
                onChange={event => {
                  const { value } = event.target;
                  const sanitizedValue = value
                    .replaceAll('.', '')
                    .replaceAll(',', '.');
                  const floatValue = parseFloat(sanitizedValue);
                  if (!isNaN(floatValue)) {
                    setFieldValue('cantidad', floatValue);
                  }
                  setFieldTouched('formatoEntrega', true);
                }}
                onFocus={event => {
                  const { value } = event.target;
                  setFieldTouched('cantidad');
                  if (
                    !event ||
                    !event.target ||
                    typeof event.target.value !== 'string'
                  ) {
                    return;
                  }
                  let sanitizedValue = value.split('.').join('') || '';
                  setFieldValue('cantidad_str', sanitizedValue);
                }}
                onBlur={() => {
                  setFieldTouched('cantidad');
                }}
                touched={touched.cantidad}
                value={values.cantidad_str || values.cantidad}
                variant="outlined"
                size="small"
                placeholder={!values.cantidad ? 'Ingrese cantidad' : ''}
                estado={
                  errors.cantidad
                    ? {
                        tipo: 'error',
                        mensaje: errors.cantidad
                      }
                    : null
                }
              />
              {/* Formato de entrega */}

              <InputComponent
                className={''}
                autoComplete={'off'}
                fullWidth
                label={
                  <LabelComponent className="mt-2">
                    Formato Entrega:
                  </LabelComponent>
                }
                name="formatoEntrega"
                onChange={handleChange}
                onBlur={() => setFieldTouched('formatoEntrega')}
                touched={touched.formatoEntrega}
                value={values.formatoEntrega}
                type="text"
                variant="outlined"
                size="small"
                placeholder={!values.formatoEntrega ? 'Ingrese formato' : ''}
                estado={
                  errors.formatoEntrega
                    ? {
                        tipo: 'error',
                        mensaje: errors.formatoEntrega
                      }
                    : null
                }
              />
            </div>

            <div className="col-span-12 xs:col-span-4">
              {/* Levantamiento de datos */}
              <div className="col-span-12 md:col-span-6 xl:col-span-4">
                <AutocompleteComponent
                  accesor="nombreLevantamientoDatos"
                  clearOnEscape={true}
                  disabled={isSubmitting || isLoadingLevantamiento}
                  fullWidth
                  name={'levantamientoDatos'}
                  options={levantamiento}
                  value={values.levantamientoDatos || ''}
                  onChange={(event, newValue, reason) => {
                    let data = { name: 'levantamientoDatos', value: newValue };
                    if (reason === 'clear') {
                      data.value = '';
                    }
                    setFieldValue('levantamientoDatos', data.value);
                  }}
                  getOptionLabel={option =>
                    option.nombreLevantamientoDatos || ''
                  }
                  noOptionsText={
                    isLoadingLevantamiento ? 'Cargando...' : 'Sin opciones'
                  }
                >
                  <InputComponent
                    autocomplete="off"
                    disabled={isLoadingLevantamiento}
                    fullWidth
                    onBlur={() => {
                      setFieldTouched('levantamientoDatos');
                    }}
                    touched={touched.levantamientoDatos}
                    placeholder={
                      isLoadingLevantamiento
                        ? 'Cargando...'
                        : 'Selecciona una opción'
                    }
                    estado={
                      errors.levantamientoDatos
                        ? { tipo: 'error', mensaje: errors.levantamientoDatos }
                        : null
                    }
                    label={
                      <LabelComponent>
                        Levantamiento de datos{' '}
                        <span className="text-neutral-60">(opcional)</span>
                      </LabelComponent>
                    }
                  />
                </AutocompleteComponent>
              </div>

              {/* Tipo de Disposición */}
              <div className="col-span-12 xs:col-span-4 mt-1">
                <ListComponent>
                  <LabelComponent>Tipo de Disposición:</LabelComponent>
                  {listaTipoDisposicion.length === 0 && (
                    <ListItem>
                      <ListItemText secondary="Sin opciones disponibles" />
                    </ListItem>
                  )}
                  {listaTipoDisposicion.map((item, i) => (
                    <ListItem key={i}>
                      <ListItemText secondary={item.nombreDisposicion} />
                    </ListItem>
                  ))}
                </ListComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
