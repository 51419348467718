import { Divider } from '@mui/material';
import { RoleComponent } from 'components/design-system/Role/Role';
import { Galeria } from '../Galeria/Galeria';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import moment from 'moment';

/**
 * @name DescripcionOferente
 * Despliega la descripción de la oferta de residuo para un publicante con rol oferente.
 */
export const DescripcionOferente = ({ rol, residuo }) => {
  
  const fechaDisponibilidadFormateada = moment(residuo.fechaDisponibilidad).format('DD-MM-YYYY');

  return (
    <div>
      <Divider className="my-3" />
      <RoleComponent rol={rol.nombreRol} tipo="etiqueta" />
      <div className="pl-3">
        <div>
          <Heading type="h5">{residuo.nombreLER}</Heading>
          <div className="grid grid-cols-12 gap-2 items-baseline">
            <div className="col-span-4 text-end">
              <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-normal pt-0.5">
                FORMATO
              </span>
            </div>
            <div className="col-span-8">
              <Text>{residuo.formatoEntrega}</Text>
            </div>

            <div className="col-span-4 text-end">
              <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-normal pt-0.5">
                TIPO RESIDUO
              </span>
            </div>
            <div className="col-span-8">
              <Text>{residuo?.tipoResiduo?.nombreTipo || ''}</Text>
            </div>

            <div className="col-span-4 text-end">
              <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-normal pt-0.5">
                DISPONIBILIDAD
              </span>
            </div>
            <div className="col-span-8">
              <Text>{fechaDisponibilidadFormateada || ''}</Text>
            </div>

            <div className="col-span-4 text-end">
              <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-normal pt-0.5">
                FRECUENCIA
              </span>
            </div>
            <div className="col-span-8">
              <Text>{residuo.frecuencia}</Text>
            </div>
          </div>
        </div>
        <div>
          <Divider className="my-3" />
          <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-normal">
            IMAGENES DEL RESIDUO
          </span>
          <Galeria codigoResiduo={residuo.codigoResiduo} />
        </div>
      </div>
    </div>
  );
};
