import { useState } from 'react';
import {
  ClickAwayListener,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip
} from '@mui/material';
import { Clear, CompareArrows, Done } from '@mui/icons-material';

// COMPONENTE SECUNDARIO
export const SimplePopper = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    handleClickTerminarYAprobar,
    handleClickTerminarYRechazar,
    transaccion
  } = props;

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Tooltip
        title="Cerrar Transacción"
        aria-label="Cerrar Transacción"
        button-target="cerrar-transaccion"
      >
        <IconButton className="cerrar-transaccion" onClick={handleClick}>
          <CompareArrows />
        </IconButton>
      </Tooltip>
      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        disablePortal
        className="transaccion-opcion"
        placement="left"
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClick}>
            <MenuList autoFocusItem={open} id="menu-list-grow">
              <MenuItem
                className="text-green-500"
                onClick={e => handleClickTerminarYAprobar(e, transaccion)}
              >
                <Done className="mr-05" />
                Terminar y aprobar
              </MenuItem>

              <MenuItem
                className="text-red-500"
                onClick={e => handleClickTerminarYRechazar(e, transaccion)}
              >
                <Clear className="mr-05" />
                Terminar y rechazar
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};
