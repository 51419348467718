import React from 'react';
import List from '@mui/material/List';
import CallReceived from '@mui/icons-material/CallReceived';
import CallMade from '@mui/icons-material/CallMade';
import ListItemButtonComponent from 'components/design-system/ListItemButton/ListItemButton';
import { RUTAS } from 'const/Dictonary';

const SubMenuRSD = ({
  isDemandante,
  isOferente,
  forceRefresh,
  isSuperAdmin
}) => {
  return (
    <List>
      {isDemandante ? (
        <ListItemButtonComponent
          onClick={forceRefresh(RUTAS.RESIDUOS_DEMANDANTE_NUEVO)}
          icon={<CallReceived />}
          label={'Requerir residuo'}
        />
      ) : null}

      {isOferente ? (
        <ListItemButtonComponent
          onClick={forceRefresh(RUTAS.RESIDUOS_OFERENTE_NUEVO)}
          icon={<CallMade />}
          label={'Ofrecer residuo'}
        />
      ) : null}
    </List>
  );
};
export default SubMenuRSD;
