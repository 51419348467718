import { Dashboard, Description, Event, Forum } from '@mui/icons-material';
import Table from 'components/design-system/Table/Table';
import Text from 'components/design-system/Text/Text';
import { useEffect, useMemo, useState } from 'react';
import { convertirStringFecha } from 'helpers/formatFecha';
import { Tooltip } from '@material-ui/core';
import { DICTONARY } from 'const/Dictonary';
import { IconButton } from '@mui/material';
import { SimplePopper } from 'views/Transaccion/components';
import { ObtieneUltimaTransaccion } from 'views/Transaccion/utils';
import storeMessages from 'states/alertMessages/store';
import store from 'states/store';
import serviceChat from 'services/chat';
import serviceRSD from 'services/residuos';
import empresasServices from 'services/empresas';
import { useHistory } from 'react-router-dom';
import { ModalTransacciones } from '../ModalTransacciones';
import { numeroBaseDatosAFront } from 'helpers/formateoNumeros';

export const TablaTransacciones = ({
  listaTransacciones,
  pagination,
  setPagination,
  handleListaTransaccion,
  setData,
  setTotal,

  registroTablaSeleccionado,
  setRegistroTablaSeleccionado,
  setCoordinadasCentro,

  total,
  isLoading,
  filtros
}) => {
  const history = useHistory();
  const [listaSucursalesUsuarioLogueado] = useState([]);
  const { isSuperAdmin, isAdmin, isAnalistaTerritorial } =
    store.loguedUser.perfiles;
  const { sucursal } = store.loguedUser.logeduser;
  const [isOferente, setIsOferente] = useState(false);
  const [isDemandante, setIsDemandante] = useState(false);
  const [listaSalasChat, setListaSalasChat] = useState([]);
  const [transaccionSelected, setTransaccionSelected] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setTransaccionSelected(false);
    setOpenModal(false);
  };

  // OBTENER LISTAS EMPRESAS GESTOR, ASESOR, TRANSPORTISTA,
  // TIPO EMPRESA USUARIO,
  // LISTA DE SALAS DE CHAT,
  // LISTA DE TRANSACCIONES, LISTA DE TIPOS DE TRANSPORTE
  useEffect(() => {
    if (!store.loguedUser.logeduser) {
      return;
    }

    // OBTENER EL TIPO EMPRESA DEL USUARIO (DEMANDANTE/OFERENTE)
    try {
      setIsDemandante(store.loguedUser.tipos.isDemandante ? true : false);
      setIsOferente(store.loguedUser.tipos.isOferente ? true : false);
    } catch (err) {
      storeMessages.message.setMessage({
        mensaje:
          'Ha ocurrido un error al tratar de obtener el tipo de empresa del usuario.',
        tipo: 'error'
      });
      console.error(err);
    }

    let params = {
      codigoEmpresa: sucursal ? sucursal.empresa.codigoEmpresa : null,
      numPagina: pagination.pageIndex,
      totalPagina: pagination.pageSize,
      ...filtros
    };

    if (isAnalistaTerritorial) {
      const codigoRegion =
        store?.loguedUser?.logeduser?.region?.codigoRegion ??
        DICTONARY.DEFAULT_REGION.REGION_METROPOLITANA;
      params.codigoRegion = codigoRegion;
    }

    // Ejecuta servicio de transacciones
    handleListaTransaccion(params);

    // SALAS DE CHAT
    if (!isSuperAdmin) {
      // SUPERADMIN NO PUEDE VER LAS CONVERSACIONES
      try {
        serviceChat
          .salaChat(null, DICTONARY.INTERCEPTOR.DESACTIVADO)
          .then(result => {
            const { data } = result;
            const { content, empty } = data;
            if (empty) {
              setListaSalasChat([]);
              console.log('LISTA DE SALAS DE CHAT VACÍA!');
            } else if (Array.isArray(content)) {
              // console.log('Salas de chat >> ', content);
              setListaSalasChat(content);
            }
          })
          .catch(err => {
            storeMessages.message.setMessage({
              mensaje:
                'Ha ocurrido un error al tratar de obtener la lista de salas de reunión.',
              tipo: 'error'
            });
            console.error(err);
          });
      } catch (err) {
        storeMessages.message.setMessage({
          mensaje:
            'Ha ocurrido un error al tratar de obtener la lista de salas de reunión.',
          tipo: 'error'
        });
        console.error(err);
      }
    }
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    handleListaTransaccion,
    isSuperAdmin,
    setData,
    setTotal,
    sucursal,
    filtros
  ]);

  // SOLO PARA DEMANDANTES
  const handleClickTerminarYAprobarDemandante = (event, transaccion) => {
    const { transaccionEstado, codigoTransaccion } = transaccion;

    let ultimaTrasaccion = ObtieneUltimaTransaccion(
      codigoTransaccion,
      transaccionEstado
    );

    setTransaccionSelected({
      nombreEstado: ultimaTrasaccion.nombreEstado,
      codigoEstado: ultimaTrasaccion.codigoEstado,
      ...transaccion
    });

    handleOpenModal();
  };

  // SOLO PARA OFERENTES
  const handleClickTerminarYAprobarOferente = (event, transaccion) => {
    const { codigoTransaccion, transaccionEstado } = transaccion;

    let ultimaTrasaccion = ObtieneUltimaTransaccion(
      codigoTransaccion,
      transaccionEstado
    );

    setTransaccionSelected({
      nombreEstado: ultimaTrasaccion.nombreEstado,
      codigoEstado: ultimaTrasaccion.codigoEstado,
      ...transaccion
    });

    const dataFormateada = {
      transaccionEstado: [
        {
          estado: {
            codigoEstado: isOferente ? 4 : ultimaTrasaccion.codigoEstado
          }
        }
      ]
    };

    try {
      serviceRSD
        .cerrarTransacciones(dataFormateada, codigoTransaccion)
        .then(result => {
          //SI TIENE EXITO LA TRANSACCION, ENVIAR A VISTA RESUMEN DE TRANSACCION
          let stateTemp = {
            codigoTransaccion: codigoTransaccion
          };
          history.push({
            pathname: '/transacciones/resumen',
            state: stateTemp
          });
        });
    } catch (error) {
      console.log('ERROR AL CERRAR LA TRANSACCION:');
      console.error(error);
      storeMessages.message.setMessage({
        mensaje:
          'Ha ocurrido un error al tratar de cerrar la transacción. Por favor inténtelo más tarde.',
        tipo: 'error'
      });
    }
  };

  // UTILIZADO POR OFERENTE Y DEMANDANTE
  const handleClickTerminarYRechazar = async (event, transaccion) => {
    try {
      const { codigoTransaccion } = transaccion;
      const estadoTransaccion =
        transaccion.transaccionEstado.length > 0
          ? ObtieneUltimaTransaccion(
              transaccion.codigoEstado,
              transaccion.transaccionEstado
            )
          : null;

      const roldeMiEmpresaEnTransaccion = transaccion.sucursalTransaccion.find(
        registro =>
          registro.sucursal.empresa.codigoEmpresa ===
          sucursal.empresa.codigoEmpresa
      );

      const dataFormateada = {
        transaccionEstado: [
          {
            estado: {
              codigoEstado:
                roldeMiEmpresaEnTransaccion.nombreRol === DICTONARY.ROL.RECEPTOR.NOMBRE
                  ? 3
                  : roldeMiEmpresaEnTransaccion.nombreRol === DICTONARY.ROL.GENERADOR.NOMBRE
                  ? 5
                  : estadoTransaccion.codigoEstado
            }
          }
        ]
      };

      serviceRSD
        .cerrarTransacciones(dataFormateada, codigoTransaccion)
        .then(result => {
          //SI TIENE EXITO LA TRANSACCION, ENVIAR A VISTA RESUMEN DE TRANSACCION
          // console.log('RESULT >> ', result);
          let stateTemp = {
            codigoTransaccion: codigoTransaccion
          };
          history.push({
            pathname: '/transacciones/resumen',
            state: stateTemp
          });
        });
    } catch (error) {
      console.log('ERROR AL CERRAR LA TRANSACCION:');
      console.error(error);
      storeMessages.message.setMessage({
        mensaje:
          'Ha ocurrido un error al tratar de cerrar la transacción. Por favor inténtelo más tarde.',
        tipo: 'error'
      });
    }
  };

  const handleClickResumenTransaccion = codigoTransaccion => {
    let stateTemp = {
      codigoTransaccion: codigoTransaccion,
      tipo: 'resumen'
    };
    history.push({
      pathname: `/transacciones/resumen`,
      state: stateTemp
    });
  };

  // LÓGICA PARA IR A LA SALA DE CHAT
  const handleClickSalaChat = codigoTransaccion => {
    if (!codigoTransaccion) return;
    if (!listaSalasChat) return;
    // console.log('codigoTransaccion : ',codigoTransaccion);
    // console.log('listaSalasChat : ',listaSalasChat);

    const salaChat = listaSalasChat.find(
      sala => sala.transaccion?.codigoTransaccion === codigoTransaccion
    );

    //codigoSalaChat = "10";
    if (!salaChat) {
      storeMessages.message.setMessage({
        mensaje:
          'Esta transacción no tiene una sala de reunión asignada. Debe solicitar la sala primero.',
        tipo: 'error'
      });
      return;
    }

    history.push('/chat/' + salaChat.codigoSalaChat);
  };

  // DETERMINAR EL ROL QUE TIENE EL USUARIO EN LA TRANSACCIÓN
  const determinarRolEnTransaccion = transaccion => {
    if (
      !transaccion ||
      !store?.loguedUser?.logeduser ||
      !listaSucursalesUsuarioLogueado
    )
      return;
    // console.log('transaccion : ',transaccion);

    try {
      let codigoSucursalUsuarioLogueado = isSuperAdmin
        ? 0
        : store.loguedUser.logeduser.sucursal.codigoSucursal;

      const roles = DICTONARY.ROLES;

      let sucursales = {};

      roles.forEach(rol => {
        sucursales[rol] = transaccion.sucursalTransaccion.find(
          sucursal => sucursal.nombreRol === rol
        );
      });

      // EN PRIMER LUGAR SE REVISA POR SUCURSAL
      console.log('sucursales ', sucursales)
      const esDemandante =
        sucursales.receptor &&
        sucursales.receptor.sucursal.codigoSucursal ===
          codigoSucursalUsuarioLogueado;
      const esOferente =
        sucursales.generador &&
        sucursales.generador.sucursal.codigoSucursal ===
          codigoSucursalUsuarioLogueado;
      const esGestor =
        sucursales.gestor &&
        sucursales.gestor.sucursal.codigoSucursal ===
          codigoSucursalUsuarioLogueado;
      const esAsesor =
        sucursales.asesor &&
        sucursales.asesor.sucursal.codigoSucursal ===
          codigoSucursalUsuarioLogueado;
      const esTransportista =
        sucursales.transportista &&
        sucursales.transportista.sucursal.codigoSucursal ===
          codigoSucursalUsuarioLogueado;

        console.log('esReceptor', esDemandante)
        console.log('esGenerador ',  esOferente)

      if (esDemandante) {
        return DICTONARY.ROL.RECEPTOR.NOMBRE;
      } else if (esOferente) {
        return DICTONARY.ROL.GENERADOR.NOMBRE;
      } else if (esGestor) {
        return DICTONARY.ROL.GESTOR.NOMBRE;
      } else if (esAsesor) {
        return  DICTONARY.ROL.ASESOR.NOMBRE;
      } else if (esTransportista) {
        return  DICTONARY.ROL.TRANSPORTISTA.NOMBRE;
      }

      // LUEGO SE REVISA POR EMPRESA SI LA SUCURSAL DEL USUARIO LOGUEADO
      // NO PARTICIPA EN LA TRANSACCIÓN

      //   CASO ESPECIAL, SI LA TRANSACCIÓN ES UNA TRANSACCIÓN
      //   INTERNA DE LA EMPRESA (SOLO APLICA PARA ADMINISTRADOR)
      if (isAdmin) {
        const sucursalDemandante = listaSucursalesUsuarioLogueado.find(
          sucursal =>
            sucursal.codigoSucursal === sucursales.receptor.codigoSucursal
        );

        const sucursalOferente = listaSucursalesUsuarioLogueado.find(
          sucursal =>
            sucursal.codigoSucursal === sucursales.generador.codigoSucursal
        );

        // Verifica que ambas sucursales pertenecen a la empresa
        if (sucursalDemandante && sucursalOferente) {
          // El rol del admin depende del estado de la transacción
          if (
            transaccion.estado === 'Iniciada' ||
            transaccion.estado === 'Cerrada Oferta Concretada'
          ) {
            return DICTONARY.ROL.RECEPTOR.NOMBRE;
          } else if (transaccion.estado === 'Cerrada Demanda Concretada') {
            return DICTONARY.ROL.GENERADOR.NOMBRE;
          }
        }
      }

      // EMPRESA ES DEMANDANTE
      //Buscar coincidencias en la lista de sucursales del usuario logueado
      const buscarCoincidencias = sucursal => {
        return listaSucursalesUsuarioLogueado.find(
          sucursalUsuario =>
            sucursalUsuario.codigoSucursal === sucursal.codigoSucursal
        );
      };

      //Verificar si la empresa es demandante y si tiene alguna coincidencia
      if (sucursales.receptor && buscarCoincidencias(sucursales.receptor)) {
        return DICTONARY.ROL.RECEPTOR.NOMBRE;
      }

      //Verificar si la empresa es oferente y si tiene alguna coincidencia
      if (sucursales.generador && buscarCoincidencias(sucursales.generador)) {
        return DICTONARY.ROL.GENERADOR.NOMBRE;
      }

      // EMPRESA ES GESTOR
      const sucursalGestor = sucursales.gestor;

      if (
        sucursalGestor &&
        listaSucursalesUsuarioLogueado.find(
          sucursal => sucursal.codigoSucursal === sucursalGestor.codigoSucursal
        )
      ) {
        return DICTONARY.ROL.GESTOR.NOMBRE;
      }

      // EMPRESA ES ASESOR
      // si la sucursal tiene un asesor
      if (
        sucursales.asesor &&
        // y el usuario ha iniciado sesión en una sucursal que tiene el mismo código de sucursal que el asesor
        listaSucursalesUsuarioLogueado.find(
          sucursal =>
            sucursal.codigoSucursal === sucursales.asesor.codigoSucursal
        )
      )
        // entonces el usuario es un asesor para esa sucursal
        return DICTONARY.ROL.ASESOR.NOMBRE;

      // EMPRESA ES TRANSPORTISTA
      if (
        sucursales.transportista &&
        listaSucursalesUsuarioLogueado.some(
          sucursal =>
            sucursal.codigoSucursal === sucursales.transportista.codigoSucursal
        )
      )
        return  DICTONARY.ROL.TRANSPORTISTA.NOMBRE;
    } catch (err) {
      storeMessages.message.setMessage({
        mensaje:
          'Ha ocurrido un error al tratar de determinar el rol del usuario en la transacción.',
        tipo: 'error'
      });
      console.error(err);
      return 'error';
    }

    // NI LA EMPRESA NI LA SUCURSAL DEL USUARIO
    // LOGUEADO PARTICIPAN EN LA TRANSACCIÓN?
    return 'none';
  };

  const handleMostrarBotonResumenTransaccion = transaccion => {

    const transaccionesPermitidas = DICTONARY.TRANSACCIONES_PERMITIDAS;

    return transaccionesPermitidas.includes(transaccion.estado);
  };

  const muestraOcultaBotonCerrarTransaccion = (
    codigoTransaccion,
    transaccionEstado,
    transaccion
  ) => {
    if (!isDemandante && !isOferente) {
      return false;
    }

    if (!codigoTransaccion) return;
    const ultimoEstado = ObtieneUltimaTransaccion(
      codigoTransaccion,
      transaccionEstado
    );

    const isRoleOferente =
      transaccion.infoOferente.nombre === sucursal.empresa.nombreEmpresa;
    const isRoleDemandante =
      transaccion.infoDemandante.nombre === sucursal.empresa.nombreEmpresa;

    if (isRoleOferente) {
      if (
        ultimoEstado.nombreEstado ===
          DICTONARY.ESTADOS_TRANSACCION.INICIADA.NOMBRE ||
        ultimoEstado.nombreEstado ===
          DICTONARY.ESTADOS_TRANSACCION['CERRADA DEMANDA CONCRETADA'].NOMBRE ||
        ultimoEstado.nombreEstado ===
          DICTONARY.ESTADOS_TRANSACCION['CERRADA DEMANDA NO CONCRETADA'].NOMBRE
      )
        return true;
      else {
        return false;
      }
    }

    if (isRoleDemandante) {
      if (
        ultimoEstado.nombreEstado ===
          DICTONARY.ESTADOS_TRANSACCION.INICIADA.NOMBRE ||
        ultimoEstado.nombreEstado ===
          DICTONARY.ESTADOS_TRANSACCION['CERRADA OFERTA CONCRETADA'].NOMBRE ||
        ultimoEstado.nombreEstado ===
          DICTONARY.ESTADOS_TRANSACCION['CERRADA OFERTA NO CONCRETADA'].NOMBRE
      )
        return true;
      else {
        return false;
      }
    }

    return false;
  };

  const transaccionConSalaChat = codigoTransaccion => {
    if (!codigoTransaccion) return;
    if (!listaSalasChat) return;

    let salaChat = listaSalasChat.find(
      sala => sala.transaccion?.codigoTransaccion === codigoTransaccion
    );

    if (salaChat) {
      return true;
    }

    return false;
  };

  const calcularCentro = arrMarkers => {
    const totalMarkers = arrMarkers.length;
    if (totalMarkers === 0) return [0, 0];

    let totalLat = 0;
    let totalLon = 0;

    arrMarkers.forEach(marker => {
      totalLat += marker.latitud;
      totalLon += marker.longitud;
    });

    setCoordinadasCentro([totalLat / totalMarkers, totalLon / totalMarkers]);
  };

  const handleSeleccionarEmpresa = empresa => {
    if (empresa !== registroTablaSeleccionado) {
      setRegistroTablaSeleccionado(empresa.codigoTransaccion);
      calcularCentro(
        listaTransacciones.dataMapa.filter(
          item => item.codigoTransaccion === empresa.codigoTransaccion
        )
      );
    }
  };

  useEffect(() => {
    setRowSelection({ row: -1 });
    setRegistroTablaSeleccionado(null);
    calcularCentro(listaTransacciones.dataMapa);
  }, [listaTransacciones]);

  const [listadoTiposTransporte, setListadoTiposTransporte] = useState([]);
  const [listadoEmpresasGestor, setListadoEmpresasGestor] = useState([]);
  const [listadoEmpresasAsesor, setListadoEmpresasAsesor] = useState([]);
  const [listadoEmpresasTransportista, setListadoEmpresasTransportista] =
    useState([]);
  const [rowSelection, setRowSelection] = useState([]);

  useEffect(() => {
    const queryEmpresa = {
      totalPagina: DICTONARY.PAGINACION.ALL_DATA
    };

    // Servicio Tipo de Transporte
    const obtenerTiposTransporte = async () => {
      try {
        const response = await empresasServices.obtenerTipoTransaporte(
          null,
          null,
          null,
          DICTONARY.INTERCEPTOR.DESACTIVADO
        );
        const content = response.data.content;
        setListadoTiposTransporte(content);
      } catch (error) {
        console.log(error);
        setListadoTiposTransporte([]);
      }
    };

    // Llamadas a funciones Gestor, Asesor, Transportista
    obtenerTiposTransporte();

    const obtenerEmpresasPorRol = (nombreRol, callback) => {
      try {
        const query = {
          ...queryEmpresa,
          nombreRol
        };
        empresasServices
          .obtieneSucursales(
            query,
            null,
            null,
            null,
            DICTONARY.INTERCEPTOR.DESACTIVADO
          )
          .then(response => {
            const { content } = response.data;
            callback(
              content.map(sucursal => ({
                codigoSucursal: sucursal.codigoSucursal,
                nombreSucursal:
                  sucursal.nombreSucursal +
                  ' - ' +
                  sucursal.empresa.nombreEmpresa,
                desdeServicio: true
              }))
            );
          });
      } catch (error) {
        callback([]);
        console.log(error);
      }
    };

    if (isSuperAdmin || isDemandante) {
      obtenerEmpresasPorRol('transportista', setListadoEmpresasTransportista);
      obtenerEmpresasPorRol('gestor', setListadoEmpresasGestor);
      obtenerEmpresasPorRol('asesor', setListadoEmpresasAsesor);
    }
  }, [isDemandante, isSuperAdmin]);

  const empresaColumnTable = useMemo(
    () => [
      {
        header: 'COD',
        accessorKey: 'codigoTransaccion',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>,
        size: 50
      },
      {
        header: 'Generador',
        accessorKey: 'infoOferente',
        Cell: ({ renderedCellValue }) => (
          <>
            <Text className="text-black">{renderedCellValue.nombre}</Text>
            <Text>{renderedCellValue.direccion} </Text>
          </>
        )
      },
      {
        header: 'Materia transaccionada',
        accessorKey: 'residuo',
        size: 260,
        Cell: ({ renderedCellValue }) => (
          <>
            <Text className="text-black">
              {renderedCellValue.tipoResiduo.nombreTipo || ''}
            </Text>
            <Text size="S" className="mb-1.5 text-neutral-30">
              {renderedCellValue.nombreRecurso || ''}
            </Text>
            <Text className="mb-1.5">
              <Dashboard className="text-uv-secondary-0 mr-1" />
              {numeroBaseDatosAFront(renderedCellValue.cantidad) || ''}{' '}
              {renderedCellValue.unidad || ''}
            </Text>
            <Text>
              <Event className="text-uv-secondary-0 mr-1" />
              {convertirStringFecha(renderedCellValue.fechaDisponibilidad)}
            </Text>
          </>
        )
      },
      {
        header: 'Receptor',
        accessorKey: 'infoDemandante',
        Cell: ({ renderedCellValue }) => (
          <>
            <Text className="text-black">{renderedCellValue.nombre}</Text>
            <Text>{renderedCellValue.direccion}</Text>
          </>
        )
      },
      {
        header: 'Estado',
        accessorKey: 'ultimaTrasaccion',
        Cell: ({ renderedCellValue }) => (
          <>
            <Tooltip arrow title={renderedCellValue.nombreEstado}>
              <img
                src={
                  DICTONARY.ESTADOS_TRANSACCION[
                    renderedCellValue.nombreEstado.toUpperCase()
                  ].ICONO
                }
                alt={renderedCellValue}
              />
            </Tooltip>
          </>
        )
      }
    ],
    []
  );

  return (
    <div className="col-span-12 lg:col-span-8">
      <Table
        muiTableContainerProps={{ sx: { height: '600px' } }}
        columns={empresaColumnTable}
        data={listaTransacciones.dataTabla}
        className="h-full"
        rowCount={total}
        isLoading={isLoading}
        manualPagination
        enableColumnActions={true}
        enableColumnFilters={false}
        enablePagination={true}
        enableTopToolbar={false}
        muiTableBodyCellProps={{
          sx: {
            verticalAlign: 'top'
          }
        }}
        getRowId={row => row.codigoTransaccion}
        muiTableBodyRowProps={({ row }) => ({
          onClick: ev => {
            if (ev.target.parentElement.nodeName === 'BUTTON') {
              //Desactiva el desmarcar al presionar un botón
              return;
            }
            if (row.id === rowSelection.id) {
              setRowSelection({ row: -1 });
              setRegistroTablaSeleccionado(null);
              return;
            }
            setRowSelection(row);
            handleSeleccionarEmpresa(row.original);
          },
          selected: rowSelection.id === row.id,
          sx: {
            cursor: 'pointer'
          }
        })}
        enableRowActions
        actions={({ row, table }) => (
          <div>
            {(isOferente || isDemandante) && (
              <Tooltip title="Sala de Reunión" aria-label="Sala de Reunión">
                <IconButton
                  disabled={
                    !transaccionConSalaChat(row.original.codigoTransaccion)
                  }
                  onClick={e =>
                    handleClickSalaChat(row.original.codigoTransaccion)
                  }
                >
                  <Forum />
                </IconButton>
              </Tooltip>
            )}

            {muestraOcultaBotonCerrarTransaccion(
              row.original.codigoTransaccion,
              row.original.transaccionEstado,
              row.original
            ) ? (
              <SimplePopper
                transaccion={row.original}
                handleClickTerminarYAprobar={
                  determinarRolEnTransaccion(row.original) === DICTONARY.ROL.RECEPTOR.NOMBRE
                    ? handleClickTerminarYAprobarDemandante
                    : handleClickTerminarYAprobarOferente
                }
                handleClickTerminarYRechazar={handleClickTerminarYRechazar}
              />
            ) : null}

            <Tooltip
              title="Resumen de transacción"
              aria-label="Resumen de transacción"
            >
              <IconButton
                disabled={
                  handleMostrarBotonResumenTransaccion(row.original) ||
                  row.original.ultimaTrasaccion === 'Iniciada'
                }
                onClick={e =>
                  handleClickResumenTransaccion(row.original.codigoTransaccion)
                }
              >
                <Description />
              </IconButton>
            </Tooltip>
          </div>
        )}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: 'Resumen',
            size: 100
          }
        }}
        positionActionsColumn="last"
        onPaginationChange={setPagination}
        state={{
          isLoading,
          showProgressBars: false,
          pagination,
          columnVisibility: {
            nombreRegion: isSuperAdmin ? true : false
          }
        }}
      />

      <ModalTransacciones
        listadoTiposTransporte={listadoTiposTransporte}
        listadoEmpresasGestor={listadoEmpresasGestor}
        listadoEmpresasAsesor={listadoEmpresasAsesor}
        listadoEmpresasTransportista={listadoEmpresasTransportista}
        transaccionSelected={transaccionSelected}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
};
