import Heading from 'components/design-system/Heading/Heading';
import LinkComponent from 'components/design-system/Link/Link';
import Text from 'components/design-system/Text/Text';
import { sinIndicadores } from './utils/dummy';
import store from 'states/store';
import { useParams } from 'react-router-dom';
import { RUTAS } from 'const/Dictonary';

export const SinIndicadores = () => {
  const { codigoEmpresa } = useParams();
  const { logeduser } = store.loguedUser;
  const sucursal = logeduser.sucursal ? logeduser.sucursal : null;

  return (
    <div className="flex flex-col bg-white shadow-sm rounded mb-5">
      <div className="flex w-full h-full align-middle justify-center items-center">
        <div className="absolute z-10 text-center">
          <div className="mx-auto w-2/3">
            <Heading type="h3">Generación de indicadores</Heading>
            <Text className="mb-4">
              <b className="text-black font-bold">
                Aun no has ingresado datos para generar los indicadores
              </b>
              , debes hacerlo para poder visualizar los indicadores asociados a
              tu negocio
            </Text>
            <LinkComponent
              href={`${RUTAS.NEGOCIO}/${codigoEmpresa}/datos-indicadores/${sucursal.codigoSucursal}`}
              type="button"
              className="h-auto sm:h-10"
            >
              Añadir datos para el cálculo
            </LinkComponent>
          </div>
        </div>

        <div className="blur-sm w-full opacity-60">
          {sinIndicadores.map(section => (
            <div className="rounded p-5 mb-5">
              <div className="flex flex-wrap justify-center">
                {section.items.map(item => (
                  <div className="w-1/4 text-center">
                    {item.heading}
                    <div className="indice-data align-items-center mt-2">
                      <img
                        src={item.image}
                        alt={item.imageAlt}
                        height="36"
                        className="mx-auto mb-2"
                      />
                      {item.element}
                      <Text className="medicion">{item.description}</Text>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
