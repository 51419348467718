import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  message: {
    position: 'fixed',
    paddingBottom: '95px'
  }
}));

export default function BackdropLoading({
  timeout = 10000,
  timeOutMessage = 'Esta tardando demasiado?'
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [istimeout, setIstimeout] = React.useState(false);

  const timeoutRef = React.useRef(null);

  React.useEffect(() => {
    if (timeout) {
      timeoutRef.current = setTimeout(() => {
        setIstimeout(true);
      }, timeout);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [timeout]);

  const handleClose = () => {
    if (istimeout) {
      setOpen(false);
    }
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        {istimeout ? <div className={classes.message}>{timeOutMessage}</div> : null}
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
