import { useContext } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import ButtonComponent from 'components/design-system/Button/Button';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import SelectComponent from 'components/design-system/Autocomplete/Autocomplete';
import { MatchContext } from '../../../context/MatchContext';
import { ManageSearchOutlined } from '@mui/icons-material';
import Text from 'components/design-system/Text/Text';

/**
 * @name NuevosCriteriosCampos
 * Componentes con campos de formulario para nuevos criterios de match manual.
 */
export const NuevosCriteriosCampos = ({
  handleChange,
  values,
  touched,
  errors,
  setFieldTouched,
  isSubmitting,
  validateForm,
  setIsNuevosCriterios
}) => {
  const {
    listaTipoResiduos,
    listaTipoRecursos,
    tieneNegocio,
    DISABLE_TAB_RESIDUOS
  } = useContext(MatchContext);

  return (
    <div className="col-span-12">
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-12 sm:col-span-6 lg:col-span-6">
          <SelectComponent
            name="codigosTipoResiduo"
            options={listaTipoResiduos}
            accesor="nombreTipo"
            value={values.codigosTipoResiduo}
            getOptionLabel={option => (option ? option.nombreTipo : '')}
            clearOnEscape={true}
            openOnFocus={true}
            noOptionsText="Sin opciones"
            onBlur={() => setFieldTouched('codigosTipoResiduo')}
            touched={touched.codigosTipoResiduo ? true : false}
            onChange={(ev, tipoResiduo, reason) => {
              let data = {
                target: { name: 'codigosTipoResiduo', value: tipoResiduo }
              };
              if (reason === 'clear') {
                data.target.value = '';
              }
              handleChange(data);
            }}
          >
            <InputComponent
              fullWidth
              className="h-9"
              touched={touched.codigosTipoResiduo ? true : false}
              estado={
                errors.codigosTipoResiduo
                  ? { tipo: 'error', mensaje: errors.codigosTipoResiduo }
                  : null
              }
              label={
                <LabelComponent className="block font-secondary text-xs text-black tracking-wider font-medium pb-1">
                  RESIDUOS
                </LabelComponent>
              }
            />
          </SelectComponent>
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-6">
          <SelectComponent
            name="codigosRecurso"
            options={listaTipoRecursos}
            value={values.codigosRecurso}
            accesor="nombreRecurso"
            getOptionLabel={option => (option ? option.nombreRecurso : '')}
            clearOnEscape={true}
            openOnFocus={true}
            noOptionsText="Sin opciones"
            onBlur={() => setFieldTouched('codigosRecurso')}
            touched={touched.codigosRecurso ? true : false}
            onChange={(ev, recursos, reason) => {
              let data = {
                target: { name: 'codigosRecurso', value: recursos }
              };

              if (reason === 'clear') {
                data.target.value = '';
              }
              handleChange(data);
            }}
          >
            <InputComponent
              fullWidth
              className="h-9"
              touched={touched.codigosRecurso ? true : false}
              estado={
                errors.codigosRecurso
                  ? { tipo: 'error', mensaje: errors.codigosRecurso }
                  : null
              }
              label={
                <LabelComponent className="block font-secondary text-xs text-black tracking-wider font-medium pb-1">
                  RECURSOS
                </LabelComponent>
              }
            />
          </SelectComponent>
        </div>

        {!DISABLE_TAB_RESIDUOS && (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
            <div className="col-span-12 sm:col-span-12 lg:col-span-6">
              <LabelComponent>Disponibilidad del Residuo</LabelComponent>
              <div className="grid grid-cols-12 gap-5">
                <div className="col-span-12 sm:col-span-6">
                  <DesktopDatePicker
                    disabled={!tieneNegocio}
                    name="fechaDisponibilidadInicio"
                    inputFormat="dd/MM/yyyy"
                    value={values.fechaDisponibilidadInicio}
                    defaultValue={values.fechaDisponibilidadInicio}
                    maxDate={values.fechaDisponibilidadFin}
                    onChange={value => {
                      handleChange({
                        target: {
                          name: 'fechaDisponibilidadInicio',
                          value: value
                        }
                      });
                    }}
                    renderInput={params => (
                      <InputComponent
                        fullWidth
                        className="pr-8"
                        outerInputProps={params.InputProps}
                        {...params}
                      />
                    )}
                  />
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <DesktopDatePicker
                    disabled={!tieneNegocio}
                    name="fechaDisponibilidadFin"
                    inputFormat="dd/MM/yyyy"
                    value={values.fechaDisponibilidadFin}
                    defaultValue={values.fechaDisponibilidadFin}
                    onChange={value => {
                      handleChange({
                        target: {
                          name: 'fechaDisponibilidadFin',
                          value: value
                        }
                      });
                    }}
                    minDate={values.fechaDisponibilidadInicio}
                    renderInput={params => (
                      <InputComponent
                        fullWidth
                        className="pr-8"
                        outerInputProps={params.InputProps}
                        {...params}
                      />
                    )}
                  />
                </div>
              </div>

              {tieneNegocio ? (
                <Text size="S" className="pt-2">
                  Este criterio solo aplica a{' '}
                  <strong className="text-black font-semibold">
                    búsqueda de residuos
                  </strong>
                </Text>
              ) : (
                <div className="bg-warning-light rounded px-4 py-2 mt-2">
                  <Text size="S">
                    Opciones de búsqueda adicionales para usuarios que hayan{' '}
                    <b className="text-black">registrado su negocio</b>
                  </Text>
                </div>
              )}
            </div>
          </LocalizationProvider>
        )}

        <div className="col-span-12 lg:col-span-6">
          <div
            className={`
            grid grid-cols-12 gap-4 items-end content-end
            ${DISABLE_TAB_RESIDUOS ? '' : 'lg:mt-7'}
          `}
          >
            <div className="col-span-6 lg:col-span-6">
              <ButtonComponent
                form="nuevosCriterios"
                isLoading={isSubmitting}
                disabled={
                  Object.keys(errors).length === 0 && !isSubmitting
                    ? false
                    : true
                }
                buttonType="submit"
                onClick={() => {
                  validateForm()
                    .then(result => {
                      console.log('result :>> ', result);
                    })
                    .catch(err => {
                      console.log(err);
                    });
                }}
                className="px-3 w-full"
              >
                {isSubmitting ? (
                  'Cargando'
                ) : (
                  <>
                    <ManageSearchOutlined className="mr-1" /> Buscar
                  </>
                )}
              </ButtonComponent>
            </div>
            <div className="col-span-6 lg:col-span-6">
              {!isSubmitting && (
                <ButtonComponent
                  type="secondary"
                  fullWidth
                  className="justify-center"
                  onClick={() => setIsNuevosCriterios(false)}
                >
                  Cancelar
                </ButtonComponent>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
