// Importaciones React
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// Librerias externas
import moment from 'moment';
import { format } from 'date-fns';

// Importaciones propias
import storeMessages from 'states/alertMessages/store';

// services
import recursosServices from 'services/recursos';

// Otros
import { diccionarioVista } from '../utils/utils';
import { RUTAS } from 'const/Dictonary';
import { formatoArchivos } from './useUtilsFormik';

export const useSubmitRecusosAdquiridos = (codigoRecursoAdquirido, values) => {

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);


  const [recursoAdquirido, setRecursoAdquirido] = useState({
    // Seccion 1 - Recurso Adquirido
    sucursal: null, // codigoSucursal registrado en la base de datos (Integer)
    tipoRecursoAdquirido: null, // codigoTipoRecursoAdquirido registrado en la base de datos (Integer)
    cantidad: null, // Double
    unidadMedida: null,//codigoUnidadMedida registrado en la base de datos (Integer)
    levantamientoDatos: null, //codigoLevantamientoDatos registrado en la base de datos (Integer)
    fechaAdquisicion: '', // Date (yyyy-MM-dd)
    // Seccion 2 - Generador - Vendedor del recurso
    rut: '',
    // Seccion 6 - Generación de residuo
    nombreLER: null, // codigoLER registrado en la base de datos (Integer) y nombreLER (String)
    nombreSubLER: null, // codigoLER registrado en la base de datos (Integer) y nombreLER (String)
    nombreSubSubLER: null, // codigoLER registrado en la base de datos (Integer) y nombreLER (String)
    tipoResiduo: null, // codigoTipoResiduo registrado en la base de datos (Integer)
    subTipoResiduo: null, // codigoSubTipoResiduo registrado en la base de datos (Integer)
    archivos: [],
  });

  const [isNuevoRecursoAdquirido, setIsNuevoRecursoAdquirido] = useState(false);

  const [cargaData, setcargaData] = useState(false)

  /**
   * @name cargarRecursoAdquirido
   * Función para cargar el recurso adquirido que se está desplegando, en el caso de que el código no viene se asume que es un nuevo recurso adquirido.
   * @throws Error
   * @return estructura servicio de recurso adquirido
   */

  const cargarRecursoAdquirido = async () => {

    if (codigoRecursoAdquirido !== 'nuevo') {
      setIsLoading(true);
      try {
        const responseRecursoAdquirido = await recursosServices.obtieneRecursoAdquirido(
          codigoRecursoAdquirido
        );
        if (responseRecursoAdquirido.status === 200) {

          const recursoAdquirido = responseRecursoAdquirido.data;

          const resultTemp = recursoAdquirido.archivos.map((archivo) => {
            const resp = {
              base64: `data:${archivo.tipoArchivo};base64,${archivo.base64}`,
              nombreArchivo: `${archivo.nombreArchivo}`,
              extension: `${archivo.tipoArchivo}`,
              codigoArchivo: `${archivo.codigoArchivoRecursoAdquirido}`
            }
            return resp;
          });
          // console.log('recursoAdquirido ::>',recursoAdquirido);
          const fechaFormateada = moment(recursoAdquirido.fechaAdquisicion).local();
          setRecursoAdquirido({
            // Mandatory
            codigoRecursoAdquirido: recursoAdquirido.codigoRecursoAdquirido,
            sucursal: recursoAdquirido.sucursal,
            tipoRecursoAdquirido: recursoAdquirido.tipoRecursoAdquirido,
            cantidad: recursoAdquirido.cantidad,
            unidadMedida: recursoAdquirido.unidadMedida,
            levantamientoDatos: recursoAdquirido.levantamientoDatos,
            fechaAdquisicion: fechaFormateada.toISOString(),
            rut: recursoAdquirido.empresaGeneradora.rut,
            nombreSubSubLER: recursoAdquirido.generacionResiduo.nombreLER,
            subTipoResiduo: recursoAdquirido.generacionResiduo.tipoResiduo,

            // Optional
            archivos: resultTemp.length > 0 ? resultTemp : [],
            tipoTratamiento: recursoAdquirido.generacionResiduo?.tipoTratamiento,
            numeroGuiaDespacho: recursoAdquirido?.numeroGuiaDespacho,
            region: recursoAdquirido?.empresaGeneradora?.direccion?.comuna?.region,
            comuna: recursoAdquirido?.empresaGeneradora?.direccion?.comuna,
            direccion: recursoAdquirido?.empresaGeneradora?.direccion?.nombreVia,
            nombreVia: recursoAdquirido.empresaGeneradora?.direccion?.nombreVia,
            longitudDireccion: recursoAdquirido.empresaGeneradora?.direccion?.longitudDireccion,
            latitudDireccion: recursoAdquirido.empresaGeneradora?.direccion?.latitudDireccion,
            tipoTransporte: recursoAdquirido?.tipoTransporte,
            contenidoReciclado: recursoAdquirido?.contenidoReciclado,
            contenidoRenovable: recursoAdquirido?.contenidoRenovable,
            embalaje: recursoAdquirido?.embalaje?.tipoEmbalaje,
            cantidadEmbalaje: recursoAdquirido?.embalaje?.cantidad,
            cantidadResiduo: recursoAdquirido?.generacionResiduo?.cantidad,
            procesoProductivo: recursoAdquirido?.generacionResiduo?.procesoProductivo,
            codigoEmbalaje: recursoAdquirido?.embalaje?.codigoEmbalaje,
          });
        }
      } catch (error) {
        console.error('Error en cargaRecursoAdquirido', error);
      }
      finally {
        setIsLoading(false);
        setcargaData(true)
      }
    } else {
      setIsLoading(false);
      setIsNuevoRecursoAdquirido(true);
      setcargaData(true)
    }
  }

  /**
   * @name handleSubmit
   * Función para enviar los datos del formulario al servicio
   * @param valores estructura formik para nuevo recurso adquirido
   * @return estructura servicio nuevo recurso adquirido
   * @throws Error
   */

  const handleSubmit = async (valores, tipoFormulario) => {
    try {
      const actionMap = {
        'ingresar': isNuevoRecursoAdquirido ? 'SUBMITING NUEVO RECURSO ADQUIRIDO' : '',
        'duplicar': !isNuevoRecursoAdquirido ? 'SUBMITING DUPLICANDO RECURSO ADQUIRIDO' : '',
        'editar': !isNuevoRecursoAdquirido ? 'SUBMITING EDITANDO RECURSO ADQUIRIDO' : '',
      };

      const submitAction = actionMap[tipoFormulario];

      console.log(submitAction);

      if (tipoFormulario === 'ingresar' || tipoFormulario === 'duplicar') {
        await submitNuevoRecuroAdquirido(valores, tipoFormulario);
      } else if (tipoFormulario === 'editar') {
        await submitActualizarRecursoAdquirido(valores);
      }

    } catch (error) {
      console.log('Error en handleSubmit ', error);
      storeMessages.message.setMessage({
        mensaje: `No se ha podido realizar la operación de ${diccionarioVista.nombrePrincipalSingular}`,
        tipo: 'error'
      });
      throw new Error('Error en handleSubmit ', error);
    }
  };



  useEffect(() => {
    // console.log(recursoAdquirido);
  }, [recursoAdquirido, cargaData, isLoading])


  /**
   * @name submitNuevoRecuroAdquirido
   * Función para enviar los datos del formulario al servicio
   * @param valores estructura formik para nuevo / duplicado recurso adquirido 
   * @return estructura servicio nuevo  recurso adquirido
   * @throws Error
   * 
   * */

  const submitNuevoRecuroAdquirido = async (valores, tipoFormulario) => {
    const generar = tipoFormulario === 'ingresar' ? 'crear' : 'duplicar';
    const generarado = tipoFormulario === 'ingresar' ? 'creado' : 'duplicado';
    try {
      const recursoAdquiridoData = estructuraServicioNuevoRecursoAdquirido(valores);
      const response = await recursosServices.crearRecursoAdquirido(recursoAdquiridoData);
      if (response.status === 200) {
        storeMessages.message.setMessage({
          mensaje: `Se ha ${generarado} el ${diccionarioVista.nombrePrincipalSingular} correctamente`,
          tipo: 'exito'
        });
        // history.push(RUTAS.RECURSOS_ADQUIRIDOS);
      }

    } catch (error) {
      console.log('Error en la creación o duplicado de un recurso adquirido  ', error);
      storeMessages.message.setMessage({
        mensaje: `No se ha podido ${generar} el ${diccionarioVista.nombrePrincipalSingular}`,
        tipo: 'error'
      });
      throw new Error('Error en submitNuevoRecuroAdquirido ', error);
    }

  };

  /**
  * @name submitActualizarRecursoAdquirido
  * Función para enviar los datos del formulario al servicio
  * @param valores estructura formik para la edición de recurso adquirido
  * @return estructura servicio recurso adquirido
  * @throws Error
  * 
  * */
  const submitActualizarRecursoAdquirido = async (valores) => {

    try {
      const recursoAdquiridoData = estructuraServicioActualizarRecursoAdquirido(valores);
      // console.log(recursoAdquiridoData);

      const codigoRecursoAdquiridoInt = parseInt(codigoRecursoAdquirido);
      //  console.log(codigoRecursoAdquiridoInt);

      const response = await recursosServices.actualizaRecursoAdquirido(codigoRecursoAdquiridoInt, recursoAdquiridoData);
      if (response.status === 200) {
        storeMessages.message.setMessage({
          mensaje: 'Se han editado los datos del recurso adquirido correctamente',
          tipo: 'exito'
        });
        // history.push(RUTAS.RECURSOS_ADQUIRIDOS);
      }

    } catch (error) {
      console.log('Error en la edición del recurso adquirido ', error);
      throw new Error('Error en submitNuevoRecuroAdquirido ', error);
    }

  }

  /**
 * @name estructuraServicioNuevoRecursoAdquirido
 * Función para estructurar los valores del formulario a la estructura requerida por el servicio
 * @param valores estructura formik 
 * @return estructura servicio nuevo/duplicado recurso adquirido
 */
  const estructuraServicioNuevoRecursoAdquirido = valores => {

    const { formattedFiles } = formatoArchivos(valores.archivos);

    try {

      // saber si el objeto valores correspondientes
      const hasDispatchGuideNumber = valores?.numeroGuiaDespacho;
      const hasRecyclableContent = valores?.contenidoReciclado;
      const hasRenewableContent = valores?.contenidoRenovable;
      const hasPackagingType = valores?.embalaje?.codigoTipoEmbalaje;
      const hasPackagingQuantity = valores?.cantidadEmbalaje;
      const hasCommune = valores?.comuna?.codigoComuna;
      const hasStreetDetails = valores?.nombreVia;
      const hasLatitudeAddress = valores?.latitudDireccion;
      const hasLongitudeAddress = valores?.longitudDireccion;
      const hasTransportTypeCode = valores?.tipoTransporte?.codigoTipoTransporte;
      const hasProductionProcess = valores?.procesoProductivo;
      const hasWasteQuantity = valores?.cantidadResiduo;
      const hasDataCollectionCode = valores?.levantamientoDatos?.codigoLevantamientoDatos;
      const hassTreatmentType = valores?.tipoTratamiento?.codigoTipoTratamiento;
      const hasWasteType = valores?.subTipoResiduo?.codigoTipoResiduo;
      const hasLERName = valores?.nombreSubSubLER?.codigoLER;
      const hasUnitOfMeasure = valores?.unidadMedida?.codigoUnidadMedida;
      const hasBranch = valores?.sucursal?.codigoSucursal;
      const hasAcquiredResourceType = valores?.tipoRecursoAdquirido?.codigoTipoRecursoAdquirido;
      const hasAcquisitionDate = valores?.fechaAdquisicion;
      const hasQuantity = valores?.cantidad;
      const hasGeneratingCompany = valores?.rut;



      const estructuraServicio = {
        // Obligatirios
        cantidad: hasQuantity ? valores.cantidad : null, // Double
        fechaAdquisicion: hasAcquisitionDate ? format(new Date(valores.fechaAdquisicion), 'yyyy-MM-dd') : null,     // Date (yyyy-MM-dd)
        numeroGuiaDespacho: hasDispatchGuideNumber ? valores.numeroGuiaDespacho : null,  // Integer (max 10)
        contenidoReciclado: hasRecyclableContent ? valores.contenidoReciclado : null, // Double
        contenidoRenovable: hasRenewableContent ? valores.contenidoRenovable : null,  // Double
        unidadMedida: hasUnitOfMeasure ? {
          codigoUnidadMedida: valores.unidadMedida.codigoUnidadMedida // Integer (codigoUnidadMedida registrado en la base de datos)
        } : null,
        generacionResiduo: {
          nombreLER: hasLERName ? {
            codigoLER: valores.nombreSubSubLER.codigoLER // Integer (codigoTipoResiduo registrado en la base de datos)
          } : null,
          tipoResiduo: hasWasteType ? {
            codigoTipoResiduo: valores.subTipoResiduo.codigoTipoResiduo // Integer (codigoTipoResiduo registrado en la base de datos)
          } : null,
          tipoTratamiento: hassTreatmentType ? {
            codigoTipoTratamiento: valores.tipoTratamiento.codigoTipoTratamiento // Integer (codigoTipoTratamiento registrado en la base de datos)
          } : null
        },
        sucursal: hasBranch ? {
          codigoSucursal: valores.sucursal.codigoSucursal // Integer (codigoSucursal registrado en la base de datos)
        } : null,
        tipoRecursoAdquirido: hasAcquiredResourceType ? {
          codigoTipoRecursoAdquirido: valores.tipoRecursoAdquirido.codigoTipoRecursoAdquirido // Integer (codigoTipoRecursoAdquirido registrado en la base de datos)
        } : null,
        empresaGeneradora: hasGeneratingCompany ? {
          rut: valores.rut, // String (max 12)
        } : null,
        // Opcionales
        embalaje:
          hasPackagingType && !hasPackagingType ? {
            // codigoEmbalaje: valores?.embalaje?.codigoTipoEmbalaje, // String
            cantidad: null, // Double
            tipoEmbalaje: {
              codigoTipoEmbalaje: valores.embalaje.codigoTipoEmbalaje // Integer (codigoTipoEmbalaje registrado en la base de datos)
            }
          } : hasPackagingQuantity && hasPackagingQuantity
            ? {
              // codigoEmbalaje: valores?.embalaje?.codigoTipoEmbalaje, // String
              cantidad: valores.cantidadEmbalaje, // Double
              tipoEmbalaje: {
                codigoTipoEmbalaje: valores.embalaje.codigoTipoEmbalaje // Integer (codigoTipoEmbalaje registrado en la base de datos)
              }
            } : !hasPackagingType && hasPackagingQuantity
              ? {
                // codigoEmbalaje: null, // String
                cantidad: valores.cantidadEmbalaje, // Double
                tipoEmbalaje: null
              } : null
        ,
        tipoTransporte: hasTransportTypeCode ? {
          codigoTipoTransporte: valores.tipoTransporte.codigoTipoTransporte, // Integer (codigoTipoTransporte registrado en la base de datos)
        } : null
        ,
        levantamientoDatos: hasDataCollectionCode ? {
          codigoLevantamientoDatos: valores.levantamientoDatos.codigoLevantamientoDatos
        } : null // Integer (codigoLevantamientoDatos registrado en la base de datos)
        ,
        archivos: formattedFiles || []
      };

      // Restante empresa generadora
      estructuraServicio.empresaGeneradora.direccion = hasCommune ? { comuna: { codigoComuna: valores.comuna.codigoComuna } } : null // Integer (codigoComuna registrado en la base de datos)},
      estructuraServicio.empresaGeneradora.direccion = hasStreetDetails
        ? {
          ...estructuraServicio.empresaGeneradora.direccion,
          nombreVia: valores.nombreVia
        }
        : null // Integer (codigoComuna registrado en la base de datos)},
      estructuraServicio.empresaGeneradora.direccion = hasLatitudeAddress ? { ...estructuraServicio.empresaGeneradora.direccion, latitudDireccion: valores.latitudDireccion } : null // Integer (codigoComuna registrado en la base de datos)},
      estructuraServicio.empresaGeneradora.direccion = hasLongitudeAddress ? { ...estructuraServicio.empresaGeneradora.direccion, longitudDireccion: valores.longitudDireccion } : null // Integer (codigoComuna registrado en la base de datos)},

      // generacionResiduo
      estructuraServicio.generacionResiduo.procesoProductivo = hasProductionProcess ? valores.procesoProductivo : null;
      estructuraServicio.generacionResiduo.cantidad = hasWasteQuantity ? valores.cantidadResiduo : null;

      // console.log('ingresar o duplicar', estructuraServicio);

      return estructuraServicio;

    } catch (error) {
      console.log('Error en obtenerEstructuraServicio ', error);
      throw new Error('Error en obtenerEstructuraServicio ', error);
    }
  };

  /**
   * @name estructuraServicioActualizarRecursoAdquirido
   * Función para estructurar los valores del formulario a la estructura requerida por el servicio
   * @param valores estructura formik
   * @return estructura servicio recurso adquirido
   * @throws Error
   * 
   * */
  const estructuraServicioActualizarRecursoAdquirido = (valores) => {

    const { formattedFiles } = formatoArchivos(valores.archivos);

    try {
      // saber si el objeto valores correspondientes
      const hasDispatchGuideNumber = valores?.numeroGuiaDespacho;
      const hasRecyclableContent = valores?.contenidoReciclado;
      const hasRenewableContent = valores?.contenidoRenovable;
      const hasPackagingType = valores?.embalaje?.codigoTipoEmbalaje;
      const hasPackagingQuantity = valores?.cantidadEmbalaje;
      const hasCommune = valores?.comuna?.codigoComuna;
      const hasStreetDetails = valores?.nombreVia;
      const hasLatitudeAddress = valores?.latitudDireccion;
      const hasLongitudeAddress = valores?.longitudDireccion;
      const hasTransportTypeCode = valores?.tipoTransporte?.codigoTipoTransporte;
      const hasProductionProcess = valores?.procesoProductivo;
      const hasWasteQuantity = valores?.cantidadResiduo;
      const hasDataCollectionCode = valores?.levantamientoDatos?.codigoLevantamientoDatos;
      const hassTreatmentType = valores?.tipoTratamiento?.codigoTipoTratamiento;
      const hasWasteType = valores?.subTipoResiduo?.codigoTipoResiduo;
      const hasLERName = valores?.nombreSubSubLER?.codigoLER;
      const hasUnitOfMeasure = valores?.unidadMedida?.codigoUnidadMedida;
      const hasBranch = valores?.sucursal?.codigoSucursal;
      const hasAcquiredResourceType = valores?.tipoRecursoAdquirido?.codigoTipoRecursoAdquirido;
      const hasAcquisitionDate = valores?.fechaAdquisicion;
      const hasQuantity = valores?.cantidad;
      const hasGeneratingCompany = valores?.rut;
      const estructuraServicio = {
        // Obligatirios
        cantidad: hasQuantity ? valores.cantidad : null, // Double
        fechaAdquisicion: hasAcquisitionDate ? format(new Date(valores.fechaAdquisicion), 'yyyy-MM-dd') : null,  
        numeroGuiaDespacho: hasDispatchGuideNumber ? valores.numeroGuiaDespacho : null,  // Integer (max 10)
        contenidoReciclado: hasRecyclableContent ? valores.contenidoReciclado : null,  // Double
        contenidoRenovable: hasRenewableContent ? valores.contenidoRenovable : null,  // Double
        unidadMedida: hasUnitOfMeasure ? {
          codigoUnidadMedida: valores.unidadMedida.codigoUnidadMedida // Integer (codigoUnidadMedida registrado en la base de datos)
        } : null,
        generacionResiduo: {
          nombreLER: hasLERName ? {
            codigoLER: valores.nombreSubSubLER.codigoLER // Integer (codigoTipoResiduo registrado en la base de datos)
          } : null,
          tipoResiduo: hasWasteType ? {
            codigoTipoResiduo: valores.subTipoResiduo.codigoTipoResiduo // Integer (codigoTipoResiduo registrado en la base de datos)
          } : null,
          tipoTratamiento: hassTreatmentType ? {
            codigoTipoTratamiento: valores.tipoTratamiento.codigoTipoTratamiento // Integer (codigoTipoTratamiento registrado en la base de datos)
          } : null
        },
        sucursal: hasBranch ? {
          codigoSucursal: valores.sucursal.codigoSucursal // Integer (codigoSucursal registrado en la base de datos)
        } : null,
        tipoRecursoAdquirido: hasAcquiredResourceType ? {
          codigoTipoRecursoAdquirido: valores.tipoRecursoAdquirido.codigoTipoRecursoAdquirido // Integer (codigoTipoRecursoAdquirido registrado en la base de datos)
        } : null,
        empresaGeneradora: hasGeneratingCompany ? {
          rut: valores.rut, // String (max 12)
        } : null,
        // Opcionales
        embalaje:
          hasPackagingType && !hasPackagingQuantity ? {
            codigoEmbalaje: valores?.codigoEmbalaje, // String
            cantidad: null, // Double
            tipoEmbalaje: {
              codigoTipoEmbalaje: valores.embalaje.codigoTipoEmbalaje // Integer (codigoTipoEmbalaje registrado en la base de datos)
            }
          } : hasPackagingType && hasPackagingQuantity
            ? {
              codigoEmbalaje: valores?.codigoEmbalaje, // String
              cantidad: valores.cantidadEmbalaje, // Double
              tipoEmbalaje: {
                codigoTipoEmbalaje: valores.embalaje.codigoTipoEmbalaje // Integer (codigoTipoEmbalaje registrado en la base de datos)
              }
            } : !hasPackagingType && hasPackagingQuantity
              ? {
                codigoEmbalaje: null, // String
                cantidad: valores.cantidadEmbalaje, // Double
                tipoEmbalaje: null
              } : null
        ,
        tipoTransporte: hasTransportTypeCode ? {
          codigoTipoTransporte: valores.tipoTransporte.codigoTipoTransporte, // Integer (codigoTipoTransporte registrado en la base de datos)
        } : null
        ,
        levantamientoDatos: hasDataCollectionCode ? {
          codigoLevantamientoDatos: valores.levantamientoDatos.codigoLevantamientoDatos
        } : null // Integer (codigoLevantamientoDatos registrado en la base de datos)
        ,
        archivos: formattedFiles || []
      };

      // Restante empresa generadora
      estructuraServicio.empresaGeneradora.direccion = hasCommune ? { comuna: { codigoComuna: valores.comuna.codigoComuna } } : null;
      estructuraServicio.empresaGeneradora.direccion = hasStreetDetails
        ? {
          ...estructuraServicio.empresaGeneradora.direccion,
          nombreVia: valores.nombreVia,
        }
        : null // Integer (codigoComuna registrado en la base de datos)},
      estructuraServicio.empresaGeneradora.direccion =
        hasLatitudeAddress ? { ...estructuraServicio.empresaGeneradora.direccion, latitudDireccion: valores.latitudDireccion } : null; // Integer (codigoComuna registrado en la base de datos)},
      estructuraServicio.empresaGeneradora.direccion =
        hasLongitudeAddress ? { ...estructuraServicio.empresaGeneradora.direccion, longitudDireccion: valores.longitudDireccion } : null; // Integer (codigoComuna registrado en la base de datos)},

      // generacionResiduo
      estructuraServicio.generacionResiduo.procesoProductivo = hasProductionProcess ? valores.procesoProductivo : null;
      estructuraServicio.generacionResiduo.cantidad = hasWasteQuantity ? valores.cantidadResiduo : null;

      //  console.log('estructuraServicio editar :', estructuraServicio);

      return estructuraServicio;


    } catch (error) {
      console.log('Error en obtenerEstructuraServicio ', error);
      throw new Error('Error en obtenerEstructuraServicio ', error);
    }

  }

  /**
   * @name handleEliminarRecursoAdquirido
   * Función para eliminar un recurso adquirido
   *  
   * @param codAdquirido codigo del recurso adquirido
   * @param cargaRecursosAdquiridos función para cargar los recursos adquiridos
   * @return estructura servicio recurso adquirido
   * @throws Error
   *  
   * */

  const handleEliminarRecursoAdquirido = async (codAdquirido, cargaRecursosAdquiridos) => {

    const params = codAdquirido

    try {
      const response = await recursosServices.eliminaRecursoAdquirido(params);
      if (response.status === 200) {
        storeMessages.message.setMessage({
          mensaje: 'Se ha eliminado el recurso adquirido correctamente',
          tipo: 'exito'
        });
        cargaRecursosAdquiridos();
        history.push(RUTAS.RECURSOS_ADQUIRIDOS);
      }

    } catch (error) {
      console.log('Error en la eliminación del recurso adquirido ', error);
      storeMessages.message.setMessage({
        mensaje: 'No se ha podido eliminar el recurso adquirido',
        tipo: 'error'
      });
      throw new Error('Error en handleEliminarRecursoAdquirido ', error);
    }
  }

  return {
    recursoAdquirido,
    isLoading,
    setIsLoading,
    cargarRecursoAdquirido,
    cargaData,
    handleSubmit,
    handleEliminarRecursoAdquirido
  }
}
