import AccessTimeOutlined from '@mui/icons-material/AccessTimeOutlined';
import ChevronRight from '@mui/icons-material/ChevronRight';
import PinDropOutlined from '@mui/icons-material/PinDropOutlined';
import { RoleComponent } from 'components/design-system/Role/Role';
import Text from 'components/design-system/Text/Text';
import { DICTONARY } from 'const/Dictonary';
import { useContext } from 'react';
import { MatchContext } from '../../../context/MatchContext';
import { numeroBaseDatosAFront } from 'helpers/formateoNumeros';

/**
 * @name ResultadoItem
 * Componente que despliega el resumen del residuo
 * @param residuo
 */
export const ResultadoItem = ({ residuo }) => {
  const {
    rol,
    nombreResiduo,
    nombreLER,
    calculoDisponibilidad,
    cantidad,
    unidad,
    precioReferencial,
    sucursal
  } = residuo;
  const {
    setResiduoSeleccionado,
    setCoordenadasCentroMapa,
    residuoSeleccionado,
    matchFiltrado
  } = useContext(MatchContext);

  const obtenerDistancia = () => {
    const distanciaDecimales = sucursal.calculoDistancia.toFixed(
      DICTONARY.MATCH.NUMERO_DECIMALES
    );
    return numeroBaseDatosAFront(
      parseFloat(distanciaDecimales) === 0 ? 0 : distanciaDecimales
    );
  };

  const handleSeleccionarResiduo = residuo => {
    if (!residuo.sucursal.direccion) {
      const detalleSucursal = matchFiltrado.sucursales.find(
        sucursal => sucursal.codigoSucursal === residuo.sucursal.codigoSucursal
      );
      const data = {
        ...residuo,
        sucursal: detalleSucursal
      };
      setResiduoSeleccionado(data);
      setCoordenadasCentroMapa([
        detalleSucursal.direccion.latitud,
        detalleSucursal.direccion.longitud
      ]);
    } else {
      setResiduoSeleccionado(residuo);
      setCoordenadasCentroMapa([
        sucursal.direccion.latitud,
        sucursal.direccion.longitud
      ]);
    }
  };

  const isSeleccionado =
    residuo.codigoResiduo ===
    (residuoSeleccionado ? residuoSeleccionado.codigoResiduo : 'false');

  window.scrollTo(0, 0);

  return (
    <div
      onClick={() => handleSeleccionarResiduo(residuo)}
      className={`
        flex items-center hover:bg-uv-primary-90 p-4 pb-2 border-b cursor-pointer
        ${isSeleccionado ? 'bg-neutral-80 hover:bg-neutral-70' : ''}
      `}
    >
      <div className="w-4/5">
        <RoleComponent tipo="etiqueta" rol={rol.nombreRol} className="" />
        <Text className="pb-2">
          {rol.codigoRol === DICTONARY.ROL.GENERADOR.CODIGO
            ? nombreLER
            : nombreResiduo}
        </Text>

        <div
          className={`
          grid grid-cols-2 rounded-full bg-background-secondary py-1 px-4
          ${isSeleccionado ? 'bg-white' : ''}
        `}
        >
          {calculoDisponibilidad && (
            <div className="col-span-1">
              <Text className="text-black">
                <AccessTimeOutlined />{' '}
                {calculoDisponibilidad > 0 ? (
                  <>
                    {numeroBaseDatosAFront(calculoDisponibilidad)}{' '}
                    {DICTONARY.MATCH.UNIDAD_DISPONIBILIDAD}
                  </>
                ) : (
                  'Inmediata'
                )}
              </Text>
            </div>
          )}

          <div className="col-span-1">
            <Text className="text-black">
              <PinDropOutlined /> {obtenerDistancia()}{' '}
              {DICTONARY.MATCH.UNIDAD_DISTANCIA}
            </Text>
          </div>
        </div>

        {rol.codigoRol === DICTONARY.ROL.GENERADOR.CODIGO && (
          <div className="flex py-2">
            {cantidad && (
              <Text className="text-black font-semibold text-sm">
                {numeroBaseDatosAFront(cantidad)} {unidad}
              </Text>
            )}

            {precioReferencial !== null && (
              <>
                <span className="border-r mx-2"></span>
                <Text className="text-black font-semibold text-sm">
                  ${numeroBaseDatosAFront(precioReferencial)}
                </Text>
              </>
            )}
          </div>
        )}
      </div>

      <div className="w-1/5 text-right">
        <ChevronRight />
      </div>
    </div>
  );
};
