import AlertComponent from 'components/design-system/Alert/Alert';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import CheckboxComponent from 'components/design-system/Checkbox/Checkbox';
import { Divider, FormControlLabel, FormGroup } from '@mui/material';
import { useEffect } from 'react';

export const BusinessRole = ({
  values,
  errors,
  touched,
  onChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  steppers,
  setSteppers
}) => {
  /**
   * Roles
   *  Oferente      : 1
   *  Demandante    : 2
   *  Transportista : 3
   *  Gestor        : 4
   *  Asesor        : 5
   */

  useEffect(() => {
    values.codigoRolesEmpresa.length === 0 &&
      setFieldError(
        'codigoRolesEmpresa',
        'Debe seleccionar al menos un tipo de usuario'
      );
  }, [setFieldError, touched.codigoRolesEmpresa, values.codigoRolesEmpresa]);

  useEffect(() => {
    if (values.codigoRolesEmpresa.length > 0) {
      const claves = Object.keys(steppers);
      const ultimaClave = claves.pop();

      if (
        ultimaClave === '4' &&
        (values.codigoRolesEmpresa.includes('1') ||
          values.codigoRolesEmpresa.includes('2'))
      ) {
        setSteppers(steppers => {
          const newSteppers = [...steppers];
          newSteppers.pop();
          return newSteppers;
        });
      } else if (
        ultimaClave === '3' &&
        (values.codigoRolesEmpresa.includes('3') ||
          values.codigoRolesEmpresa.includes('4') ||
          values.codigoRolesEmpresa.includes('5'))
      ) {
        setSteppers(steppers => {
          const nuevoObjeto = {
            label: 'Acreditaciones y certificaciones',
            description: ``,
            id: 'acreditaciones'
          };
          return [...steppers, nuevoObjeto];
        });
      }
    }
  }, [
    setSteppers,
    steppers,
    values.codigoRolesEmpresa,
    values.codigoRolesEmpresa.length
  ]);

  return (
    <>
      <div className="grid grid-cols-12">
        <div className="col-span-12">
          <Heading type="h3">Rol del negocio</Heading>
          <Text className="mb-8">
            Selecciona el tipo de rol que deseas desempeñar en la plataforma.
          </Text>
        </div>

        <div className="col-span-12 md:col-span-9">
          <AlertComponent type="warning">
            <Heading type="h5">Atención</Heading>
            <Text>
              A diferencia de todas las otras opciones en esta página, está
              opción <b className="text-black">no podrás editarla nuevamente</b>{' '}
              en el futuro por lo que te recomendamos entender los limites y
              funcionalidades de cada rol.{' '}
              {/* <LinkComponent href="#" className="pl-1">
                Ver más sobre roles
              </LinkComponent> */}
            </Text>
          </AlertComponent>
        </div>
      </div>

      <div className="mt-5 mb-2">
        <Text>
          Selecciona <b className="text-black">almenos un</b> tipo de usuario
        </Text>
        <div className="mx-4 pt-4">
          <div className="border border-dashed h-4 w-4 border-neutral-60 rotate-45 absolute ml-16 -mt-2 border-b-0 border-r-0"></div>
          <div className="w-0 h-0 border-l-[16px] border-l-transparent border-r-[17px] border-r-transparent border-b-[16px] border-b-white absolute ml-14 -mt-2"></div>
          <div className="border border-dashed border-b-0 rounded-t-2xl h-3 border-neutral-70"></div>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-12 sm:col-span-5">
          <div className="border rounded py-8 px-5">
            <FormGroup>
              <FormControlLabel
                className=""
                control={
                  <CheckboxComponent
                    name="codigoRolesEmpresa"
                    value={1}
                    onBlur={handleBlur}
                    onChange={onChange}
                    disabled={
                      values.codigoRolesEmpresa.includes('3') ||
                      values.codigoRolesEmpresa.includes('4') ||
                      values.codigoRolesEmpresa.includes('5')
                    }
                    checked={values.codigoRolesEmpresa.includes('1')}
                  />
                }
                label={
                  <div className="ml-2">
                    <Heading
                      disabled={
                        values.codigoRolesEmpresa.includes('3') ||
                        values.codigoRolesEmpresa.includes('4') ||
                        values.codigoRolesEmpresa.includes('5')
                      }
                      type="h5"
                      className="mb-1"
                    >
                      Generador
                    </Heading>
                    <Text>
                      Publicar ofertas de materia, desechos o residuos.
                    </Text>
                  </div>
                }
              />

              <div className="my-4">
                <Divider />
              </div>

              <FormControlLabel
                className=""
                control={
                  <CheckboxComponent
                    name="codigoRolesEmpresa"
                    value={2}
                    onBlur={handleBlur}
                    onChange={onChange}
                    disabled={
                      values.codigoRolesEmpresa.includes('3') ||
                      values.codigoRolesEmpresa.includes('4') ||
                      values.codigoRolesEmpresa.includes('5')
                    }
                    checked={values.codigoRolesEmpresa.includes('2')}
                  />
                }
                label={
                  <div className="ml-2">
                    <Heading
                      disabled={
                        values.codigoRolesEmpresa.includes('3') ||
                        values.codigoRolesEmpresa.includes('4') ||
                        values.codigoRolesEmpresa.includes('5')
                      }
                      type="h5"
                      className="mb-1"
                    >
                      Receptor
                    </Heading>
                    <Text>Busca y solicita materia, desechos o residuos</Text>
                  </div>
                }
              />
            </FormGroup>
          </div>
          <div className="text-center pt-2">
            <Text>Puedes seleccionar ambos tipos de usuarios</Text>
          </div>
        </div>

        <div className="col-span-12 sm:col-span-7">
          <div className="border rounded py-4 px-5">
            <FormGroup>
              <div className="grid grid-cols-12 items-center">
                <div className="col-span-12 md:col-span-6">
                  <FormControlLabel
                    className=""
                    control={
                      <CheckboxComponent
                        name="codigoRolesEmpresa"
                        value={3}
                        onChange={e => {
                          onChange(e);
                        }}
                        onBlur={handleBlur}
                        disabled={
                          values.codigoRolesEmpresa.includes('1') ||
                          values.codigoRolesEmpresa.includes('2')
                        }
                        checked={
                          values.codigoRolesEmpresa.includes('3') ? true : false
                        }
                      />
                    }
                    label={
                      <Heading
                        disabled={
                          values.codigoRolesEmpresa.includes('1') ||
                          values.codigoRolesEmpresa.includes('2')
                        }
                        type="h5"
                        className="mb-0"
                      >
                        Transportista
                      </Heading>
                    }
                  />
                </div>
                <div className="col-span-12 md:col-span-6">
                  <Text>Movilizar los residuos transaccionados</Text>
                </div>
              </div>

              <div className="my-4">
                <Divider />
              </div>

              <div className="grid grid-cols-12 items-center">
                <div className="col-span-12 md:col-span-6">
                  <FormControlLabel
                    className=""
                    control={
                      <CheckboxComponent
                        name="codigoRolesEmpresa"
                        value={4}
                        onChange={onChange}
                        onBlur={handleBlur}
                        disabled={
                          values.codigoRolesEmpresa.includes('1') ||
                          values.codigoRolesEmpresa.includes('2')
                        }
                        checked={
                          values.codigoRolesEmpresa.includes('4') ? true : false
                        }
                      />
                    }
                    label={
                      <Heading
                        disabled={
                          values.codigoRolesEmpresa.includes('1') ||
                          values.codigoRolesEmpresa.includes('2')
                        }
                        type="h5"
                        className="mb-0"
                      >
                        Gestor
                      </Heading>
                    }
                  />
                </div>
                <div className="col-span-12 md:col-span-6">
                  <Text>Gestiona materia, desechos o residuos</Text>
                </div>
              </div>

              <div className="my-4">
                <Divider />
              </div>

              <div className="grid grid-cols-12 items-center">
                <div className="col-span-12 md:col-span-6">
                  <FormControlLabel
                    className=""
                    control={
                      <CheckboxComponent
                        name="codigoRolesEmpresa"
                        value={5}
                        onChange={onChange}
                        disabled={
                          values.codigoRolesEmpresa.includes('1') ||
                          values.codigoRolesEmpresa.includes('2')
                        }
                        checked={
                          values.codigoRolesEmpresa.includes('5') ? true : false
                        }
                      />
                    }
                    onBlur={handleBlur}
                    label={
                      <Heading
                        disabled={
                          values.codigoRolesEmpresa.includes('1') ||
                          values.codigoRolesEmpresa.includes('2')
                        }
                        type="h5"
                        className="mb-0"
                      >
                        Asesor
                      </Heading>
                    }
                  />
                </div>
                <div className="col-span-12 md:col-span-6">
                  <Text>
                    Generar oportunidades de negocio al recopilar datos
                  </Text>
                </div>
              </div>
            </FormGroup>
          </div>
          <div className="text-center pt-2">
            <Text>
              Puedes seleccionar múltiples roles en esta misma categoría
            </Text>
          </div>
        </div>
      </div>

      <Text className="text-error-dark">
        {' '}
        {errors.codigoRolesEmpresa ? errors.codigoRolesEmpresa : ''}
      </Text>
    </>
  );
};

export default BusinessRole;
