import React, { useContext, useEffect, useState } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { RouteWithLayout } from 'components';
import { Main as MainLayout } from 'layouts';
import {
  /* Cambiar Contraseña */
  OlvidoContrasena as OlvidoContrasenaView
} from 'views';
import store from 'states/store';
import storeMessage from 'states/alertMessages/store';
import useAlertMessage from 'states/alertMessages/use.alertMessages';
import useLogedUser from 'states/use.logedUser';

import storeBackdropLoading from 'states/backdropLoading/store';
import useBackdropLoading from 'states/backdropLoading/use.backdropLoading';
import { RUTAS } from 'const/Dictonary';
import { RutasPublicas } from './RutasPublicas';
import { RutasPrivadas } from './RutasPrivadas';
// Context
import { AuthContext } from 'context';

const Routes = () => {
  const [redirectCambioContrasena, setRedirectCambioContrasena] =
    useState(false);

  const { logged } = useContext(AuthContext);

  store.loguedUser = useLogedUser();
  storeMessage.message = useAlertMessage();
  storeBackdropLoading.backdropLoading = useBackdropLoading();

  useEffect(() => {
    if (!store.loguedUser || !store.loguedUser.logeduser) {
      return;
    }
    if (
      store.loguedUser.logeduser.passwordTemporal &&
      window.location.pathname !== RUTAS.CAMBIAR_CONTRASENA
    ) {
      setRedirectCambioContrasena(true);
    }
  }, [store.loguedUser]);

  if (logged && redirectCambioContrasena) {
    return (
      <Switch>
        <RouteWithLayout
          component={OlvidoContrasenaView}
          exact
          store={store}
          layout={MainLayout}
          path={RUTAS.CAMBIAR_CONTRASENA}
        />
        <Redirect to={RUTAS.CAMBIAR_CONTRASENA} />
      </Switch>
    );
  } else {
    return (
      <>
        {logged ? (
          // Rutas privadas para usuarios logueados
          <>
            {/* {console.log('RutasPrivadas')} */}
            <RutasPrivadas store={store} />
          </>
        ) : (
          // Rutas publicas para usuarios no logueados
          <>
            {/* {console.log('RutasPublicas')} */}
            <RutasPublicas />
          </>
        )}
      </>
    );
  }
};

export default Routes;
