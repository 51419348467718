import { useCallback, useEffect, useState } from 'react';
import Dropzone from 'components/design-system/Dropzone/Dropzone';
import Heading from 'components/design-system/Heading/Heading';
import IconComponent from 'components/design-system/icon/Icon';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Text from 'components/design-system/Text/Text';
import { DICTONARY } from 'const/Dictonary';
import { FilethumbComponent } from 'components/design-system/Filethumb/Filethumb';
import storeMessages from 'states/alertMessages/store';
import serviceDocumentos from 'services/documentos';
export const BusinessCertifications = ({
  values,
  errors,
  touched,
  onChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  setStep,
  handleSubmit,
  codEmpresa
}) => {
  const [dataFiles, setDataFiles] = useState([]);
  const [cargandoDocumentos, setCargandoDocumentos] = useState(true);

  const [dataFilesLocal, setDataFilesLocal] = useState([]);
  const [guardandoDataFiles, setGuardandoDataFiles] = useState(false);

  const cargaDocumentos = useCallback(async () => {
    setCargandoDocumentos(true);
    try {
      const response = await serviceDocumentos.obtenerArchivos({
        codigoEmpresa: codEmpresa
      });
      if (response.data) {
        const resultTemp = response.data;
        resultTemp.length > 0 &&
          resultTemp.map(item => {
            const extension =
              item.tipoArchivo === '.png' || item.tipoArchivo === 'png'
                ? 'data:image/png;base64,'
                : item.tipoArchivo === '.jpg' || item.tipoArchivo === 'jpg'
                ? 'data:image/jpeg;base64,'
                : item.tipoArchivo === '.pdf' || item.tipoArchivo === 'pdf'
                ? 'data:application/pdf;base64,'
                : item.tipoArchivo === '.doc' || item.tipoArchivo === 'doc'
                ? 'data:application/msword;base64,'
                : item.tipoArchivo === '.docx' || item.tipoArchivo === 'docx'
                ? 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,'
                : item.tipoArchivo === '.xls' || item.tipoArchivo === 'xls'
                ? 'data:application/vnd.ms-excel;base64,'
                : item.tipoArchivo === '.xlsx' || item.tipoArchivo === 'xlsx'
                ? 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
                : item.tipoArchivo === '.ppt' || item.tipoArchivo === 'ppt'
                ? 'data:application/vnd.ms-powerpoint;base64,'
                : item.tipoArchivo === '.pptx' || item.tipoArchivo === 'pptx'
                ? 'data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,'
                : item.tipoArchivo === '.txt' || item.tipoArchivo === 'txt'
                ? 'data:text/plain;base64,'
                : '';
            item.base64 = extension + item.base64Archivo;

            // string sin puntos al comienzo
            item.extension = item.tipoArchivo.replace(/^\.+/, '');
            return item;
          });
        if (resultTemp.length > 0) {
          setDataFiles(resultTemp);
          setDataFilesLocal(resultTemp);
          setFieldValue('archivos', resultTemp);
        } else {
          setDataFiles([]);
          setDataFilesLocal([]);
          setFieldValue('archivos', []);
        }
      } else {
        setDataFiles([]);
      }
    } catch (error) {
      console.log('Error al cargar documentos:', error);
      setDataFiles([]);
    } finally {
      setCargandoDocumentos(false);
    }
  }, [codEmpresa, setFieldValue]);

  useEffect(() => {
    cargaDocumentos();
    return () => {
      setDataFiles([]);
      setDataFilesLocal([]);
    };
  }, [cargaDocumentos]);

  useEffect(() => {
    let nuevosArchivos = [];

    if (dataFiles !== dataFilesLocal) {
      setFieldTouched('archivos', true);
      setFieldValue('interactuandoArchivos', true);
      nuevosArchivos = dataFiles.filter(
        ({ codigoArchivo: id1 }) =>
          !dataFilesLocal.some(({ codigoArchivo: id2 }) => id2 === id1)
      );
    } else {
      nuevosArchivos = dataFilesLocal;
    }

    if (dataFiles.length <= 4) {
      // entre dos objetos necesito dejar uno que no repita los valores
      // console.log('nuevosArchivos', nuevosArchivos);
      setFieldValue('archivos', nuevosArchivos);
    } else {
      // console.log('maximo de archivos alcanzado');
      setFieldTouched('archivos', true);
      setFieldValue('interactuandoArchivos', true);

      storeMessages.message.setMessage({
        mensaje:
          'Haz alcanzado el máximo de archivos permitidos. Si deseas agregar más archivos, elimina alguno de los que ya has cargado.',
        tipo: 'warning'
      });
    }

    // return () => {
    //   setFieldValue('interactuandoArchivos', false);
    //   setFieldTouched('archivos', false);
    // };
  }, [dataFiles, dataFilesLocal, setFieldTouched, setFieldValue]);

  const eliminarArchivo = useCallback(
    file => {
      const archivosEliminados = file.codigoArchivo
        ? [...values.archivosEliminados, parseInt(file.codigoArchivo)]
        : values.archivosEliminados;
      setFieldValue('archivosEliminados', archivosEliminados);
      setFieldValue('eliminandoArchivos', true);

      const archivosFiltrados = dataFilesLocal.filter(
        archivo => archivo.codigoArchivo !== file.codigoArchivo
      );
      setDataFilesLocal(archivosFiltrados);
      setDataFiles([]);
      setFieldValue('archivos', archivosFiltrados);

      setFieldValue('interactuandoArchivos', true);
      setFieldTouched('archivos', true);
      return;
    },
    [ dataFilesLocal, 
      setFieldTouched, 
      setFieldValue, 
      values.archivosEliminados
    ]
  );

  useEffect(() => {
    // console.log(values.archivos);
  }, [
    dataFiles.length, 
    dataFilesLocal, 
    values.archivos, 
    values.archivosEliminados, 
    values.interactuandoArchivos]
    )
  

  return (
    <div className="px-4">
      <div className="grid grid-cols-12">
        <div className="col-span-9">
          <Heading type="h3">
            Acreditaciones y certificaciones <small>(opcional)</small>
          </Heading>
          <Text className="mb-8">
            Puedes cargar las acreditaciones y certificaciones de tu negocio las
            cuales serán visibles para los usuarios que quieran ver el perfíl de
            tu negocio, este paso lo puedes realizar ahora si tienes los
            antecedentes o efectuar la carga despúes.
          </Text>
        </div>

        <div className="col-span-12">
          <Dropzone
            name="archivos"
            disabled={cargandoDocumentos || dataFilesLocal.length >= 4}
            setDataFiles={setDataFiles}
            enableEditingFile={false}
            dataFiles={dataFiles}
            {...DICTONARY.DROPZONE.CONFIG_UPLOAD_BUSINESS_FILES}
          >
            <Text className="text-black font-medium">
              Solo se aceptan los siguientes tipos de archivos
            </Text>
            <ul className="flex mt-1">
              <li className="d-flex items-center mb-1">
                <IconComponent className="mr-2">
                  <TaskAltIcon className="text-base text-uv-primary-20" />
                </IconComponent>
                <Text className="text-neutral-40">Formatos JPG y PDF</Text>
              </li>
              <li className="d-flex items-center mb-1 ml-6">
                <IconComponent className="mr-2">
                  <TaskAltIcon className="text-base text-uv-primary-20" />
                </IconComponent>
                <Text className="text-neutral-40">Tamaño no superior a 4 MB</Text>
              </li>
            </ul>
          </Dropzone>

          {dataFilesLocal.length > 0 && !cargandoDocumentos && (
            <aside className="mt-5">
              <Text className="mb-2">Archivos Guardados</Text>
              <div className="rounded bg-background-secondary p-5">
                <div className="grid grid-cols-4">
                  {dataFilesLocal.map((file, key) => (
                    <FilethumbComponent
                      key={key}
                      disabled={guardandoDataFiles}
                      file={{
                        preview: dataFilesLocal[key].base64,
                        type: dataFilesLocal[key].extension,
                        name: dataFilesLocal[key].nombreArchivo
                      }}
                      onRemove={() => {
                        eliminarArchivo(file);
                      }}
                    />
                  ))}
                </div>
              </div>
            </aside>
          )}
        </div>
      </div>
    </div>
  );
};

export default BusinessCertifications;
