import Heading from 'components/design-system/Heading/Heading';
import IconComponent from 'components/design-system/icon/Icon';
import Warehouse from '@mui/icons-material/Warehouse';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link } from 'react-router-dom';
import { RUTAS } from 'const/Dictonary';
import ButtonComponent from 'components/design-system/Button/Button';
import TravelExplore from '@mui/icons-material/TravelExplore';
import LocalShipping from '@mui/icons-material/LocalShipping';
import { useEffect, useState } from 'react';
import { AccountBalance, Group } from '@mui/icons-material';
import store from 'states/store';

export const CuadroRecomendaciones = ({ isSuperAdmin }) => {
  const [recomendaciones, setRecomendaciones] = useState([]);
  const { logeduser } = store.loguedUser;
  const codigoEmpresa = logeduser?.sucursal
    ? logeduser.sucursal.empresa.codigoEmpresa
    : null;

  useEffect(() => {
    isSuperAdmin
      ? setRecomendaciones(recomendacionesAdministrador)
      : setRecomendaciones(recomendacionesUsuario);
  }, []);

  const recomendacionesUsuario = [
    {
      icono: <Warehouse className="w-8 h-8" />,
      titulo: (
        <>
          Administrar las <br />
          sucursales asociadas
        </>
      ),
      ruta: `${RUTAS.NEGOCIO}/${codigoEmpresa}/sucursales`
    },
    {
      icono: <TravelExplore className="w-8 h-8" />,
      titulo: (
        <>
          Buscar una <br />
          empresa o negocio
        </>
      ),
      ruta: RUTAS.MARKETPLACE_EMPRESAS
    },
    {
      icono: <LocalShipping className="w-8 h-8" />,
      titulo: (
        <>
          Buscar un <br />
          residuo o recurso
        </>
      ),
      ruta: RUTAS.MARKETPLACE_RESIDUOS
    }
  ];

  const recomendacionesAdministrador = [
    {
      icono: <AccountBalance className="w-8 h-8" />,
      titulo: (
        <>
          Administrar los <br />
          negocios registrados
        </>
      ),
      ruta: RUTAS.ADMINISTRAR_NEGOCIOS
    },
    {
      icono: <Group className="w-8 h-8" />,
      titulo: (
        <>
          Administrar los <br />
          usuarios registrado
        </>
      ),
      ruta: RUTAS.ADMINISTRAR_USUARIOS
    },
    {
      icono: <Warehouse className="w-8 h-8" />,
      titulo: (
        <>
          Administrar las <br />
          sucursales registradas
        </>
      ),
      ruta: RUTAS.ADMINISTRAR_SUCURSALES
    }
  ];

  return (
    <div className="mb-8">
      <div className="bg-background-primary px-6 py-4 rounded">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12">
            <Heading type="H3">Te recomendamos</Heading>
          </div>
          {recomendaciones.map((recomendacion, index) => (
            <div
              className="col-span-12 sm:col-span-6 md:col-span-4 bg-white p-5 rounded shadow-md"
              key={index}
            >
              <div className="block sm:flex">
                <div className="w-12 h-12 rounded-full bg-uv-primary-0 align-middle justify-center content-center items-center mr-4">
                  <IconComponent color="white">
                    {recomendacion.icono}
                  </IconComponent>
                </div>
                <div>
                  <Heading type="H5" className="!text-uv-primary-0">
                    {recomendacion.titulo}
                  </Heading>
                  <Link to={recomendacion.ruta}>
                    <ButtonComponent type="primary">
                      Iniciar
                      <KeyboardArrowRightIcon />
                    </ButtonComponent>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
