import { FormularioDemandante } from '../Secciones/FormularioDemandante';
import { SwitchStepsComponent } from '../SwitchSteps/SwitchSteps';


export const CamposResiduosFormik = ({
  codigoResiduo,
  errors,
  handleBlur,
  handleChange,
  handleReset,
  handleSubmit,
  isSubmitting,
  setFieldError,
  setFieldTouched,
  setFieldValue,
  touched,
  validateForm,
  values,
  // Props adicionales
  estadoGuardado,
  isLoading,
  setStateValidation,
  step,
  isNombreRolOferente,
  isNombreRolDemandante,
  tipoFormulario,
  tipoFormularioSeleccionado,
  ...restProps
}) => {


  return (
    <>
      {
      isNombreRolOferente ?
        SwitchStepsComponent({
          codigoResiduo,
          errors,
          handleBlur,
          handleChange,
          handleReset,
          handleSubmit,
          isSubmitting,
          setFieldError,
          setFieldTouched,
          setFieldValue,
          touched,
          validateForm,
          values,
          // Props adicionales
          estadoGuardado,
          isLoading,
          setStateValidation,
          step,
          tipoFormulario,
          tipoFormularioSeleccionado

        })
        :
        <FormularioDemandante 
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          handleReset={handleReset}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          setFieldError={setFieldError}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          touched={touched}
          validateForm={validateForm}
          values={values}
          // Props adicionales
          estadoGuardado={estadoGuardado}
          isLoading={isLoading}
          setStateValidation={setStateValidation}
          isNombreRolDemandante={isNombreRolDemandante}
          tipoFormulario={tipoFormulario}
          tipoFormularioSeleccionado={tipoFormularioSeleccionado}
        />
    

      }

      

    </>
  )
}
