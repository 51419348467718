const businessOptValues = [
  /**
   * value :
   * id    : id del checkbox
   * label :
   * text  : texto utilizado en el componente, puede ser en formato html
   */
  {
    value: 'empresa',
    id: 'radio-one',
    label: 'Empresa',
    text: `Al elegir esta opción tu empresa debe estar <strong>inscrita legalmente</strong> en el SII (Servicio de impuestos internos)`
  },
  {
    value: 'persona-natural',
    id: 'radio-two',
    label: 'Persona Natural',
    text: 'Al elegir esta opción defines que actuarás como una persona privada en las interacciones de la plataforma'
  }
];

export { businessOptValues };
