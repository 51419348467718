import * as yup from 'yup';
import { DICTONARY } from 'const/Dictonary';
import ValidateRut from 'helpers/validateRut';

const emailRegex = DICTONARY.EMAIL_REGEX;

const schemaEditarCuenta = yup.object().shape({
  rutUsuario: yup
    .string()
    .trim()
    .test(
      'Evaluación de rut',
      'El rut ingresado es inválido',
      value => !value || ValidateRut.isValid(value)
    ),

  primerNombre: yup
    .string()
    .max(25, 'El nombre no puede ser tan largo')
    .required('Este campo es obligatorio'),

  apellidoPaterno: yup
    .string()
    .max(25, 'El segundo nombre no puede ser tan largo')
    .required('Este campo es obligatorio'),

  apellidoMaterno: yup
    .string()
    .max(25, 'El segundo nombre no puede ser tan largo')
    .required('Este campo es obligatorio'),

  correoElectronico: yup
    .string()
    .matches(emailRegex, 'El correo ingresado es inválido')
    .required('Este campo es obligatorio'),

  telefonoUsuario: yup
    .string()
    .max(25, 'El segundo nombre no puede ser tan largo'),

  profesion: yup
    .string()
    .max(50, 'El nombre de la profesion es demasiado largo'),

  imagenBase64: yup.string(),

  perfiles: yup
    .object()
    .typeError('Este campo es obligatorio')
    .required('Este campo es obligatorio'),

  empresa: yup.object().when('perfiles', {
    is: perfiles =>
      perfiles?.codigoPerfil === DICTONARY.CODIGOS_PERFILES.ADMINISTRADOR ||
      perfiles?.codigoPerfil === DICTONARY.CODIGOS_PERFILES.FUNCIONARIO,
    then: () =>
      yup
        .object()
        .typeError('Este campo es obligatorio')
        .required('Este campo es obligatorio')
  }),

  sucursal: yup.object().when('perfiles', {
    is: perfiles =>
      perfiles?.codigoPerfil === DICTONARY.CODIGOS_PERFILES.ADMINISTRADOR ||
      perfiles?.codigoPerfil === DICTONARY.CODIGOS_PERFILES.FUNCIONARIO,
    then: () =>
      yup
        .object()
        .typeError('Este campo es obligatorio')
        .required('Este campo es obligatorio')
  }),

  region: yup.object().when('perfiles', {
    is: perfiles =>
      perfiles?.codigoPerfil ===
      DICTONARY.CODIGOS_PERFILES.ANALISTA_TERRITORIAL,
    then: () =>
      yup
        .object()
        .typeError('Este campo es obligatorio')
        .required('Este campo es obligatorio')
  })
});

export { schemaEditarCuenta };
