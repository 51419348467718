import Edit from '@mui/icons-material/Edit';
import TaskAlt from '@mui/icons-material/TaskAlt';
import { Divider } from '@mui/material';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import Text from 'components/design-system/Text/Text';
import { useContext } from 'react';
import IconComponent from 'components/design-system/icon/Icon';
import { MatchContext } from '../../../context/MatchContext';
import { PopperComponent } from 'components/design-system/Popper/Popper';
import { Help } from '@mui/icons-material';
import FiltrarMatch from '../FiltrarMatch/FiltrarMatch';

/**
 * @name CriteriosMatch
 * Despliega el resumen de los criterios utilizados en el match automático o manual a modo informátivo.
 */
export const CriteriosMatch = () => {
  const {
    setIsNuevosCriterios,
    isCargandoMatch,
    isCargandoMatchResiduos,
    criteriosMatch,
    isNuevosCriterios
  } = useContext(MatchContext);

  const handleEstablecerNuevosCriterios = async () => {
    setIsNuevosCriterios(true);
  };

  return (
    <div className="col-span-12">
      <div className="flex flex-col sm:flex-row justify-between">
        <div className="flex min-w-[260px]">
          <Heading
            type="H4"
            className="flex content-center items-center font-medium mb-0"
          >
            <span>
              Criterios usados en <b className="text-black font-bold">Match</b>
            </span>
            <PopperComponent
              Button={
                <IconButtonComponent size="small" type="neutral">
                  <Help className="text-uv-secondary-0" />
                </IconButtonComponent>
              }
              showCloseButton={true}
            >
              <div className="py-2 px-4 max-w-[600px]">
                <div className="grid gap-4 grid-cols-12">
                  <div className="col-span-12">
                    <Text>
                      Las opciones seleccionadas han sido cargadas
                      automáticamente debido a los ajustes que has establecido
                      en la edición de residuos y recursos de tu Negocio.
                    </Text>
                  </div>

                  <div className="col-span-12">
                    <Divider />
                  </div>

                  <div className="col-span-6">
                    <Heading type="h5">RESIDUOS</Heading>
                    <ListaCriterios
                      data={criteriosMatch.tipoResiduos}
                      nombre="RESIDUOS"
                      isManual={criteriosMatch.manual}
                    />
                  </div>
                  <div className="col-span-6">
                    <Heading type="h5">RECURSOS</Heading>
                    <ListaCriterios
                      data={criteriosMatch.tipoRecursos}
                      nombre="RECURSOS"
                      isManual={criteriosMatch.manual}
                    />
                  </div>
                </div>
              </div>
            </PopperComponent>
          </Heading>
        </div>

        <div className="flex flex-row justify-between w-full">
          <ButtonComponent
            disabled={
              isCargandoMatch || isNuevosCriterios || isCargandoMatchResiduos
            }
            isLoading={isCargandoMatch}
            onClick={handleEstablecerNuevosCriterios}
          >
            {isCargandoMatch || isCargandoMatchResiduos ? (
              <>Buscando...</>
            ) : (
              <>
                <Edit /> <span className="hidden xs:block">Nueva búsqueda</span>
              </>
            )}
          </ButtonComponent>
          {!isCargandoMatch && <FiltrarMatch />}
        </div>
      </div>
    </div>
  );
};

const ListaCriterios = ({ data = [], nombre, isManual }) => {
  return (
    <Text size="s">
      {data.length > 0 ? (
        <div className="p-4 overflow-y-auto scrollbar-uv max-h-[40vh] bg-uv-primary-90">
          <ul>
            {data.map((item, key) => (
              <li key={key} className="d-flex items-center mb-1">
                <IconComponent className="mr-2">
                  <TaskAlt className="text-base" />
                </IconComponent>
                <Text>{item.nombre}</Text>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <>{`No has seleccionado categorías en ${nombre}.`}</>
      )}
    </Text>
  );
};
