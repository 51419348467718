import { useState, useEffect, useContext } from 'react';
import { AuthContext } from 'context';
import { DICTONARY } from 'const/Dictonary';

function getTipo(logeduser) {
  let tipo = {
    isDemandante: false,
    isOferente: false
  };

  if (
    !logeduser ||
    !logeduser.sucursal ||
    !Array.isArray(logeduser.sucursal.empresa.roles)
  ) {
    return tipo;
  }

  /**
   * Roles
   *  Generador     : 1
   *  Receptor      : 2
   *  Transportista : 3
   *  Gestor        : 4
   *  Asesor        : 5
   */
  if (logeduser.sucursal?.empresa.roles.find(t => t.codigoRol === 1)) {
    tipo.isOferente = true;
  }
  if (logeduser.sucursal?.empresa.roles.find(t => t.codigoRol === 2)) {
    tipo.isDemandante = true;
  }
  if (logeduser.sucursal?.empresa.roles.find(t => t.nombreRol === 3)) {
    tipo.isTransportista = true;
  }
  if (logeduser.sucursal?.empresa.roles.find(t => t.nombreRol === 4)) {
    tipo.isGestor = true;
  }
  if (logeduser.sucursal?.empresa.roles.find(t => t.nombreRol === 5)) {
    tipo.isAsesor = true;
  }
  return tipo;
}

function getPerfiles(logeduser) {
  let newPerfiles = {
    isSuperAdmin: false,
    isAdmin: false,
    isFuncionario: false,
    isAnalistaTerritorial: false
  };
  if (logeduser && Array.isArray(logeduser.perfiles)) {
    var superadmin = logeduser.perfiles.find(
      p => p.nombrePerfil === 'SuperAdministrador'
    );
    if (superadmin) {
      newPerfiles.isSuperAdmin = true;
    }

    var admin = logeduser.perfiles.find(
      p => p.nombrePerfil === 'Administrador'
    );
    if (admin) {
      newPerfiles.isAdmin = true;
    }

    var funcionario = logeduser.perfiles.find(
      p => p.nombrePerfil === 'Funcionario'
    );
    if (funcionario) {
      newPerfiles.isFuncionario = true;
    }

    var analistaTerritorial = logeduser.perfiles.find(
      p => p.nombrePerfil === 'Analista Territorial'
    );
    if (analistaTerritorial) {
      newPerfiles.isAnalistaTerritorial = true;
    }
  }
  return newPerfiles;
}

const getTiposArray = tipos => {
  const nuevoTipos = [];

  if (tipos.isDemandante === true) {
    nuevoTipos.push(DICTONARY.ROL.RECEPTOR.NOMBRE);
  }

  if (tipos.isOferente === true) {
    nuevoTipos.push(DICTONARY.ROL.GENERADOR.NOMBRE);
  }

  if (tipos.isGestor === true) {
    nuevoTipos.push(DICTONARY.ROL.GESTOR.NOMBRE);
  }

  if (tipos.isTransportista === true) {
    nuevoTipos.push(DICTONARY.ROL.TRANSPORTISTA.NOMBRE);
  }

  if (tipos.isAsesor === true) {
    nuevoTipos.push(DICTONARY.ROL.ASESOR.NOMBRE)
  }
  return nuevoTipos;
};

function useLogedUser(user, pass) {
  // Logout Context
  const { logout } = useContext(AuthContext);

  const [logeduser, _setLogeduser] = useState(undefined);
  const [firstate, setFirstate] = useState(false);
  const [permisos, setPermisos] = useState(null);
  const [expiredUserEmail, setExpiredUserEmail] = useState(null);
  const [perfiles, setPerfiles] = useState({
    isSuperAdmin: false,
    isAdmin: false,
    isFuncionario: false
  });
  const [tipos, setTipos] = useState({
    isDemandante: false,
    isOferente: false,
    isGestor: false,
    isTransportista: false,
    isAsesor: false
  });
  const [listaTipos, setListaTipos] = useState([]);
  const [token, _setToken] = useState(null);
  const setToken = token => {
    if (token === 'null') {
      _setToken(null);
    } else {
      _setToken(token);
    }
  };

  const setLogeduser = user => {
    if (user) {
      if (!user.empresa) {
        try {
          const p = user.perfiles.find(
            p => p.nombrePerfil === 'SuperAdministrador'
          );
          if (!p) {
            console.warn('USUARIO SIN EMPRESA', user);
            user.empresa = { codigoEmpresa: '0' };
          }
        } catch (error) {
          console.warn('USUARIO SIN EMPRESA', user);
          user.empresa = { codigoEmpresa: '0' };
        }
      }
    }
    _setLogeduser(user);
  };

  const loadFromLocalStorage = () => {
    const storedToken = localStorage.token;

    if (storedToken && storedToken !== 'null') {
      setToken(storedToken);
      setFirstate(true);
    }

    try {
      let logeduser = JSON.parse(localStorage.logeduser);
      let tipos = getTipo(logeduser);

      setPerfiles(getPerfiles(logeduser));
      setPermisos(logeduser ? logeduser.permisos : null);
      setTipos(tipos);
      setLogeduser(logeduser);
      setListaTipos(getTiposArray(tipos));
    } catch (error) {}

    if (!storedToken || storedToken === 'null') {
      setFirstate(true);
      requestNewLogin();
    }
  };

  useEffect(loadFromLocalStorage, []);

  useEffect(() => {
    if (typeof logeduser === 'undefined' || logeduser === null) {
      return;
    }

    try {
      localStorage.logeduser = JSON.stringify(logeduser);
    } catch (error) {}
    setPerfiles(getPerfiles(logeduser));
    setTipos(getTipo(logeduser));
    setPermisos(logeduser.permisos);
  }, [logeduser]);

  useEffect(() => {
    localStorage.token = token;
    if (token) {
      setExpiredUserEmail(null);
    }
    let localCodUser;
    let logCodeUser;

    try {
      const LU = logeduser && JSON.parse(localStorage.logeduser);
      localCodUser = LU && LU.codigoUsuario;
    } catch (error) {
      //cerrar la sesion cuando no hay
      logout();
    }

    logCodeUser = logeduser && logeduser.codigoUsuario;

    if (localCodUser && logCodeUser && localCodUser !== logCodeUser) {
      console.log({ localCodUser, logCodeUser });
      loadFromLocalStorage();
    }
  }, [logeduser, logout, token]);

  const getSucursal = id => {
    return logeduser?.sucursal ? logeduser.sucursal : null;
  };

  const requestNewLogin = () => {
    setExpiredUserEmail(logeduser ? logeduser.correoElectronico : null);
    setLogeduser(null);
    setToken(null);
  };

  return {
    logeduser,
    setLogeduser,
    token,
    setToken,
    perfiles,
    tipos,
    listaTipos,
    requestNewLogin,
    expiredUserEmail,
    firstate,
    permisos,
    getSucursal: getSucursal,
    logout
  };
}

export default useLogedUser;
