import { DICTONARY } from 'const/Dictonary';

export const initRadioBusqueda = {
  limitarRadio: false,
  radio: DICTONARY.MAPA_RADIO.DEFAULT
};

export const initFormularioFiltros = {
  empresa: '',
  rubro: { codigoCiuRubro: '', nombreRubro: '' },
  radio: 10,
  limitarRadio: false,
  rolesFiltrado: []
};

/**
 * @name initOrdernarMatch
 * Array de opciones para ordenamiento.
 */
export const initOrdernarMatch = ['disponibilidad', 'distancia'];

/**
 * @name INIT_CONTEXT_MATCH
 * @isCargandoMatch       Flag para reconocer cuando está cargando por llamada a match automático o manual
 * @isNuevosCriterios     Flag para mostrar nueva selección de criterios de búsqueda de match.
 * @radioBusqueda         Determina el radio de alcance al filtrar por radio en cuadro de filtros
 * @match                 Resultado de servicio match automático o manual sin alteración
 * @matchFiltrado         Resultado de servicio match automático o manual sin alteración
 * @criteriosMatch        Objeto que contiene los residuos o tipo de recursos usados como match manual o automático
 * @ordenarMatch          Array con opciones de disponibles de ordenamiento de match filtrado.
 * @residuoSeleccionado   Objeto utilizado para cuando un usuario selecciona un residuo de la barra lateral y desea ver el detalle.
 * @coordenadasCentroMapa Parametros para centrar el mapa al cargar por primera vez.
 * @sucursalUsuario       Datos del usuario para mostrar su posición y marcardor en el mapa.
 * @transacciones         Almacena las transacciones activas del usuario para deshabilitar el botón de iniciar transacción
 * @tieneNegocio          Almacena las transacciones activas del usuario para deshabilitar el botón de iniciar transacción
 */
export const INIT_CONTEXT_MATCH = {
  isCargandoMatch: true,
  isNuevosCriterios: false,
  radioBusqueda: initRadioBusqueda,
  tabActiva: 'RESIDUOS',
  isCargandoMatchResiduos: false,
  isCargandoMatchEmpresas: false,
  isCargandoMatchAmpliada: false,

  match: {
    sucursales: [],
    residuos: [],
    numeroSucursales: 0,
    numeroResiduos: 0
  },
  matchFiltrado: {
    sucursales: [],
    residuos: [],
    numeroResiduos: 0,
    numeroSucursales: 0
  },

  matchEmpresas: {
    sucursales: [],
    numeroSucursales: 0
  },
  matchEmpresasFiltrado: {
    sucursales: [],
    numeroSucursales: 0
  },

  matchAmpliada: {
    sucursales: [],
    numeroSucursales: 0
  },
  matchAmpliadaFiltrado: {
    sucursales: [],
    numeroSucursales: 0
  },

  criteriosMatch: {
    tipoResiduos: [],
    tipoRecursos: [],
    manual: false,
    isBusquedaAmpliada: false
  },

  ordenarMatch: initOrdernarMatch,
  residuoSeleccionado: null,
  sucursalSeleccionada: null,
  sucursalATSeleccionada: null,

  coordenadasCentroMapa: null,
  sucursalUsuario: { sucursal: {}, coordenadas: [] },
  transacciones: [],
  salasChat: {
    residuos: [],
    empresas: []
  },

  //Para usuarios sin negocio:
  tieneNegocio: false,
  matchStep: 'loading',
  empresaSeleccionada: null,

  filtrosAplicados: {
    empresa: null,
    rubro: null,
    radio: 10,
    limitarRadio: false,
    rolesFiltrado: []
  }
};
