import API from './api';
import store from '../states/store.js';

const chatServices = {
  crearSalaChat: function (data, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: '/sala-chat',
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      data: data,
      interceptor : conInterceptor
    })
  },

  listaMensajes: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/sala-chat/${params.codigoSalaChat}/mensajes`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: params,
      interceptor : conInterceptor
    })
  },

  listaUsuariosSalaChat: function (roomid, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/sala-chat/${roomid}/usuarios`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor : conInterceptor
    })
  },

  agregaUsuarioSalaChat: function (data, conInterceptor = true) {
    return API.axios({
      method: 'put',
      url: `/sala-chat/${data.codigoSalaChat}/agregar-usuario`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      data: data,
      interceptor : conInterceptor
    })
  },

  eliminarUsuarioSalaChat: function (data, conInterceptor = true) {
    return API.axios({
      method: 'put',
      url: `/sala-chat/${data.codigoSalaChat}/eliminar-usuario`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      data: data,
      interceptor : conInterceptor
    })
  },

  salaChat: function (params, conInterceptor = true) {    
    return API.axios({
      method: 'get',
      url: '/sala-chat',
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: params,
      interceptor : conInterceptor
    })
  }
};

export default chatServices;
