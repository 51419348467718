import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import _store from '../../states/store';
import AvisoAUsuario from '../Messages/AvisoAUsuario';
import BackdropLoading from '../BackdropLoading';
import PropTypes from 'prop-types';
import storeBackdropLoading from '../../states/backdropLoading/store';

const ALLOWED = [
  '/',
  '/not-found',
  '/registro',
  '/verifica-usuario',
  '/not-allowed',
  '/analisis-territorial/base',
  '/analisis-territorial/sinergias',
  '/transacciones'
];

const RouteWithLayout = props => {
  let history = useHistory();

  const [ready, setReady] = useState(false);
  const [permited, setPermited] = useState(null);
  const { layout: Layout, component: Component, store, ...rest } = props;

  const { message, visible, maxTime } = storeBackdropLoading.backdropLoading;

  useEffect(() => {
    setReady(_store.loguedUser.firstate);
  }, [_store.loguedUser.firstate]);

  useEffect(() => {
    if (ready) {
      if (ALLOWED.find(ruta => props.path.includes(ruta))) {
        setPermited(true);
        return;
      }
      try {
        if (
          _store.loguedUser.permisos.find(
            ruta =>
              ruta.url === props.path ||
              (ruta.url !== '/' && props.path.indexOf(ruta.url) === 0)
          )
        ) {
          setPermited(true);
        } else {
          setPermited(false);
        }
      } catch (error) {
        setPermited(false);
      }
    }
  }, [ready, rest.computedMatch.url]);

  if (!ready || permited === null) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={matchProps => {
        if (permited) {
          return (
            <>
              <Layout>
                <Component {...matchProps} store={store} />
              </Layout>
              <AvisoAUsuario />

              {visible ? (
                <BackdropLoading timeout={maxTime} timeOutMessage={message} />
              ) : null}
            </>
          );
        } else {
          if (ready && matchProps.match.path != '/') {
            history.push('/not-allowed');
          }
        }
      }}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  store: PropTypes.any,
  path: PropTypes.string
};

export default RouteWithLayout;
