import { useCallback, useEffect, useState } from 'react';
import serviceCommons from 'services/commons';
import serviceResiduos from 'services/residuos';
import serviceEmpresa from 'services/empresas';

import { DICTONARY } from 'const/Dictonary';
import store from 'states/store';
import storeMessages from 'states/alertMessages/store';

/**
 * Servicio Nombre LER (ObtieneListaLer)
 * @returns
 **/

export const ObtieneListaLer = () => {
  const [listaLer, setListaLer] = useState([]);
  const [cargandoListaLer, setCargandoListaLer] = useState(true);
  const [listaLerNivelUno, setListaLerNivelUno] = useState([]);
  // Edición
  const [listaLerNivelUnoEdit, setListaLerNivelUnoEdit] = useState([]);
  const [listaLerNivelDosEdit, setListaLerNivelDosEdit] = useState([]);
  const [listaLerNivelTresEdit, setListaLerNivelTresEdit] = useState([]);

  const handleListaLer = useCallback(async () => {
    const params = {
      totalPagina: DICTONARY.PAGINACION.ALL_DATA,
      ordenarPor: 'nombreLER'
    };
    setCargandoListaLer(true);
    try {
      const response = await serviceCommons.nombresLER(params);

      const { content, empty } = response.data;
      if (response.status === 200 && !empty) {
        setListaLer(content);
        setListaLerNivelUno(content.filter(rsd => rsd.nivel === 1));
        // Solo edición
        setListaLerNivelUnoEdit(content.filter(rsd => rsd.nivel === 1));
        setListaLerNivelDosEdit(content.filter(rsd => rsd.nivel === 2));
        setListaLerNivelTresEdit(content.filter(rsd => rsd.nivel === 3));
      } else {
        setListaLer([]);
      }
    } catch (error) {
      setListaLer([]);
      console.log(error);
    } finally {
      setCargandoListaLer(false);
    }
  }, []);

  useEffect(() => {
    handleListaLer();
  }, [handleListaLer]);

  return {
    listaLer,
    cargandoListaLer,
    handleListaLer,
    listaLerNivelUno,
    listaLerNivelUnoEdit,
    listaLerNivelDosEdit,
    listaLerNivelTresEdit
  };
};

/**
 * Carga Info Residuo a Editar (ObtieneInfoResiduo)
 * @param {string} codigoResiduo
 * @returns
 **/
export const ObtieneInfoResiduo = codigoResiduo => {
  const [cargando, setCargando] = useState(true);
  // Lista de residuo seleccionado
  const [listaResiduo, setListaResiduo] = useState([]);
  const [error, setError] = useState(null);

  const cargaListaResiduo = useCallback(async () => {
    try {
      setCargando(true);
      const response = await serviceResiduos.obtenerResiduo(codigoResiduo);
      if (response.data) {
        const resultTemp = response.data;
        setListaResiduo(resultTemp);
      } else {
        setListaResiduo([]);
        setError(new Error('Residuo no encontrado'));
      }
    } catch (error) {
      setListaResiduo([]);
      console.log('Error al cargar residuo:', error);
      setError(new Error('Error al cargar residuo.'));
    } finally {
      setCargando(false);
    }
  }, [codigoResiduo]);

  useEffect(() => {
    // CARGAR INFORMACION DEL RESIDUO
    if (store.loguedUser.logeduser && codigoResiduo) {
      cargaListaResiduo();
    }
  }, [cargaListaResiduo, codigoResiduo]);

  return {
    cargando,
    listaResiduo,
    error,
    cargaListaResiduo
  };
};

/**
 *  Servicio Tipo Residuos (ObtieneListaTipoResiduos)
 */
export const ObtieneListaTipoResiduos = () => {
  const [listaTipoResiduos, setListaTipoResiduos] = useState([]);
  const [cargandoListaTipoResiduos, setCargandoListaTipoResiduos] =
    useState(true);
  const [listaTipoResiduosNivelUno, setTipoResiduosNivelUno] = useState([]);
  // Edición
  const [listaTipoResiduoNivelUnoEdit, setTipoResiduoNivelUnoEdit] = useState(
    []
  );
  const [listaTipoResiduoNivelDosEdit, setTipoResiduoNivelDosEdit] = useState(
    []
  );

  const handleTipoResiuduos = useCallback(async () => {
    const params = {
      totalPagina: DICTONARY.PAGINACION.ALL_DATA
    };
    setCargandoListaTipoResiduos(true);
    try {
      const response = await serviceCommons.tipoRSD(params);
      const { content, empty } = response.data;
      if (response.status === 200 && !empty) {
        setListaTipoResiduos(content);
        setTipoResiduosNivelUno(content.filter(rsd => rsd.nivel === 1));
        // Solo edición
        setTipoResiduoNivelUnoEdit(content.filter(rsd => rsd.nivel === 1));
        setTipoResiduoNivelDosEdit(content.filter(rsd => rsd.nivel === 2));
      } else {
        setListaTipoResiduos([]);
      }
    } catch (error) {
      setListaTipoResiduos([]);
      console.log(error);
    } finally {
      setCargandoListaTipoResiduos(false);
    }
  }, []);

  useEffect(() => {
    handleTipoResiuduos();
  }, [handleTipoResiuduos]);

  return {
    listaTipoResiduos,
    cargandoListaTipoResiduos,
    handleTipoResiuduos,
    listaTipoResiduosNivelUno,
    listaTipoResiduoNivelUnoEdit,
    listaTipoResiduoNivelDosEdit
  };
};

/**
 * Servicio Tipo Recursos (ObtieneListaTipoRecursos)
 * @returns
 */
export const ObtieneListaRecursos = () => {
  const [listaRecursos, setListaRecursos] = useState([]);
  const [cargandoListaRecursos, setCargandoListaRecursos] = useState(true);

  const handleRecursos = useCallback(async () => {
    const params = {
      totalPagina: DICTONARY.PAGINACION.ALL_DATA
    };
    const conInterceptor = false;
    setCargandoListaRecursos(true);
    try {
      const response = await serviceCommons.recursos(params, conInterceptor);
      const { content, empty } = response.data;
      if (response.status === 200 && !empty) {
        setListaRecursos(content);
      } else {
        setListaRecursos([]);
      }
    } catch (error) {
      setListaRecursos([]);
      console.log(error);
    } finally {
      setCargandoListaRecursos(false);
    }
  }, []);

  useEffect(() => {
    handleRecursos();
  }, [handleRecursos]);

  return {
    listaRecursos,
    cargandoListaRecursos,
    handleRecursos
  };
};

/**
 * Servicio Tipo Recursos por codigo (ObtieneTipoRecursosPorCodigosTipoRecursos)
 * @param {string} codigosTipoResiduo
 * @returns
 */
export const ObtieneTipoRecursosPorCodigosTipoRecursos = codigosTipoResiduo => {
  const [listaTipoRecursos, setListaTipoRecursos] = useState([]);
  const [cargandoListaTipoRecursos, setCargandoListaTipoRecursos] =
    useState(true);

  const handleTipoRecursos = useCallback(async () => {
    if (!codigosTipoResiduo) {
      setListaTipoRecursos([]);
      setCargandoListaTipoRecursos(false);
      return;
    }
    const params = {
      totalPagina: DICTONARY.PAGINACION.ALL_DATA,
      codigosTipoResiduo: codigosTipoResiduo
    };
    const conInterceptor = false;

    setCargandoListaTipoRecursos(true);
    try {
      const response = await serviceCommons.recursos(params, conInterceptor);
      const { data } = response;

      const { content, empty } = data;

      if (response.status === 200 && !empty) {
        if (Array.isArray(content)) {
          setListaTipoRecursos(content);
        } else {
          setListaTipoRecursos([]);
        }
      } else {
        setListaTipoRecursos([]);
        return;
      }
    } catch (error) {
      console.log(error);
      setListaTipoRecursos([]);
    } finally {
      setCargandoListaTipoRecursos(false);
    }
  }, [codigosTipoResiduo]);

  useEffect(() => {
    handleTipoRecursos();
  }, [handleTipoRecursos]);

  return {
    listaTipoRecursos,
    cargandoListaTipoRecursos,
    handleTipoRecursos
  };
};

/**
 * Servicio Tipo Recursos por codigo (ObtieneTipoRecursosPorCodigosTipoRecursos)
 * @param {string} codigoRecursoReemplazo
 * @param {Function} setRecursoReemplazo
 * @param {Function} setCargandoRecursoReemplazo
 */
export const ObtieneRecursoReemplazoFiltrado = async ({
  codigoRecursoReemplazo,
  setRecursoReemplazo,
  setCargandoRecursoReemplazo
}) => {
  try {
    const response = await serviceCommons.obtieneRecursoReemplazo();
    if (response.status === 200) {
      const { data } = response;
      const { content } = data;

      const recursoReemplazoFiltrado = content.filter(
        r =>
          r.codigoRecursoReemplazo === codigoRecursoReemplazo && r.nivel === 1
      );
      setRecursoReemplazo(recursoReemplazoFiltrado[0]);
    }
  } catch (error) {
    setRecursoReemplazo([]);
    storeMessages.message.setMessage({
      mensaje:
        'Ha ocurrido un error al obtener recurso de reemplazo, si el problema persiste comunícate con el administrador del sitio',
      tipo: 'error'
    });
    return error;
  } finally {
    setCargandoRecursoReemplazo(false);
  }
};

/**
 * Servicio Sucursales (ObtieneListaSucursales)
 * @param {string} codigoEmpresaState
 * @returns
 */
export const ObtieneListaSucursales = codigoEmpresaState => {
  const [listaSucursales, setListaSucursales] = useState([]);
  const [cargandoListaSucursales, setCargandoListaSucursales] = useState(true);

  const handleListaSucursales = useCallback(async () => {
    if (!codigoEmpresaState) return;
    const params = {
      codigoEmpresa: codigoEmpresaState,
      totalPagina: DICTONARY.PAGINACION.ALL_DATA
    };
    const conInterceptor = false;
    setCargandoListaSucursales(true);
    try {
      const response = await serviceEmpresa.obtieneSucursales(
        params,
        conInterceptor
      );
      const { content, empty } = response.data;
      if (response.status === 200 && !empty) {
        setListaSucursales(content);
      } else {
        setListaSucursales([]);
      }
    } catch (error) {
      setListaSucursales([]);
      console.log(error);
    } finally {
      setCargandoListaSucursales(false);
    }
  }, [codigoEmpresaState]);

  useEffect(() => {
    handleListaSucursales();
  }, [handleListaSucursales]);

  return {
    listaSucursales,
    cargandoListaSucursales,
    handleListaSucursales
  };
};

/**
 * Servicio Tipo Disposicion (ObtieneListaTipoDisposicion)
 * @param {string} codigoTipoResiduo
 * @returns
 */
export const ObtieneListaTipoDisposicion = codigoTipoResiduo => {
  const [listaTipoDisposicion, setListaTipoDisposicion] = useState([]);
  const [cargandoListaTipoDisposicion, setCargandoListaTipoDisposicion] =
    useState(true);

  const handleListaTipoDisposicion = useCallback(async () => {
    if (!codigoTipoResiduo) return;
    // const params = {
    //   codigosTipoResiduo: codigoTipoResiduo,
    //   totalPagina: DICTONARY.PAGINACION.ALL_DATA
    // };
    const conInterceptor = false;
    setCargandoListaTipoDisposicion(true);
    try {
      const response = await serviceCommons.tipodisposicion(
        codigoTipoResiduo,
        conInterceptor
      );
      const { content, empty } = response.data;
      if (response.status === 200 && !empty) {
        setListaTipoDisposicion(content);
      } else {
        setListaTipoDisposicion([]);
      }
    } catch (error) {
      setListaTipoDisposicion([]);
      console.log(error);
    } finally {
      setCargandoListaTipoDisposicion(false);
    }
  }, [codigoTipoResiduo]);

  useEffect(() => {
    handleListaTipoDisposicion();
  }, [handleListaTipoDisposicion]);

  return {
    listaTipoDisposicion,
    cargandoListaTipoDisposicion,
    handleListaTipoDisposicion
  };
};

/**
 * Servicio Tipo Recurso Reemplazo (ObtieneListaTipoRecursoReemplazo)
 * @returns
 */
export const ObtieneListaTipoRecursoReemplazo = () => {
  const [listaTipoRecursoReemplazo, setListaTipoRecursoReemplazo] = useState(
    []
  );
  const [listaTipoRecursoReemplazo2, setListaTipoRecursoReemplazo2] = useState(
    []
  );

  const [
    cargandoListaTipoRecursoReemplazo,
    setCargandoListaTipoRecursoReemplazo
  ] = useState(true);

  const handleListaTipoRecursoReemplazo = useCallback(async () => {
    const params = {
      totalPagina: DICTONARY.PAGINACION.ALL_DATA
    };
    const conInterceptor = false;
    setCargandoListaTipoRecursoReemplazo(true);
    try {
      const response = await serviceCommons.obtieneRecursoReemplazo(
        params,
        conInterceptor
      );
      const { content, empty } = response.data;
      if (response.status === 200 && !empty) {
        setListaTipoRecursoReemplazo(content.filter(r => r.nivel === 1));
        setListaTipoRecursoReemplazo2(content.filter(r => r.nivel !== 1));
      } else {
        setListaTipoRecursoReemplazo([]);
      }
    } catch (error) {
      setListaTipoRecursoReemplazo([]);
      console.log(error);
    } finally {
      setCargandoListaTipoRecursoReemplazo(false);
    }
  }, []);

  useEffect(() => {
    handleListaTipoRecursoReemplazo();
  }, [handleListaTipoRecursoReemplazo]);

  return {
    listaTipoRecursoReemplazo,
    listaTipoRecursoReemplazo2,
    cargandoListaTipoRecursoReemplazo,
    handleListaTipoRecursoReemplazo
  };
};

/**
 * Valida Si Existe Recurso (ValidaSiExisteRecurso)
 * @param {Array} listaRecursos
 * @param {string} nombre
 * @returns
 */
export const ValidaSiExisteRecurso = ({ listaRecursos, nombre }) => {
  // Verifica si el nombre está presente
  if (!nombre) return false;

  // Convierte todo a minúsculas para hacer la comparación insensible a mayúsculas
  const nombreLowerCase = nombre.trim().toLowerCase();

  // Utiliza find en lugar de filter, ya que solo necesitas la primera coincidencia
  const existeNombreRecurso = listaRecursos.find(
    r => r.nombreRecurso.trim().toLowerCase() === nombreLowerCase
  );

  // Imprime el resultado en la consola
  // console.log(existeNombreRecurso);

  // Retorna true si existe el nombre y false en caso contrario
  return Boolean(existeNombreRecurso);
};

/**
 * Obtiene Codigo Recurso Existe (ObtieneCodigoRecursoExiste)
 * @param {Array} listaRecursos
 * @param {string} nombre
 * @returns
 */
export const ObtieneCodigoRecursoExiste = ({ listaRecursos, nombre }) => {
  // Verifica si el nombre está presente
  if (!nombre) return false;

  // Convierte todo a minúsculas para hacer la comparación insensible a mayúsculas
  const nombreLowerCase = nombre.trim().toLowerCase();

  // Utiliza find en lugar de filter, ya que solo necesitas la primera coincidencia
  const existeNombreRecurso = listaRecursos.find(
    r => r.nombreRecurso.trim().toLowerCase() === nombreLowerCase
  );

  // Imprime el resultado en la consola
  // console.log(existeNombreRecurso);

  // Retorna true si existe el nombre y false en caso contrario
  return {
    codigoRecurso: existeNombreRecurso.codigoRecurso,
    nombreRecurso: existeNombreRecurso.nombreRecurso
  };
};

/**
 * Servicio Lista de Transporte (ObtieneListaTransporte)
 * @returns
 */
export const ObtieneListaTipoTransporte = () => {
  const [listaTipoTransporte, setListaTipoTransporte] = useState([]);
  const [isLoadingTipoTransporte, setIsLoadingTipoTransporte] = useState(true);

  const handleListaTipoTransporte = useCallback(async () => {
    const params = {
      totalPagina: DICTONARY.PAGINACION.ALL_DATA
    };
    const conInterceptor = false;
    try {
      const response = await serviceEmpresa.obtenerTipoTransaporte(
        params,
        conInterceptor
      );
      const { content, empty } = response.data;

      if (response.status === 200 && !empty) {
        setListaTipoTransporte(content);
      } else {
        setListaTipoTransporte([]);
      }
    } catch (error) {
      setListaTipoTransporte([]);
      console.log(error);
    } finally {
      setIsLoadingTipoTransporte(false);
    }
  }, []);

  useEffect(() => {
    handleListaTipoTransporte();
  }, [handleListaTipoTransporte]);

  return {
    listaTipoTransporte,
    isLoadingTipoTransporte,
    handleListaTipoTransporte
  };
};
export const ObtieneListaTipoTratamiento = () => {
  const [listaTipoTratamiento, setListaTipoTratamiento] = useState([]);
  const [isLoadingTipoTratamiento, setIsLoadingTipoTratamiento] =
    useState(true);

  const handleListaTipoTratamiento = useCallback(async customParams => {
    setIsLoadingTipoTratamiento(true);
    const params = {
      totalPagina: DICTONARY.PAGINACION.ALL_DATA
    };
    const conInterceptor = false;
    try {
      const response = await serviceResiduos.obtenerListaTipoTratamiento(
        customParams ? customParams : params,
        conInterceptor
      );
      const { content, empty } = response.data;

      if (response.status === 200 && !empty) {
        // evitar duplicados  y ordenar por nombre
        const contentFiltrado = content.filter(
          (v, i, a) =>
            a.findIndex(
              t => t.nombreTipoTratamiento === v.nombreTipoTratamiento
            ) === i
        );
        setListaTipoTratamiento(contentFiltrado);
      } else {
        setListaTipoTratamiento([]);
      }
    } catch (error) {
      setListaTipoTratamiento([]);
      console.log(error);
    } finally {
      setIsLoadingTipoTratamiento(false);
    }
  }, []);

  useEffect(() => {
    handleListaTipoTratamiento();
  }, [handleListaTipoTratamiento]);

  return {
    listaTipoTratamiento,
    isLoadingTipoTratamiento,
    handleListaTipoTratamiento
  };
};

export const ObtieneLevantamiento = () => {
  const [isLoadingLevantamiento, setIsLoadingLevantamiento] = useState(true);
  const [levantamiento, setLevantamiento] = useState([]);

  const handleLevantamiento = useCallback(async () => {
    const params = {
      totalPagina: DICTONARY.PAGINACION.ALL_DATA
    };
    const conInterceptor = false;
    try {
      const response = await serviceResiduos.obtieneLevantamientoDatos(
        params,
        conInterceptor
      );
      const { content, empty } = response.data;
      if (response.status === 200 && !empty) {
        setLevantamiento(content);
      } else {
        setLevantamiento([]);
      }
    } catch (err) {
      console.log('err :>> ', err);
      setLevantamiento([]);
    } finally {
      setIsLoadingLevantamiento(false);
    }
  }, []);

  useEffect(() => {
    handleLevantamiento();
  }, [handleLevantamiento]);

  return {
    levantamiento,
    isLoadingLevantamiento,
    handleLevantamiento
  };
};
