// Importaciones de react
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// Importaciones de terceros
import storeMessages from 'states/alertMessages/store';
import { RUTAS } from 'const/Dictonary';
import { format } from 'date-fns';
// services
import residuosServices from 'services/residuos';
import { diccionarioVista } from '../utils/utils';
// otros
import moment from 'moment';
import { formatoArchivos } from './useUtilsFormik';

export const useSubmitResiduosDeclarados = (codigoResiduoDeclarado, values) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  const [residuoDeclarado, setResiduoDeclarado] = useState({
    // Seccion 1 - Residuo declarado
    nombreLER: null, // codigoLER registrado en la base de datos (Integer) y nombreLER (String)
    nombreSubLER: null, // codigoLER registrado en la base de datos (Integer) y nombreLER (String)
    nombreSubSubLER: null, // codigoLER registrado en la base de datos (Integer) y nombreLER (String)
    tipoResiduo: null, // codigoTipoResiduo registrado en la base de datos (Integer)
    subTipoResiduo: null, // codigoSubTipoResiduo registrado en la base de datos (Integer)
    sucursal: null, // codigoSucursal registrado en la base de datos (Integer)
    fechaTransaccion: '', // Date (yyyy-MM-dd)
    cantidad: null, // Double
    // Seccion 2 - Transporte
    // Seccion 3 - Receptor
    rutEmpresaReceptor: '',
    tipoTratamiento: null, // codigoTipoTratamiento registrado en la base de datos (Integer)
    archivos: [],

    codigoEmpresaTransporte: '',
    codigoEmpresaReceptor: ''
  });
  const [isNuevoResiduoDeclarado, setIsNuevoResiduoDeclarado] = useState(false);

  const [cargaData, setcargaData] = useState(false);

  /**
   * @name cargarResiduoDeclarado
   * Función para cargar el recurso adquirido que se está desplegando, en el caso de que el código no viene se asume que es un nuevo recurso adquirido.
   * @throws Error
   * @return estructura servicio nueva sucursal
   */

  const cargarResiduoDeclarado = async () => {
    if (codigoResiduoDeclarado !== 'nuevo') {
      setIsLoading(true);
      try {
        const responseResiduoDeclarado =
          await residuosServices.obtieneResiduoDeclarado(
            codigoResiduoDeclarado
          );
        if (responseResiduoDeclarado.status === 200) {
          const residuoDeclarado = responseResiduoDeclarado.data;

          const resultTemp = residuoDeclarado.archivos.map(archivo => {
            const resp = {
              base64: `data:${archivo.tipoArchivo};base64,${archivo.base64}`,
              nombreArchivo: `${archivo.nombreArchivo}`,
              extension: `${archivo.tipoArchivo}`,
              codigoArchivo: `${archivo.codigoArchivoResiduoDeclarado}`
            };
            return resp;
          });

          const fechaFormateada = moment(
            residuoDeclarado.fechaTransaccion
          ).local();

          setResiduoDeclarado({
            // Mandatory
            cantidad: residuoDeclarado.cantidad,
            nombreSubSubLER: residuoDeclarado.nombreLER,
            subTipoResiduo: residuoDeclarado.tipoResiduo,
            sucursal: residuoDeclarado.sucursal,
            rutEmpresaReceptor: residuoDeclarado.empresaReceptor.rut,
            fechaTransaccion: fechaFormateada.toISOString(),
            tipoTratamiento: residuoDeclarado.empresaReceptor.tipoTratamiento,
            codigoEmpresaTransporte:
              residuoDeclarado.empresaTransporte.codigoEmpresaTransporte || '',
            codigoEmpresaReceptor:
              residuoDeclarado.empresaReceptor.codigoEmpresaReceptor || '',
            // Optional
            archivos: resultTemp.length > 0 ? resultTemp : [],
            formatoEntrega: residuoDeclarado.formatoEntrega,
            levantamientoDatos: residuoDeclarado.levantamientoDatos,
            rutEmpresaTransporte: residuoDeclarado.empresaTransporte?.rut,
            tipoTransporte: residuoDeclarado.empresaTransporte?.tipoTransporte,
            patente: residuoDeclarado.empresaTransporte?.patente,
            region: residuoDeclarado.empresaReceptor.direccion?.comuna?.region,
            comuna: residuoDeclarado.empresaReceptor.direccion?.comuna,
            direccion: residuoDeclarado.empresaReceptor.direccion?.nombreVia,
            nombreVia: residuoDeclarado.empresaReceptor.direccion?.nombreVia,
            longitudDireccion:
              residuoDeclarado.empresaReceptor?.direccion?.longitudDireccion,
            latitudDireccion:
              residuoDeclarado.empresaReceptor?.direccion?.latitudDireccion,
            codigoSINADER: residuoDeclarado.empresaReceptor?.codigoSINADER,
            numeroGuiaDespacho:
              residuoDeclarado.empresaReceptor?.numeroGuiaDespacho,
            archivosEliminados: [],
            codigoDireccion:
              residuoDeclarado.empresaReceptor?.direccion?.codigoDireccion || ''
          });
        }
      } catch (error) {
        console.error('Error en cargarResiduoDeclarado', error);
      } finally {
        setIsLoading(false);
        setcargaData(true);
      }
    } else {
      setIsLoading(false);
      setIsNuevoResiduoDeclarado(true);
      setcargaData(true);
    }
  };

  /**
   * @name handleSubmit
   * Función para enviar los datos del formulario al servicio
   * @param valores estructura formik para nuevo residuo declarado
   * @return estructura servicio nuevo residuo declarado
   * @throws Error
   */

  const handleSubmit = async (valores, tipoFormulario) => {
    try {
      if (tipoFormulario === 'ingresar' || tipoFormulario === 'duplicar') {
        await submitNuevoResiduoDeclarado(valores, tipoFormulario);
      } else if (tipoFormulario === 'editar') {
        await submitActualizarResiduoDeclarado(valores);
      }
    } catch (error) {
      console.log('Error en handleSubmit ', error);
      storeMessages.message.setMessage({
        mensaje: `No se ha podido realizar la operación de ${diccionarioVista.nombrePrincipalSingular}`,
        tipo: 'error'
      });
      throw new Error('Error en handleSubmit ', error);
    }
  };

  useEffect(() => {
    // console.log(recursoAdquirido);
  }, [residuoDeclarado, cargaData, isLoading]);

  /**
   * @name submitNuevoResiduoDeclarado
   * Función para enviar los datos del formulario al servicio
   * @param valores estructura formik
   * @return estructura servicio nuevo residuo declarado
   * @throws Error
   *
   * */

  const submitNuevoResiduoDeclarado = async (valores, tipoFormulario) => {
    const generar = tipoFormulario === 'ingresar' ? 'crear' : 'duplicar';
    const generarado = tipoFormulario === 'ingresar' ? 'creado' : 'duplicado';
    try {
      const residuosDeclaradosData =
        estructuraServicioNuevoResiduoDeclarado(valores);
      // console.log('residuoDeclarado ::>> ', residuosDeclaradosData);
      const response = await residuosServices.crearResiduoDeclarado(
        residuosDeclaradosData
      );
      if (response.status === 200) {
        storeMessages.message.setMessage({
          mensaje: `Se ha ${generarado} el ${diccionarioVista.nombrePrincipalSingular} correctamente`,
          tipo: 'exito'
        });
        // history.push(RUTAS.);
      }
    } catch (error) {
      console.log(
        `Error en ${generar} un ${diccionarioVista.nombrePrincipalSingular} ${error}`
      );
      storeMessages.message.setMessage({
        mensaje: `No se ha podido ${generar} el ${diccionarioVista.nombrePrincipalSingular}`,
        tipo: 'error'
      });
      throw new Error('Error en submitNuevoResiduoDeclarado ', error);
    }
  };

  /**
   * @name submitActualizarResiduoDeclarado
   * Función para enviar los datos del formulario al servicio
   * @param valores estructura formik para la edición de residuo declarado
   * @return estructura servicio residuo declarado
   * @throws Error
   *
   * */
  const submitActualizarResiduoDeclarado = async valores => {
    try {
      const residuosDeclaradosData =
        estructuraServicioActualizarResiduoDeclarado(valores);
      // transformar string a numero
      const codigoResiduoDeclaradoInt = parseInt(codigoResiduoDeclarado);
      const response = await residuosServices.actualizaResiduoDeclarado(
        codigoResiduoDeclaradoInt,
        residuosDeclaradosData
      );
      if (response.status === 200) {
        storeMessages.message.setMessage({
          mensaje: `Se han editado los datos del ${diccionarioVista.nombrePrincipalSingular} correctamente`,
          tipo: 'exito'
        });
        // history.push(RUTAS.RECURSOS_ADQUIRIDOS);
      }
    } catch (error) {
      console.log(
        `Error en la edición de un ${diccionarioVista.nombrePrincipalSingular} ${error}`
      );
      throw new Error('Error en submitNuevoRecuroAdquirido ', error);
    }
  };

  /**
   * @name estructuraServicioNuevoRecursoAdquirido
   * Función para estructurar los valores del formulario a la estructura requerida por el servicio
   * @param valores estructura formik
   * @return estructura servicio nuevo/duplicado residuo declarado
   */
  const estructuraServicioNuevoResiduoDeclarado = valores => {
    const { formattedFiles } = formatoArchivos(valores.archivos);

    try {
      // saber si el objeto valores correspondientes
      const hasDeliveryFormat = valores?.formatoEntrega;
      const hasDataCollection = valores?.levantamientoDatos;
      const hasTransportCompanyRut = valores?.rutEmpresaTransporte;
      const hasLicensePlate = valores?.patente;
      const hasTransportType = valores?.tipoTransporte;
      const hasSINADERCode = valores?.codigoSINADER;
      const hasDispatchGuideNumber = valores?.numeroGuiaDespacho;
      const hasCommune = valores?.comuna;
      const hasStreetDetails = valores?.nombreVia;
      const hasLatitudeAddress = valores?.latitudDireccion;
      const hasLongitudeAddress = valores?.longitudDireccion;

      const estructuraServicio = {
        cantidad: valores.cantidad, // Double
        fechaTransaccion: format(
          new Date(valores.fechaTransaccion),
          'yyyy-MM-dd'
        ), // Date (yyyy-MM-dd)
        formatoEntrega: hasDeliveryFormat
          ? {
              codigoFormatoEntrega: valores.formatoEntrega.codigoFormatoEntrega
            }
          : null,
        levantamientoDatos: hasDataCollection
          ? {
              codigoLevantamientoDatos:
                valores.levantamientoDatos.codigoLevantamientoDatos
            }
          : null,
        nombreLER: {
          codigoLER: valores.nombreSubSubLER.codigoLER // Integer (codigoLER registrado en la base de datos)
        },
        tipoResiduo: {
          codigoTipoResiduo: valores.subTipoResiduo.codigoTipoResiduo // Integer (codigoTipoResiduo registrado en la base de datos)
        },
        sucursal: {
          codigoSucursal: valores.sucursal.codigoSucursal // Integer (codigoSucursal registrado en la base de datos)
        },
        empresaTransporte: {
          rut: hasTransportCompanyRut ? valores.rutEmpresaTransporte : null, // String (max 12)
          patente: hasLicensePlate ? valores.patente : null, // String
          tipoTransporte: hasTransportType
            ? {
                codigoTipoTransporte:
                  valores.tipoTransporte.codigoTipoTransporte
              }
            : null
        },

        empresaReceptor: {
          rut: valores.rutEmpresaReceptor, // String (max 12)
          codigoSINADER: hasSINADERCode ? valores.codigoSINADER : null, // Integer (4 a 7)
          numeroGuiaDespacho: hasDispatchGuideNumber
            ? valores.numeroGuiaDespacho
            : null, // Integer (max 10)
          tipoTratamiento: {
            codigoTipoTratamiento: valores.tipoTratamiento.codigoTipoTratamiento // Integer (codigoTipoTratamiento registrado en la base de datos)
          }
        },
        archivos: formattedFiles || []
      };
      // Logica para la dirección
      estructuraServicio.empresaReceptor.direccion = hasCommune
        ? { comuna: { codigoComuna: valores.comuna.codigoComuna } }
        : null; // Integer (codigoComuna registrado en la base de datos)},
      estructuraServicio.empresaReceptor.direccion = hasStreetDetails
        ? {
            ...estructuraServicio.empresaReceptor.direccion,
            nombreVia: valores.nombreVia
          }
        : null; // Integer (codigoComuna registrado en la base de datos)},
      estructuraServicio.empresaReceptor.direccion = hasLatitudeAddress
        ? {
            ...estructuraServicio.empresaReceptor.direccion,
            latitudDireccion: valores.latitudDireccion
          }
        : null; // Integer (codigoComuna registrado en la base de datos)},
      estructuraServicio.empresaReceptor.direccion = hasLongitudeAddress
        ? {
            ...estructuraServicio.empresaReceptor.direccion,
            longitudDireccion: valores.longitudDireccion
          }
        : null; // Integer (codigoComuna registrado en la base de datos)},

      // console.log('estructuraServicio ingresar - duplicar : ', estructuraServicio);
      return estructuraServicio;
    } catch (error) {
      console.log('Error en obtenerEstructuraServicio ', error);
      throw new Error('Error en obtenerEstructuraServicio ', error);
    }
  };
  const estructuraServicioActualizarResiduoDeclarado = valores => {
    const { formattedFiles } = formatoArchivos(valores.archivos);

    try {
      // saber si el objeto valores correspondientes
      const hasDeliveryFormat = valores?.formatoEntrega;
      const hasDataCollection = valores?.levantamientoDatos;
      const hasTransportCompanyRut = valores?.rutEmpresaTransporte;
      const hasLicensePlate = valores?.patente;
      const hasTransportType = valores?.tipoTransporte;
      const hasSINADERCode = valores?.codigoSINADER;
      const hasDispatchGuideNumber = valores?.numeroGuiaDespacho;
      const hasCommune = valores?.comuna;
      const hasStreetDetails = valores?.nombreVia;
      const hasLatitudeAddress = valores?.latitudDireccion;
      const hasLongitudeAddress = valores?.longitudDireccion;

      const estructuraServicio = {
        cantidad: valores.cantidad, // Double
        fechaTransaccion: format(
          new Date(valores.fechaTransaccion),
          'yyyy-MM-dd'
        ), // Date (yyyy-MM-dd)
        formatoEntrega: hasDeliveryFormat
          ? {
              codigoFormatoEntrega: valores.formatoEntrega.codigoFormatoEntrega
            }
          : null,
        levantamientoDatos: hasDataCollection
          ? {
              codigoLevantamientoDatos:
                valores.levantamientoDatos.codigoLevantamientoDatos
            }
          : null,
        nombreLER: {
          codigoLER: valores.nombreSubSubLER.codigoLER // Integer (codigoLER registrado en la base de datos)
        },
        tipoResiduo: {
          codigoTipoResiduo: valores.subTipoResiduo.codigoTipoResiduo // Integer (codigoTipoResiduo registrado en la base de datos)
        },
        sucursal: {
          codigoSucursal: valores.sucursal.codigoSucursal // Integer (codigoSucursal registrado en la base de datos)
        },
        empresaTransporte: {
          rut: hasTransportCompanyRut ? valores.rutEmpresaTransporte : null, // String (max 12)
          patente: hasLicensePlate ? valores.patente : null, // String
          tipoTransporte: hasTransportType
            ? {
                codigoTipoTransporte:
                  valores.tipoTransporte.codigoTipoTransporte
              }
            : null,
          codigoEmpresaTransporte: valores.codigoEmpresaTransporte
        },

        empresaReceptor: {
          rut: valores.rutEmpresaReceptor, // String (max 12)
          codigoSINADER: hasSINADERCode ? valores.codigoSINADER : null, // Integer (4 a 7)
          numeroGuiaDespacho: hasDispatchGuideNumber
            ? valores.numeroGuiaDespacho
            : null, // Integer (max 10)
          tipoTratamiento: {
            codigoTipoTratamiento: valores.tipoTratamiento.codigoTipoTratamiento // Integer (codigoTipoTratamiento registrado en la base de datos)
          },
          codigoEmpresaReceptor: valores.codigoEmpresaReceptor
        },
        archivos: formattedFiles || []
      };
      // Logica para la dirección
      estructuraServicio.empresaReceptor.direccion = hasCommune
        ? { comuna: { codigoComuna: valores.comuna.codigoComuna } }
        : null; // Integer (codigoComuna registrado en la base de datos)},
      estructuraServicio.empresaReceptor.direccion = hasStreetDetails
        ? {
            ...estructuraServicio.empresaReceptor.direccion,
            nombreVia: valores.nombreVia
          }
        : null; // Integer (codigoComuna registrado en la base de datos)},
      estructuraServicio.empresaReceptor.direccion = hasLatitudeAddress
        ? {
            ...estructuraServicio.empresaReceptor.direccion,
            latitudDireccion: valores.latitudDireccion
          }
        : null; // Integer (codigoComuna registrado en la base de datos)},
      estructuraServicio.empresaReceptor.direccion = hasLongitudeAddress
        ? {
            ...estructuraServicio.empresaReceptor.direccion,
            longitudDireccion: valores.longitudDireccion
          }
        : null; // Integer (codigoComuna registrado en la base de datos)},

      estructuraServicio.empresaReceptor.direccion.codigoDireccion =
        valores.codigoDireccion;

      // console.log('estructuraServicio actualizar : ', estructuraServicio);
      return estructuraServicio;
    } catch (error) {
      console.log('Error en obtenerEstructuraServicio ', error);
      throw new Error('Error en obtenerEstructuraServicio ', error);
    }
  };

  /**
   * @name handleEliminarResiduoDeclarado
   * Función para eliminar un residuo declarados
   *
   * @param codAdquirido codigo del residuo declarados
   * @param cargaRecursosAdquiridos función para cargar el listado de residuos declarados
   * @return estructura servicio residuo declarados
   * @throws Error
   *
   * */
  const handleEliminarResiduoDeclarado = async (
    codAdquirido,
    cargaResiduosDeclarados
  ) => {
    const params = codAdquirido;

    try {
      const response = await residuosServices.eliminaResiduoDeclarado(params);
      if (response.status === 200) {
        storeMessages.message.setMessage({
          mensaje: `Se ha eliminado el ${diccionarioVista.nombrePrincipalSingular} correctamente`,
          tipo: 'exito'
        });
        cargaResiduosDeclarados();
        history.push(RUTAS.RECURSOS_ADQUIRIDOS);
      }
    } catch (error) {
      console.log(
        `Error en la eliminación de un ${diccionarioVista.nombrePrincipalSingular} ${error}`
      );
      storeMessages.message.setMessage({
        mensaje: `No se ha podido eliminar el ${diccionarioVista.nombrePrincipalSingular}`,
        tipo: 'error'
      });
      throw new Error('Error en handleResiduoDeclarado ', error);
    }
  };

  return {
    residuoDeclarado,
    isLoading,
    setIsLoading,
    cargarResiduoDeclarado,
    cargaData,
    handleSubmit,
    handleEliminarResiduoDeclarado
  };
};
