// Services
import archivoService from 'services/archivos';
import empresasService from 'services/empresas';
import residuosService from 'services/residuos';
import commonsService from 'services/commons';
import analisisTerritorialService from 'services/analisisTerritorial';

import { useDescargaArchivos } from './useDescargaArchivos';
// storeMessages
import storeMessages from 'states/alertMessages/store';
import { DICTONARY } from 'const/Dictonary';

const { CARGA_MASIVA } = DICTONARY;
const {
  NOMBRE_ARCHIVO_FORMATO_SII,
  NOMBRE_ARCHIVO_FORMATO_RECT,
  NOMBRE_ARCHIVO_FORMATO_PS
} = CARGA_MASIVA;

// SERVICIOS DE DESCARGA DE ARCHIVOS

export const descargaBaseDatosSII = () => {
  // console.log('descargaBaseDatosSII :>> ');
  try {
    archivoService.getExportarCargaMasivaSii().then(response => {
      if (response.status === 200) {
        const { data } = response;
        const { handleDownload: habldleDownloadSII } = useDescargaArchivos({
          contenidoDocumento: data,
          nombreArchivo: NOMBRE_ARCHIVO_FORMATO_SII
        });

        habldleDownloadSII();

        storeMessages.message.setMessage({
          mensaje: `Se ha descargado el archivo ${NOMBRE_ARCHIVO_FORMATO_SII} correctamente, por favor verifique en la carpeta de descargas de su navegador.`,
          tipo: 'success'
        });
      } else {
        storeMessages.message.setMessage({
          mensaje: `Ha ocurrido un problema con la descarga del archivo ${NOMBRE_ARCHIVO_FORMATO_SII}, , Si el problema persiste comunícate con el administrador del sitio`,
          tipo: 'warning'
        });
      }
    });
  } catch (error) {
    console.log(error);
    storeMessages.message.setMessage({
      mensaje: `Ha ocurrido un error con la descarga del archivo ${NOMBRE_ARCHIVO_FORMATO_SII}, , Si el problema persiste comunícate con el administrador del sitio`,
      tipo: 'error'
    });
  }
};

export const descargaBaseDatosRECT = () => {
  // console.log('descargaBaseDatosRect :>> ');
  try {
    archivoService.getExportarCargaMasivaRect().then(response => {
      if (response.status === 200) {
        const { data } = response;
        const { handleDownload: habldleDownloadRect } = useDescargaArchivos({
          contenidoDocumento: data,
          nombreArchivo: NOMBRE_ARCHIVO_FORMATO_RECT
        });

        habldleDownloadRect();

        storeMessages.message.setMessage({
          mensaje: `Se ha descargado el archivo ${NOMBRE_ARCHIVO_FORMATO_RECT} correctamente, por favor verifique en la carpeta de descargas de su navegador.`,
          tipo: 'success'
        });
      } else {
        storeMessages.message.setMessage({
          mensaje: `Ha ocurrido un problema con la descarga del archivo ${NOMBRE_ARCHIVO_FORMATO_RECT}, , Si el problema persiste comunícate con el administrador del sitio`,
          tipo: 'warning'
        });
      }
    });
  } catch (error) {
    console.log(error);
    storeMessages.message.setMessage({
      mensaje: `Ha ocurrido un error con la descarga del archivo ${NOMBRE_ARCHIVO_FORMATO_RECT}, , Si el problema persiste comunícate con el administrador del sitio`,
      tipo: 'error'
    });
  }
};

export const descargaBaseDatosPs = () => {
  // console.log('descargaBaseDatosPS :>> ');
  try {
    archivoService.getExportarCargaMasivaPs().then(response => {
      if (response.status === 200) {
        const { data } = response;
        const { handleDownload: habldleDownloadPs } = useDescargaArchivos({
          contenidoDocumento: data,
          nombreArchivo: NOMBRE_ARCHIVO_FORMATO_PS
        });
        habldleDownloadPs();
        storeMessages.message.setMessage({
          mensaje: `Se ha descargado el archivo ${NOMBRE_ARCHIVO_FORMATO_PS} correctamente, por favor verifique en la carpeta de descargas de su navegador.`,
          tipo: 'success'
        });
      } else {
        storeMessages.message.setMessage({
          mensaje: `Ha ocurrido un problema con la descarga del archivo ${NOMBRE_ARCHIVO_FORMATO_PS}, , Si el problema persiste comunícate con el administrador del sitio`,
          tipo: 'warning'
        });
      }
    });
  } catch (error) {
    console.log(error);
    storeMessages.message.setMessage({
      mensaje: `Ha ocurrido un error con la descarga del archivo ${NOMBRE_ARCHIVO_FORMATO_PS}, , Si el problema persiste comunícate con el administrador del sitio`,
      tipo: 'error'
    });
  }
};

// SERVICIOS DE SUBIDA DE ARCHIVOS

export const subirArchivoSII = (baseDatosSII, setLoadingBDSII) => {

  const formData = new FormData();

  if (baseDatosSII && baseDatosSII[0] && baseDatosSII[0].File) {
    formData.append('file', baseDatosSII[0].File);


    try {
      archivoService
        .postCargaMasivaSii(formData)
        .then(response => {
          storeMessages.message.setMessage({
            mensaje: `Se ha subido el archivo correctamente`,
            tipo: 'success'
          });
          setLoadingBDSII(false);
        })
        .catch(error => {
          console.log('error :>> ', error);
          storeMessages.message.setMessage({
            mensaje: `Ha ocurrido un problema con la descarga del archivo  , Si el problema persiste comunícate con el administrador del sitio`,
            tipo: 'warning'
          });
          setLoadingBDSII(false);
        });
      setTimeout(() => {
        setLoadingBDSII(false);
      }, 4000);
    } catch (error) {
      console.log('error :>> ', error);
      storeMessages.message.setMessage({
        mensaje: `Ha ocurrido un problema con la descarga del archivo  , Si el problema persiste comunícate con el administrador del sitio`,
        tipo: 'error'
      });
      setLoadingBDSII(false);
    }
  }


};

export const subirArchivoRECT = (residuisRect, anyoDeclaracion, setLoadingBDRECT) => {
  const formData = new FormData();
  if (residuisRect && residuisRect[0] && residuisRect[0].File && anyoDeclaracion) {
    formData.append('file', residuisRect[0].File);
    formData.append('anyoDeclaracion', anyoDeclaracion.getFullYear());
    try {
      archivoService
        .postCargaMasivaRect(formData)
        .then(response => {
          storeMessages.message.setMessage({
            mensaje: `Se ha subido el archivo correctamente`,
            tipo: 'success'
          });
          setLoadingBDRECT(false);
        })
        .catch(error => {
          console.log('error :>> ', error);
          storeMessages.message.setMessage({
            mensaje: `Ha ocurrido un problema con la descarga del archivo  , Si el problema persiste comunícate con el administrador del sitio`,
            tipo: 'warning'
          });
          setLoadingBDRECT(false);
        });
    } catch (error) {
      console.log('error :>> ', error);
      storeMessages.message.setMessage({
        mensaje: `Ha ocurrido un problema con la descarga del archivo  , Si el problema persiste comunícate con el administrador del sitio`,
        tipo: 'error'
      });
      setLoadingBDRECT(false);
    }
  }
};

export const subirArchivoPS = (data, setLoadingBDPS) => {
  const formData = new FormData();
  formData.append('file', data[0].File);

  try {
    archivoService
      .postCargaMasivaPs(formData)
      .then(response => {
        storeMessages.message.setMessage({
          mensaje: `Se ha subido el archivo correctamente`,
          tipo: 'success'
        });
        setLoadingBDPS(false);
      })
      .catch(error => {
        console.log('error :>> ', error);
        storeMessages.message.setMessage({
          mensaje: `Ha ocurrido un problema con la descarga del archivo  , Si el problema persiste comunícate con el administrador del sitio`,
          tipo: 'warning'
        });
        setLoadingBDPS(false);
      });
  } catch (error) {
    console.log('error :>> ', error);
    storeMessages.message.setMessage({
      mensaje: `Ha ocurrido un problema con la descarga del archivo  , Si el problema persiste comunícate con el administrador del sitio`,
      tipo: 'error'
    });
    setLoadingBDPS(false);
  }
};

// Obtener EMPRESA (Un Registro)
export const obtenerEmpresaAT = async ({
  codigoEmpresa,
  setEmpresaAt,
  setIsLoadingEmpresa
}) => {
  try {
    const response = await empresasService.obtenerListaEmpresaAT(codigoEmpresa);
    if (response.status === 200) {
      const { data } = response;
      setEmpresaAt(data);
    }
  } catch (error) {
    setEmpresaAt([]);
    storeMessages.message.setMessage({
      mensaje:
        'Ha ocurrido un error al obtener la empresa, si el problema persiste comunícate con el administrador del sitio',
      tipo: 'error'
    });
    return error;
  } finally {
    setIsLoadingEmpresa(false);
  }
};
// LISTADO Paginado DE EMPRESAS AT
export const obtenerEmpresasAT = async ({
  columnFilters,
  setIsLoading,
  pagination,
  setEmpresasAt,
  setRowCount
}) => {
  setIsLoading(true);
  const query = {};

  let rutEmpresa = null;
  let nombreActividad = null;
  let nombreComuna = null;
  let nombreRegion = null;

  if (columnFilters) {
    rutEmpresa = columnFilters.find(filtro => filtro.id.includes('rutEmpresa'));
    nombreActividad = columnFilters.find(filtro =>
      filtro.id.includes('actividad.nombreActividad')
    );
    nombreRegion = columnFilters.find(filtro =>
      filtro.id.includes('direccion.comuna.region.nombreRegion')
    );
    nombreComuna = columnFilters.find(filtro =>
      filtro.id.includes('direccion.comuna.nombreComuna')
    );
  }
  if (rutEmpresa) {
    query.rutEmpresa = rutEmpresa.value;
  }
  if (nombreActividad) {
    query.nombreActividad = nombreActividad.value;
  }
  if (nombreComuna) {
    query.nombreComuna = nombreComuna.value;
  }
  if (nombreRegion) {
    query.nombreRegion = nombreRegion.value;
  }

  if (pagination) {
    query.numPagina = pagination.pageIndex;
    query.totalPagina = pagination.pageSize;
  }
  try {
    const response = await empresasService.obtenerListaEmpresasAT(query);
    if (response.status === 200) {
      const { data } = response;
      const { content, empty } = data;
      if (empty) {
        setEmpresasAt([]);
        setRowCount(0);
        return;
      } else if (Array.isArray(content)) {
        const dataFiltrada = content.map(empresa => {
          if (!empresa.direccion) return empresa;
          let nombreCalleyNumero =
            empresa.direccion.nombreVia;
          return {
            nombreCalleyNumero,
            ...empresa
          };
        });
        setEmpresasAt(dataFiltrada);
        setRowCount(data.totalElements);
      }
    } else {
      setEmpresasAt([]);
    }
  } catch (error) {
    console.log(error);
    setEmpresasAt([]);
    storeMessages.message.setMessage({
      mensaje:
        'Ha ocurrido un error al obtener el listado de empresas, si el problema persiste comunícate con el administrador del sitio',
      tipo: 'error'
    });
    return error;
  } finally {
    setIsLoading(false);
  }
};

// LISTADO Paginado DE Residuo AT (Un Registro)
export const obtenerResiduoAT = async ({
  codigoResiduo,
  setIsLoadingResiduo,
  setResiduoAt
}) => {
  try {
    const response = await residuosService.obtenerListaResiduoAT(codigoResiduo);
    if (response.status === 200) {
      const { data } = response;
      setResiduoAt(data);
    }
  } catch (error) {
    setResiduoAt([]);
    storeMessages.message.setMessage({
      mensaje:
        'Ha ocurrido un error al obtener el residuo, si el problema persiste comunícate con el administrador del sitio',
      tipo: 'error'
    });
    return error;
  } finally {
    setIsLoadingResiduo(false);
  }
};

// LISTADO Paginado DE Residuos AT
export const obtenerResiduosAT = async ({
  setIsLoading,
  pagination,
  setResiduosAt,
  setRowCount
}) => {
  setIsLoading(true);

  const query = {};

  if (pagination) {
    query.numPagina = pagination.pageIndex;
    query.totalPagina = pagination.pageSize;
  }

  try {
    const response = await residuosService.obtenerListaResiduosAT(query);
    if (response.status === 200) {
      const { data } = response;
      const { content, empty } = response.data;
      if (empty) {
        setResiduosAt([]);
        setRowCount(0);
        return;
      } else if (Array.isArray(content)) {
        const dataFiltrada = content.map(residuo => {
          return {
            ...residuo,
            cantidad: residuo.cantidad.toString().replace('.', ',') || '-'
          };
        });
        setResiduosAt(dataFiltrada);
        setRowCount(data.totalElements);
      } else {
        setResiduosAt([]);
        setRowCount(0);
      }
    }
  } catch (error) {
    setResiduosAt([]);
    storeMessages.message.setMessage({
      mensaje:
        'Ha ocurrido un error al obtener el listado de residuos, si el problema persiste comunícate con el administrador del sitio',
      tipo: 'error'
    });
    return error;
  } finally {
    setIsLoading(false);
  }
};

// Confirmacioón Eliminaciones
export const handleIniciarEliminacion = ({
  row,
  table,
  setRegistroEliminar,
  setOpenConfirmacion
}) => {
  const registro = row;
  setRegistroEliminar(registro.original);
  setOpenConfirmacion(true);
};

// Eliminar Empresa AT
export const handleConfirmarEliminacionEmpresaAT = async ({
  registroEliminar,
  empresasAt,
  setEmpresasAt,
  setOpenConfirmacion,
  setRegistroEliminar,
  setIsEliminando
}) => {
  try {
    setIsEliminando(true);
    const response = await empresasService.eliminarEmpresaAT(
      registroEliminar.codigoEmpresaAT
    );
    if (response.status === 200) {
      const empresasAtAux = empresasAt.filter(
        e => e.codigoEmpresaAT !== registroEliminar.codigoEmpresaAT
      );
      storeMessages.message.setMessage({
        mensaje: 'La empresa ha sido eliminada correctamente',
        tipo: 'exito'
      });
      setEmpresasAt(empresasAtAux);
      setOpenConfirmacion(false);
      setRegistroEliminar({});
      setIsEliminando(false);
    }
  } catch (error) {
    console.log('Error en la confirmación ', error);
    storeMessages.message.setMessage({
      mensaje:
        'Ha ocurrido un error al eliminar la empresa, si el problema persiste comunícate con el administrador del sitio',
      tipo: 'error'
    });
    setOpenConfirmacion(false);
    setIsEliminando(false);
  }
};

// Eliminar Residuo AT
export const handleConfirmarEliminacionResiduoAT = async ({
  registroEliminar,
  residuosAt,
  setResiduosAt,
  setOpenConfirmacion,
  setRegistroEliminar,
  setIsEliminando
}) => {
  try {
    setIsEliminando(true);
    const response = await residuosService.eliminarResiduoAT(
      registroEliminar.codigoResiduoAT
    );
    if (response.status === 200) {
      const residuosAtAux = residuosAt.filter(
        r => r.codigoResiduoAT !== registroEliminar.codigoResiduoAT
      );
      storeMessages.message.setMessage({
        mensaje: 'El residuo ha sido eliminado correctamente',
        tipo: 'exito'
      });
      setResiduosAt(residuosAtAux);
      setOpenConfirmacion(false);
      setRegistroEliminar({});
      setIsEliminando(false);
    }
  } catch (error) {
    console.log('Error en la confirmación ', error);
    storeMessages.message.setMessage({
      mensaje:
        'Ha ocurrido un error al eliminar el residuo, si el problema persiste comunícate con el administrador del sitio',
      tipo: 'error'
    });
    setOpenConfirmacion(false);
    setIsEliminando(false);
  }
};

// Servicio Actividades
export const CargaActividades = async ({
  setIsLoadingActividades,
  setActividades
}) => {
  const params = {
    totalPagina: DICTONARY.PAGINACION.ALL_DATA
  };
  try {
    setIsLoadingActividades(true);
    const response = await empresasService.actividades(params);

    const { data } = response;

    if (response.status === 200) {
      const actividadesData = data.content;
      setActividades(
        actividadesData.map(act => {
          return {
            value: act.codigoCiuActividad,
            label: act.nombreActividad,
            rubro: {
              value: act.rubro.codigoCiuRubro,
              label: act.rubro.nombreRubro
            }
          };
        })
      );
    }
    data.empty && setActividades([]);
  } catch (error) {
    console.log(error);
  } finally {
    setIsLoadingActividades(false);
  }
};

// Servicio  Listas LER
export const CargaListasLER = async ({
  setListaLer,
  setListaLerNivelUno,
  setCargandoListaLer
}) => {
  try {
    const result = await commonsService.nombresLER({
      totalPagina: DICTONARY.PAGINACION.ALL_DATA,
      ordenarPor: 'nombreLER'
    });
    const { content, empty } = result.data;
    if (result.status !== 200 && empty) {
      setListaLer([]);
    } else if (Array.isArray(content)) {
      setListaLer(content);
      setListaLerNivelUno(content.filter(rsd => rsd.nivel === 1));
    } else {
      setListaLer([]);
      // console.error(result.data);
      //TODO: agregar mensaje de error
    }
  } catch (error) {
    console.error(error);
    //TODO: agregar mensaje de error
  } finally {
    setCargandoListaLer(false);
  }
};

// Servicio  Regiones
export const CargaRegiones = async ({ setRegiones, setLoadingRegiones }) => {
  try {
    setLoadingRegiones(true);
    await commonsService
      .obtieneRegiones()
      .then(response => {
        if (response.length === 0) return;
        setRegiones(true);
        const content = response.data.content;
        setRegiones(
          content.map(comuna => {
            return {
              value: comuna.codigoRegion,
              label: comuna.nombreRegion
            };
          })
        );
      })
      .catch(err => {
        storeMessages.message.setMessage({
          mensaje:
            'Ha ocurrido un error al obtener el listado de regiones, si el problema persiste comunícate con el administrador del sitio',
          tipo: 'error'
        });
        console.error('error: ', err);
      });
  } catch (error) {
    console.log(error);
    storeMessages.message.setMessage({
      mensaje:
        'Ha ocurrido un error al obtener el listado de comunas, si el problema persiste comunícate con el administrador del sitio',
      tipo: 'error'
    });
  } finally {
    setLoadingRegiones(false);
  }
};
// Servicio  Comunas
export const CargaComunas = async ({
  codigoRegion,
  setComunas,
  setLoadingComunas
}) => {
  if (!codigoRegion) return;
  try {
    setLoadingComunas(true);
    const params = {
      codigosRegion: codigoRegion
    };
    await commonsService
      .obtieneComunas(params)
      .then(response => {
        if (response.length === 0) return;
        setComunas(true);
        const content = response.data.content;
        setComunas(
          content.map(comuna => {
            return {
              value: comuna.codigoComuna,
              label: comuna.nombreComuna
            };
          })
        );
      })
      .catch(err => {
        storeMessages.message.setMessage({
          mensaje:
            'Ha ocurrido un error al obtener el listado de comunas, si el problema persiste comunícate con el administrador del sitio',
          tipo: 'error'
        });
        console.error('error: ', err);
      });
  } catch (error) {
    console.log(error);
    storeMessages.message.setMessage({
      mensaje:
        'Ha ocurrido un error al obtener el listado de comunas, si el problema persiste comunícate con el administrador del sitio',
      tipo: 'error'
    });
  } finally {
    setLoadingComunas(false);
  }
};

// Servicio Empresas

// LISTADO Paginado DE EMPRESAS AT
export const obtenerTodasEmpresasAT = async ({
  setLoadingEmpresas,
  paginationEmpresas,
  setEmpresas,
  setRowCountEmpresas,
  razonSocial
}) => {
  if (razonSocial === undefined) return;
  if (razonSocial === null) return;
  if (razonSocial.length <= 3) return;

  setEmpresas(false);

  const query = {};

  if (paginationEmpresas) {
    query.numPagina = paginationEmpresas.pageIndex;
    query.totalPagina = paginationEmpresas.pageSize;
    query.razonSocial = razonSocial;
  }
  // query.totalPagina = DICTONARY.PAGINACION.ALL_DATA;

  try {
    setLoadingEmpresas(true);
    const response = await empresasService.obtenerListaEmpresasOptimizadoAT(
      query,
      DICTONARY.INTERCEPTOR.DESACTIVADO
    );
    if (response.status === 200 && !response.data.error) {
      const { data } = response;
      const { content } = data;
      setEmpresas(
        content.map(empresa => {
          return {
            value: empresa.codigoEmpresaAT,
            label: empresa.razonSocial
          };
        })
      );
      setRowCountEmpresas(data.totalElements);
    }
  } catch (error) {
    setEmpresas([]);
    storeMessages.message.setMessage({
      mensaje:
        'Ha ocurrido un error al obtener el listado de empresas, si el problema persiste comunícate con el administrador del sitio',
      tipo: 'error'
    });
    return error;
  } finally {
    setLoadingEmpresas(false);
  }
};

// Editar Empresa AT
export const EditarSubmitEmpresaAT = async ({
  setIsLoading,
  empresasAtData,
  codigoEmpresaAT
}) => {
  try {
    setIsLoading(true);
    await empresasService
      .editarEmpresaAT(codigoEmpresaAT, empresasAtData)
      .then(response => {
        // console.log('response :>> ', response);
        storeMessages.message.setMessage({
          mensaje: 'La empresa ha sido editada correctamente',
          tipo: 'exito'
        });
      })
      .catch(err => {
        console.error('error: ', err);
        storeMessages.message.setMessage({
          mensaje:
            'Ha ocurrido un error al editar la empresa, si el problema persiste comunícate con el administrador del sitio',
          tipo: 'error'
        });
      });
  } catch (error) {
    console.log(error);
    storeMessages.message.setMessage({
      mensaje:
        'Ha ocurrido un error al editar la empresa, si el problema persiste comunícate con el administrador del sitio',
      tipo: 'error'
    });
  } finally {
    setIsLoading(false);
    setTimeout(() => {
      //  window.location.replace(RUTAS.LISTA_EMPRESAS_AT);
    }, 1);
  }
};

// Editar Residuo AT
export const EditarSubmitResiduoAT = async ({
  setIsLoading,
  data,
  codigoResiduo
}) => {
  try {
    setIsLoading(true);
    await residuosService
      .editarResiduoAT(codigoResiduo, data)
      .then(response => {
        if (response.status === 200) {
          storeMessages.message.setMessage({
            mensaje: 'El residuo ha editado creado correctamente',
            tipo: 'exito'
          });
        }
      })
      .catch(err => {
        console.error('error: ', err);
        storeMessages.message.setMessage({
          mensaje:
            'Ha ocurrido un error al editar el residuo, si el problema persiste comunícate con el administrador del sitio',
          tipo: 'error'
        });
      });
  } catch (error) {
    console.log(error);
    storeMessages.message.setMessage({
      mensaje:
        'Ha ocurrido un error al editar lel residuo, si el problema persiste comunícate con el administrador del sitio',
      tipo: 'error'
    });
  } finally {
    setTimeout(() => {
      setIsLoading(false);
      //  window.location.replace(RUTAS.LISTA_RESIDUOS_AT);
    }, 1);
  }
};

// Crear Empresa AT
export const CrearEmpresaAT = async ({ setIsLoading, empresasAtData }) => {
  try {
    setIsLoading(true);
    await empresasService
      .crearEmpresaAT(empresasAtData)
      .then(response => {
        if (response.status === 200) {
          storeMessages.message.setMessage({
            mensaje: 'La empresa ha sido creada correctamente',
            tipo: 'exito'
          });
        }
      })
      .catch(err => {
        console.error('error: ', err);
        storeMessages.message.setMessage({
          mensaje:
            'Ha ocurrido un error al crear la empresa, si el problema persiste comunícate con el administrador del sitio',
          tipo: 'error'
        });
      });
  } catch (error) {
    console.log(error);
    storeMessages.message.setMessage({
      mensaje:
        'Ha ocurrido un error al crear la empresa, si el problema persiste comunícate con el administrador del sitio',
      tipo: 'error'
    });
  } finally {
    setTimeout(() => {
      setIsLoading(false);
      //  window.location.replace(RUTAS.LISTA_EMPRESAS_AT);
    }, 1);
  }
};

// Crear Residuo AT
export const CrearResiduoAT = async ({ setIsLoading, data }) => {
  try {
    setIsLoading(true);
    await residuosService
      .crearResiduoAT(data)
      .then(response => {
        if (response.status === 200) {
          storeMessages.message.setMessage({
            mensaje: 'El residuo ha sido creado correctamente',
            tipo: 'exito'
          });
        }
      })
      .catch(err => {
        console.error('error: ', err);
        storeMessages.message.setMessage({
          mensaje:
            'Ha ocurrido un error al crear el residuo, si el problema persiste comunícate con el administrador del sitio',
          tipo: 'error'
        });
      });
  } catch (error) {
    console.log(error);
    storeMessages.message.setMessage({
      mensaje:
        'Ha ocurrido un error al crear lel residuo, si el problema persiste comunícate con el administrador del sitio',
      tipo: 'error'
    });
  } finally {
    setTimeout(() => {
      setIsLoading(false);
      // window.location.replace(RUTAS.LISTA_RESIDUOS_AT);
    }, 1);
  }
};

// Servicio  Potencial de Sinergias
export const CargaPotencialSinergias = async ({
  setIsLoadingSinergias,
  setDataSinergias,
  paginationSinergias,
  setTotalSinergias
}) => {
  try {
    setIsLoadingSinergias(true);
    let params = {
      numPagina: paginationSinergias.pageIndex,
      totalPagina: paginationSinergias.pageSize
    };
    await analisisTerritorialService
      .potencialSinergias(params, DICTONARY.INTERCEPTOR.DESACTIVADO)
      .then(response => {
        if (response.data.error) throw new Error('SIN_REGISTROS');
        const { data } = response;
        const { content, empty } = data;
        if (empty) {
          setDataSinergias([]);
          setTotalSinergias(0);
          return;
        } else if (Array.isArray(content)) {
          setDataSinergias(content);
          setTotalSinergias(data.totalElements);
        }
      })
      .catch(error => {
        setDataSinergias([]);
        setTotalSinergias(0);
        setIsLoadingSinergias(false);
        return error;
      });
  } catch (error) {
    console.log(error);
    setDataSinergias([]);
    setTotalSinergias(0);
    setIsLoadingSinergias(false);
    return error;
  } finally {
    setIsLoadingSinergias(false);
  }
};
