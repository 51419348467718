import { useEffect, useState } from 'react';
// Design System
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import Heading from 'components/design-system/Heading/Heading';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import Text from 'components/design-system/Text/Text';
// Const
import { DICTONARY } from "const/Dictonary";
// hooks Selectores
import { useNombreLER } from 'views/Residuos/hooks/Selectores/useNombreLER';
import { useResiduos } from 'views/Residuos/hooks/Selectores/useResiduos';
import { useRecursos } from 'views/Residuos/hooks/Selectores/useRecursos';
// Inputs
import { RecursoDistinto } from '../Inputs/RecursoDistinto';
// Material UI
import { Chip } from '@mui/material';
import { ObtieneListaRecursos, ValidaSiExisteRecurso } from 'hooks';
import { ObtieneCodigoRecursoExiste } from 'hooks/useServices';


export const IdentificacionResiduo = ({
  errors,
  // handleBlur,
  handleChange,
  // handleReset,
  // handleSubmit,
  isSubmitting,
  setFieldError,
  setFieldTouched,
  setFieldValue,
  touched,
  // validateForm,
  values,
  tipoFormularioSeleccionado,
}) => {

  const [tieneTipoRecursos, setTieneTipoRecursos] = useState(false);

  const [mensajeRecursoExiste, setMensajeRecursoExiste] = useState(false);

  // SERVICIO nombresLER Refactoring
  const {
    calculoListaSubLer,
    calculoListaSubSubLer,
    setListaLerNivelTres,
    setTieneSubSubtiposLer,
    cargandoListaLer,
    listaLer,
    listaLerNivelDos,
    listaLerNivelDosEdit,
    listaLerNivelTres,
    listaLerNivelTresEdit,
    listaLerNivelUno,
    listaLerNivelUnoEdit,
    tieneSubSubtiposLer,
  } = useNombreLER({
    values,
    setFieldValue,
  });

  const {
    calculoSubTipoResiduos,
    setCargandoListaSubTipoResiduos,
    setTieneTipoResiduos,
    cargandoListaSubTipoResiduos,
    cargandoListaTipoResiduos,
    listaSubTipoResiduos,
    listaTipoResiduoNivelDosEdit,
    listaTipoResiduoNivelUnoEdit,
    listaTipoResiduos,
    listaTipoResiduosNivelUno,
    tieneTipoResiduos,
  } = useResiduos();

  const {
    listaTipoRecursos,
    cargandoListaTipoRecursos,
    handleTipoRecursos,
    handleDeleteMateriaPrima,
    handleAddOtraMateriaPrima,
    handleOtraMateriaKeyPress,
    setListasOtrosTiposRecursos
  } = useRecursos({
    codigosTipoResiduo: values.subTipoResiduo?.codigoTipoResiduo,
    tieneTipoRecursos,
  });


  // Carga selectores
  useEffect(() => {
  }, [
    cargandoListaLer,
    cargandoListaTipoResiduos,
  ]);

  // Lena selectores de Residuos para edición
  useEffect(() => {

    if (
      listaTipoResiduoNivelUnoEdit.length &&
      values.subTipoResiduo?.codigoNivelAnteriorTipoResiduo
    ) {
      const nivelUnoEdit = listaTipoResiduoNivelUnoEdit.find(
        ler =>
          ler.codigoTipoResiduo ===
          values.subTipoResiduo?.codigoNivelAnteriorTipoResiduo
      );

      setFieldValue('tipoResiduo', nivelUnoEdit);
    }
  }, [listaTipoResiduoNivelUnoEdit,
    listaTipoResiduoNivelUnoEdit.length,
    setFieldValue,
    values.subTipoResiduo?.codigoNivelAnteriorTipoResiduo]);


  // Logica si el recurso existe

  const {
    listaRecursos,
  } = ObtieneListaRecursos();

  useEffect(() => {
    setMensajeRecursoExiste(false)
    if (values.otroTipoRecurso && values.otroTipoRecurso.length > 0) {
      // eliminar de la lista de otros recursos los que tenga el mismo nombreRecruso de la lista tipoRecurso
      const obtieneNombreUltimoOtroTipoRecurso = values.otroTipoRecurso[values.otroTipoRecurso.length - 1].nombreRecurso;
    



      const existe = ValidaSiExisteRecurso({
        listaRecursos: listaRecursos,
        nombre: obtieneNombreUltimoOtroTipoRecurso,
      });

    
      if (existe) {
        // eliminar registro que existe en la bd de la lista de otros recursos
        // const 
        setFieldValue('otroTipoRecurso', values.otroTipoRecurso.filter(recurso => recurso.nombreRecurso !== obtieneNombreUltimoOtroTipoRecurso));
        const {
          codigoRecurso,
          nombreRecurso
        } = ObtieneCodigoRecursoExiste({
          listaRecursos: listaRecursos,
          nombre: obtieneNombreUltimoOtroTipoRecurso,
        });
        // que sea unico el recurso
        // los tipos de recursos deben ser unicos
        const listaTipoRecurso = values.tipoRecurso.map(tipoRecurso => tipoRecurso.nombreRecurso);
        const existeTipoRecurso = listaTipoRecurso.includes(obtieneNombreUltimoOtroTipoRecurso);
        
        console.log(existeTipoRecurso);
        if(existeTipoRecurso) return setMensajeRecursoExiste('El recurso ya existe en la lista de recursos');

        setFieldValue('tipoRecurso' , [...values.tipoRecurso, { codigoRecurso: codigoRecurso, nombreRecurso: nombreRecurso }])
        // eliminar de la lista de otros recursos los que tenga el mismo nombreRecruso de la lista tipoRecurso
   
      }
    }
  }, [listaRecursos, values.otroTipoRecurso, setFieldValue]);


  

  const getIcon = tipo => {
    const BASE = DICTONARY.ESTADO[tipo.toUpperCase()];
    return <BASE.ICONO className="mr-1 text-lg" />;
  };

  useEffect(() => {
  
    if(values.tipoRecurso.length > 0 ){
     setTieneTipoRecursos(true)
    }
   
   }, [setFieldValue, values.tipoRecurso])
   

  return (
    <div className="col-span-12">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-12">
          <Heading type="h4" className="mb-0 text-uv-primary-10">
            Identificación del residuo o recurso
          </Heading>
        </div>
        <div className="col-span-12 xs:col-span-12">

          {/* Fila 1 */}
          <div className="grid grid-cols-12 gap-4">
            {/* NombreLER */}
            <div className="col-span-12 xs:col-span-4">
              <AutocompleteComponent
                accesor="nombreLER"
                className=""
                clearOnEscape={true}
                disabled={isSubmitting || cargandoListaLer}
                fullWidth
                getOptionLabel={option => option.nombreLER || ''}
                loading={!listaLerNivelUno.length && cargandoListaLer}
                loadingText={!listaLerNivelUno.length && 'Cargando...'}
                name="nombreLER"
                noOptionsText="No hay coincidencias"
                openOnFocus={true}
                options={listaLerNivelUno || []}
                size="small"
                value={values.nombreLER || ''}
                variant="outlined"
                onChange={(ev, selected) => {
                  // Limpieza al cambiar de opción
                  setFieldValue('nombreSubLER', []);
                  setFieldValue('nombreSubSubLER', []);

                  selected
                    ? setFieldValue('nombreLER', {
                      codigoLER: selected.codigoLER,
                      nombreLER: selected.nombreLER
                    })
                    : setFieldValue('nombreLER', []);

                  calculoListaSubLer(listaLer, selected, setFieldValue);

                  setFieldTouched('codigoSubLER');
                }}
                isOptionEqualToValue={(option, value) =>
                  option.nombreLER === value.nombreLER
                }
              >
                <InputComponent
                  className=""
                  fullWidth
                  onBlur={() => setFieldTouched('nombreLER')}
                  touched={touched.nombreLER}
                  placeholder={
                    listaLerNivelUno.length ? 'Seleccione nombre LER' : ''
                  }
                  estado={
                    errors.nombreLER
                      ? {
                        tipo: 'error',
                        mensaje: errors.nombreLER
                      }
                      : null
                  }
                  label={
                    <LabelComponent>
                      Nombre LER (Listado europeo de residuos)
                      <br />
                      <span className="text-neutral-60">Categoría</span>
                    </LabelComponent>
                  }
                />
              </AutocompleteComponent>
            </div>

            {/* NombreSubLER */}
            <div className="col-span-12 xs:col-span-4">
              <AutocompleteComponent
                accesor="nombreLER"
                className=""
                clearOnEscape={true}
                disabled={isSubmitting || cargandoListaLer}
                getOptionLabel={option => option.nombreLER || ''}
                loading={!listaLerNivelDos.length && cargandoListaLer}
                loadingText={!listaLerNivelDos.length && 'Cargando...'}
                name="nombreSubLER"
                noOptionsText="No hay coincidencias"
                openOnFocus={false}
                options={listaLerNivelDosEdit.filter(ler => ler.codigoNivelAnterior === values.nombreLER?.codigoLER)}
                size="small"
                value={values.nombreSubLER || ''}
                variant="outlined"
                onChange={(ev, selected) => {
                  // Limpieza al cambiar de opción
                  setFieldValue('nombreSubSubLER', []);
                  selected
                    ? setFieldValue('nombreSubLER', {
                      codigoLER: selected.codigoLER,
                      nombreLER: selected.nombreLER
                    })
                    : setFieldValue('nombreSubLER', []);

                  //Calculo SUB SUB LER
                  calculoListaSubSubLer(
                    listaLer,
                    selected,
                    setFieldValue,
                    setListaLerNivelTres,
                    setTieneSubSubtiposLer
                  );

                  setFieldTouched('nombreSubSubLER');
                  setFieldTouched('tipoResiduo');
                }}
              >
                <InputComponent
                  fullWidth
                  disabled={listaLerNivelDos.length === 0 || cargandoListaLer}
                  onBlur={() => setFieldTouched('nombreSubLER')}
                  touched={touched.nombreSubLER}
                  placeholder={
                    listaLerNivelDos.length ? 'Seleccione nombre LER' : ''
                  }
                  estado={
                    errors.nombreSubLER
                      ? {
                        tipo: 'error',
                        mensaje: errors.nombreSubLER
                      }
                      : null
                  }
                  label={
                    <LabelComponent className="mt-5">
                      <span className="text-neutral-60">Capítulo</span>
                    </LabelComponent>
                  }
                />
              </AutocompleteComponent>
            </div>

            {/* NombreSubSubLER */}
            <div className="col-span-12 xs:col-span-4">
              <AutocompleteComponent
                accesor="nombreLER"
                className=""
                clearOnEscape={true}
                disabled={isSubmitting || cargandoListaLer}
                getOptionLabel={option => option.nombreLER || ''}
                loading={!listaLerNivelTres.length && cargandoListaLer}
                loadingText={!listaLerNivelTres.length && 'Cargando...'}
                name="nombreSubSubLER"
                noOptionsText="No hay coincidencias"
                openOnFocus={false}
                options={listaLerNivelTresEdit.filter(ler => ler.codigoNivelAnterior === values.nombreSubLER?.codigoLER)}
                size="small"
                value={values.nombreSubSubLER || ''}
                variant="outlined"
                onChange={(ev, selected) => {
                  selected
                    ? setFieldValue('nombreSubSubLER', {
                      codigoLER: selected.codigoLER,
                      nombreLER: selected.nombreLER
                    })
                    : setFieldValue('nombreSubSubLER', []);

                  setFieldTouched('codigoSubSubLER');
                  setFieldTouched('tipoResiduo');
                }}
              >
                <InputComponent
                  fullWidth
                  onBlur={() => setFieldTouched('nombreSubSubLER')}
                  touched={touched.nombreSubSubLER}
                  placeholder={
                    listaLerNivelTres.length ? 'Seleccione nombre LER' : ''
                  }
                  estado={
                    errors.nombreSubSubLER
                      ? {
                        tipo: 'error',
                        mensaje: errors.nombreSubSubLER
                      }
                      : null
                  }
                  label={
                    <LabelComponent className="mt-5">
                      <span className="text-neutral-60">Sub capítulo</span>
                    </LabelComponent>
                  }
                />
              </AutocompleteComponent>
            </div>
          </div>

          {/* Fila 2 */}
          <div className="grid grid-cols-12 gap-4 mt-4">
            {/* Tipo Residuo */}
            <div className="col-span-12 xs:col-span-4">
              <AutocompleteComponent
                accesor="nombreTipo"
                className="mb-3 "
                clearOnEscape={true}
                disabled={isSubmitting || cargandoListaTipoResiduos}
                fullWidth
                loading={cargandoListaTipoResiduos}
                loadingText={cargandoListaTipoResiduos && 'Cargando...'}
                name="tipoResiduo"
                openOnFocus={true}
                options={listaTipoResiduosNivelUno || []}
                size="small"
                value={values.tipoResiduo || ''}
                variant="outlined"
                getOptionLabel={option => option.nombreTipo || ''}
                onChange={(ev, selected) => {
                  if (!selected) {
                    setCargandoListaSubTipoResiduos(true);

                    // setSubTipoRSDdisabled(true);

                    setTieneTipoResiduos(false);

                    setFieldValue('tieneSubTipoResiduo', false);
                  }

                  // Limpieza subTipoResiduo
                  setFieldValue('subTipoResiduo', []);
                  // Limpieza tipoRecurso
                  setFieldValue('tipoRecurso', []);

                  selected
                    ? setFieldValue('tipoResiduo', {
                      codigoTipoResiduo: selected.codigoTipoResiduo,
                      nombreTipo: selected.nombreTipo
                    })
                    : setFieldValue('tipoResiduo', []);

                  // Calculo Sub Tipos de Residuos
                  selected &&
                    calculoSubTipoResiduos(
                      selected.codigoTipoResiduo,
                      setFieldValue
                    );

                  selected && setFieldTouched('subTipoResiduo');
                }}
              >
                <InputComponent
                  fullWidth
                  onBlur={() => setFieldTouched('tipoResiduo')}
                  touched={touched.tipoResiduo}
                  placeholder={
                    listaTipoResiduosNivelUno.length
                      ? 'Seleccione tipo de residuo'
                      : ''
                  }
                  estado={
                    errors.tipoResiduo
                      ? {
                        tipo: 'error',
                        mensaje: errors.tipoResiduo
                      }
                      : null
                  }
                  label={
                    <LabelComponent>
                      Residuo
                      <br />
                      <span className="text-neutral-60">
                        (incluye subproductos)
                      </span>
                    </LabelComponent>
                  }
                />
              </AutocompleteComponent>
            </div>

            {/* Sub Tipo Residuo */}
            {/* Subtipo de residuo */}
            <div className="col-span-12 xs:col-span-4">
              <AutocompleteComponent
                accesor="nombreTipo"
                className="mb-3 "
                clearOnEscape={true}
                disabled={tipoFormularioSeleccionado.ingresar
                  ?  values.tipoResiduo.length === 0
                  :  !values.tipoResiduo 
                }
                loading={cargandoListaSubTipoResiduos}
                name="subTipoResiduo"
                openOnFocus={false}
                options={listaTipoResiduoNivelDosEdit.filter(tipoRSD => tipoRSD?.codigoNivelAnteriorTipoResiduo === values.tipoResiduo?.codigoTipoResiduo)}
                size="small"
                variant="outlined"
                noOptionsText={
                  cargandoListaSubTipoResiduos ? 'Cargando...' : 'Sin opciones'
                }
                value={values.subTipoResiduo || ''}
                getOptionLabel={option => option.nombreTipo || ''}
                onChange={(ev, selected) => {
                  selected
                    ? setFieldValue('subTipoResiduo', {
                      codigoTipoResiduo: selected.codigoTipoResiduo,
                      nombreTipo: selected.nombreTipo
                    })
                    : setFieldValue('subTipoResiduo', []);

                  // Limpieza tipoRecurso
                  setFieldValue('tipoRecurso', []);
                  setFieldValue('otroTipoRecurso', []);
                  setFieldValue('_otraMateriaPrima', '');

                  if (selected && selected.recursos.length > 0) {
                    setFieldValue('tieneTipoRecursos', true);
                    setTieneTipoRecursos(true);
                    setFieldTouched('tipoRecurso');
                  } else {
                    setFieldValue('tieneTipoRecursos', false);
                    setTieneTipoRecursos(false);
                    setFieldTouched('_otraMateriaPrima');
                  }
                  setFieldTouched('sucursal');
                }}
              >
                <InputComponent
                  fullWidth
                  onBlur={() => setFieldTouched('subTipoResiduo')}
                  touched={touched.subTipoResiduo}
                  placeholder={
                    listaSubTipoResiduos.length
                      ? 'Seleccione subtipo de residuo'
                      : ''
                  }
                  estado={
                    errors.subTipoResiduo
                      ? {
                        tipo: 'error',
                        mensaje: errors.subTipoResiduo
                      }
                      : null
                  }
                  label={
                    <LabelComponent>
                      Subtipo de residuo
                      <br />
                      <span className="text-neutral-60">
                        (incluye subproductos)
                      </span>
                    </LabelComponent>
                  }
                />
              </AutocompleteComponent>
            </div>

          </div>

          {/* Fila 3 */}
          <div className="grid grid-cols-12 gap-4 mt-4">

            <div className="col-span-12 xs:col-span-4">
              <Text type="p" className="font-semibold pt-2">
                Seleccione el recurso (incluye recursos o componentes)
              </Text>
              <Text type="h6" className="font-normal pt-2">
                Puede seleccionar más de uno e incluso incluir uno que no se
                encuentre en el listado.
              </Text>
            </div>

            {/* Tipo Recurso */}
            <div className="col-span-12 xs:col-span-4">
              <AutocompleteComponent
                multiple
                className="mb-3 flex-1 block "
                clearOnEscape={true}
                accesor="nombreRecurso"
                disabled={!tieneTipoRecursos}
                loading={cargandoListaTipoRecursos}
                loadingText={
                  !listaTipoRecursos &&
                  'No hay recursos para el residuo seleccionado' &&
                  touched.tipoRecurso &&
                  errors.tipoRecurso
                }
                name="tipoRecurso"
                options={listaTipoRecursos}
                variant="outlined"
                onBlur={() => setFieldTouched('tipoRecurso')}
                value={values.tipoRecurso}
                getOptionLabel={option => option.nombreRecurso || ''}
                noOptionsText={
                  cargandoListaTipoRecursos ? 'Cargando...' : 'Sin opciones'
                }
                onChange={(ev, selected) => {
                  selected
                    ? setFieldValue('tipoRecurso', selected)
                    : setFieldValue('tipoRecurso', []);
                }}
              >
                <InputComponent
                  className="min-h-9 "
                  fullWidth
                  placeholder={
                    listaTipoRecursos
                      ? 'Seleccione el recurso'
                      : 'Sin opciones'
                  }
                  touched={touched.tipoRecurso}
                  estado={
                    errors.tipoRecurso
                      ? {
                        tipo: 'error',
                        mensaje: errors.tipoRecurso
                      }
                      : null
                  }
                  label={
                    <>
                      <LabelComponent>Recursos</LabelComponent>
                    </>
                  }
                />
                {
                  touched.tipoResiduo &&
                    !cargandoListaTipoRecursos &&
                    listaTipoRecursos.length === 0
                    && values.tipoRecurso.length === 0 ? (
                    <Text
                      size="s"
                      className="font-medium text-warning-dark flex items-center py-1"
                    >
                      * {'No hay recursos para el residuo seleccionado'}
                    </Text>
                  ) : (
                    <></>
                  )}
              </AutocompleteComponent>
            </div>

            {/* Otro Tipo Recurso */}
            <div className="col-span-12 xs:col-span-4">
              <InputComponent
                multiple
                className="w-full "
                disabled={(errors.otroTipoRecursoExiste)}
                label={
                  <LabelComponent>Añadir uno distinto</LabelComponent>
                }
                name="_otraMateriaPrima"
                onChange={handleChange}
                onKeyPress={event => {
                  handleOtraMateriaKeyPress(
                    event,
                    values,
                    setFieldValue
                  );
                  setFieldTouched('tipoRecursoReemplazo');
                }}
                estado={
                  errors._otraMateriaPrima
                    ? {
                      tipo: 'error',
                      mensaje: errors._otraMateriaPrima
                    }
                    : null
                }
                touched={touched._otraMateriaPrima}
                onBlur={() => setFieldTouched('_otraMateriaPrima')}
                placeholder="o ingrese uno manualmente"
                type="text"
                value={values._otraMateriaPrima || ''}
                variant="outlined"
                outerInputProps={{
                  endAdornment: (
                    <RecursoDistinto
                      handleAddOtraMateriaPrima={handleAddOtraMateriaPrima}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  )
                }}
              />

              {values.otroTipoRecurso &&
                values.otroTipoRecurso?.map((mp, i) => (
                  <Chip
                    label={mp.nombreRecurso}
                    className="mt-2"
                    onDelete={handleDeleteMateriaPrima(
                      mp,
                      values,
                      setFieldValue
                    )}
                    key={mp + i}
                  />
                ))}
              {touched._otraMateriaPrima && errors.otroTipoRecurso ? (
                <Text
                  size="s"
                  className="font-medium text-error-dark flex items-center py-1"
                >
                  {getIcon('error')} {errors.otroTipoRecurso}
                </Text>
              ) : null}
              {touched._otraMateriaPrima && mensajeRecursoExiste ? (
                <Text
                  size="s"
                  className="font-medium text-warning-dark flex items-center py-1"
                >
                  {mensajeRecursoExiste}
                </Text>
              ) : null}

            </div>



          </div>

          {/* Fila 4 */}
          <div className="grid grid-cols-12 gap-4 mt-4">
            {/* Descripción adicional */}
            <div className="col-span-12 xs:col-span-12">
              <InputComponent
                name="descripcionAdicional"
                type="text"
                fullWidth
                multiline
                rows={4}
                size={'small'}
                value={values.descripcionAdicional}
                onBlur={() => setFieldTouched('descripcionAdicional')}
                touched={touched.descripcionAdicional ? true : false}
                onChange={e => {
                  setFieldTouched('nombreLER');
                  setFieldTouched('tipoResiduo');
                  handleChange(e);
                }}
                label={<LabelComponent>Descripción adicional</LabelComponent>}
                estado={
                  errors.descripcionAdicional
                    ? {
                      tipo: 'error',
                      mensaje: errors.descripcionAdicional
                    }
                    : null
                }
                placeholder="Detallar calidad del producto o cualquier descipción adicional del Residuo"
              />
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
