import ChatBubble from '@mui/icons-material/ChatBubble';
import Close from '@mui/icons-material/Close';
import PlaceRounded from '@mui/icons-material/PlaceRounded';
import { Divider } from '@mui/material';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import { RoleComponent } from 'components/design-system/Role/Role';
import Text from 'components/design-system/Text/Text';
import { useContext, useEffect, useState } from 'react';
import { MatchContext } from '../../../../Residuos/context/MatchContext';
import { Lock } from '@mui/icons-material';
import LinkComponent from 'components/design-system/Link/Link';
import { DICTONARY, RUTAS } from 'const/Dictonary';
import { Documentos } from './Documentos';
import { useReunion } from 'hooks/useReunion';

export const EmpresaDetalle = () => {
  const {
    tieneNegocio,
    salasChat,
    sucursalSeleccionada,
    setSucursalSeleccionado
  } = useContext(MatchContext);

  const empresa = sucursalSeleccionada;
  const { handleCrearReunion, isCreandoReunion } = useReunion();
  const [isSalaChatCreada, setIsSalaChatCreada] = useState(false);

  const esDemandante = sucursalSeleccionada
    ? !!empresa.rol &&
      empresa.rol.find(rol => rol.codigoRol === DICTONARY.ROL.RECEPTOR.CODIGO)
    : false;
  const esOferente = sucursalSeleccionada
    ? !!empresa.rol &&
      empresa.rol.find(rol => rol.codigoRol === DICTONARY.ROL.GENERADOR.CODIGO)
    : false;

  const handleSolicitarReunion = async () => {

    try {
      handleCrearReunion({
        codigoSucursal: empresa.codigoSucursal,
        origenSalaChat: 'empresa'
      });
      setIsSalaChatCreada(true);
    } catch (error) {
      console.log('ERROR_AL_CREAR_SALA_CHAT ', error);
    }
  };

  if (!sucursalSeleccionada) {
    return;
  }

  return (
    <div>
      <div className="w-full shadow-md bg-white">
        <div className="h-[80vh] scrollbar-uv overflow-y-auto p-4 w-full">
          <div>
            <div className="relative">
              <Heading type="h3" className="pr-10 mb-0">
                {empresa.empresa ? empresa.empresa : 'Nombre no definido'}
                <div className="absolute -right-1 top-0">
                  <IconButtonComponent
                    onClick={() => setSucursalSeleccionado(null)}
                    size="small"
                    type="neutral"
                  >
                    <Close />
                  </IconButtonComponent>
                </div>
              </Heading>
              <div className="mb-2">
                {empresa.rol &&
                  empresa.rol.map(rol => (
                    <RoleComponent
                      className="mr-1.5"
                      key={rol.codigoRol}
                      rol={rol.nombreRol}
                      verbo="nombre"
                      tipo="etiqueta"
                    />
                  ))}
              </div>
              <div className="relative">
                <span className="pl-8 block font-secondary text-xs text-neutral-60 tracking-wider font-medium uppercase">
                  {empresa.nombreSucursal ? empresa.nombreSucursal : 'Sucursal'}
                </span>

                <div className="flex items-center">
                  <PlaceRounded className="mr-2 text-uv-secondary-10" />
                  <Text>
                    {empresa.direccion ? (
                      <>
                        {empresa.direccion?.nombreVia}
                        {', '}
                        {empresa.direccion?.comuna}
                        {',  Región '}
                        {empresa.direccion?.region}
                      </>
                    ) : (
                      'Dirección no definida'
                    )}
                  </Text>
                </div>
              </div>
            </div>
          </div>

          <div>
            <Divider className="my-3" />

            {!tieneNegocio && (
              <div>
                <div className="bg-warning-light rounded px-4 py-2">
                  <div className="flex">
                    <Lock className="mr-2 text-warning-dark" />
                    <Text size="S">
                      Solo{' '}
                      <b className="text-black">
                        usuarios con negocios registrados
                      </b>{' '}
                      pueden comunicarse internamente en la plataforma.{' '}
                      <LinkComponent href={RUTAS.REGISTRAR_NEGOCIO}>
                        Registrar un negocio
                      </LinkComponent>
                    </Text>
                  </div>
                </div>
              </div>
            )}

            <div className="w-2/3 my-4">
              <ButtonComponent
                isLoading={isCreandoReunion}
                disabled={
                  isSalaChatCreada ||
                  !tieneNegocio ||
                  isCreandoReunion ||
                  salasChat.empresas.find(suc => suc.includes(empresa.empresa))
                }
                onClick={() => handleSolicitarReunion()}
                className="px-2 w-full"
                type="primary"
              >
                <ChatBubble className="mr-1" /> Solicitar Reunión
              </ButtonComponent>
            </div>
            <Divider className="my-3" />

            <div className="mb-4">
              <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-normal pt-0.5">
                RUBRO
              </span>
              <Text className="text-black">{empresa.rubro}</Text>
            </div>

            <div className="mb-4">
              <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-normal pt-0.5 uppercase">
                Actividades económicas
              </span>
              <Text className="text-black">{empresa.actividad}</Text>
            </div>
            {empresa.rol && !esDemandante && !esOferente && (
              <Documentos empresa={empresa} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
