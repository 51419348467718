

import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RUTAS } from 'const/Dictonary';
import storeMessages from 'states/alertMessages/store';
import { eliminarResiduo } from '../useServicesResiduos';

export const useHelpersResiduos = () => {
  const navigate        = useHistory();
  const [open, setOpen] = useState(false);

  const [selectedRow, setSelectedRow] = useState({});

  // PARA EL FUNCIONAMIENTO DEL MENSAJE DE CONFIRMACIÓN
  const handleClickOpen = (selectedRow, table) => {
    setSelectedRow(selectedRow);
    setOpen(true);
  };
  
  const handleClose = () => {
    setSelectedRow({});
    setOpen(false);
  };

  const handleClickIngresar = () => {
    const tipoFormulario = { formulario: { tipo: 'ingresar' } };

    navigate.push({
      pathname: `${RUTAS.RESIDUOS}/ingresar`,
      state: {
        tipoFormulario,
      }
    });
    
  };

  const handleClickEditar = (row, table) => {

    const tipoFormulario = { formulario: { tipo: 'editar' } };
    const original       = row.original;
    const codigoResiduo  = original?.codigoResiduo;
    const nombreRol      = original?.rol?.nombreRol;

    // Check if original, original.codigoResiduo, and original.rol.nombreRol are defined
    if (!original || !codigoResiduo || !nombreRol) {
      // Show an error message on the screen
      storeMessages.message.setMessage({
        mensaje: 'Ha ocurrido un error al tratar de editar el Residuo declarado: no es un Residuo válido.',
        tipo: 'error'
      });

      // Return control immediately and do nothing else
      return;
    }

    // Check if codigoResiduo is defined before navigating
    navigate.push({
      pathname: `${RUTAS.RESIDUOS}/${nombreRol}/editar/${codigoResiduo}`,
      state: {
        codigoResiduo,
        original,
        tipoFormulario,
      }
    });

  };

  const handleClickDuplicar = (row, cargaResiduos) => {
    const tipoFormulario = { formulario: { tipo: 'duplicar' } };
    const original       = row.original;
    const codigoResiduo  = original?.codigoResiduo;
    const nombreRol      = original?.rol?.nombreRol;

    // Check if original, original.codigoResiduo, and nombreRol are defined
    if (!original || !codigoResiduo || !nombreRol) {
      // Show an error message on the screen
      storeMessages.message.setMessage({
        mensaje: 'Ha ocurrido un error al tratar de duplicar el Residuo: no es un Residuo válido.',
        tipo: 'error'
      });

      // Return control immediately and do nothing else
      return;
    }

    // Check if codigoResiduo is defined before navigating
    if (codigoResiduo) {
      navigate.push({
        pathname: `${RUTAS.RESIDUOS}/${nombreRol}/duplicar/${codigoResiduo}`,
        state: {
          codigoResiduo,
          original,
          tipoFormulario,
        }
      });
    }

  };

  const handleClickEliminar = (
    row,
    residuos,
    setResiduos,
    setOpen,
    cargaResiduos
  ) => {
    const original = row.original;
    const codigoResiduo = original?.codigoResiduo;
    // Se verifica si original y original.codigoResiduo no son nulos ni indefinidos
    if (!original || !codigoResiduo) {
      // Si alguno de los dos es nulo o indefinido
      // se muestra un mensaje de error en la pantalla
      storeMessages.message.setMessage({
        mensaje:
          'Ha ocurrido un error al tratar de eliminar el Residuo : no es un  Residuo válido.',
        tipo: 'error'
      });
      // Se devuelve el control inmediatamente y no se hace nada más
      return;
    }

    setResiduos(
      residuos.filter(r => r.codigoResiduo !== codigoResiduo)
    );

    // Eliminación de Residuo
    eliminarResiduo(codigoResiduo, residuos, setResiduos,cargaResiduos);
    setOpen(false);
  };










  return {
    open,
    selectedRow,
    setOpen,
    setSelectedRow,
    handleClickOpen,
    handleClose,
    handleClickIngresar,
    handleClickEditar,
    handleClickEliminar,
    handleClickDuplicar,
  }
}
