import React from 'react';
import { withRouter } from 'react-router-dom';
import imgEditarClave from 'assets/images/usuario/editar-clave.svg';
import Divider from '@mui/material/Divider';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import ButtonComponent from 'components/design-system/Button/Button';
import ContrasenaCampos from '../Contrasena/ContrasenaCampos';
import storeMessages from 'states/alertMessages/store';
import { Form, Formik } from 'formik';
import { schemaContrasena } from '../Contrasena/schema/schemaContrasena';
import { useOlvidoContrasena } from './hooks/useOlvidoContrasena';

const OlvidoContrasenaFormik = () => {
  const { 
    openRedirect,
    handleClickCloseRedirect,
    handleOnSubmit
  } =  useOlvidoContrasena();
  
  return (
    <section className="w-full bg-gradient-to-b from-[#EFF5F4] to-white">
      <div className="px-4 md:container md:mx-auto">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 pt-10">
            <div className="mb-4">
              <Heading type="h3" className="mb-0">
                Cambiar contraseña
              </Heading>
              <Text className="mb-0">
                Establece una nueva contraseña para el inicio de sesión
              </Text>
            </div>

            <div className="bg-white rounded shadow-md p-5 mb-5">
              <Formik
                initialValues={{
                  passwordActual: '',
                  password: '',
                  confirmarPassword: '',
                  validacionPassword: [
                    'largo',
                    'numero',
                    'minuscula',
                    'mayuscula',
                    'caracter'
                  ]
                }}
                validateOnMount={false}
                validationSchema={schemaContrasena}
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={async (values, { resetForm }) => {
                  try {
                    await handleOnSubmit(values, resetForm);
                  } catch (error) {
                    storeMessages.message.setMessage({
                      mensaje: 'La contraseña no se ha podido actualizar, vuelve a intentarlo y si el problema persiste comunicaté con el administrador.',
                      tipo: 'error'
                    });
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldTouched,
                  isSubmitting,
                  handleChange,
                  setFieldValue
                }) => (
                  <Form id="contrasena">
                    <div className="grid grid-cols-12 gap-4">
                      <div className="col-span-12 md:col-span-3 mx-auto">
                        <img src={imgEditarClave} alt="Cambiar contraseña" />
                      </div>
                      <ContrasenaCampos
                        values={values}
                        handleChange={handleChange}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        errors={errors}
                        isSubmitting={isSubmitting}
                      />
                      <div className="col-span-12 my-2">
                        <Divider />
                      </div>
                      <div className="col-span-12 text-end">
                        <ButtonComponent
                          form="contrasena"
                          buttonType="submit"
                          isLoading={isSubmitting}
                          disabled={
                            Object.keys(errors).length === 0 && !isSubmitting
                              ? false
                              : true
                          }
                        >
                          {isSubmitting ? 'Guardando' : 'Cambiar contraseña'}
                        </ButtonComponent>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            <Dialog
              open={openRedirect}
              onClose={handleClickCloseRedirect}
            >
              <DialogTitle id="alert-dialog-title" className="border-b border-neutral-90">
                <Heading type="h3" className="mb-0">Cambiar contraseña</Heading>
              </DialogTitle>
              <DialogContent className="p-5">
                <DialogContentText id="alert-dialog-description" className="pt-4">
                  <Text>Has ingresado utilizando una <b>contraseña temporal</b>, antes de continuar es <b>obligación que la cambies</b> por alguna de tu preferencia.</Text>
                </DialogContentText>
              </DialogContent>
              <DialogActions className="border-t border-neutral-90 p-4 justify-between">
                <ButtonComponent
                  type="primary"
                  autoFocus
                  onClick={handleClickCloseRedirect}
                  className="w-full xs:w-auto justify-center"
                >
                  Aceptar
                </ButtonComponent>
              </DialogActions>
            </Dialog>

          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(OlvidoContrasenaFormik);
