import { useEffect, useState } from 'react';
import Dropzone from 'components/design-system/Dropzone/Dropzone';
import Heading from 'components/design-system/Heading/Heading';
import IconComponent from 'components/design-system/icon/Icon';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Text from 'components/design-system/Text/Text';
import { DICTONARY } from 'const/Dictonary';

export const BusinessCertifications = ({
  values,
  errors,
  touched,
  onChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  setStep,
  handleSubmit
}) => {
  const [dataFiles, setDataFiles] = useState([]);

  useEffect(() => {
    const esTransportista = values.codigoRolesEmpresa.includes('3');
    const esGestor = values.codigoRolesEmpresa.includes('4');
    const esAsesor = values.codigoRolesEmpresa.includes('5');

    if (esTransportista || esGestor || esAsesor) {
      setFieldValue('archivos', dataFiles);
      console.log(dataFiles);
    } else {
      handleSubmit();
      setStep(5);
    }
  }, [
    // dataFiles,
    setFieldValue,
    setStep,
    values.codigoRolesEmpresa
  ]);

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-9">
        <Heading type="h3">
          Acreditaciones y certificaciones <small>(opcional)</small>
        </Heading>
        <Text className="mb-8">
          Puedes cargar las acreditaciones y certificaciones de tu negocio las
          cuales serán visibles para los usuarios que quieran ver el perfíl de
          tu negocio, este paso lo puedes realizar ahora si tienes los
          antecedentes o efectuar la carga despúes.
        </Text>
      </div>

      <div className="col-span-12">
        <Dropzone
          setDataFiles={setDataFiles}
          dataFiles={dataFiles}
          {...DICTONARY.DROPZONE.CONFIG_UPLOAD_BUSINESS_FILES}
        >
          <Text className="text-black font-medium">
            Solo se aceptan los siguientes tipos de archivos
          </Text>
          <ul className="flex mt-1">
            <li className="d-flex items-center mb-1">
              <IconComponent className="mr-2">
                <TaskAltIcon className="text-base text-uv-primary-20" />
              </IconComponent>
              <Text className="text-neutral-40">Formatos JPG y PDF</Text>
            </li>
            <li className="d-flex items-center mb-1 ml-6">
              <IconComponent className="mr-2">
                <TaskAltIcon className="text-base text-uv-primary-20" />
              </IconComponent>
              <Text className="text-neutral-40">Tamaño no superior a 4 MB</Text>
            </li>
          </ul>
        </Dropzone>
      </div>
    </div>
  );
};

export default BusinessCertifications;
