import React from 'react';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import AlertComponent from 'components/design-system/Alert/Alert';
import { SinIndicadores } from './Reporte/SinIndicadores';
import { useReporte } from './Reporte/hooks/useReporte';
import InputComponent from 'components/design-system/input/Input';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import LabelComponent from 'components/design-system/Label/Label';
import { Indicador } from './Reporte/Indicador';
import { Link, useParams } from 'react-router-dom';
import ButtonComponent from 'components/design-system/Button/Button';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import { Hidden } from '@mui/material';
import { Edit } from '@mui/icons-material';

const Indicadores = () => {
  const {
    isLoading,
    indicadores,
    conIndicadores,
    isSuperAdmin,
    empresas,
    cargaIndicadores
  } = useReporte();

  const { codigoEmpresa } = useParams();

  return (
    <SidebarLayout>
      <div className="mb-5">
        <Heading type="h2">Indicadores</Heading>
      </div>

      {!conIndicadores && !isSuperAdmin && <SinIndicadores />}

      {isSuperAdmin && (
        <div className="mb-5">
          <div className="bg-white rounded shadow-md">
            <div className="grid grid-cols-12 p-5 gap-4 items-center">
              <div className="col-span-12 sm:col-span-3">
                <Heading type="h4" className="mb-0">
                  Cargar indicadores <br className="hidden sm:block" />
                  por empresa
                </Heading>
              </div>
              <div className="col-span-12 sm:col-span-9 lg:col-span-6">
                <AutocompleteComponent
                  id="empresa"
                  name="empresa"
                  accesor="nombreEmpresa"
                  options={empresas}
                  disabled={isLoading ? true : false}
                  onChange={(ev, object, reason) => {
                    if (reason === 'clear') {
                      cargaIndicadores(null);
                    } else {
                      cargaIndicadores(object.codigoEmpresa);
                    }
                  }}
                  getOptionLabel={option => option.nombreEmpresa || ''}
                  value={empresas[0]}
                >
                  <InputComponent
                    fullWidth
                    className="h-9"
                    label={
                      <LabelComponent>
                        Selecciona una empresa para ver sus sucursales
                      </LabelComponent>
                    }
                  />
                </AutocompleteComponent>
              </div>
            </div>
          </div>
        </div>
      )}

      {!isSuperAdmin && (
        <div className="mb-5">
          <AlertComponent type="warning">
            <div className="lg:flex">
              <div className="lg:w-2/3">
                <Text>
                  Los siguientes indicadores han sido generados a partir de las
                  transacciones que has realizado y los datos establecidos para
                  el cálculo los cuales puedes editar.
                </Text>
              </div>
              <div className="lg:w-1/3 lg:text-end mt-3 lg:mt-0">
                <Link to={`/negocio/${codigoEmpresa}/datos-indicadores`}>
                  <ButtonComponent type="primary">
                    <Hidden smDown>Editar datos para el cálculo</Hidden>
                    <Hidden smUp>
                      Editar <Edit className="ml-2" />
                    </Hidden>
                  </ButtonComponent>
                </Link>
              </div>
            </div>
          </AlertComponent>
        </div>
      )}

      <div className="">
        {indicadores.map(section => (
          <div className="bg-white shadow-md rounded p-5 mb-5">
            <div className="flex flex-wrap justify-center">
              {section.heading && (
                <div className="w-full border-b mb-4">
                  <Heading type="h4">{section.heading}</Heading>
                </div>
              )}

              {section.items.map((item, index) => (
                <Indicador
                  heading={item.heading}
                  image={item.image}
                  imageAlt={item.imageAlt}
                  element={item.element}
                  description={item.description}
                  isLoading={isLoading}
                  key={index}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </SidebarLayout>
  );
};

export default Indicadores;
