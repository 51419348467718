import API from './api';
import store from '../states/store.js';

const documentos = {
  get: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/lista-documentos',
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: params,
      interceptor: conInterceptor
    });
  },

  guardarDocumento: function (data, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: `/archivos-indexados`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      data: data,
      interceptor: conInterceptor
    });
  },

  actualizarDocumento: function (uid, data, conInterceptor = true) {
    return API.axios({
      method: 'put',
      url: `/archivos-indexados/${uid}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      data: data,
      interceptor: conInterceptor
    });
  },

  getDocumento: function (uid, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/archivos-indexados/${uid}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  obtenerDocumentos: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/archivos-indexados',
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: params,
      interceptor: conInterceptor
    });
  },

  guardar: function (data, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: '/archivos',
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      data: data,
      interceptor: conInterceptor
    });
  },

  obtenerArchivos: function (data, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/archivos',
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: data,
      interceptor: conInterceptor
    });
  },
  editarArchivos: function (codigoArchivo, data, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/archivos/' + codigoArchivo,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      data: data,
      interceptor: conInterceptor
    });
  },
  eliminaArchivos: function (codigoArchivo, conInterceptor = true) {
    return API.axios({
      method: 'delete',
      url: '/archivos/' + codigoArchivo,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  guardarLucene: function (data, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: '/guardar-documentos',
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      data: {
        ...data,
        origen: 'documentos'
      },
      interceptor: conInterceptor
    });
  },

  editarLucene: function (data, conInterceptor = true) {
    return API.axios({
      method: 'patch',
      url: '/actualiza-documento',
      data: data,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  delete: function (uidDocumento, conInterceptor = true) {
    return API.axios({
      method: 'delete',
      url: '/archivos-indexados/' + uidDocumento,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  // GET - Material Información (Lista paginada)

  obtenerListaMaterialInformacion: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/material-informacion',
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: params,
      interceptor: conInterceptor
    });
  },


  
  // GET - Material informacion (un registro)
  obtenerMaterialInformacion: function (codigoMaterialInformacion, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url:`/material-informacion/${codigoMaterialInformacion}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  // DELETE - Material informacion
  eliminarMaterialInformacion: function (codigoMaterialInformacion, conInterceptor = true) {
    return API.axios({
      method: 'delete',
      url:`/material-informacion/${codigoMaterialInformacion}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  // POST - Material informacion
  guardarMaterialInformacion: function (data, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: `/material-informacion`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      data: data,
      interceptor: conInterceptor
    });
  },

  // PUT - Material informacion
  actualizarMaterialInformacion: function (codigoMaterialInformacion, data, conInterceptor = true) {
    return API.axios({
      method: 'put',
      url: `/material-informacion/${codigoMaterialInformacion}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      data: data,
      interceptor: conInterceptor
    });
  },

  //GET - Material informacion (solo base 64)
  obtenerMaterialInformacionBase64: function (codigoMaterialInformacion, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url:`/material-informacion/base64/${codigoMaterialInformacion}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor: conInterceptor
    });
  },

  //GET - Marcos legales (Lista paginada)
  obtenerListaMarcosLegales: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/marcos-legales',
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: params,
      interceptor: conInterceptor
    });
  },

  // GET - Publicaciones (Lista paginada)
  obtenerListaPublicaciones: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/publicaciones',
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: params,
      interceptor: conInterceptor
    });
  },

  // GET - Normativas tecnicas (Lista paginada)
  obtenerListaNormativasTecnicas: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/normativas-tecnicas',
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: params,
      interceptor: conInterceptor
    });
  },


};

export default documentos;
