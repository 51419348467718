import { useState } from 'react';
// Material UI
import { FormControl, IconButton, InputAdornment } from '@mui/material';
import { Close, Done, Visibility, VisibilityOff } from '@mui/icons-material';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import ButtonComponent from 'components/design-system/Button/Button';
import { Form } from 'formik';
import Text from 'components/design-system/Text/Text';
import IconComponent from 'components/design-system/icon/Icon';

export const SignupForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  validateForm,
  cargando,
  correoInvitado
}) => {
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [originalEmailValue, setOriginalEmailValue] = useState('');
  const handleClickShowPassword1 = () => setShowPassword1(show => !show);
  const handleClickShowPassword2 = () => setShowPassword2(show => !show);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };


  return(
    <Form
      className="grid grid-cols-12 gap-4"
      onChange={event => {}}
    >
      <div className="col-span-12">
        <InputComponent
          autoComplete="off" 
          name={'name'}
          onChange={e => {
            setFieldValue('emailModificado', false);
            handleChange(e);
          }}
          fullWidth
          value={values.name}
          label={<LabelComponent>Nombre completo</LabelComponent>}
          onBlur={() => {
            setFieldTouched('name');
            setFieldValue('emailModificado', false);
          }}
          touched={touched.name ? true : false}
          estado={
            errors.name && touched.name
              ? { tipo: 'error', mensaje: errors.name }
              : null
          }
        />
      </div>
      <div className="col-span-12">
        <InputComponent
          id='email'
          autoComplete="off" 
          name={'email'}
          type="text"
          value={values.email ? values.email: ''}
          disabled={correoInvitado}
          isLoading={isLoadingEmail}
          onChange={e => {
            setFieldValue('emailModificado', false);
            setFieldValue('email', e.target.value.trim());
            handleChange(e);
          }}
          onPaste={e => {
            setFieldValue('emailModificado', false);
            setFieldValue('email', e.target.value.trim());
            setFieldValue('setIsLoadingEmail', setIsLoadingEmail);
            setFieldValue('emailModificado', true);
          }}
          fullWidth
          label={<LabelComponent>Email</LabelComponent>}
          onClick={e => {
            setFieldValue('emailModificado', false);
            setFieldTouched('email');
          }}
          onBlur={e => {
            setFieldTouched('email');
            if (originalEmailValue !== e.target.value) {
              handleBlur(e);
              setFieldValue('emailModificado', true);
              setFieldValue('setIsLoadingEmail', setIsLoadingEmail);
              /* setFieldValue('email'); */
            }
          }}
          onFocus={e => {
            setOriginalEmailValue(e.target.value);
          }}
          touched={touched.email}
          estado={
            errors.email && touched.email
              ? { tipo: 'error', mensaje: errors.email }
              : null
          }
        />
      </div>
      <div className="col-span-12">
        <FormControl variant="outlined" fullWidth>
          <InputComponent
            name={'password1'}
            type={showPassword1 ? 'text' : 'password'}
            onChange={e => {
              setFieldValue('emailModificado', false);
              handleChange(e);
            }}
            value={values.password1}
            fullWidth
            label={<LabelComponent>Contraseña</LabelComponent>}
            outerInputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword1}
                    onMouseDown={handleMouseDownPassword}
                    size="small"
                  >
                    {showPassword1 ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
            onBlur={() => {
              setFieldTouched('password1');
              setFieldValue('emailModificado', false);
            }}
            touched={touched.password1 ? true : false}
            estado={
              errors.password1 && touched.password1
                ? { tipo: 'error', mensaje: '' }
                : null
            }
          />
        </FormControl>
        {values.password1.length > 0 && (
          <>
            <ul className="mt-4 mb-4">
              {values.password1.length < 8 && (
                <Text className="text-error-dark">
                  {errors.password1}
                </Text>
              )}
              {values.password1.length > 7 && (
                <>
                  <li className="d-flex items-center mb-1">
                    <IconComponent className="mr-2">
                      {errors.password1 !== 'numero' ? (
                        <Done className="text-base" />
                      ) : (
                        <Close className="text-base text-error-dark" />
                      )}
                    </IconComponent>

                    <Text>Tiene al menos un número</Text>
                  </li>

                  {errors.password1 !== 'numero' && (
                    <li className="d-flex items-center mb-1">
                      <IconComponent className="mr-2">
                        {errors.password1 !== 'mayuscula' ? (
                          <Done className="text-base" />
                        ) : (
                          <Close className="text-base text-error-dark" />
                        )}
                      </IconComponent>
                      <Text>Tiene al menos una mayúscula</Text>
                    </li>
                  )}
                  {errors.password1 !== 'numero' &&
                    errors.password1 !== 'mayuscula' && (
                      <li className="d-flex items-center mb-1">
                        {
                          <IconComponent className="mr-2">
                            {errors.password1 !== 'minuscula' ? (
                              <Done className="text-base" />
                            ) : (
                              <Close className="text-base text-error-dark" />
                            )}
                          </IconComponent>
                        }
                        <Text>Tiene al menos una minúscula</Text>
                      </li>
                    )}
                  {errors.password1 !== 'numero' &&
                    errors.password1 !== 'mayuscula' &&
                    errors.password1 !== 'minuscula' && (
                      <li className="d-flex items-center mb-1 text-error-dark">
                        {
                          <IconComponent className="mr-2">
                            {errors.password1 !== 'caracter' ? (
                              <Done className="text-base" />
                            ) : (
                              <Close className="text-base text-error-dark" />
                            )}
                          </IconComponent>
                        }
                        <Text
                          className={
                            errors.password1 === 'caracter' &&
                            'text-error-dark font-semibold'
                          }
                        >
                          Tiene un carácter especial ej: ej: #@,"
                        </Text>
                      </li>
                    )}
                </>
              )}
            </ul>
          </>
        )}
      </div>
      <div className="col-span-12 mb-4">
        <FormControl variant="outlined" fullWidth>
          <InputComponent
            name={'password2'}
            type={showPassword2 ? 'text' : 'password'}
            onChange={e => {
              setFieldValue('emailModificado', false);
              handleChange(e);
            }}
            value={values.password2}
            fullWidth
            label={
              <LabelComponent>Repetir contraseña</LabelComponent>
            }
            outerInputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={handleClickShowPassword2}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword2 ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
            onBlur={() => {
              setFieldTouched('password2');
              setFieldValue('emailModificado', false);
            }}
            touched={touched.password2 ? true : false}
            estado={
              errors.password2 && touched.password2
                ? { tipo: 'error', mensaje: errors.password2 }
                : null
            }
          />
        </FormControl>
      </div>
      <div className="col-span-12 text-center">
        <ButtonComponent
          isLoading={cargando}
          disabled={
            Object.keys(errors).length === 0 && !cargando
              ? false
              : true
          }
          buttonType="submit"
          onClick={() => {
            validateForm()
              .then(result => {
                console.log('result :>> ', result);
              })
              .catch(err => {});
          }}
          className="min-w-[180px] justify-center"
        >
          {cargando ? 'Creando cuenta' : 'Registro'}
        </ButtonComponent>
      </div>
    </Form>
  )
};