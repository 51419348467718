import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import CheckboxComponent from 'components/design-system/Checkbox/Checkbox';
import LabelComponent from 'components/design-system/Label/Label';
import Text from 'components/design-system/Text/Text';
import InputComponent from 'components/design-system/input/Input';
import { useDireccion } from 'hooks/useDireccion';
import { useEffect } from 'react';
import store from 'states/store';

export const FiltroFormCampos = ({
  values,
  sectorEconomico,
  isSubmitting,
  setFieldValue,
  setFieldTouched,
  handleBlur,
  touched,
  errors,
  isDisabled
}) => {
  const {
    regiones,
    cargaListaRegiones,
    listadoComunas,
    cargaListaComunas,
    handleObtenerComunas
  } = useDireccion();

  const { isAnalistaTerritorial } = store.loguedUser.perfiles;

  useEffect(() => {
    if (!values.region) return;
    if (values.region && values.region.codigoRegion) {
      handleObtenerComunas(values.region.codigoRegion);
    }
  }, [values.region?.codigoRegion]);

  const renderTags = values => {
    if (values.length === 1) {
      return <Text>1 Sector seleccionado</Text>;
    }

    return <Text>{values.length} Sectores seleccionados</Text>;
  };

  return (
    <>
      <div className="col-span-12 sm:col-span-6 lg:col-span-4">
        <AutocompleteComponent
          name="sectorEconomico"
          limitTags={1}
          disable
          disableCloseOnSelect
          multiple
          fullWidth
          accesor="nombreSectorEconomico"
          options={sectorEconomico}
          renderTags={values => renderTags(values)}
          disabled={isSubmitting || isDisabled}
          getOptionLabel={option => option.nombreSectorEconomico || ''}
          value={values.sectorEconomico || ''}
          onChange={(ev, object) => {
            setFieldValue('sectorEconomico', object);
          }}
          onBlur={() => setFieldTouched('sectorEconomico')}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <CheckboxComponent
                size="small"
                className="py-1"
                checked={selected}
              />
              {option.nombreSectorEconomico}
            </li>
          )}
        >
          <InputComponent
            autoComplete={'off'}
            fullWidth
            label={<LabelComponent>Sectores económicos</LabelComponent>}
            value={values.sectorEconomico.nombreSectorEconomico}
            placeholder={
              values.sectorEconomico.length === 0 ? 'Sin selección' : ''
            }
            onBlur={handleBlur}
            touched={touched.sectorEconomico}
            estado={
              errors.sectorEconomico
                ? { tipo: 'error', mensaje: errors.sectorEconomico }
                : null
            }
          />
        </AutocompleteComponent>
      </div>

      {isAnalistaTerritorial ? null : (
        <div className="col-span-12 sm:col-span-6 lg:col-span-2">
          <AutocompleteComponent
            name="region"
            accesor="nombreRegion"
            clearOnEscape={true}
            openOnFocus={true}
            options={regiones}
            disabled={!cargaListaRegiones || isSubmitting || isDisabled}
            onChange={(ev, region, reason) => {
              let data = { name: 'region', value: region };
              if (reason === 'clear') {
                data.value = '';
                return;
              }
              setFieldValue('comuna', null);
              setFieldValue('region', data.value);
            }}
            onBlur={() => setFieldTouched('region')}
            value={values.region}
            getOptionLabel={option => option.nombreRegion || ''}
            isOptionEqualToValue={(option, value) =>
              option.nombreRegion === values.region.nombreRegion
            }
          >
            <InputComponent
              autoComplete={'off'}
              fullWidth
              touched={touched.region ? true : false}
              estado={
                errors.region ? { tipo: 'error', mensaje: errors.region } : null
              }
              label={<LabelComponent>Región</LabelComponent>}
            />
          </AutocompleteComponent>
        </div>
      )}

      <div
        className={`${
          isAnalistaTerritorial
            ? 'col-span-12 sm:col-span-6 lg:col-span-4'
            : 'col-span-12 sm:col-span-6 lg:col-span-2'
        }`}
      >
        <AutocompleteComponent
          name="comuna"
          accesor="nombreComuna"
          options={listadoComunas}
          disabled={isSubmitting || isDisabled || !cargaListaComunas}
          onChange={(ev, comuna, reason) => {
            let data = { name: 'comuna', value: comuna };
            if (reason === 'clear') {
              data.value = '';
            }
            setFieldValue('comuna', data.value);
          }}
          value={values.comuna}
          getOptionLabel={option => option.nombreComuna || ''}
          isOptionEqualToValue={(option, value) =>
            option.nombreComuna === values.comuna.nombreComuna
          }
          onBlur={() => setFieldTouched('comuna')}
        >
          <InputComponent
            autoComplete={'off'}
            fullWidth
            touched={touched.comuna ? true : false}
            estado={
              errors.comuna ? { tipo: 'error', mensaje: errors.comuna } : null
            }
            label={<LabelComponent>Comuna</LabelComponent>}
          />
        </AutocompleteComponent>
      </div>
    </>
  );
};
