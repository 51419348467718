import { Divider } from '@mui/material';
import { Form, Formik } from 'formik';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import storeMessages from 'states/alertMessages/store';
import imgEditarClave from 'assets/images/usuario/editar-clave.svg';
import ContrasenaCampos from './ContrasenaCampos';
import { schemaContrasena } from './schema/schemaContrasena';
import store from 'states/store';
import usuariosService from 'services/usuarios';

export const ContrasenaFormik = () => {
  const { logeduser } = store.loguedUser;

  return (
    <>
      <div className="mb-4">
        <Heading type="h3" className="mb-0">
          Cambiar contraseña
        </Heading>
        <Text className="mb-0">
          Establece una nueva contraseña para el inicio de sesión
        </Text>
      </div>

      <div className="bg-white rounded shadow-md p-5 mb-5">
        <Formik
          initialValues={{
            passwordActual: '',
            password: '',
            confirmarPassword: '',
            validacionPassword: [
              'largo',
              'numero',
              'minuscula',
              'mayuscula',
              'caracter'
            ]
          }}
          validateOnMount={false}
          validationSchema={schemaContrasena}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={async (values, { resetForm }) => {
            try {
              const response = await usuariosService.actualizarPassword(
                logeduser.codigoUsuario,
                {
                  passwordActual: values.passwordActual,
                  passwordNueva: values.password
                }
              );

              if (response.status === 200) {
                resetForm();
                storeMessages.message.setMessage({
                  mensaje: 'La contraseña se ha actualizado correctamente',
                  tipo: 'success'
                });
              }
            } catch (error) {}
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldTouched,
            isSubmitting,
            handleChange,
            setFieldValue
          }) => (
            <Form id="contrasena">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12 md:col-span-3 mx-auto">
                  <img src={imgEditarClave} alt="Cambiar contraseña" />
                </div>

                <ContrasenaCampos
                  values={values}
                  handleChange={handleChange}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  errors={errors}
                  isSubmitting={isSubmitting}
                />

                <div className="col-span-12 my-2">
                  <Divider />
                </div>
                <div className="col-span-12 text-end">
                  <ButtonComponent
                    form="contrasena"
                    buttonType="submit"
                    isLoading={isSubmitting}
                    disabled={
                      Object.keys(errors).length === 0 && !isSubmitting
                        ? false
                        : true
                    }
                  >
                    {isSubmitting ? 'Guardando' : 'Cambiar contraseña'}
                  </ButtonComponent>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
