import API from './api';
import store from '../states/store.js';
const cacheUsuarios = {};

const usuarios = '/usuarios';
const sucursales = '/sucursales';

const usuariosService = {
  getAvatar: function (codigoUsuario, conInterceptor = true, { cache } = {}) {

    if (cache && cacheUsuarios[codigoUsuario]) {
      return cacheUsuarios[codigoUsuario];
    }

    cacheUsuarios[codigoUsuario] = API.axios({
      method: 'get',
      url: `/usuarios/${codigoUsuario}/avatar`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor : conInterceptor
    });

    return cacheUsuarios[codigoUsuario];
  },

  validar: function (email, conInterceptor = true) {
    return API.axios({
      method: 'put',
      url: '/valida-usuario-perfil/' + email,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor : conInterceptor
    });
  },

  eliminarUsuario: function (codigoUsuario, conInterceptor = true) {
    return API.axios({
      method: 'delete',
      url: `${usuarios}/${codigoUsuario}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor : conInterceptor
    });
  },

  patch: function (data, conInterceptor = true) {
    return API.axios({
      method: 'patch',
      url: '/actualiza-usuario',
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      data: data,
      interceptor : conInterceptor
    });
  },
  
  autoRegistro: function (usuarioData, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: '/autoregistro',
      data: usuarioData,
      interceptor : conInterceptor
    });
  },

  verificarCuentaUsuarios: function (token, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `${usuarios}/confirmar`,
      params: { token: token },
      interceptor : conInterceptor
    });
  },

  confirmarInvitacion: function (token, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `${usuarios}/confirmar-invitacion`,
      params: { token: token },
      interceptor : conInterceptor
    });
  },

  confirmacionCorreoUsuarios: function (correo, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: `${usuarios}/correo-confirmacion`,
      data: {
        correoElectronico: correo
      },
      interceptor : conInterceptor
    });
  },

  invitarUsuario: function (correo, codigoSucursal, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: `${sucursales}/${codigoSucursal}/invitar-usuario`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      data: {
        correoElectronico: correo
      },
      interceptor : conInterceptor
    });
  },

  invitados: function (codigoEmpresa, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `${usuarios}/invitados`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: {
        codigoEmpresa: codigoEmpresa
      },
      interceptor : conInterceptor
    });
  },

  actualizarPassword: function (codigoUsuario, data, conInterceptor = true) {
    return API.axios({
      method: 'put',
      url: `${usuarios}/${codigoUsuario}/actualizar-password`,
      data: data,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor : conInterceptor
    });
  },

  actualizarUsuario: function (codigoUsuario, data, conInterceptor = true) {
    return API.axios({
      method: 'put',
      url: `${usuarios}/${codigoUsuario}`,
      data: data,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor : conInterceptor
    });
  },

  nuevoUsuario: function (data, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: `${usuarios}`,
      data: data,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor : conInterceptor
    });
  },

  getUsuarios: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `${usuarios}`,
      params: params,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor : conInterceptor
    });
  },

  getUsuario: function (codigoUsuario, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `${usuarios}/${codigoUsuario}`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor : conInterceptor
    });
  },

  correosSinSucursal: function (filtros, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `${usuarios}/correos-sin-sucursal`,
      params: filtros,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      interceptor : conInterceptor
    });
  },

  getResumenUsuario: function (codigoUsuario, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `${usuarios}/resumen`,
      headers: {
        Authorization: 'Bearer ' + store.loguedUser.token
      },
      params: {
        codigoUsuario: codigoUsuario
      },
      interceptor : conInterceptor
    });
  },

  compruebaCorreoRegistrado: function (correo, conInterceptor = true) {
    // console.log(correo);
    return API.axios({
      method: 'post',
      url: `${usuarios}/correo-electronico-registrado`,
      data: correo,
      interceptor : conInterceptor
    });
  },


};

export default usuariosService;
