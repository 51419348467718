import { useState, useContext } from 'react';
import { toLocaleStringPLus } from 'utils';
// Context
import { AuthContext } from 'context';

// Constants
import { DICTONARY } from 'const/Dictonary';
// Services
import residuosServices from 'services/residuos';
import empresasServices from 'services/empresas';

// Utils
import { diccionarioVista } from '../utils/utils';
import {
  AlertaError,
  AlertaSinRegistros,
  ValidacionConexion
} from 'utils/Alertas';

// store
import store from 'states/store';

export const useServicesResiduosDeclarados = () => {
  const { logged } = useContext(AuthContext);

  const { isSuperAdmin } = store?.loguedUser?.perfiles;

  const sucursal = store?.loguedUser?.logeduser?.sucursal;

  // States Campos Listado de Residuos Declarados
  const [residuosDeclarados, setResiduosDeclarados] = useState([]);
  const [isLoadingLista, setIsLoadingLista] = useState(true);

  // States Paginacion
  const [rowCount, setRowCount] = useState(1);
  const [pagination, setPagination] = useState(DICTONARY.PAGINACION.DEFAULT);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);

  // States Campos de Sucursales
  const [sucursales, setSucursales] = useState([]);
  const [isLoadingSucursales, setIsLoadingSucursales] = useState(true);

  // States Campos de Levantamiento de datos
  const [levantamiento, setLevantamiento] = useState([]);
  const [isLoadingLevantamiento, setIsLoadingLevantamiento] = useState(true);

  // States Tipo de Transporte
  const [tipoTransporte, setTipoTransporte] = useState([]);
  const [isLoadingTipoTransporte, setIsLoadingTipoTransporte] = useState(true);

  // States Formato de entrega
  const [formatoEntrega, setFormatoEntrega] = useState([]);
  const [isLoadingFormatoEntrega, setIsLoadingFormatoEntrega] = useState(true);

  // States Campos de RUT empresas SII Transporte
  const [empresasSIITransporte, setEmpresasSIITransporte] = useState([]);
  const [isLoadingEmpresasSIITransporte, setIsLoadingEmpresasSIITransporte] =
    useState(true);

  // States Campos de RUT empresas SII Receptor
  const [empresasSIIReceptor, setEmpresasSIIReceptor] = useState([]);
  const [isLoadingEmpresasSIIReceptor, setIsLoadingEmpresasSIIReceptor] =
    useState(true);

  // States Campos de tipo de tratamiento
  const [tipoTratamiento, setTipoTratamiento] = useState([]);
  const [isLoadingTipoTratamiento, setIsLoadingTipoTratamiento] =
    useState(true);

  const ObtieneListaResiduosDeclarados = async () => {
    setIsLoadingLista(true);

    if (!logged) {
      ValidacionConexion();
      return;
    }

    const query = {};

    let nombreSucursal = null;

    let nombreTipo = null;

    if (columnFilters) {
      nombreSucursal = columnFilters.find(filtro =>
        filtro.id.includes('nombreSucursal')
      );
      nombreTipo = columnFilters.find(filtro =>
        filtro.id.includes('nombreTipo')
      );
    }

    if (nombreSucursal) {
      query.nombreSucursal = nombreSucursal.value;
    }

    if (nombreTipo) {
      query.nombreTipo = nombreTipo.value;
    }

    if (pagination) {
      query.numPagina = pagination.pageIndex;
      query.totalPagina = pagination.pageSize;
    }

    if (!isSuperAdmin) {
      query.codigoEmpresa = sucursal?.empresa?.codigoEmpresa;
    }

    try {
      const response = await residuosServices.obtenerListaResiduosDeclarados(
        query
      );
      if (response.status === 200) {
        const { content, totalElements, empty } = response.data;
        if (empty) {
          console.log(
            `No hay ${diccionarioVista.nombreSingular} disponibles ::>`
          );
          setResiduosDeclarados([]);
          throw new Error('SIN_REGISTROS');
        } else if (Array.isArray(content)) {
          setResiduosDeclarados(
            content.map(residuo => ({
              ...residuo,
              cantidad:
                residuo.cantidad && residuo.toLocaleString
                  ? toLocaleStringPLus(residuo.cantidad)
                  : '-'
            }))
          );
          setRowCount(totalElements);
        }
      }
    } catch (error) {
      console.log('error :>> ', error);
      setResiduosDeclarados([]);
      setRowCount(0);
      if (error.message === 'SIN_REGISTROS') {
        AlertaSinRegistros();
      }
      if (
        !error.response ||
        !error.response.data ||
        !error.response.data.codigoError
      ) {
        AlertaError({
          mensaje:
            'Ha ocurrido un error al tratar de obtener la lista de residuos.'
        });
      }
    } finally {
      setIsLoadingLista(false);
    }
  };

  const ObtieneLevantamiento = async () => {
    setIsLoadingLevantamiento(true);

    if (!logged) {
      ValidacionConexion();
      return;
    }

    try {
      const response = await residuosServices.obtieneLevantamientoDatos();
      if (response.status === 200) {
        const { content, totalElements, empty } = response.data;
        if (empty) {
          console.log('No hay levantamientos de datos disponibles ::>');
          setLevantamiento([]);
          throw new Error('SIN_REGISTROS');
        } else if (Array.isArray(content)) {
          setLevantamiento(content);
        }
      }
    } catch (err) {
      console.log('err :>> ', err);
      setLevantamiento([]);
      if (err.message === 'SIN_REGISTROS') {
        AlertaSinRegistros();
      }
      if (
        !err.response ||
        !err.response.data ||
        !err.response.data.codigoError
      ) {
        AlertaError({
          mensaje:
            'Ha ocurrido un error al tratar de obtener la lista de levantamientos de datos.'
        });
      }
    } finally {
      setIsLoadingLevantamiento(false);
    }
  };

  const ObtieneEmpresasSIIReceptor = async rutEmpresa => {
    // setIsLoadingEmpresasSIIReceptor(true);

    if (!logged) {
      ValidacionConexion();
      return;
    }

    try {
      const params = { rutEmpresa: rutEmpresa };
      const response = await empresasServices.datosEmpresasSII(
        params,
        DICTONARY.INTERCEPTOR.DESACTIVADO
      );
      if (response.status === 200) {
        const { data } = response;
        if (data.error) {
          setEmpresasSIIReceptor([]);
          throw new Error('SIN_REGISTROS');
        }
        if (data) {
          setEmpresasSIIReceptor(data);
        } else {
          console.log('Sin información de empresas SII ::>');
          setEmpresasSIIReceptor([]);
        }
      }
    } catch (err) {
      console.log('err :>> ', err);
      setEmpresasSIIReceptor([]);
      if (err.message === 'SIN_REGISTROS') {
        AlertaSinRegistros();
      } else if (
        !err.response ||
        !err.response.data ||
        !err.response.data.codigoError
      ) {
        AlertaError({
          mensaje:
            'Ha ocurrido un error al tratar de obtener la información de la empresa.'
        });
      }
    } finally {
      setIsLoadingEmpresasSIIReceptor(false);
    }
  };

  const ObtieneEmpresasSIITransporte = async rutEmpresa => {
    // setIsLoadingEmpresasSIITransporte(true);

    if (!logged) {
      ValidacionConexion();
      return;
    }

    try {
      const params = { rutEmpresa: rutEmpresa };
      const response = await empresasServices.datosEmpresasSII(
        params,
        DICTONARY.INTERCEPTOR.DESACTIVADO
      );
      if (response.status === 200) {
        const { data } = response;
        if (data.error) {
          setEmpresasSIITransporte([]);
          throw new Error('SIN_REGISTROS');
        }
        if (data) {
          setEmpresasSIITransporte(data);
        } else {
          console.log('Sin información de empresas SII ::>');
          setEmpresasSIITransporte([]);
        }
      }
    } catch (err) {
      console.log('err :>> ', err);
      setEmpresasSIITransporte([]);
      if (err.message === 'SIN_REGISTROS') {
        AlertaSinRegistros();
      } else if (
        !err.response ||
        !err.response.data ||
        !err.response.data.codigoError
      ) {
        AlertaError({
          mensaje:
            'Ha ocurrido un error al tratar de obtener la información de la empresa.'
        });
      }
    } finally {
      setIsLoadingEmpresasSIITransporte(false);
    }
  };

  const ObtieneSucursales = async () => {
    setIsLoadingSucursales(true);

    if (!logged) {
      ValidacionConexion();
      return;
    }

    const query = {};

    if (!isSuperAdmin) {
      query.codigoEmpresa = sucursal?.empresa?.codigoEmpresa;
    }

    query.totalPagina = DICTONARY.PAGINACION.ALL_DATA;

    try {
      // TODO:  obliga enviar el cod de empresa
      const response = await empresasServices.obtieneSucursales(query);
      if (response.status === 200) {
        const { content, totalElements, empty } = response.data;
        if (empty) {
          console.log('No hay sucursales disponibles ::>');
          setSucursales([]);
          throw new Error('SIN_REGISTROS');
        } else if (Array.isArray(content)) {
          setSucursales(content);
        }
      }
    } catch (err) {
      console.log('err :>> ', err);
      setSucursales([]);
      if (err.message === 'SIN_REGISTROS') {
        AlertaSinRegistros();
      }
      if (
        !err.response ||
        !err.response.data ||
        !err.response.data.codigoError
      ) {
        AlertaError({
          mensaje:
            'Ha ocurrido un error al tratar de obtener la lista de sucursales.'
        });
      }
    } finally {
      setIsLoadingSucursales(false);
    }
  };

  const ObtieneTipoTransporte = async () => {
    setIsLoadingTipoTransporte(true);

    if (!logged) {
      ValidacionConexion();
      return;
    }

    try {
      const response = await empresasServices.obtenerTipoTransaporte();
      if (response.status === 200) {
        const { content, totalElements, empty } = response.data;
        if (empty) {
          console.log('No hay tipo de transporte disponibles ::>');
          setTipoTransporte([]);
          throw new Error('SIN_REGISTROS');
        } else if (Array.isArray(content)) {
          setTipoTransporte(content);
        }
      }
    } catch (err) {
      console.log('err :>> ', err);
      setTipoTransporte([]);
      if (err.message === 'SIN_REGISTROS') {
        AlertaSinRegistros();
      }
      if (
        !err.response ||
        !err.response.data ||
        !err.response.data.codigoError
      ) {
        AlertaError({
          mensaje:
            'Ha ocurrido un error al tratar de obtener la lista de tipos de transporte.'
        });
      }
    } finally {
      setIsLoadingTipoTransporte(false);
    }
  };

  const ObtieneFormatoEntrega = async () => {
    setIsLoadingFormatoEntrega(true);

    if (!logged) {
      ValidacionConexion();
      return;
    }

    try {
      const response = await residuosServices.obtenerListaFormatoEntrega();

      if (response.status === 200) {
        const { content, totalElements, empty } = response.data;
        if (empty) {
          console.log('No hay formato de entrega disponibles ::>');
          setFormatoEntrega([]);
          throw new Error('SIN_REGISTROS');
        } else if (Array.isArray(content)) {
          setFormatoEntrega(content);
        }
      }
    } catch (err) {
      console.log('err :>> ', err);
      setFormatoEntrega([]);
      if (err.message === 'SIN_REGISTROS') {
        AlertaSinRegistros();
      }

      if (
        !err.response ||
        !err.response.data ||
        !err.response.data.codigoError
      ) {
        AlertaError({
          mensaje:
            'Ha ocurrido un error al tratar de obtener la lista de formato de entrega.'
        });
      }
    } finally {
      setIsLoadingFormatoEntrega(false);
    }
  };

  const ObtieneTipoTratamiento = async () => {
    setIsLoadingTipoTratamiento(true);

    if (!logged) {
      ValidacionConexion();
      return;
    }
    const params = {
      totalPagina: DICTONARY.PAGINACION.ALL_DATA
    };
    try {
      const response = await residuosServices.obtenerListaTipoTratamiento(
        params
      );
      if (response.status === 200) {
        const { content, totalElements, empty } = response.data;
        if (empty) {
          console.log('No hay tipo de tratamiento disponibles ::>');
          setTipoTratamiento([]);
          throw new Error('SIN_REGISTROS');
        } else if (Array.isArray(content)) {
          const listaFiltrada = content.map(item => item.nombreTipoTratamiento);
          setTipoTratamiento(
            content.filter(
              (item, index) =>
                listaFiltrada.indexOf(item.nombreTipoTratamiento) === index
            )
          );
        }
      }
    } catch (err) {
      console.log('err :>> ', err);
      setTipoTratamiento([]);
      if (err.message === 'SIN_REGISTROS') {
        AlertaSinRegistros();
      }

      if (
        !err.response ||
        !err.response.data ||
        !err.response.data.codigoError
      ) {
        AlertaError({
          mensaje:
            'Ha ocurrido un error al tratar de obtener la lista de tipo de tratamiento.'
        });
      }
    } finally {
      setIsLoadingTipoTratamiento(false);
    }
  };

  return {
    // Funciones
    ObtieneEmpresasSIITransporte,
    ObtieneEmpresasSIIReceptor,
    ObtieneFormatoEntrega,
    ObtieneLevantamiento,
    ObtieneListaResiduosDeclarados,
    ObtieneSucursales,
    ObtieneTipoTransporte,
    ObtieneTipoTratamiento,
    // States Listado de Recursos Adquiridos
    columnFilters,
    globalFilter,
    isLoadingLista,
    pagination,
    residuosDeclarados,
    rowCount,
    setColumnFilters,
    setGlobalFilter,
    setIsLoadingLista,
    setPagination,
    setResiduosDeclarados,
    setRowCount,

    // States Levantamiento de datos
    levantamiento,
    setLevantamiento,
    isLoadingLevantamiento,
    setIsLoadingLevantamiento,

    // States Sucursales
    sucursales,
    setSucursales,
    isLoadingSucursales,
    setIsLoadingSucursales,

    // States Tipo de Transporte
    tipoTransporte,
    setTipoTransporte,
    isLoadingTipoTransporte,
    setIsLoadingTipoTransporte,

    // States Formato de Entrega
    formatoEntrega,
    setFormatoEntrega,
    isLoadingFormatoEntrega,
    setIsLoadingFormatoEntrega,

    // States Rut Empresas SII Transporte
    empresasSIITransporte,
    setEmpresasSIITransporte,
    isLoadingEmpresasSIITransporte,
    setIsLoadingEmpresasSIITransporte,

    // States Rut Empresas SII Receptor
    empresasSIIReceptor,
    setEmpresasSIIReceptor,
    isLoadingEmpresasSIIReceptor,
    setIsLoadingEmpresasSIIReceptor,

    // States Tipo de Tratamiento
    tipoTratamiento,
    setTipoTratamiento,
    isLoadingTipoTratamiento,
    setIsLoadingTipoTratamiento
  };
};
